import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

//css
import './chart-data.scss';
import colors from '../../../components/charts/colors.json';

//parts
import ColumnTitle from './ColumnTitle';
import DataItem from './DataItem';
import errorAlert from '../../../../../../../components/confirm-alert/error-alert';

const ChartData = ({
  editable,
  filters,
  chartData,
  axie,
  chartType,
  onChangeFilters,
  onChangeChartData,
  onChangeAxie,
  onDeleteAxie,
  onChangeColorItems,
  onRemoveColorItes,
  colorsList,
  sourceDestination
}) => {
  const [showFiltersData, setShowFilterData] = useState(true);
  const [showChartData, setShowChartData] = useState(true);
  const [showSelectedItemPalette, setShowSelectedItemPalette] = useState({name:null,key:null});

  useEffect(() => {
    onDragEnd(sourceDestination)
  }, [sourceDestination])
  

  const _onGroupItem = (type, item, isActive) => {
    if (type === 'filter') {
      let newItems = filters.map((elm) => {
        let field = elm;
        if (elm.key === item.key) {
          field = field.set('group', isActive);
        }
        return field;
      });
      onChangeFilters(newItems);
    }
    if (type === 'chartData') {
      let newItems = chartData.map((elm) => {
        let field = elm;
        if (elm.key === item.key) {
          field = field.set('group', isActive);
        }
        return field;
      });
      onChangeChartData(newItems);
    }
  };

  const _onFilterItem = (type, item, isActive) => {
    if (type === 'filter') {
      let newItems = filters.map((elm) => {
        let field = elm;
        if (elm.key === item.key) {
          field = field.set('filter', isActive);
        }
        return field;
      });
      onChangeFilters(newItems);
    }
    if (type === 'chartData') {
      let newItems = chartData.map((elm) => {
        let field = elm;
        if (elm.key === item.key) {
          field = field.set('filter', isActive);
        }
        return field;
      });

      onChangeChartData(newItems);
    }
    if (type === 'axie') {
      let _axie = axie;
      _axie = _axie.set('filter', isActive);
      onChangeAxie(_axie);
    }
  };

  const _onChangeData = (type, item, data) => {
    if (type === 'filter') {
      let newItems = filters.map((elm) => {
        let field = elm;
        if (elm.key === item.key) {
          field = field.set('data', data);
        }
        return field;
      });
      onChangeFilters(newItems);
    }
    if (type === 'chartData') {
      let newItems = chartData.map((elm) => {
        let field = elm;
        if (elm.key === item.key) {
          field = field.set('data', data);
        }
        return field;
      });
      onChangeChartData(newItems);
    }
  };

  const _onDeleteFilter = (filter) => {
    let newFilters = filters.filter((f) => f.key !== filter.key);
    onChangeFilters(newFilters);
  };
  const _onDeleteChartDataItem = (item) => {
    let newItems = chartData.filter((itm) => itm.key !== item.key);
    let colorsIndex = colorsList.findIndex((itm) => itm.key === item.key);
    if(colorsIndex>-1){
      colorsList[colorsIndex] = {color: colorsList[colorsIndex].color}
      onRemoveColorItes(colorsList)
    }
    onChangeChartData(newItems && newItems.length>0 ? newItems: null);
  };

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

  const onDragEnd = (e) => {
    console.log('onDragEnd', e);
    if (!e.destination) return null;
    let source = e.source;
    let destination = e.destination;

    // if (source.droppableId === destination.droppableId) return null;
    
    let draggbleItem = null;
    if (source.droppableId === 'filter') {
      draggbleItem = filters[source.index];
    }
    if (source.droppableId === 'data') {
      draggbleItem = chartData[source.index];
    }
    if (source.droppableId === 'scale') {
      draggbleItem = axie;
    }

    // source left side
    if (source.droppableId === 'initData') {
      draggbleItem = source.draggbleItem;
    }

    if (destination.droppableId === 'data') {
      if(chartData && chartData.length >= 9){
        errorAlert.show("vous ne pouvez pas sélectionner plus de 9 données à chiffrer", "Attention!");
        destination.droppableId = 'filter'
      }
    }

    if (source.droppableId === destination.droppableId && draggbleItem.isCryptedData) {
      if (destination.droppableId === 'filter') {
        if(filters && filters.length > 0){
          onChangeFilters(array_move(filters,source.index,destination.index))
        } else {
          onChangeFilters([draggbleItem])
        }
      }

      if (destination.droppableId === 'data') {
        if(filters && filters.length > 0){
          onChangeChartData(array_move(chartData,source.index,destination.index))
        } else {
          onChangeChartData([draggbleItem])
        }
      }


    }

    if( source.droppableId !== destination.droppableId){
      if (destination.droppableId === 'filter') {
        if (draggbleItem.isCryptedData) {
          filters = filters && filters.length > 0 ? [...filters, draggbleItem]: [draggbleItem]
          onChangeFilters(filters && filters.length > 0 ? array_move(filters,((filters && filters.length-1 < 0) ? 0 :filters?.length-1),destination.index) : filters);
  
          if (source.droppableId == 'data') {
            // remove source
            let newChartData = chartData;
            newChartData.splice(source.index, 1);
            onChangeChartData(newChartData);
          }
          if (source.droppableId == 'scale') {
            onChangeAxie(null);
          }
        }
      }
      if (destination.droppableId === 'data') {
        if (draggbleItem.isCryptedData) {
          chartData = chartData?.length > 0 ? [...chartData, draggbleItem]: [draggbleItem]
          onChangeChartData(chartData?.length > 0 ? array_move(chartData,((chartData?.length-1 < 0) ? 0 :chartData?.length-1),destination.index) : chartData);
  
          if (source.droppableId == 'filter') {
            // remove source
            let newFilters = filters;
            newFilters.splice(source.index, 1);
            onChangeFilters(newFilters);
          }
          if (source.droppableId == 'scale') {
            onChangeAxie(null);
          }
        }
      }
      if (destination.droppableId === 'scale') {
        if (draggbleItem.isScale) {
          onChangeAxie(draggbleItem);
  
          if (source.droppableId == 'filter') {
            // remove source
            let newFilters = filters;
            newFilters.splice(source.index, 1);
            onChangeFilters(newFilters);
          }
          if (source.droppableId == 'data') {
            // remove source
            let newChartData = chartData;
            newChartData.splice(source.index, 1);
            onChangeChartData(newChartData);
          }
        }
      }
    }

  };

  let __chartData = chartData;
  if (chartData && chartData.length > 1 && chartType > 3) {
    __chartData = [chartData[0]];
  }
  
  const onToogleColorPalette = (selectedItem) => {
    if(showSelectedItemPalette && showSelectedItemPalette.name == selectedItem.name && showSelectedItemPalette.key == selectedItem.key){
      setShowSelectedItemPalette({
        name:null,
        key:null
       })
    }else{
      setShowSelectedItemPalette({
       name:selectedItem.name,
       key:selectedItem.key
      })
    }
  }
  return (
    // <DragDropContext onDragEnd={onDragEnd}>
      <div className="dash-chart-data">
        <div className="row">
          <div className="col-sm-4">
            <ColumnTitle
              title={'Filtrer selon'}
              onPressToggle={filters && filters?.length > 0 ? setShowFilterData : null}
            />
            <Droppable droppableId={'filter'}>
              {(provided, snapshot) => (
                <div
                  className="droppable"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    background: snapshot.isDraggingOver ? '#f6603233' : 'transparent',
                  }}
                >
                  {filters?.map((item, key) => (
                    <>
                      <Draggable key={key} draggableId={item.key} index={key}  isDragDisabled={!editable}>
                        {(provided, snapshot) => (
                          <div
                            className="Draggable-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <DataItem
                              editable={editable}
                              label={item.name}
                              activeGroup={false}
                              activeFilter={item?.filter}
                              onToggleGroup={null}
                              onToggleFilter={() => _onFilterItem('filter', item, !item?.filter)}
                              onPressDelete={() => _onDeleteFilter(item)}
                              data={showFiltersData ? (item.data).sort((a, b) => a && a.name && b && b.name && a.name.localeCompare(b.name)) : null}
                              isMultipleSelection={item.isMultipleSelection}
                              onChangeData={(data) => _onChangeData('filter', item, data)}
                            />
                          </div>
                        )}
                      </Draggable>
                    </>
                  ))}
                </div>
              )}
            </Droppable>
          </div>
          <div className="col-sm-4">
            <ColumnTitle
              title={'Données à chiffrer'}
              info={
                'Ajouter des courbes ou des barres d’histogramme. Non disponible pour les Donuts et Camemberts.'
              }
              onPressToggle={chartData && chartData?.length > 0 ? setShowChartData : null}
            />

            <Droppable droppableId={'data'}>
              {(provided, snapshot) => (
                <div
                  className="droppable"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    background: snapshot.isDraggingOver ? '#f6603233' : 'transparent',
                  }}
                >
                  {__chartData?.map((item, key) => {
                    let found = colorsList.findIndex(x=>x.key == item.key && x.name == item.name)
                    if(found<0){
                      let indexNewElement = colorsList.findIndex(x=>!x.key)
                      colorsList[indexNewElement] = {
                        ... colorsList[indexNewElement],
                        key: item.key,
                        name: item.name
                      }
                    }
                  
                    return(
                    
                    <>
                      { showSelectedItemPalette && showSelectedItemPalette.name == item.name && showSelectedItemPalette.key == item.key &&
                        <>
                          <p className='close-color-palette' onClick={()=> onToogleColorPalette(item)}>Fermer</p>
                          <div className="color-palette">
                            {colorsList.map((colorItem, index) => (
                              colorItem.key !== undefined && colorItem.key !== item.key ?
                              <div
                                key={index}
                                className={"color not-allowed"}
                                style={{ backgroundColor: colorItem.color }}
                              />
                              :
                              <div
                                key={index}
                                className={(colorItem.key == item.key ? "color selected" : "color")}
                                style={{ backgroundColor: colorItem.color }}
                                onClick={() => onChangeColorItems(colorItem,showSelectedItemPalette)}
                              />
                            ))}
                          </div>
                        </>
                      }
                      <Draggable key={key} draggableId={item.key} index={key} isDragDisabled={!editable}>
                        {(provided, snapshot) => (
                          <div
                            className="Draggable-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <DataItem
                              editable={editable}
                              label={item.name}
                              activeGroup={item?.group}
                              activeFilter={item?.filter}
                              onToggleGroup={
                                chartType <= 3
                                  ? () => _onGroupItem('chartData', item, !item?.group)
                                  : null
                              }
                              onToggleFilter={() => _onFilterItem('chartData', item, !item?.filter)}
                              onPressDelete={() => _onDeleteChartDataItem(item)}
                              data={showChartData ? (item.data).sort((a, b) =>  a && b && a.name && b.name && a.name.localeCompare(b.name)) : null}
                              isMultipleSelection={item.isMultipleSelection}
                              onChangeData={(data) => _onChangeData('chartData', item, data)}
                              color={colorsList.find(x => x.key && x.key == item.key && x.name == item.name).color || null}
                              onToogleColorPalette={()=> onToogleColorPalette(item)}
                            />
                          </div>
                        )}
                      </Draggable>
                    </>
                  )})}
                </div>
              )}
            </Droppable>
          </div>
          <div className="col-sm-4">
            <ColumnTitle
              title={'Echelle horizontale'}
              info={'Modifier Les unités sur l’axe horizontal. Ne peut contenir qu’un seul champ.'}
            />

            <Droppable droppableId={'scale'}>
              {(provided, snapshot) => (
                <div
                  className="droppable"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    background: snapshot.isDraggingOver ? '#f6603233' : 'transparent',
                  }}
                >
                  {axie ? (
                    <Draggable draggableId={'scale'} index={1}  isDragDisabled={!editable}>
                      {(provided, snapshot) => (
                        <div
                          className="Draggable-item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DataItem
                            editable={editable}
                            label={axie.name}
                            activeGroup={false}
                            activeFilter={axie?.filter}
                            onToggleFilter={() => _onFilterItem('axie', null, !axie.filter)}
                            onPressDelete={() => onDeleteAxie(null)}
                            data={axie.data}
                            isMultipleSelection={axie && axie.name !== undefined && !axie.name.toLowerCase().includes("date")}
                            onChangeData={(data) => onChangeAxie(axie.set('data', data))}
                          />
                        </div>
                      )}
                    </Draggable>
                  ) : null}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </div>
    // </DragDropContext>
  );
};

export default ChartData;
