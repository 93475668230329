/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { NavDropdown, Navbar, NavItem } from "react-bootstrap";
import { BrowserRouter as Router, Link } from "react-router-dom";

import "./Header.scss";
import DataContext from "../../../DataManagement/DataContext";
import { getGuestById, getUserById } from "../../../api/user";
import { getTotalNumberOfPages } from "../../../api/client";
import CompaniesDropDown from "./parts/CompaniesDropDown";
import LanguagesSwitcher from "../../languagesSwitcher";

//i18n
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { getNegociationsByUserType } from "../../../api/negociation";
import logo from "./logo/docatweb-logo.png";
import { manageHomeTabsAndCompanies } from "../../../api/home";

class Header extends Component {
  constructor() {
    super();
    this.state = {
      userRights: Cookies.get("userRights") ? JSON.parse(Cookies.get("userRights")) : {},
      currentClient: {},
      userWithAvatar: {},
      totalNumberOfPages: 0,
      percentage: 0,
      alertMessage: null,
      isOpenedSideBar: false,
      isOpenedLateralBar: false,
      tabs:[],
      defaultClient: null,
      defaultRightType: null,
      redirectionArchive: Cookies.get("redirectionArchive") ? JSON.parse(Cookies.get("redirectionArchive")) : null,
    };
  }

  componentWillMount() {
    const queryString = require("query-string");
    var parsed = queryString.parse(this.props.location.search);
    
    if(parsed && parsed.redirect && parsed.guest_id && (parsed.redirect === true || parseInt(parsed.redirect) === 1) && parseInt(parsed.guest_id)){
      Cookies.set("guest_id",parseInt(parsed.guest_id));
      this.props.history.push(
        "/forgot-password"
      );
    }
    if (Cookies.get("redirectionArchive")) {
      setTimeout(() => {
        //Redirect
        const redirectionTab = JSON.parse(Cookies.get('redirectionArchive'));
        Cookies.remove('redirectionArchive')
        this.props.history.push(
          "/home?tab=" + redirectionTab.tab + "&client=" + redirectionTab.client
        );
        window.location.reload();
      }, 3000);
    }
    if(parsed && parsed.tab && parsed.client){
      this.setState({
        ...this.state,
        archiveRedirection:{
          clientId: parsed.client,
          tab: parsed.tab
        }
      })
    }else{
      this.setState({
        ...this.state,
        archiveRedirection: null
      })
    }

    if(this.state.userRights['userRights'] == undefined){
      this.props.history.push(
        "/login"
      );
    }
    this.setState({
      ...this.state,
      userRights:{
        ...this.state.userRights,
        userRights:this.state.userRights['userRights'] !== undefined && (this.state.userRights['userRights'].sort((a, b) => a.client['companyName'].localeCompare(b.client['companyName']))).sort((a, b) => a.roleType.localeCompare(b.roleType))
      }
    });
    
    let currentClientCookie = Cookies.get("currentClient");
    if (currentClientCookie) {
      this.setState({
        ...this,
        currentClient: JSON.parse(currentClientCookie),
      });
      this.context.update({ currentClient: JSON.parse(currentClientCookie) });
    }
    if(parsed && parsed.contract !== undefined  && this.state.userRights && this.state.userRights.id !== undefined && this.state.userRights.type !== undefined && this.state.userRights.userRights !== undefined && this.state.userRights.userRights.length>0 && this.state.userRights.userRights.some(x=>x.roleType == this.state.userRights.type)){
      if(this.state.userRights.type == "USER" || this.state.userRights.type == "GUEST")
        getNegociationsByUserType(this.state.userRights.id, this.state.userRights.type).then((response) => {
          const negosList = response['data']
          const selectedContract = negosList.find((negociacion) => negociacion.contractId == parsed.contract)
          if(selectedContract && selectedContract.clientId !== undefined){
            let findClient = this.state.userRights.userRights.filter(x=>x.client["id"] ==  selectedContract.clientId && x.roleType ==  this.state.userRights.type)
            if(findClient && findClient.length == 1 && findClient[0] !== undefined && findClient[0].client.id !== undefined){
              Cookies.set("currentClient", findClient[0])
              this.setState({
                ...this,
                userRights: {
                  ...this.state.userRights,
                  negociations: response['data']
                },
                currentClient: findClient[0],
              });
            }

          }     
        });
    }
  
    if(this.state.userRights && this.state.userRights.id !== undefined && currentClientCookie && (JSON.parse(currentClientCookie).roleType == "ADMIN_CLIENT" || JSON.parse(currentClientCookie).roleType == "USER" || JSON.parse(currentClientCookie).roleType == "GUEST")){
      
      let roleConnectedUser = JSON.parse(currentClientCookie).roleType
      let idConnectedUser = this.state.userRights.id
      if(JSON.parse(currentClientCookie).roleType == "ADMIN_CLIENT" && Cookies.get("currentUserInformation") && JSON.parse(Cookies.get("currentUserInformation"))){
        roleConnectedUser = 'USER'
        idConnectedUser = JSON.parse(Cookies.get("currentUserInformation")).id
      }
      if(roleConnectedUser == "USER" || roleConnectedUser == "GUEST")
        getNegociationsByUserType(idConnectedUser, roleConnectedUser).then((response) => {
          // Cookies.set("currentNegosClient",response['data']);
          this.setState({
            ...this.state,
            userRights: {
              ...this.state.userRights,
              negociations: response['data']
            }
          });
        });
    }
    if (this.state.userRights.id !== undefined && currentClientCookie && JSON.parse(currentClientCookie).roleType == "GUEST") {
      getGuestById(this.state.userRights.id).then((response) => {
        this.setState({
          ...this,
          userWithAvatar: response.data,
        });
      });
    }else if(this.state.userRights.id !== undefined){
      getUserById(this.state.userRights.id).then((response) => {
        this.setState({
          ...this,
          userWithAvatar: response.data,
          tabs: response.data["tabs"],
          defaultClient: response.data["defaultClient"],
          defaultRightType: response.data["defaultRightType"]
        },()=>{
          console.log("Client changed")
          if(this.state.defaultClient && this.state.defaultClient !== undefined && this.state.defaultClient !== "" && Cookies.get("isDefaultClientLoaded") && JSON.parse(Cookies.get("isDefaultClientLoaded")) === false && this.state.userRights.userRights !== undefined && this.state.userRights.userRights.length>0){
            let findClient = this.state.userRights.userRights.filter(x=>x.client["id"] ==  this.state.defaultClient && x.roleType ==  this.state.defaultRightType)
            if(findClient && findClient.length == 1 && findClient[0] !== undefined && findClient[0].client.id !== undefined){
              Cookies.set("currentClient", findClient[0])
              this.setState({
                ...this,
                currentClient: findClient[0],
              },()=>{
                Cookies.set("isDefaultClientLoaded", true);
                if (this.state.currentClient && this.state.currentClient.roleType === "ADMIN_CLIENT") {
                  this.props.history.push("/back-office/home");
                  window.location.reload(false);
                } else if (
                  this.state.currentClient.roleType == "USER" ||
                  this.state.currentClient.roleType == "GUEST" ||
                  this.state.currentClient.roleType ==
                    "COMMERCIAL_CONTRACT" ||
                  this.state.currentClient.roleType == "TECHNICAL_CONTRACT"
                ) {
                  this.props.history.push("/home");
                  window.location.reload(false);
                }
              });
            }

          }  
        });
      });
      getTotalNumberOfPages().then((response) => {
        this.setState({
          ...this,
          totalNumberOfPages: response.data?.numberOfPages,
          percentage: response.data?.percentage,
          alertMessage: response.data?.alertMessage
        });
      });
    }
  }

  componentWillReceiveProps() {
    // getUserById(this.state.userRights.id).then((response) => {
    //   this.setState({
    //     ...this,
    //     userWithAvatar: response.data,
    //   });
    // });
  }

  getUserCurrentRole() {
    if (this.state.userRights.isAdmin) {
      return t('header.profile.admin');
    } else {
      let currentClientCookie = Cookies.get("currentClient");
      if (currentClientCookie && currentClientCookie !== undefined && currentClientCookie !== null) {
        if (JSON.parse(currentClientCookie).roleType == "GUEST") {
          return  t('header.profile.guest');
        }
        if (JSON.parse(currentClientCookie).roleType == "USER") {
          return  t('header.profile.user');
        }
        if (JSON.parse(currentClientCookie).roleType == "ADMIN_CLIENT") {
          return t('header.profile.admin_customer');
        }
        if (JSON.parse(currentClientCookie).roleType == "COMMERCIAL_CONTRACT") {
          return t('header.profile.commercial_contract');
        }
        if (JSON.parse(currentClientCookie).roleType == "TECHNICAL_CONTRACT") {
          return t('header.profile.technical_contract');
        }
      }
    }
  }

  getUserAvatar() {
    return this.state.userWithAvatar.avatar
      ? this.state.userWithAvatar.avatar
      : null;
  }

  changeCurrentClient = (client,isContratId=false,isAvt=false, roleTypeClt = null) => {
    
    if(isContratId){
      let clt = this.state.userRights?.negociations.find(item=>item.contractId === client)
      if(clt && clt !== undefined){
        let cltItemIndex =  this.state.userRights.userRights.findIndex(item=> item.roleType == 'USER' && item['client'].companyName === clt.client)
        if(cltItemIndex < 0)
          cltItemIndex =  this.state.userRights.userRights.findIndex(item=> item.roleType == 'USER')
        if(cltItemIndex !== undefined && cltItemIndex>-1){
          this.setState({
            currentClient: this.state.userRights.userRights[cltItemIndex],
          });
          Cookies.set("currentClient", this.state.userRights.userRights[cltItemIndex]);
          this.context.update({
            currentClient: this.state.userRights.userRights[cltItemIndex],
          });
        }
      }
      if(isAvt)
        this.props.history.push("/manage-contract/negociations/endorsement?contract="+client);
      else
        this.props.history.push("/manage-contract/negociations?contract="+client);

      window.location.reload(false);
    }else{

      if(this.state.userRights?.negociations && Cookies.get("currentClient") && JSON.parse(Cookies.get("currentClient")).roleType == "GUEST"){
    
        Cookies.set("currentNegosClientForGuest", this.state.userRights?.negociations.filter(item=>item.clientId.toString() === client.toString()));
        console.log( JSON.parse(Cookies.get("currentNegosClientForGuest")))
        this.props.history.push("/home");
        window.location.reload(false);
      }else{

        const indexClient = (this.state.userRights.userRights).findIndex(item=>item.client["id"].toString() === client.toString() && item.roleType == roleTypeClt)

        if(indexClient>-1){
          console.log("changeCurrentClient", this.state.userRights, client);
          this.setState({
            currentClient: this.state.userRights.userRights[indexClient],
          });
          Cookies.set("currentClient", this.state.userRights.userRights[indexClient]);
          this.context.update({
            currentClient: this.state.userRights.userRights[indexClient],
          });
          if (this.state.userRights && this.state.userRights.userRights && this.state.userRights.userRights[indexClient].roleType === "ADMIN_CLIENT") {
            this.props.history.push("/back-office/home");
            window.location.reload(false);
          } else if (
            this.state.userRights.userRights[indexClient].roleType == "USER" ||
            this.state.userRights.userRights[indexClient].roleType == "GUEST" ||
            this.state.userRights.userRights[indexClient].roleType ==
              "COMMERCIAL_CONTRACT" ||
            this.state.userRights.userRights[indexClient].roleType == "TECHNICAL_CONTRACT"
          ) {
            this.props.history.push("/home");
            window.location.reload(false);
          }
          getTotalNumberOfPages().then((response) => {
            this.setState({
              totalNumberOfPages: response.data,
            });
          });
        }
  
      }
    }

  };

  toogleLeftSideMenu = () => {
    
    
    
    this.setState({
      ...this,
      isOpenedSideBar: !this.state.isOpenedSideBar,
    },()=>{
      // Get elements by class name
      var elementsSideBar = document.getElementsByClassName("closed-sidebar");

      // Toggle class "sidebar-mobile-open" for elements with class "closed-sidebar"
      for (var i = 0; i < elementsSideBar.length; i++) {
        elementsSideBar[i].classList.toggle("sidebar-mobile-open",this.state.isOpenedSideBar);
      }
    })

  }

  toogleTopMenu = () => {
    this.setState({
      ...this,
      isOpenedLateralBar: !this.state.isOpenedLateralBar,
    })

  }

  //@ToDO: replace localStorage with api call
  pinUnpinClt = (clientId, action = null, accessType) => {
    if(clientId == null || clientId == undefined)
      return;
    else{

      if ((this.state.currentClient).roleType !== "GUEST") {


        if(this.state.userRights.id !== undefined){
          getUserById(this.state.userRights.id).then((response) => {
            this.setState({
              ...this.state,
              tabs: response.data["tabs"],
            },()=>{
              switch (action) {
                case "pin":
                  manageHomeTabsAndCompanies(this.state.userRights["id"],this.state.tabs, clientId, accessType).then((response) => { })
                  this.setState({
                    ...this.state,
                    defaultClient: clientId,
                    defaultRightType: accessType
                  },()=>{})
                  break;
                case "unpin":
                  manageHomeTabsAndCompanies(this.state.userRights["id"],this.state.tabs).then((response) => { })
                  this.setState({
                    ...this.state,
                    defaultClient: null,
                    defaultRightType: null
                  },()=>{ })
                  break;
              
                default:
                  break;
              } 
            });
          });
        }
      }

    }
  }

  getItemsClients = (items) => {
    const {defaultClient, defaultRightType} = this.state
    if(items && items.length>0 && defaultClient !== null && defaultRightType !== null){
      items = items.sort((a, b) => a.client['companyName'].localeCompare(b.client['companyName'])).sort((a, b) => a.roleType.localeCompare(b.roleType));
      const pinRow = { defaultClient: defaultClient, defaultRightType: defaultRightType };
      const pinnedRow = items.filter(item => item.client.id === pinRow.defaultClient && item.roleType === pinRow.defaultRightType);
      const filteredItems = items.filter(item => item.client.id !== pinRow.defaultClient || item.roleType !== pinRow.defaultRightType);
      const indexToInsert = filteredItems.findIndex(item => item.roleType === pinRow.defaultRightType);
      if(indexToInsert !== null && indexToInsert !== undefined && indexToInsert > -1)
        items = [...filteredItems.slice(0, indexToInsert), ...pinnedRow, ...filteredItems.slice(indexToInsert)];
    }
    return items;
  }

  render() {
    return (
      <div className="app-header header-shadow">
        <div className="app-header__logo">
          <div className="header__pane">
            <div>
              <button
                type="button"
                className={"hamburger close-sidebar-btn hamburger--elastic" + (this.state.isOpenedSideBar ? " is-active":"")}
                data-class="closed-sidebar"
                onClick={()=>this.toogleLeftSideMenu()}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>{" "}
              {t("header.menu.name")}
            </div>
          </div>
          <div className="logo">
            <Link to="/home" className="nav-link">
              <img src={logo}></img>
            </Link>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button
              type="button"
              className={"hamburger hamburger--elastic mobile-toggle-nav" + (this.state.isOpenedSideBar ? " is-active":"")}
              onClick={()=>this.toogleLeftSideMenu()}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
          <div className="logo">
            <Link to="/home" className="nav-link">
              <img src={logo}></img>
            </Link>
          </div>
        </div>
        <div className="app-header__menu">
          <span>
            <button
              type="button"
              className={"btn-icon btn-icon-only btn btn-default btn-sm mobile-toggle-header-nav" + (this.state.isOpenedLateralBar ? " active":"")}
              onClick={()=>this.toogleTopMenu()}
            >
              <span className="btn-icon-wrapper">
                <i className="fa fa-ellipsis-v fa-w-6"></i>
              </span>
            </button>
          </span>
        </div>
        <div className={"app-header__content" + (this.state.isOpenedLateralBar ? " header-mobile-open":"")}>
          <div className="app-header-left">
            <ul className="header-megamenu nav">
              <li className="">
                  <CompaniesDropDown
                  items={this.getItemsClients(this.state.userRights?.type === 'GUEST' ? this.state.userRights?.negociations : this.state.userRights.userRights)}
                  value={this.state.currentClient}
                  onSelect={this.changeCurrentClient}
                  roleType={this.state.userRights?.type === 'GUEST' ? 'GUEST' : null}
                  negociations={this.state.userRights?.type === 'USER' ? this.state.userRights?.negociations : null}
                  onPinUnpinClt={this.pinUnpinClt}
                  defaultClient={this.state.defaultClient}
                  defaultRightType={this.state.defaultRightType}
                />
              </li>
            </ul>
          </div>
          <div className="app-header-right">
            <div className="">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  {this.state.userRights?.type !== 'GUEST' && <div className="widget-content-left">
                    <div className="widget-heading">
                      {this.state.totalNumberOfPages}{" "}
                      <small>{t("header.documents_consumed")}</small>
                    </div>
                    <div className="progress-bar-sm progress progress-bar-custom">
                      <div
                        className="progress-bar bg-colored"
                        style={{ width: this.state.percentage+'%' }}
                      ></div>
                    </div>
                    { this.state.alertMessage &&
                    <div className="colored">
                      <small>{t("header.subscription_alert_message")}</small>
                    </div>
                    }
                  </div>}
                  {this.state.userRights?.type === 'GUEST' && <div className="widget-content-left">
                    <div className="widget-heading">
                      {this.state.userRights.negociations && this.state.userRights.negociations.length}{" "}
                      <small>{t('header.negotiations')}</small>
                    </div>
                    
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div className="app-header-right">
            <div className="">
              <LanguagesSwitcher
                label={<i className="nav-link-icon fa fa-language"></i>}
              />
            </div>
          </div>
          <div className="app-header-right">
            <div className="">
              <div className="widget-content p-0">
                <div
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="p-0 btn user-infos"
                >
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left avatar-user">
                      <div className="btn-group">
                        <a
                          href="#"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="p-0 btn"
                        >

                          {
                            this.getUserAvatar() &&
                            <img
                            width="42"
                            className="rounded-circle"
                            src={this.getUserAvatar()}
                            alt=""
                          />}

                          {
                            !this.getUserAvatar() &&
                            <div className="no-avatar-header">
                            { this.state.userRights?.firstName ? this.state.userRights?.firstName.charAt(0) : '' }{ this.state.userRights?.lastName ? this.state.userRights?.lastName.charAt(0) : ''}
                          </div>}
                        </a>
                      </div>
                    </div>
                    <div className="widget-content-left  ml-3 header-user-info">
                      <div className="widget-heading shortText">
                        {(this.state.userRights?.firstName ? this.state.userRights?.firstName.charAt(0)+'.' : '') +
                          " " +
                          this.state.userRights?.lastName}{" "}
                        <i className="fa fa-angle-down ml-2 opacity-8"></i>
                      </div>
                      <div className="widget-heading fullText">
                        {this.state.userRights?.firstName +
                          " " +
                          this.state.userRights?.lastName}{" "}
                        <i className="fa fa-angle-down ml-2 opacity-8"></i>
                      </div>
                      <div className="widget-subheading colored">
                        {this.getUserCurrentRole()}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  tabIndex="-1"
                  role="menu"
                  aria-hidden="true"
                  className="user-profile rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right"
                >
                  <div className="">
                    <div className="scrollbar-container ps">
                      <ul className="nav flex-column">
                        {/* <li className="nav-item-header nav-item">Activity</li> */}
                        <li className="nav-item">
                          <Link to="/profile" className="nav-link">
                            {t("header.profile.profile")}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/unconnect" className="nav-link">
                            {t("header.profile.logout")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Header.contextType = DataContext;
export default withRouter(withTranslation()(Header));
