import React, { Component } from 'react';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
class CustomDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedOption: (this.props.handledBy !== null) ? this.props.options.find(item => item.id === this.props.handledBy.id): null
    };
    this.dropdownRef = React.createRef(); // Create a ref to the dropdown container
  }

  componentDidMount() {
    // Add a click event listener to the document to handle clicks outside the component
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    document.removeEventListener('click', this.handleClickOutside);
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  selectOption = (contract, option) => {
    this.setState({
      selectedOption: option,
      isOpen: false,
      contract: contract
    });
    // Call the onSelect prop with the selected option
    if (this.props.onSelect) {
        this.props.onSelect({
          selectedOption: option,
          contract: contract
        });
    }
  };

  // Add this method to prevent the page from reloading
  handleButtonClick = (e) => {
    e.preventDefault();
    this.toggleDropdown();
  };

  // Handle clicks outside the component
  handleClickOutside = (e) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
      // Click occurred outside the dropdown, so close it
      this.setState({
        isOpen: false,
      });
    }
  };

  render() {
    const { contract, options, disabled, canBeFree, handledByArchivedUser } = this.props;
    const { isOpen, selectedOption } = this.state;

    return (
      <div className="custom-dropdown" ref={this.dropdownRef}>
        <button disabled={disabled} onClick={this.handleButtonClick} className="dropdown-toggle form-control" style={{color:selectedOption  && selectedOption.isBuyer ? "#219653": "black"}}>
          {selectedOption ? (selectedOption.firstName + " " + selectedOption.lastName) : (this.props.handledBy !== null && this.props.handledByArchivedUser !== undefined &&  typeof this.props.handledByArchivedUser == "string" ? this.props.handledByArchivedUser: "--- "+ t('frontoffice.home.contracts.table.speaker') +" ---")}
        </button>
        {isOpen && (
          <ul className="dropdown-list text-center" style={{fontFamily:'sans-serif'}}>
            {canBeFree &&
              <>
                <li
                  key={-1}
                  onClick={() => this.selectOption(contract, null)}
                  className="dropdown-item font-weight-bold text-danger"
                >
                  {t('frontoffice.home.contracts.table.release_contract')}
                </li>
                <li
                  key={-1}
                  className="dropdown-item font-weight-bold replacedby"
                >
                 --- {t('frontoffice.home.contracts.table.replaced_by')} ---
                </li>

              </>}
            {options.map((option, key) => (
              <li
                key={option.id}
                onClick={() => this.selectOption(contract, option)}
                className="dropdown-item" style={{fontFamily:'sans-serif', backgroundColor: selectedOption && selectedOption.id == option.id ?"#f6603138": "transparent" ,color:option.isBuyer ? "#219653": "black", fontWeight: 600}}
              >
                {option.firstName + " " + option.lastName}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default withTranslation()(CustomDropdown) ;
