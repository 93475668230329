import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../components/Modal';
import { t } from "i18next";
import { withTranslation } from 'react-i18next';


const ContractsModal = ({ isOpen, onRequestClose, contracts, setContracts, currentDocument, onSaveLinkedContracts }) => {

  

  const onChangeContractLinkStatus = (e, index) => {
    let linked = e.target.checked;
    let idsToLink = null
    if(currentDocument && currentDocument.contractsToLink !== undefined){
      idsToLink = currentDocument.contractsToLink.map((item)=>{return item.id})
    }
    let listing = contracts.map((item, i) => {
      const itemId = item.id;
      return { ...item, linked: (i === index || (idsToLink !== null && idsToLink.includes(itemId)) ) ? linked : (item.linked !== undefined ? item.linked:false) };
    });
    setContracts(listing);
  };


  return (
    <Modal
      title={t('frontoffice.contract_linked_docs.contract_linked_doc.new_link')}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      isFullScreen={false}
      footer={
        <>
          <button className='m-auto' onClick={() => onSaveLinkedContracts()}>{t('common.modals.ok')}</button>
          <button className='m-auto' onClick={() => onRequestClose(false)}>{t('common.modals.ignore')}</button>
        </>
      }
    >
      <table className="linked-docs-modal w-auto">
        <tbody>
          <tr>
            <td className="zone-details">
              <div>
                {/* <a class="ui teal ribbon label">Détails</a> */}
                <ul className="linked-doc-fiels p-4">
                  {contracts.map((field, key) => {
                    return(
                      <li key={key}>
                        <label className="label-radio-check">
                          <input
                            type="checkbox"
                            disabled={currentDocument && currentDocument.linkedContracts !== undefined && currentDocument.linkedContracts.length > 0 && currentDocument.linkedContracts.find(l=>l.id === field.id)}
                            checked={(field.linked && field.linked !== undefined && field.linked !== false) || (currentDocument && currentDocument.linkedContracts !== undefined && currentDocument.linkedContracts.length > 0 && currentDocument.linkedContracts.find(l=>l.id === field.id))}
                            onChange={(e) => onChangeContractLinkStatus(e, key)}
                          />
                          <span>
                            {field.name}
                          </span>
                        </label>
                      </li>
                    )
                  }
                  )}
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default withTranslation()(ContractsModal);
