import React, { useEffect, useState } from 'react';
import { t } from "i18next";
import { withTranslation } from 'react-i18next';
import Modal from '../../../../../../../components/Modal';

import "./RecurrenceDetails.scss";
import ButtonRadioUnChecked from '../../Buttons-radio/ButtonRadioUnChecked';
import ButtonRadioChecked from '../../Buttons-radio/ButtonRadioChecked';
import ButtonArrowsUpDown from '../../Buttons-arrow/ButtonArrowsUpDown';
import DatePicker from 'react-datepicker';
import Moment from 'moment';

const RecurrenceDetails = ({ selectedAlert, locale}) => {
  const getDayNameAndNumber = (date) =>{
    
    // Array of short day names
    var shortDays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

    // Get the day of the week (0-6)
    var dayIndex = date.getDay();

    // Get the short day name
    var shortDayName = shortDays[dayIndex];

    // Get the date number
    var dateNumber = date.getDate();

    return {
      shortDay: shortDayName,
      numDate: dateNumber
    }
  }

  const convertDateFormat = (dateStr) => {
     // Parse the input date string using the expected format
     const date = Moment(dateStr, 'DD/MM/YYYY', true);

     // Check if the date is valid
     if (!date.isValid()) {
         return dateStr
     }
 
     // Format the date to yyyy-MM-dd
     const formattedDate = date.format('YYYY-MM-DD');
 
     return formattedDate;
  }

  const [dateObj, setDateObj] = useState(getDayNameAndNumber(selectedAlert && selectedAlert.date !== undefined ? new Date(selectedAlert.date):new Date()))

   // 'endDate', 'isRecurrent', 'repeatAtDay', 'frequencyType', 'frequency', 'repeatCount', 'endWithContract', 'repeatAtDays'
   const [recurrenceObj, setRecurrenceObj] = useState({
    isRecurrent: selectedAlert.isRecurrent, // true / false
    frequency:selectedAlert.frequency, // repetion numbers
    frequencyType: selectedAlert.frequencyType, // "DAILY"/ "WEEKLY" / "MONTHLY" / "YEARLY"
    repeatAtDays: selectedAlert.repeatAtDays, // "SU" / "MO" / "TU" / "WE" / "TH" / "FR" / "SA"
    repeatAtDay: selectedAlert.repeatAtDay || null, // example : "1TH" / "2TH" / "3TH" / "4TH" / "-1TH" 
    endWithContract: selectedAlert.endWithContract, // true / false
    endDate: (selectedAlert && selectedAlert.endDate !== undefined ? new Date(convertDateFormat(selectedAlert.endDate)): null), // date dd/MM/YYYY
    repeatCount: selectedAlert.repeatCount, // repetion numbers
    endWith:{
      withContract: selectedAlert.endWithContract !== undefined && (selectedAlert.endWithContract.toString() === "1" || selectedAlert.endWithContract.toString() === "true") ? true:false,
      withDate: !selectedAlert.endWithContract && !selectedAlert.repeatCount && selectedAlert && selectedAlert.endDate !== undefined,
      withRepetition: selectedAlert.repeatCount
    },
    dayNameOnCurrentMonth: null
  })

  const detectPositionOfDayOnMonth =(date)=>{
    const dayOfMonth = date.getDate();
    const dayOfWeek = date.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1).getDay(); // Day of the week for the first day of the month
    
    let occurrencesCount = 0;
    let currentDate = new Date(date.getFullYear(), date.getMonth(), 1);
    
    // Count occurrences of the specified day name within the month
    while (currentDate.getMonth() === date.getMonth()) {
        if (currentDate.getDay() === dayOfWeek) {
            occurrencesCount++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    const occurrence = Math.ceil((dayOfMonth + firstDayOfMonth) / 7);
    const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
    const occurrenceSuffix = ['st', 'nd', 'rd', 'th'][Math.min(occurrence - 1, 3)];

    const monthName = date.toLocaleString('default', { month: 'long' });

    console.log(`${dayName} is the ${occurrence}${occurrenceSuffix} on this month ${monthName} ${date.getFullYear()}. There are ${occurrencesCount} occurrences of ${dayName} in ${monthName}.`);
    return {
      position: occurrence+''+occurrenceSuffix,
      occurrences: occurrencesCount
    }
  }

  useEffect(() => {
    if (selectedAlert) {

      setRecurrenceObj({
        ...recurrenceObj,
        frequency: selectedAlert.frequency || 1, // repetion numbers
        frequencyType: selectedAlert.frequencyType || "DAILY", // "DAILY"/ "WEEKLY" / "MONTHLY" / "YEARLY"
        repeatAtDays: selectedAlert.repeatAtDays || [], // "SU" / "MO" / "TU" / "WE" / "TH" / "FR" / "SA"
        repeatAtDay: selectedAlert.repeatAtDay ? selectedAlert.repeatAtDay : (dateObj ? dateObj.numDate.toString() : null), // example : "1TH" / "2TH" / "3TH" / "4TH" / "-1TH" 
        endWithContract: selectedAlert.endWithContract !== undefined && selectedAlert.endWithContract.toString() === "1" ? true : false, // true / false
        endDate: (selectedAlert && selectedAlert.endDate !== undefined ? new Date(convertDateFormat(selectedAlert.endDate)) : null), // date dd/MM/YYYY
        repeatCount: selectedAlert.repeatCount || 1, // repetion numbers
        endWith: {
          withContract: selectedAlert.endWithContract !== undefined && (selectedAlert.endWithContract.toString() === "1" || selectedAlert.endWithContract.toString() === "true") ? true : false,
          withDate: !selectedAlert.endWithContract && !selectedAlert.repeatCount && selectedAlert && selectedAlert.endDate !== undefined,
          withRepetition: selectedAlert.repeatCount ? true : false
        }
      })
    }

  }, [selectedAlert])

  // useEffect(() => {
  //  if(selectedAlert && selectedAlert.date !== undefined){
  //    setRecurrenceObj({
  //      ...recurrenceObj,
  //      date: new Date(selectedAlert.date),
  //      positionOnCurrentMonth: detectPositionOfDayOnMonth(new Date(selectedAlert.date))
  //    })
  //    setDateObj(getDayNameAndNumber(new Date(selectedAlert.date)))
  //  }
    
  // }, [selectedAlert["date"]])

  // useEffect(() => {
  //  if(selectedAlert && selectedAlert.endDate !== undefined){
  //   console.log(selectedAlert.endDate)
  //    setRecurrenceObj({
  //      ...recurrenceObj,
  //      endDate: new Date(convertDateFormat(selectedAlert.endDate))
  //    })
  //  }
    
  // }, [selectedAlert["endDate"]])
  

  const handleChangeAction = (e, varName) =>{
    setRecurrenceObj({
      ...recurrenceObj,
      [varName]: e.target.value
    })
  }

  const handleUpAction = (varName) =>{
    let  newVar = recurrenceObj[varName]
    newVar+=1
    setRecurrenceObj({
      ...recurrenceObj,
      [varName]: newVar
    })
  }
  
  const handleDownAction = (varName) =>{
    let  newVar = recurrenceObj[varName]
    if(newVar > 1)
      newVar-=1
    setRecurrenceObj({
      ...recurrenceObj,
      [varName]: newVar
    })
  }

  const handleStringParam = (varName, newVar) =>{ 
    setRecurrenceObj({
      ...recurrenceObj,
      [varName]: newVar
    })
  }
  
  const getFRDayName = (shortDayEN) => {
    switch (shortDayEN) {
      case "MO":
        return "Lundi"
        break;
      case "TU":
        return "Mardi"
        break;
      case "WE":
        return "Mercredi"
        break;
      case "TH":
        return "Jeudi"
        break;
      case "FR":
        return "Vendredi"
        break;
      case "SA":
        return "Samedi"
        break;
      case "SU":
        return "Dimanche"
        break;

      default:
        return "Inconnue"
        break;
    }
  }
                  
  const handleChangeEndDate = (d) => {
    setRecurrenceObj({
      ...recurrenceObj,
      endDate: d
    })
  }

  const handleChangeRepeatAtDays = (element) => {
    let dayList = recurrenceObj.repeatAtDays || []
    const index = dayList.indexOf(element);
    if (index === -1) {
        // Element doesn't exist, so add it
        dayList.push(element);
    } else {
        // Element exists, so remove it
        dayList.splice(index, 1);
    }
    setRecurrenceObj({
      ...recurrenceObj,
      repeatAtDays: dayList
    })
  }


  const handleChangeRepeatAtDay = (d) => {
    setRecurrenceObj({
      ...recurrenceObj,
      repeatAtDay: d
    })
  }


  const onChangeEndWith = (str) =>{
    let endWithVar = recurrenceObj.endWith || {}
    if(str==="withContract"){
      endWithVar.withContract = true
      endWithVar.withDate = false
      endWithVar.withRepetition = false
    }
    if(str==="withDate"){
      endWithVar.withContract = false
      endWithVar.withDate = true
      endWithVar.withRepetition = false
    }
    if(str==="withRepetition"){
      endWithVar.withContract = false
      endWithVar.withDate = false
      endWithVar.withRepetition = true
    }
    setRecurrenceObj({
      ...recurrenceObj,
      endWith: endWithVar
    })
  }

  
  const checkBeforeFinalize = () =>{
    const {frequencyType, frequency, repeatAtDays, repeatAtDay, endWith, endDate, repeatCount} = recurrenceObj;
    switch (frequencyType) {
      case "DAILY":
        if (!frequency || frequency === null || frequency === "" || frequency === undefined) return true
        if (endWith && endWith.withDate === true && (!endDate || endDate === null || endDate === "" || endDate === undefined)) return true
        if (endWith && endWith.withRepetition === true && (!repeatCount || repeatCount === null || repeatCount === "" || repeatCount === undefined)) return true
        return false
        break;

      case "WEEKLY":
        if (!frequency || frequency === null || frequency === "" || frequency === undefined) return true
        if (!repeatAtDays || repeatAtDays === null || repeatAtDays === "" || repeatAtDays === undefined || repeatAtDays.length === 0) return true
        if (endWith && endWith.withDate === true && (!endDate || endDate === null || endDate === "" || endDate === undefined)) return true
        if (endWith && endWith.withRepetition === true && (!repeatCount || repeatCount === null || repeatCount === "" || repeatCount === undefined)) return true
        return false
        break;

      case "MONTHLY":
        if (!frequency || frequency === null || frequency === "" || frequency === undefined) return true
        if (!repeatAtDay || repeatAtDay === null || repeatAtDay === "" || repeatAtDay === undefined) return true
        if (endWith && endWith.withDate === true && (!endDate || endDate === null || endDate === "" || endDate === undefined)) return true
        if (endWith && endWith.withRepetition === true && (!repeatCount || repeatCount === null || repeatCount === "" || repeatCount === undefined)) return true
        return false
        break;

      case "YEARLY":
        if (!frequency || frequency === null || frequency === "" || frequency === undefined) return true
        if (endWith && endWith.withDate === true && (!endDate || endDate === null || endDate === "" || endDate === undefined)) return true
        if (endWith && endWith.withRepetition === true && (!repeatCount || repeatCount === null || repeatCount === "" || repeatCount === undefined)) return true
        return false
        break;

      default:
        return true
        break;
    }
  }

  return (
    
      <div className="details-recurrence">
        <div className="rows d-flex">
          <div className='col-12 freq-alert'>
            <p className="freq-actions">
              {t("frontoffice.contract_alerts.popup_custom_recurrence.repeat_alert_every") + " " + recurrenceObj.frequency + " " + 
              (recurrenceObj.frequencyType === "DAILY" ? t("common.days.label") : (recurrenceObj.frequencyType === "WEEKLY" ? t("common.weeks") : (recurrenceObj.frequencyType === "MONTHLY" ? t("common.months.label") : t("common.years"))))}
              {/* <input type='number' name='freq' value={recurrenceObj.frequency} className='form-control' min={1} onChange={e=>handleChangeAction(e,"frequency")}/> */}
              {/* <ButtonArrowsUpDown onPressUp={()=>handleUpAction("frequency")} onPressDown={()=>{handleDownAction("frequency")}} /> */}
            </p>
            { recurrenceObj &&  recurrenceObj.frequencyType === "WEEKLY" &&
              <p className="freq-actions">
                <ul className="weekly-zone">
                  <li className={recurrenceObj.repeatAtDays.includes("MO") ? 'active-day' : ''}>{t("common.days.short.mo")}</li>
                  <li className={recurrenceObj.repeatAtDays.includes("TU") ? 'active-day' : ''}>{t("common.days.short.tu")}</li>
                  <li className={recurrenceObj.repeatAtDays.includes("WE") ? 'active-day' : ''}>{t("common.days.short.we")}</li>
                  <li className={recurrenceObj.repeatAtDays.includes("TH") ? 'active-day' : ''}>{t("common.days.short.th")}</li>
                  <li className={recurrenceObj.repeatAtDays.includes("FR") ? 'active-day' : ''}>{t("common.days.short.fr")}</li>
                  <li className={recurrenceObj.repeatAtDays.includes("SA") ? 'active-day' : ''}>{t("common.days.short.sa")}</li>
                  <li className={recurrenceObj.repeatAtDays.includes("SU") ? 'active-day' : ''}>{t("common.days.short.su")}</li>
                </ul>
              </p>
            }
            { recurrenceObj &&  recurrenceObj.frequencyType === "MONTHLY" &&
              <p className="freq-actions">
                <ul className="daily-zone">
                  <li className={recurrenceObj.repeatAtDay.includes(dateObj.numDate.toString()) ? 'active-day' : ''}>{dateObj.numDate}</li>
                  { 
                    // recurrenceObj && recurrenceObj.positionOnCurrentMonth !== null && recurrenceObj.positionOnCurrentMonth !== undefined && recurrenceObj.positionOnCurrentMonth.position !== undefined && recurrenceObj.positionOnCurrentMonth.position === "1st" && 
                    <li className={recurrenceObj.repeatAtDay === ("1"+dateObj.shortDay) ? 'active-day' : ''}>{t("common.steps.first")}</li>
                  }
                  { 
                    // recurrenceObj && recurrenceObj.positionOnCurrentMonth !== null && recurrenceObj.positionOnCurrentMonth !== undefined && recurrenceObj.positionOnCurrentMonth.position !== undefined && recurrenceObj.positionOnCurrentMonth.position === "2nd" && 
                    <li className={recurrenceObj.repeatAtDay === ("2"+dateObj.shortDay) ? 'active-day' : ''}>{t("common.steps.second")}</li>
                  }
                  { 
                    // recurrenceObj && recurrenceObj.positionOnCurrentMonth !== null && recurrenceObj.positionOnCurrentMonth !== undefined && recurrenceObj.positionOnCurrentMonth.position !== undefined && recurrenceObj.positionOnCurrentMonth.position === "3rd" && 
                    <li className={recurrenceObj.repeatAtDay === ("3"+dateObj.shortDay) ? 'active-day' : ''}>{t("common.steps.third")}</li>
                  }
                  { 
                    // recurrenceObj && recurrenceObj.positionOnCurrentMonth !== null && recurrenceObj.positionOnCurrentMonth !== undefined && recurrenceObj.positionOnCurrentMonth.position !== undefined && (recurrenceObj.positionOnCurrentMonth.position === "4th" || recurrenceObj.positionOnCurrentMonth.position === "5th") && 
                    <li className={(recurrenceObj.repeatAtDay === ("4"+dateObj.shortDay)) ? 'active-day' : ''}>{t("common.steps.fourth")}</li>
                  }
                  { 
                    // recurrenceObj && recurrenceObj.positionOnCurrentMonth !== null && recurrenceObj.positionOnCurrentMonth !== undefined && recurrenceObj.positionOnCurrentMonth.position !== undefined && recurrenceObj.positionOnCurrentMonth.position === "5th" && 
                    <li className={recurrenceObj.repeatAtDay === ("-1"+dateObj.shortDay) ? 'active-day last-day-of-month' : 'last-day-of-month'}>{t("common.steps.last")}</li>
                  }
                </ul>
                <span className="current-day">{t("common.days.long."+getFRDayName(dateObj.shortDay))}</span>
              </p>
            }
          </div>
        </div>
        <div className="rows d-flex">
          <div className="col-12">
            <p className="horizontal-bar"></p>
          </div>
        </div>
        <div className="rows d-flex">
          <div className="col-12 pt-3">
            <p>{t("frontoffice.contract_alerts.popup_custom_recurrence.end_with")} :</p>
            <ul className="radio-list">
              <li>
                <div>
                  {recurrenceObj.endWith.withContract ?
                    <ButtonRadioChecked onPress={() => { }} /> :
                    <ButtonRadioUnChecked onPress={() => { }} />
                  }
                  <span>{t("frontoffice.contract_alerts.popup_custom_recurrence.with_contract")}</span>
                </div>
              </li>
              <li className="mt-2">
                <div>
                  {recurrenceObj.endWith.withDate ?
                    <ButtonRadioChecked onPress={() => { }} /> :
                    <ButtonRadioUnChecked onPress={() => { }} />
                  }
                  <span>{t("common.date")}</span>
                </div>
                {recurrenceObj.endWith.withDate && <DatePicker locale={locale} disabled={!recurrenceObj.endWith.withDate} dateFormat="dd MMMM yyyy" className="form-control mx-2 bg-transparent" autoComplete="off" name="date" selected={recurrenceObj.endDate} readOnly={true} minDate={new Date()} placeholderText="Date" required />}
              </li>
              <li className="repeat-count mt-2">
                <div>
                  {recurrenceObj.endWith.withRepetition ?
                    <ButtonRadioChecked onPress={() => { }} /> :
                    <ButtonRadioUnChecked onPress={() => { }} />
                  }
                  <span >{t("frontoffice.contract_alerts.popup_custom_recurrence.repetitions")} X </span>
                </div>
                <input className='mx-2 count-freq bg-transparent' type='number' disabled={true} name='freq2' value={recurrenceObj.repeatCount} min={1} readOnly={true}/>
                {/* <ButtonArrowsUpDown disabled={!recurrenceObj.endWith.withRepetition} onPressUp={()=>handleUpAction("repeatCount")} onPressDown={()=>{handleDownAction("repeatCount")}}  /> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
  );
};

export default withTranslation()(RecurrenceDetails);
