import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShow(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShow(false);
  };

  return (
    show && (
      <div style={styles.cookieBanner}>
        <p className="mb-0">Ce site utilise des cookies pour améliorer votre expérience et analyser l'utilisation du site.</p>
        <button onClick={handleAccept} style={styles.button}>Accepter</button>
        <button onClick={() =>{ /*window.location.href='/cookie-policy'*/}} style={styles.linkButton}>En savoir plus</button>
      </div>
    )
  );
};

const styles = {
  cookieBanner: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#5367F4',
    color: '#fff',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1000,
  },
  button: {
    backgroundColor: '#F66031',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
  },
  linkButton: {
    backgroundColor: 'transparent',
    color: '#FFFFFF',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    textDecoration: 'underline',
  }
};

export default CookieConsent;
