import React, { Component } from 'react';

class CustomDropdownCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.dropdownRef = React.createRef(); // Create a ref to the dropdown container
  }

  componentDidMount() {
    // Add a click event listener to the document to handle clicks outside the component
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    document.removeEventListener('click', this.handleClickOutside);
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  selectOption = (contract, option) => {
    this.setState({
      selectedOption: option,
      isOpen: false,
      contract: contract
    });
    // Call the onSelect prop with the selected option
    if (this.props.onSelect) {
        this.props.onSelect({
          selectedOption: option,
          contract: contract
        });
    }
  };

  // Add this method to prevent the page from reloading
  handleButtonClick = (e) => {
    e.preventDefault();
    this.toggleDropdown();
  };

  // Handle clicks outside the component
  handleClickOutside = (e) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
      // Click occurred outside the dropdown, so close it
      this.setState({
        isOpen: false,
      });
    }
  };

  render() {
    const { contract, options, disabled} = this.props;
    const { isOpen, selectedOption } = this.state;

    return (
      <div className="custom-dropdown" ref={this.dropdownRef}>
        <button disabled={disabled} onClick={this.handleButtonClick} className={disabled ? "dropdown-toggle form-control disabled-category-dorpdownlist":"dropdown-toggle form-control"}>
          {selectedOption ? (selectedOption.name) : (contract.category ? contract.category: "--- Catégories ---")}
        </button>
        {isOpen && (
          <ul className="dropdown-list text-center" style={{fontFamily:'sans-serif'}}>
            {options.map((option, key) => (
              <li
                key={option.id}
                onClick={() => this.selectOption(contract, option)}
                className="dropdown-item" style={{fontFamily:'sans-serif', backgroundColor: selectedOption && selectedOption.id == option.id ?"transparent": (contract.categoryId == option.id ? "#FAFCFE":"transparent") ,color:contract.categoryId == option.id ? "#7077A5": "black", fontWeight: 600}}
              >
                {option.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default CustomDropdownCategories;
