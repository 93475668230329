import Axios from "axios";
import { API_URL } from "../../config/common";
import {
  getConnectedUserAccessToken,
  getCurrentClientID,
} from "../QueryAuthParameters";

//models
import Field from "./models/field";
import DashBord from "./models/DashBord";
import moment from "moment";
import DashBordField from "./models/DashBordField";
import DashBordData from "./models/DashBordData";


export const getFields = (validatedContracts=false) => {
  const token = getConnectedUserAccessToken();

  const formData = new FormData()
  formData.append('client', getCurrentClientID())
  formData.append(
    "fromDate",
    moment().startOf('year').format("DD/MM/YYYY")
  );

  formData.append(
    "toDate",
    moment().format("DD/MM/YYYY")
  );

  if(validatedContracts == true){
    formData.append("isValidated", 1)
  }

  const requestUrl = `${API_URL}/buyingDashboard/getDashboardDataBasedOnFiltersCryptedData?access_token=${token}`;
  return Axios.post(requestUrl, formData).then(res => res.data.map(field => new Field(field)));
  // return demoData.fields.map((field) => new Field(field));
};

export const getFieldsByRangeDate = (data, validatedContracts=false) => {
  const token = getConnectedUserAccessToken();

  const formData = new FormData()
  formData.append('client', getCurrentClientID())
   
  if (data.fromDate) {
    formData.append(
      "fromDate",
      moment(data.fromDate, "YYYY-MM-DD").format("DD/MM/YYYY")
    );
  }
  if (data.fromDate) {
    formData.append(
      "toDate",
      moment(data.toDate, "YYYY-MM-DD").format("DD/MM/YYYY")
    );
  }

  if(validatedContracts == true){
    formData.append("isValidated", 1)
  }

  const requestUrl = `${API_URL}/buyingDashboard/getDashboardDataBasedOnFiltersCryptedData?access_token=${token}`;
  return Axios.post(requestUrl, formData).then(res => res.data.map(field => new Field(field)));
  // return demoData.fields.map((field) => new Field(field));
};

export const getListing = () => {
  const token = getConnectedUserAccessToken();
  const id = getCurrentClientID();
  const requestUrl = `${API_URL}/getBuyingDashboardList/${id}?access_token=${token}`;
  return Axios.get(requestUrl).then(res => res.data.map(
    (item) =>{
      const scaleValues = item.scale && item.scale !== undefined && item.scale["values"] && item.scale["values"] !== undefined && item.scale["values"].length>0 ? item.scale["values"]:[]
      return new DashBord({
        ...item,
        filters: item.filters.map((itm) => new DashBordField(itm)),
        cryptedData: item.cryptedData.map((itm) => new DashBordField(itm)),
        scale: new DashBordField({
          ...item.scale,
          values: scaleValues
        })
      })
    }
  ));
  // return demoData.listings.map(
  //   (item) =>{
  //     return new DashBord({
  //       ...item,
  //       filters: item.filters.map((itm) => new DashBordField(itm)),
  //       cryptedData: item.cryptedData.map((itm) => new DashBordField(itm)),
  //       scale: new DashBordField({
  //         ...item.scale,
  //         values: [item.scale.value]
  //       })
  //     })
  //   }
  // );
};

export const create = (data) => {
  const formData = new FormData();
  formData.append("client", getCurrentClientID());
  formData.append("name", data.name);
  formData.append("isPrivate", data.isPrivate ? 1 : 0);
  formData.append("isFavourite", data.isFavorite ? 1 : 0);
  if (data.fromDate) {
    formData.append(
      "fromDate",
      moment(data.fromDate, "YYYY-MM-DD").format("DD/MM/YYYY")
    );
  }
  if (data.fromDate) {
    formData.append(
      "toDate",
      moment(data.toDate, "YYYY-MM-DD").format("DD/MM/YYYY")
    );
  }
  formData.append("type", data.type || 1);
  formData.append("displayValues", data.displayValues ? 1 : 0);
  formData.append("displayLegend", data.displayLegend ? 1 : 0);

  formData.append("validatedContracts", data.validatedContracts ? 1 : 0);
  formData.append("isCalendar", data.isCalendar ? 1 : 0);
  formData.append("averageTime", data.averageTime ? 1 : 0);

  // formData.append("unity", data.unity || "euro");

  if (data.filters && data.filters.length > 0) {
    data.filters.forEach((element, index) => {
      let selectedItems = element.data
        .filter((item) => item.selected)
        .map((item) => item.name);
      formData.append(
        "filters[" + index + "]",
        JSON.stringify({
          name: element.name,
          isGrouped: element.group,
          isFiltered: element.filter,
          values: selectedItems || [],
          sequence: index
        })
      );
    });
  }
  if (data.cryptedData && data.cryptedData.length > 0) {
    data.cryptedData.forEach((element, index) => {
      let selectedItems = element.data
        .filter((item) => item.selected)
        .map((item) => item.name);
      formData.append(
        "cryptedData[" + index + "]",
        (element && element.color) ?
        JSON.stringify({
          name: element.name,
          isGrouped: element.group,
          isFiltered: element.filter,
          values: selectedItems || [],
          sequence: index,
          color: element.color
        })
        :
        JSON.stringify({
          name: element.name,
          isGrouped: element.group,
          isFiltered: element.filter,
          values: selectedItems || [],
          sequence: index
        })
      );
    });
  }
  
  if (data.scale && data.scale !== null && data.scale !== undefined && data.scale !== "") {
    let selectedScales = data.scale.data
        .filter((item) => item.selected)
        .map((item) => item.name);
    formData.append(
      "scale",
      JSON.stringify({
        name: data.scale.name,
        values: selectedScales || [],
      })
    );
  }

  console.log("data create", data);
  console.log("formDataformData", formData);

  // return
  const token = getConnectedUserAccessToken();

  let requestUrl =''
  if( data.id && data.id > 0 ){
    // api update
    requestUrl = `${API_URL}/buyingDashboard/${data.id}?access_token=${token}`;
  }else{
    // api create
    requestUrl = `${API_URL}/buyingDashboard?access_token=${token}`;
  }
  return Axios.post(requestUrl, formData).then((res) => res.data);
};

export const duplicate = (id) => {
  const token = getConnectedUserAccessToken();
  const requestUrl = `${API_URL}/buyingDashboard/duplicate/${id}?access_token=${token}`;
  return Axios.get(requestUrl).then((res) => res.data);
};

export const remove = (id) => {
  const token = getConnectedUserAccessToken();
  const requestUrl = `${API_URL}/buyingDashboard/${id}?access_token=${token}`;
  return Axios.delete(requestUrl).then((res) => res.data);
};

export const hideDashboard = (id) => {
  const token = getConnectedUserAccessToken();
  const requestUrl = `${API_URL}/buyingDashboard/hideDashboardByUser/${id}?access_token=${token}`;
  return Axios.get(requestUrl).then((res) => res.data);
};

export const unHideDashboard = (id) => {
  const token = getConnectedUserAccessToken();
  const requestUrl = `${API_URL}/buyingDashboard/unhideDashboardByUser/${id}?access_token=${token}`;
  return Axios.get(requestUrl).then((res) => res.data);
};

export const calculateData = ({startDate, endDate, filters, cryptedData, scale, validatedContracts=false}) => {
  const formData = new FormData()
  console.log('calculateData called')
  formData.append('beginningDate', moment(startDate).format('DD/MM/YYYY'))
  formData.append('endingDate', moment(endDate).format('DD/MM/YYYY'))
  formData.append('client', getCurrentClientID())

  
  if(filters !== null && filters !== undefined && filters !== ''){
    let keyFilter = 0;
    filters.forEach((item, key) => {
      if((item.data.filter(itm => itm.selected).map(itm => itm.name)).length>0){
        formData.append(`filters[${keyFilter}]`, JSON.stringify({
          name: item.name,
          values: item.data.filter(itm => itm.selected).map(itm => itm.name)
        }))
        keyFilter++
      }
    })
  }
    const cryptedDataNotEmpty = cryptedData.map(category => ({
      ...category,
      color: category.color,
      data: category.data.filter(item => item.selected === true),
      filter: category.filter,
      group: category.group,
      isCryptedData: category.isCryptedData,
      isMultipleSelection: category.isMultipleSelection,
      isScale: category.isScale,
      key: category.key,
      name: category.name
    })).filter(category => category.data.length > 0);
   
    let keyCryptedData = 0; 
    cryptedDataNotEmpty.forEach((item, key) => {
      if((item.data.filter(itm => itm.selected).map(itm => itm.name)).length>0){
        formData.append(`cryptedData[${keyCryptedData}]`, JSON.stringify({
          name: item.name,
          values: item.data.filter(itm => itm.selected).map(itm => itm.name)
        }))
        keyCryptedData++;
      }
  })
  
  if(scale !== null && scale !== undefined && scale !== '' && scale.data !== undefined && scale.data.length>0 && scale.data.some(item => item.selected === true))
    formData.append('scale', JSON.stringify({
      name: scale.name,
      values: scale.data.filter(itm => itm.selected).map(itm => itm.name)
    }))
  if(validatedContracts == true){
    formData.append("isValidated", 1)
  }
  const token = getConnectedUserAccessToken();
  const requestUrl = `${API_URL}/buyingDashboard/calculateData?access_token=${token}`;
  if(scale !== null && scale !== undefined && scale !== '' && scale.data !== undefined && scale.data.length>0 && scale.data.some(item => item.selected === true))
    return Axios.post(requestUrl, formData).then((res) => res.data.map(item => new DashBordData(item)));
  else
    return Axios.post(requestUrl, formData).then((res) => [{scale: '', cryptedData: [{values:res.data.map(item => new DashBordData(item))}]}]);
};

export const getFieldsByFilters = ({startDate, endDate, filters,cryptedData, scale, validatedContracts=false}) => {

  const formData = new FormData()

  formData.append('beginningDate', moment(startDate).format('DD/MM/YYYY'))
  formData.append('endingDate', moment(endDate).format('DD/MM/YYYY'))
  formData.append('client', getCurrentClientID())
  if(validatedContracts == true){
    formData.append("isValidated", 1)
  }
  if(filters)
    filters = filters.filter(category => category.data.some(item => item.selected === true));
  let keyFilter = 0;
  filters.forEach((item, key) => {
    if((item.data.filter(itm => itm.selected).map(itm => itm.name)).length>0){
      formData.append(`filters[${keyFilter}]`, JSON.stringify({
        name: item.name,
        values: item.data.filter(itm => itm.selected).map(itm => itm.name)
      }))
      keyFilter++;
    }
  })
  let keyCryptedData = 0;
  cryptedData.forEach((item, key) => {
    if((item.data.filter(itm => itm.selected).map(itm => itm.name)).length>0){
      formData.append(`cryptedData[${keyCryptedData}]`, JSON.stringify({
        name: item.name,
        values: item.data.filter(itm => itm.selected).map(itm => itm.name)
      }))
      keyCryptedData++
    }
  })

  if(scale !== null && scale !== undefined && scale !== '' && scale.data !== undefined && scale.data.length>0 && scale.data.some(item => item.selected === true)){
    formData.append('scale', JSON.stringify({
      name: scale.name,
      values: scale.data.filter(itm => itm.selected).map(itm => itm.name)
    }))
  }

  const token = getConnectedUserAccessToken();
  const requestUrl = `${API_URL}/buyingDashboard/getDashboardDataBasedOnFiltersCryptedData?access_token=${token}`;
  return Axios.post(requestUrl, formData).then((res) => res.data.map(item => new Field(item)));
}

export const loadExport = ({startDate, endDate, filters, cryptedData, scale, calendar, validatedContracts}) => {

  const formData = new FormData()

  formData.append('beginningDate', moment(startDate).format('DD/MM/YYYY'))
  formData.append('endingDate', moment(endDate).format('DD/MM/YYYY'))
  let keyFilter = 0;
  filters.forEach((item, key) => {
    if((item.data.filter(itm => itm.selected).map(itm => itm.name)).length>0){
      formData.append(`filters[${keyFilter}]`, JSON.stringify({
        name: item.name,
        values: item.data.filter(itm => itm.selected).map(itm => itm.name)
      }))
      keyFilter++;
    }
  })
  let keyCryptedData = 0;
  cryptedData.forEach((item, key) => {
    if((item.data.filter(itm => itm.selected).map(itm => itm.name)).length>0){
      formData.append(`cryptedData[${keyCryptedData}]`, JSON.stringify({
        name: item.name,
        values: item.data.filter(itm => itm.selected).map(itm => itm.name)
      }))
      keyCryptedData++;
    }
  })
  formData.append('scale', JSON.stringify({
    name: scale.name,
    values: scale.data.filter(itm => itm.selected).map(itm => itm.name)
  }))

  formData.append('client', getCurrentClientID())
  if(calendar === 1)
    formData.append('calendar', calendar)

  if (validatedContracts == true) {
    formData.append("isValidated", 1)
  }

  const token = getConnectedUserAccessToken();
  const requestUrl = `${API_URL}/buyingDashboard/csvExport?access_token=${token}`;
  return Axios.post(requestUrl, formData).then((res) => res.data);
}



// ---API contenu des pages:
export const getPagesDetail = () => {
  const token = getConnectedUserAccessToken();
  const id = getCurrentClientID();
  const requestUrl = `${API_URL}/buyingPage/detail/${id}?access_token=${token}`;
  return Axios.get(requestUrl).then(res => res.data);
};

// ---Création page par user (max 10 pages par utilisateur):
export const addPage = ({name,type}) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('type', type)
  const token = getConnectedUserAccessToken();
  const id = getCurrentClientID();
  const requestUrl = `${API_URL}/buyingPage/${id}?access_token=${token}`;
  return Axios.post(requestUrl, formData).then((res) => res.data);
};
// ---Edit page user:
export const editPage = ({name, id},type) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('type', type)
  formData.append('id', id)
  const token = getConnectedUserAccessToken();
  const idClient = getCurrentClientID();
  const requestUrl = `${API_URL}/buyingPage/${idClient}?access_token=${token}`;
  return Axios.post(requestUrl, formData).then((res) => res.data);
};
// ---Suppression page:
export const deletePage = ({id}) => {
  const formData = new FormData()
  formData.append('id', id)
  const token = getConnectedUserAccessToken();
  const requestUrl = `${API_URL}/buyingPage/${id}?access_token=${token}`;
  return Axios.delete(requestUrl, formData);
};

// ---Ajout d'un TDB à une page:
export const addTDBToPage = (idTDB, idPage, position, height, width) => {
  const formData = new FormData()
  formData.append('dashboard', idTDB)
  formData.append('page', idPage)
  formData.append('position', position)
  formData.append('height', height)
  formData.append('width', width)
  const token = getConnectedUserAccessToken();
  const requestUrl = `${API_URL}/buyingDashboardPage?access_token=${token}`;
  return Axios.post(requestUrl, formData).then((res) => res.data);
};
// ---Edit d'un TDB à une page:
export const editTDBPage = (id, idTDB, idPage, position, height, width) => {
  const formData = new FormData()
  formData.append('dashboard', idTDB)
  formData.append('page', idPage)
  formData.append('position', position)
  formData.append('height', height)
  formData.append('width', width)
  formData.append('id', id)
  const token = getConnectedUserAccessToken();
  const requestUrl = `${API_URL}/buyingDashboardPage?access_token=${token}`;
  return Axios.post(requestUrl, formData).then((res) => res.data);
};
// ---Suppression TDB d'une page:
export const deleteTDB = ({id}) => {
  const formData = new FormData()
  formData.append('id', id)
  const token = getConnectedUserAccessToken();
  const requestUrl = `${API_URL}/buyingDashboardPage/${id}?access_token=${token}`;
  return Axios.delete(requestUrl, formData);
};
