import Axios from 'axios';
import { API_URL } from '../../config/common';
import { getConnectedUserAccessToken, getCurrentClientID } from '../QueryAuthParameters';

export const getContractKeywords = () => {
  const clientID = getCurrentClientID();
  const requestUrl =
    API_URL +
    `/clientKeyword/getExportContractParametersByClient/${clientID}?access_token=` +
    getConnectedUserAccessToken();
  return Axios.get(requestUrl).then((res) => res.data);
};

export const generateAndDownloadExport = ({
  systemKeywords,
  customClauses,
  othersClauses,
  contractsIds,
}) => {
  const formData = new FormData();
  systemKeywords.forEach(({ code, label }, index) => {
    formData.append(`systemKeywords[${index}]`, JSON.stringify({ code, label }));
  });
  customClauses.forEach(({ code, label }, index) => {
    formData.append(`customClauses[${index}]`, JSON.stringify({ code, label }));
  });
  othersClauses.forEach(({ code, label }, index) => {
    formData.append(`others[${index}]`, JSON.stringify({ code, label }));
  });
  contractsIds.forEach((field, index) => {
    formData.append(`contractsIds[${index}]`, field);
  });

  const clientID = getCurrentClientID();
  const requestUrl =
    API_URL + `/contract/csvExport/${clientID}?access_token=` + getConnectedUserAccessToken();

  return Axios.post(requestUrl, formData);
};

export const saveNewExport = (paramsExport) => {
  const requestUrl = API_URL + `/user/saveNewExport?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  const clientID = getCurrentClientID();
  formData.append('client', clientID);
  formData.append('name', paramsExport.name);
  if(paramsExport && paramsExport.id !== undefined){
    formData.append('id', paramsExport.id);
  }
  // paramsExport["fields"] = {
  //   "systemKeywords": paramsExport["fields"]["systemKeywords"].filter(item=>item.selected && item.selected !== undefined && item.selected !== false),
  //   "customClauses": paramsExport["fields"]["customClauses"].filter(item=>item.selected && item.selected !== undefined && item.selected !== false),
  //   "others":paramsExport["fields"]["others"].filter(item=>item.selected && item.selected !== undefined && item.selected !== false)
  // }
  // const fieldsArray = {}
  // if(paramsExport["fields"] && paramsExport["fields"].systemKeywords !== undefined && paramsExport["fields"].systemKeywords.length>0)
  //   fieldsArray["systemKeywords"] =  paramsExport["fields"].systemKeywords
  // if(paramsExport["fields"] && paramsExport["fields"].customClauses !== undefined && paramsExport["fields"].customClauses.length>0)
  //   fieldsArray["customClauses"] =  paramsExport["fields"].customClauses
  // if(paramsExport["fields"] && paramsExport["fields"].others !== undefined && paramsExport["fields"].others.length>0)
  //   fieldsArray["others"] =  paramsExport["fields"].others
  
  formData.append('fields', JSON.stringify(paramsExport["fields"]));
  
  // Display FormData entries in the console
  for (let [key, value] of formData.entries()) {
    console.log(key, value);
  }
  return Axios.post(requestUrl, formData);
};

export const deleteExport = (exportID) => {
  const requestUrl = API_URL+`/user/deleteExport/`+exportID+`?access_token=`+getConnectedUserAccessToken();
  return Axios.delete(requestUrl);
};
