import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ConfirmAlert from '../../../../../../components/confirm-alert/ConfirmAlert';
import NotifyAlert from '../../../../../../components/confirm-alert/notify-alert/NotifyAlert';
import { TrashIcon, SearchIcon, SortIcon, TagIcon } from '../../../assets/icons';
import AdvancedSearch from '../../../../back-office/dash-bord/edit-mode/parts/chart-data/icons/advanced-search.icon';
// API
import {
  deleteUnvalidatedContracts, updateCategory
} from  '../../../../../../api/validateContract';
import ColumnsModal from './parts/ColumnsModal';

import Cookies from "js-cookie";
import ColumnsVisibilityIcon from '../../../../back-office/dash-bord/edit-mode/parts/chart-data/icons/columns-visibility.icon';
import SearchModal from './parts/SearchModal';
import CustomDropdownCategories from './parts/CustomDropdownCategories';
import errorAlert from '../../../../../../components/confirm-alert/error-alert';

const ListingUnValidatedContract = ({
  data: initialData,
  keywordsDB,
  tags,
  getContractBadges,
  handleDoubleClick,
  seachIncontractList,
  removeDuplicateRowsByUlDataIndex,
  columnsList,
  setColumnsList,
  homeTabsSearch
}) => {
    const [data, setData] = useState(initialData);
    const [isSearchModalOpen, setOpenSearchModal] = useState(false);
    const [systemKeywords, setSystemKeyWords] = useState([]);
    const [isDisputedFilter, setIsDisputedFilter] = useState(false);
    const [removeDupplicatedCalled, setRemoveDupplicatedCalled] = useState(0);
    const [isSearchTag, setSearchTag] = useState(false);
    const [isColumnsModalOpen, setOpenColumnsModal] = useState(false);
    const [selectedContractTags, setSelectedContractTags] = useState(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const [defaultContracts, setDefaultContracts] = useState(data);
    
    const getColumns = (columnsList) => {
      if (columnsList && columnsList !== null && columnsList["items"] !== undefined && columnsList["items"].length > 0) {
        const item = columnsList["items"].find(section => section.key === "unvalidated_contracts");
        return item["columns"]
      } else {
        return ['contract_full_name', 'category', 'type', 'status', 'imported_by', 'importation_date', 'tag']
      }
    }
    const [orderContractColumns, setOrderContractColumns] = useState(getColumns(columnsList));
    const loadColumns = () =>{
      let columns = [
        { id: 'contract_full_name', label: t('frontoffice.home.contracts.table.contract_full_name'), isVisible: true, },
        { id: 'category', label: t('frontoffice.home.contracts.table.category'), isVisible: true, },
        { id: 'type', label: t('frontoffice.home.contracts.table.type'), isVisible: true, },
        { id: 'status', label: t('frontoffice.home.contracts.table.status'), isVisible: true, },
        { id: 'imported_by', label: t('frontoffice.home.contracts.table.imported_by'), isVisible: true, },
        { id: 'importation_date', label: t('frontoffice.home.contracts.table.importation_date'), isVisible: true, },
        { id: 'tag', label: t('frontoffice.home.contracts.table.tag'), isVisible: true, },
        // Add more columns as needed
      ]
      const sortedDataArray = columns.sort((a, b) => {
        const isVisibleA = orderContractColumns.includes(a.id);
        const isVisibleB = orderContractColumns.includes(b.id);
  
        // Update isVisible property
        a.isVisible = isVisibleA;
        b.isVisible = isVisibleB;
  
        // Keep the sorting logic
        return orderContractColumns.indexOf(a.id) - orderContractColumns.indexOf(b.id);
      });
      return sortedDataArray
    }
    const [columns, setColumns] = useState(loadColumns());
    const [draggedIndex, setDraggedIndex] = useState(null);

    useEffect(() => {
      const sortedDataArray = columns.sort((a, b) => {
        const isVisibleA = orderContractColumns.includes(a.id);
        const isVisibleB = orderContractColumns.includes(b.id);
  
        // Update isVisible property
        a.isVisible = isVisibleA;
        b.isVisible = isVisibleB;
  
        // Keep the sorting logic
        return orderContractColumns.indexOf(a.id) - orderContractColumns.indexOf(b.id);
      });
      setColumns(sortedDataArray)
    }, [orderContractColumns])
  
    // Uncomment for api save
    useEffect(() => {
      if (columns && columns.length > 0 && !isFirstLoad) {
        const editedColumns = editItemsArray(columns.filter(x => x.isVisible).map(item => item.id))
        setColumnsList(editedColumns)
      }else{
        setIsFirstLoad(false)
      }
    }, [columns])
  
    const editItemsArray = (dataC) => {
      // Find the object with the key "unvalidated_contracts"
      const validatedContractsItem = columnsList.items.find(item => item.key === "unvalidated_contracts");
      // Check if the item is found
      if (validatedContractsItem) {
        // Update the columns for "unvalidated_contracts"
        validatedContractsItem.columns = dataC;
        // Log the updated columnsList array
        return columnsList
      }
    };

    const handleDragStart = (index) => {
      setDraggedIndex(index);
    };
  
    const handleDragOver = (index) => {
      if (draggedIndex === null || index === draggedIndex) {
        return;
      }
  
      const updatedColumns = [...columns];
      const [removed] = updatedColumns.splice(draggedIndex, 1);
      updatedColumns.splice(index, 0, removed);
      setColumns(updatedColumns);
      setDraggedIndex(index);
      setOrderContractColumns(updatedColumns.filter(x => x.isVisible).map(item => item.id))
    };

    const onchangeSystemKeyword = (e) => {
      let id = e.target.value
      let listing = systemKeywords? systemKeywords:[];
      if(e.target.checked){
        listing.push(id)
      }else{
        listing = listing.filter(f => f !== id)
      }
      setSystemKeyWords(listing);
    };
    

    const deleteContracts = (nbConrats) => {

      if (nbConrats == 0) {
          NotifyAlert.show(t('frontoffice.home.contracts.table.delete.delete_msg_empty_contractsIds'), t('frontoffice.home.contracts.table.delete.delete_title'));
      }
      else{
          ConfirmAlert.show(t('frontoffice.home.contracts.table.delete.delete_msg_confirm_contractsIds_part_one')+ nbConrats.length + t('frontoffice.home.contracts.table.delete.delete_msg_confirm_contractsIds_part_two')).then(()=>{
              deleteUnvalidatedContracts(systemKeywords).then((response) => {
                  NotifyAlert.show(t('frontoffice.home.contracts.table.delete.delete_msg_confirm_contractsIds_success_part_one')+' (' +nbConrats.length+') ' +t('frontoffice.home.contracts.table.delete.delete_msg_confirm_contractsIds_success_part_two'), t('frontoffice.home.contracts.table.delete.delete_operation')).then(() => {window.location.reload();});
              });
          }, (e) => {

          }
          );
      }
  }

    useEffect(() => {
      removeDuplicateRowsByUlDataIndex('homeDataTable2')
      setTimeout(function() {
        removeDuplicateRowsByUlDataIndex('homeDataTable2')
      }, 2500);
      
    }, [data,getContractBadges, handleDoubleClick, seachIncontractList, removeDupplicatedCalled, systemKeywords])
    
    const rmDupplicated = () => {
      let cmpt = removeDupplicatedCalled+1 
      setRemoveDupplicatedCalled(cmpt)
    };

    const moreTags = (index, tags) =>{
      if(tags && tags.length>1){
          setSelectedContractTags({
            index: index,
            othertags: [...new Set(tags)].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).slice(1)
          })
        }
    }


  const doSearch = (query) =>{
    console.log(query)
    const filteredContracts = searchContracts(query, defaultContracts);
    console.log(filteredContracts)
    setData(filteredContracts);
  }

  const doClearSearch = () =>{
    setData(defaultContracts);
  }

  
  function searchContracts(searchConfig, contracts) {
    const selectedCategoryIds = searchConfig.fields.filter(field => field.selected).map(field => field.id);
    let selectedKeywords = searchConfig.fields.flatMap(field => {
      return field.keywords.filter(keyword => keyword.selected).map(keyword => ({
        categoryId: field.id,
        keywordId: keyword.id,
        synonyms: keyword.synonyms.filter(synonym => synonym.selected).map(synonym => synonym.name)
      }));
    });

    const selectedSynonyms = searchConfig.fields.flatMap(field => {
      return field.keywords.flatMap(keyword => {
        return keyword.synonyms.filter(synonym => synonym.selected).map(synonym => ({
          categoryId: field.id,
          keywordId: keyword.id,
          synonymName: synonym.name
        }));
      });
    });

    if(selectedSynonyms && selectedSynonyms.length>0){
      // Iterate over selectedSynonyms
      selectedSynonyms.forEach(function (synonym) {
        // Check if there exists a keyword with the same categoryId and keywordId
        var existingKeyword = selectedKeywords.find(function (keyword) {
          return (keyword.categoryId === synonym.categoryId && keyword.keywordId === synonym.keywordId);
        });

        // If no keyword exists, add a new keyword object with an empty synonyms array
        if (!existingKeyword) {
          existingKeyword = {
            "categoryId": synonym.categoryId,
            "keywordId": synonym.keywordId,
            "synonyms": []
          };
          selectedKeywords.push(existingKeyword);
        }

        // Push the synonym into the synonyms array of the existing or newly created keyword
        existingKeyword.synonyms.push(synonym.synonymName);
      });
      console.log(selectedKeywords)
    }

    if (searchConfig.andContains) {
      return contracts.filter(contract => {
        return selectedCategoryIds.every(categoryId => contract.categoryId === categoryId) &&
          selectedKeywords.every(keyword =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.id === keyword.keywordId ||
              keyword.synonyms.some(synonym =>
                contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym)
              )
            )
          ) 
          // &&
          // selectedSynonyms.every(synonym =>
          //   contract.keywords.some(contractKeyword =>
          //     contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym.synonymName)
          //   )
          // );
      });
    } else if (searchConfig.orContains) {
      return contracts.filter(contract => {
        return selectedCategoryIds.some(categoryId => contract.categoryId === categoryId) ||
          selectedKeywords.some(keyword =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.id === keyword.keywordId ||
              keyword.synonyms.some(synonym =>
                contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym)
              )
            )
          ) ||
          selectedSynonyms.some(synonym =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym.synonymName)
            )
          );
      });
    } else if (searchConfig.doesntContain) {
      return contracts.filter(contract => {
        return !selectedCategoryIds.includes(contract.categoryId) &&
          !selectedKeywords.some(keyword =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.id === keyword.keywordId ||
              keyword.synonyms.some(synonym =>
                contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym)
              )
            )
          ) &&
          !selectedSynonyms.some(synonym =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym.synonymName)
            )
          );
      });
    } else {
      // Default behavior: `andContains`
      return contracts.filter(contract => {
        return selectedCategoryIds.every(categoryId => contract.categoryId === categoryId) &&
          selectedKeywords.every(keyword =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.id === keyword.keywordId ||
              keyword.synonyms.some(synonym =>
                contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym)
              )
            )
          ) &&
          selectedSynonyms.every(synonym =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym.synonymName)
            )
          );
      });
    }
  }

  const handleDropdownChangeCategories = (selectedItem) => {
    const contractID = selectedItem["contract"].id;
    const categoryID = selectedItem["selectedOption"].id
    const indexContract = data.findIndex(el=> el.id == contractID);
   
    if(indexContract > -1){
      updateCategory(contractID, categoryID).then(
        res=>{
          data[indexContract].categoryId = res["data"].id
          data[indexContract].category = res["data"].name
          NotifyAlert.show('Catégorie mise à jours avec succès', 'Info');
        },error=>{
          errorAlert.show(error && error.response !== undefined && error.response.data !== undefined ? error.response.data : "Une erreur est survenue lors de la mise à jours de catégorie", 'Erreur API');
        }
      )
    }
  }

    return (
      <>
        <div className="filters">
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-4 search-area-imported-contracts-mobile">
                {!isSearchTag && <div className="search-input">
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder={t('frontoffice.home.contracts.table.search')}
                    onChange={(e) => seachIncontractList(e, 'homeDataTable2')}
                  />
                </div>}
                {isSearchTag && <div className="tag-search">
                  <select
                    className="form-control"
                    name="tags"
                    onChange={(e) => seachIncontractList(e, 'homeDataTable2', true)}
                  >
                    <option value="">
                      Choisissez un tag
                    </option>
                    {tags && tags.map((item) => (
                      <option key={item} value={item}>{item}</option>
                    ))}
                  </select>
                </div>}
              </div>
              <div className="col-sm-12 col-lg-8 hidden-only-on-mobile" style={{textAlign:'end'}}>
              <button className="btn btn-link btn-vertical-content" onClick={() => {
                setOpenSearchModal(!isSearchModalOpen)
              }}>
                <i>
                  <AdvancedSearch />
                </i>
                <span>
                  {t('frontoffice.home.contracts.table.advanced')}
                </span>
              </button>

                <button className="btn btn-link btn-vertical-content" onClick={() => {
                  setOpenColumnsModal(!isColumnsModalOpen)
                }}>
                  <i>
                    <ColumnsVisibilityIcon />
                  </i>
                  <span>
                    {t('frontoffice.home.contracts.table.columns')}
                  </span>
                </button>
                <button className="btn btn-link btn-vertical-content" onClick={() => deleteContracts(systemKeywords)}>
                  <i>
                    <TrashIcon color="#5367F4" />
                  </i>
                  <span>
                    {t('frontoffice.home.contracts.table.delete.delete_button')}
                  </span>
                </button>
              </div>
            </div>
          </div>
      <div className='table-container'>
        <table
          id="homeDataTable2"
          width="100%"
          className="table table-hover"
          data-searching="true"
          data-select="false"
          data-paging="false"
          data-info="false"
        >
          <thead>
            <tr>
              <th data-sorting="false"><input type="checkbox" disabled/></th>
              {columns.map((column, index) => (
                column && column.isVisible && <th
                  key={column.id}
                  draggable
                  onDragStart={() => handleDragStart(index)}
                  onDrop={()=> handleDragOver(index)}
                  onDragOver={(e) => e.preventDefault()}
                >
                  {/* {column.label} */}
                  {t('frontoffice.home.contracts.table.'+column.id)}
                </th>
              ))}
              {/* <th>{t('frontoffice.home.contracts.table.contract_full_name')}</th>
              <th>{t('frontoffice.home.contracts.table.category')}</th>
              <th>{t('frontoffice.home.contracts.table.type')}</th>
              <th>{t('frontoffice.home.contracts.table.status')}</th>
              <th>{t('frontoffice.home.contracts.table.imported_by')}</th>
              <th align="center">{t('frontoffice.home.contracts.table.importation_date')}</th>
              <th align="center">{t('frontoffice.home.contracts.table.tag')}</th> */}
            </tr>
          </thead>
          <tbody>
          {
            isDisputedFilter && data && (data.filter(x=>x.isDisputed)).length <1 &&
              <tr>
                <td colSpan={8}>
                  <p className="no-contrat-in-litige">{t('frontoffice.home.contracts.table.no_litige_found')}</p>
                </td>
              </tr>
          
          }
            {data?.map((contrat, index) => {
              let contractBadges = getContractBadges(contrat);
              return (
                ((isDisputedFilter && contrat.isDisputed) || !isDisputedFilter) &&
                <tr onDoubleClick={() => handleDoubleClick(contrat.hachedId, contrat.avt)} onClick={()=>{removeDuplicateRowsByUlDataIndex('homeDataTable2');rmDupplicated()}}>
                  <td className={contrat.isDisputed ? "disputed-row":""}>
                  <input type="checkbox" value={contrat.id} onChange={onchangeSystemKeyword} />
                  </td>
                    {columns.map((column) =>
                      <>
                        {column && column.isVisible && column.id == "contract_full_name" && <td>{contrat.name ? contrat.name:contrat.fileName}</td>}
                        {column && column.isVisible && column.id == "category" && <td className="affectation-categories-select ddlist">
                       
                          <CustomDropdownCategories
                            contract={contrat}
                            disabled={contrat.avt === 1 || contrat.accessType !== "UPDATE"}
                            options={contrat.allowedCategories || (contrat.avt === 1 ? { id: contrat["categoryId"], name: contrat["category"] } : []) || []}
                            onSelect={handleDropdownChangeCategories} />

                          </td>}
                        {column && column.isVisible && column.id == "type" && <td>
                          {contrat.avt === 1 ? <strong className='av-style'>{t('frontoffice.home.contracts.table.endorsement')}</strong> : <strong className='no-style'></strong>}
                          {contrat.avt === 0 ? <strong className='co-style'>{t('frontoffice.home.contracts.table.contract')}</strong> : <strong className='no-style'></strong>}
                        </td>}
                        {column && column.isVisible && column.id == "status" && <td>{contrat.status && contrat.status.toString() === "3" ? <small className="sign_in_progress">{t('frontoffice.home.contracts.table.sign_in_progress')+" "}{(contrat.signaturesCount)?("("+contrat.signaturesCount['count']+"/"+contrat.signaturesCount['total']+")"):""}</small>:<small className="sign_empty">{t('frontoffice.home.contracts.table.pending')}</small>}</td>}
                        {column && column.isVisible && column.id == "imported_by" && <td>{contrat.importedBy}</td>}
                        {column && column.isVisible && column.id == "importation_date" && <td
                          align="center"
                          className={contractBadges[0]}
                          data-order={moment(contrat.importedAt, 'DD/MM/YYYY HH:mm:ss').toDate().getTime()}
                          >
                          {contrat.importedAt}
                        </td>}
                        {column && column.isVisible && column.id == "tag" && <td align="center" className='tags small' onMouseEnter={()=>moreTags(index, [...new Set(contrat.tags)])} onMouseLeave={()=> setSelectedContractTags(null)} data-search={contrat.tags && contrat?.tags.length > 0 ? ("tags #"+([...new Set(contrat.tags)].join(" #"))).toString() :""}> 
                          { contrat.tags && contrat?.tags.length > 0 &&
                            <span className={'color'}>
                              {'#'+[...new Set(contrat.tags)].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))[0]}
                              {contrat.tags && [...new Set(contrat.tags)].length > 1 &&
                                <small>
                                  {selectedContractTags !== null && selectedContractTags["index"] === index  ? "-" : ("+"+([...new Set(contrat.tags)].length-1))}
                                </small>
                              }
                            </span>
                          }
                          {selectedContractTags !== null && selectedContractTags["index"] === index && selectedContractTags.othertags && selectedContractTags.othertags.map(othertag=>{
                            return <span className={'color3'}>{'#' + othertag}</span>
                          })}
                        </td>}
                      </>
                    )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <SearchModal isOpen={isSearchModalOpen} keywordsDB={keywordsDB} onRequestClose={() => setOpenSearchModal(false)} doSearch={doSearch} doClearSearch={doClearSearch} tabKey={"unvalidated_contracts"} homeTabsSearch={homeTabsSearch}/>
        <ColumnsModal isOpen={isColumnsModalOpen} onRequestClose={() => setOpenColumnsModal(false)} columns={columns} setColumns={setColumns} setOrderContractColumns={setOrderContractColumns}/>
      </div>
      </>
    )
      };

export default withTranslation()(ListingUnValidatedContract);
