import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Modal from '../../../../../../components/Modal';
import { CopyIcon } from '../../../assets/icons';
import "./PricePreviewModal.scss";


const PricePreviewModal = ({ isOpen, onRequestClose, initPrice }) => {

  const [indice_N, setIndice_N] = useState(0)
  const [indice_Zero, setIndice_Zero] = useState(0)
  const [previwedPrice, setPreviwedPrice] = useState(0)
  const [copied, setCopied] = useState(false);

  const onchangeIndice = (e, typeIndice) => {
    if (typeIndice === "indice_N") {
      setIndice_N(e.target.value)
    }
    if (typeIndice === "indice_Zero") {
      setIndice_Zero(e.target.value)
    }
  };

  useEffect(() => {
    doCalcul()
  }, [indice_N, indice_Zero])


  const doCalcul = () => {
    let price = (parseFloat(initPrice) * (parseFloat(indice_N) / parseFloat(indice_Zero)));
    if (parseFloat(indice_Zero) === 0 || isNaN(price))
      price = 0
    setPreviwedPrice(price.toFixed(2));
  }

  const copyToClipboard = () => {
    // Create a temporary textarea element
    const tempTextArea = document.createElement('textarea');
    // Assign the text to be copied
    tempTextArea.value = previwedPrice;
    // Append the textarea to the document
    document.body.appendChild(tempTextArea);
    // Select the text
    tempTextArea.select();
    // Copy the text to the clipboard
    document.execCommand('copy');
    // Remove the temporary textarea
    document.body.removeChild(tempTextArea);
    // Set copied to true
    setCopied(true);
    // Reset copied state after a short delay
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }


  return (
    <Modal
      title={"Simulation de révision de prix"}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      isFullScreen={false}
    >
      <div>
        <p>
          Évolution des indices depuis la date de validation du contrat :
        </p>


        <table className="price-review-modal">
          <tbody>
            <tr>
              <td className="zone-calc">
                <div>
                  <a class="ui teal ribbon label">Zone de calcules</a>
                  <ul className="export-contracts-fiels p-4">
                    <li>
                      <strong className="mr-2">
                        <span className="text-danger">*</span> Formulaire de calcule:
                      </strong>
                      <span className="font-weight-bold text-danger">Pn = Po x (In / Io)</span>
                      <p>
                      </p>
                      <div className="legend-formulas">
                        <ul>
                          <li>"I" = indice de prix </li>
                          <li>"P" = prix </li>
                          <li>"o" = période de départ </li>
                          <li>"n" = la période d'arrivée </li>
                          <li> Pn = le prix HT après révision </li>
                          <li> Po = le prix HT initial </li>
                          <li> In = la dernière valeur connue de l'indice XXX publiée par XXXX à la date de la révision </li>
                          <li> Io = la dernière valeur connue de l'indice XXX publiée par XXXX à la date de remise des offres </li>

                        </ul>
                      </div>
                      <hr />
                    </li>
                    <li>
                      <div className="content-ios-7">
                        <input type="checkbox" name="andContains" className="sw" id="toggle-1" disabled={true}
                          checked={true} />
                        <label for="toggle-1"><span>Définir les indices de calcules</span>
                        </label>
                      </div>
                      <hr />
                    </li>
                    <li className="d-flex m-auto">
                      <div className="input-item input-item-styled mx-2">
                        <label>
                          I<sub>n</sub>
                        </label>
                        <div className="d-flex">
                          <input
                            required
                            type="number"
                            className="form-control"
                            name="libelle"
                            value={indice_N}
                            placeholder="Veuilez saisir l'indice In"
                            onChange={(e) => onchangeIndice(e, "indice_N")}
                          />
                        </div>
                      </div>
                      <div className="input-item input-item-styled mx-2">
                        <label>
                          I<sub>0</sub>
                        </label>
                        <div className="d-flex">
                          <input
                            required
                            type="number"
                            className="form-control"
                            name="libelle"
                            value={indice_Zero}
                            placeholder="Veuilez saisir l'indice Io"
                            onChange={(e) => onchangeIndice(e, "indice_Zero")}
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </td>
              <td></td>
              <td className="zone-result" width="50%">
                <div>
                  <a class="ui teal ribbon label">Résultats</a>
                  <p className="result-price-label" onClick={copyToClipboard}>
                    <CopyIcon />
                    <span className="ml-2">Prix révisé</span>
                  </p>
                  <p className="result-price">
                    <span className="price">{previwedPrice}</span><span className="currency">€</span>
                  </p>
                  {copied && <p className="text-copied">Copié en press-papier!</p>}
                  <hr />
                  <p>
                    Vous pouvez avoir les indices de révision à partir des liens ci-dessus
                  </p>
                  <hr />
                  <ul>
                    <li><a href="https://www.data.gouv.fr/en/datasets/prix-et-indices-de-prix" title="« data.gouv.fr » dans une nouvelle fenêtre" target="_blank" rel="noopener">Prix et indices de prix</a> (data.gouv.fr)</li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>


      </div>
    </Modal>
  );
};

export default withTranslation()(PricePreviewModal);
