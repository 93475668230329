import React, { useEffect, useState } from "react";

//css
import "./chart-data.scss";

//icons
import GroupIcon from "./icons/group.icon";
import FilterIcon from "./icons/filter.icon";
import DeleteIcon from "./icons/delete.icon";
import CheckboxIcon from "./icons/checkbox.icon";
import RadioIcon from "./icons/Radio.icon";
import PaletteIcon from "./icons/palette.icon";

const DataItem = ({
  editable,
  label,
  activeGroup,
  activeFilter,
  onToggleGroup,
  onToggleFilter,
  onPressDelete,
  data,
  isMultipleSelection,
  onChangeData,
  onToogleColorPalette,
  color,
}) => (
  <div className="dash-bord-data-item-container">
    <div
      className="dash-bord-data-item"
      style={color ? { borderColor: color } : {}}
    >
      <div className="d-flex">
        <label>{label}</label>
        <div className="dash-bord-data-item-action d-flex">
          {onToggleGroup ? (
            <Button
              icon={<GroupIcon />}
              active={activeGroup}
              onPress={editable ? onToggleGroup : null}
            />
          ) : null}

          {onToggleFilter && isMultipleSelection ? (
            <Button
              icon={<FilterIcon />}
              active={activeFilter}
              onPress={editable ? onToggleFilter : null}
            />
          ) : null}

          {onToogleColorPalette && color ? (
            <Button
              icon={<PaletteIcon />}
              active={true}
              onPress={editable ? onToogleColorPalette : null}
            />
          ) : null}

          {onPressDelete && editable ? <ButtonDelete onPress={onPressDelete} /> : null}
        </div>
      </div>
    </div>
    {data ? (
      <SelectionList
        editable={editable}
        data={data?.filter(item => !item.selected && activeFilter ? false : true )}
        onChange={onChangeData}
        isMultipleSelection={isMultipleSelection}
      />
    ) : null}
  </div>
);

const Button = ({ icon, active, onPress }) => (
  <button
    className={`btn btn-light ${active ? "active" : ""}`}
    onClick={() => onPress ? onPress(!active) : null}
  >
    {icon}
  </button>
);

const ButtonDelete = ({ onPress }) => (
  <button className="btn btn-danger btn-delete" onClick={onPress}>
    <DeleteIcon />
  </button>
);

const SelectionList = ({ editable, data, onChange, isMultipleSelection }) => {
  const [__data, setData] = useState(data);
  const [isSelectedAll, setSelectedAll] = useState(false);

  const selectItem = (index) => {
    let newData = __data?.map((item, i) =>
      index === i ? { ...item, selected: !item.selected } : isMultipleSelection ? item : {...item, selected: false}
    );
    setData(newData);
    setSelectedAll(false);
    setTimeout(() => (onChange ? onChange(newData) : null));
  };

  const selectAll = () => {
    let newData = __data?.map((item, i) => {
      return { ...item, selected: !isSelectedAll };
    });
    setData(newData);
    setSelectedAll(!isSelectedAll);
    setTimeout(() => onChange(newData));
  };

  useEffect(() => {
    setData(data);
  }, [data]);

  return (
    <div className="dash-bord-data-item-selection">
      <ul>
        {
          data.length === 0 &&
          <li className="text-center text-danger font-weight-light">Vide</li>
        }
        {isMultipleSelection && data.length > 0 && (
          <li className="d-flex" onClick={editable ? selectAll : null}>
            <span>
              <CheckboxIcon checked={isSelectedAll} />
            </span>
            <label>Tout</label>
          </li>
        )}

        {__data?.map((item, index) => (
          <li key={index} className="d-flex" onClick={editable ? () => selectItem(index) : null}>
            <span>
              {isMultipleSelection ? (
                <CheckboxIcon checked={item.selected} />
              ):(
                <RadioIcon checked={item.selected} />
              )}
            </span>
            <label>
            {(item.isRGPD ? "RGPD": item.name)} {item.count ? `(${item.count})` : ""}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DataItem;
