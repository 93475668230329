import React, { Component } from 'react';
import "./DataTable.scss"

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedColumn: null,
      sortOrder: 'asc',
      currentPage: 1,
      itemsPerPage: 5,
      itemsPerPageOptions: [5, 10, 20, 30, 40, 50, 70, 100],
      selectedRows: [], // Array to store selected row indices
      sortedData: null
    };
  }

  handleSort = (columnName, sortable) => {
    console.log("columnName",columnName)
    console.log("sortable",sortable)
    if (!sortable) return;

    this.setState((prevState) => {
      const { sortedColumn, sortOrder } = prevState;
      const { data } = this.props;

      const newSortOrder = sortedColumn === columnName ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc';

      const sortedData = [...data].sort((a, b) => {
        const aValue = a[columnName];
        const bValue = b[columnName];

        if (aValue < bValue) return newSortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return newSortOrder === 'asc' ? 1 : -1;
        return 0;
      });
      
      return {
        sortedColumn: columnName,
        sortOrder: newSortOrder,
        selectedRows: [], // Clear selected rows when sorting
        sortedData: sortedData
      };
    });
  };

  handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    this.setState({ itemsPerPage: newItemsPerPage, currentPage: 1 });
  };


  handlePageChange = (page, event) => {
    event.preventDefault();
    this.setState({ currentPage: page }); // Clear selected rows when changing page
  };

  handleRowSelect = (rowIndex, getway) => {
    if(getway == "index"){
      this.setState((prevState) => {
        const { selectedRows } = prevState;
  
        // Toggle selected row
        const newSelectedRows = selectedRows.includes(rowIndex)
          ? selectedRows.filter((index) => index !== rowIndex)
          : [...selectedRows, rowIndex];
  
        return {
          selectedRows: newSelectedRows,
        };
      },
      () => {
        const { onSelectedRowsChange } = this.props;
        if (onSelectedRowsChange) {
          onSelectedRowsChange(this.state.selectedRows);
        }
      });
      
    }else{
      this.setState((prevState) => {
        const { selectedRows } = prevState;

        // Toggle selected row using item.id
        const newSelectedRows = selectedRows.includes(rowIndex)
          ? selectedRows.filter((id) => id !== rowIndex)
          : [...selectedRows, rowIndex];

        return {
          selectedRows: newSelectedRows,
        };
      },
      () => {
        const { onSelectedRowsChange } = this.props;
        if (onSelectedRowsChange) {
          onSelectedRowsChange(this.state.selectedRows);
        }
      });

    }
  };

  handleAllRowsSelect = (dataItems) => {
    if(dataItems && this.state.selectedRows && dataItems.length == this.state.selectedRows.length && this.state.selectedRows.length>0){
      this.setState((prevState) => {
        return {
          selectedRows: []
        };
      },
      () => {
        const { onSelectedRowsChange } = this.props;
        if (onSelectedRowsChange) {
          onSelectedRowsChange(this.state.selectedRows);
        }
      });
    }else{
      this.setState((prevState) => {
        return {
          selectedRows: dataItems.map(obj => obj.id),
        };
      },
      () => {
        const { onSelectedRowsChange } = this.props;
        if (onSelectedRowsChange) {
          onSelectedRowsChange(this.state.selectedRows);
        }
      });
    }
  };

  componentDidUpdate(prevProps) {
    // Check if the data prop has changed
    if (prevProps.data !== this.props.data) {
      this.setState({
        currentPage: 1, // Reset current page to 1
        selectedRows: [], // Clear selected rows
        sortedData: null, // Clear sortedData
      });
    }else if(prevProps.resetCheckedRows !==  undefined && this.props.resetCheckedRows !== undefined && prevProps.resetCheckedRows !== this.props.resetCheckedRows){
      this.setState({
        ...this.state,
        currentPage: 1, // Reset current page to 1
        selectedRows: [], // Clear selected rows
      });
    }
  }

  render() {
    const { sortedColumn, sortOrder, sortedData, currentPage, itemsPerPage, selectedRows, itemsPerPageOptions } = this.state;
    const { data, columns, selectable, allRowsSortable, loading, isFirstLoad } = this.props;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const totalItems = (sortedData || data).length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const currentItems = (sortedData || data).slice(indexOfFirstItem, indexOfLastItem); // Use sortedData if available


    return (
      <div className='custom-table'>
        <table className='table'> 
          <thead>
            <tr>
              {selectable && 
                <th>
                  <input
                    type="checkbox"
                    checked={data && selectedRows && data.length == selectedRows.length && selectedRows.length>0 }
                    onChange={() => this.handleAllRowsSelect(data)}
                  />
                </th>
              }
              {columns.map((column, index) => (
                <th
                  key={index}
                  onClick={() => this.handleSort(column.name, column.sortable || allRowsSortable)}
                  style={{ cursor: column.sortable || allRowsSortable ? 'pointer' : 'default' }}
                >
                  {(!sortedColumn ||  sortedColumn  !== column.name) && <i className="fa fa-sort mr-1" aria-hidden="true"></i>}
                  {sortedColumn  == column.name && sortOrder == "asc" && <i className="fa fa-arrow-up mr-1" aria-hidden="true"></i>}
                  {sortedColumn  == column.name && sortOrder == "desc" && <i className="fa fa-arrow-down mr-1" aria-hidden="true"></i>}
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {
            (!currentItems || currentItems && currentItems.length == 0) &&
              <tr><td className='text-center text-danger' colSpan={1 + columns.length}>{loading ? 
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="translate(50 50)">
                      <g>
                        <animateTransform attributeName="transform" type="rotate" calcMode="discrete" values="0;90;180;270;360" keyTimes="0;0.25;0.5;0.75;1" dur="2.5s" repeatCount="indefinite"></animateTransform>
                        <path d="M-40 0A40 40 0 1 0 40 0" fill="#f66031">
                          <animate attributeName="fill" calcMode="discrete" values="#f66031;#260c4e;#f66031;#260c4e;#f66031" keyTimes="0;0.24;0.49;0.74;0.99" dur="2.5s" repeatCount="indefinite"></animate>
                        </path>
                        <path d="M-40 0A40 40 0 0 1 40 0" fill="#260c4e">
                          <animate attributeName="fill" calcMode="discrete" values="#260c4e;#f66031;#260c4e;#f66031;#260c4e" keyTimes="0;0.25;0.5;0.75;1" dur="2.5s" repeatCount="indefinite"></animate>
                        </path>
                        <path d="M-39 0L39 0" stroke="#c63609" strokeWidth="2">
                          <animate attributeName="stroke" values="#f66031;#c63609;#1b0837;#260c4e;#1b0837;#c63609;#f66031;#c63609;#1b0837;#260c4e;#1b0837;#c63609;#f66031" keyTimes="0;0.124;0.125;0.25;0.374;0.375;0.5;0.624;0.625;0.75;0.874;0.875;1" dur="2.5s" repeatCount="indefinite"></animate>
                        </path>
                        <g>
                          <path d="M-40 0A40 40 0 0 1 40 0Z" fill="#c63609">
                            <animate attributeName="fill" values="#f66031;#c63609;#1b0837;#260c4e;#1b0837;#c63609;#f66031;#c63609;#1b0837;#260c4e;#1b0837;#c63609;#f66031" keyTimes="0;0.124;0.125;0.25;0.374;0.375;0.5;0.624;0.625;0.75;0.874;0.875;1" dur="2.5s" repeatCount="indefinite"></animate>
                            <animateTransform attributeName="transform" type="scale" values="1 1;1 0;1 -1;1 1" keyTimes="0;0.5;0.999;1" dur="0.625s" repeatCount="indefinite"></animateTransform>
                          </path></g>
                      </g>
                    </g>
                  </svg>
                : (isFirstLoad ? "........................................": "Aucun résultat trouvé")}</td></tr>
            }
            {currentItems.map((item, index) => (
              <tr key={index}  className={(index%2 == 0) ? "colored-tr":""}>
                {selectable && 
                  <td>
                  {item.id == undefined &&
                    <input
                    type="checkbox"
                    checked={selectedRows.includes(index)}
                    onChange={() => this.handleRowSelect(index, "index")}
                  />}
                  {item.id !== undefined &&
                    <input
                    type="checkbox"
                    checked={selectedRows.includes(item.id)}
                    onChange={() => this.handleRowSelect(item.id, "id")}
                  />}
                </td>}  
                {columns.map((column, columnIndex) => (
                  <td key={columnIndex}>{item[column.name]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {currentItems && currentItems.length > 0 &&
          <div className='footer-dt'>
            <div className="items-per-page-dropdown mx-2">
              <label htmlFor="itemsPerPage">Éléments par page:</label>
              <select id="itemsPerPage" value={itemsPerPage} onChange={this.handleItemsPerPageChange}>
                {itemsPerPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className='items-page-navigation mx-2'>
              <button className="mr-1" onClick={(event) => this.handlePageChange(1, event)} disabled={currentPage === 1}>
                <i className="fa fa-step-backward" aria-hidden="true"></i>
              </button>
              <button onClick={(event) => this.handlePageChange(currentPage - 1, event)} disabled={currentPage === 1}>
                <i className="fa fa-backward" aria-hidden="true"></i>
              </button>
              <span>{currentPage} sur {totalPages}</span>
              <button
                onClick={(event) => this.handlePageChange(currentPage + 1, event)}
                disabled={indexOfLastItem >= data.length}
              >
                <i className="fa fa-forward" aria-hidden="true"></i>
              </button>
              <button className="ml-1" onClick={(event) => this.handlePageChange(totalPages, event)} disabled={currentPage === totalPages}>
                <i className="fa fa-step-forward" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default DataTable;
