import React, { useEffect, useState } from 'react';

import { t } from 'i18next';

import { withTranslation } from 'react-i18next';
import "./PassRulesMeasure.scss"
import PassGeneratorModal from '../PassGeneratorModal';


const PassRulesMeasure = ({ password, userPasswordConfiguration, onGetCopiedPass }) => {

    const [showPASSGenModal, setShowPASSGenModal] = useState(false)

    const calculateStrength = (password) => {
        let strength = 0;
        password = password || ""
        if (password.length >= 6) strength += 1;
        if (/[A-Z]/.test(password)) strength += 1;
        if (/[a-z]/.test(password)) strength += 1;
        if (/\d/.test(password)) strength += 1;
        if (/[!@#$%^&*()_+\[\]{}|;:,.<>?]/.test(password)) strength += 1;
        return strength;
    }

    const strength = calculateStrength(password);
    const strengthLevels = [
        '',
        t('password_generator.strengthLevels.very_weak'),
        t('password_generator.strengthLevels.weak'), 
        t('password_generator.strengthLevels.good'), 
        t('password_generator.strengthLevels.strong'), 
        t('password_generator.strengthLevels.very_strong')
    ]; //['', 'Very Weak', 'Weak', 'Good', 'Strong', 'Very Strong']
    const strengthLabel = strengthLevels[strength];
    const widthPercentage = (strength / 5) * 100;
    return (
        <div className="pass-measures">
            <div className="strength-bar">
                <div
                    className={`strength-indicator strength-${strength}`}
                    style={{ width: `${widthPercentage}%` }}
                >
                    <span className="strength-label">{strengthLabel}</span>
                </div>
                {!strengthLabel && <span className="empty-strength-label">{t('password_generator.strength_indicator')}</span>}
            </div>
            {/* <div className="strength-label">{strengthLabel}</div> */}

            {password &&
                <div className="passwords_rules">
                    <ul>
                        <li>
                            {(password.length >= 6) && <i className="fa fa-check text-success mr-1" aria-hidden="true"></i>}
                            {(password.length < 6) && <i className="fa fa-times text-danger mr-1" aria-hidden="true"></i>}
                            {t('password_generator.passwords_rules.chars_length')}
                        </li>
                        <li>
                            {(/[a-z]/.test(password)) && <i className="fa fa-check text-success mr-1" aria-hidden="true"></i>}
                            {!(/[a-z]/.test(password)) && <i className="fa fa-times text-danger mr-1" aria-hidden="true"></i>}
                            {t('password_generator.passwords_rules.lowercase')}
                        </li>
                        <li>
                            {(/[A-Z]/.test(password)) && <i className="fa fa-check text-success mr-1" aria-hidden="true"></i>}
                            {!(/[A-Z]/.test(password)) && <i className="fa fa-times text-danger mr-1" aria-hidden="true"></i>}
                            {t('password_generator.passwords_rules.uppercase')}
                        </li>
                        <li>
                            {(/\d/.test(password)) && <i className="fa fa-check text-success mr-1" aria-hidden="true"></i>}
                            {!(/\d/.test(password)) && <i className="fa fa-times text-danger mr-1" aria-hidden="true"></i>}
                            {t('password_generator.passwords_rules.numbers')}
                        </li>
                        <li>
                            {(/[!@#$%^&*()_+\[\]{}|;:,.<>?]/.test(password)) && <i className="fa fa-check text-success mr-1" aria-hidden="true"></i>}
                            {!(/[!@#$%^&*()_+\[\]{}|;:,.<>?]/.test(password)) && <i className="fa fa-times text-danger mr-1" aria-hidden="true"></i>}
                            {t('password_generator.passwords_rules.sp_chars')}
                        </li>
                    </ul>
                </div>
            }
            <p onClick={() => setShowPASSGenModal(!showPASSGenModal)} className="password-generator">

                <svg width="20" height="20" viewBox="0 0 106 106" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                    <path d="M81.875 93.8333C80.5237 100.49 74.6386 105.5 67.5833 105.5H15.0833C7.02918 105.5 0.5 98.9708 0.5 90.9167V50.0833C0.5 43.028 5.51013 37.1429 12.1667 35.7917V29.6667C12.1667 13.5584 25.225 0.5 41.3333 0.5C57.4416 0.5 70.5 13.5584 70.5 29.6667V35.7917C77.1565 37.1429 82.1667 43.028 82.1667 50.0833V58.8333H90.9167C98.9708 58.8333 105.5 65.3625 105.5 73.4167V79.25C105.5 87.3042 98.9708 93.8333 90.9167 93.8333H81.875ZM75.8354 93.8333H50.0833C42.0292 93.8333 35.5 87.3042 35.5 79.25V73.4167C35.5 65.3625 42.0292 58.8333 50.0833 58.8333H76.3333V50.0833C76.3333 45.4778 72.7752 41.7034 68.258 41.359C68.0414 41.4102 67.8156 41.4374 67.5833 41.4374C67.3151 41.4374 67.0553 41.4011 66.8085 41.3333H15.0833C10.2508 41.3333 6.33333 45.2508 6.33333 50.0833V90.9167C6.33333 95.7492 10.2508 99.6667 15.0833 99.6667H67.5833C71.3931 99.6667 74.6343 97.2318 75.8354 93.8333ZM18 35.5H64.6667V29.6667C64.6667 16.78 54.22 6.33333 41.3333 6.33333C28.4467 6.33333 18 16.78 18 29.6667V35.5ZM99.6667 79.25V73.4167C99.6667 68.5842 95.7492 64.6667 90.9167 64.6667H50.0833C45.2508 64.6667 41.3333 68.5842 41.3333 73.4167V79.25C41.3333 84.0825 45.2508 88 50.0833 88H90.9167C95.7492 88 99.6667 84.0825 99.6667 79.25ZM50.0833 70.5H55.9167C57.5275 70.5 58.8333 71.8058 58.8333 73.4167V79.25C58.8333 80.8608 57.5275 82.1667 55.9167 82.1667H50.0833C48.4725 82.1667 47.1667 80.8608 47.1667 79.25V73.4167C47.1667 71.8058 48.4725 70.5 50.0833 70.5ZM67.5833 70.5H73.4167C75.0275 70.5 76.3333 71.8058 76.3333 73.4167V79.25C76.3333 80.8608 75.0275 82.1667 73.4167 82.1667H67.5833C65.9725 82.1667 64.6667 80.8608 64.6667 79.25V73.4167C64.6667 71.8058 65.9725 70.5 67.5833 70.5ZM85.0833 70.5H90.9167C92.5275 70.5 93.8333 71.8058 93.8333 73.4167V79.25C93.8333 80.8608 92.5275 82.1667 90.9167 82.1667H85.0833C83.4725 82.1667 82.1667 80.8608 82.1667 79.25V73.4167C82.1667 71.8058 83.4725 70.5 85.0833 70.5Z" fill="black" />
                </svg>
                {t('password_generator.use_pass_generator')}
            </p>
            <PassGeneratorModal isOpen={showPASSGenModal} userPasswordConfiguration={userPasswordConfiguration} onRequestClose={() => { setShowPASSGenModal(false) }} onGetCopiedPass={onGetCopiedPass} />
        </div>
    );
};

export default withTranslation()(PassRulesMeasure);
