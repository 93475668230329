import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../../components/Modal';
import { withTranslation } from 'react-i18next';
import { SearchIcon } from '../../../../assets/icons';


const ContractsReadyToAssociateModal = ({ isOpen, onRequestClose, contractsToLink, setContracts, currentDocument, onSaveLinkedContracts, setSelectedCategory }) => {
  const [searchingkey, setSearchingkey] = useState("");
  const onChangeContractLinkStatus = (e, index, field) => {
    let linked = e.target.checked;
    let idsToLink = null
    if(contractsToLink && contractsToLink !== undefined){
      idsToLink = contractsToLink.filter(c=>c.linked).map((item)=>{return item.id})
    }
    
    let listing = contractsToLink.map((item, i) => {
      const itemId = item.id;
      return { ...item, linked: (itemId === field.id) ? linked : (item.linked !== undefined ? item.linked:false) };
    });
    
    if((idsToLink === null || idsToLink.length === 0) && linked ) {
      setSelectedCategory(listing[index].category["id"])
    }
    if(!listing.some(c=>c.linked) && !linked ) {
      setSelectedCategory(null)
    }
    setContracts(listing);
  };
  


  return (
    <Modal
      title={"Nouvelle liaison"}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      isFullScreen={false}
      footer={
        <>
          <button className='m-auto' onClick={() => onSaveLinkedContracts()}>OK</button>
          <button className='m-auto' onClick={() => onRequestClose(false)}>IGNORER</button>
        </>
      }
    >
      <table className="linked-docs-modal w-auto">
        <tbody>
          <tr>
            <td className="zone-details">
              <div>
                <div className="title-search-bar">
                  <p className="text-center font-weight-bold">
                    Veuilez selectionner le ou les contrats que vous souhaitez associer à ce document
                  </p>
                  <div className="search-input search-bar">
                    <SearchIcon />
                    <input
                      className="form-control"
                      type="text"
                      placeholder={"Recherche par nom de contrat"}
                      onChange={(e) => {
                        setSearchingkey(e && e.target !== undefined && e.target.value !== undefined && e.target.value !== null ? e.target.value : "")
                      }}
                    />
                  </div>
                  <hr/>
                </div>
                {/* <a class="ui teal ribbon label">Détails</a> */}
                <ul className="linked-doc-fiels p-4">
                  {contractsToLink.map((field, key) => {
                    if (field && field.name.toLowerCase().includes(searchingkey.toLowerCase()))
                      return (
                        <li key={key}>
                          <label className="label-radio-check">
                            <input
                              type="checkbox"
                              disabled={currentDocument && currentDocument.linkedContracts !== undefined && currentDocument.linkedContracts.length > 0 && currentDocument.linkedContracts.find(l => l.id === field.id)}
                              checked={(field.linked && field.linked !== undefined && field.linked !== false) || (currentDocument && currentDocument.linkedContracts !== undefined && currentDocument.linkedContracts.length > 0 && currentDocument.linkedContracts.find(l => l.id === field.id))}
                              onChange={(e) => onChangeContractLinkStatus(e, key, field)}
                            />
                            <span className={!field.name && 'text-danger'}>
                              {field.name ? field.name : "Sans nom"}
                              <small className="ml-3 badge-category">
                                <small>Catégorie: </small>
                                {field.category.name}
                              </small>
                            </span>
                          </label>
                        </li>
                      )
                  }
                  )}
                  {
                    contractsToLink && contractsToLink.length > 0 && searchingkey.trim() !== "" && !contractsToLink.some(obj => obj.name.toLowerCase().includes(searchingkey.toLowerCase())) &&
                    <p className="text-danger text-center">Aucun résultat trouvée</p>
                  }
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default withTranslation()(ContractsReadyToAssociateModal);
