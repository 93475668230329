import React from "react";
import { Chart } from "react-google-charts";

const ChartBar = ({ title, subTitle, data, displayLegend, colors }) => (
  <Chart
    chartType="ColumnChart"
    width="100%"
    height="100%"
    data={data}
    options={{
      chart: {
        title: title,
        subtitle: subTitle,
      },
      colors: colors.flat(),
      legend: { position: displayLegend ? "right" : "none" },
      animation: {
        startup: true,
        easing: 'linear',
        duration: 500,
      }
    }}
  />
);

export default ChartBar;


// import React from "react";
// import { Chart } from "react-google-charts";
// const svgString = require('./icons/hatched-pattern.svg').default.toString();
// const dataUrl = `data:image/svg+xml;base64,${btoa(encodeURIComponent(svgString))}`;

// const ChartBar = ({ title, subTitle, data, displayLegend, colors }) => {
//   data = [  ['Month', 'Total', 'Litige'],
//   ['Jan', 50, 70],
//   ['Feb', 60, 80],
//   ['Mar', 0, 90],
//   ['Apr', 80, 100],
//   ['May', 190, 0],
// ];
//   return(
//     <Chart
//       chartType="ColumnChart"
//       width="100%"
//       height="100%"
//       data={data}
//       options={{
//         seriesType: 'bars',
//         series: {
//           0: { type: 'bars', targetAxisIndex: 0, axis: 'Total', color: 'blue'},
//           1: { type: 'bars', targetAxisIndex: 0, axis: 'Litige', color: 'blue', backgroundColor: {pattern: {src: dataUrl}} },
//         },
//         vAxes: {
//           0: { title: 'Value', minValue: 0 },
//           1: { title: 'Percentage', maxValue: 100, format: '##\'%\'' },
//         },
//         hAxis: { title: 'Month' },
//         isStacked: true,
//         chart: {
//           title: title,
//           subtitle: subTitle,
//         },
//         legend: { position: displayLegend ? "right" : "none" },
//       }}
//     />
//   )
// };

// export default ChartBar;
