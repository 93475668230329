import React from "react";
import { CibleIcon } from "../../../assets/icons";
const ButtonRadioChecked = ({ onPress, disabled}) => (
  <button
    type="button"
    className={`btn btn-default button_radio_custom`}
    disabled={disabled}
    onClick={onPress}
  >
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.5" cy="10.5" r="10" fill="#FAFCFE" stroke="#190734" />
      <circle cx="10.5" cy="10.5" r="7.5" fill="#F66031" />
    </svg>

  </button>
);

export default ButtonRadioChecked;
