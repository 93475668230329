import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"

export const getDocAtWebKeywords = () => {
    const requestUrl = API_URL+`/docAtWebKeyword?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

export const createDocAtWebKeyword = (docAtWebKeywordForm) => {
    var index;
    var addedSynonym = false;
    const requestUrl = API_URL+`/docAtWebKeyword?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    if(docAtWebKeywordForm.id) {
        formData.append('id', docAtWebKeywordForm.id);
    }
    formData.append('name', docAtWebKeywordForm.name);
    if (docAtWebKeywordForm.comment) {
        formData.append('comment', docAtWebKeywordForm.comment);
    }

    if (docAtWebKeywordForm.isObligatory) {
        formData.append('isObligatory', 1);
    } else {
        formData.append('isObligatory', 0);
    }

    if (docAtWebKeywordForm.isDate) {
        formData.append('isDate', 1);
    }else {
        formData.append('isDate', 0);
    }

    if (docAtWebKeywordForm.isFirstPage) {
        formData.append('isFirstPage', 1);
    }else {
        formData.append('isFirstPage', 0);
    }

    //formData.append('client', getCurrentClientID());
    if (docAtWebKeywordForm.synonyms) {
        if(docAtWebKeywordForm.synonyms && docAtWebKeywordForm.synonyms.length) {
            for (index = 0; index < docAtWebKeywordForm.synonyms.length; ++index) {
                if(!docAtWebKeywordForm.synonyms[index].unselected || docAtWebKeywordForm.synonyms[index].unselected == false ) {
                    formData.append('synonyms['+ index +']', JSON.stringify({label: docAtWebKeywordForm.synonyms[index].label, priority: docAtWebKeywordForm.synonyms[index].priority}));
                    addedSynonym = true;
                }
            }
        }
        if (addedSynonym == false) {
            formData.append('synonyms', []);
          }

    }

    return axios.post(requestUrl, formData);
}

export const deleteDocAtWebKeyword = (keywordID) => {
    const requestUrl = API_URL+`/docAtWebKeyword/`+keywordID+`?access_token=`+getConnectedUserAccessToken();
    return axios.delete(requestUrl);
  };
