import React from "react";
import { DownIcon } from "../../../assets/icons";
const ButtonDown = ({ onPress, disabled, color }) => (
  <button
    type="button"
    className={`btn btn-default`}
    disabled={disabled}
    onClick={onPress}
  >
    <DownIcon color={color}/>
  </button>
);

export default ButtonDown;
