import React from "react";
const PencilIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.05" width="34" height="34" rx="18" />
    <path d="M19.0136 6.98339L19.2993 6.69771C20.4035 5.59321 20.119 4.08827 19.0136 2.98377C17.9082 1.87928 16.4026 1.59328 15.2994 2.69839L15.0137 2.98407" stroke="#F66031" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
     <path d="M17.2652 8.73364L19.0158 6.98339L15.0156 2.98376L13.2656 4.73371" stroke="#F66031" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
     <path d="M3.22975 14.7695L7.22907 18.7691M7.22907 18.7691L17.2652 8.73363L13.2655 4.7337L3.22975 14.7695L1.99805 20.0014L7.22907 18.7691Z" stroke="#F66031" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

export default PencilIcon;
