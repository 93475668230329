import React from "react";

//css
import "./chart-controls.scss";

//icons
import chartColumn from "./icons/chart-column.svg";
import chartLine from "./icons/chart-line.svg";
import chartBar from "./icons/chart-bar.svg";
import chartDonut from "./icons/chart-donut​.svg";
import chartPie from "./icons/chart-pie.svg";
import euroIcon from "./icons/euro.svg";
import timeIcon from "./icons/time.svg";
import flagIcon from "./icons/flag.svg";
import moyIcon from "./icons/moy.svg";
import timerIcon from "./icons/timer.svg";
import calendarIcon from "./icons/calendar.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ChartControls = ({
  editable,
  type,
  onChangeType,
  displayValues,
  onToggleValues,
  displayLegend,
  onToggleLegend,
  validatedContracts,
  onToggleValidatedContracts,
  averageTime,
  onToggleAverageTime,
  isCalendar,
  onToggleDCN,
  isScaleDate
}) => {
  const chartTypes = [
    { icon: chartColumn, type: 1, isDisabled: false},
    { icon: chartLine, type: 2, isDisabled: false},
    { icon: chartBar, type: 3, isDisabled: false },
    { icon: chartDonut, type: 4, isDisabled: isScaleDate},
    { icon: chartPie, type: 5, isDisabled: isScaleDate},
  ];
  const displayDetailAmount = false;
  

  return (
    <div className="dash-bord-chart-controls">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {chartTypes.map((item, index) => (
            <ButtonChart
              icon={item.icon}
              active={type === item.type}
              onPress={() => editable ? onChangeType(item.type) : null}
              isDisabled={item.isDisabled}
            />
          ))}
        </div>
        <div>
          <div className="d-flex" style={{height:"42px"}}>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip className="dash-bord-tooltip">
                  {displayValues ? "Masquer" : "Afficher"} {" le temps sur le graphique"}
                </Tooltip>
              }
            >
              <button
                className={`btn btn-light btn-toggle-values ${
                  !displayValues ? "hide-values" : ""
                }`}
                onClick={() => editable ? onToggleValues(!displayValues) : null}
              >
                <img src={timeIcon} alt="" />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip className="dash-bord-tooltip">
                  {displayLegend ? "Marquer" : "Afficher"} {"la légende"}
                </Tooltip>
              }
            >
              <button
                className={`btn btn-light btn-toggle-values ${
                  !displayLegend ? "hide-values" : ""
                }`}
                onClick={() => editable ? onToggleLegend(!displayLegend) : null}
              >
                <i class="fas fa-list" style={{ fontSize: 25, marginTop: "-2px" }}></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip className="dash-bord-tooltip">
                  {displayDetailAmount ? "Marquer" : "Afficher"} {" les contrats en cours de négo ou validés"}
                </Tooltip>
              }
            >
              <button
                className={`btn btn-light btn-toggle-values ${validatedContracts ? "active-control" : ""
                }`}
                onClick={() => editable ? onToggleValidatedContracts(!validatedContracts) : null}
              >
                <img src={flagIcon} alt="" style={{height: "24px"}}/>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip className="dash-bord-tooltip">
                  {displayDetailAmount ? "Marquer" : "Afficher"} {" le temps moyen/temps Total"}
                </Tooltip>
              }
            >
              <button
                className={`btn btn-light btn-toggle-values ${averageTime ? "active-control" : ""
                }`}
                onClick={() => editable ? onToggleAverageTime(!averageTime) : null}
              >
                <img src={moyIcon} alt="" style={{height: "24px"}}/>
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <div>
          <div className="d-flex" style={{ height: "42px" }}>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip className="dash-bord-tooltip">
                  {displayDetailAmount ? "Marquer" : "Afficher"} {" la durée temps passé (DTP)"}
                </Tooltip>
              }
            >
              <button
                className={`btn btn-light btn-toggle-values ${!isCalendar ? "active-control" : ""
                  }`}
                onClick={() => editable ? onToggleDCN(!isCalendar) : null}
              >
                <img src={timerIcon} alt="" style={{ height: "24px", marginTop: "-2px" }} />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip className="dash-bord-tooltip">
                  {isCalendar ? "Marquer" : "Afficher"} {" la durée calendaire de négo (DCN)"}
                </Tooltip>
              }
            >
              <button
                className={`btn btn-light btn-toggle-values ${isCalendar ? "active-control" : ""
                  }`}
                onClick={() => editable ? onToggleDCN(!isCalendar) : null}
              >
                <img src={calendarIcon} alt="" style={{ height: "24px", marginTop: "-2px" }} />
              </button>
            </OverlayTrigger>
            
          </div>
        </div>
      </div>
    </div>
  );
};

const ButtonChart = ({ icon, onPress, active, isDisabled }) => (
  <button
    className={`${isDisabled? "disabled-action":""} btn btn-light btn-chart-type ${active ? "active" : ""}`}
    onClick={onPress}
  >
    <img src={icon} alt="" />
  </button>
);

export default ChartControls;
