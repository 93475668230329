import React from "react";
const CibleIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.05" width="36" height="36" rx="18" fill="#292C41" />
    <path
      d="M29.25 18H23.25M18 6.75V12.75V6.75ZM18 23.25V29.25V23.25ZM12.75 18H6.75H12.75Z"
      stroke="#5367F4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 26.25C22.5563 26.25 26.25 22.5563 26.25 18C26.25 13.4437 22.5563 9.75 18 9.75C13.4437 9.75 9.75 13.4437 9.75 18C9.75 22.5563 13.4437 26.25 18 26.25Z"
      stroke="#5367F4"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CibleIcon;
