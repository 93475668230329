import React from "react";
const DocumentIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.28856 14.4535L12.7533 9.06598C12.93 8.89239 13.0702 8.68612 13.1659 8.459C13.2615 8.23188 13.3108 7.98838 13.3108 7.74246C13.3108 7.49654 13.2615 7.25304 13.1659 7.02592C13.0702 6.7988 12.93 6.59253 12.7533 6.41895C12.5772 6.24475 12.368 6.10654 12.1376 6.01223C11.9073 5.91793 11.6603 5.86938 11.4108 5.86938C11.1614 5.86938 10.9144 5.91793 10.684 6.01223C10.4536 6.10654 10.2444 6.24475 10.0683 6.41895L1.91856 14.4535C1.33023 15.0369 1 15.8262 1 16.6489C1 17.4717 1.33023 18.261 1.91856 18.8444C2.51041 19.4244 3.31102 19.75 4.14553 19.75C4.98005 19.75 5.78066 19.4244 6.3725 18.8444L16.9546 8.44315C17.3653 8.03829 17.6911 7.55765 17.9133 7.02867C18.1356 6.49968 18.25 5.93271 18.25 5.36013C18.25 4.78756 18.1356 4.22059 17.9133 3.6916C17.6911 3.16261 17.3653 2.68197 16.9546 2.27712C16.5439 1.87222 16.0564 1.55104 15.5198 1.33191C14.9832 1.11278 14.4081 1 13.8273 1C13.2465 1 12.6714 1.11278 12.1349 1.33191C11.5983 1.55104 11.1108 1.87222 10.7001 2.27712L2.80303 10.0625"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentIcon;
