import React from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import "./EditMode.scss";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const EditMode = ({ isEditMode, onChange, isDisputedMode, onChangeDisputedMode, disabled, isContractPage, isRestrictedMode=false, disableAction = false}) => {

  const rowMaxWidth = isContractPage ? 1900 : null
  const colMaxWidth = isContractPage ? 650 : null

  return (
    <div
      className={`bg-under-header editModeTitle ${
        isEditMode ? "editMode" : (isDisputedMode ? "disputedMode":"")
      }`}
    >
      <div className="_container" style={{maxWidth: '100%'}}>
        <div className="row" style={rowMaxWidth ? {maxWidth: '100%'} : null}>
          <div className="col-md-5"  style={colMaxWidth ? {maxWidth: '100%'} : null}>
            <div className="d-flex justify-content-between align-items-center">
              { !isRestrictedMode &&
                <div className={disableAction ? "disabled-action edit-mode": "edit-mode"}>
                <BootstrapSwitchButton
                  onChange={onChange}
                  checked={isEditMode}
                  disabled={false}
                />
                {isEditMode ? t('common.disable_edit_mode') : t('common.enable_edit_mode')}
              </div>}
              {isRestrictedMode &&
                <div className="edit-mode" style={{color:'white'}}>
                <span className="fa fa-lock" style={{marginRight:'10px'}}></span>
                Accès limité
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(EditMode);
