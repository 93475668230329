import React from "react";
import { LockIcon, UnLockIcon } from "../../../assets/icons";

const ButtonUnLock = ({ onPress}) => (
  <button
    type="button"
    className={`btn btn-default icon-restriction`}
    onClick={onPress}
  >
    <UnLockIcon />
  </button>
);

export default ButtonUnLock;
