/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router, Link } from "react-router-dom";

import "./HeaderVisitor.scss";
import DataContext from "../../../DataManagement/DataContext";

//i18n
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import logo from "./logo/docatweb-logo.png";

import Cookies from "js-cookie";
import NotifyAlert from "../../confirm-alert/notify-alert/NotifyAlert";

class HeaderVisitor extends Component {
  constructor() {
    super();
    this.state = {
      userRights: {},
      currentClient: {},
      userWithAvatar: {},
      totalNumberOfPages: 0,
      percentage: 0,
      alertMessage: null,
    };
  }

  componentWillMount() {
    const search = window.location.search;
    const query = new URLSearchParams(search);
    if(query && query.get('renew_success')  && query.get('renew_success').toString() === '0'){
      NotifyAlert.show(
        query.get('message') !== null && query.get('message') !== 'null' &&  query.get('message') !== undefined &&  query.get('message') !== 'undefined' && query.get('message') !== '' ? decodeURIComponent(query.get('message')) : "Une erreur est survenue lors du renouvellement de votre contrat",
        'Reconduction'
      ).then(() => {});
      this.props.history.push(
        "/home"
      );
    }else{
      let currentClientCookie = Cookies.get("currentClient");
      if (currentClientCookie) {
        this.props.history.push(
          "/home"
        );
        // NotifyAlert.show(
        //   "Vous êtes déjà connecté",
        //   'Information'
        // ).then(() => {});
        // this.props.history.push(
        //   "/home"
        // );
      }
    }
  }


 

  render() {
    return (
      <div className="app-header header-shadow header-visitor">
        <div className="app-header__logo">
          <div className="logo">
            <Link to="/" className="nav-link-header-visitor">
              <img src={logo}></img>
            </Link>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button
              type="button"
              className="hamburger hamburger--elastic mobile-toggle-nav"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
          <div className="logo">
            <Link to="/home" className="nav-link-header-visitor">
              <img src={logo}></img>
            </Link>
          </div>
        </div>
        <div className="app-header__menu">
          <span>
            <button
              type="button"
              className="btn-icon btn-icon-only btn btn-default btn-sm mobile-toggle-header-nav"
            >
              <span className="btn-icon-wrapper">
                <i className="fa fa-ellipsis-v fa-w-6"></i>
              </span>
            </button>
          </span>
        </div>
        <div className="app-header__content">
          
          {/* <div className="app-header-right">
            <div className="">
              <LanguagesSwitcher
                label={<i className="nav-link-icon fa fa-language"></i>}
              />
            </div>
          </div> */}
         
          <div className="app-header-right">
            <div className="inline-btn">
              <div className="widget-content p-0">
                <button className="btn btn-primary btn-client-contact" onClick={() => window.location = 'mailto:contact@docatweb.fr'} type="submit">
                  {t("authentication.header.contact")}
                </button>
              </div>
              {/* <div className="widget-content p-0">
                <button className="btn btn-primary btn-client-space" type="submit">
                  {t("authentication.header.client_space")}
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
HeaderVisitor.contextType = DataContext;
export default withRouter(withTranslation()(HeaderVisitor));
