import React from 'react'
const InfoIcon = ({color=null}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
      stroke={color ? color : "#7077A5"}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.7998 8.20001H9.9998V14.8M8.7998 14.8024H11.1998"
      stroke={color ? color : "#7077A5"}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.7001 6.7C10.0315 6.7 10.3001 6.43137 10.3001 6.1C10.3001 5.76863 10.0315 5.5 9.7001 5.5C9.36873 5.5 9.1001 5.76863 9.1001 6.1C9.1001 6.43137 9.36873 6.7 9.7001 6.7Z"
      stroke={color ? color : "#7077A5"}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InfoIcon
