import React, { Component } from 'react';  
import $ from 'jquery'

class LoaderComponent extends Component {  

  constructor(props) {
    super(props); 
  }

  show = ()=>{
    $('#page-loading').fadeIn()
  }

  hide = ()=>{
    $('#page-loading').fadeOut()
  } 

  getLoader = (width = 30)=>{
    return(
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(50 50)">
          <g>
            <animateTransform attributeName="transform" type="rotate" calcMode="discrete" values="0;90;180;270;360" keyTimes="0;0.25;0.5;0.75;1" dur="2.5s" repeatCount="indefinite"></animateTransform>
            <path d="M-40 0A40 40 0 1 0 40 0" fill="#f66031">
              <animate attributeName="fill" calcMode="discrete" values="#f66031;#260c4e;#f66031;#260c4e;#f66031" keyTimes="0;0.24;0.49;0.74;0.99" dur="2.5s" repeatCount="indefinite"></animate>
            </path>
            <path d="M-40 0A40 40 0 0 1 40 0" fill="#260c4e">
              <animate attributeName="fill" calcMode="discrete" values="#260c4e;#f66031;#260c4e;#f66031;#260c4e" keyTimes="0;0.25;0.5;0.75;1" dur="2.5s" repeatCount="indefinite"></animate>
            </path>
            <path d="M-39 0L39 0" stroke="#c63609" strokeWidth="2">
              <animate attributeName="stroke" values="#f66031;#c63609;#1b0837;#260c4e;#1b0837;#c63609;#f66031;#c63609;#1b0837;#260c4e;#1b0837;#c63609;#f66031" keyTimes="0;0.124;0.125;0.25;0.374;0.375;0.5;0.624;0.625;0.75;0.874;0.875;1" dur="2.5s" repeatCount="indefinite"></animate>
            </path>
            <g>
              <path d="M-40 0A40 40 0 0 1 40 0Z" fill="#c63609">
                <animate attributeName="fill" values="#f66031;#c63609;#1b0837;#260c4e;#1b0837;#c63609;#f66031;#c63609;#1b0837;#260c4e;#1b0837;#c63609;#f66031" keyTimes="0;0.124;0.125;0.25;0.374;0.375;0.5;0.624;0.625;0.75;0.874;0.875;1" dur="2.5s" repeatCount="indefinite"></animate>
              <animateTransform attributeName="transform" type="scale" values="1 1;1 0;1 -1;1 1" keyTimes="0;0.5;0.999;1" dur="0.625s" repeatCount="indefinite"></animateTransform>
            </path></g>
          </g>
        </g>
      </svg>
    )
  }
}  
export default new LoaderComponent(); 