import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { ArchiveIcon, ExportIcon, SearchIcon, SortIcon, TagIcon, TrashIcon } from '../../../assets/icons';
import moment from 'moment';
import ExportModal from './parts/ExportModal';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import FilterIcon from '../../../../back-office/dash-bord/edit-mode/parts/chart-data/icons/filter.icon';
import EyeVisible from '../../../../back-office/dash-bord/edit-mode/parts/chart-data/icons/eye-visible.icon';
import EyeHidden from '../../../../back-office/dash-bord/edit-mode/parts/chart-data/icons/eye-hidden.icon';
import ColumnVisibility from '../../../../back-office/dash-bord/edit-mode/parts/chart-data/icons/columns-visibility.icon';
import AdvancedSearch from '../../../../back-office/dash-bord/edit-mode/parts/chart-data/icons/advanced-search.icon';
import ColumnsModal from './parts/ColumnsModal';
import SearchModal from './parts/SearchModal';
import { toast, ToastContainer } from "react-toastify";
import ArchiveDeleteModal from './parts/ArchiveDeleteModal';
import { archiveContractsByUser } from '../../../../../../api/validateContract';

const ListingValidatedContract = ({
  data: initialData,
  keywordsDB,
  tags,
  handleDoubleClick,
  getContractBadges,
  seachIncontractList,
  removeDuplicateRowsByUlDataIndex,
  columnsList,
  setColumnsList,
  homeTabsSearch
}) => {
  const [data, setData] = useState(initialData);
  const [isExportModalOpen, setOpenExportModal] = useState(false);
  const [isSearchModalOpen, setOpenSearchModal] = useState(false);
  const [isColumnsModalOpen, setOpenColumnsModal] = useState(false);
  const [isArchiveDeleteModalOpen, setOpenArchiveDeleteModal] = useState(false);
  const [isDisputedFilter, setIsDisputedFilter] = useState(false);
  const [isAvtVisible, setIsAvtVisible] = useState(true);
  const [isSearchTag, setSearchTag] = useState(false);
  const [removeDupplicatedCalled, setRemoveDupplicatedCalled] = useState(0);
  
  const [selectedContractTags, setSelectedContractTags] = useState(null);

  const [defaultContracts, setDefaultContracts] = useState(data);
  
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  
  const [archiveSelectedList, setArchiveSelectedList] = useState([]);
  
  const [archive, setArchive] = useState(false);
  const [unarchive, setUnarchive] = useState(false);
  const [isDeleteAction, setIsDeleteAction] = useState(false);
  
  const getColumns = (columnsList) =>{
    if(columnsList && columnsList !== null && columnsList["items"] !== undefined && columnsList["items"].length>0){
      const item = columnsList["items"].find(section => section.key === "archive_box_contracts");
      return item["columns"]
    }else{
      return ['number', 'contract_full_name', 'category', 'archive_date', 'arch_del', 'amount', 'end_date']
    }
  }
  const [orderContractColumns, setOrderContractColumns] = useState(getColumns(columnsList));
  const loadColumns = () =>{
    let columns = [
      { id: 'number', label: t('frontoffice.home.contracts.table.number'), isVisible: true,  },
      { id: 'contract_full_name', label: t('frontoffice.home.contracts.table.contract_full_name'), isVisible: true,  },
      { id: 'category', label: t('frontoffice.home.contracts.table.category'), isVisible: true,  },
      { id: 'archive_date', label: t('frontoffice.home.contracts.table.archive_date'), isVisible: true,  },
      { id: 'arch_del', label: t('frontoffice.home.contracts.table.arch_del'), isVisible: true,  },
      { id: 'amount', label: t('frontoffice.home.contracts.table.amount'), isVisible: true,  },
      { id: 'end_date', label: t('frontoffice.home.contracts.table.end_date'), isVisible: true,  },
      // Add more columns as needed
    ]
    const sortedDataArray = columns.sort((a, b) => {
      const isVisibleA = orderContractColumns.includes(a.id);
      const isVisibleB = orderContractColumns.includes(b.id);

      // Update isVisible property
      a.isVisible = isVisibleA;
      b.isVisible = isVisibleB;

      // Keep the sorting logic
      return orderContractColumns.indexOf(a.id) - orderContractColumns.indexOf(b.id);
    });
    return sortedDataArray
  }
  const [columns, setColumns] = useState(loadColumns());

  const [draggedIndex, setDraggedIndex] = useState(null);
  
  


  useEffect(() => {
    removeDuplicateRowsByUlDataIndex('homeDataTable1')
    
  }, [data, handleDoubleClick, getContractBadges, seachIncontractList, removeDupplicatedCalled])
  
  const rmDupplicated = () => {
    let cmpt = removeDupplicatedCalled+1 
    setRemoveDupplicatedCalled(cmpt)
  };
  

  useEffect(() => {
    const sortedDataArray = columns.sort((a, b) => {
      const isVisibleA = orderContractColumns.includes(a.id);
      const isVisibleB = orderContractColumns.includes(b.id);

      // Update isVisible property
      a.isVisible = isVisibleA;
      b.isVisible = isVisibleB;

      // Keep the sorting logic
      return orderContractColumns.indexOf(a.id) - orderContractColumns.indexOf(b.id);
    });
    setColumns(sortedDataArray)
  }, [orderContractColumns])
  // Uncomment for api save
  useEffect(() => {
    if (columns && columns.length > 0 && !isFirstLoad) {
      const editedColumns = editItemsArray(columns.filter(x => x.isVisible).map(item => item.id))
      setColumnsList(editedColumns)
    }else{
      setIsFirstLoad(false)
    }
  }, [columns])

  const editItemsArray = (dataC) => {
    // Find the object with the key "validated_contracts"
    const validatedContractsItem = columnsList.items.find(item => item.key === "validated_contracts");
    // Check if the item is found
    if (validatedContractsItem) {
      // Update the columns for "validated_contracts"
      validatedContractsItem.columns = dataC;
      // Log the updated columnsList array
      return columnsList
    }
  };


  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggedIndex === null || index === draggedIndex) {
      return;
    }

    const updatedColumns = [...columns];
    const [removed] = updatedColumns.splice(draggedIndex, 1);
    updatedColumns.splice(index, 0, removed);
    setColumns(updatedColumns);
    setDraggedIndex(index);
    setOrderContractColumns(updatedColumns.filter(x => x.isVisible).map(item => item.id))
  };


  const handleSubContratcts = (contract) => {
    var targetDataId = contract.id;
    var targetRow = document.querySelector('#homeDataTable1 tr[data-id="' + targetDataId + '"].parent-contract');

    if (targetRow) {

      const childs = contract.children

      for (let index = 0; index < childs.length; index++) {
        const contrat = childs[index];
        var targetTr = document.querySelector('tr[data-childId="' + contrat.id + '"]');
        var span = targetRow.querySelector('.more-items');
        // Select the <svg> element within the <span>
        var svg = span.querySelector('svg');

        // Select the <path> element within the <svg>
        var path = svg.querySelector('path');

        if(targetTr !== null){
          targetTr.remove()
          // Update the path data
          path.setAttribute('d', 'M75.4436 69.9999L59.4572 51.4814C58.4014 50.2583 58.5441 48.417 59.7758 47.3687C61.0076 46.3204 62.862 46.462 63.9178 47.6851L81.5428 68.1017C82.4858 69.194 82.4858 70.8058 81.5428 71.898L63.9178 92.3147C62.862 93.5377 61.0076 93.6794 59.7758 92.631C58.5441 91.5827 58.4014 89.7414 59.4572 88.5184L75.4436 69.9999Z');
          continue; 
        }
        // Update the path data
        path.setAttribute('d', 'M88.5186 59.0357C89.7416 57.9874 91.5829 58.129 92.6312 59.3521C93.6796 60.5751 93.5379 62.4164 92.3149 63.4647L71.8982 80.9647C70.8059 81.9009 69.1942 81.9009 68.1019 80.9647L47.6853 63.4647C46.4622 62.4164 46.3206 60.5751 47.3689 59.3521C48.4172 58.129 50.2585 57.9874 51.4815 59.0357L70.0001 74.9087L88.5186 59.0357Z');

        let contractBadges = getContractBadges(contrat);
       
        // Create a new <tr> element
        var newRow = document.createElement('tr');

        newRow.classList.add('sub-contract');

        // Set the dynamic attributes
        newRow.setAttribute('data-id', contrat.avt === 1 ? null : contrat.id);
        newRow.setAttribute('data-childId', contrat.id ? contrat.id :null);
        newRow.setAttribute('contractId', contrat.id);

        // Set the event handlers
        newRow.ondblclick = function() {
          handleDoubleClick(contrat.hachedId, contrat.avt);
        };
        newRow.onclick = function() {
          removeDuplicateRowsByUlDataIndex('homeDataTable1');
          rmDupplicated();
        };

        // Construct the HTML content of the <tr> element
        newRow.innerHTML = `
          <td class="${contrat.isDisputed ? 'disputed-row' : 'undisputed-row'}">
            ${!isAvtVisible ? `<span class="child-td">&nbsp</span>` : ''}
            ${contrat.num ? `<span class="child-contratct-num">${contrat.num} </span>`: ""}
          </td>
          <td>${contrat.name}</td>
          <td>
            ${contrat.avt === 1 ? `<strong class='av-style'>Avenant</strong>` : `<strong class='no-style'></strong>`}
            ${contrat.avt === 0 ? `<strong class='co-style'>Contrat</strong>` : `<strong class='no-style'></strong>`}
          </td>
          <td>${contrat.category}</td>
          <td>${contrat.accountingCode}</td>
          <td>${contrat.tier}</td>
          <td>${contrat.amount}</td>
          <td align="center" class="${contractBadges[0]}" data-order="${moment(contrat.date_negociation, 'DD/MM/YYYY').toDate().getTime()}">
            <div class="${contrat.endingDateUpdated ? 'updatedDate' : ''}">${contrat.date_negociation}</div>
          </td>
          <td align="center" class="${contractBadges[1]}" data-order="${moment(contrat.date_preavi, 'DD/MM/YYYY').toDate().getTime()}">
            <div class="${contrat.endingDateUpdated ? 'updatedDate' : ''}">${contrat.date_preavi}</div>
          </td>
          <td align="center" class="${contractBadges[2]}" data-order="${moment(contrat.date_fin, 'DD/MM/YYYY').toDate().getTime()}">
            <div class="${contrat.endingDateUpdated ? 'updatedDate' : ''}">${contrat.date_fin}</div>
          </td>
        `;

        // Append the new <tr> element after the target <tr> element
        targetRow.insertAdjacentElement('afterend', newRow);

      }
    
      // // Generate the HTML string for the new <tr> elements
      // var trHTML = trList.join('');
    
      // // Insert the HTML string after the target <tr> element
      // targetRow.insertAdjacentHTML('afterend', trHTML);
      // console.log('selectedcontract',contract)
    }

  };

  const removeSubContracts = () =>{
    var divArray = document.querySelectorAll('.sub-contract');
    if(divArray){
      // Remove each div element from its parent node
      divArray.forEach(function(divElement) {
        divElement.parentNode.removeChild(divElement);
      });
    }
  }

  const moreTags = (index, tags) =>{
    if(tags && tags.length>1){
        setSelectedContractTags({
          index: index,
          othertags: [...new Set(tags)].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).slice(1)
        })
      }
  }

  const doSearch = (query) =>{
    console.log(query)
    const filteredContracts = searchContracts(query, defaultContracts);
    console.log(filteredContracts)
    setData(filteredContracts);
  }

  const doClearSearch = () =>{
    setData(defaultContracts);
  }

  
  function searchContracts(searchConfig, contracts) {
    const selectedCategoryIds = searchConfig.fields.filter(field => field.selected).map(field => field.id);
    let selectedKeywords = searchConfig.fields.flatMap(field => {
      return field.keywords.filter(keyword => keyword.selected).map(keyword => ({
        categoryId: field.id,
        keywordId: keyword.id,
        synonyms: keyword.synonyms.filter(synonym => synonym.selected).map(synonym => synonym.name)
      }));
    });

    const selectedSynonyms = searchConfig.fields.flatMap(field => {
      return field.keywords.flatMap(keyword => {
        return keyword.synonyms.filter(synonym => synonym.selected).map(synonym => ({
          categoryId: field.id,
          keywordId: keyword.id,
          synonymName: synonym.name
        }));
      });
    });

    if(selectedSynonyms && selectedSynonyms.length>0){
      // Iterate over selectedSynonyms
      selectedSynonyms.forEach(function (synonym) {
        // Check if there exists a keyword with the same categoryId and keywordId
        var existingKeyword = selectedKeywords.find(function (keyword) {
          return (keyword.categoryId === synonym.categoryId && keyword.keywordId === synonym.keywordId);
        });

        // If no keyword exists, add a new keyword object with an empty synonyms array
        if (!existingKeyword) {
          existingKeyword = {
            "categoryId": synonym.categoryId,
            "keywordId": synonym.keywordId,
            "synonyms": []
          };
          selectedKeywords.push(existingKeyword);
        }

        // Push the synonym into the synonyms array of the existing or newly created keyword
        existingKeyword.synonyms.push(synonym.synonymName);
      });
      console.log(selectedKeywords)
    }

    if (searchConfig.andContains) {
      return contracts.filter(contract => {
        return selectedCategoryIds.every(categoryId => contract.categoryId === categoryId) &&
          selectedKeywords.every(keyword =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.id === keyword.keywordId ||
              keyword.synonyms.some(synonym =>
                contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym)
              )
            )
          ) 
          // &&
          // selectedSynonyms.every(synonym =>
          //   contract.keywords.some(contractKeyword =>
          //     contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym.synonymName)
          //   )
          // );
      });
    } else if (searchConfig.orContains) {
      return contracts.filter(contract => {
        return selectedCategoryIds.some(categoryId => contract.categoryId === categoryId) ||
          selectedKeywords.some(keyword =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.id === keyword.keywordId ||
              keyword.synonyms.some(synonym =>
                contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym)
              )
            )
          ) ||
          selectedSynonyms.some(synonym =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym.synonymName)
            )
          );
      });
    } else if (searchConfig.doesntContain) {
      return contracts.filter(contract => {
        return !selectedCategoryIds.includes(contract.categoryId) &&
          !selectedKeywords.some(keyword =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.id === keyword.keywordId ||
              keyword.synonyms.some(synonym =>
                contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym)
              )
            )
          ) &&
          !selectedSynonyms.some(synonym =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym.synonymName)
            )
          );
      });
    } else {
      // Default behavior: `andContains`
      return contracts.filter(contract => {
        return selectedCategoryIds.every(categoryId => contract.categoryId === categoryId) &&
          selectedKeywords.every(keyword =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.id === keyword.keywordId ||
              keyword.synonyms.some(synonym =>
                contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym)
              )
            )
          ) &&
          selectedSynonyms.every(synonym =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym.synonymName)
            )
          );
      });
    }
  }

  const archiveContracts = (action) =>{
    if(action === "Delete")
      setIsDeleteAction(true);
    else
      setIsDeleteAction(false)
    if(archiveSelectedList && archiveSelectedList.length>0){
      setOpenArchiveDeleteModal(!isArchiveDeleteModalOpen)
    }else{
      toast.info("Veuillez choisir au moins un contrat", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }


  const onSetToArchiveList = (e, archive_action) => {
    
    let id = e.target.value
    let listing = archiveSelectedList? archiveSelectedList:[];
    if(e.target.checked){
      listing.push(id)
    }else{
      listing = listing.filter(f => f !== id)
    }
    setArchiveSelectedList(listing);
    if(listing && listing.length===0){
      setArchive(false)
      setUnarchive(false)
    }
    if(listing && listing.length>0 && archive_action === "archive" && !archive && !unarchive){
      setArchive(!archive)
    }
    if(listing && listing.length>0 && archive_action === "unarchive" && !archive && !unarchive){
      setUnarchive(!unarchive)
    }
  };

  const onManageArchiveSelected = (action) => {
    if (action === "DELETE" || action === "ARCHIVE" || action === "UNARCHIVE") {
      archiveContractsByUser({
        contractIDs: archiveSelectedList,
        type: action
      }).then(response => {
        switch (action) {
          case "DELETE":
            setOpenArchiveDeleteModal(false)
            setArchiveSelectedList([])
            toast.success("Contrat(s) supprimé(s) avec succès!", {
              position: toast.POSITION.TOP_RIGHT
            });
            setTimeout(() => {
              window.location.reload()
            }, 3000);
            break;
          case "ARCHIVE":
            setOpenArchiveDeleteModal(false)
            setArchiveSelectedList([])
            toast.success("Contrat(s) archivé(s) avec succès!", {
              position: toast.POSITION.TOP_RIGHT
            });
            setTimeout(() => {
              window.location.reload()
            }, 3000);
            break;
          case "UNARCHIVE":
            setOpenArchiveDeleteModal(false)
            setArchiveSelectedList([])
            toast.success("Contrat(s) désarchivé(s) avec succès!", {
              position: toast.POSITION.TOP_RIGHT
            });
            setTimeout(() => {
              window.location.reload()
            }, 3000);
            break;

          default:
            break;
        }
      }, err => {
        switch (action) {
          case "DELETE":
            setOpenArchiveDeleteModal(false)
            setArchiveSelectedList([])
            toast.warn("Une erreur est survenue lors de la suppression", {
              position: toast.POSITION.TOP_RIGHT
            });
            break;
          case "ARCHIVE":
            setOpenArchiveDeleteModal(false)
            setArchiveSelectedList([])
            toast.warn("Une erreur est survenue lors de l'archivage", {
              position: toast.POSITION.TOP_RIGHT
            });
            break;
          case "UNARCHIVE":
            setOpenArchiveDeleteModal(false)
            setArchiveSelectedList([])
            toast.warn("Une erreur est survenue lors du désarchivage", {
              position: toast.POSITION.TOP_RIGHT
            });
            break;

          default:
            break;
        }
      })
    }
  }

  const contractsAlreadyArchived = (contract) =>{
    switch (contract["archiveData"].type) {
      case "DELETE":
        setOpenArchiveDeleteModal(false)
        setArchiveSelectedList([])
        toast.warning("Vous ne pouvez pas afficher le contenue d'un contrat/avenant déjà supprimé.", {
          position: toast.POSITION.TOP_RIGHT
        });
        break;
      case "ARCHIVE":
        setOpenArchiveDeleteModal(false)
        setArchiveSelectedList([])
        toast.warning("Vous ne pouvez pas afficher le contenue d'un contrat/avenant déjà archivé.", {
          position: toast.POSITION.TOP_RIGHT
        });
        break;

      default:
        break;
    }
  }

  return (
    <>
      <div className="filters">
        <div className="row">
          <ToastContainer className="toast-styled-contract-home"/>
          <div className="col-12 col-sm-12 col-lg-4 search-area-mobile">
            {!isSearchTag && <div className="search-input">
              <SearchIcon />
              <input
                type="text"
                placeholder={t('frontoffice.home.contracts.table.search')}
                onChange={(e) => seachIncontractList(e, 'homeDataTable1')}
              />
            </div>}
            {isSearchTag && <div className="tag-search">
              <select
                className="form-control"
                name="tags"
                onChange={(e) => seachIncontractList(e, 'homeDataTable1', true)}
              >
                <option value="">
                  Choisissez un tag
                </option>
                {tags && tags.map((item) => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>}
          </div>
          <div className="col-sm-12 col-lg-8 hidden-only-on-mobile" style={{textAlign:'end'}}>
            {/* <button className="btn btn-link" onClick={() => {
              setOpenSearchModal(!isSearchModalOpen)
              removeSubContracts()
              }}>
              <i>
                <AdvancedSearch/>
              </i>
              Avancé
            </button> */}
            <button className="btn btn-link btn-vertical-content" onClick={() => {
              setOpenColumnsModal(!isColumnsModalOpen)
              removeSubContracts()
              }}>
              <i>
                <ColumnVisibility/>
              </i>
              <span>
                {t('frontoffice.home.contracts.table.columns')}
              </span>
            </button>

            {!!data && data.length > 0 && (
              <button className="btn btn-link btn-vertical-content" onClick={() => setOpenExportModal(true)}>
                <i>
                  <ExportIcon />
                </i>
                <span>
                  {t('frontoffice.home.contracts.table.export.export_button')}
                </span>
              </button>
            )}
              <button className="btn btn-link btn-vertical-content" onClick={() => archiveContracts("Archive")}>
              <i style={{ marginRight: "5px" }}>
                <ArchiveIcon color={unarchive ? "#EB3A59": null}/>
              </i>
              <span>
                {!archive && !unarchive && t('frontoffice.home.contracts.table.archive.archive_unarchive_button')}
                {archive && !unarchive && t('frontoffice.home.contracts.table.archive.archive_button')}
                {!archive && unarchive && t('frontoffice.home.contracts.table.archive.unarchive_button')}
              </span>
            </button>
            <button className="btn btn-link btn-vertical-content" onClick={() => archiveContracts("Delete")}>
              <i>
                <TrashIcon color="#5367F4" />
              </i>
              <span>
                {t('frontoffice.home.contracts.table.delete.delete_action')}
              </span>
            </button>

          </div>
        </div>
      </div>
      <div className='table-container'>
        <table
          width="100%"
          id="homeDataTable1"
          className="table table-hover"
          data-searching="true"
          data-select="false"
          data-paging="false"
          data-info="false"
        >
          <thead>
            {/* <tr>HERE WE GO
              <th>{t('frontoffice.home.contracts.table.number')}</th>
              <th>{t('frontoffice.home.contracts.table.third_party_name')}</th>
              <th>{t('frontoffice.home.contracts.table.contract_full_name')}</th>
              <th>{t('frontoffice.home.contracts.table.category')}</th>
              <th>{t('frontoffice.home.contracts.table.type')}</th>
              <th>{t('frontoffice.home.contracts.table.accounting_code')}</th>
              <th>{t('frontoffice.home.contracts.table.amount')}</th>
              <th align="center">{t('frontoffice.home.contracts.table.negotiation')}</th>
              <th align="center" id="deadline">{t('frontoffice.home.contracts.table.date_of_notice')}</th>
              <th align="center">{t('frontoffice.home.contracts.table.end_date')}</th>
              <th align="center">{t('frontoffice.home.contracts.table.tag')}</th>
            </tr> */}
            <tr>
              <th data-sorting="false"><input type="checkbox" disabled/></th>
              {columns.map((column, index) => (
                column && column.isVisible && <th
                  key={column.id}
                  draggable
                  onDragStart={() => handleDragStart(index)}
                  onDrop={()=> handleDragOver(index)}
                  onDragOver={(e) => e.preventDefault()}
                >
                  {/* {column.label} */}
                  {t('frontoffice.home.contracts.table.'+column.id)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {
              isDisputedFilter && data && (data.filter(x=>x.isDisputed)).length <1 &&
                <tr>
                  <td colSpan={10}>
                    <p className="no-contrat-in-litige">{t('frontoffice.home.contracts.table.no_litige_found')}</p>
                  </td>
                </tr>
            
            }
            {data?.map((contrat, index) => {
              let contractBadges = getContractBadges(contrat);
              return (
                ((isDisputedFilter && contrat.isDisputed) || !isDisputedFilter) && ((!isAvtVisible && contrat.avt === 0) || isAvtVisible) &&
                <>
                  <tr className={ (contrat?.archiveData?.type === "DELETE" ? "delete_Row ":(contrat?.archiveData?.type === "ARCHIVE" ? "archive_Row ":"")) + (isAvtVisible ? "" : "parent-contract")} data-id={contrat.avt === 1 ? null : contrat.id} contractId={contrat.id} onDoubleClick={() => !contrat?.archiveData?.type ? handleDoubleClick(contrat.hachedId, contrat.avt): contractsAlreadyArchived(contrat)} onClick={() => { removeDuplicateRowsByUlDataIndex('homeDataTable1'); rmDupplicated() }}>
                    <td>
                      {
                        (contrat?.toArchive === true || contrat?.archiveData?.type !== "DELETE") && 
                        <input type="checkbox" disabled={(contrat?.toArchive === true && (unarchive)) || (contrat?.toArchive === undefined && (archive))}
                          value={contrat.id} onChange={(e)=>onSetToArchiveList(e, contrat?.toArchive === true ? "archive":"unarchive" )} />
                      }
                    </td>
                    {columns.map((column) => 
                      <>
                        {column && column.isVisible && column.id == "number" && <td className={contrat.isDisputed ? "disputed-row" : "undisputed-row"}
                          style={{borderLeft:0, borderBottom:0}}>
                          {!isAvtVisible && contrat.children && contrat.children.length > 0 &&
                            <span className='more-items' onClick={() => handleSubContratcts(contrat)}>
                              <svg width="31" height="30" viewBox="0 0 70 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M75.4436 69.9999L59.4572 51.4814C58.4014 50.2583 58.5441 48.417 59.7758 47.3687C61.0076 46.3204 62.862 46.462 63.9178 47.6851L81.5428 68.1017C82.4858 69.194 82.4858 70.8058 81.5428 71.898L63.9178 92.3147C62.862 93.5377 61.0076 93.6794 59.7758 92.631C58.5441 91.5827 58.4014 89.7414 59.4572 88.5184L75.4436 69.9999Z" fill="#6d4cf5" />
                              </svg>
                            </span>
                          }
                          {!isAvtVisible && contrat.children && contrat.children.length == 0 &&
                            <span className='more-items' style={{ width: "31px", display: "inline-flex" }}></span>
                          }
                          {contrat.num}
                        </td>}
                        {column && column.isVisible && column.id == "third_party_name" && <td>{contrat.tier}</td>}
                        {column && column.isVisible && column.id == "contract_full_name" && <td>{contrat.name}</td>}
                        {column && column.isVisible && column.id == "category" && <td>{contrat.category}</td>}
                        {column && column.isVisible && column.id == "archive_date" && <td>{contrat?.archiveData?.date}</td>}
                        {column && column.isVisible && column.id == "arch_del" && <td>{(contrat?.archiveData?.type === "DELETE" ? t('frontoffice.home.contracts.table.deleted'): (contrat?.archiveData?.type === "ARCHIVE"? t('frontoffice.home.contracts.table.archived'):""))}</td>}
                        {column && column.isVisible && column.id == "amount" && <td>{contrat.amount}</td>}
                        {column && column.isVisible && column.id == "end_date" && <td
                          className={contractBadges[2]}
                          data-order={moment(contrat.date_fin, 'DD/MM/YYYY').toDate().getTime()}
                        >
                          <div className={contrat.endingDateUpdated ? "updatedDate" : ""}>{contrat.date_fin}</div>
                        </td>}
                      </>
                    )}
                  </tr>

                </>
              );
            })}
          </tbody>
        </table>

        <ExportModal isOpen={isExportModalOpen} onRequestClose={() => setOpenExportModal(false)} />
        <SearchModal isOpen={isSearchModalOpen} keywordsDB={keywordsDB} onRequestClose={() => setOpenSearchModal(false)} doSearch={doSearch} doClearSearch={doClearSearch} tabKey={"validated_contracts"} homeTabsSearch={homeTabsSearch}/>
        <ColumnsModal isOpen={isColumnsModalOpen} onRequestClose={() => setOpenColumnsModal(false)} columns={columns} setColumns={setColumns} setOrderContractColumns={setOrderContractColumns}/>
        <ArchiveDeleteModal isOpen={isArchiveDeleteModalOpen} onManageArchiveSelected={onManageArchiveSelected} actions={{archive:archive,unarchive:unarchive,isDeleteAction:isDeleteAction}} onRequestClose={() => setOpenArchiveDeleteModal(false)}  archiveSelectedList={data.filter(x=>archiveSelectedList.includes(x.id.toString())) || []}/>
      </div>
    
    </>
  );
};

export default withTranslation()(ListingValidatedContract);
