import React, { useState } from "react";
import DashBordEditMode from "./edit-mode";
import DashBordConsultnMode from "./consult-mode";

const DashBordBuying = () => {
  const [editMode, setEditMode] = useState(false);

  return editMode ? (
    <DashBordEditMode editMode={editMode} onChangeEditMode={setEditMode} />
  ) : (
    <DashBordConsultnMode editMode={editMode} onChangeEditMode={setEditMode} />
  );
};

export default DashBordBuying;
