import React, { Component } from 'react'; 
import { BrowserRouter as Router, Link } from "react-router-dom";

import './Subscription.scss'

// components
import Header from '../../../../components/layouts/header/Header'
import Sidebar from '../../../../components/layouts/sidebar/Sidebar' 

// plugins
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { SliderRail, Handle, Track, Tick } from './Components' // example render components - source below
import Chart from "react-google-charts";



class Subscription extends Component {

    constructor (props) { 
        super(props);    
        this.state = {
            editMode: true, 


            chartMode: 'quarterly',
            yearlyChartData : [
                ['', ''],
                ['2016', 100],
                ['2017', 200],
                ['2018', 300],
                ['2019', 400],
                ['2020', 500],
            ],
            quarterlyChartData :[
                ['', ''],
                ['T1/18', 10],
                ['T2/18', 30],
                ['T3/18', 45],
                ['T4/18', 70],

                ['T1/19', 10],
                ['T2/19', 30],
                ['T3/19', 45],
                ['T4/19', 70],

                ['T1/20', 10],
                ['T2/20', 30],
                ['T3/20', 45],
                ['T4/20', 70],
            ]
        } 
 
    }   

    changeGraphMode = (isYearly) => { 
        setTimeout(()=>{
            this.setState({
                ...this.state,
                chartMode: isYearly ? 'yearly' : 'quarterly'
            })
        })
    }
    changeEditMode = (checked) => { 
        this.setState({
            ...this,
            editMode: checked
        }) 
    }








    export = ()=>{
        // to do
    }
    print = ()=>{
        // to do
    }

    render() {
        const sliderStyle = {
            position: 'relative',
            width: '100%',
          }
        return (
            <div className="app-container page-subscription app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
                <Header />

                <div className="app-main">
                    <div className="app-sidebar sidebar-shadow">
                        <Sidebar />
                    </div>
                    <div className="app-main__outer">
                        <div className={ `bg-under-header editModeTitle ${this.state.editMode ? 'editMode' : ''}` } >
                           <span> Mode édition</span>
                        </div>
                        <div className="app-main__inner"> 

                            <div className="row">
                                <div className="col-sm-5 left-side">
                                    <h1>
                                        <svg width="35" height="47" viewBox="0 0 35 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24.9375 23.5H28.125V5.4375C28.125 3.67709 26.6979 2.25 24.9375 2.25H5.8125C4.05209 2.25 2.625 3.67709 2.625 5.4375V23.5H5.8125V22.7188C5.8125 20.8527 6.79159 19.1235 8.39174 18.1634L10.4529 16.9267C9.54518 15.8252 9 14.4138 9 12.875C9 9.35419 11.8542 6.5 15.375 6.5C18.8958 6.5 21.75 9.35419 21.75 12.875C21.75 14.4138 21.2048 15.8252 20.2971 16.9267L22.3583 18.1634C23.9584 19.1235 24.9375 20.8527 24.9375 22.7188V23.5ZM22.8125 23.5V22.7188C22.8125 21.5992 22.225 20.5616 21.265 19.9856L18.5905 18.3809C17.6465 18.9333 16.5477 19.25 15.375 19.25C14.2023 19.25 13.1035 18.9333 12.1595 18.3809L9.48504 19.9856C8.52495 20.5616 7.9375 21.5992 7.9375 22.7188V23.5H22.8125ZM28.125 25.625H2.625V37.3125C2.625 39.0729 4.05209 40.5 5.8125 40.5H24.9375C26.6979 40.5 28.125 39.0729 28.125 37.3125V25.625ZM30.161 4.464C32.6301 4.92126 34.5 7.08601 34.5 9.6875V41.5625C34.5 44.4965 32.1215 46.875 29.1875 46.875H10.0625C7.46101 46.875 5.29626 45.0051 4.839 42.536C2.36991 42.0787 0.5 39.914 0.5 37.3125V5.4375C0.5 2.50349 2.87849 0.125 5.8125 0.125H24.9375C27.539 0.125 29.7037 1.99491 30.161 4.464ZM7.05638 42.625C7.49395 43.863 8.67464 44.75 10.0625 44.75H29.1875C30.9479 44.75 32.375 43.3229 32.375 41.5625V9.6875C32.375 8.29964 31.488 7.11895 30.25 6.68138V37.3125C30.25 40.2465 27.8715 42.625 24.9375 42.625H7.05638ZM15.375 17.125C17.7222 17.125 19.625 15.2222 19.625 12.875C19.625 10.5278 17.7222 8.625 15.375 8.625C13.0278 8.625 11.125 10.5278 11.125 12.875C11.125 15.2222 13.0278 17.125 15.375 17.125ZM7.9375 32C7.3507 32 6.875 31.5243 6.875 30.9375C6.875 30.3507 7.3507 29.875 7.9375 29.875H22.8125C23.3993 29.875 23.875 30.3507 23.875 30.9375C23.875 31.5243 23.3993 32 22.8125 32H7.9375ZM7.9375 36.25C7.3507 36.25 6.875 35.7743 6.875 35.1875C6.875 34.6007 7.3507 34.125 7.9375 34.125H18.5625C19.1493 34.125 19.625 34.6007 19.625 35.1875C19.625 35.7743 19.1493 36.25 18.5625 36.25H7.9375Z" fill="black"/>
                                        </svg> 
                                        Fiche Entreprise
                                    </h1>

                                    <div className="edit-mode">
                                        <BootstrapSwitchButton
                                            onChange={(checked)=>{this.changeEditMode(checked)}} 
                                            checked={this.state.editMode} 
                                            />
                                        { this.state.editMode ? 'Désactiver' : 'Activer' } le mode édition
                                    </div>

                                    <div className="sub-menu">
                                        <ul>
                                            <li>
                                                <Link to="/back-office/home">
                                                    <i className="fa fa-home"></i>
                                                    Accueil
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <hr /> 

                                    <div className="records">
                                        <div className="record">
                                            <h3>Extension 10 contrats/100 pages</h3>
                                            <p>18 Avril 2020 - 60 contrats/600 pages</p> 
                                        </div>
                                        <div className="record">
                                            <h3>Ajout Utilisateurs</h3>
                                            <p>18 Avril 2020 - 2 Utilisateurs ajoutés</p> 
                                        </div>
                                        <div className="record">
                                            <h3>Pack Pro</h3>
                                            <p>3 Juillet 2019 - 50 contrats/500 pages</p> 
                                        </div>
                                        <div className="record">
                                            <h3>Starter Pack</h3>
                                            <p>13 Mars 2019 - 15 contrats/150 pages</p> 
                                        </div>
                                    </div>

                                     
                                </div>

                                <div className="col-sm-7 viewer">

                                    <h2>Montant Actuel : 112,40 € HT</h2>
                                    
                                    <div className="head">
                                        <button onClick={()=>{this.export()}} className="btn btm-light"><i className="lnr-download"></i></button>
                                        <button onClick={()=>{this.print()}} className="btn btm-light"><i className="lnr-printer"></i></button>
                                    </div> 

                                    <section className="regulations-information">
                                        <h3>Informations règlements</h3>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <strong>Echéances :</strong>
                                            </div>
                                            <div className="col-sm-9">
                                                <div className="switchButton">
                                                    <label>Mensuelles</label>
                                                        <BootstrapSwitchButton/>
                                                    <label>Annuelles (remise 3%)</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3">
                                                <strong>Type règlement :</strong>
                                            </div>
                                            <div className="col-sm-9">
                                                <div className="switchButton">
                                                    <label>Autre</label>
                                                        <BootstrapSwitchButton/>
                                                    <label>Prélèvements (remise 3%)</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3">
                                                <strong>Engagement</strong>
                                            </div>
                                            <div className="col-sm-9">
                                                <div className="switchButton">
                                                    <label>3 ans</label>
                                                        <BootstrapSwitchButton/>
                                                    <label>5 ans (remise 10%)</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <strong>Remise Commerciale 5 %</strong>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="subscription">
                                        <h3>Sélection abonnement</h3>

                                        <div className="switchButton disabled"> 
                                            <BootstrapSwitchButton/>
                                            <label>Starter Pack  (15 contrats ou 150 pages*)  pour 26,18 € HT/mois*</label>
                                        </div>

                                        <div className="switchButton disabled"> 
                                            <BootstrapSwitchButton/>
                                            <label>Starter Pack  Avancé (30 contrats ou 300 pages) pour 52,35 € HT/mois*</label>
                                        </div>

                                        
                                        
                                        <div className="switchButton rangeInputContainer"> 
                                            <BootstrapSwitchButton checked={true}/>
                                            <label>Pack  Pro 50 (50 contrats ou 500 pages) pour <strong>123,00 €HT/mois*</strong></label>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <label className="rangelabel">
                                                        Extension
                                                    </label>
                                                </div>
                                                <div className="col-sm-8">
                                                <Slider
                                                    className="rangeInput"
                                                    mode={2}
                                                    step={5}
                                                    domain={[0, 35]}
                                                    rootStyle={sliderStyle}
                                                    onUpdate={this.onUpdate}
                                                    onChange={this.onChange}
                                                    values={[10]}
                                                    >
                                                    <Rail>
                                                        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                                                    </Rail>
                                                    <Handles>
                                                        {({ handles, getHandleProps }) => (
                                                        <div className="slider-handles">
                                                            {handles.map(handle => (
                                                            <Handle
                                                                key={handle.id}
                                                                handle={handle}
                                                                domain={[0, 35]}
                                                                getHandleProps={getHandleProps}
                                                            />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Handles>
                                                    <Tracks right={false}>
                                                        {({ tracks, getTrackProps }) => (
                                                        <div className="slider-tracks">
                                                            {tracks.map(({ id, source, target }) => (
                                                            <Track
                                                                key={id}
                                                                source={source}
                                                                target={target}
                                                                getTrackProps={getTrackProps}
                                                            />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Tracks>
                                                    <Ticks count={5}>
                                                        {({ ticks }) => (
                                                        <div className="slider-ticks">
                                                            {ticks.map(tick => (
                                                            <Tick key={tick.id} tick={tick} count={ticks.length} />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Ticks>
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="switchButton rangeInputContainer"> 
                                            <BootstrapSwitchButton checked={true}/>
                                            <label>Pack  Pro 100 (100 contrats ou 1 000 pages) à partir de 143,00 €/mois*</label>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <label className="rangelabel">
                                                        Extension
                                                    </label>
                                                </div>
                                                <div className="col-sm-8">
                                                <Slider
                                                    className="rangeInput"
                                                    mode={2}
                                                    step={10}
                                                    domain={[0, 70]}
                                                    rootStyle={sliderStyle}
                                                    onUpdate={this.onUpdate}
                                                    onChange={this.onChange}
                                                    values={[0]}
                                                    >
                                                    <Rail>
                                                        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                                                    </Rail>
                                                    <Handles>
                                                        {({ handles, getHandleProps }) => (
                                                        <div className="slider-handles">
                                                            {handles.map(handle => (
                                                            <Handle
                                                                key={handle.id}
                                                                handle={handle}
                                                                domain={[0, 70]}
                                                                getHandleProps={getHandleProps}
                                                            />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Handles>
                                                    <Tracks right={false}>
                                                        {({ tracks, getTrackProps }) => (
                                                        <div className="slider-tracks">
                                                            {tracks.map(({ id, source, target }) => (
                                                            <Track
                                                                key={id}
                                                                source={source}
                                                                target={target}
                                                                getTrackProps={getTrackProps}
                                                            />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Tracks>
                                                    <Ticks count={5}>
                                                        {({ ticks }) => (
                                                        <div className="slider-ticks">
                                                            {ticks.map(tick => (
                                                            <Tick key={tick.id} tick={tick} count={ticks.length} />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Ticks>
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="switchButton rangeInputContainer"> 
                                            <BootstrapSwitchButton checked={true}/>
                                            <label>Pack  Pro 200 (200 contrats ou 2 000 pages) à partir de 246,00 € HT/mois*</label>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <label className="rangelabel">
                                                        Extension
                                                    </label>
                                                </div>
                                                <div className="col-sm-8">
                                                <Slider
                                                    className="rangeInput"
                                                    mode={2}
                                                    step={20}
                                                    domain={[0, 140]}
                                                    rootStyle={sliderStyle}
                                                    onUpdate={this.onUpdate}
                                                    onChange={this.onChange}
                                                    values={[0]}
                                                    >
                                                    <Rail>
                                                        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                                                    </Rail>
                                                    <Handles>
                                                        {({ handles, getHandleProps }) => (
                                                        <div className="slider-handles">
                                                            {handles.map(handle => (
                                                            <Handle
                                                                key={handle.id}
                                                                handle={handle}
                                                                domain={[0, 140]}
                                                                getHandleProps={getHandleProps}
                                                            />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Handles>
                                                    <Tracks right={false}>
                                                        {({ tracks, getTrackProps }) => (
                                                        <div className="slider-tracks">
                                                            {tracks.map(({ id, source, target }) => (
                                                            <Track
                                                                key={id}
                                                                source={source}
                                                                target={target}
                                                                getTrackProps={getTrackProps}
                                                            />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Tracks>
                                                    <Ticks count={5}>
                                                        {({ ticks }) => (
                                                        <div className="slider-ticks">
                                                            {ticks.map(tick => (
                                                            <Tick key={tick.id} tick={tick} count={ticks.length} />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Ticks>
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="switchButton"> 
                                            <BootstrapSwitchButton/>
                                            <label>Pack  illimité (sans limite d‘utilisateurs, de contrats ou de pages) </label> 
                                            <p className="pack-description">Cette offre vous reviendrait à 1,03 % du montant annuel** de tous vos contrats soit <strong>232,00 € HT/mois*</strong></p>
                                        </div>

                                        <div className="legal-notice">
                                            La facturation commencera à partir du mois suivant, le prorata du mois en cours est offert.<br />
                                            * Ce montant tient compte de vos remises.<br />
                                            ** payable uniquement annuellement à date de souscription, le montant sera réévalué à chaque date anniversaire en fonction du montant total des contrats actifs au cours des 365 derniers jours. Le montant annuel minimum de facturation est de 3 600 € HT.
                                        </div>

                                        <div className="update-subscription">
                                            <button className="btn btn-large btn-primary">Modifier l’abonnement</button>
                                        </div>

                                    </section>

                                    <section className="manage-users">
                                        <h3>Gestion des utilisateurs</h3>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                Nombre d’utilisateurs Max : 7
                                            </div>
                                            <div className="col-sm-6">
                                                Nombre d’utilisateurs créés : 5
                                            </div>
                                        </div>
                                        <form>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    Demande d’utilisateurs supplémentaires :
                                                </div>
                                                <div className="col-sm-6">
                                                   <input type="number" className="form-control"></input> x 10 € HT/mois
                                                </div>
                                                <div className="col-sm-12 add-users">
                                                        <button className="btn btn-large btn-primary">Modifier l’abonnement</button>
                                                </div>
                                            </div>
                                        </form> 
                                    </section>


                                    <section className="graphs">
                                        <h3>Evolution de votre abonnement</h3>
                                        {
                                            this.state?.chartMode == 'quarterly' && 
                                            <Chart
                                                width={'100%'}
                                                height={400}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.state.quarterlyChartData}
                                                options={{
                                                    title: '',
                                                    chartArea: { width: '80%' },
                                                    hAxis: {
                                                        title: 'Montant abonnement',
                                                        minValue: null,
                                                    },
                                                    vAxis: {
                                                        title: 'En (€)',
                                                    },
                                                    titleTextStyle: { color: '#7077A5' } ,
                                                    colors: ['#F66031']
                                                }}
                                                legendToggle
                                            />
                                        }
                                        {
                                            this.state?.chartMode == 'yearly' &&  
                                            <Chart
                                                width={'100%'}
                                                height={400}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.state.yearlyChartData}
                                                options={{
                                                    title: '',
                                                    chartArea: { width: '80%' },
                                                    hAxis: {
                                                        title: 'Montant abonnement',
                                                        minValue: null,
                                                    },
                                                    vAxis: {
                                                        title: 'En (€)',
                                                    },
                                                    titleTextStyle: { color: '#7077A5' } ,
                                                    colors: ['#F66031']
                                                }}
                                                legendToggle
                                            />
                                        }
                                    
                                        <center className="switchButton toggleColored">
                                            <label>Par Trimestres</label>
                                                <BootstrapSwitchButton checked={this.state?.chartMode == 'yearly' }  onChange={(checked)=>{this.changeGraphMode(checked)}} />
                                            <label>Par ans</label>
                                        </center>
                                        
                                        <center>
                                            <p>
                                                Doc @t Web vous permet de sécuriser un montant Total de contrat de <br />
                                                <strong>214 240 €</strong>
                                            </p>
                                        </center>

                                    </section>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Subscription;