import React from "react";
import { CopyIcon } from "../../../assets/icons";

const ButtonRadioUnChecked = ({ onPress}) => (
  <button
    type="button"
    className={`btn btn-default button_radio_custom`}
    onClick={onPress}
  >
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.5" cy="10.5" r="10" fill="#FAFCFE" stroke="#190734" />
    </svg>
  </button>
);

export default ButtonRadioUnChecked;
