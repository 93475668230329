import React, { Component } from 'react';
import { BrowserRouter as Router, Link, withRouter } from 'react-router-dom';

import './Home.scss';

//plugins
import { StickyContainer, Sticky } from 'react-sticky';
import {
  getContracts,
  getTimeLineData,
  associateContractToFavouritesList,
  dissociateContractToFavouritesList,
  associateDocumentToFavouritesList,
  dissociateDocumentToFavouritesList,
  manageHomeTabsAndCompanies,
  getHomeTabsColumns,
  manageHomeTabsColumns,
  getHomeTabsSearch
} from '../../../../api/home';
import $ from 'jquery';
import { deleteUnvalidatedContracts } from '../../../../api/validateContract';
import NotifyAlert from '../../../../components/confirm-alert/notify-alert/NotifyAlert';
import Moment from 'moment';

// components
import Header from '../../../../components/layouts/header/Header';
import Sidebar from '../../../../components/layouts/sidebar/Sidebar';
import ErrorAlert from '../../../../components/confirm-alert/error-alert';
import ConfirmAlert from '../../../../components/confirm-alert/ConfirmAlert';
import LoaderComponent from '../../../../components/loader/Loader';

//parts
import CarouselTimelineDashBords from './parts/Carousels/timeLine/CarouselTimelineDashBords';
import TimeLine from './parts/timeline';
import Tabs from './parts/tabs';
import TabContent from './parts/tabs/TabContent';
import RecentValidatedContract from './parts/Carousels/contracts/RecentValidateContract';
import RecentUnValidatedContract from './parts/Carousels/contracts/RecentUnValidateContract';
import RecentContractsInNegociation from './parts/Carousels/contracts/RecentContractsInNegociation';
import RecentDocuments from './parts/Carousels/contracts/RecentDocuments';
import ListingValidatedContract from './parts/listing/ListingValidatedContract';
import ListingUnValidatedContract from './parts/listing/ListingUnValidatedContract';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import ListingInNegociationContract from './parts/listing/ListingInNegociationContract';
import Cookies from "js-cookie";
import ListingGuestNegociationContract from './parts/listing/ListingGuestNegociationContract';
import { getNegociationsByUserType } from '../../../../api/negociation';
import ListingLinkedDocuments from './parts/listing/ListingLinkedDocuments';
import ListingAllContracts from './parts/listing/ListingAllContracts';
import { getUserById } from '../../../../api/user';
import ListingArchivedContract from './parts/listing/ListingArchivedContract';
import { toast, ToastContainer } from "react-toastify";
class Home extends Component {
  constructor() {
    super();
    this.state = {
      userRights: JSON.parse(Cookies.get("userRights")),
      currentClient: Cookies.get("currentClient"),
      currentNegosClient: /*(Cookies.get("currentNegosClient"))? JSON.parse(Cookies.get("currentNegosClient")) : */[],
      currentNegosClientForGuest: (Cookies.get("currentNegosClientForGuest"))? JSON.parse(Cookies.get("currentNegosClientForGuest")) : [],
      currentNegosClientForUser:[],
      isTimeLineSticky: false,
      contrats: null,
      mergedContratctsList: null,
      timelineData: null,
      currentTimeLineUnit: 1,
      currentDay: null,
      currentIndex: null,
      showAllContracts: false,
      selectAll: false,
      selectedContracts: [],
      displayRecent:true,
      displayTimeline:true,
      isSticky:false,
      isRecentBlocVisible:true,
      stopCalculateScroll: false,
      itemsTabs:[],
      tabs:['validated_contracts', 'negotiation_contracts', 'unvalidated_contracts', 'all', 'documents', 'archives'],
      draggedIndex: null,
      selectedTab: (localStorage.getItem("selectedTab")) ? localStorage.getItem("selectedTab") : "#tab-6",
      defaultClient: null,
      defaultRightType: null,
      homeTabsColumns: null,
      homeTabsSearch: null,
      archiveRedirection: null,
      pinTimeline: (Cookies.get("pinTimeline"))? JSON.parse(Cookies.get("pinTimeline")):false
    };
    this.toggleAllChange = this.toggleAllChange.bind(this);
    this.toggleOneChange = this.toggleOneChange.bind(this);

    // this.handleDragStart = this.handleDragStart.bind(this);
    // this.handleDragEnter = this.handleDragEnter.bind(this);

    // reload main js
    // if (!$('#homeDataTable1').hasClass('dataTable')) {
    //   let js_src = '/assets/js/main.js';
    //   $('script[src="' + js_src + '"]').remove();
    //   $('<script>').attr('src', js_src).appendTo('head');
    // }
  }

  loadColumns(){
    console.log("Load")
    getHomeTabsColumns().then(response=>{
      console.log("response",response['data'])
      if(response['data'] && response['data'] !== undefined && response['data'].tabs !== undefined ){
        let cloumnsHome  = JSON.parse(response['data'].tabs)
        let checkArchiveBox = cloumnsHome["items"]?.find(x=>x.key ==='archive_box_contracts')
        if(!checkArchiveBox)
          cloumnsHome["items"].push(
            {
              key: 'archive_box_contracts',
              columns: ['number', 'contract_full_name', 'category', 'archive_date', 'arch_del', 'amount', 'end_date']
            }
        )
        if (this.state.currentClient !== undefined && JSON.parse(this.state.currentClient).roleType !== "GUEST" && this.state.userRights.id !== undefined){
          this.setState({
            ...this.state,
            homeTabsColumns: cloumnsHome
          })
        }
      }else{
        this.setState({
          ...this.state,
          homeTabsColumns: {
            items: [
              {
                key: 'all_contracts',
                columns: ['number', 'third_party_name', 'contract_full_name', 'category', 'type', 'status', 'amount', 'negotiation', 'date_of_notice', 'end_date', 'tag']
              },
              {
                key: 'validated_contracts',
                columns: ['number', 'third_party_name', 'contract_full_name', 'category', 'type', 'accounting_code', 'amount', 'negotiation', 'date_of_notice', 'end_date', 'tag']
              },
              {
                key: 'unvalidated_contracts',
                columns: ['contract_full_name', 'category', 'type', 'status', 'imported_by', 'importation_date', 'tag']
              },
              {
                key: 'in_negociation_contracts',
                columns: ['number', 'third_party_name', 'contract_full_name', 'category', 'type', 'status', 'speaker', 'cumul_duration', 'tag', 'negociatories', 'signatories']
              },
              {
                key: 'linked_documents',
                columns: ['contract', 'date_link', 'object', 'type', 'sender']
              },
              {
                key: 'archive_box_contracts',
                columns: ['number', 'contract_full_name', 'category', 'archive_date', 'arch_del', 'amount', 'end_date']
              },
            ]
          }
        })
      }
    })
  }
  
  loadSearchData(){
    console.log("Load")
    getHomeTabsSearch().then(response=>{
      console.log("getHomeTabsSearch",response['data'])
      console.log("getHomeTabsSearch",this.organizeSearchConfigurations(response['data']))
      this.setState({
        ...this.state,
        homeTabsSearch: this.organizeSearchConfigurations(response['data'])
      })
    })
  }

  // Function to organize search configurations by key
  organizeSearchConfigurations(searchConfigurations) {
    const organizedConfigurations = {};
    searchConfigurations.forEach(config => {
      const parsedConfig = { ...config }; // Create a shallow copy
      parsedConfig.fields = JSON.parse(config.fields);
      const key = parsedConfig.fields.key;
      if (!organizedConfigurations[key]) {
        organizedConfigurations[key] = { key: key, configurationsList: [] };
      }
      organizedConfigurations[key].configurationsList.push(parsedConfig);
    });
    return Object.values(organizedConfigurations);
  }


  toggleAllChange(event) {
    const { name, checked } = event.target;
    this.setState({ ...this.state, selectAll: checked });

    if (checked) {
      var contracts = this.state.selectedContracts;
      this.state.contrats.unvalidatedContracts.map((contrat) => {
        if (!contracts.includes(contrat.id)) {
          contracts.push(contrat.id);
        }
      });
      this.setState({ ...this.state, selectedContracts: contracts });
    } else {
      this.setState({ ...this.state, selectedContracts: [] });
    }
  }

  toggleOneChange(event, id) {
    const { name, checked } = event.target;

    if (checked) {
      var contracts = this.state.selectedContracts;
      if (!contracts.includes(id)) {
        contracts.push(id);
      }
      this.setState({ ...this.state, selectedContracts: contracts });
      if (this.state.selectedContracts.length == this.state.contrats.unvalidatedContracts.length) {
        this.setState({ ...this.state, selectAll: true });
      }
    } else {
      this.setState({ ...this.state, selectAll: false });
      var contracts = this.state.selectedContracts;
      if (contracts.includes(id)) {
        for (var i = 0; i < contracts.length; i++) {
          if (contracts[i] === id) {
            contracts.splice(i, 1);
          }
        }
      }
      this.setState({ ...this.state, selectedContracts: contracts });
    }
  }

  componentWillUnmount() {

    window.removeEventListener('scroll', function (event) { });

    var tbodyElement = document.querySelector('.table-container');
    if (tbodyElement)
      tbodyElement.removeEventListener('scroll', function (event) { });
      document.body.style.overflow = 'auto';
  }


  componentWillMount() {
    const queryString = require("query-string");
    var parsed = queryString.parse(this.props.location.search);
    

    if(parsed && parsed.tab && parsed.client){
      
      this.setState({
        ...this.state,
        selectedTab: "#tab-archives",
        archiveRedirection:{
          clientId: parsed.client,
          tab: parsed.tab
        }
      },()=>{
        localStorage.setItem('selectedTab', this.state.selectedTab);
      })
    }else{
      this.setState({
        ...this.state,
        archiveRedirection: null
      })
    }
  }

  addArchiveBoxToContractList(contractList){
    let contractsForTest = contractList?.validatedContracts?.filter(x=>x.archiveData || x.toArchive)
    contractList.archiveBoxContracts = contractsForTest
    contractList.validatedContracts = contractList?.validatedContracts?.filter(x=> !(x.archiveData || x.toArchive))
    const associatedDocuments = contractList.documents.map(item=>{return{...item, alreadyAssociated: true}})
    const unassociatedDocuments = contractList.unassociatedDocuments.map(item=>{return{...item, alreadyAssociated: false}})
    contractList.documents =  [...associatedDocuments , ...unassociatedDocuments]
    return contractList

  }

  componentDidMount() {
    // LoaderComponent.show();
    var today = new Date();
    var day = today.getDay();
    
    

    this.setState({
      ...this.state,
      currentDay: day - 1,
      currentIndex: day - 1,
    });

      if (this.state.currentClient) {
        if (JSON.parse(this.state.currentClient).roleType === "GUEST") {
          if(!this.state.currentNegosClient){

            this.setState({
              ...this.state,
              userRights: {
                ...this.state.userRights,
                negociations: this.state.currentNegosClient
              }
            });
          }else{
            if(JSON.parse(this.state.currentClient).roleType == "USER" || JSON.parse(this.state.currentClient).roleType == "GUEST")
              getNegociationsByUserType(this.state.userRights.id, JSON.parse(this.state.currentClient).roleType).then((response) => {
                // Cookies.set("currentNegosClient",response['data']);
                this.setState({
                  ...this.state,
                  userRights:{
                    ...this.state.userRights,
                    negociations:response['data']
              }
                });
              });
          }
        }
        if (JSON.parse(this.state.currentClient).roleType !== "GUEST") {


          if(this.state.userRights.id !== undefined){
            getUserById(this.state.userRights.id).then((response) => {
              this.setState({
                ...this.state,
                tabs: response.data["tabs"].replace("recent", "archives"),
                defaultClient: response.data["defaultClient"] !== undefined ? response.data["defaultClient"] : null,
                defaultRightType: response.data["defaultRightType"] !== undefined ? response.data["defaultRightType"] : null
              });
            });
          }
          
          // Uncomment for api save
          this.loadColumns()

          this.loadSearchData()

          // Check params  from url and set them to the state
          
          getContracts().then((response) => {
            let mergedContratctsList = [];
            let contracts = response['data'];
            
            
            if(contracts['validatedContracts'] && contracts['validatedContracts'].length>0){
              contracts['validatedContracts'].forEach(obj => {obj.tab = 'validated'});
              mergedContratctsList = mergedContratctsList.concat(...contracts['validatedContracts'])
            }
            if(contracts['unvalidatedContracts'] && contracts['unvalidatedContracts'].length>0){
              contracts['unvalidatedContracts'].forEach(obj => {obj.tab = 'unvalidated'});
              mergedContratctsList = mergedContratctsList.concat(...contracts['unvalidatedContracts'])
            }
            if(contracts['inNegociationContracts'] && contracts['inNegociationContracts'].length>0){
              contracts['inNegociationContracts'].forEach(obj => {obj.tab = 'in_negociation'});
              mergedContratctsList = mergedContratctsList.concat(...contracts['inNegociationContracts'])
            }
            let itemsTabs = [
              {
                id: '#tab-1',
                label: t('frontoffice.home.contracts.tabs.validated_contracts'),
                count: (response.data?.validatedContracts?.filter(x=>!(x.archiveData || x.toArchive)))?.length || 0,
                key: 'validated_contracts'
              },
              {
                id: '#tab-3',
                label: t('frontoffice.home.contracts.tabs.negotiation_contracts'),
                count: response.data?.inNegociationContracts?.length || 0,
                key: 'negotiation_contracts',
                active: false
              },
              {
                id: '#tab-2',
                label: t('frontoffice.home.contracts.tabs.unvalidated_contracts'),
                count: response.data?.unvalidatedContracts?.length || 0,
                key: 'unvalidated_contracts',
                active: false
              },
              {
                id: '#tab-6',
                label: t('frontoffice.home.contracts.tabs.all'),
                count: mergedContratctsList?.length || 0,
                key: 'all',
                active: true
              },
              {
                id: '#tab-4',
                label: t('frontoffice.home.contracts.tabs.documents'),
                count: response.data?.documents?.length || 0,
                key: 'documents',
                active: false
              },
              {
                id: '#tab-archives',
                label: t('frontoffice.home.contracts.tabs.archives'),
                count: (response.data?.validatedContracts?.filter(x=>x.archiveData || x.toArchive))?.length || 0,
                key: 'archives',
                active: false
              }
            ]
            itemsTabs.sort((a, b) => {
              const indexA = this.state.tabs.indexOf(a.key);
              const indexB = this.state.tabs.indexOf(b.key);
            
              return indexA - indexB;
            });
            if(localStorage.getItem("selectedTab") !== undefined && localStorage.getItem("selectedTab") !== null && localStorage.getItem("selectedTab") !== "" ){
              itemsTabs = itemsTabs.map(tab => ({ ...tab, active: tab.id === localStorage.getItem("selectedTab") }));
            }
            this.setState(
              {
                ...this.state,
                contrats: this.addArchiveBoxToContractList(response.data),
                mergedContratctsList: mergedContratctsList,
                itemsTabs: itemsTabs ? itemsTabs : []
              },
              () => {
                const component = this;
                var elementsDom = document.querySelectorAll('[data-module="sticky-table"]');
                var btnTopScroll = document.querySelector('.scroll-to-top');

                var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;


                window.addEventListener('scroll', function (event) {
                  scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
                  if (!component.state.currentClient || JSON.parse(component.state.currentClient).roleType !== "GUEST") {
                    console.log(scrollPosition)
                    elementsDom.forEach(function (el) {
                      if (!component.state.stopCalculateScroll) {
                        if (scrollPosition >= 430 && !component.state.isRecentBlocVisible) {
                          window.scrollTo(0, 435);
                          document.body.style.overflow = 'hidden';
                          btnTopScroll.style.display = 'block';
                          el.classList.add('fixed-content');
                          console.log("stage1")
                        } else if (scrollPosition >= 490 && !component.state.displayRecent) {
                          window.scrollTo(0, 495);
                          btnTopScroll.style.display = 'block';
                          document.body.style.overflow = 'hidden';
                          el.classList.add('fixed-content');
                          console.log("stage2")
                        } else if (scrollPosition >= 170) {
                          window.scrollTo(0, 360);
                          btnTopScroll.style.display = 'block';
                          document.body.style.overflow = 'hidden';
                          el.classList.add('fixed-content');
                          console.log("stage3")
                        } else {
                          btnTopScroll.style.display = 'none';
                          document.body.style.overflow = 'auto';
                          el.classList.remove('fixed-content');
                          console.log("stage4")
                        }
                      }
                      if (scrollPosition == 0) {
                        component.setState({
                          ...component.state,
                          stopCalculateScroll: false
                        })
                      }
                    })
                  }
                });
                this.hideTheLoadComponent();
              }
            );
          });
          getTimeLineData().then((response) => {
            this.setState(
              {
                ...this.state,
                timelineData: response.data,
              },
              () => {
                this.hideTheLoadComponent();
              }
            );
          });
          if(JSON.parse(this.state.currentClient).roleType == "USER" || JSON.parse(this.state.currentClient).roleType == "GUEST")
            getNegociationsByUserType(this.state.userRights.id, JSON.parse(this.state.currentClient).roleType).then((response) => {
              this.setState({
                ...this.state,
                currentNegosClientForUser:response['data']?response['data'].map(ids=>ids.contractId):[]
              });
            })
        }
      }
    }

  hideTheLoadComponent() {
    if (this.state.contrats != null && this.state.timelineData != null) {
      LoaderComponent.hide();
    }
  }

  HandleTimeLineStickyStateChange = (isSticky) => {
    if (isSticky !== this.state.isTimeLineSticky) {
      this.setState({
        ...this.state,
        isTimeLineSticky: isSticky,
      });
    }
  };

  //////// set Favorite to specific Contrat
  setFavoriteContrat = (contrat, index, listName) => {
    let contrats = this.state.contrats;
    contrats[listName][index].favorite = !contrat.favorite;
    if (contrat.favorite === true) {
      associateContractToFavouritesList(contrat.id).then(
        (response) => {
          this.setState({
            ...this.state,
            contrats: contrats,
          });
        },
        (error) => {
          ErrorAlert.show(error.response.data, 'Erreur API');
        }
      );
    } else {
      dissociateContractToFavouritesList(contrat.id).then(
        (response) => {
          this.setState({
            ...this.state,
            contrats: contrats,
          });
        },
        (error) => {
          ErrorAlert.show(error.response.data, 'Erreur API');
        }
      );
    }
  };

  setFavoriteDocument = (contrat, index) => {
    let contrats = this.state.contrats;
    contrats.recentDocuments[index].favorite = !contrat.favorite;
    if (contrat.favorite === true) {
      associateDocumentToFavouritesList(contrat.id).then(
        (response) => {
          this.setState({
            ...this.state,
            contrats: contrats,
          });
        },
        (error) => {
          ErrorAlert.show(error.response.data, 'Erreur API');
        }
      );
    } else {
      dissociateDocumentToFavouritesList(contrat.id).then(
        (response) => {
          this.setState({
            ...this.state,
            contrats: contrats,
          });
        },
        (error) => {
          ErrorAlert.show(error.response.data, 'Erreur API');
        }
      );
    }
  };

  handleDoubleClick = (hachedId,avt=null) => {
    if(avt === 1 || avt === true)
      this.props.history.push('/manage-contract/endorsements?contract=' + hachedId);
    else
      this.props.history.push('/manage-contract/validate?contract=' + hachedId);
  };

  handleNegoDoubleClick = (hachedId) => {
    this.props.history.push('/manage-contract/negociations?contract=' + hachedId);
  };

  handleDocumentDoubleClick = (hachedId, contractHachedId) => {
    this.props.history.push(
      '/manage-contract/linked-documents?contract=' + contractHachedId + '&document=' + hachedId
    );
  };

  changeTimeLineUnit = (newUnit) => {
    this.setState({
      ...this.state,
      currentTimeLineUnit: newUnit,
    });
  };

  setNewList = (list, type, index) => {
    let contracts = this.state.contrats;
    contracts.validatedContracts = list;
    this.setState({
      ...this.state,
      contrats: contracts,
      showAllContracts: true,
      currentIndex: index
    });
  };

  getCardClassName = (contract) => {
    let currentTomeLineContracts = this.getCurrentContractsTimeLineList();
    let currentDate = Moment().format('DD/MM/YYYY');
    currentDate = Moment(currentDate, 'DD/MM/YYYY');
    let foundNegociation = null;
    let foundPriorNotice = null;
    let foundEnding = null;

    if (currentTomeLineContracts) {
      for (let i = 0; i < currentTomeLineContracts.ending.length; i++) {
        if (currentTomeLineContracts.ending[i].id === contract.id) {
          foundEnding = Moment(currentTomeLineContracts.ending[i].date_fin, 'DD/MM/YYYY');
        }
      }
      for (let i = 0; i < currentTomeLineContracts.priorNotice.length; i++) {
        if (currentTomeLineContracts.priorNotice[i].id === contract.id) {
          foundPriorNotice = Moment(
            currentTomeLineContracts.priorNotice[i].date_preavi,
            'DD/MM/YYYY'
          );
        }
      }
      for (let i = 0; i < currentTomeLineContracts.negociation.length; i++) {
        if (currentTomeLineContracts.negociation[i].id === contract.id) {
          foundNegociation = Moment(
            currentTomeLineContracts.negociation[i].date_negociation,
            'DD/MM/YYYY'
          );
        }
      }
      if (
        foundNegociation &&
        (foundNegociation.isAfter(currentDate) || foundNegociation.isSame(currentDate))
      ) {
        let differenceNegociation = currentDate.diff(foundNegociation, 'seconds');

        if (
          foundPriorNotice &&
          foundEnding &&
          (foundPriorNotice.isAfter(currentDate) || foundPriorNotice.isSame(currentDate)) &&
          (foundEnding.isAfter(currentDate) || foundEnding.isSame(currentDate))
        ) {
          //if (currentDate.diff(foundPriorNotice, 'seconds') > differenceNegociation && currentDate.diff(foundEnding, 'seconds') > differenceNegociation) {
          return 'card-shadow-success';
          //}
        } else if (
          foundPriorNotice &&
          (foundPriorNotice.isAfter(currentDate) || foundPriorNotice.isSame(currentDate))
        ) {
          //if (currentDate.diff(foundPriorNotice, 'seconds') > differenceNegociation) {
          return 'card-shadow-success';
          //}
        } else if (
          foundEnding &&
          (foundEnding.isAfter(currentDate) || foundEnding.isSame(currentDate))
        ) {
          //if (currentDate.diff(foundEnding, 'seconds') > differenceNegociation) {
          return 'card-shadow-success';
          //}
        } else {
          return 'card-shadow-success';
        }
      }

      if (
        foundPriorNotice &&
        (foundPriorNotice.isAfter(currentDate) || foundPriorNotice.isSame(currentDate))
      ) {
        let differencePriorNotice = currentDate.diff(foundPriorNotice, 'seconds');

        if (
          foundNegociation &&
          foundEnding &&
          (foundNegociation.isAfter(currentDate) || foundNegociation.isSame(currentDate)) &&
          (foundEnding.isAfter(currentDate) || foundEnding.isSame(currentDate))
        ) {
          //if (currentDate.diff(foundNegociation, 'seconds') > differencePriorNotice && currentDate.diff(foundEnding, 'seconds') > differencePriorNotice) {
          return 'card-shadow-primary';
          //}
        } else if (
          foundNegociation &&
          (foundNegociation.isAfter(currentDate) || foundNegociation.isSame(currentDate))
        ) {
          //if (currentDate.diff(foundNegociation, 'seconds') > differencePriorNotice) {
          return 'card-shadow-primary';
          //}
        } else if (
          foundEnding &&
          (foundEnding.isAfter(currentDate) || foundEnding.isSame(currentDate))
        ) {
          //if (currentDate.diff(foundEnding, 'seconds') > differencePriorNotice) {
          return 'card-shadow-primary';
          //}
        } else {
          return 'card-shadow-primary';
        }
      }
      if (foundEnding && (foundEnding.isAfter(currentDate) || foundEnding.isSame(currentDate))) {
        let differenceEnding = currentDate.diff(foundEnding, 'seconds');

        if (
          foundNegociation &&
          foundPriorNotice &&
          (foundPriorNotice.isAfter(currentDate) || foundPriorNotice.isSame(currentDate)) &&
          (foundNegociation.isAfter(currentDate) || foundNegociation.isSame(currentDate))
        ) {
          //if (currentDate.diff(foundNegociation, 'seconds') > differenceEnding && currentDate.diff(foundPriorNotice, 'seconds') > differenceEnding) {
          return 'card-shadow-danger';
          //}
        } else if (
          foundNegociation &&
          (foundNegociation.isAfter(currentDate) || foundNegociation.isSame(currentDate))
        ) {
          //if (currentDate.diff(foundNegociation, 'seconds') > differenceEnding) {
          return 'card-shadow-danger';
          //}
        } else if (
          foundPriorNotice &&
          (foundPriorNotice.isAfter(currentDate) || foundPriorNotice.isSame(currentDate))
        ) {
          //if (currentDate.diff(foundPriorNotice, 'seconds') > differenceEnding) {
          return 'card-shadow-danger';
          //}
        } else {
          return 'card-shadow-danger';
        }
      }
    }
  };

  affectationToContract(toBeAffected, contract) {
    ConfirmAlert.show('Voulez vous vraiment ' + (toBeAffected ? " affecter ":" désaffecter ") + ' un acheteur/gestionnaire au contrat '+ contract?.name +' ?').then(
      () => {
        console.log("api call")
      },
      () => {}
    );
  }

  getCurrentContractsTimeLineList() {
    let currentTomeLineContracts = [];
    if (this.state.currentTimeLineUnit === 1) {
      currentTomeLineContracts =
        this.state.timelineData?.days?.contractsByIntervals[this.state.currentIndex];
    } else if (this.state.currentTimeLineUnit === 2) {
      currentTomeLineContracts = this.state.timelineData?.weeks?.contractsByIntervals[this.state.currentIndex];
    } else if (this.state.currentTimeLineUnit === 3) {
      currentTomeLineContracts = this.state.timelineData?.months?.contractsByIntervals[this.state.currentIndex];
    } else if (this.state.currentTimeLineUnit === 5) {
      currentTomeLineContracts = this.state.timelineData?.years?.contractsByIntervals[this.state.currentIndex]; //TODO
    } else {
      //let trimesterIndex = this.getTheTrimesterIndex();
      currentTomeLineContracts = this.state.timelineData?.trimesters?.contractsByIntervals[this.state.currentIndex];
    }
    return currentTomeLineContracts;
  }

  getTheTrimesterIndex() {
    var currentDate = new Date();
    var month = currentDate.getMonth();
    if ((month + 1) % 3 != 0) {
      return Math.trunc((month + 1) / 3);
    }
    return (month + 1) / 3 - 1;
  }

  getContractBadges = (contract) => {
    if (
      contract.negociationBadge != '' ||
      contract.priorNoticeBadge != '' ||
      contract.endingBadge != ''
    ) {
      return [contract.negociationBadge, contract.priorNoticeBadge, contract.endingBadge];
    } else {
      let currentTomeLineContracts = this.getCurrentContractsTimeLineList();
      let negociationBadge = '';
      let priorNoticeBadge = '';
      let endingBadge = '';

      if (currentTomeLineContracts) {
        for (let i = 0; i < currentTomeLineContracts.ending.length; i++) {
          if (currentTomeLineContracts.ending[i].id === contract.id) {
            endingBadge = 'badge-danger';
          }
        }

        for (let i = 0; i < currentTomeLineContracts.priorNotice.length; i++) {
          if (currentTomeLineContracts.priorNotice[i].id === contract.id) {
            priorNoticeBadge = 'badge-primary';
          }
        }

        for (let i = 0; i < currentTomeLineContracts.negociation.length; i++) {
          if (currentTomeLineContracts.negociation[i].id === contract.id) {
            negociationBadge = 'badge-success';
          }
        }
      }
      return [negociationBadge, priorNoticeBadge, endingBadge];
    }
  };
  seachIncontractList = (e, table, isTag=false) => {
    try {
      if(isTag){
        if(e.target.value === "" || e.target.value === null )
            window[table].search(e.target.value).draw();
        else
            window[table].search('#'+(e.target.value).replace(/#/g, '')).draw();
      }else{
        if (e.target.value === "" || e.target.value === null)
          window[table].search(e.target.value).draw();
        else
          window[table].search((e.target.value.includes("#")) ? ('#'+(e.target.value).replace(/#/g, '')):e.target.value).draw();
      }
    } catch (error) {
      console.log(error)
    }
  };

  deleteContracts = (nbConrats) => {
    if (nbConrats == 0) {
      NotifyAlert.show('Merci de choisir le ou les contrat(s) à supprimer', 'Rappel');
    } else {
      ConfirmAlert.show('Voulez vous vraiment supprimer ' + nbConrats + ' contrat(s) ?').then(
        () => {
          deleteUnvalidatedContracts(this.state.selectedContracts).then((response) => {
            NotifyAlert.show(
              'La supression de ' + response + " contrat(s) s'est terminée avec succès",
              'Suppression contrat'
            ).then(() => {
              window.location.reload();
            });
          });
        },
        () => {}
      );
    }
  };

  removeDuplicateRowsByUlDataIndex = (tableId) => {
    // console.log(tableId)
    var table = document.getElementById(tableId);
    if(table && table.getElementsByTagName('tr')){
      var rows = table.getElementsByTagName('tr');
      var duplicateUlDataIndexes = {};
    
      // Iterate over each row
      for (var i = 0; i < rows.length; i++) {
        var currentRow = rows[i];
        var ulElements = currentRow.getElementsByTagName('ul');
    
        // Iterate over each <ul> element in the row
        for (var j = 0; j < ulElements.length; j++) {
          var ulElement = ulElements[j];
          var dataIndex = ulElement.getAttribute('data-dtr-index');
    
          if (dataIndex in duplicateUlDataIndexes) {
            // Remove duplicate row
            currentRow.parentNode.removeChild(currentRow);
            break; // Break the loop since the row is already removed
          } else {
            // Mark <ul> element as a duplicate
            duplicateUlDataIndexes[dataIndex] = true;
          }
        }
      }
    }
  }

  handleTabClick = (tabId, index) => {
    console.log("tabId", tabId)
    console.log("index", index)
    let itemsTabs = this.state.itemsTabs;
    let updatedArray = itemsTabs.map((obj) => ({ ...obj, active: false }));
    updatedArray[index].active = true;
    if(tabId === "#tab-recent" && this.state.selectedTab !== null && this.state.selectedTab !== undefined){
      // Remove class active from #tab-recent
      const divRecent = document.querySelector('[data-id="'+ tabId +'"]');
      divRecent.classList.remove('active');
      // Inject class active to #tabId
      const divElement = document.querySelector('[data-id="'+ this.state.selectedTab +'"]');
      divElement.classList.add('active');
    }
    this.setState({
      ...this.state,
      isRecentBlocVisible: tabId && (tabId == '#tab-5' || tabId == '#tab-6') ? false : true,
      stopCalculateScroll:true,
      itemsTabs: tabId === "#tab-recent" ? itemsTabs : updatedArray,
      selectedTab: tabId === "#tab-recent" ? this.state.selectedTab: tabId
    },()=>{
      document.body.style.overflow = 'auto';
      window.scrollTo({ top: 0, behavior: 'smooth'});
      localStorage.setItem('selectedTab', this.state.selectedTab);
    })
  };
  
  scrollToTop = () => {
    this.setState({
      ...this.state,
      stopCalculateScroll:true
    },()=>{
      window.scrollTo({ top: 0, behavior: 'smooth'});
    })
  };

  handleDragStart = (event, index) => {
    this.setState({ ...this.state, draggedIndex: index });
    event.dataTransfer.setData('text/plain', index.toString());
    const divElement = document.querySelector('.draggable-list');
    divElement.classList.add('dragging');
  }

  handleDragOver = (event) => {
    event.preventDefault();
  }

  handleDrop = (event, index) => {
    const draggedIndex = parseInt(event.dataTransfer.getData('text/plain'));
    if (draggedIndex !== null && draggedIndex !== index) {
      const { itemsTabs } = this.state;
      const newItems = [...itemsTabs];
      const [draggedItem] = newItems.splice(draggedIndex, 1);
      newItems.splice(index, 0, draggedItem);
      if (JSON.stringify(itemsTabs) !== JSON.stringify(newItems)) {
        this.setState({ ...this.state ,itemsTabs: newItems, draggedIndex: null, tabs: newItems ? newItems.map(obj => obj.key) : [] },()=>{
          const divElement = document.querySelector('.draggable-list');
          divElement.classList.remove('dragging');
          manageHomeTabsAndCompanies(this.state.userRights["id"],this.state.tabs, this.state.defaultClient, this.state.defaultRightType).then((response) => {
          })
        });
      }
    }
  } 

  handleMouseEnter = () => {
    const divTimeLineSticked = document.querySelector('.timeLineSticked');
    if(divTimeLineSticked !== null && !divTimeLineSticked.classList.contains('timeLineSticked-hover')){
      divTimeLineSticked.classList.add('timeLineSticked-hover');
    }
  }

  handleMouseLeave = () => {
    const divTimeLineSticked = document.querySelector('.timeLineSticked-hover');
    if(divTimeLineSticked !== null && !this.state.pinTimeline && divTimeLineSticked.classList.contains('timeLineSticked-hover')){
      divTimeLineSticked.classList.remove('timeLineSticked-hover');
    }
  }

  sortItemsByParentId = (items = []) => {
    const sortedItems = [];
    const itemMap = {};
    const topLevelItems = [];

    // Create a map of items using their id as the key
    items.forEach((item) => {
      item.children = []; // Initialize children as an array
      itemMap[item.id] = item;

      // Check if the item is a top-level item
      if (!item.parentContract) {
        topLevelItems.push(item);
      } else {
        // Add child items to their parent's children array
        const parent = itemMap[item.parentContract];
        if (parent) {
          parent.children.push(item);
        } else {
          // Parent item not found, handle accordingly (optional)
          console.warn(`Parent item with ID ${item.parentContract} not found for item with ID ${item.id}`);
        }
      }
    });

    // Recursive function to process items and their children
    function processItem(item) {
      sortedItems.push(item); // Add the current item to the sorted array

      // Sort the children recursively
      item.children.sort((a, b) => a.id - b.id);

      // Process each child item
      item.children.forEach((child) => {
        processItem(child);
      });
    }

    // Sort the top-level items
    topLevelItems.sort((a, b) => a.id - b.id);

    // Process each top-level item and its children
    topLevelItems.forEach((item) => {
      processItem(item);
    });

    // console.log("sortedItems --> ",sortedItems)

    return sortedItems;
  }

  getListContractsFromApi = () =>{
    if (JSON.parse(this.state.currentClient).roleType !== "GUEST") {
      LoaderComponent.show();
      getContracts().then((response) => {
        let mergedContratctsList = [];
        let contracts = response['data'];
        
        
        if(contracts['validatedContracts'] && contracts['validatedContracts'].length>0){
          contracts['validatedContracts'].forEach(obj => {obj.tab = 'validated'});
          mergedContratctsList = mergedContratctsList.concat(...contracts['validatedContracts'])
        }
        if(contracts['unvalidatedContracts'] && contracts['unvalidatedContracts'].length>0){
          contracts['unvalidatedContracts'].forEach(obj => {obj.tab = 'unvalidated'});
          mergedContratctsList = mergedContratctsList.concat(...contracts['unvalidatedContracts'])
        }
        if(contracts['inNegociationContracts'] && contracts['inNegociationContracts'].length>0){
          contracts['inNegociationContracts'].forEach(obj => {obj.tab = 'in_negociation'});
          mergedContratctsList = mergedContratctsList.concat(...contracts['inNegociationContracts'])
        }
       
        this.setState(
          {
            ...this.state,
            contrats: response.data,
            mergedContratctsList: mergedContratctsList
          },
          () => {
            this.hideTheLoadComponent();
          }
        );
      });
      getTimeLineData().then((response) => {
        this.setState(
          {
            ...this.state,
            timelineData: response.data,
          },
          () => {
            this.hideTheLoadComponent();
          }
        );
      });
    }
  }

  handleUpdateColumnsList = (dataTabs) =>{
    this.setState({
      ...this.state,
      homeTabsColumns: dataTabs
    },()=>{
      manageHomeTabsColumns(JSON.stringify(dataTabs)).then(response=>{

      },error=>{
        ErrorAlert.show(error.response.data, 'Erreur API');
      })
    })
  }

  contractsAlreadyArchived = (contract) =>{
    switch (contract["archiveData"].type) {
      case "DELETE":
        toast.warning("Vous ne pouvez pas afficher le contenue d'un contrat/avenant déjà supprimé.", {
          position: toast.POSITION.TOP_RIGHT
        });
        break;
      case "ARCHIVE":
        toast.warning("Vous ne pouvez pas afficher le contenue d'un contrat/avenant déjà archivé.", {
          position: toast.POSITION.TOP_RIGHT
        });
        break;

      default:
        break;
    }
  }

  render() {
    const { timelineData, showAllContracts, contrats, mergedContratctsList } = this.state;
    if(contrats && JSON.parse(this.state.currentClient).roleType !== "GUEST"){
      if (!$('#homeDataTable1').hasClass('dataTable')) {
        let js_src = '/assets/js/main.js';
        $('script[src="' + js_src + '"]').remove();
        $('<script>').attr('src', js_src).appendTo('head');
      }
    }
    const negosList = Cookies.get("currentNegosClientForGuest") ? JSON.parse(Cookies.get("currentNegosClientForGuest")) : this.state.userRights?.negociations
    if(negosList && JSON.parse(this.state.currentClient).roleType === "GUEST"){
      if (!$('#homeDataTable5').hasClass('dataTable')) {
        let js_src = '/assets/js/main.js';
        $('script[src="' + js_src + '"]').remove();
        $('<script>').attr('src', js_src).appendTo('head');
      }
    }

    return (
      <StickyContainer>
        <div className="app-container dashbord app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
          <Header />

          <div className="app-main">
            {/* <ToastContainer className="toast-styled-contract"/> */}
            <div className="app-sidebar sidebar-shadow">
              <Sidebar />
            </div>
            <div className="app-main__outer">
              <div className="app-main__inner noEditMode">
                { JSON.parse(this.state.currentClient).roleType !== "GUEST" && timelineData && contrats &&
                  <div className="timeline-mobile"> 
                    <Sticky topOffset={360} relative={false}>
                      {({ style, isSticky, distanceFromTop }) => {
                        if(isSticky !== this.state.isSticky )
                          this.setState({
                            ...this.state,
                            isSticky:isSticky
                          })
                        return (
                        <div
                          style={style}
                          className={`timeLineRow time-line  ${isSticky ? (this.state.pinTimeline ? 'timeLineSticked timeLineSticked-hover':'timeLineSticked') : ''}`}
                          data-isSticky={this.HandleTimeLineStickyStateChange(isSticky)}
                          onMouseEnter={this.handleMouseEnter}
                          onMouseLeave={this.handleMouseLeave}
                        >
                          <CarouselTimelineDashBords
                            timeline={
                              <TimeLine
                                isTimeLineSticky={isSticky}
                                onChangeTimeLineUnit={this.changeTimeLineUnit}
                                showAllContracts={showAllContracts}
                                onPressshowAllContracts={() => this.getListContractsFromApi()}
                                timelineData={timelineData}
                                setNewList={this.setNewList}
                                displayTimeline={this.state.displayTimeline}
                                pinTimeline={this.state.pinTimeline}
                                toggleTimeline={() => this.setState(
                                  {
                                    ...this.state,
                                    pinTimeline: !this.state.pinTimeline
                                    // displayTimeline: !this.state.displayTimeline
                                  },()=>{
                                    Cookies.set("pinTimeline",this.state.pinTimeline)
                                  }
                                )}
                              />
                            }
                            dashBords={[
                              // {
                              //   name: 'Montant par catégorie',
                              //   type: 1,
                              //   displayValues: true,
                              //   unity: 'euro',
                              // },
                              // {
                              //   name: 'Prévision trésorerie',
                              //   type: 2,
                              //   displayValues: true,
                              //   unity: 'k-euro',
                              // },
                              // {
                              //   name: 'Budget Prévisionnel',
                              //   type: 3,
                              //   displayValues: false,
                              //   unity: 'percent',
                              // },
                            ]}
                          />
                        </div>
                        )
                      }
                      }
                    </Sticky>
                  </div>
                }
                {/* Tabs  */}
                {((!contrats && JSON.parse(this.state.currentClient).roleType !== "GUEST") || (!negosList && JSON.parse(this.state.currentClient).roleType === "GUEST")) &&
                  <div className='loading-contract-list'>
                    <p>
                      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <g transform="translate(50 50)">
                          <g>
                            <animateTransform attributeName="transform" type="rotate" calcMode="discrete" values="0;90;180;270;360" keyTimes="0;0.25;0.5;0.75;1" dur="2.5s" repeatCount="indefinite"></animateTransform>
                            <path d="M-40 0A40 40 0 1 0 40 0" fill="#f66031">
                              <animate attributeName="fill" calcMode="discrete" values="#f66031;#260c4e;#f66031;#260c4e;#f66031" keyTimes="0;0.24;0.49;0.74;0.99" dur="2.5s" repeatCount="indefinite"></animate>
                            </path>
                            <path d="M-40 0A40 40 0 0 1 40 0" fill="#260c4e">
                              <animate attributeName="fill" calcMode="discrete" values="#260c4e;#f66031;#260c4e;#f66031;#260c4e" keyTimes="0;0.25;0.5;0.75;1" dur="2.5s" repeatCount="indefinite"></animate>
                            </path>
                            <path d="M-39 0L39 0" stroke="#c63609" strokeWidth="2">
                              <animate attributeName="stroke" values="#f66031;#c63609;#1b0837;#260c4e;#1b0837;#c63609;#f66031;#c63609;#1b0837;#260c4e;#1b0837;#c63609;#f66031" keyTimes="0;0.124;0.125;0.25;0.374;0.375;0.5;0.624;0.625;0.75;0.874;0.875;1" dur="2.5s" repeatCount="indefinite"></animate>
                            </path>
                            <g>
                              <path d="M-40 0A40 40 0 0 1 40 0Z" fill="#c63609">
                                <animate attributeName="fill" values="#f66031;#c63609;#1b0837;#260c4e;#1b0837;#c63609;#f66031;#c63609;#1b0837;#260c4e;#1b0837;#c63609;#f66031" keyTimes="0;0.124;0.125;0.25;0.374;0.375;0.5;0.624;0.625;0.75;0.874;0.875;1" dur="2.5s" repeatCount="indefinite"></animate>
                                <animateTransform attributeName="transform" type="scale" values="1 1;1 0;1 -1;1 1" keyTimes="0;0.5;0.999;1" dur="0.625s" repeatCount="indefinite"></animateTransform>
                              </path></g>
                          </g>
                        </g>
                      </svg>
                    </p>
                    <p>{t("common.loading_plz_wait")}</p>
                  </div>
                }
                {contrats && JSON.parse(this.state.currentClient).roleType !== "GUEST" && this.state.homeTabsColumns !== undefined && this.state.homeTabsColumns !== null &&
                  <Tabs
                    isSticky={this.state.isSticky}
                    items={this.state.itemsTabs}
                    handleTabClick={this.handleTabClick}
                    handleDragStart={this.handleDragStart}
                    handleDragOver={this.handleDragOver}
                    handleDrop={this.handleDrop}
                    draggedIndex={this.state.draggedIndex}
                    recentContract={
                      this.state.selectedTab && this.state.selectedTab === "#tab-1" ?
                        <RecentValidatedContract
                          data={contrats?.recentValidatedContract?.filter(x => !(x.archiveData || x.toArchive))}
                          getCardClassName={this.getCardClassName}
                          handleDoubleClick={this.handleDoubleClick}
                          contractsAlreadyArchived={this.contractsAlreadyArchived}
                          setFavoriteContrat={this.setFavoriteContrat}
                          displayRecent={this.state.displayRecent}
                          toggleRecent={() => this.setState(
                            {
                              ...this.state,
                              displayRecent: !this.state.displayRecent
                            }
                          )}
                        />
                        : (
                          this.state.selectedTab && this.state.selectedTab === "#tab-2" ?
                            <RecentUnValidatedContract
                              data={contrats?.recentUnValidatedContract}
                              getCardClassName={this.getCardClassName}
                              handleDoubleClick={this.handleDoubleClick}
                              setFavoriteContrat={this.setFavoriteContrat}
                              displayRecent={this.state.displayRecent}
                              toggleRecent={() => this.setState(
                                {
                                  ...this.state,
                                  displayRecent: !this.state.displayRecent
                                }
                              )}
                            /> :
                            (
                              this.state.selectedTab && this.state.selectedTab === "#tab-3" ?
                                <RecentContractsInNegociation
                                  data={contrats?.recentContractsInNegociation}
                                  getCardClassName={this.getCardClassName}
                                  handleDoubleClick={this.handleDoubleClick}
                                  setFavoriteContrat={this.setFavoriteContrat}
                                  displayRecent={this.state.displayRecent}
                                  toggleRecent={() => this.setState(
                                    {
                                      ...this.state,
                                      displayRecent: !this.state.displayRecent
                                    }
                                  )}
                                />
                                : (
                                  this.state.selectedTab && this.state.selectedTab === "#tab-4" ?
                                    <RecentDocuments
                                      data={contrats?.recentDocuments}
                                      handleDocumentDoubleClick={this.handleDocumentDoubleClick}
                                      setFavoriteDocument={this.setFavoriteDocument}
                                      displayRecent={this.state.displayRecent}
                                      toggleRecent={() => this.setState(
                                        {
                                          ...this.state,
                                          displayRecent: !this.state.displayRecent
                                        }
                                      )}
                                    /> :
                                    (
                                      this.state.selectedTab && this.state.selectedTab === "#tab-6" ?
                                        <RecentValidatedContract
                                          data={contrats?.recentValidatedContract}
                                          getCardClassName={this.getCardClassName}
                                          handleDoubleClick={this.handleDoubleClick}
                                          contractsAlreadyArchived={this.contractsAlreadyArchived}
                                          setFavoriteContrat={this.setFavoriteContrat}
                                          displayRecent={this.state.displayRecent}
                                          toggleRecent={() => this.setState(
                                            {
                                              ...this.state,
                                              displayRecent: !this.state.displayRecent
                                            }
                                          )}
                                        /> :
                                        (
                                          this.state.selectedTab && this.state.selectedTab === "#tab-archives" ?
                                            <RecentValidatedContract
                                              data={contrats?.recentValidatedContract?.filter(x => x.archiveData || x.toArchive)}
                                              getCardClassName={this.getCardClassName}
                                              handleDoubleClick={this.handleDoubleClick}
                                              contractsAlreadyArchived={this.contractsAlreadyArchived}
                                              setFavoriteContrat={this.setFavoriteContrat}
                                              displayRecent={this.state.displayRecent}
                                              toggleRecent={() => this.setState(
                                                {
                                                  ...this.state,
                                                  displayRecent: !this.state.displayRecent
                                                }
                                              )}
                                            /> : ""
                                        )
                                    )
                                )
                            ))
                    }
                    currentTab={this.state.selectedTab}
                    pinTimeline={this.state.pinTimeline}
                  >
                    <TabContent
                      TabId={'tab-1'}
                      recentContract={false &&
                        <RecentValidatedContract
                          data={contrats?.recentValidatedContract?.filter(x=>!(x.archiveData || x.toArchive))}
                          getCardClassName={this.getCardClassName}
                          handleDoubleClick={this.handleDoubleClick}
                          contractsAlreadyArchived={this.contractsAlreadyArchived}
                          setFavoriteContrat={this.setFavoriteContrat}
                          displayRecent={this.state.displayRecent}
                          toggleRecent={()=>this.setState(
                            {
                              ...this.state,
                              displayRecent:!this.state.displayRecent
                            }
                          )}
                        />
                      }
                      listingTitle={t('frontoffice.home.contracts.contract_listing.validated_contacts')} //{'Liste de contrats validés'}
                      isActive={this.state.selectedTab && this.state.selectedTab === "#tab-1"}
                      listing={
                        <ListingValidatedContract
                          data={this.sortItemsByParentId(contrats?.validatedContracts)}
                          keywordsDB={contrats?.keywords}
                          homeTabsSearch={this.state.homeTabsSearch?.find(x=>x.key === "validated_contracts") || []}
                          tags={contrats?.tags}
                          getContractBadges={this.getContractBadges}
                          seachIncontractList={this.seachIncontractList}
                          handleDoubleClick={this.handleDoubleClick}
                          removeDuplicateRowsByUlDataIndex={this.removeDuplicateRowsByUlDataIndex}
                          columnsList={this.state.homeTabsColumns}
                          setColumnsList={this.handleUpdateColumnsList}
                        />
                      }
                    />
                    <TabContent
                      TabId={'tab-2'}
                      recentContract={
                        false && 
                        <RecentUnValidatedContract
                          data={contrats?.recentUnValidatedContract}
                          getCardClassName={this.getCardClassName}
                          handleDoubleClick={this.handleDoubleClick}
                          setFavoriteContrat={this.setFavoriteContrat}
                          displayRecent={this.state.displayRecent}
                          toggleRecent={()=>this.setState(
                            {
                              ...this.state,
                              displayRecent:!this.state.displayRecent
                            }
                          )}
                        />
                      }
                      listingTitle={t('frontoffice.home.contracts.contract_listing.unvalidated_contacts')}
                      isActive={this.state.selectedTab && this.state.selectedTab === "#tab-2"}
                      listing={
                        <ListingUnValidatedContract
                          data={contrats?.unvalidatedContracts}
                          keywordsDB={contrats?.keywords}
                          homeTabsSearch={this.state.homeTabsSearch?.find(x=>x.key === "unvalidated_contracts") || []}
                          tags={contrats?.tags}
                          getContractBadges={this.getContractBadges}
                          seachIncontractList={(e) => this.seachIncontractList(e, 'homeDataTable2')}
                          handleDoubleClick={this.handleDoubleClick}
                          removeDuplicateRowsByUlDataIndex={this.removeDuplicateRowsByUlDataIndex}
                          affectationToContract={this.affectationToContract}
                          columnsList={this.state.homeTabsColumns}
                          setColumnsList={this.handleUpdateColumnsList}
                        />
                      }
                    />
                    <TabContent
                      TabId={'tab-3'}
                      recentContract={
                        false &&
                        <RecentContractsInNegociation
                          data={contrats?.recentContractsInNegociation}
                          getCardClassName={this.getCardClassName}
                          handleDoubleClick={this.handleDoubleClick}
                          setFavoriteContrat={this.setFavoriteContrat}
                          displayRecent={this.state.displayRecent}
                          toggleRecent={()=>this.setState(
                            {
                              ...this.state,
                              displayRecent:!this.state.displayRecent
                            }
                          )}
                        />
                      }
                      listingTitle={t('frontoffice.home.contracts.contract_listing.in_negociation_contacts')}
                      isActive={this.state.selectedTab && this.state.selectedTab === "#tab-3"}
                      listing={
                        <ListingInNegociationContract
                          data={contrats?.inNegociationContracts}
                          keywordsDB={contrats?.keywords}
                          homeTabsSearch={this.state.homeTabsSearch?.find(x=>x.key === "in_negociation_contracts") || []}
                          tags={contrats?.tags}
                          getContractBadges={this.getContractBadges}
                          seachIncontractList={(e) => this.seachIncontractList(e, 'homeDataTable3')}
                          handleDoubleClick={this.handleNegoDoubleClick}
                          currentNegosClientForUser={this.state.currentNegosClientForUser}
                          removeDuplicateRowsByUlDataIndex={this.removeDuplicateRowsByUlDataIndex}
                          affectationToContract={this.affectationToContract}
                          columnsList={this.state.homeTabsColumns}
                          setColumnsList={this.handleUpdateColumnsList}
                        />
                      }
                    />
                    <TabContent
                      TabId={'tab-4'}
                      recentContract={
                        false &&
                        <RecentDocuments
                          data={contrats?.recentDocuments}
                          handleDocumentDoubleClick={this.handleDocumentDoubleClick}
                          setFavoriteDocument={this.setFavoriteDocument}
                          displayRecent={this.state.displayRecent}
                          toggleRecent={()=>this.setState(
                            {
                              ...this.state,
                              displayRecent:!this.state.displayRecent
                            }
                          )}
                        />
                      }
                      listingTitle={t('frontoffice.home.contracts.contract_listing.documents')}
                      isActive={this.state.selectedTab && this.state.selectedTab === "#tab-4"}
                      listing={
                        <ListingLinkedDocuments
                          LoaderComponent={LoaderComponent}
                          data={contrats?.documents}
                          tags={contrats?.tags}
                          seachIncontractList={(e) => this.seachIncontractList(e, 'homeDataTable4')}
                          handleDocumentDoubleClick={this.handleDocumentDoubleClick}
                          columnsList={this.state.homeTabsColumns}
                          setColumnsList={this.handleUpdateColumnsList}
                        />
                      }
                    />
                    <TabContent
                      TabId={'tab-6'}
                      recentContract={
                        false &&
                        <RecentValidatedContract
                          data={contrats?.recentValidatedContract}
                          getCardClassName={this.getCardClassName}
                          handleDoubleClick={this.handleDoubleClick}
                          contractsAlreadyArchived={this.contractsAlreadyArchived}
                          setFavoriteContrat={this.setFavoriteContrat}
                          displayRecent={this.state.displayRecent}
                          toggleRecent={()=>this.setState(
                            {
                              ...this.state,
                              displayRecent:!this.state.displayRecent
                            }
                          )}
                        />
                      }
                      listingTitle={t('frontoffice.home.contracts.contract_listing.all_contacts')} //{'Liste de contrats validés'}
                      isActive={this.state.selectedTab && this.state.selectedTab === "#tab-6"}
                      listing={
                        <ListingAllContracts
                          data={this.sortItemsByParentId(mergedContratctsList || [])}
                          keywordsDB={contrats?.keywords}
                          homeTabsSearch={this.state.homeTabsSearch?.find(x=>x.key === "all_contracts") || []}
                          tags={contrats?.tags}
                          getContractBadges={this.getContractBadges}
                          seachIncontractList={(e) => this.seachIncontractList(e, 'homeDataTable6')}
                          handleDoubleClick={this.handleDoubleClick}
                          removeDuplicateRowsByUlDataIndex={this.removeDuplicateRowsByUlDataIndex}
                          columnsList={this.state.homeTabsColumns}
                          setColumnsList={this.handleUpdateColumnsList}
                        />
                      }
                    />
                    <TabContent
                      TabId={'tab-archives'}
                      recentContract={
                        false && 
                        <RecentValidatedContract
                          data={contrats?.recentValidatedContract?.filter(x=>x.archiveData || x.toArchive)}
                          getCardClassName={this.getCardClassName}
                          handleDoubleClick={this.handleDoubleClick}
                          contractsAlreadyArchived={this.contractsAlreadyArchived}
                          setFavoriteContrat={this.setFavoriteContrat}
                          displayRecent={this.state.displayRecent}
                          toggleRecent={()=>this.setState(
                            {
                              ...this.state,
                              displayRecent:!this.state.displayRecent
                            }
                          )}
                        />
                      }
                      listingTitle={t('frontoffice.home.contracts.contract_listing.archive_box')} //{'Liste de contrats validés'}
                      isActive={this.state.selectedTab && this.state.selectedTab === "#tab-archives"}
                      listing={
                        <ListingArchivedContract
                          data={this.sortItemsByParentId(contrats?.archiveBoxContracts)}
                          keywordsDB={contrats?.keywords}
                          homeTabsSearch={this.state.homeTabsSearch?.find(x=>x.key === "archive_box_contracts") || []}
                          tags={contrats?.tags}
                          getContractBadges={this.getContractBadges}
                          seachIncontractList={this.seachIncontractList}
                          handleDoubleClick={this.handleDoubleClick}
                          removeDuplicateRowsByUlDataIndex={this.removeDuplicateRowsByUlDataIndex}
                          columnsList={this.state.homeTabsColumns}
                          setColumnsList={this.handleUpdateColumnsList}
                        />
                      }
                    />
                  </Tabs>
                }

                {negosList && JSON.parse(this.state.currentClient).roleType === "GUEST" &&
                <div className='guest-home '>
                  <h2>{t('frontoffice.home.contracts.contract_listing.my_own_negos')}</h2>
                  <TabContent
                      TabId={'tab-5'}
                      isActive={true}
                      listing={
                        <ListingGuestNegociationContract
                          data={Cookies.get("currentNegosClientForGuest") ? JSON.parse(Cookies.get("currentNegosClientForGuest")) : this.state.userRights?.negociations}
                          tags={contrats?.tags}
                          getContractBadges={this.getContractBadges}
                          seachIncontractList={(e) => this.seachIncontractList(e, 'homeDataTable5')}
                          handleDoubleClick={this.handleNegoDoubleClick}
                        />
                      }
                    />

                </div>
                }
              </div>
            </div>
          </div>
          <a className={`scroll-to-top show`} title='Vers le haut de page' onClick={this.scrollToTop} >
            <svg width="35" height="35" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M59.375 34.1543V85.8125C59.375 87.4349 58.0598 88.75 56.4375 88.75C54.8152 88.75 53.5 87.4349 53.5 85.8125V34.1543L35.0146 52.6396C33.8675 53.7868 32.0075 53.7868 30.8604 52.6396C29.7132 51.4925 29.7132 49.6325 30.8604 48.4854L54.3604 24.9854C55.5075 23.8382 57.3675 23.8382 58.5146 24.9854L82.0146 48.4854C83.1618 49.6325 83.1618 51.4925 82.0146 52.6396C80.8675 53.7868 79.0076 53.7868 77.8604 52.6396L59.375 34.1543ZM112.25 56.4375C112.25 87.2619 87.2619 112.25 56.4375 112.25C25.6131 112.25 0.625 87.2619 0.625 56.4375C0.625 25.6131 25.6131 0.625 56.4375 0.625C87.2619 0.625 112.25 25.6131 112.25 56.4375ZM106.375 56.4375C106.375 28.8578 84.0172 6.5 56.4375 6.5C28.8578 6.5 6.5 28.8578 6.5 56.4375C6.5 84.0172 28.8578 106.375 56.4375 106.375C84.0172 106.375 106.375 84.0172 106.375 56.4375Z" fill="red" />
            </svg>
          </a>
        </div>
      </StickyContainer>
    );
  }
}

//export default Home;
export default withRouter(withTranslation()(Home));
