import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import "./Login.scss";
import axios from "axios";
import {
  API_CONNECTION_URL,
  API_GOOGLE_CONNECTION_URL,
  API_MICROSOFT_CONNECTION_URL, API_URL,
} from "../../../config/common";
import DataContext from "../../../DataManagement/DataContext";
import Cookies from "js-cookie";
import { checkIfUserEmailAndPasswordAreCorrect, connectUserByPassword, getUserRights } from "../../../api/user";
import ErrorAlert from "../../../components/confirm-alert/error-alert";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import LoaderComponent from "../../../components/loader/Loader";

//i18n
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import LanguagesSwitcher from "../../../components/languagesSwitcher";
import HeaderVisitor from "../../../components/layouts/header-visitor/HeaderVisitor";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      password: {
        type: "password",
        error: false,
      },
      email: {
        error: false,
      },
      userEmail: "",
      userPassword: ""
    };
    this.showPassword = this.showPassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmitGoogle = this.onSubmitGoogle.bind(this);
    this.onSubmitMicrosoft = this.onSubmitMicrosoft.bind(this);
  }

  async componentDidMount() {
    document.body.classList.add('bg-login');
    const queryString = require("query-string");
    var parsed = queryString.parse(this.props.location.search);
    if (parsed.userRights || Cookies.get("userRights")) {
      if (!Cookies.get("userRights")) {
        if (parsed.userRights !== undefined && parsed.userRights != "error") {
          try {
            
            localStorage.setItem('apiToken', JSON.parse(parsed.userRights).apiToken);
            
            var userInformation = await this.fetchDataUser();
            
            userInformation.userRights = userInformation.userRights.sort((a, b) => a.client['companyName'].localeCompare(b.client['companyName'])).sort((a, b) => a.roleType.localeCompare(b.roleType));
            
            // Cookies.set("apiToken", userInformation.apiToken);
            delete userInformation.apiToken
            
            Cookies.set("userRights", userInformation);
          } catch (error) {
            console.error('Invalid JSON string:', error);
          }
        }
      }

      if(userInformation && userInformation?.userRights !== undefined && (userInformation?.userRights[0] === undefined || userInformation?.userRights[0] === "") &&
         userInformation.type === "GUEST"){
          userInformation.userRights.push({roleType : "GUEST",client:{}})
          Cookies.set("userRights", userInformation);
      }

      if ((!parsed.userRights || parsed.userRights != "error") && Cookies.get("userRights") !== undefined) {
        var userInformation = JSON.parse(Cookies.get("userRights"));
        //this.context.update({ userRights: userInformation})

        if (userInformation.isAdmin == true) {
          this.props.history.push("/back-office/home");
        } else {
          if (
            userInformation?.userRights[0] === undefined ||
            userInformation?.userRights[0] === ""
          ) {
            Cookies.remove("userRights");
            localStorage.clear();

            if (Cookies.get("currentClient")) {
              Cookies.remove("currentClient");
            }
            if(Cookies.get("currentNegosClient")) {
              Cookies.remove("currentNegosClient");
            }
            if(Cookies.get("currentUserInformation")) {
              Cookies.remove("currentUserInformation");
            }
            ErrorAlert.show(
              t('authentication.errors.access_denied'),
              t('authentication.errors.connection_rejected')
            );
          } else {
            Cookies.set("currentUserInformation", userInformation)
            Cookies.set("currentClient", userInformation.userRights[0]);
            if (userInformation.userRights[0].roleType == "ADMIN_CLIENT") {
              this.props.history.push("/back-office/home");
            } else if (
              userInformation.userRights[0].roleType == "USER" ||
              (userInformation && userInformation.userRights[0].roleType == "GUEST")||
              userInformation.userRights[0].roleType == "COMMERCIAL_CONTRACT" ||
              userInformation.userRights[0].roleType == "TECHNICAL_CONTRACT"
            ) {
              this.props.history.push("/home");
            }
          }
        }
      }
    }
    if (parsed.userRights && parsed.userRights == "error") {
      ErrorAlert.show(
        t('authentication.errors.email_not_found'),
        t('authentication.errors.connection_rejected')
      );
    }
  }

  componentWillUnmount(){
    document.body.classList.remove('bg-login');
  }

  handleChange(event) {
    this.setState({
      ...this,
      [event.target.name]: event.target.value,
    });
  }

  showPassword(e) {
    e.preventDefault();
    this.setState({
      password: {
        type: this.state.password.type == "password" ? "text" : "password",
      },
    });
  }

  onSubmit(e) {
    e.preventDefault();
    LoaderComponent.show();
    checkIfUserEmailAndPasswordAreCorrect(
      this.state.userEmail,
      this.state.userPassword
    ).then((response) => {
      LoaderComponent.hide();
      if (response.data == "-2") {
        this.setState({
          ...this,
          email: { error: true },
          password: { type: "password", error: false },
        });
      } else if (response.data == "-1") {
        this.setState({
          ...this,
          password: { type: "password", error: true },
          email: { error: false },
        });
      } else {
        this.setState({
          ...this,
          email: { error: false },
          password: { type: "password", error: false },
        }); //TODO: here

        connectUserByPassword(this.state.userEmail, this.state.userPassword).then(dataUser => {
          if (dataUser && dataUser["data"] && dataUser["data"].apiToken !== undefined) {
            let dataUserConnection = dataUser["data"]
            localStorage.setItem('apiToken', dataUserConnection.apiToken);
            dataUserConnection.userRights = dataUserConnection.userRights.sort((a, b) => a.client['companyName'].localeCompare(b.client['companyName'])).sort((a, b) => a.roleType.localeCompare(b.roleType));

            // Cookies.set("apiToken", userInformation.apiToken);
            delete dataUserConnection.apiToken

            Cookies.set("userRights", dataUserConnection);
            Cookies.set("isDefaultClientLoaded", false);


            if (dataUserConnection && dataUserConnection?.userRights !== undefined && (dataUserConnection?.userRights[0] === undefined || dataUserConnection?.userRights[0] === "") &&
              dataUserConnection.type === "GUEST") {
              dataUserConnection.userRights.push({ roleType: "GUEST", client: {} })
              Cookies.set("userRights", dataUserConnection);
            }

            if ((dataUserConnection.userRights != "error") && Cookies.get("userRights") !== undefined) {
              if(dataUserConnection && dataUserConnection.defaultClient !== undefined && dataUserConnection.defaultClient !== "" && dataUserConnection.defaultRightType !== undefined && dataUserConnection.defaultRightType !== ""){
                console.log(dataUserConnection)
                let findClient = dataUserConnection.userRights.filter(x => x.client["id"] == dataUserConnection.defaultClient && x.roleType == dataUserConnection.defaultRightType)
                if (findClient && findClient.length == 1 && findClient[0] !== undefined && findClient[0].client.id !== undefined) {
                  Cookies.set("currentClient", findClient[0])
                }

                if(dataUserConnection.defaultRightType === "USER"){
                  this.props.history.push("/home");
                }
                if(dataUserConnection.defaultRightType === "ADMIN_CLIENT"){
                  this.props.history.push("/back-office/home");
                }
              }else if (dataUserConnection.isAdmin == true) {
                this.props.history.push("/back-office/home");
              } else {
                if (
                  dataUserConnection?.userRights[0] === undefined ||
                  dataUserConnection?.userRights[0] === ""
                ) {
                  Cookies.remove("userRights");
                  
                  const cookieConsent = localStorage.getItem('cookieConsent');
                  localStorage.clear();
                  if (cookieConsent !== null) {
                    localStorage.setItem('cookieConsent', cookieConsent);
                  }
                  
                  if(Cookies.get("pinTimeline")) {
                    Cookies.remove("pinTimeline");
                  }

                  if (Cookies.get("currentClient")) {
                    Cookies.remove("currentClient");
                  }
                  if (Cookies.get("currentNegosClient")) {
                    Cookies.remove("currentNegosClient");
                  }
                  if(Cookies.get("currentUserInformation")) {
                    Cookies.remove("currentUserInformation");
                  }
                  if (Cookies.get("itemsColumnsByClient")) {
                    Cookies.remove("itemsColumnsByClient");
                  }
                  if (Cookies.get("isDefaultClientLoaded")) {
                    Cookies.remove("isDefaultClientLoaded");
                  }
                  ErrorAlert.show(
                    t('authentication.errors.access_denied'),
                    t('authentication.errors.connection_rejected')
                  );
                } else {
                  Cookies.set("currentUserInformation", dataUserConnection)
                  Cookies.set("currentClient", dataUserConnection.userRights[0]);
                  if (dataUserConnection.userRights[0].roleType == "ADMIN_CLIENT") {
                    this.props.history.push("/back-office/home");
                  } else if (
                    dataUserConnection.userRights[0].roleType == "USER" ||
                    (dataUserConnection && dataUserConnection.userRights[0].roleType == "GUEST") ||
                    dataUserConnection.userRights[0].roleType == "COMMERCIAL_CONTRACT" ||
                    dataUserConnection.userRights[0].roleType == "TECHNICAL_CONTRACT"
                  ) {
                    this.props.history.push("/home");
                  }
                }
              }
            }

            if (dataUserConnection.userRights && dataUserConnection.userRights == "error") {
              // window.location.replace(
              //   API_CONNECTION_URL +
              //     "/?email=" +
              //     this.state.userEmail +
              //     "&password=" +
              //     this.state.userPassword +
              //     "&redirectUrl=http://localhost:3000"
              // );
              window.location.replace(API_CONNECTION_URL + '/?email=' + this.state.userEmail + '&password=' + this.state.userPassword);
            }

          }
        }, (error) => {
          LoaderComponent.hide();
          ErrorAlert.show(error?.response?.data, "Erreur API");
        })

      }
    });
  }

  onSubmitGoogle(e) {
    e.preventDefault();
    window.location.replace(API_GOOGLE_CONNECTION_URL);
  }
  onSubmitMicrosoft(e) {
    e.preventDefault();
    window.location.replace(API_URL+`/connect/microsoft`);
  }


  handleConnect = (microsoftType) => {
    if(microsoftType == 1){
      window.location.replace(API_MICROSOFT_CONNECTION_URL+'_pro');
    }
    if(microsoftType == 2){
      window.location.replace(API_MICROSOFT_CONNECTION_URL);
    }
  };

  fetchDataUser = async () =>{
    return new Promise((resolve) => {
      getUserRights().then(usrRights=>{
        resolve(usrRights?.data)
      }, (error) => {
        LoaderComponent.hide();
      ErrorAlert.show(error?.response?.data, "Erreur API"); 
      resolve(null)
    })
  });
  }

  render() {
    return (
      <div className="wave-bg">
        <HeaderVisitor/>
        <form
          method="post"
          action="#"
          className="login-form"
          onSubmit={this.onSubmit}
        >

          <center>
            <h3 className="">{t("Connectez-vous avec")}</h3>
          </center>
          <div className="inline-link">
            <div className="account-container">
              <a
                href={API_GOOGLE_CONNECTION_URL}
                className="btn btn-light google-connect"
              >
                <img src="assets/images/google-connect-logo.png"></img>
              </a>
              {/* <span className="tag-account">Google</span> */}
            </div>
            <div className="account-container">
              <a
                onClick={() => this.handleConnect(1)}
                className="btn btn-light microsoft-connect"
              >
                <img src="assets/images/microsoft-connect-logo.png"></img>
              </a>
              {/* <span className="tag-account">Entreprise</span> */}
            </div>
            {/* <div className="account-container">
              <a
                onClick={() => this.handleConnect(2)}
                className="btn btn-light microsoft-connect"
              >
                <img src="assets/images/microsoft-connect-logo.png"></img>
              </a>
              <span className="tag-account">Particulier</span>
            </div> */}
          </div>
          <div className="box-connection">

            <center>
              <h3 className="black-text">
                {t("authentication.signin")}
              </h3>
              <br/>
              <h4 className="black-text">{t("authentication.signin_text")}<a href="mailto:contact@docatweb.fr">{t("authentication.signup_request")}</a></h4>
            </center>
            <div
              className={`form-group input-group mb-form-input ${this.state.email.error ? "error" : ""
                }`}
            >
              {/* <label>{t("authentication.login")}</label> */}
              <input
                className="form-control"
                name="userEmail"
                placeholder="Adresse mail"
                onChange={this.handleChange}
                required
              ></input>
              <span className="error">
                {t("authentication.errors.email_not_found")}
              </span>
            </div>

            <div
              className={`form-group input-group ${this.state.password.error ? "error" : ""
                }`}
            >
              {/* <label>{t("authentication.password")}</label> */}
              <input
                className="form-control"
                type={this.state.password.type}
                name="userPassword"
                placeholder="Mot de passe"
                onChange={this.handleChange}
                required
              ></input>
              <div className="input-group-addon">
                <a href="#" onClick={this.showPassword}>
                  <i
                    className={
                      this.state.password.type == "password"
                        ? "fa fa-eye-slash"
                        : "fa fa-eye"
                    }
                  ></i>
                </a>
              </div>
              <span className="error">
                {t("authentication.errors.wrong_password")}
              </span>
            </div>

            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6 col-sm-12">
                <button className="btn btn-primary btn-connection" type="submit">
                  {t("authentication.connect")}
                </button>
              </div>
              <div className="col-md-3"></div>
            </div>

            <div className="row">
              <div className="col-md-12 forgot">
                <center>
                  <Link
                    to="/forgot-password"
                    className="btn btn-link black-text"
                    type="button"
                  >
                    {t("authentication.forgot_your_password")}
                  </Link>
                </center>
              </div>
              {/* <div className="col-md-6 text-right">
                <a
                  href="mailto:contact@docatweb.fr"
                  className="btn btn-link black-text"
                  type="button"
                >
                  {t("authentication.contact_us")}
                </a>
              </div> */}
            </div>
            <div className="d-flex justify-content-center">
              <LanguagesSwitcher label={"Langues"} />
            </div>
          </div>
        </form>
       
        {/*<div class="wrapper">
            <button className="btn btn-primary" type="submit" onClick={this.onSubmitGoogle}>Google</button>
        </div>*/}
      </div>
    );
  }
}
Login.contextType = DataContext;
export default withRouter(withTranslation()(Login));
