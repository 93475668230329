import React from "react";

//css
import './control-panel.scss'

const ControlPanel = ({
  createdAt,
  updatedAt,
  onPressDownload,
  onPressPrint,
  onPressPrevResult,
  currentResult,
  totalResults,
  onPressNextResult,
}) => (
  <div className="dash-bord-control d-flex">
    <div className="fileName flex-1">
      <div>Créé le {createdAt}</div>
      {updatedAt && <div>Dernière modification {updatedAt}</div>}
    </div>
    <div className="exportBtns">
      <button onClick={onPressDownload} className="btn btm-light">
        <i className="lnr-download"></i>
      </button>
      {/* <button onClick={onPressPrint} className="btn btm-light">
        <i className="lnr-printer"></i>
      </button> */}
    </div>
    {/* <div className="navigateBtns">
      <button onClick={onPressPrevResult} className="btn btm-light">
        <i className="fa fa-angle-up"></i>
      </button>
      <span>
        {currentResult}/{totalResults}
      </span>
      <button onClick={onPressNextResult} className="btn btm-light">
        <i className="fa fa-angle-down"></i>
      </button>
    </div> */}
  </div>
);

export default ControlPanel;
