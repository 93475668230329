import { t } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import PinIcon from '../../../../../../components/layouts/header/svg-icons/pin.svg';
import UnpinIcon from '../../../../../../components/layouts/header/svg-icons/unpin.svg';

const TimeLineHead = ({ onChangeTimeLineUnit, showAllContracts, onPressshowAllContracts, displayTimeline, pinTimeline, toggleTimeline}) => (
  <div className="card-body timeLineHead pb-0">
    <h1 className="tab-title">
      {t('frontoffice.home.timeLine.title')} 
      <a style={{ cursor: 'pointer',marginLeft:'10px' }} onClick={() => toggleTimeline()}>
        {/* {!displayTimeline && <i className="fa fa-angle-up"></i>}
        {displayTimeline && <i className="fa fa-angle-down"></i>} */}
        {!pinTimeline && <PinIcon />}
        {pinTimeline && <UnpinIcon />}
       
        
      </a>
    </h1>
    {displayTimeline &&
    <div className="timeLineControlers">
      <ul className="nav">
        <li className="nav-item">
          <a
            data-toggle="tab"
            href="#time-line-jours"
            className="active nav-link"
            onClick={() => onChangeTimeLineUnit(1)}
          >
            {t('frontoffice.home.timeLine.day')}
          </a>
        </li>
        <li className="nav-item">
          <a
            data-toggle="tab"
            href="#time-line-semaine"
            className="nav-link"
            onClick={() => onChangeTimeLineUnit(2)}
          >
            {t('frontoffice.home.timeLine.week')}
          </a>
        </li>
        <li className="nav-item">
          <a
            data-toggle="tab"
            href="#time-line-mois"
            className="nav-link"
            onClick={() => onChangeTimeLineUnit(3)}
          >
            {t('frontoffice.home.timeLine.month')}
          </a>
        </li>
        <li className="nav-item">
          <a
            data-toggle="tab"
            href="#time-line-trimestre"
            className="nav-link"
            onClick={() => onChangeTimeLineUnit(4)}
          >
            {t('frontoffice.home.timeLine.quarter')}
          </a>
        </li>
        <li className="nav-item">
          <a
            data-toggle="tab"
            href="#time-line-annee"
            className="nav-link"
            onClick={() => onChangeTimeLineUnit(5)}
          >
            {t('frontoffice.home.timeLine.year')}
          </a>
        </li>
      </ul>
    </div>}
    {showAllContracts && (
      <div className="showAllContracts">
        <button className="btn btn-warning" onClick={onPressshowAllContracts}>
          <i className="fa fa-eye" aria-hidden="true"></i>{' '}
          {t('frontoffice.home.timeLine.show_all_contract')}
        </button>
      </div>
    )}
  </div>
);

export default withTranslation()(TimeLineHead);
