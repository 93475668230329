import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie';
const DataForm = ({user, onFormSubmit, isUserFilled=false, onEditUser, clearUserForm, setClearUserForm, upload_in_progress, ocr_in_progress}) => {
  
  const [render, setRender] = useState(true)

  const [company, setCompany] = useState(user && user.company !== undefined ?  user.company : '')
  const [lastName, setLastName] = useState(user && user.lastName !== undefined ?  user.lastName : '')
  const [firstName, setFirstName] = useState(user && user.firstName !== undefined ?  user.firstName : '')
  const [job, setJob] = useState(user && user.job !== undefined ?  user.job : '')
  const [email, setEmail] = useState(user && user.email !== undefined ?  user.email : '')
  const [emailconfirm, setEmailConfirm] = useState(user && user.emailconfirm !== undefined ?  user.emailconfirm : '')
  const [rememberMe, setRememberMe] = useState(Cookies.get("destUser") !== undefined ?  true : false)


  const onSubmit = () => {
    onFormSubmit({
      company,
      lastName,
      firstName,
      job,
      email,
      emailconfirm
    })
  }

  useEffect(() => {
    if(clearUserForm){
      clear()
      setClearUserForm(false)
    }
  }, [user])

  useEffect(() => {
    if(rememberMe){
      Cookies.set("destUser",
        {
          company,
          lastName,
          firstName,
          job,
          email,
          emailconfirm
        }
      )
    }else{
      if(Cookies.get("destUser") !== undefined){
        Cookies.remove("destUser")
      }
    }
  }, [company, lastName, firstName, job, email, emailconfirm, rememberMe])
  

  const clear = () => {
    setCompany('')
    setLastName('')
    setFirstName('')
    setJob('')
    setEmail('')
    setEmailConfirm('')
    
    onFormSubmit({
      company: '',
      lastName: '',
      firstName: '',
      job: '',
      email: '',
      emailconfirm: '',
    })
    setRender(false)
    setTimeout(() => setRender(true));
  }

  const isFieldRequired = () =>{
    if(upload_in_progress)
      return true
    if(ocr_in_progress)
      return true
    if(!company || company.trim() === "")
      return true
    else if(!lastName || lastName.trim() === "")
      return true
    else if(!firstName || firstName.trim() === "")
      return true
    else if(!job || job.trim() === "")
      return true
    else if(!email || email.trim() === "")
      return true
    else if(!emailconfirm || emailconfirm.trim() === "")
      return true
    else if(email !== emailconfirm)
      return true
    else
      return false
  }
  
  return render ? (
    <form
      className="formData-BS"
      noValidate
    >
      <div className="row">
        <div className="col-lg-12">
          {/* <h2>Nom société</h2> */}
          <div className={isUserFilled ? "multi-select-checkboxes userFilled" : "multi-select-checkboxes"}>
            <input
              disabled={isUserFilled}
              type="text"
              name="company"
              value={company}
              onChange={(e)=>{
                setCompany(e.target.value)
              }}
              placeholder="Nom société (*)"
              className={company ? "form-control":"form-control required-input"}
            />
          </div>
        </div>
        <div className="col-lg-12">
          {/* <h2>Prénom</h2> */}
          <div className={isUserFilled ? "multi-select-checkboxes userFilled" : "multi-select-checkboxes"}>
            <input
              disabled={isUserFilled}
              type="text"
              name="lastName"
              value={lastName}
              onChange={(e)=>{
                setLastName(e.target.value)
              }}
              placeholder="Nom (*)"
              className={lastName ? "form-control":"form-control required-input"}
            />
          </div>
        </div>
        <div className="col-lg-12">
          {/* <h2>Nom</h2> */}
          <div className={isUserFilled ? "multi-select-checkboxes userFilled" : "multi-select-checkboxes"}>
            <input
              disabled={isUserFilled}
              type="text"
              name="firstName"
              value={firstName}
              onChange={(e)=>{
                setFirstName(e.target.value)
              }}
              placeholder="Prénom (*)"
              className={firstName ? "form-control":"form-control required-input"}
            />
          </div>
        </div>
        <div className="col-lg-12">
          {/* <h2>Fonction</h2> */}
          <div className={isUserFilled ? "multi-select-checkboxes userFilled" : "multi-select-checkboxes"}>
            <input
              disabled={isUserFilled}
              type="text"
              name="job"
              value={job}
              onChange={(e)=>{
                setJob(e.target.value)
              }}
              placeholder="Fonction (*)"
              className={job ? "form-control":"form-control required-input"}
            />
          </div>
        </div>
        <div className="col-lg-12">
          {/* <h2>Adresse mail</h2> */}
          <div className={isUserFilled ? "multi-select-checkboxes userFilled" : "multi-select-checkboxes"}>
            <input
              disabled={isUserFilled}
              type="text"
              name="email"
              value={email}
              onChange={(e)=>{
                setEmail(e.target.value)
              }}
              placeholder="Adresse mail (*)"
              className={email ? "form-control":"form-control required-input"}
            />
          </div>
        </div>
        <div className="col-lg-12">
          {/* <h2>Vérification Adresse mail</h2> */}
          <div className={isUserFilled ? "multi-select-checkboxes userFilled" : "multi-select-checkboxes"}>
            <input
              disabled={isUserFilled}
              type="text"
              name="emailconfirm"
              value={emailconfirm}
              onChange={(e)=>{
                setEmailConfirm(e.target.value)
              }}
              placeholder="Vérification Adresse mail (*)"
              className={emailconfirm && emailconfirm === email ? "form-control":"form-control required-input"}
            />
          </div>
          { emailconfirm !== email && emailconfirm && email &&
            <p className='alert alert-danger required-fields m-auto'>Les 2 emails ne sont pas identiques</p>
          }
        </div>
        <div className="col-lg-12">
          <div className="custom-checkbox">
            <label>
              Se souvenir de moi ?
              <input
                // disabled={isUserFilled}
                type="checkbox"
                name="rememberme"
                checked={rememberMe}
                onChange={(e) => {
                  setRememberMe(!rememberMe)
                }}
              ></input>
              <span className="cr ml-2">
                <i className="cr-icon fa fa-check"></i>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <small className="required-fields">
            (*) Champs obligatoires
          </small>
          <p className="mt-4">
            Ces informations sont collectées uniquement pour le fonctionnement de l’application (envoi des exports) et possiblement à des fin commerciales pour Docatweb SAS uniquement. Elle ne seront divulguées ni revendues à aucun tiers. Docatweb mettra tout en œuvre pour en assurer la confidentialité et la protection. Je reconnais avoir pris connaissance du présent paragraphe en passant à l’étape suivante
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          { !isUserFilled &&
            <button
              type="button"
              onClick={onSubmit}
              className={isFieldRequired() ? "btn btn-block btn-search disabled-btn" : "btn btn-warning btn-block btn-search"}
              disabled={isFieldRequired()}
            >
              OK
            </button>
          }
          { isUserFilled &&
            <button
              type="button"
              onClick={onEditUser}
              className={isFieldRequired() ? "btn btn-block btn-search disabled-btn" : "btn btn-warning btn-block btn-search"}
              disabled={isFieldRequired()}
            >
              Modifier
            </button>
          }
        </div>
      </div>
    </form>
  ):null;
}


export default DataForm