import React, { Component } from "react";

import "./BankStatements.scss";
import Cookies from 'js-cookie';
// components
import Sidebar from "../../components/layouts/sidebar/Sidebar";
import DataForm from "./parts/DataForm";
import HeaderBankStatements from "../../components/layouts/header-bank-statements/headerBankStatements";
import UploadIcon from "./icons/upload.icon";
import DupplicateIcon from "./icons/dupplicate.icon";
import ReactMultiselect from "../../components/react-multiselect";
import NewIcon from "./icons/new.icon";
import UploadModal from "./parts/UploadModal";
import { DeleteIcon } from "./icons";
import { importStatements } from "../../api/dest/statements";
import NotifyAlert from "../../components/confirm-alert/notify-alert/NotifyAlert";
import errorAlert from "../../components/confirm-alert/error-alert";
import LoaderComponent from "../../components/loader/Loader";
import LoaderOCRComponent from "../../components/loader-ocr/LoaderOcr";
const monthOptions = [
  { label: "Janvier", value: 1 },
  { label: "Février", value: 2 },
  { label: "Mars", value: 3 },
  { label: "Avril", value: 4 },
  { label: "Mai", value: 5 },
  { label: "Juin", value: 6 },
  { label: "Juillet", value: 7 },
  { label: "Août", value: 8 },
  { label: "Septembre", value: 9 },
  { label: "Octobre", value: 10 },
  { label: "Novembre", value: 11 },
  { label: "Décembre", value: 12 }
];

const currentYear = new Date().getFullYear();
const yearOptions = Array.from({ length: 12 }, (_, i) => {
  const year = currentYear + 1 - i;
  return { label: year.toString(), value: year };
});

class BankStatements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: Cookies.get("destUser") !== undefined ? JSON.parse(Cookies.get("destUser")): null,
      statements: [
        // {
        //   period: {
        //     month: 6,
        //     year: 2024
        //   },
        //   infos: {
        //     account: "Compte crédit/débit",
        //     bank: "BANK OF AFRICA",
        //     folder: "my folder"
        //   },
        //   uploadedDocs: []
        // },
        // {
        //   period: {
        //     month: 4,
        //     year: 2023
        //   },
        //   infos: {
        //     account: "Compte crédit/débit",
        //     bank: "CFG BANK",
        //     folder: "custom folder"
        //   },
        //   uploadedDocs: []
        // },
        // {
        //   period: {
        //     month: 2,
        //     year: 2021
        //   },
        //   infos: {
        //     account: "Compte crédit/débit",
        //     bank: "BNP PARIBAS",
        //     folder: "second folder"
        //   },
        //   uploadedDocs: []
        // },
      ],
      selectedIndex: null,
      showModalUpload: false,
      isUserFilled: Cookies.get("destUser") !== undefined ?  true : false,
      clearUserForm: false,
      upload_in_progress: false,
      ocr_in_progress: false,
      uploadProgress: 0
    };
    this.form = {};
  }

  async componentDidMount() {
    // LoaderComponent.show();
    // LoaderComponent.hide();
  }

  onFormSubmit = (data) => {
    this.form.company = data.company;
    this.form.lastName = data.lastName;
    this.form.firstName = data.firstName;
    this.form.job = data.job;
    this.form.email = data.email;
    this.form.emailconfirm = data.emailconfirm;
    this.setState({
      ...this.state,
      user:this.form,
      isUserFilled: true
    })
  };

  onEditUser = (data) => {
    this.setState({
      ...this.state,
      isUserFilled: false
    })
  };

  dupplicateRow = (index) => {
    let statements = [...this.state.statements];
    let rowToDuplicate = { ...statements[index], period: { ...statements[index].period }, infos: { ...statements[index].infos } };
    rowToDuplicate.uploadedDocs = [];
    statements.splice(index + 1, 0, rowToDuplicate);
    this.setState({...this.state, statements, selectedIndex: index + 1 });
  };

  deleteRow = (index) => {
    let statements = [...this.state.statements];
    statements.splice(index, 1);
    this.setState({ ...this.state, statements, selectedIndex: null });
  };

  onStartUploadProcess = (index) => {
    this.setState({...this.state, showModalUpload:true, selectedIndex: index})
  };

  handleChange = (e, index, itemName) => {
    if (itemName === "month" || itemName === "year") {
      let statements = [...this.state.statements];
      statements[index].period[itemName] = e.value;
      this.setState({...this.state, statements, selectedIndex: index });
    }else{
      let statements = [...this.state.statements];
      statements[index].infos[itemName] = e.value;
      this.setState({...this.state, statements, selectedIndex: index });
    }
  };

  newRow = () => {
    if(this.state.user === null){
      NotifyAlert.show(
        "Veuillez saisir les informations dans la partie gauche et cliquer sur OK pour accéder à l’upload de fichiers",
        'Information'
      ).then(() => {});
    }else{
      let statements = [...this.state.statements];
      // Find the highest id in the array
      let highestId = statements.length> 0 ? Math.max(...statements.map(record => record.fileId)):0;
      statements.push({
        fileId: highestId+1,
        period: {
          month: new Date().getMonth()+1,
          year: new Date().getFullYear()
        },
        infos: {
          account: null,
          bank: null,
          folder: null
        },
        uploadedDocs:[]
      });
      console.log(statements)
      this.setState({...this.state, statements});
    }
  }

  uploadedFiles = (files) => {
    console.log(files)
    let statements = [...this.state.statements];
      statements[this.state.selectedIndex].uploadedDocs = files;
      console.log("file_"+statements[this.state.selectedIndex].fileId); 
      this.setState({...this.state, statements, showModalUpload: false});
  }

  sentRequest = () => {
    console.log("statements",this.state.statements)
    let uploadedSize = 0;
    const totalFileSize = this.state.statements.reduce((total, statement) => {
      const uploadedDocsTotal = statement.uploadedDocs.reduce((subTotal, doc) => {
        return subTotal + doc.file.size;
      }, 0);
      return total + uploadedDocsTotal;
    }, 0);
    console.log("totalFileSize",totalFileSize);
    this.setState({...this.state, upload_in_progress: true})
  
    try {
      importStatements(this.state.user, this.state.statements, (progressEvent) => {
        uploadedSize += progressEvent.loaded;
        const percentComplete = (uploadedSize / totalFileSize) * 100;
        if(Math.min(percentComplete, 100) === 100){
          this.setState({...this.state, uploadProgress: Math.min(percentComplete, 100), upload_in_progress: false, ocr_in_progress: true})
          // LoaderComponent.show();
        }else{
          this.setState({...this.state, uploadProgress: Math.min(percentComplete, 100)})
        }
        console.log(Math.min(percentComplete, 100));
      }).then(respnse=>{
        LoaderComponent.hide();
        NotifyAlert.show(
          "Relevé(s) envoyé(s) avec succès",
          'Information'
        ).then(() => {
          this.clearRequest();
          this.setState({...this.state, uploadProgress: 0, upload_in_progress: false, ocr_in_progress: false})
        });
      },(error)=>{
        // LoaderComponent.hide();
        this.setState({...this.state, uploadProgress: 0, upload_in_progress: false, ocr_in_progress: false})
        errorAlert.show(error?.response?.data?.message || error?.response?.data, "Erreur API");
      })
    } catch (error) {
      // LoaderComponent.hide();
      this.setState({...this.state, uploadProgress: 0, upload_in_progress: false, ocr_in_progress: false})
      errorAlert.show("Une erreur est survenue lors de l'envoie de(s) relevé(s)", "Erreur");
    }

  }

  clearRequest = () => {
    this.setState({...this.statexw, statements: [], selectedIndex: null, showModalUpload: false, isUserFilled: true, clearUserForm:false },()=>{
      this.form = {};
    });
  }

  

  render() {
    return (
      <div className="app-container bank-statements app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
        <HeaderBankStatements />

        <div className="app-main">
          <div className="app-sidebar sidebar-shadow">
            <Sidebar />
          </div>
          <div className="app-main__outer">
            <div className="bg-under-header editModeTitle">
              <span className="empty-header"> </span>
            </div>
            <div className="app-main__inner">
              <div className="row">
                <div className="col-sm-5 left-side">
                  <DataForm
                    user={this.state.user}
                    onFormSubmit={this.onFormSubmit}
                    onEditUser={this.onEditUser}
                    isUserFilled={this.state.isUserFilled}
                    clearUserForm={this.state.clearUserForm}
                    setClearUserForm={(clearUserForm)=>this.setState({...this.state, clearUserForm: clearUserForm})}
                    upload_in_progress={this.state.upload_in_progress} 
                    ocr_in_progress={this.state.ocr_in_progress}
                  />
                </div>
                <div className="col-sm-7 viewer">
                  <div className="head d-bloc">
                    <h2 className="title-viewer">Effectuez vos demandes</h2>
                    <p className="sub-title">
                      Vous pourrez sélectionner plusieurs fichiers par demande, ils seront fusionnés pour être traités comme un seul fichier et seront réunis dans un même fichier d’export
                    </p>
                  </div>
                  {!(this.state.upload_in_progress || this.state.ocr_in_progress) &&
                    <table
                      width="100%"
                      id="example"
                      className="table table-hover"
                      data-searching="false"
                      data-select="false"
                      data-paging="false"
                      data-info="false"
                    >
                      <thead>
                        <tr>
                          <th></th>
                          <th colSpan={2} className="title-viewer text-left">Période</th>
                          <th colSpan={3} className="title-viewer text-left">Informations complémentaires</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.statements.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span className="action-btn mr-1" title="Supprimer" onClick={() => this.deleteRow(index)}>
                                <DeleteIcon />
                              </span>
                              <span className="action-btn" title="Duppliquer" onClick={() => this.dupplicateRow(index)}>
                                <DupplicateIcon />
                              </span>
                            </td>
                            <td>
                              <div className="multi-select-checkboxes">
                                <ReactMultiselect
                                  isMulti={false}
                                  placeholderButtonLabel={item.period.month !== null ? item.period.month : "Sélectionner"}
                                  closeMenuOnSelect={true}
                                  placeholder="Rechercher"
                                  onChange={(e) => this.handleChange(e, index, "month")}
                                  className="multi-select-options-bank"
                                  options={monthOptions}
                                  value={item.period.month ? monthOptions.find(x => x.value === item.period.month) : null}
                                />
                              </div>
                            </td>
                            <td>
                              <div className="multi-select-checkboxes">
                                <ReactMultiselect
                                  isMulti={false}
                                  placeholderButtonLabel={item.period.year !== null ? item.period.year : "Sélectionner"}
                                  closeMenuOnSelect={true}
                                  placeholder="Rechercher"
                                  onChange={(e) => this.handleChange(e, index, "year")}
                                  className="multi-select-options-bank"
                                  options={yearOptions}
                                  value={item.period.year ? yearOptions.find(x => x.value === item.period.year) : null}
                                />
                              </div>
                            </td>
                            <td>
                              <input
                                type="text"
                                name="account"
                                value={item.infos.account}
                                onChange={(e) => {
                                  this.handleChange(e.target, index, e.target.name)
                                }}
                                className="form-control"
                                placeholder="Compte"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="bank"
                                value={item.infos.bank}
                                onChange={(e) => {
                                  this.handleChange(e.target, index, e.target.name)
                                }}
                                className="form-control"
                                placeholder="Banque"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="folder"
                                value={item.infos.folder}
                                onChange={(e) => {
                                  this.handleChange(e.target, index, e.target.name)
                                }}
                                className={item.infos.folder ? "form-control" : "form-control required-input"}
                                placeholder="Nom Dossier (*)"
                              />
                            </td>
                            <td>
                              <span className="action-btn" title="Import relevés" onClick={() => this.onStartUploadProcess(index)}>
                                <UploadIcon />
                                <span className="badge-count">{item?.uploadedDocs?.length}</span>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  }
                  {!(this.state.upload_in_progress || this.state.ocr_in_progress) &&
                    <div className="row">
                      <div className="col-lg-12 text-right">
                        <span className="mr-3 action-new-item" onClick={() => { this.newRow() }}>
                          <NewIcon />
                        </span>
                      </div>
                    </div>
                  }
                  {!(this.state.upload_in_progress || this.state.ocr_in_progress) &&
                    <div className="row formData-BS">
                      <div className="col-lg-12">
                        <button type="button" onClick={() => this.sentRequest()}
                          className={(this.state.statements.length === 0 || (this.state.statements.length > 0 &&
                            (this.state.statements.some(x => x.uploadedDocs === undefined || x.uploadedDocs.length === 0) || this.state.statements.some(x => x["infos"].folder === undefined || x["infos"].folder === null || x["infos"].folder === ""))
                          )) ? "btn btn-block btn-search  disabled-btn" : "btn btn-warning btn-block btn-search"}
                          disabled={this.state.upload_in_progress || this.state.ocr_in_progress || (this.state.statements.length === 0 || (this.state.statements.length > 0 &&
                            (this.state.statements.some(x => x.uploadedDocs === undefined || x.uploadedDocs.length === 0) || this.state.statements.some(x => x["infos"].folder === undefined || x["infos"].folder === null || x["infos"].folder === ""))
                          ))} >
                          Envoyer
                        </button>
                      </div>
                    </div>
                  }
                  {this.state.upload_in_progress &&
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="progress-area mt-4">
                          <p className="text-center">
                            {this.state.uploadProgress === 100 ? "Relevé(s) envoyé(s) avec succès":"Progression d'envoie de relevé(s)"}
                          </p>
                          <div className="mb-3 progress w-75 m-auto">
                            <div className="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style={{ width: this.state.uploadProgress + '%' }}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {this.state.ocr_in_progress &&
                    <div className="row">
                      <div className="col-lg-12 mt-3 text-center">
                        {
                          LoaderOCRComponent.getLoader()
                        }
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <UploadModal isOpen={this.state.showModalUpload} onConfirmUploadedFiles={(files)=>this.uploadedFiles(files)} onRequestClose={()=>this.setState({...this.state, showModalUpload:false})} currentRowFiles={this.state.selectedIndex !== null ? (this.state.statements[this.state.selectedIndex].uploadedDocs || null):[]} />
      </div>
    );
  }
}

export default BankStatements;
