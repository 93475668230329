import React from "react";

const TrashIcon = ({color = "#ffff", width="24", height="24"}) => (
  <>

    <svg width={width} height={height} viewBox="0 0 36 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.5 5.5V24.25C23.5 26.3211 21.8211 28 19.75 28H6.25C4.17893 28 2.5 26.3211 2.5 24.25V5.5H1.75C1.33579 5.5 1 5.16421 1 4.75C1 4.33579 1.33579 4 1.75 4H8.5V3.25C8.5 2.00736 9.50736 1 10.75 1H15.25C16.4926 1 17.5 2.00736 17.5 3.25V4H24.25C24.6642 4 25 4.33579 25 4.75C25 5.16421 24.6642 5.5 24.25 5.5H23.5ZM4 5.5V24.25C4 25.4926 5.00736 26.5 6.25 26.5H19.75C20.9926 26.5 22 25.4926 22 24.25V5.5H4ZM16 4V3.25C16 2.83579 15.6642 2.5 15.25 2.5H10.75C10.3358 2.5 10 2.83579 10 3.25V4H16ZM16 10.75C16 10.3358 16.3358 10 16.75 10C17.1642 10 17.5 10.3358 17.5 10.75V21.25C17.5 21.6642 17.1642 22 16.75 22C16.3358 22 16 21.6642 16 21.25V10.75ZM8.5 10.75C8.5 10.3358 8.83579 10 9.25 10C9.66421 10 10 10.3358 10 10.75V21.25C10 21.6642 9.66421 22 9.25 22C8.83579 22 8.5 21.6642 8.5 21.25V10.75Z" stroke={color} />
    </svg>

  </>
);

export default TrashIcon;