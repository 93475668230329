import React from "react";
import { Chart } from "react-google-charts";

const ChartLine = ({ title, subTitle, data, displayLegend, colors }) => (
  <Chart
    chartType="LineChart"
    width="100%"
    height="100%"
    data={data}
    options={{
      chart: {
        title: title,
        subtitle: subTitle,
      },
      colors: colors.flat(),
      legend: { position: displayLegend ? "right" : "none" },
      animation: {
        startup: true,
        easing: 'linear',
        duration: 500,
      }
    }}
  />
);

export default ChartLine;
