import React, { Component } from 'react';

class CustomDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedOption: (this.props.handledBy !== null && this.props.handledBy !== undefined) ? this.props.options.find(item => item.id === this.props.handledBy.id): null
    };
    this.dropdownRef = React.createRef(); // Create a ref to the dropdown container
  }

  componentDidMount() {
    // Add a click event listener to the document to handle clicks outside the component
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    document.removeEventListener('click', this.handleClickOutside);
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  selectOption = (option, isWorkflow, position) => {
    this.setState({
      selectedOption: option,
      isOpen: false,
      isWorkflow: isWorkflow,
      position: position
    });
    // Call the onSelect prop with the selected option
    if (this.props.onSelect) {
        this.props.onSelect(option, position);
    }
  };

  // Add this method to prevent the page from reloading
  handleButtonClick = (e) => {
    e.preventDefault();
    this.toggleDropdown();
  };

  // Handle clicks outside the component
  handleClickOutside = (e) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
      // Click occurred outside the dropdown, so close it
      this.setState({
        isOpen: false,
      });
    }
  };

  render() {
    const { options, isWorkflow, position, requiredFieldErrored, disabled, canBeFree, handledByArchivedUser } = this.props;
    const { isOpen } = this.state;
    let selectedOption = (this.props.handledBy !== null && this.props.handledBy !== undefined) ? this.props.options.find(item => item.id === this.props.handledBy.id): null
    return (
      <div className="custom-dropdown" ref={this.dropdownRef}>
        <button disabled={disabled}  onClick={this.handleButtonClick} className="dropdown-toggle form-control" style={{borderBottom: requiredFieldErrored ? "1px solid #EB3A59": "1px solid #ccc", color:selectedOption  && selectedOption.isBuyer ? "#219653": "#190734"}}>
          {selectedOption ? (selectedOption.firstName + " " + selectedOption.lastName) : (this.props.handledBy !== null && this.props.handledByArchivedUser !== undefined &&  typeof this.props.handledByArchivedUser == "string" ? this.props.handledByArchivedUser: "--- Intervenant ---")}
        </button>
        {isOpen && (
          <ul className="dropdown-list">
            {canBeFree && 
            <>
              <li
                key={-1}
                onClick={() => this.selectOption(null, false, 0)}
                className="dropdown-item font-weight-bold text-danger"
              >
                Libérer le contrat
              </li>
              <li
                  key={-1}
                  className="dropdown-item font-weight-bold replacedby"
                >
                 --- Remplacé par ---
                </li>
            </>
            }
            {options.map((option, key) => (
              <li
                key={option.id}
                onClick={() => this.selectOption(option, isWorkflow, position)}
                className="dropdown-item" style={{backgroundColor: selectedOption && selectedOption.id == option.id ?"#f6603138": "transparent" ,color:option.isBuyer ? "#219653": "black", fontWeight: 600}}
              >
                {option.firstName + " " + option.lastName}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default CustomDropdown;
