import React from "react";
export default () => (
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 2.08333C1 1.48502 1.48502 1 2.08333 1H3.16667C3.76498 1 4.25 1.48502 4.25 2.08333V3.16667C4.25 3.76497 3.76498 4.25 3.16667 4.25H2.08333C1.48502 4.25 1 3.76497 1 3.16667V2.08333ZM6.95833 3.16667C6.65918 3.16667 6.41667 2.92415 6.41667 2.625C6.41667 2.32585 6.65918 2.08333 6.95833 2.08333H19.9583C20.2575 2.08333 20.5 2.32585 20.5 2.625C20.5 2.92415 20.2575 3.16667 19.9583 3.16667H6.95833ZM1 7.5C1 6.90169 1.48502 6.41667 2.08333 6.41667H3.16667C3.76498 6.41667 4.25 6.90169 4.25 7.5V8.58333C4.25 9.18164 3.76498 9.66667 3.16667 9.66667H2.08333C1.48502 9.66667 1 9.18164 1 8.58333V7.5ZM6.95833 8.58333C6.65918 8.58333 6.41667 8.34082 6.41667 8.04167C6.41667 7.74251 6.65918 7.5 6.95833 7.5H19.9583C20.2575 7.5 20.5 7.74251 20.5 8.04167C20.5 8.34082 20.2575 8.58333 19.9583 8.58333H6.95833ZM1 12.9167C1 12.3184 1.48502 11.8333 2.08333 11.8333H3.16667C3.76498 11.8333 4.25 12.3184 4.25 12.9167V14C4.25 14.5983 3.76498 15.0833 3.16667 15.0833H2.08333C1.48502 15.0833 1 14.5983 1 14V12.9167ZM2.08333 2.08333V3.16667H3.16667V2.08333H2.08333ZM2.08333 7.5V8.58333H3.16667V7.5H2.08333ZM2.08333 12.9167V14H3.16667V12.9167H2.08333ZM6.95833 14C6.65918 14 6.41667 13.7575 6.41667 13.4583C6.41667 13.1592 6.65918 12.9167 6.95833 12.9167H19.9583C20.2575 12.9167 20.5 13.1592 20.5 13.4583C20.5 13.7575 20.2575 14 19.9583 14H6.95833Z"
      stroke="#E0E0E0"
    />
  </svg>
);
