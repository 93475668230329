import React, { useState, useRef, useEffect  } from "react";
import { t } from 'i18next';
import NotifyAlert from "../../../../../../../components/confirm-alert/notify-alert/NotifyAlert";
import LoaderComponent from '../../../../../../../components/loader/Loader';
import "./tabs.scss";

const Tabs = ({ pages, onChangeTab, onPressAdd, onPressDel, onEditTab, toast,onSetActiveTab, activeTab }) => {
  

  return (
    <div className="dash-bord-consult-tabs">
      <div className="d-flex">
        {pages.map((page, index) => (
          <TabItem
            key={index}
            name={page.page.name}
            active={activeTab === index}
            onPress={() => {
              onSetActiveTab(index);
              onChangeTab(page);
            }}
            onEditTab={onEditTab}
            page={page.page}
            currentPage={pages[index].page}
            pages={pages}
            toast={toast}
          />
        ))}
        <div className="dash-bord-consult-tabs-add-tab">
          {pages && pages.length<10 && <button onClick={onPressAdd}>
            <i className="fa fa-plus"></i>{(pages && pages.length==0)?' Nouvelle page':''}
          </button>}
          {pages && pages.length>0 && <button onClick={onPressDel}>
            <i className="fa fa-minus"></i>
          </button>}
        </div>
      </div>
    </div>
  );
};

const TabItem = ({ key, name, active, onPress, onEditTab, page, currentPage, pages, toast}) => {
  const [_name, setName] = useState(name);
  const [editName, setEditName] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    // Add a click event listener to the document to handle clicks outside the input
    const handleOutsideClick = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setEditName(false);
      }
    };

    if (editName) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [editName]);

  const handleInputBlur = async () => {
    setEditName(false);
    try {
      if(pages && pages.length>0){
        let found = pages.find(item=> (item.page.name).toLowerCase().replace(/\s/g, '') == _name.toLowerCase().replace(/\s/g, ''))
        if(found){
          toast.warn(t('Il existe déjà une page portant le même nom : ' + _name), {
            position: toast.POSITION.TOP_RIGHT
          });
          LoaderComponent.hide();
          setName(currentPage.name)
          return;
        }
      }
      await onEditTab({
        id: page.id,
        name: _name,
      });
      LoaderComponent.hide();
      toast.success(t('Page modifié avec succès'), {
        position: toast.POSITION.TOP_RIGHT
      });
      
    } catch (error) {
      // console.log('error', error);
      setName(currentPage.name)
      LoaderComponent.hide();
      toast.error(t('Une erreur est survenue, page non modifié'), {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const handleInputKeyPress = async (e) => {
    if (e.key === 'Enter') {
      setEditName(false);
      try {
        if(pages && pages.length>0){
          let found = pages.find(item=> (item.page.name).toLowerCase().replace(/\s/g, '') == _name.toLowerCase().replace(/\s/g, ''))
          if(found){
            toast.warn(t('Il existe déjà une page portant le même nom : ' + _name), {
              position: toast.POSITION.TOP_RIGHT
            });
            LoaderComponent.hide();
            setName(currentPage.name)
            return;
          }
        }
        await onEditTab({
          id: page.id,
          name: _name,
        });
        LoaderComponent.hide();
        toast.success(t('Page modifié avec succès'), {
          position: toast.POSITION.TOP_RIGHT
        });
        
      } catch (error) {
        // console.log('error', error);
        setName(currentPage.name)
        LoaderComponent.hide();
        toast.error(t('Une erreur est survenue, page non modifié'), {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  };


  const handleKeyDown = async (event) => {
    if (event.key === "Enter" && _name ) {
      setEditName(false)
      try {
        if(pages && pages.length>0){
          let found = pages.find(item=> (item.page.name).toLowerCase().replace(/\s/g, '') == _name.toLowerCase().replace(/\s/g, ''))
          if(found){
            toast.warn(t('Il existe déjà une page portant le même nom : ' + _name), {
              position: toast.POSITION.TOP_RIGHT
            });
            LoaderComponent.hide();
            setName(currentPage.name)
            return;
          }
        }
        await onEditTab({
          id: page.id,
          name: _name,
        });
        LoaderComponent.hide();
        toast.success(t('Page modifié avec succès'), {
          position: toast.POSITION.TOP_RIGHT
        });
        
      } catch (error) {
        // console.log('error', error);
        setName(currentPage.name)
        LoaderComponent.hide();
        toast.error(t('Une erreur est survenue, page non modifié'), {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  };

  return (
    <div key={key} className="dash-bord-consult-tabs-item">
      {editName ? (
        <input
          ref={inputRef}
          type
          value={_name}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleInputBlur}
          onKeyPress={handleInputKeyPress}
          className="form-control"
          required
        />
      ) : (
        <button
          className={active ? "active" : ""}
          onClick={onPress}
          onDoubleClick={() => setEditName(true)}
        >
          {_name}
        </button>
      )}
    </div>
  );
};

export default Tabs;
