
import React from "react";
export default ({ color }) => (
    <svg width="24" height="30" viewBox="0 0 44 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* Inclined line */}
      <line x1="0" y1="0" x2="44" y2="54" stroke={color ? color : "#E0E0E0"} strokeWidth="2"/>
  
      <path d="M2.5 1.00714C1.67157 1.00714 1 1.68351 1 2.51786V13.5964C1 14.4308 1.67157 15.1071 2.5 15.1071H17.5C18.3284 15.1071 19 14.4308 19 13.5964V5.53928C19 4.70494 18.3284 4.02857 17.5 4.02857H12C10.8954 4.02857 10 3.12674 10 2.01429C10 1.45806 9.55228 1.00714 9 1.00714H2.5ZM2.5 0H9C10.1046 0 11 0.901826 11 2.01429C11 2.57051 11.4477 3.02143 12 3.02143H17.5C18.8807 3.02143 20 4.14871 20 5.53928V13.5964C20 14.987 18.8807 16.1143 17.5 16.1143H2.5C1.11929 16.1143 0 14.987 0 13.5964V2.51786C0 1.12728 1.11929 0 2.5 0Z" fill={color ? color : "#E0E0E0"}/>
      <line x1="9" y1="44" x2="9" y2="16" stroke={color ? color : "#E0E0E0"} strokeWidth="2"/>
      <line x1="9" y1="26" x2="25" y2="26" stroke={color ? color : "#E0E0E0"} strokeWidth="2"/>
      <path d="M26.5 19H33C34.1046 19 35 19.9018 35 21.0143C35 21.5705 35.4477 22.0214 36 22.0214H41.5C42.8807 22.0214 44 23.1487 44 24.5393V32.5964C44 33.987 42.8807 35.1143 41.5 35.1143H26.5C25.1193 35.1143 24 33.987 24 32.5964V21.5179C24 20.1273 25.1193 19 26.5 19ZM26.5 20.0071C25.6716 20.0071 25 20.6835 25 21.5179V32.5964C25 33.4308 25.6716 34.1071 26.5 34.1071H41.5C42.3284 34.1071 43 33.4308 43 32.5964V24.5393C43 23.7049 42.3284 23.0286 41.5 23.0286H36C34.8954 23.0286 34 22.1267 34 21.0143C34 20.4581 33.5523 20.0071 33 20.0071H26.5Z" fill={color ? color : "#E0E0E0"}/>
      <line x1="8" y1="44" x2="24" y2="44" stroke={color ? color : "#E0E0E0"} strokeWidth="2"/>
      <path d="M26.5 37H33C34.1046 37 35 37.9018 35 39.0143C35 39.5705 35.4477 40.0214 36 40.0214H41.5C42.8807 40.0214 44 41.1487 44 42.5393V50.5964C44 51.987 42.8807 53.1143 41.5 53.1143H26.5C25.1193 53.1143 24 51.987 24 50.5964V39.5179C24 38.1273 25.1193 37 26.5 37ZM26.5 38.0071C25.6716 38.0071 25 38.6835 25 39.5179V50.5964C25 51.4308 25.6716 52.1071 26.5 52.1071H41.5C42.3284 52.1071 43 51.4308 43 50.5964V42.5393C43 41.7049 42.3284 41.0286 41.5 41.0286H36C34.8954 41.0286 34 40.1267 34 39.0143C34 38.4581 33.5523 38.0071 33 38.0071H26.5Z" fill={color ? color : "#E0E0E0"}/>
    </svg>
  );
