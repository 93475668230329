import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken} from "./QueryAuthParameters"

export const getUsers = () => {
    const requestUrl = API_URL+`/user/getDocAtWebAdmins?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

export const createUser = (userForm) => {
    var index;
    let addedOrganization = false;
    const requestUrl = API_URL+`/user/createNewDocAtWebAdmin?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    if(userForm.id) {
        formData.append('id', userForm.id);
    }
    formData.append('firstName', userForm.firstName);
    formData.append('lastName', userForm.lastName);
    formData.append('email', userForm.email);

    return axios.post(requestUrl, formData);
}


export const deleteDocAtWebAdmin = (userID) => {
    const requestUrl = API_URL+`/user/deleteDocAtWebAdmin/`+userID+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
}


export const getRgpdUserClients = (userID) => {
    const requestUrl = API_URL+`/user/rgpd/getUserClients/`+userID+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };