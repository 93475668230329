import React from "react";
export default () => (
    <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2518 13.3158H10.7354C11.5843 13.3158 12.2726 14.0051 12.2726 14.8553V18.9605C12.2726 19.8108 11.5843 20.5 10.7354 20.5H8.68584C7.83689 20.5 7.14868 19.8108 7.14868 18.9605V14.8553C7.14868 14.0051 7.83689 13.3158 8.68584 13.3158H9.21536C9.35114 12.1428 10.2796 11.1967 11.4772 11.0634L17.0538 10.4429C17.8323 10.3563 18.4212 9.69727 18.4212 8.91284V6.64477C18.4212 5.79455 17.733 5.1053 16.884 5.1053C16.6011 5.1053 16.3717 4.87555 16.3717 4.59215C16.3717 4.30874 16.6011 4.07899 16.884 4.07899C18.299 4.07899 19.446 5.22773 19.446 6.64477V8.91284C19.446 10.2202 18.4644 11.3185 17.167 11.4629L11.5904 12.0835C10.9128 12.1589 10.3789 12.6679 10.2518 13.3158ZM8.68584 14.3421C8.40286 14.3421 8.17345 14.5719 8.17345 14.8553V18.9605C8.17345 19.2439 8.40286 19.4737 8.68584 19.4737H10.7354C11.0184 19.4737 11.2478 19.2439 11.2478 18.9605V14.8553C11.2478 14.5719 11.0184 14.3421 10.7354 14.3421H8.68584ZM3.56194 1H14.8345C16.2494 1 17.3964 2.14874 17.3964 3.56578V5.61846C17.3964 7.0355 16.2494 8.18424 14.8345 8.18424H3.56194C2.14702 8.18424 1 7.0355 1 5.61846V3.56578C1 2.14874 2.14702 1 3.56194 1ZM3.56194 2.02631C2.71299 2.02631 2.02477 2.71556 2.02477 3.56578V5.61846C2.02477 6.46868 2.71299 7.15793 3.56194 7.15793H14.8345C15.6834 7.15793 16.3717 6.46868 16.3717 5.61846V3.56578C16.3717 2.71556 15.6834 2.02631 14.8345 2.02631H3.56194Z"
      stroke="#E0E0E0"
    />
  </svg>
);
