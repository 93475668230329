import React, { useEffect, useState } from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import "./switcher.scss";

const Switcher = ({ checked, onChange, disabled, offstyle }) => {

  return(
    <div className={`switcher ${disabled ? 'disabled' : ''}`}>
      <BootstrapSwitchButton
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        offstyle={offstyle || null}
      />
    </div>
  );
}

export default Switcher;
