import React from "react";

import ConfirmAlert from "../../../../../../../components/confirm-alert/ConfirmAlert";

import "./delete-graph.scss";
import deleteIcon from "../../../icons/delete.svg";


const DeleteGraph = ({onPress}) => {

  const onPressDelete = () => {
    ConfirmAlert.show("Voulez vous vraiment supprimer ce tableau de bord ?").then(onPress)
  }

  return(
    <div className="dash-bord-delete">
      <div className="d-flex justify-content-end">
        <button>
          <img src={deleteIcon} alt="" onClick={onPressDelete} />
        </button>
      </div>
    </div>
  );
}

export default DeleteGraph;
