import React, { useState } from 'react';
import i18next from 'i18next';

//flags
import frFlag from './flags/fr.svg';
import enFlag from './flags/en.svg';
import esFlag from './flags/es.svg';
import './styles.scss';
import { Dropdown } from 'react-bootstrap';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
const languages = [
  { code: 'fr', label: t('header.lang.fr'), flag: frFlag },
  { code: 'en', label: t('header.lang.en'), flag: enFlag },
  { code: 'es', label: t('header.lang.es'), flag: esFlag },
];

const LanguagesSwitcher = ({ label }) => {
  const [lang, setLang] = useState(localStorage.getItem('USER_LANG') || 'fr');

  const onChange = (lang) => {
    i18next.changeLanguage(lang.code);
    localStorage.setItem('USER_LANG', lang.code);
    setLang(lang.code);
  };

  const DropdownItem = ({ index, item }) => (
    <Dropdown.Item key={index} onClick={() => onChange(item)} className="d-flex align-items-center">
      <img src={item.flag} width={23} style={{ marginRight: 10 }} />
      <span>{t('header.lang.'+item.code)}</span>
    </Dropdown.Item>
  );

  const SeletedLang = () => {
    const currentLang = languages.find((item) => item.code === lang);
    return (
      <Dropdown.Toggle variant="default" className="d-flex align-items-center">
        <img src={currentLang.flag} width={23} style={{ marginRight: 10 }} />
        <span>{t('header.lang.'+currentLang.code)}</span>
      </Dropdown.Toggle>
    );
  };

  return (
    <div className="languages-switcher header-megamenu nav">
      <ul className="header-megamenu nav">
        <li>
          <Dropdown>
            <SeletedLang />
            <Dropdown.Menu className="dropdown-list">
              {languages
                .filter((item) => item.code !== lang)
                .map((item, index) => (
                  <DropdownItem key={index} item={item} />
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </div>
  );
};

export default withTranslation()(LanguagesSwitcher);
