import React from 'react';
import accents from 'remove-accents';

//icons
import starIcon from '../../../icons/star-light.svg';
import usersIcon from '../../../icons/users.svg';
import eyeOnIcon from '../../../icons/eye-on-light.svg';
import eyeOffIcon from '../../../icons/eye-off-light.svg';
// scss
import './listing.scss';
import { getCurrentUserID } from '../../../../../../../api/QueryAuthParameters';

const Listing = ({
  data,
  searchInput,
  navMenu,
  onPressItem,
  onHideDashboard,
  onUnHideDashboard,
}) => {
  const _filter = (item) => {
    if (navMenu === 'hidden' && item.hidden) {
      return accents
        .remove(item.name)
        .toLowerCase()
        .includes(accents.remove(searchInput).toLowerCase());
    } else if (navMenu !== 'hidden') {
      if (item.hidden) return false;
      return accents
        .remove(item.name)
        .toLowerCase()
        .includes(accents.remove(searchInput).toLowerCase());
    } else {
      return false;
    }
  };
  
  return (
    <div className="graph-listing">
      {data.filter(x=>x.hidden === ((navMenu === 'hidden')? true : false)).sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => (
        <Item
          key={index}
          name={item.name}
          cryptedBy={item.cryptedBy}
          createdAt={item.createdAt}
          updatedAt={item.updatedAt}
          editable={item.editable}
          isFavorite={item.isFavorite}
          isPrivate={item.isPrivate}
          isRGPD={item.isRGPD}
          hidden={item.hidden}
          onPressItem={() => onPressItem(item)}
          onHideDashboard={() => onHideDashboard(item)}
          onUnHideDashboard={() => onUnHideDashboard(item)}
        />
      ))}

      {navMenu == 'hidden' && data && data.filter((item) => item.hidden).length === 0 && (
        <div className="no-resut-found">Aucun tableau de bord trouvé</div>
      )}
      {navMenu != 'hidden' && data && data.filter((item) => !item.hidden).length === 0 && (
        <div className="no-resut-found">Aucun tableau de bord trouvé</div>
      )}
    </div>
  );
};

const Item = ({
  key,
  name,
  cryptedBy,
  createdAt,
  updatedAt,
  editable,
  isFavorite,
  isPrivate,
  hidden,
  onPressItem,
  onHideDashboard,
  onUnHideDashboard,
}) => (
  <div
    key={key}
    className={`listing-item ${hidden ? 'hidden' : ''}`}
    style={{ opacity: !hidden ? 1 : 0.5 }}
  >
    <div className="d-flex justify-content-between">
      <h3 onClick={!hidden ? onPressItem : null} className="flex-1">
        {name}
      </h3>
      <div className="icons d-flex">
        {isFavorite && <img src={starIcon} width={36} alt="" />}
        {!isPrivate && cryptedBy && cryptedBy.id == getCurrentUserID() && <img src={usersIcon} width={30} alt="" />}
        {hidden && (
          <button onClick={onUnHideDashboard} className="eye-off-btn">
            <img src={eyeOffIcon} width={30} alt="" />
          </button>
        )}
        {!hidden && (
          <button
            onClick={onHideDashboard}
            className="eye-off-btn"
            title="Cacher le tableau de bord"
          >
            <img src={eyeOnIcon} width={30} alt="" />
          </button>
        )}
      </div>
    </div>
    <div onClick={!hidden ? onPressItem : null}>
      {!isPrivate && cryptedBy ? (
        <small className="d-flex">
          {cryptedBy && cryptedBy.id !== getCurrentUserID() && <img src={usersIcon} width={30} alt="" />}
          {cryptedBy ? (
            <>
              {editable ? (
                <span>
                  Créer par vous
                </span>
              ) : (
                <span>
                  Partagé par {cryptedBy?.firstName} {cryptedBy?.lastName}{' '}
                  {!editable && '(non modifiable)'}
                </span>
              )}
            </>
          ) : null}
        </small>
      ) : null}
      {updatedAt ? (
        <small className="d-flex time">
          <span>Mis à jour le {updatedAt}</span>
        </small>
      ) : null}
      {createdAt && !updatedAt ? (
        <small className="d-flex time">
          <span>Créer le {createdAt}</span>
        </small>
      ) : null}
    </div>
  </div>
);

export default Listing;
