import ImportIcon from './import.icon'
import ContractIcon from './contract.icon'
import TagIcon from './tag.icon'
import CommentIcon from './comment.icon'
import AlertIcon from './alert.icon'
import NegociationIcon from './negociation.icon'
import DocumentIcon from './document.icon'
import ArchiveIcon from './archive.icon'
import AuthenticationIcon from './authentication.icon'

export {
  ImportIcon,
  ContractIcon,
  TagIcon,
  CommentIcon,
  AlertIcon,
  NegociationIcon,
  DocumentIcon,
  ArchiveIcon,
  AuthenticationIcon
};
