import React from "react";
import {CloseIcon } from "../../../assets/icons";
const ButtonClose = ({ onPress, disabled }) => (
  <button
    style={{display:'flex'}}
    type="button"
    className={`btn btn-default ${disabled ? "" : ""}`}
    onClick={onPress}
  >
    <CloseIcon />
  </button>
);

export default ButtonClose;
