import axios from 'axios';
import { API_URL } from "../config/common";
import { getConnectedUserAccessToken, getCurrentClientID } from "./QueryAuthParameters"

export const getIPList = () => {
    const requestUrl = API_URL + `/erpConfiguration/getIPList/${getCurrentClientID()}?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl);
};

export const saveIP = (ipObj) => {

    const requestUrl = API_URL + `/erpConfiguration/saveIP/${getCurrentClientID()}?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    if(ipObj && ipObj.id){
        formData.append("id", ipObj.id)
    }
    formData.append("name", ipObj.name )
    formData.append("ip", ipObj.address)
    formData.append("port", ipObj.portNumber )

    return axios.post(requestUrl, formData);
}

export const deleteIP = (ipID) => {
    const requestUrl = API_URL + `/erpConfiguration/deleteIP/` + ipID + `?access_token=` + getConnectedUserAccessToken();
    return axios.delete(requestUrl);
};

export const getKeywordByClient = () => {
    const requestUrl = API_URL + `/erpConfiguration/getKeywordByClient/${getCurrentClientID()}?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl);
};

export const getExportList = () => {
    const requestUrl = API_URL + `/erpConfiguration/getExportList/${getCurrentClientID()}?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl);
};

export const saveExport = (exportData, picklist) => {

    const requestUrl = API_URL + `/erpConfiguration/saveExport/${getCurrentClientID()}?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();

    if(exportData && exportData.id){
        formData.append("id", exportData.id)
    }

    formData.append('delimiter', exportData.delimiter);
    formData.append('name', exportData.name);
    if(typeof exportData.ipAddress === "object"){
        formData.append('ipAddress', exportData.ipAddress.id);
    }else{
        formData.append('ipAddress', exportData.ipAddress);
    }

    if(picklist){
        
        // Extracting systemKeywords ids
        const systemKeywordsIds = picklist.systemKeywords.map(item => item.id);

        // Extracting clientKeywords ids
        const clientKeywordsIds = picklist.clientKeywords.map(item => item.id);

        // Check if specific keywords exist in otherKeywords
        const otherKeywords = picklist.otherKeywords.reduce((acc, item) => {
            acc[item.id] = 1;
            return acc;
        }, { "index": 0, "tags": 0, "comments": 0 });


        // Combine into the final result
        const resultKeywords = {
            systemKeywords: systemKeywordsIds,
            clientKeywords: clientKeywordsIds,
            ...otherKeywords
        };

        formData.append('fields', JSON.stringify(resultKeywords));
    }

     // Display FormData entries in the console
     for (let [key, value] of formData.entries()) {
        console.log(key, value);
    }
    
    return axios.post(requestUrl, formData);
}

export const deleteErpConfig = (configID) => {
    const requestUrl = API_URL + `/erpConfiguration/` + configID + `?access_token=` + getConnectedUserAccessToken();
    return axios.delete(requestUrl);
};
