import React, { Component } from 'react';

class CustomDropdownKeywords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      currentSelectedSectionIndex: -1
    };
    this.dropdownRef = React.createRef(); // Create a ref to the dropdown container
  }

  componentDidMount() {
    // Add a click event listener to the document to handle clicks outside the component
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    document.removeEventListener('click', this.handleClickOutside);
  }

  toggleDropdown = () => {
    // this.setState((prevState) => ({
    //   isOpen: !prevState.isOpen,
    // }));
  };

  selectSection = (index) => {
    this.setState({
      ...this.state,
      currentSelectedSectionIndex: index,
    });
  };

  selectOption = (option) => {
    this.setState({
      selectedOption: option
    });
    // Call the onSelect prop with the selected option
    if (this.props.onSelect) {
        this.props.onSelect({
          selectedOption: option
        });
    }
  };

  // Add this method to prevent the page from reloading
  handleButtonClick = (e) => {
    e.preventDefault();
    this.toggleDropdown();
  };

  // Handle clicks outside the component
  handleClickOutside = (e) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
      // Click occurred outside the dropdown, so close it
      // this.setState({
      //   isOpen: false,
      // });
    }
  };

  render() {
    const {isOpen, options, disabled, selectedValue, optionValues } = this.props;
    const { selectedOption, currentSelectedSectionIndex } = this.state;
    return (
      <div className="custom-dropdown-keywords" ref={this.dropdownRef}>
        <button disabled={disabled} onClick={this.props.handleCloseDLKT} className="dropdown-toggle form-control" style={{color:selectedValue ? "#219653": "black"}}>
          {selectedValue ? selectedValue :  "Clauses personnalisées"}
        </button>
        {isOpen && (
          <ul className="dropdown-list text-center" style={{fontFamily:'sans-serif'}}>
            {options.map((option, key) => (
              <li
                key={option.keywordType}
                
                className="dropdown-item" style={{paddingTop: (selectedOption && selectedOption.type == option.type || option.type === selectedValue) ? "4px" :"10px", paddingBottom: (selectedOption && selectedOption.type == option.type || option.type === selectedValue) ? "4px" :"10px", fontFamily:'sans-serif', backgroundColor: (selectedOption && selectedOption.type == option.type || option.type === selectedValue) ? "#f6603138": "transparent" ,color: "black", fontWeight: 400}}
              >
                {key !== currentSelectedSectionIndex &&
                <span onClick={()=>this.selectSection(key)}>
                  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 10.5L6.25 6.00225L1 1.5" stroke="#6E4CF5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                }
                {key === currentSelectedSectionIndex &&
                <span onClick={()=>this.selectSection(-1)}>
                  <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 7L6.00225 1.75L1.5 7" stroke="#6E4CF5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>                  
                </span>
                }
                <span className="font-weight-bold" onClick={()=>this.selectSection(key === currentSelectedSectionIndex ? -1: key)}>{option.keywordType}</span>
                {
                  key === currentSelectedSectionIndex && option.keywords && option.keywords.length>0  &&
                  <ul style={{fontFamily:'sans-serif'}}>
                      {
                        option["keywords"].map((keyword, index) => {
                          const isKeywordChoosed = optionValues.some(x=>x.value === keyword.value)
                          return (
                            <>
                              <li onClick={()=>this.selectOption(keyword)}>
                                <span>
                                  {
                                    isKeywordChoosed &&
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect x="2.25" y="2.25" width="19.5" height="19.5" rx="3" stroke="#202020" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M4.5 12.4688L9.65625 17.625L19.5 7.3125" stroke="#202020" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                  }
                                  {
                                    !isKeywordChoosed &&
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect x="2.25" y="2.25" width="19.5" height="19.5" rx="3" stroke="#202020" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                  }
                                </span>
                                {keyword.label}
                              </li>
                            </>
                          )
                        })
                      }
                  </ul>
                }
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default CustomDropdownKeywords;
