import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"
import Moment from 'moment';

export const getLinkedDocuments = (contract) => {
    const requestUrl = API_URL+`/LinkedDocument/getDocumentsListByContract?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contract);
    return axios.post(requestUrl, formData);
}

export const setNewDocumentData = (document) => {
    const requestUrl = API_URL+`/LinkedDocument/setNewDocumentData?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('document', document.hachedId);
    if(document.title) {
        formData.append('title', document.subject);
    }
    if(document.subject) {
        formData.append('object', document.subject);
    }
    if(document.sender) {
        formData.append('sender', document.sender);
    }
    if(document.content) {
        formData.append('comment', document.content);
    }
    if(document.date) {
        formData.append('date', Moment(document.date).format('YYYY-MM-DD'));
    }

    if(document.type) {
        let docType = document.type === 'out' ? 0 : 1;
        console.log('docType', docType)
        formData.append('type', docType);
    }
    return axios.post(requestUrl, formData);
}

export const getContractInfo = (contract) => {
    const requestUrl = API_URL+`/contract/getContractInfo?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contract);
    return axios.post(requestUrl, formData);
}

export const deleteDocumentWithFile = (document) => {
    const requestUrl = API_URL+`/LinkedDocument/deleteDocumentWithFile?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('document', document);
    return axios.post(requestUrl, formData);
}

export const getCSVFile = (contractID) => {
    const requestUrl = API_URL+`/LinkedDocument/getCSVFile/`+contractID+`?access_token=`+getConnectedUserAccessToken();
    window.open(requestUrl, '_blank');
};

export const addDocumentToTheRecentsList = (documentID) => {
    const requestUrl = API_URL+`/LinkedDocument/addDocumentToTheRecentsList/`+documentID+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
};

export const updateLinkedDocumentContent = (id, fileContent) => {
    var formData = new FormData();
    const requestUrl = API_URL+`/LinkedDocument/updateContent?access_token=`+getConnectedUserAccessToken();
    formData.append('document', id);
    formData.append('content', fileContent);
    return axios({
        method: 'post',
        url: requestUrl,
        data: formData,
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        timeout: Infinity,
    });
}

export const getOnlyContractsByCategory = (categoryID) => {
    const requestUrl = API_URL+`/contract/getOnlyContractsByCategory/${categoryID}?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
}

export const getOtherLinkedDocumentsByCategory = (categoryID) => {
    const requestUrl = API_URL+`/LinkedDocument/getOtherDocuments/${categoryID}?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
}

export const getDocumentContracts = (documentID) => {
    const requestUrl = API_URL+`/LinkedDocument/getDocumentContracts/${documentID}?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
}

export const linkDocumentToContract = (documentID, contractIDs) => {
    const requestUrl = API_URL+`/LinkedDocument/linkDocumentToContracts?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('document', documentID);
    contractIDs.forEach((field, index) => {
        formData.append(`contracts[${index}]`, field);
    });
    return axios.post(requestUrl, formData);
}

export const getDocumentsDataListByContract = (contractHashID) => {
    const requestUrl = API_URL+`/LinkedDocument/getDocumentsDataListByContract?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contractHashID);
    return axios.post(requestUrl, formData);
}

export const converContractToLinkedDocument = (fromContractID, toContractID) => {
    const requestUrl = API_URL+`/contract/converContractToLinkedDocument?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', fromContractID);
    formData.append('toContract', toContractID);
    return axios.post(requestUrl, formData);
}

export const getOnlyContractsByUserByClientByRightType = () => {
    const requestUrl = API_URL+`/contract/getOnlyContractsByUserByClientByRightType?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('client', getCurrentClientID());
    formData.append('rightType', 1);
    return axios.post(requestUrl, formData);
}

export const getDocument = (documentID) => {
    const requestUrl = API_URL+`/LinkedDocument/getDocument/${documentID}?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
}

export const linkUnassociatedDocument = (documentID, contractIDs) => {
    const requestUrl = API_URL+`/LinkedDocument/linkUnassociatedDocument?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('client', getCurrentClientID());
    formData.append('document', documentID);
    contractIDs.forEach((field, index) => {
        formData.append(`contracts[${index}]`, field);
    });
    return axios.post(requestUrl, formData);
}