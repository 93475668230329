import React from "react";

import Switcher from "../../../../../../../components/switcher/Switcher";

import "./graph-name.scss";

const GraphName = ({ name, editable, onChangeName, isPrivate, onChangeType }) => {

  return(
    <div className="graph-name">
      <div className="d-flex justify-content-between align-items-center">
        <span>Nom : </span>
        <div className="input-text flex-1">
          <input 
            type="text" 
            value={name}
            className="form-control"
            onChange={e => onChangeName(e.target.value)}
            required={true}
            readOnly={!editable}
          />
        </div>
        <div className="switcher-container d-flex justify-content-between align-items-center">
          <span>Partagé</span>
          <Switcher
            checked={isPrivate}
            onChange={checked => {
              onChangeType(checked)
            }}
            disabled={!editable}
          />
          <span>Privé</span>
        </div>
      </div>
    </div>
  );
}

export default GraphName;
