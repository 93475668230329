import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';

import SearchForm from '../../components/SearchForm';
import PageLayout from '../components/layout';

// parts
import Menu from './parts/menu/Menu';
import FormAddGraph from './parts/form-add-graph/FormAddGraph';
import Fields from './parts/fields/Fields';
import Listing from './parts/listing/Listing';
import GraphName from './parts/graph-name/GraphName';
import ControlPanel from '../../components/control-panel/ControlPanel';
import DateRange from './parts/date-range/DateRange';
import ChartPreview from '../components/charts';
import ChartControls from './parts/chart-controls/chartControls';
import ChartData from './parts/chart-data/ChartData';
import DeleteGraph from './parts/delete-graph/DeleteGraph';

//API
import {
  getFields,
  getListing,
  create,
  duplicate,
  hideDashboard,
  unHideDashboard,
  calculateAmount,
  getFieldsByFilters,
  loadExport,
} from '../../../../../api/dashboard';
import { getFieldsByRangeDate, remove } from '../../../../../api/dashboard/dashboard';

//
import ErrorAlert from '../../../../../components/confirm-alert/error-alert';
import LoaderComponent from '../../../../../components/loader/Loader';
import Moment from 'moment'
import SearchGraph from './parts/search-graph/SearchGraph';

// XLSX library
import * as XLSX from 'xlsx';
import Modal from '../../../../../components/Modal';

import colors from '../../../back-office/dash-bord/components/charts/colors.json';
import ConfirmAlert from '../../../../../components/confirm-alert/ConfirmAlert';
import { DragDropContext } from 'react-beautiful-dnd';

const DashBordEditMode = ({ editMode, onChangeEditMode }) => {
  //// states
  const [navMenu, setNavMenu] = useState('users');

  const [showFormAdd, setShowFormAdd] = useState(false);
  const [newRecord, setNewRecord] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const [searchInput, setSearchInput] = useState('');
  const [fields, setFields] = useState([]);
  const [listing, setListing] = useState([]);

  const [graphName, setGraphName] = useState('');
  const [isPrivate, setPrivate] = useState(true);
  const [isFavorite, setFavorite] = useState(false);

  const [date, setDate] = useState(null);

  const [calculatedData, setCalculatedData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  
  const [chartType, setChartType] = useState(1); // (1:bar | 2:line | 3:barHorizontal | 4:donut​ | 5pie)
  const [displayValues, setDisplayValues] = useState(true);
  const [displayLegend, setDisplayLegend] = useState(false);
  const [displayDetailAmount, setDisplayDetailAmount] = useState(false);
  const [unity, setUnity] = useState('euro'); //(euro | k-euro | m-euro | percent | qty)
  
  const [filters, setFilters] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [scale, setScale] = useState(null);
  ////
  const [isRotated, setIsRotated] = useState(false);
  const [isCalculateDataAllowed, setCalculateDataAllowed] = useState(true);

  const [showModal, setShowModal] = useState(false);

  const [colorItems, setColorItems] = useState(colors["1"].map(color => ({ color })));

  const [calculedDataPayload, setCalculedDataPayload] = useState(null);

  const [calculateDataInProgress, setCalculateDataInProgress] = useState(false);

  const [refreshSearchedData, setRefreshSearchedData] = useState(true);

  const [sourceDestination, setSourceDestination] = useState({});

  useEffect(() => {
    loadFields();
    loadListing();
  }, []);

  useEffect(() => {
    if(!calculateDataInProgress){
      getCalculatedData().then(_=>{
        loadFieldsByFilters(filters, chartData)
      })
    }
  }, [filters,chartData, scale, colorItems, refreshSearchedData]);
  

  const loadFields = async () => {
    const _fields = await getFields();
    setFields(_fields.map((obj, index) => obj.set('key', index + 1)));
  };

  const loadFieldsByRangeDate = async () => {
    const _fields = await getFieldsByRangeDate({
      fromDate: date?.start,
      toDate: date?.end,
    });
    setFields(_fields.map((obj, index) => obj.set('key', index + 1)));
  };

  const loadListing = async () => {
    const _fields = await getListing();
    setListing(_fields);
  };

  const loadFieldsByFilters = async (filters, chartData) => {
    if (!date) return;
    if (!date.start) return;
    if (!date.end) return;
    // if (filters?.length === 0) {
    //   loadFields()
    //   return
    // };
    if (!date.end) return;
    if (!chartData) return;
    const previousCalculedDataPayload = calculedDataPayload
    const newCalculedDataPayload = {
      startDate: date.start,
      endDate: date.end,
      filters: filters,
      cryptedData: chartData,
      scale: scale,
      recordId: currentRecord && currentRecord.id !== undefined ? currentRecord.id : null
    }
    const isEqual = await areObjectsEqualAsync(previousCalculedDataPayload, newCalculedDataPayload);

    if (isEqual) {
      console.log("%cThe objects are identical Api[getDashboardDataBasedOnFiltersCryptedData].", "color:red;font-size: 20px");
      return;
    }
    console.log("%cThe objects are not identical Api[getDashboardDataBasedOnFiltersCryptedData].", "color:green;font-size: 20px");

    setCalculedDataPayload({
      startDate: date.start,
      endDate: date.end,
      filters: filters,
      cryptedData: chartData,
      scale: scale,
      recordId: currentRecord && currentRecord.id !== undefined ? currentRecord.id : null
    })

    const _fields = await getFieldsByFilters({
      startDate: date.start,
      endDate: date.end,
      cryptedData: chartData,
      scale: scale,
      filters: filters ? filters:[]
    });
    setCalculateDataInProgress(true)
    setFields(_fields.map((obj, index) => obj.set('key', index + 1)));

    // update selected Filters
    let _filters = [];
    if (Array.isArray(filters)) {
      filters.forEach((item) => {
        let field = _fields.find((f) => f.name === item.name);
        let dataField = item.data.filter((itm) => itm.selected).map((itm) => itm.name);
        let newData = field.data.map((itm) => {
          return {
            ...itm,
            selected: dataField && dataField.includes(itm.name) ? true:false,
          };
        });
        let f = item.set('data', newData);
        console.log('dataField', newData, dataField);
        _filters.push(f);
      });
      setCalculateDataInProgress(true)
      setFilters(_filters);
    }

    // update echelle
    if (scale && scale !== undefined) {
      let __scale = null;
      let element = scale;
      let values = element.data.filter(x => x.selected == true).map((v) => v.name);
      let field = _fields.find((f) => f.name === element.name);
      if (field) {
        let newData = [...field.data].map((d) => {
          return values.includes(d.name) ? { ...d, selected: true } : d;
        });
        __scale = field.set('data', newData);
        setCalculateDataInProgress(true)
        setScale(__scale)
      }
    }

    // update selected chartData
    let _chartData = [];
    if (Array.isArray(chartData)) {
      chartData.forEach((item) => {
        let field = _fields.find((f) => f.name === item.name);
        let dataField = item.data.filter((itm) => itm.selected).map((itm) => itm.name);
        let newData = field.data.map((itm) => {
          return {
            ...itm,
            selected: dataField && dataField.includes(itm.name) ? true:false,
          };
        });
        let f = item.set('data', newData);
        console.log('newDatanewData', item.name, newData);
        _chartData.push(f);
      });
      setCalculateDataInProgress(true)
      setChartData(_chartData);
    }
  };

  /// Seach
  const onSort = (sort) => {
    //type: ASC|DESC
    setListing([...listing].sort(() => sort));
  };

  /// listing
  const prepareNewRecord = (record) => {
    setCalculatedData(null);
    setFavorite(false)
    setNewRecord(true);
    setShowFormAdd(true);
    setGraphName(record?.name);
    setPrivate(record == null ? true : record?.isPrivate);
    setDate({
      start: Moment().startOf('year').format('YYYY-MM-DD'),
      end: Moment().format('YYYY-MM-DD')
    });
    setChartType(record ? record.type : 1);
    setDisplayValues(record ? record.displayValues : true);
    setUnity(record?.unity || 'euro');
    setFilters(record?.filters);
    setChartData(record?.chartData);
    setScale(record?.scale);
    setCurrentRecord(record || { editable: true });
  };

  const areObjectsEqual = (obj1, obj2) => {
    // Check if the objects are of the same type
    if (typeof obj1 !== typeof obj2) {
      return false;
    }

    // Check if the objects are arrays
    if (Array.isArray(obj1) !== Array.isArray(obj2)) {
      return false;
    }

    // Check if the objects are primitives or null
    if (obj1 === null || typeof obj1 !== 'object' || obj2 === null || typeof obj2 !== 'object') {
      return obj1 === obj2;
    }

    // Get the keys of the objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Iterate through the keys and recursively compare values
    for (const key of keys1) {
      if (!areObjectsEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    // If all checks passed, the objects are considered equal
    return true;
  }

  const areObjectsEqualAsync = async (obj1, obj2) => {
    // Simulate an asynchronous operation
    return new Promise((resolve) => {
      setTimeout(() => {
        const isEqual = areObjectsEqual(obj1, obj2);
        resolve(isEqual);
      }, 0);
    });
  }

  const getCalculatedData = async () => {

    if(isCalculateDataAllowed === false){
      setCalculateDataAllowed(true)
      return;
    }
    
    if (!date) return;
    if (!date.start) return;
    if (!date.end) return;
    if (chartData?.length === 0) return;
    // if (!scale) return;

    const previousCalculedDataPayload = calculedDataPayload
    const newCalculedDataPayload = {
      startDate: date.start,
      endDate: date.end,
      filters: filters,
      cryptedData: chartData,
      scale: scale,
      recordId: currentRecord && currentRecord.id !== undefined ? currentRecord.id : null
    }

    const isEqual = await areObjectsEqualAsync(previousCalculedDataPayload, newCalculedDataPayload);

    if (isEqual) {
      console.log("%cThe objects are identical Api[calculateData].", "color:red;font-size: 20px");
      return;
    }
    console.log("%cThe objects are not identical Api[calculateData].", "color:green;font-size: 20px");
    
    setCalculatedData(null);
    setCalculedDataPayload({
      startDate: date.start,
      endDate: date.end,
      filters: filters,
      cryptedData: chartData,
      scale: (scale && scale?.data.some(item => item.selected)) ? scale: undefined,
      recordId: currentRecord && currentRecord.id !== undefined ? currentRecord.id : null
    })

    setLoadingData(true);
    setIsRotated(true);
    LoaderComponent.show();
    try {
      const result = await calculateAmount({
        startDate: date.start,
        endDate: date.end,
        filters: filters,
        cryptedData: chartData,
        scale: (scale && scale?.data.some(item => item.selected)) ? scale: undefined,
      });
      console.log('result => ',result)
      setCalculatedData(result);

      setLoadingData(false);
      setIsRotated(false);
      LoaderComponent.hide();
    } catch (error) {
      setLoadingData(false);
      setIsRotated(false);
      LoaderComponent.hide();
    }
  };

  const EditRecord = (record) => {
    setSourceDestination({})
    setCalculedDataPayload(null)

    setCalculatedData(null);
    
    setNewRecord(false);
    setShowFormAdd(true);
    setCurrentRecord(record);
    setGraphName(record.name);
    setPrivate(record.isPrivate);
    setFavorite(record.isFavorite);
    setDate({
      start: record && record.fromDate? record.fromDate :  Moment().startOf('year').format('YYYY-MM-DD'),
      end: record && record.toDate? record.toDate : Moment().format('YYYY-MM-DD')
    });

    setChartType(record.type);
    setDisplayValues(record.displayValues);
    setDisplayLegend(record.displayLegend);
    setUnity(record.unity);

    //// pre selectec filters
    let __filters = [];
    record.filters.forEach((element) => {
      let values = element.data.map((v) => v.name);
      let field = fields.find((f) => f.name === element.name);
      if (field) {
        field = field.set('filter', element.filter)
        let newData = [...field.data].map((d) => {
          return values.includes(d.name) ? { ...d, selected: true } : d;
        });
        __filters.push(field.set('data', newData));
      }
    });
    //// pre selectec cryptedData
    let NamesRecord = record.cryptedData.map(x=>x.name)
    let __cryptedData = [];
    record.cryptedData.forEach(async(element) => {
      let values = element.data.map((v) => v.name);
      let field = fields.find((f) => f.name === element.name);
      if (field) {
        field = field.set('filter', element.filter)
        field = field.set('group', element.group)
        field = field.set('color', element.color ? element.color:"")
        let newData = [...field.data].map((d) => {
          return values.includes(d.name) ? { ...d, selected: true } : d;
        });
        __cryptedData.push(field.set('data', newData));
        if(element.color && element.color !== "" && element.color !== undefined){
          let findIndexColorCode = await colorItems.findIndex(x=>x.color == element.color)
          if(findIndexColorCode > -1){
            let findIndexColorByCardName = await colorItems.findIndex(x=> x.name === element.name)
            if(findIndexColorByCardName > -1){
              let item = colorItems[findIndexColorByCardName]
              
                colorItems[findIndexColorCode]={
                  color: element.color,
                  key: fields.find(x=>x.name === element.name).key || item.key,
                  name: element.name 
                }
                colorItems[findIndexColorByCardName]={
                  color: colorItems[findIndexColorByCardName].color
                }
              }
            }
          

        }
        
      }
    });
    
    // console.log('colorItems------>',colorItems)

    //// pre selectec Scale
    let __scale = null;
    let element = record.scale;
    let values = element.data.map((v) => v.name);
    let field = fields.find((f) => f.name === element.name);
    if (field) {
      let newData = [...field.data].map((d) => {
        return values.includes(d.name) ? { ...d, selected: true } : d;
      });
      __scale = field.set('data', newData);
    }

    //Remove items !== NamesRecord from colorItems
    colorItems.forEach((element,index) => {
      if(!NamesRecord.includes(element.name)){
        colorItems[index] = {color:element.color}
      }
    });
    
    setCalculateDataInProgress(true)
    setColorItems(colorItems)
    setFilters(__filters);
    setScale(__scale);
    setCalculateDataInProgress(false)
    setChartData(__cryptedData);
    // loadFieldsByFilters(__filters, __cryptedData)
  };

  const checkRecord = async (record) => {

    if(!record){
      return true
    }

    console.log("=======> Start check")

    if (record.name !== undefined && graphName !== record.name) {
      return true;
    }
    if (record.isPrivate !== undefined && isPrivate !== record.isPrivate) {
      return true;
    }

    if (record.isFavorite !== undefined && isFavorite !== record.isFavorite) {
      return true;
    }

    if (date && date.start &&  date.start !== undefined && record && record.fromDate && date.start !== record.fromDate) {
      return true;
    }

    if (date && date.end &&  date.end !== undefined && record && record.toDate && date.end !== record.toDate) {
      return true;
    }

    if (chartType !== record.type) {
      return true;
    }

    if (displayValues !== record.displayValues) {
      return true;
    }

    if (displayLegend !== record.displayLegend) {
      return true;
    }


    //// pre selectec filters
    let __filters = [];
    record.filters.forEach((element) => {
      let values = element.data.map((v) => v.name);
      let field = fields.find((f) => f.name === element.name);
      if (field) {
        field = field.set('filter', element.filter)
        let newData = [...field.data].map((d) => {
          return values.includes(d.name) ? { ...d, selected: true } : d;
        });
        __filters.push(field.set('data', newData));
      }
    });
    //// pre selectec cryptedData
    let NamesRecord = record.cryptedData.map(x => x.name)
    let __cryptedData = [];
    record.cryptedData.forEach(async (element) => {
      let values = element.data.map((v) => v.name);
      let field = fields.find((f) => f.name === element.name);
      if (field) {
        field = field.set('filter', element.filter)
        field = field.set('group', element.group)
        field = field.set('color', element.color ? element.color : "")
        let newData = [...field.data].map((d) => {
          return values.includes(d.name) ? { ...d, selected: true } : d;
        });
        __cryptedData.push(field.set('data', newData));
        if (element.color && element.color !== "" && element.color !== undefined) {
          let findIndexColorCode = await colorItems.findIndex(x => x.color == element.color)
          if (findIndexColorCode > -1) {
            let findIndexColorByCardName = await colorItems.findIndex(x => x.name === element.name)
            if (findIndexColorByCardName > -1) {
              let item = colorItems[findIndexColorByCardName]

              colorItems[findIndexColorCode] = {
                color: element.color,
                key: fields.find(x => x.name === element.name).key || item.key,
                name: element.name
              }
              colorItems[findIndexColorByCardName] = {
                color: colorItems[findIndexColorByCardName].color
              }
            }
          }


        }

      }
    });

    // console.log('colorItems------>',colorItems)

    //// pre selectec Scale
    let __scale = null;
    let element = record.scale;
    let values = element.data.map((v) => v.name);
    let field = fields.find((f) => f.name === element.name);
    if (field) {
      let newData = [...field.data].map((d) => {
        return values.includes(d.name) ? { ...d, selected: true } : d;
      });
      __scale = field.set('data', newData);
    }

    //Remove items !== NamesRecord from colorItems
    colorItems.forEach((element, index) => {
      if (!NamesRecord.includes(element.name)) {
        colorItems[index] = { color: element.color }
      }
    });

    if( filters &&  filters.length>0 && __filters && __filters.length>0){
      // Sort the "data" arrays based on the "name" property
      const sortDataFilter = (a, b) => a.name.localeCompare(b.name);
      filters[0].data.sort(sortDataFilter);
      __filters[0].data.sort(sortDataFilter);
    }

    // Convert arrays to JSON strings
    const filtersString = JSON.stringify(filters);
    const __filtersString = JSON.stringify(__filters);

    // Compare the strings
    const isEqualFilter = filtersString === __filtersString;
    
    if (isEqualFilter) {
      console.log("%cThe objects Filters are identical.","color:green;font-size: 15px;background:yellow");
    }else{
      console.log("filters========>",filters)
      console.log("__filters========>",__filters)
      console.log("%cThe objects Filters are not identical.","color:red;font-size: 15px;background:yellow");
      return true;
    } 


    if( chartData &&  chartData.length>0 && __cryptedData && __cryptedData.length>0){
      // Sort the "data" arrays based on the "name" property
      const sortDataChartData = (a, b) => a.name.localeCompare(b.name);
      chartData[0].data.sort(sortDataChartData);
      __cryptedData[0].data.sort(sortDataChartData);
    }

    // Convert arrays to JSON strings
    const chartDataString = JSON.stringify(chartData);
    const __cryptedDataString = JSON.stringify(__cryptedData);

    // Compare the strings
    const isEqualChartData = chartDataString === __cryptedDataString;
    if (isEqualChartData) {
      console.log("%cThe objects ChartData are identical.","color:green;font-size: 15px;background:yellow");
    }else{
      console.log("chartData========>",chartData)
      console.log("__cryptedData========>",__cryptedData)
      console.log("%cThe objects ChartData are not identical.","color:red;font-size: 15px;background:yellow");
      return true;
    } 

    const isEqualScale = await areObjectsEqualAsync(scale, __scale);
    if(scale && __scale && scale.name !== undefined && __scale.name !== undefined && scale.name == __scale.name){
      
      if( scale &&  scale.length>0 && __scale && __scale.length>0){
        // Sort the "data" arrays based on the "name" property
        const sortDataChartDataScale = (a, b) => a.name.localeCompare(b.name);
        scale[0].data.sort(sortDataChartDataScale);
        __scale[0].data.sort(sortDataChartDataScale);
      }
      if (isEqualScale) {
        console.log("%cThe objects Scale are identical.","color:green;font-size: 15px;background:yellow");
      }else{
        console.log("scale=============>",scale)
        console.log("__scale=============>",__scale)
        console.log("%cThe objects Scale are not identical.","color:red;font-size: 15px;background:yellow");
        return true;
      } 
    }
   
    return false;
  };

  const duplicateRecord = async (record) => {
    LoaderComponent.show();
    await duplicate(record.id);
    await loadListing();
    LoaderComponent.hide();
    cancelForm();
  };

  const saveRecord = async () => {
    if (!graphName) return ErrorAlert.show('Le nom du tableau de bord est requis', '');
    if (!date?.start) return ErrorAlert.show('Date de début est requis', '');
    if (!date?.end) return ErrorAlert.show('Date de fin est requis', '');
    if (!chartData || chartData.length === 0 || (chartData && !chartData.some(category => category.data.some(item => item.selected))))
      return ErrorAlert.show('Veuillez sélectionner au moins une donnée à chiffrer', '');
    // if (!scale) return ErrorAlert.show('Veuillez sélectionner un echelle horizontale', '');

    LoaderComponent.show();
    try {
      await create({
        id: currentRecord?.id,
        name: graphName,
        isPrivate: isPrivate,
        isFavorite: isFavorite,
        fromDate: date?.start,
        toDate: date?.end,
        type: chartType,
        displayValues: displayValues,
        displayLegend: displayLegend,
        unity: unity,
        filters: filters,
        cryptedData: chartData,
        scale: scale,
      });

      await loadListing();
      LoaderComponent.hide();
      cancelForm();
    } catch (error) {
      console.log('error', error);
      LoaderComponent.hide();
    }
  };

  const cancelForm = () => {
    setShowFormAdd(false);
    setCurrentRecord(null);
  };

  /// control panel
  const onPressDownload = () => {
    setShowModal(true)
  };
  const doDownload = async (fileExtension = '') => {
    try {
      LoaderComponent.show();
      const result = await loadExport({
        startDate: date.start,
        endDate: date.end,
        filters: filters,
        cryptedData: chartData,
        scale: scale,
      });
      LoaderComponent.hide();
      if (fileExtension === 'csv') {
        const fileName = `${graphName}.csv`;
        if (result) fileDownload(result, fileName);
      }
      if (fileExtension === 'xlsx') {
        const fileNameExcel = `${graphName}.xlsx`;
        if (result) convertCsvToExcelBuffer(result, fileNameExcel)
      }
      setShowModal(false)
    } catch (error) {
      LoaderComponent.hide();
    }
  };

  const fileDownload = (data, fileName) => {
    var universalBOM = '\uFEFF';
    var a = window.document.createElement('a');
    a.setAttribute(
      'href',
      'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + data)
    );
    a.setAttribute('download', fileName);
    window.document.body.appendChild(a);
    a.click();
  };

  const convertCsvToExcelBuffer = (csvString, fileNameExcel) => {
    const arrayOfArrayCsv = csvString.split("\n").map((row) => {
      return row.split(";")
    });
    const wb = XLSX.utils.book_new();
    const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
    XLSX.utils.book_append_sheet(wb, newWs);
    const rawExcel = XLSX.write(wb, { type: 'base64' })
    console.log('rawExcel',rawExcel)
    XLSX.writeFile(wb, fileNameExcel);
    return rawExcel
  }

  const onDownloadCSVFile = () => {
    doDownload('csv')
  }

  const onDownloadXLSXFile = () => {
    doDownload('xlsx')
  }

  const onPressPrint = () => {};
  const onPressPrevResult = () => {};
  const onPressNextResult = () => {};

  //Delete
  const deleteRecord = async (record) => {
    LoaderComponent.show();
    await remove(record.id);
    await loadListing();
    LoaderComponent.hide();
    cancelForm();
  };

  const onHideDashboard = async (record) => {
    LoaderComponent.show();
    await hideDashboard(record.id);
    await loadListing();
    LoaderComponent.hide();
    cancelForm();
  };
  const onUnHideDashboard = async (record) => {
    LoaderComponent.show();
    await unHideDashboard(record.id);
    await loadListing();
    LoaderComponent.hide();
    cancelForm();
  };

  const checkBeforeSetFilters = async (data, source) => {
    if(source === 'filters-field'){
      if(data && data && data['data']){
        let count = data['data'].filter(x=>x.selected === true).length
        setCalculateDataAllowed(count>0)
        setFilters(filters ? [...filters, data] : [data]);
      }else{
        setFilters(filters ? [...filters, data] : [data]);
      }
    }
  };

  const onChangeColorItems = async (selectedColor,showSelectedItemPalette) => {
    let oldIndex = colorItems.findIndex(x => x.key && x.key == showSelectedItemPalette.key && x.name == showSelectedItemPalette.name)
    let newIndex = colorItems.findIndex(x =>  x.color && x.color == selectedColor.color)
    if(oldIndex !== null && oldIndex !== undefined && newIndex !== null && newIndex !== undefined){
      if (newIndex > -1) {
        if(colorItems[newIndex] && colorItems[newIndex].key === undefined && colorItems[newIndex].name === undefined){
          colorItems[newIndex] = {
            ...colorItems[newIndex],
            key: showSelectedItemPalette.key,
            name: showSelectedItemPalette.name
          }
          if(colorItems[oldIndex] && colorItems[oldIndex].key && colorItems[oldIndex].name){
            delete colorItems[oldIndex].key
            delete colorItems[oldIndex].name
          }
          setColorItems(colorItems);
          let cDataIndex = chartData.findIndex(x=>x.key && x.key == showSelectedItemPalette.key && x.name == showSelectedItemPalette.name)
          if (cDataIndex > -1) {
            chartData[cDataIndex] = chartData[cDataIndex].set('color', colorItems[newIndex].color)
            setChartData(chartData)
          }
          getCalculatedData();
        }
      }
    }
  }

  const onDragEnd = (e) => {
    console.log('onDragEnd', e);
    if (!e.destination) return null;
    let source = e.source;
    if(source && source.droppableId == "initData"){
      let item = fields.find(x=>x.key == source.index)
      e.source.draggbleItem = item
      setSourceDestination(e)
    }else{
      setSourceDestination(e)
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <PageLayout
        editMode={editMode}
        onChangeEditMode={onChangeEditMode}
        leftArea={
          <>
            <Menu onchange={setNavMenu} />
            <SearchForm
              editMode={editMode}
              onSort={onSort}
              onSearch={setSearchInput}
              onAdd={() => prepareNewRecord(null)}
            />
            <FormAddGraph
              show={showFormAdd}
              editMode={editMode}
              isNew={newRecord}
              name={graphName}
              isFavorite={isFavorite}
              editable={currentRecord?.editable}
              onSetFavorite={setFavorite}
              onPressCancel={cancelForm}
              onPressSave={saveRecord}
              onPressDuplicate={() => duplicateRecord(currentRecord)}
            />
            {showFormAdd && (
              <Fields
                data={fields}
                searchInput={searchInput}
                filters={filters}
                chartData={chartData}
                axie={scale}
                onPressItem={(item) => {
                  if (currentRecord?.editable) {
                    if (item.isScale && !item.isCryptedData) {
                      setScale(item);
                    } else {
                      checkBeforeSetFilters(item, 'filters-field')
                    }
                  }
                }}
                sourceDestination={sourceDestination}
              />
            )}
  
            <Listing
              data={listing}
              navMenu={navMenu}
              searchInput={searchInput}
              onPressItem={async (record) => {
                const myDiv = document.querySelector('.left-side');
                myDiv.scrollTop = 0;
                setColorItems(colors["1"].map(color => ({ color })))
                let isRecordChanged = await checkRecord(currentRecord)
                if(currentRecord && record && currentRecord.id !== record.id && isRecordChanged){
                  ConfirmAlert.show(
                    "Vos modifications n'ont pas été enregistrées",
                    "Rappel",
                    "Enregistrer",
                    "Abandonner"
                  ).then(async yes => {
                    await saveRecord()
                  },async no=> {
                    await EditRecord(record);
                  });
                }else{
                  await EditRecord(record);
                }
              }}
              onHideDashboard={onHideDashboard}
              onUnHideDashboard={onUnHideDashboard}
            />
          </>
        }
        rightArea={
          currentRecord !== null ? (
            <>
              {showModal &&
                <Modal
                  title={"Export"}
                  isOpen={showModal}
                  onRequestClose={() => setShowModal(false)}
                  footer={
                    <>
                      <button onClick={() => onDownloadCSVFile()}>
                        CSV
                      </button>
                      <button onClick={() => onDownloadXLSXFile()}>
                        XLSX
                      </button>
                    </>
                  }
                  isFullScreen={false}
                >
                  <div>
                    <p className="text-center font-weight-bold">
                      Veuillez choisir le format souhaité
                    </p>
                  </div>
                </Modal>
              }
              <GraphName
                name={graphName}
                editable={currentRecord?.editable}
                onChangeName={setGraphName}
                isPrivate={isPrivate}
                onChangeType={setPrivate}
              />
              {!newRecord && (
                <ControlPanel
                  createdAt={currentRecord?.createdAt}
                  updatedAt={currentRecord?.updatedAt}
                  onPressDownload={onPressDownload}
                  onPressPrint={onPressPrint}
                  onPressPrevResult={onPressPrevResult}
                  currentResult={3}
                  totalResults={10}
                  onPressNextResult={onPressNextResult}
                />
              )}
  
              <DateRange
                start={date ? date.start : null}
                end={date ? date.end : null}
                editable={currentRecord?.editable}
                onchangeDate={(start, end) => setDate({ start, end })}
              />
  
              <SearchGraph onPress={() => {setCalculedDataPayload(null); (!chartData && date && date?.start && date?.end) ? loadFieldsByRangeDate() :getCalculatedData().then(()=>loadFieldsByFilters(filters, chartData))}} isRotated={isRotated}/>
  
              {!newRecord && currentRecord?.editable && (
                <DeleteGraph onPress={() => deleteRecord(currentRecord)} />
              )}
  
              {/* Charts */}
  
              <ChartPreview
                type={chartType}
                data={calculatedData}
                loadingData={loadingData}
                chartData={chartData}
                displayValues={displayValues}
                displayLegend={displayLegend}
                displayDetailAmount={displayDetailAmount}
                unity={unity}
                colorsList={colorItems}
              />
              <ChartControls
                editable={currentRecord?.editable}
                type={chartType}
                onChangeType={setChartType}
                displayValues={displayValues}
                onToggleValues={setDisplayValues}
                displayLegend={displayLegend}
                onToggleLegend={setDisplayLegend}
                displayDetailAmount={displayDetailAmount}
                onToggleAmount={setDisplayDetailAmount}
                unity={unity}
                onChangeUnity={setUnity}
                isScaleDate={scale && scale.name.toLowerCase().includes('date') ? true:false}
              />
              {/* end Charts */}
  
              {/* Filters */}
              <ChartData
                editable={currentRecord?.editable}
                filters={filters}
                chartData={chartData}
                axie={scale}
                chartType={chartType}
                onChangeFilters={async (data) => {
                  await setFilters(data);
                  await setCalculateDataInProgress(false);
                  await setRefreshSearchedData(!refreshSearchedData)
                  // loadFieldsByFilters(data, chartData);
                }}
                onChangeChartData={async (data) => {
                  await setChartData(data);
                  await setCalculateDataInProgress(false);
                  await setRefreshSearchedData(!refreshSearchedData)
                  // loadFieldsByFilters(filters, data);
                }}
                onChangeAxie={async (data)=>{
                  await setScale(data);
                  await setCalculateDataInProgress(false)
                  await setRefreshSearchedData(!refreshSearchedData)
                }}
                onDeleteAxie={async (data)=>{
                  await setScale(data);
                  await setCalculateDataInProgress(false)
                  await setRefreshSearchedData(!refreshSearchedData)
                }}
                onChangeColorItems={onChangeColorItems}
                onRemoveColorItes={setColorItems}
                colorsList={colorItems}
                sourceDestination={sourceDestination}
              />
              {/* end Filters */}
  
              {/* /////////////// Debug ///////////////////// */}
              {/* <pre style={{ marginTop: 200 }}>
                {JSON.stringify(
                  {
                    name: graphName,
                    isPrivate: isPrivate,
                    date: date,
                    type: chartType,
                    displayValues: displayValues,
                    unity: unity,
                    filters: filters,
                    chartData: chartData,
                    axie: axie,
                  },
                  null,
                  2
                )}
              </pre> */}
              {/* //////////////////////////////////// */}
            </>
          ) : null
        }
      />
    </DragDropContext>
  );
};

export default DashBordEditMode;
