import React, { Component } from 'react';
import { getCategories, createCategory, deleteCategory } from '../../../../../api/category';
import './Categories.scss';
import ErrorAlert from '../../../../../components/confirm-alert/error-alert';

// components
import Header from '../../../../../components/layouts/header/Header';
import Sidebar from '../../../../../components/layouts/sidebar/Sidebar';
import BoContractSettings from '../../../../../components/layouts/BO-Contract-Settings/BoContractSettings';
import ConfirmAlert from '../../../../../components/confirm-alert/ConfirmAlert';
import LoaderComponent from '../../../../../components/loader/Loader';
import EditMode from '../../../../../components/edit-mode/EditMode';

// plugins
import * as moment from 'moment/min/moment-with-locales';
moment.locale('fr-fr');
const ObjectsToCsv = require('objects-to-csv');

// form validation
const ReactFormWithConstraints = require('react-form-with-constraints');

/* eslint-disable import/first */
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { withTranslation } from 'react-i18next';
import i18next, { t } from 'i18next';
const { FormWithConstraints, FieldFeedbacks, FieldFeedback } = ReactFormWithConstraints;

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      default: 123,
      showFormAddCategory: false,
      editMode: false,
      categories: [],
      selectedCategory: {},
      selectedCategoryIndex: null,
      isNewCategory: true,
      disbaleKeywords: true,
      keywords: [
        {
          label: 'Révision Prix',
          value: 'Révision Prix',
        },
        {
          label: 'Date Fin',
          value: 'Date Fin',
        },
        {
          label: 'Durée',
          value: 'Durée',
        },
        {
          label: 'Fournisseur',
          value: 'Fournisseur',
        },
        {
          label: 'Pénalités',
          value: 'Pénalités',
        },
      ],
      scrolling: false,
    };

    this.allcategories = this.state.categories;
  }

  changeEditMode = (checked) => {
    this.setState({
      ...this,
      editMode: checked,
    });
  };

  componentDidMount() {
    LoaderComponent.show();
    getCategories().then((response) => {
      LoaderComponent.hide();
      this.setState(
        {
          ...this,
          categories: response.data,
        },
        () => {
          this.allcategories = [...this.state.categories];
          document.getElementById('search-categories-input').value = '';
        }
      );
    });
  }

  exitForm = () => {
    return new Promise((resolve, reject) => {
      if (
        !this.state.showFormAddCategory ||
        !this.state.editMode ||
        Object.keys(this.state.selectedCategory).length == 0
      ) {
        resolve();
      } else {
        if (
          this.state.selectedCategory !== this.state.categories[this.state.selectedCategoryIndex]
        ) {
          ConfirmAlert.show(t('common.modals.confirm_action')).then(resolve, reject);
        } else {
          resolve();
        }
      }
    });
  };

  newCategory = () => {
    this.exitForm().then(() => {
      document.getElementById('form-category').reset();
      this.setState({
        ...this,
        isNewCategory: true,
        showFormAddCategory: true,
        selectedCategory: {
          keywords: [],
        },
      });
    });
  };

  cancelNewCategory = () => {
    this.exitForm().then(() => {
      document.getElementById('form-category').reset();
      this.setState({
        ...this,
        isNewCategory: false,
        showFormAddCategory: false,
        selectedCategory: {},
      });
    });
  };

  handleChangeInput = (e) => {
    if (!this.state.selectedCategory.hasOwnProperty('name')) {
      this.setState({
        ...this,
        isNewCategory: true,
        showFormAddCategory: true,
        selectedCategory: {
          keywords: [],
        },
      });
    }
    this.form.validateFields(e.target);
    this.setState({
      ...this,
      selectedCategory: {
        ...this.state.selectedCategory,
        [e.target.name]: e.target.value,
      },
    });
  };
  addNewCategory = () => {
    this.form.validateFields();

    setTimeout(() => {
      if (this.form.isValid()) {
        if (this.state.isNewCategory) {
          LoaderComponent.show();
          createCategory(this.state.selectedCategory).then(
            (response) => {
              getCategories().then((response) => {
                LoaderComponent.hide();
                this.setState(
                  {
                    ...this,
                    categories: response.data,
                  },
                  () => {
                    this.allcategories = [...this.state.categories];
                    document.getElementById('search-categories-input').value = '';
                  }
                );
              });
              this.setState({
                ...this,
                showFormAddCategory: !this.state.showFormAddCategory,
                selectedCategory: {
                  keywords: [],
                },
              });
              document.getElementById('form-category').reset();
            },
            (error) => {
              LoaderComponent.hide();
              ErrorAlert.show(error.response.data, t('common.modals.api_error'));
              getCategories().then((response) => {
                this.setState(
                  {
                    ...this,
                    categories: response.data,
                  },
                  () => {
                    this.allcategories = [...this.state.categories];
                    document.getElementById('search-categories-input').value = '';
                  }
                );
              });
            }
          );
          //this.state.categories.unshift(this.state.selectedCategory);
        } else {
          this.UpdateCategory();
        }
      }
    });
  };

  viewCategoryInfo = async (Category, index) => {
    if (!Category.keywords) Category.keywords = [];
    await this.exitForm().then(() => {
      this.setState({
        ...this,
        isNewCategory: false,
        showFormAddCategory: true,
        selectedCategory: Category,
        selectedCategoryIndex: index,
      });
      document.getElementById('form-category').reset();
    });
    this.setState({
      ...this,
    });
    this.setState({
      ...this,
    });
  };

  UpdateCategory = () => {
    let categories = this.state.categories;
    LoaderComponent.show();
    createCategory(this.state.selectedCategory).then(
      (response) => {
        getCategories().then((response) => {
          LoaderComponent.hide();
          this.setState(
            {
              ...this,
              categories: response.data,
            },
            () => {
              this.allcategories = [...this.state.categories];
              document.getElementById('search-categories-input').value = '';
            }
          );
        });
      },
      (error) => {
        LoaderComponent.hide();
        ErrorAlert.show(error.response.data, t('common.modals.api_error'));
        getCategories().then((response) => {
          this.setState(
            {
              ...this,
              categories: response.data,
            },
            () => {
              this.allcategories = [...this.state.categories];
              document.getElementById('search-categories-input').value = '';
            }
          );
        });
      }
    );
    categories[this.state.selectedCategoryIndex] = this.state.selectedCategory;
    this.setState({
      ...this,
      isNewCategory: false,
      showFormAddCategory: false,
      selectedCategoryIndex: null,
      categories: categories,
      selectedCategory: {},
    });
  };

  viewNextCategory = () => {
    let index = parseInt(this.state.selectedCategoryIndex);
    let categories = this.state.categories;
    console.log(index + 1);
    if (index >= 0 && index < categories.length - 1) {
      this.setState({
        ...this,
        selectedCategory: categories[index + 1],
        selectedCategoryIndex: [index + 1],
      });
    }
  };

  viewPrevCategory = () => {
    let index = parseInt(this.state.selectedCategoryIndex);
    let categories = this.state.categories;
    console.log(categories);
    if (index >= 1 && index <= categories.length) {
      this.setState({
        ...this,
        selectedCategory: categories[index - 1],
        selectedCategoryIndex: [index - 1],
      });
    }
  };

  sortcategories = (sortby, sort) => {
    let categories = this.state.categories;
    categories.sort(function (a, b) {
      if (a[sortby] < b[sortby]) return sort == 'ASC' ? -1 : 1;
      if (a[sortby] > b[sortby]) return sort == 'ASC' ? 1 : -1;
      return 0;
    });
    this.setState({
      ...this,
      categories: categories,
    });
  };

  searchcategories = (e) => {
    let categories = this.allcategories;
    let searchInput = e.target.value;
    let foundedcategories = [];
    categories.map((Category, index) => {
      if (Category.name.toLowerCase().includes(searchInput.toLowerCase()))
        foundedcategories.push(Category);
    });
    this.setState({
      ...this,
      categories: foundedcategories,
    });
  };

  exportCategory = () => {
    let data = [this.state.selectedCategory];
    const csv = new ObjectsToCsv(data).toString();
    // Save to file:
    csv.then((csvString) => {
      let link = document.createElement('a');
      link.id = 'download-csv';
      link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvString));
      link.setAttribute('download', this.state.selectedCategory.name + '.csv');
      document.body.appendChild(link);
      document.querySelector('#download-csv').click();
      setTimeout(() => {
        document.querySelector('#download-csv').remove();
      });
    });
  };

  deleteCategory = () => {
    ConfirmAlert.show(t('common.modals.confirm_action')).then(() => {
      let categories = this.state.categories;
      LoaderComponent.show();
      deleteCategory(this.state.selectedCategory.id).then(
        (response) => {
          getCategories().then((response) => {
            LoaderComponent.hide();
            this.setState(
              {
                ...this,
                categories: response.data,
              },
              () => {
                this.allcategories = [...this.state.categories];
                document.getElementById('search-categories-input').value = '';
              }
            );
          });
        },
        (error) => {
          LoaderComponent.hide();
          ErrorAlert.show(error.response.data, t('common.modals.api_error'));
        }
      );

      document.getElementById('form-category').reset();
      this.setState({
        ...this,
        categories: categories,
        isNewCategory: false,
        showFormAddCategory: false,
        selectedCategory: {
          keywords: [],
        },
        selectedCategoryIndex: null,
      });
    });
  };

  changeCategories = (categories) => {
    this.setState({
      ...this,
      selectedCategory: {
        ...this.state.selectedCategory,
        keywords: categories,
      },
    });
  };

  unselectCategory = (index) => {
    let categories = this.state.selectedCategory.keywords;
    categories[index].unselected = !categories[index].unselected;
    this.setState({
      ...this,
      selectedCategory: {
        ...this.state.selectedCategory,
        keywords: categories,
      },
    });
  };

  getCategoryContracts = () => {
    let rows = [];
    if (this.state.selectedCategory.contracts) {
      {
        this.state.selectedCategory.contracts.map((contract) => {
          rows.push(
            <tr>
              <td>{contract.subCategory ? contract.subCategory.name : '-'}</td>
              <td> {contract.name ? contract.name : contract.originalFileName} </td>
            </tr>
          );
        });
      }
    }
    return rows;
  };

  onScrolling = (e) => {
    this.setState({
      ...this,
      scrolling: e.target.scrollTop > 100 ? true : false,
    });
  };

  getNumberofCategories = () => {
    let subCategoriesNumber;
    let contractsNumber;
    if (this.state.selectedCategory.id) {
      this.state.selectedCategory.subCategories
        ? (subCategoriesNumber = this.state.selectedCategory.subCategories.length)
        : (subCategoriesNumber = 0);
      this.state.selectedCategory.contracts
        ? (contractsNumber = this.state.selectedCategory.contracts.length)
        : (contractsNumber = 0);

      return t(
        'backoffice.contract_settings.categories.nbr_subCategories_and_contacts_in_current_category',
        {
          nbr_sub_categories: subCategoriesNumber,
          nbr_contacts: contractsNumber,
        }
      );
    }
    return '';
  };

  getCategoryContractsDetailTable = () => {
    if (this.state.selectedCategory.id && this.state.selectedCategory.contracts[0]) {
      return (
        <table
          width="100%"
          id="example"
          className="table table-hover"
          data-searching="false"
          data-select="false"
          data-paging="false"
          data-info="false"
        >
          <thead>
            <tr>
            <th>{t('backoffice.contract_settings.categories.title')}</th>
              <th>{t('backoffice.contract_settings.sub_categories.contacts')}</th>
            </tr>
          </thead>
          <tbody>{this.getCategoryContracts()}</tbody>
        </table>
      );
    }
    return '';
  };

  selectKeywords() {
    if (this.state.disbaleKeywords === false) {
      return (
        <div>
          <div className="row">
            <div className="col-lg-12">
              <label>Sélection des mots clés :</label>
              <div className="multi-select-checkboxes">
                <ReactMultiSelectCheckboxes
                  placeholderButtonLabel="Sélectionner"
                  tabSelectsValue="true"
                  placeholder="Rechercher"
                  value={this.state.selectedCategory.keywords}
                  onChange={(list) => {
                    this.changeCategories(list);
                  }}
                  className="multi-select-options"
                  options={this.state.keywords}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 selectedKeywords">
              <div className="scrolling-zone">
                {this.state.selectedCategory.keywords &&
                  this.state.selectedCategory.keywords.map((category, index) => {
                    return (
                      <button
                        onClick={() => {
                          this.unselectCategory(index);
                        }}
                        type="button"
                        className={`mb-2 mr-2 btn-icon btn-pill btn btn-secondary ${
                          category.unselected ? 'unselected' : ''
                        }`}
                      >
                        {category.label}
                        <svg
                          width="52"
                          height="52"
                          viewBox="0 0 52 52"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.542969"
                            y="26"
                            width="36"
                            height="36"
                            rx="18"
                            transform="rotate(-45 0.542969 26)"
                            fill="#F66031"
                          />
                          <path
                            d="M30.2416 21.7574L21.7563 30.2426M21.7563 21.7574L30.2416 30.2426L21.7563 21.7574Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  editModeReminder = () => {
    ConfirmAlert.show(
      "Voulez-vous activer le mode d'édition pour effectuer des modifications ?",
      "Rappel",
      "Activer",
      "Annuler"
    ).then(() => {
      this.setState({
        ...this.state,
        editMode: true,
      });
    });
  };
  render() {
    const lang = i18next.resolvedLanguage;
    return (
      <div className="app-container contract-settings page-categories app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
        <Header />

        <div className="app-main">
          <div className="app-sidebar sidebar-shadow">
            <Sidebar />
          </div>
          <div className="app-main__outer">
            <EditMode
              isEditMode={this.state.editMode}
              onChange={(checked) => {
                this.changeEditMode(checked);
              }}
            />
            <div className="app-main__inner">
              <div className="row">
                <div className={`col-sm-5 left-side ${this.state.scrolling ? 'scrolling' : ''}`}>
                  <h1 className="side-menu-title-style">
                    <svg
                      width="39"
                      height="33"
                      viewBox="0 0 39 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.5187 28.125C21.0265 30.5499 18.8826 32.375 16.3125 32.375C13.7424 32.375 11.5985 30.5499 11.1063 28.125H1.4375C0.850697 28.125 0.375 27.6493 0.375 27.0625C0.375 26.4757 0.850697 26 1.4375 26H11.1063C11.5985 23.5751 13.7424 21.75 16.3125 21.75C18.8826 21.75 21.0265 23.5751 21.5187 26H37.5625C38.1493 26 38.625 26.4757 38.625 27.0625C38.625 27.6493 38.1493 28.125 37.5625 28.125H21.5187ZM34.2687 17.5C33.7765 19.9249 31.6326 21.75 29.0625 21.75C26.4924 21.75 24.3485 19.9249 23.8563 17.5H1.4375C0.850697 17.5 0.375 17.0243 0.375 16.4375C0.375 15.8507 0.850697 15.375 1.4375 15.375H23.8563C24.3485 12.9501 26.4924 11.125 29.0625 11.125C31.6326 11.125 33.7765 12.9501 34.2687 15.375H37.5625C38.1493 15.375 38.625 15.8507 38.625 16.4375C38.625 17.0243 38.1493 17.5 37.5625 17.5H34.2687ZM15.1437 6.875C14.6515 9.29988 12.5076 11.125 9.9375 11.125C7.36736 11.125 5.22349 9.29988 4.73127 6.875H1.4375C0.850697 6.875 0.375 6.3993 0.375 5.8125C0.375 5.2257 0.850697 4.75 1.4375 4.75H4.73127C5.22349 2.32512 7.36736 0.5 9.9375 0.5C12.5076 0.5 14.6515 2.32512 15.1437 4.75H37.5625C38.1493 4.75 38.625 5.2257 38.625 5.8125C38.625 6.3993 38.1493 6.875 37.5625 6.875H15.1437ZM9.9375 9C11.6979 9 13.125 7.57291 13.125 5.8125C13.125 4.05209 11.6979 2.625 9.9375 2.625C8.17709 2.625 6.75 4.05209 6.75 5.8125C6.75 7.57291 8.17709 9 9.9375 9ZM29.0625 19.625C30.8229 19.625 32.25 18.1979 32.25 16.4375C32.25 14.6771 30.8229 13.25 29.0625 13.25C27.3021 13.25 25.875 14.6771 25.875 16.4375C25.875 18.1979 27.3021 19.625 29.0625 19.625ZM16.3125 30.25C18.0729 30.25 19.5 28.8229 19.5 27.0625C19.5 25.3021 18.0729 23.875 16.3125 23.875C14.5521 23.875 13.125 25.3021 13.125 27.0625C13.125 28.8229 14.5521 30.25 16.3125 30.25Z"
                        fill="black"
                      />
                    </svg>
                    <span>{t('header.menu.contract_settings')} : </span>
                    {t('backoffice.contract_settings.categories.title')}
                  </h1>

                  <div className="sub-menu">
                    <BoContractSettings activeMenu="categories"></BoContractSettings>
                  </div>
                  <hr />
                  <div className="actions side-menu-actions">
                    <div className="sorting">
                      <button
                        onClick={() => {
                          this.sortcategories('name', 'ASC');
                        }}
                        className="btn btn-sorting"
                      >
                        <svg
                          width="24"
                          height="21"
                          viewBox="0 0 24 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.64706 3.08294V20.2112C5.64706 20.554 5.33102 20.8318 4.94118 20.8318C4.55133 20.8318 4.23529 20.554 4.23529 20.2112V3.08294L1.20502 5.74683C0.929352 5.98916 0.482412 5.98916 0.206748 5.74683C-0.0689161 5.5045 -0.0689161 5.11159 0.206748 4.86926L4.44204 1.14605C4.71771 0.903711 5.16465 0.903711 5.44031 1.14605L9.67561 4.86926C9.95127 5.11159 9.95127 5.5045 9.67561 5.74683C9.39994 5.98916 8.953 5.98916 8.67734 5.74683L5.64706 3.08294ZM12 3.44644C11.6102 3.44644 11.2941 3.16861 11.2941 2.8259C11.2941 2.48319 11.6102 2.20537 12 2.20537H23.2941C23.684 2.20537 24 2.48319 24 2.8259C24 3.16861 23.684 3.44644 23.2941 3.44644H12ZM12 8.41072C11.6102 8.41072 11.2941 8.1329 11.2941 7.79019C11.2941 7.44747 11.6102 7.16965 12 7.16965H20.4706C20.8604 7.16965 21.1765 7.44747 21.1765 7.79019C21.1765 8.1329 20.8604 8.41072 20.4706 8.41072H12ZM12 13.375C11.6102 13.375 11.2941 13.0972 11.2941 12.7545C11.2941 12.4118 11.6102 12.1339 12 12.1339H17.6471C18.0369 12.1339 18.3529 12.4118 18.3529 12.7545C18.3529 13.0972 18.0369 13.375 17.6471 13.375H12ZM12 18.3393C11.6102 18.3393 11.2941 18.0615 11.2941 17.7188C11.2941 17.376 11.6102 17.0982 12 17.0982H14.8235C15.2134 17.0982 15.5294 17.376 15.5294 17.7188C15.5294 18.0615 15.2134 18.3393 14.8235 18.3393H12Z"
                            fill="#5367F4"
                          />
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          this.sortcategories('name', 'DESC');
                        }}
                        className="btn btn-sorting"
                      >
                        <svg
                          width="24"
                          height="22"
                          viewBox="0 0 24 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.84923 20.9721L4.82229 20.9994L4.79982 21.0304C4.76761 21.0749 4.71905 21.1 4.66667 21.1C4.61428 21.1 4.56573 21.0749 4.53352 21.0304L4.51105 20.9994L4.48411 20.9721L0.550913 16.9918C0.483029 16.9231 0.483029 16.8093 0.550914 16.7406C0.61553 16.6752 0.717803 16.6752 0.78242 16.7406L3.64435 19.6368L4.5 20.5027V19.2854V0.674649C4.5 0.572566 4.58021 0.5 4.66667 0.5C4.75312 0.5 4.83333 0.572566 4.83333 0.674649V19.2854V20.5027L5.68898 19.6368L8.55091 16.7406C8.61553 16.6752 8.7178 16.6752 8.78242 16.7406C8.85031 16.8093 8.85031 16.9231 8.78242 16.9918L4.84923 20.9721ZM12.6667 2.19859C12.5802 2.19859 12.5 2.12603 12.5 2.02395C12.5 1.92186 12.5802 1.8493 12.6667 1.8493H23.3333C23.4198 1.8493 23.5 1.92186 23.5 2.02395C23.5 2.12603 23.4198 2.19859 23.3333 2.19859H12.6667ZM12.6667 7.59578C12.5802 7.59578 12.5 7.52322 12.5 7.42114C12.5 7.31905 12.5802 7.24649 12.6667 7.24649H20.6667C20.7531 7.24649 20.8333 7.31905 20.8333 7.42114C20.8333 7.52322 20.7531 7.59578 20.6667 7.59578H12.6667ZM12.6667 12.993C12.5802 12.993 12.5 12.9204 12.5 12.8183C12.5 12.7162 12.5802 12.6437 12.6667 12.6437H18C18.0865 12.6437 18.1667 12.7162 18.1667 12.8183C18.1667 12.9204 18.0865 12.993 18 12.993H12.6667ZM12.6667 18.3902C12.5802 18.3902 12.5 18.3176 12.5 18.2155C12.5 18.1134 12.5802 18.0409 12.6667 18.0409H15.3333C15.4198 18.0409 15.5 18.1134 15.5 18.2155C15.5 18.3176 15.4198 18.3902 15.3333 18.3902H12.6667Z"
                            fill="black"
                            stroke="#5367F4"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="search-input">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.7488 12.7482L19.0001 19"
                          stroke="#5367F4"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.88235 14.7647C11.6834 14.7647 14.7647 11.6834 14.7647 7.88235C14.7647 4.08133 11.6834 1 7.88235 1C4.08133 1 1 4.08133 1 7.88235C1 11.6834 4.08133 14.7647 7.88235 14.7647Z"
                          stroke="#5367F4"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <input
                        id="search-categories-input"
                        type="text"
                        placeholder=""
                        onKeyUp={(e) => {
                          this.searchcategories(e);
                        }}
                      />
                    </div>
                    <button
                      onClick={() => {
                        this.newCategory();
                      }}
                      className="btn-icon btn-pill btn btn-danger"
                    >
                      <svg
                        className="inner"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 7H1M7 1V13V1Z"
                          stroke="#FFF"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <svg
                        className="outer"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="36" height="36" rx="18" fill="#5367F4"></rect>
                      </svg>
                      {t('common.add')}
                    </button>
                  </div>
                  <div
                    className={`scrolling-verticaly ${
                      this.state.showFormAddCategory ? 'showFormAddRecord' : ''
                    }`}
                    onScroll={(e) => {
                      this.onScrolling(e);
                    }}
                  >
                    {this.state.showFormAddCategory && (
                      <div className="new-category add-record">
                        <h2>
                          {this.state.selectedCategory.categoryName ||
                            t('backoffice.contract_settings.categories.category_name')}
                        </h2>
                        <div>
                          <button
                            onClick={() => {
                              this.cancelNewCategory();
                            }}
                            className="btn-pill btn btn-cancel"
                          >
                            {t('common.cancel')}
                          </button>
                          <button
                            onClick={() => {
                              this.addNewCategory();
                            }}
                            className="btn-pill btn btn-danger btn-save"
                            disabled={!this.state.editMode}
                          >
                            {t('common.save')}
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="categories records">
                      {this.state.categories.map((category, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              this.viewCategoryInfo(category, index);
                            }}
                            className={`category side-menu-style mb-0 ${
                              index == this.state.selectedCategoryIndex && !this.state.isNewCategory
                                ? 'active'
                                : ''
                            }`}
                          >
                            <h3 className="title-stage-one">{category.name}</h3>
                            <p className="title-stage-two">{moment(category.createdAt).lang(lang).format('LL')}</p>
                          </div>
                        );
                      })}
                      {this.state.categories.length == 0 && (
                        <div className="no-resut-found">{t('common.no_result_found')}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-7 viewer">
                  <h1>
                    {this.state.selectedCategory.categoryName ||
                      t('backoffice.contract_settings.categories.category_name')}
                  </h1>
                  <div className={this.state.isNewCategory ? 'hidden' : ''}>
                    <div className="formHead">
                      <div className="row">
                        <div className="col-sm-4 createdAt"></div>
                        <div className="col-sm-4 exportBtns">
                          <button
                            onClick={() => {
                              this.exportCategory();
                            }}
                            className="btn btm-light"
                          >
                            <i className="lnr-download"></i>
                          </button>
                          <button className="btn btm-light">
                            <i className="lnr-printer"></i>
                          </button>
                        </div>
                        <div className="col-sm-4 navigateBtns">
                          <button
                            onClick={() => {
                              this.viewPrevCategory();
                            }}
                            className="btn btm-light"
                          >
                            <i className="fa fa-angle-up"></i>
                          </button>
                          <span>
                            {parseInt(this.state.selectedCategoryIndex) + 1}/
                            {this.state.categories.length}
                          </span>
                          <button
                            onClick={() => {
                              this.viewNextCategory();
                            }}
                            className="btn btm-light"
                          >
                            <i className="fa fa-angle-down"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FormWithConstraints
                    ref={(formWithConstraints) => (this.form = formWithConstraints)}
                    id="form-category"
                    className={!this.state.editMode ? "consultation" : ""}
                    noValidate
                  >
                    <div
                      className="hideFormInputs"
                      onClick={this.editModeReminder}
                    ></div>
                    {!this.state.isNewCategory && this.state.editMode && (
                      <div className="row">
                        <div className="col-lg-12" style={{ textAlign: 'right' }}>
                          <button
                            onClick={() => {
                              this.deleteCategory();
                            }}
                            type="button"
                            className="btn btn-default deleteCategory"
                          >
                            <svg
                              width="48"
                              height="48"
                              viewBox="0 0 48 48"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.6622 40.7325L21.6819 40.7341H21.7017H22.3009H22.3207L22.3404 40.7325C23.3253 40.6545 24.2891 40.2356 25.0414 39.4779L26.4334 38.0759L26.7831 37.7237L26.4334 37.3714L10.8578 21.6837L10.5029 21.3264L10.1481 21.6837L8.75614 23.0857C7.07954 24.7744 7.07954 27.5108 8.75614 29.1994L18.9612 39.4779C19.7135 40.2356 20.6773 40.6545 21.6622 40.7325ZM26.0578 39.8818L25.2116 40.7341H26.4126H36.9797C37.2521 40.7341 37.4791 40.9575 37.4791 41.2406C37.4791 41.5238 37.2521 41.7471 36.9797 41.7471H22.3716H22.3556L22.3396 41.7482C22.1143 41.7626 21.8883 41.7626 21.663 41.7482L21.647 41.7471H21.631H10.9967C10.7243 41.7471 10.4974 41.5238 10.4974 41.2406C10.4974 40.9575 10.7243 40.7341 10.9967 40.7341H17.59H18.791L17.9448 39.8818L8.05249 29.9183C5.9825 27.8334 5.9825 24.4517 8.05249 22.3668L22.255 8.06217C24.323 5.97928 27.6744 5.97928 29.7424 8.06217L39.9475 18.3407C42.0175 20.4256 42.0175 23.8073 39.9475 25.8922L26.0578 39.8818ZM11.5614 20.2603L11.2116 20.6125L11.5614 20.9648L27.137 36.6525L27.4918 37.0099L27.8466 36.6525L39.2439 25.1733C40.9205 23.4846 40.9205 20.7482 39.2439 19.0596L29.0388 8.78106C27.3602 7.09041 24.6372 7.09041 22.9586 8.78106L11.5614 20.2603Z"
                                fill="black"
                                stroke="#EB3A59"
                              />
                              <rect opacity="0.05" width="48" height="48" rx="24" fill="#292C41" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-lg-12">
                        <label>{t('backoffice.contract_settings.categories.category_name')}*</label>
                        <input
                          value={this.state.selectedCategory.name}
                          onChange={(e) => {
                            this.handleChangeInput(e);
                          }}
                          type="text"
                          name="name"
                          placeholder={
                            t('backoffice.contract_settings.categories.category_name') + '*'
                          }
                          className="form-control"
                          required
                        />
                        <FieldFeedbacks for="name">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <label>{t('common.description')}*</label>
                        <input
                          value={this.state.selectedCategory.designation}
                          onChange={(e) => {
                            this.handleChangeInput(e);
                          }}
                          type="text"
                          name="designation"
                          placeholder={t(
                            'backoffice.contract_settings.categories.description_placeholder'
                          )}
                          className="form-control"
                          required
                        />
                        <FieldFeedbacks for="designation">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-md-12 negotiationPeriod d-flex">
                        <label>
                          {t('backoffice.contract_settings.categories.trading_period_length')}
                        </label>
                        <input
                          type="number"
                          name="negociationTime"
                          value={this.state.selectedCategory.negociationTime}
                          onChange={(e) => {
                            this.handleChangeInput(e);
                          }}
                          className="form-control"
                        />
                        <select
                          value={this.state.selectedCategory.negociationTimeUnit}
                          onChange={(e) => {
                            this.handleChangeInput(e);
                          }}
                          name="negociationTimeUnit"
                          className="form-control"
                        >
                          <option value="">
                            {t('backoffice.contract_settings.categories.select_period')}
                          </option>
                          <option value="1">
                            {t('backoffice.contract_settings.categories.days')}
                          </option>
                          <option value="2">
                            {t('backoffice.contract_settings.categories.weeks')}
                          </option>
                          <option value="3">
                            {t('backoffice.contract_settings.categories.months')}
                          </option>
                          <option value="4">
                            {t('backoffice.contract_settings.categories.years')}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-md-12 negotiationPeriod d-flex">
                        <label>
                          {t('backoffice.contract_settings.categories.archive_type')}
                        </label>
                        <select
                          value={this.state.selectedCategory.archiveType}
                          onChange={(e) => {
                            this.handleChangeInput(e);
                          }}
                          name="archiveType"
                          className="form-control"
                        >
                          <option value="">
                            {t('backoffice.contract_settings.categories.select_type')}
                          </option>
                          <option value="DELETE">
                            {t('backoffice.contract_settings.categories.archive_delete')}
                          </option>
                          <option value="ARCHIVE">
                            {t('backoffice.contract_settings.categories.archive_without_delete')}
                          </option>
                          <option value="MANUAL">
                            {t('backoffice.contract_settings.categories.archive_manual')}
                          </option>
                        </select>
                        <div style={{ width: 20 }}></div>
                        <label className="w-auto mr-2">
                          Durée
                        </label>
                        <input
                          type="number"
                          name="archiveDuration"
                          value={this.state.selectedCategory.archiveDuration}
                          onChange={(e) => {
                            this.handleChangeInput(e);
                          }}
                          className="form-control"
                        />
                        <span className="pt-2 font-font-weight-bold">({t('backoffice.contract_settings.categories.years')})</span>
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-lg-12">
                        <label>{t('backoffice.contract_settings.categories.article_code')}</label>
                        <input
                          value={this.state.selectedCategory.accountingAccount}
                          onChange={(e) => {
                            this.handleChangeInput(e);
                          }}
                          type="text"
                          name="accountingAccount"
                          placeholder=""
                          className="form-control"
                        />
                        <FieldFeedbacks for="accountingAccount">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                    </div>
                    {this.selectKeywords}
                    <div style={{ height: 20 }}></div>
                    <div className="row">
                      <div className="col-lg-12">
                        <label>{this.getNumberofCategories()}</label>
                        {this.getCategoryContractsDetailTable()}
                      </div>
                    </div>
                  </FormWithConstraints>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Categories);
