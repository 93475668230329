import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"

export const getTeams = () => {
    const requestUrl = API_URL+`/team/getTeamsByClient/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

  export const exportTeams = () => {
    const requestUrl = API_URL+`/team/getClientTeamsCSVFile/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl)
  };

export const getPerimeters = () => {
    const requestUrl = API_URL+`/perimeter/getPerimeterByClientToCreateTeam/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

export const getUsers = () => {
    const requestUrl = API_URL+`/user/getUserByClientToCreateTeam/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

export const createTeam = (teamForm) => {
  console.log('teamForm', teamForm)
    var index;
    var addedUser = false;
    var addedPerimeter = false;
    var addedManager = false;
    const requestUrl = API_URL+`/team?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    if(teamForm.id) {
        formData.append('id', teamForm.id);
      }

    if (teamForm.type !== undefined &&
      (teamForm.type == "Import seulement" || teamForm.type == "Consultation Restreinte" || teamForm.type == "RESTREINT" || teamForm.type == "Consultation")
    ) {
      formData.append('integrateLinkedDocuments', teamForm.integrateLinkedDocuments ? 1 : 0);
    }

    formData.append('name', teamForm.name);
    if (teamForm.designation) {
        formData.append('designation', teamForm.designation);
    }
    formData.append('clientID', getCurrentClientID());

    if (teamForm.perimeters) {
      if(teamForm.perimeters && teamForm.perimeters.length) {
          for (index = 0; index < teamForm.perimeters.length; ++index) {
              if(!teamForm.perimeters[index].unselected || teamForm.perimeters[index].unselected == false ) {
                  formData.append('perimeters['+ index +']', teamForm.perimeters[index].value);
                  addedPerimeter = true;  
              } 
          }
      } 
      if (addedPerimeter == false) {
          formData.append('perimeters', []);
        }
      
  }

  if (teamForm.users) {
    if (teamForm.users && teamForm.users.length>0) {
      for (index = 0; index < teamForm.users.length; ++index) {
        if (!teamForm.users[index].unselected || teamForm.users[index].unselected == false) {
          formData.append('users[' + index + ']', teamForm.users[index].value);
          addedUser = true;
        }
      }
    }
    if (addedUser == false) {
      formData.append('users', []);
    }

  }

  if (teamForm.managers) {
    if (teamForm.managers && teamForm.managers.length>0) {
      for (index = 0; index < teamForm.managers.length; ++index) {
        if (!teamForm.managers[index].unselected || teamForm.managers[index].unselected == false) {
          formData.append('managers[' + index + ']', teamForm.managers[index].value);
          addedManager = true;
        }
      }
    }
    if (addedManager == false) {
      formData.append('managers', []);
    }
  }else{
    formData.append('managers', []);
  }


  if(teamForm.type) {
    switch(teamForm.type) {
      case 'MANAGER':
        formData.append('rightType', 4);
        break;
      case 'Consultation Restreinte':
        formData.append('rightType', 3);
        break;
      case 'Consultation':
        formData.append('rightType', 2);
        break;
      case 'Modification':
        formData.append('rightType', 1);
        break;
      case 'Import seulement':
        formData.append('rightType', 0);
    }
  }

    return axios.post(requestUrl, formData);
}

export const deleteTeam = (teamID) => {
  const requestUrl = API_URL+`/team/`+teamID+`?access_token=`+getConnectedUserAccessToken();
  return axios.delete(requestUrl);
};