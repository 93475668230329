import { t } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";

const TimeLineData = ({ data, setNewList, translateInterval }) => (
  <div>
    <div className="resources">
      <div>
      {t('frontoffice.home.timeLine.launch_of_negotiation')}
      </div>
      <div>{t('frontoffice.home.timeLine.notice_period')}</div>
      <div>{t('frontoffice.home.timeLine.end_date')}</div>
    </div>
    <div className="events">
      <div className="table-head">
        {data?.intervals.map((interval, index) => {
          return <div>{translateInterval(interval)}</div>;
        })}
      </div>
      <div className="table-content">
        <div className="negociation">
          {data?.contractsByIntervals.map((item, index) => (
            <div>
              {item?.negociation.length > 0 && (
                <button
                  className="btn btn-pill btn-primary"
                  onClick={() => setNewList(item.negociation, 1, index)}
                >
                  {item.negociation.length}
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="preavis">
          {data?.contractsByIntervals.map((item, index) => (
            <div>
              {item?.priorNotice.length > 0 && (
                <button
                  className="btn btn-pill btn-primary"
                  onClick={() => {
                    setNewList(item.priorNotice, 2, index);
                  }}
                >
                  {item.priorNotice.length}
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="fin">
          {data?.contractsByIntervals.map((item, index) => (
            <div>
              {item?.ending.length > 0 && (
                <button
                  className="btn btn-pill btn-primary"
                  onClick={() => {
                    setNewList(item.ending, 2, index);
                  }}
                >
                  {item.ending.length}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default withTranslation()(TimeLineData);
