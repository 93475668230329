/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Switcher from "../../../../../../../components/switcher/Switcher";

import "./dash-bords-listing.scss";
import Cookies from "js-cookie";

const DashBordsListing = ({ navMenu, items, screen, onToggle, currentDash, activePage, isToogleActive }) => { 
  let currentUserCookie = Cookies.get("currentUserInformation") ? JSON.parse(Cookies.get("currentUserInformation")) : null;
  if(navMenu === 'MANAGER')
    return (
      <div className="dash-bord-consult-listing">
        {items.sort((a, b) => a.name.localeCompare(b.name)).filter(x=>x.cryptedBy && currentUserCookie && x.cryptedBy.id === currentUserCookie.id).map((item, index) => (
          <DashBordItem
            key={index}
            name={item.name}
            checked={(activePage && activePage['detail'] && activePage['detail'].find(x => x.dashboard === item.id)) || (currentDash && currentDash.id == item.id) 
              || (isToogleActive && isToogleActive.dashboard == item.id)  ? true : false}
            disabled={(!screen && !item.screen && !(activePage && activePage['detail'] && activePage['detail'].find(x => x.dashboard === item.id)) ? true : false)
            }
            onChange={(value) => onToggle(value, item, activePage && activePage['detail'] && activePage['detail'].find(x => x.dashboard === item.id) ? true : false)}
          />
        ))}
      </div>
    )
    else
    return (
      <div className="dash-bord-consult-listing">
        {items.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => {
          if(item.cryptedBy && currentUserCookie && item.cryptedBy.id === currentUserCookie.id)
            return(
              <DashBordItem
                key={index}
                name={item.name}
                checked={(activePage && activePage['detail'] && activePage['detail'].find(x => x.dashboard === item.id)) || (currentDash && currentDash.id == item.id)
                  || (isToogleActive && isToogleActive.dashboard == item.id) ? true : false}
                disabled={(!screen && !item.screen && !(activePage && activePage['detail'] && activePage['detail'].find(x => x.dashboard === item.id)) ? true : false)
                }
                onChange={(value) => onToggle(value, item, activePage && activePage['detail'] && activePage['detail'].find(x => x.dashboard === item.id) ? true : false)}
              />
            )
          else if(item.cryptedBy && currentUserCookie && item.cryptedBy.id !== currentUserCookie.id)
            return(
              <DashBordItem
                key={index}
                name={item.name}
                checked={(activePage && activePage['detail'] && activePage['detail'].find(x => x.dashboard === item.id)) || (currentDash && currentDash.id == item.id)
                  || (isToogleActive && isToogleActive.dashboard == item.id) ? true : false}
                disabled={(!screen && !item.screen && !(activePage && activePage['detail'] && activePage['detail'].find(x => x.dashboard === item.id)) ? true : false)
                }
                onChange={(value) => onToggle(value, item, activePage && activePage['detail'] && activePage['detail'].find(x => x.dashboard === item.id) ? true : false)}
                canEdit={false}
              />
            )
        }
        )}
      </div>
    )
     
};

const DashBordItem = ({ key, name, checked, disabled, onChange,canEdit=true }) => (
  <div className="dash-bord-consult-listing-item">
    <div
      key={key}
      className="d-flex justify-content-between align-items-center"
    >
      <h3>
        {!canEdit && <span title="Tableau de bord partagé">
          <svg width="39" height="39" viewBox="0 0 139 139" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M45.573 65.4388L22.0865 77.5285C19.1934 79.0178 17.375 81.9989 17.375 85.2528V89.7708C17.375 94.5688 21.2646 98.4583 26.0629 98.4583C27.6622 98.4583 28.9588 99.7548 28.9588 101.354C28.9588 102.953 27.6622 104.25 26.0629 104.25C18.066 104.25 11.5833 97.7675 11.5833 89.7708V85.2528C11.5833 79.8296 14.6139 74.8611 19.4358 72.3791L38.8827 62.3687C32.6758 56.9568 28.9583 49.0687 28.9583 40.5416C28.9583 24.5483 41.9234 11.5833 57.9167 11.5833C67.627 11.5833 76.5266 16.4016 81.8823 24.2818C82.7813 25.6045 82.4378 27.4056 81.1151 28.3046C79.7924 29.2036 77.9913 28.8601 77.0923 27.5373C72.8036 21.2272 65.6883 17.3749 57.9167 17.3749C45.1221 17.3749 34.75 27.747 34.75 40.5416C34.75 48.7178 39.0173 56.154 45.8716 60.3351C47.2369 61.168 47.6686 62.95 46.8357 64.3154C46.5239 64.8265 46.0792 65.2067 45.573 65.4388ZM69.2757 90.1579L45.6343 100.904C42.5329 102.314 40.5417 105.406 40.5417 108.813V112.937C40.5417 117.735 44.4312 121.625 49.2292 121.625H112.938C117.735 121.625 121.625 117.735 121.625 112.937V108.813C121.625 105.406 119.634 102.314 116.532 100.904L92.891 90.1579C89.285 91.7701 85.2888 92.6666 81.0834 92.6666C76.8779 92.6666 72.8817 91.7701 69.2757 90.1579ZM63.2575 86.5315C56.4809 81.2312 52.125 72.9784 52.125 63.7083C52.125 47.715 65.0901 34.7499 81.0834 34.7499C97.0766 34.7499 110.042 47.715 110.042 63.7083C110.042 72.9784 105.686 81.2312 98.9092 86.5315L118.929 95.6314C124.098 97.9809 127.417 103.135 127.417 108.813V112.937C127.417 120.934 120.934 127.417 112.938 127.417H49.2292C41.2326 127.417 34.75 120.934 34.75 112.937V108.813C34.75 103.135 38.0687 97.9809 43.2377 95.6314L63.2575 86.5315ZM81.0834 86.8749C93.878 86.8749 104.25 76.5029 104.25 63.7083C104.25 50.9137 93.878 40.5416 81.0834 40.5416C68.2888 40.5416 57.9167 50.9137 57.9167 63.7083C57.9167 76.5029 68.2888 86.8749 81.0834 86.8749Z" fill="#F66031" />
          </svg>
        </span>
        }
        {name}
      </h3>

      {
      (disabled ? (
        <OverlayTrigger
          placement={"top"}
          overlay={
            <Tooltip className="dash-bord-tooltip">
              {!checked && "Veuillez sélectionnez une zone tout d'abord"}
            </Tooltip>
          }
        >
          <span>
            <span style={{ pointerEvents: disabled ? "none" : "all" }}>
              <Switcher
                checked={checked}
                offstyle={"outline-light"}
                onChange={onChange}
              />
            </span>
          </span>
        </OverlayTrigger>
      ) : (
        <Switcher
          checked={checked}
          offstyle={"outline-light"}
          onChange={onChange}
        />
      ))
      }
    </div>
  </div>
);

export default DashBordsListing;
