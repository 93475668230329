import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"

export const getPerimeters = () => {
    const requestUrl = API_URL+`/perimeter/getPerimeterByClient/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

  export const exportPerimeters = () => {
    const requestUrl = API_URL+`/perimeter/getClientPerimetersDetailCSVFile/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl)
  };

  export const getCategories = () => {
    const requestUrl = API_URL+`/category/getCategoriesByClient/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

  export const createPerimeter = (perimeterForm) => {
    var index;
    let addedCategory = false;
    const requestUrl = API_URL+`/perimeter?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    if(perimeterForm.id) {
        formData.append('id', perimeterForm.id);
    }
    formData.append('name', perimeterForm.name);
    if (perimeterForm.designation) {
        formData.append('designation', perimeterForm.designation);
    }
    formData.append('clientID', getCurrentClientID());

    if (perimeterForm.categories) {
        if(perimeterForm.categories && perimeterForm.categories.length) {
            for (index = 0; index < perimeterForm.categories.length; ++index) {
                if(!perimeterForm.categories[index].unselected || perimeterForm.categories[index].unselected == false ) {
                    formData.append('categories['+ index +']', perimeterForm.categories[index].value);
                    addedCategory = true;
                } 
            }
        } 
        if (addedCategory == false) {
            formData.append('categories', []);
          }
        
    }

    return axios.post(requestUrl, formData);
}

export const deletePerimeter = (perimeterID) => {
    const requestUrl = API_URL+`/perimeter/`+perimeterID+`?access_token=`+getConnectedUserAccessToken();
    return axios.delete(requestUrl);
};