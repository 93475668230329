import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { CloseCarreIcon, ExportIcon, LegendIcon, SearchIcon, SortIcon, TagIcon, TrashIcon } from '../../../assets/icons';
import moment from 'moment';
import ExportModal from './parts/ExportModal';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import FilterIcon from '../../../../back-office/dash-bord/edit-mode/parts/chart-data/icons/filter.icon';
import NotifyAlert from '../../../../../../components/confirm-alert/notify-alert/NotifyAlert';
import ConfirmAlert from '../../../../../../components/confirm-alert/ConfirmAlert';
import { affectContractWithoutWorkflow, deleteSingleUnvalidatedContract, updateCategory } from '../../../../../../api/validateContract';
import LoaderComponent from '../../../../../../components/loader/Loader';
import CustomDropdown from './parts/CustomDropdown';
import AdvancedSearch from '../../../../back-office/dash-bord/edit-mode/parts/chart-data/icons/advanced-search.icon';
import ColumnsModal from './parts/ColumnsModal';
import ColumnsVisibilityIcon from '../../../../back-office/dash-bord/edit-mode/parts/chart-data/icons/columns-visibility.icon';
import Cookies from "js-cookie";
import SearchModal from './parts/SearchModal';
import CustomDropdownCategories from './parts/CustomDropdownCategories';
import errorAlert from '../../../../../../components/confirm-alert/error-alert';
const ListingInNegociationContract = ({
  data: initialData,
  keywordsDB,
  tags,
  handleDoubleClick,
  getContractBadges,
  seachIncontractList,
  currentNegosClientForUser,
  removeDuplicateRowsByUlDataIndex,
  affectationToContract,
  columnsList,
  setColumnsList,
  homeTabsSearch
}) => {
  const [data, setData] = useState(initialData);
  const [isSearchModalOpen, setOpenSearchModal] = useState(false);
  const [systemKeywords, setSystemKeyWords] = useState([]);
  const [isAffectationModalOpen, setOpenAffectationModal] = useState(false);
  const [isExportModalOpen, setOpenExportModal] = useState(false);
  const [isDisputedFilter, setIsDisputedFilter] = useState(false);
  const [removeDupplicatedCalled, setRemoveDupplicatedCalled] = useState(0);
  const [isSearchTag, setSearchTag] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [affectedList, setAffectedList] = useState([]);

  const [userWorkflowInfoList, setUserWorkflowInfoList] = useState(null);

  const [defaultContracts, setDefaultContracts] = useState(data);

  const [selectedContractTags, setSelectedContractTags] = useState(null);
  const [isColumnsModalOpen, setOpenColumnsModal] = useState(false);
  const [selectedContractNegociators, setSelectedContractNegociators] = useState(null);
  const [selectedContractSignatories, setSelectedContractSignatories] = useState(null);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [displayLegend, setDisplayLegend] = useState(false);
  
  const getColumns = (columnsList) =>{
    if(columnsList && columnsList !== null && columnsList["items"] !== undefined && columnsList["items"].length>0){
      const item = columnsList["items"].find(section => section.key === "in_negociation_contracts");
      return item["columns"]
    }else{
      return ['number', 'third_party_name', 'contract_full_name', 'category', 'type', 'status', 'speaker', 'cumul_duration', 'tag', 'negociatories', 'signatories']
    }
  }
  const [orderContractColumns, setOrderContractColumns] = useState(getColumns(columnsList));

  const loadColumns = () =>{
    let columns = [
      { id: 'number', label: t('frontoffice.home.contracts.table.number'), isVisible: true,  },
      { id: 'third_party_name', label: t('frontoffice.home.contracts.table.third_party_name'), isVisible: true,  },
      { id: 'contract_full_name', label: t('frontoffice.home.contracts.table.contract_full_name'), isVisible: true,  },
      { id: 'category', label: t('frontoffice.home.contracts.table.category'), isVisible: true,  },
      { id: 'type', label: t('frontoffice.home.contracts.table.type'), isVisible: true,  },
      { id: 'status', label: t('frontoffice.home.contracts.table.status'), isVisible: true,  },
      { id: 'speaker', label: t('frontoffice.home.contracts.table.speaker'), isVisible: true,  },
      { id: 'cumul_duration', label: t('frontoffice.home.contracts.table.cumul_duration'), isVisible: true,  },
      { id: 'tag', label: t('frontoffice.home.contracts.table.tag'), isVisible: true,  },
      { id: 'negociatories', label: t('frontoffice.home.contracts.table.negociatories'), isVisible: true,  },
      { id: 'signatories', label: t('frontoffice.home.contracts.table.signatories'), isVisible: true,  },
      // Add more columns as needed
    ]
    const sortedDataArray = columns.sort((a, b) => {
      const isVisibleA = orderContractColumns.includes(a.id);
      const isVisibleB = orderContractColumns.includes(b.id);

      // Update isVisible property
      a.isVisible = isVisibleA;
      b.isVisible = isVisibleB;

      // Keep the sorting logic
      return orderContractColumns.indexOf(a.id) - orderContractColumns.indexOf(b.id);
    });
    return sortedDataArray
  }
  const [columns, setColumns] = useState(loadColumns());

  const [draggedIndex, setDraggedIndex] = useState(null);

  useEffect(() => {
    const sortedDataArray = columns.sort((a, b) => {
      const isVisibleA = orderContractColumns.includes(a.id);
      const isVisibleB = orderContractColumns.includes(b.id);

      // Update isVisible property
      a.isVisible = isVisibleA;
      b.isVisible = isVisibleB;

      // Keep the sorting logic
      return orderContractColumns.indexOf(a.id) - orderContractColumns.indexOf(b.id);
    });
    setColumns(sortedDataArray)
  }, [orderContractColumns])

  // Uncomment for api save
  useEffect(() => {
    if (columns && columns.length > 0 && !isFirstLoad) {
      const editedColumns = editItemsArray(columns.filter(x => x.isVisible).map(item => item.id))
      setColumnsList(editedColumns)
    }else{
      setIsFirstLoad(false)
    }
  }, [columns])

  const editItemsArray = (dataC) => {
    // Find the object with the key "in_negociation_contracts"
    const validatedContractsItem = columnsList.items.find(item => item.key === "in_negociation_contracts");
    // Check if the item is found
    if (validatedContractsItem) {
      // Update the columns for "in_negociation_contracts"
      validatedContractsItem.columns = dataC;
      // Log the updated columnsList array
      return columnsList
    }
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggedIndex === null || index === draggedIndex) {
      return;
    }

    const updatedColumns = [...columns];
    const [removed] = updatedColumns.splice(draggedIndex, 1);
    updatedColumns.splice(index, 0, removed);
    setColumns(updatedColumns);
    setDraggedIndex(index);
    setOrderContractColumns(updatedColumns.filter(x => x.isVisible).map(item => item.id))
  };

  const onchangeSystemKeyword = (e) => {
    let id = e.target.value
    let listing = systemKeywords? systemKeywords:[];
    if(e.target.checked){
      listing.push(id)
    }else{
      listing = listing.filter(f => f !== id)
    }
    setSystemKeyWords(listing);
  };

  const deleteContracts = (nbConrats) => {

    if (nbConrats == 0) {
        NotifyAlert.show(t('frontoffice.home.contracts.table.delete.delete_msg_empty_contractsIds'), t('frontoffice.home.contracts.table.delete.delete_title'));
    }
    else{
        ConfirmAlert.show(t('frontoffice.home.contracts.table.delete.delete_msg_confirm_contractsIds_part_one')+ nbConrats.length + t('frontoffice.home.contracts.table.delete.delete_msg_confirm_contractsIds_part_two')).then(()=>{
            deleteSingleUnvalidatedContract(systemKeywords).then((response) => {
                NotifyAlert.show(t('frontoffice.home.contracts.table.delete.delete_msg_confirm_contractsIds_success_part_one')+' (' +nbConrats.length+') ' +t('frontoffice.home.contracts.table.delete.delete_msg_confirm_contractsIds_success_part_two'), t('frontoffice.home.contracts.table.delete.delete_operation')).then(() => {window.location.reload();});
            });
        }, (e) => {

        }
        );
    }
}

  const sortByDeadline = () => {
    $('#deadline').click();
  };

  useEffect(() => {
    removeDuplicateRowsByUlDataIndex('homeDataTable3')
  }, [data, handleDoubleClick, getContractBadges, seachIncontractList, removeDupplicatedCalled])

  const changeAffectedList = (affected) =>{
    setAffectedList(affected)
  }

  const rmDupplicated = () => {
    let cmpt = removeDupplicatedCalled+1 
    setRemoveDupplicatedCalled(cmpt)
  };

  const moreTags = (index, tags) =>{
    if(tags && tags.length>1){
        setSelectedContractTags({
          index: index,
          othertags: [...new Set(tags)].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).slice(1)
        })
      }
  }

  const moreNegociators = (index, negociators) =>{
    if(negociators && negociators.length>1){
        setSelectedContractNegociators({
          index: index,
          otherNegociators: [...new Set(negociators)].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).slice(1)
        })
      }
  }

  const moreSignatories = (index, signatories) =>{
    if(signatories && signatories.length>1){
        setSelectedContractSignatories({
          index: index,
          otherSignatories: [...new Set(signatories)].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).slice(1)
        })
      }
  }
  const handleDropdownChangeCategories = (selectedItem) => {
    const contractID = selectedItem["contract"].id;
    const categoryID = selectedItem["selectedOption"].id
    const indexContract = data.findIndex(el=> el.id == contractID);
   
    if(indexContract > -1){
      updateCategory(contractID, categoryID).then(
        res=>{
          data[indexContract].categoryId = res["data"].id
          data[indexContract].category = res["data"].name
          NotifyAlert.show('Catégorie mise à jours avec succès', 'Info');
        },error=>{
          errorAlert.show(error && error.response !== undefined && error.response.data !== undefined ? error.response.data : "Une erreur est survenue lors de la mise à jours de catégorie", 'Erreur API');
        }
      )
    }
  }
  const handleDropdownChange = (selectedItem) => {
    console.log("selected option",selectedItem)
    const desiredId = selectedItem?.contract?.id;
    let indexOfContract = data.findIndex(obj => obj.id === desiredId)
    if (indexOfContract !== -1) {
      data[indexOfContract].handledBy = selectedItem.selectedOption
    } else {
      console.log(`Object with id ${desiredId} not found in the array.`);
      return;
    }
    LoaderComponent.show();
    if(selectedItem.contract && selectedItem.contract.status <2){
      if (selectedItem["selectedOption"] == null) {
        affectContractWithoutWorkflow({
          contractID: selectedItem.contract.id,
          contributor: null,
          replace: true
        }).then((response) => {
          LoaderComponent.hide();
          NotifyAlert.show("Contrat liberé avec succès", "Information").then(() => {});
        });
      } else {
        affectContractWithoutWorkflow({
          contractID: selectedItem.contract.id,
          contributor: selectedItem.selectedOption.id,
          replace: true
        }).then((response) => {
          LoaderComponent.hide();
          NotifyAlert.show("Affectation effectuée avec succès", "Information").then(() => {});
        });
      }
    }
  };


  // Function to convert French date format (dd/mm/yyyy) to JavaScript Date object
  const convertFrenchDateToJSDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  }

  // Get the current date
  
  const isDateWorkflowEndDateWithinFourDaysOrLess = (data) =>{
    const currentDate = new Date();

    // Filter the data to find rows with "isValidated" set to false and "endDate" 3 days or less from the current date
    const matchingRows = data.filter((row) => {
      if (row.isValidated !== true && row.endDate) {
        const endDate = convertFrenchDateToJSDate(row.endDate);
        const timeDifference = endDate - currentDate;
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
        return daysDifference < 4 && daysDifference >= 0;
      }
      return false;
    });
  
    // Check if there are matching rows
    if (matchingRows.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const isDateWorkflowEndDateExpired = (data) =>{
    const currentDate = new Date();

    // Filter the data to find rows with "isValidated" set to false and "endDate" 3 days or less from the current date
    const matchingRows = data.filter((row) => {
      if (row.isValidated !== true && row.endDate) {
        const endDate = convertFrenchDateToJSDate(row.endDate);
        const timeDifference = endDate - currentDate;
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
        return daysDifference < 0;
      }
      return false;
    });
  
    // Check if there are matching rows
    if (matchingRows.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const isWorflowValidatedAllTasksDone = (data) =>{
    let tasksCount = data.length || -1;
    
    const validatedRows = data.filter((row) => row.isValidated && row.endDate);
  
    // Check if there are matching rows
    if (validatedRows.length ==  tasksCount) {
      return true;
    } else {
      return false;
    }
  }

  const calcDateWorkFlow = (data, contractId) =>{
    const currentDate = new Date();

    // Filter the data to find rows with "isValidated" set to false and "endDate" 3 days or less from the current date
    const matchingRows = data.filter(item=> item && item.pending).map((row) => {
      if (row.isValidated !== true && row.endDate) {
        const endDate = convertFrenchDateToJSDate(row.endDate);
        const timeDifference = endDate - currentDate;
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
        return {
          daysDifference:daysDifference,
          firstName: (row.handledBy !== undefined && row.handledBy["firstName"] !== undefined) ? row.handledBy["firstName"] : (row.handledBy["code"] !== undefined? row.handledBy["code"] : ""),
          lastName: (row.handledBy !== undefined && row.handledBy["lastName"] !== undefined) ? row.handledBy["lastName"] : "",
          pending: row.pending
        }
      }else{
        return null
      }
    }).filter(item => item !== null);

    // Check if there are matching rows
    if (matchingRows.length > 0) {
      
      if(matchingRows.some(item => item.daysDifference<0 )){
        // Check if there are matching rows daysDifference <0 Retard
        let userWorkflowInfoList = {contractId:contractId, users:matchingRows.filter(item => item.daysDifference<0)};
        return(
          <p className='font-weight-bold text-center info-tag'  onMouseEnter={()=>{setUserWorkflowInfoList({contractId:contractId, users:matchingRows.filter(item => item.daysDifference<0)})}} onMouseLeave={()=>{setUserWorkflowInfoList(null)}}>
            {/* Retard */}
             { (userWorkflowInfoList == null || (userWorkflowInfoList["contractId"] !== undefined && userWorkflowInfoList["contractId"] !== contractId)) && <small>{matchingRows.filter(item => item.daysDifference<0 ).length} {t('frontoffice.home.contracts.table.speaker')}(s)</small>}
             { userWorkflowInfoList !== null && userWorkflowInfoList["contractId"] !== undefined && userWorkflowInfoList["contractId"] === contractId && <small>{userWorkflowInfoList["users"].map((item, index) => {return (item.firstName + " " + item.lastName + (index%2 == 0 && userWorkflowInfoList["users"].length %2 == 0 ? " ,":" ")) }) }</small>}
          </p>
        )
      }
      else if(matchingRows.some(item => item.daysDifference == 0 )){
        // Check if there are matching rows daysDifference =0 Reste n days
        let userWorkflowInfoList = {contractId:contractId, users:matchingRows.filter(item => item.daysDifference == 0)};
        return(
          <p className='font-weight-bold text-center info-tag' onMouseEnter={()=>{setUserWorkflowInfoList({contractId:contractId, users:matchingRows.filter(item => item.daysDifference == 0)})}} onMouseLeave={()=>{setUserWorkflowInfoList(null)}}>
              {/* Reste 0 jours */}
              { (userWorkflowInfoList == null || (userWorkflowInfoList["contractId"] !== undefined && userWorkflowInfoList["contractId"] !== contractId)) && <small>{matchingRows.filter(item => item.daysDifference == 0 ).length} {t('frontoffice.home.contracts.table.speaker')}(s)</small>}
              { userWorkflowInfoList !== null && userWorkflowInfoList["contractId"] !== undefined && userWorkflowInfoList["contractId"] === contractId && <small>{userWorkflowInfoList["users"].map((item, index) => {return (item.firstName + " " + item.lastName + (index%2 == 0 && userWorkflowInfoList["users"].length %2 == 0 ? " ,":" ")) }) }</small>}
          </p>
          )
      }
      else if(matchingRows.some(item => item.daysDifference>=0 &&  item.daysDifference<=3)){
        // Check if there are matching rows daysDifference >0 and <= 3 Reste moins de 3 jrs
        let userWorkflowInfoList = {contractId:contractId, users:matchingRows.filter(item => item.daysDifference>0 &&  item.daysDifference<3)};
        return(
          <p className='font-weight-bold text-center info-tag' onMouseEnter={()=>{setUserWorkflowInfoList({contractId:contractId, users:matchingRows.filter(item => item.daysDifference>0 &&  item.daysDifference<3)})}} onMouseLeave={()=>{setUserWorkflowInfoList(null)}}>
              {/* Reste moins de 3 jours  */}
              { (userWorkflowInfoList == null || (userWorkflowInfoList["contractId"] !== undefined && userWorkflowInfoList["contractId"] !== contractId)) && <small>{matchingRows.filter(item => item.daysDifference>0 &&  item.daysDifference<3 ).length} {t('frontoffice.home.contracts.table.speaker')}(s)</small>}
              { userWorkflowInfoList !== null && userWorkflowInfoList["contractId"] !== undefined && userWorkflowInfoList["contractId"] === contractId && <small>{userWorkflowInfoList["users"].map((item, index) => {return (item.firstName + " " + item.lastName + (index%2 == 0 && userWorkflowInfoList["users"].length %2 == 0 ? " ,":" ")) }) }</small>}
          </p>
        )
      }
      else if(matchingRows.some(item => item.daysDifference>3 )){
        // Check if there are matching rows daysDifference >3 Reste +3 days
        let userWorkflowInfoList = {contractId:contractId, users:matchingRows.filter(item => item.daysDifference>=3)};
        return(
          <p className='font-weight-bold text-center info-tag' onMouseEnter={()=>{setUserWorkflowInfoList({contractId:contractId, users:matchingRows.filter(item => item.daysDifference>=3)})}} onMouseLeave={()=>{setUserWorkflowInfoList(null)}}>
              {/* Reste plus de 2 jours  */}
              { (userWorkflowInfoList == null || (userWorkflowInfoList["contractId"] !== undefined && userWorkflowInfoList["contractId"] !== contractId)) && <small>{matchingRows.filter(item => item.daysDifference>=3 ).length} {t('frontoffice.home.contracts.table.speaker')}(s)</small>}
              { userWorkflowInfoList !== null && userWorkflowInfoList["contractId"] !== undefined && userWorkflowInfoList["contractId"] === contractId && <small>{userWorkflowInfoList["users"].map((item, index) => {return (item.firstName + " " + item.lastName + (index%2 == 0 && userWorkflowInfoList["users"].length %2 == 0 ? " ,":" ")) }) }</small>}
          </p>
        )
      }
     
    } else {
      return (
        <p className='font-weight-bold text-center info-tag'>
          Workflow
        </p>
      )
    }
  }

  const calculateCalendarTime = (firstAssignDate) =>{
   
  
    const currentDate = new Date();
    const fDate = convertFrenchDateToJSDate(firstAssignDate);
    const timeDifference = currentDate - fDate;
    const secondsDifference = timeDifference / 1000;
    const minutesDifference = secondsDifference / 60;
    const hoursDifference = minutesDifference / 60;
    const daysDifference = hoursDifference / 24;
    const monthsDifference = daysDifference / 30.44; // Average days in a month
    const yearsDifference = monthsDifference / 12;
  
    if (yearsDifference >= 1) {
      return yearsDifference.toFixed(0) + " " + t('common.time_unit.year');
    } else if (monthsDifference >= 1) {
      return monthsDifference.toFixed(0) + " " + t('common.time_unit.month');
    } else if (daysDifference >= 1) {
      return daysDifference.toFixed(0) + " " + t('common.time_unit.day');
    } else if (hoursDifference >= 1) {
      return hoursDifference.toFixed(0) + " " + t('common.time_unit.hour');
    } else if (minutesDifference >= 1) {
      return minutesDifference.toFixed(0) + " " + t('common.time_unit.minute');
    } else {
      return secondsDifference.toFixed(0) + " " + t('common.time_unit.second');
    }
  }

  const calculateCumulatedTime = (data) => {
    // Use map to extract activity values and filter out null values
    const activityValues = data.map(entry => entry.activity).filter(activity => activity !== null);

    // Use reduce to calculate the sum of activity values
    const sumActivity = activityValues.reduce((total, activity) => total + activity, 0)*1000;
    const secondsDifference = sumActivity / 1000;
    const minutesDifference = secondsDifference / 60;
    const hoursDifference = minutesDifference / 60;
    const daysDifference = hoursDifference / 24;
    const monthsDifference = daysDifference / 30.44; // Average days in a month
    const yearsDifference = monthsDifference / 12;
  
    if (yearsDifference >= 1) {
      return yearsDifference.toFixed(0) + " " + t('common.time_unit.year');
    } else if (monthsDifference >= 1) {
      return monthsDifference.toFixed(0) + " " + t('common.time_unit.month');
    } else if (daysDifference >= 1) {
      return daysDifference.toFixed(0) + " " + t('common.time_unit.day');
    } else if (hoursDifference >= 1) {
      return hoursDifference.toFixed(0) + " " + t('common.time_unit.hour');
    } else if (minutesDifference >= 1) {
      return minutesDifference.toFixed(0) + " " + t('common.time_unit.minute');
    } else {
      return secondsDifference.toFixed(0) + " " + t('common.time_unit.second');
    }

  }
    


  const doSearch = (query) =>{
    console.log(query)
    const filteredContracts = searchContracts(query, defaultContracts);
    console.log(filteredContracts)
    setData(filteredContracts);
  }

  const doClearSearch = () =>{
    setData(defaultContracts);
  }

  
  function searchContracts(searchConfig, contracts) {
    const selectedCategoryIds = searchConfig.fields.filter(field => field.selected).map(field => field.id);
    let selectedKeywords = searchConfig.fields.flatMap(field => {
      return field.keywords.filter(keyword => keyword.selected).map(keyword => ({
        categoryId: field.id,
        keywordId: keyword.id,
        synonyms: keyword.synonyms.filter(synonym => synonym.selected).map(synonym => synonym.name)
      }));
    });

    const selectedSynonyms = searchConfig.fields.flatMap(field => {
      return field.keywords.flatMap(keyword => {
        return keyword.synonyms.filter(synonym => synonym.selected).map(synonym => ({
          categoryId: field.id,
          keywordId: keyword.id,
          synonymName: synonym.name
        }));
      });
    });

    if(selectedSynonyms && selectedSynonyms.length>0){
      // Iterate over selectedSynonyms
      selectedSynonyms.forEach(function (synonym) {
        // Check if there exists a keyword with the same categoryId and keywordId
        var existingKeyword = selectedKeywords.find(function (keyword) {
          return (keyword.categoryId === synonym.categoryId && keyword.keywordId === synonym.keywordId);
        });

        // If no keyword exists, add a new keyword object with an empty synonyms array
        if (!existingKeyword) {
          existingKeyword = {
            "categoryId": synonym.categoryId,
            "keywordId": synonym.keywordId,
            "synonyms": []
          };
          selectedKeywords.push(existingKeyword);
        }

        // Push the synonym into the synonyms array of the existing or newly created keyword
        existingKeyword.synonyms.push(synonym.synonymName);
      });
      console.log(selectedKeywords)
    }

    if (searchConfig.andContains) {
      return contracts.filter(contract => {
        return selectedCategoryIds.every(categoryId => contract.categoryId === categoryId) &&
          selectedKeywords.every(keyword =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.id === keyword.keywordId ||
              keyword.synonyms.some(synonym =>
                contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym)
              )
            )
          ) 
          // &&
          // selectedSynonyms.every(synonym =>
          //   contract.keywords.some(contractKeyword =>
          //     contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym.synonymName)
          //   )
          // );
      });
    } else if (searchConfig.orContains) {
      return contracts.filter(contract => {
        return selectedCategoryIds.some(categoryId => contract.categoryId === categoryId) ||
          selectedKeywords.some(keyword =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.id === keyword.keywordId ||
              keyword.synonyms.some(synonym =>
                contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym)
              )
            )
          ) ||
          selectedSynonyms.some(synonym =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym.synonymName)
            )
          );
      });
    } else if (searchConfig.doesntContain) {
      return contracts.filter(contract => {
        return !selectedCategoryIds.includes(contract.categoryId) &&
          !selectedKeywords.some(keyword =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.id === keyword.keywordId ||
              keyword.synonyms.some(synonym =>
                contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym)
              )
            )
          ) &&
          !selectedSynonyms.some(synonym =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym.synonymName)
            )
          );
      });
    } else {
      // Default behavior: `andContains`
      return contracts.filter(contract => {
        return selectedCategoryIds.every(categoryId => contract.categoryId === categoryId) &&
          selectedKeywords.every(keyword =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.id === keyword.keywordId ||
              keyword.synonyms.some(synonym =>
                contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym)
              )
            )
          ) &&
          selectedSynonyms.every(synonym =>
            contract.keywords.some(contractKeyword =>
              contractKeyword.synonyms.some(contractSynonym => contractSynonym.name === synonym.synonymName)
            )
          );
      });
    }
  }
  

  return (
    <>
      <div className="filters zone-in-nego-legend">
          <div className="row search-zone-home">
            <div className="col-12 col-sm-12 col-lg-3 search-area-mobile">
            {!isSearchTag && <div className="search-input">
                <SearchIcon />
                <input
                  type="text"
                  placeholder={t('frontoffice.home.contracts.table.search')}
                  onChange={(e) => seachIncontractList(e, 'homeDataTable3')}
                />
              </div>}
              {isSearchTag && <div className="tag-search">
                <select
                  className="form-control"
                  name="tags"
                  onChange={(e) => seachIncontractList(e, 'homeDataTable3', true)}
                >
                  <option value="">
                    Choisissez un tag
                  </option>
                  {tags && tags.map((item) => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select>
              </div>}
            </div>
          
            <div className="col-sm-12 col-lg-3 hidden-only-on-mobile vertical-direction-in-nego" style={{ textAlign: 'end' }}>
              <button className="btn btn-link btn-vertical-content" onClick={() => {
                setOpenSearchModal(!isSearchModalOpen)}}>
                <i>
                  <AdvancedSearch />
                </i>
                <span>
                  {t('frontoffice.home.contracts.table.advanced')}
                </span>
              </button>
              <button className="btn btn-link btn-vertical-content" onClick={() => setDisplayLegend(!displayLegend)}>
                <i>
                  <LegendIcon color={displayLegend ? "#F66031":"#5367F4"} width={30} height={30} />
                </i>
                <span>
                  {t('frontoffice.home.contracts.table.legend')}
                </span>
              </button>
              <button className="btn btn-link btn-vertical-content" title="Modifier les colonnes affichées" onClick={() => {
                setOpenColumnsModal(!isColumnsModalOpen)
              }}>
                <i>
                  <ColumnsVisibilityIcon />
                </i>
                <span>
                  {t('frontoffice.home.contracts.table.columns')}
                </span>
              </button>
              <button className="btn btn-link btn-vertical-content" title={t('frontoffice.home.contracts.table.delete.delete_button')} onClick={() => deleteContracts(systemKeywords)}>
                <i>
                  <TrashIcon color="#5367F4" width={30} height={30} />
                </i>
                <span>
                  {t('frontoffice.home.contracts.table.deletion')}
                </span>
                {/* {t('frontoffice.home.contracts.table.delete.delete_button')} */}
              </button>
            </div>
          
          {displayLegend &&
            <div className="d-flex zone-legend-nego">
              <div className="col-sm-12 col-lg-3 pt-3 hidden-only-on-mobile">
                <p className='nego-key nego-colored'>
                  <div></div> <span>{t('frontoffice.home.contracts.table.nego_u_part')}</span>
                </p>
              </div>
              <div className="col-sm-6 col-lg-2 col-legend">
                <div className="key-workflow">
                  <div className="w-left">
                    <p className="pt-3 mr-2">
                    {t('frontoffice.home.contracts.table.state_workflow')}
                    </p>
                  </div>
                  <div className="w-right">
                    <p className='nego-key hashed-key-danger'>
                      <div></div> <span>{t('frontoffice.home.contracts.table.delay')}</span>
                    </p>
                    <p className='nego-key hashed-key-success'>
                      <div></div> <span>{t('frontoffice.home.contracts.table.more_three_days')}</span>
                    </p>
                    <p className='nego-key hashed-key-warning'>
                      <div></div> <span>{t('frontoffice.home.contracts.table.less_three_days')}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          }

            {/* <div className="col-sm-12 col-lg-4" style={{textAlign:'end'}}>
              <button className="btn btn-link" onClick={() => setIsDisputedFilter(!isDisputedFilter)}>
                <i>
                  <FilterIcon color={isDisputedFilter ? "#EB3A59" : "#5367F4"} />
                </i>
                En litige
              </button>
            </div> */}
          </div>
      </div>
      <div className='table-container'>
        <table
          id="homeDataTable3"
          width="100%"
          className="table table-hover"
          data-searching="true"
          data-select="false"
          data-paging="false"
          data-info="false"
        >
          <thead>
            <tr>
              <th data-sorting="false"><input type="checkbox" disabled /></th>
              {columns.map((column, index) => (
                column && column.isVisible && <th
                  key={column.id}
                  draggable
                  onDragStart={() => handleDragStart(index)}
                  onDrop={()=> handleDragOver(index)}
                  onDragOver={(e) => e.preventDefault()}
                >
                  {/* {column.label} */}
                  {t('frontoffice.home.contracts.table.'+column.id)}
                </th>
              ))}
              {/* <th>{t('frontoffice.home.contracts.table.number')}</th>
              <th>{t('frontoffice.home.contracts.table.third_party_name')}</th>
              <th>{t('frontoffice.home.contracts.table.contract_full_name')}</th>
              <th>{t('frontoffice.home.contracts.table.category')}</th>
              <th>{t('frontoffice.home.contracts.table.type')}</th>
              <th>{t('frontoffice.home.contracts.table.status')}</th>
              <th>{t('frontoffice.home.contracts.table.speaker')}</th>
              <th align="center">{t('frontoffice.home.contracts.table.cumul_duration')}</th>
              <th align="center">{t('frontoffice.home.contracts.table.tag')}</th>
              <th align="center">Négociateurs</th>
              <th align="center">Signataires</th> */}
            </tr>
          </thead>
          <tbody>
          {
              isDisputedFilter && data && (data.filter(x=>x.isDisputed)).length <1 &&
                <tr>
                  <td colSpan={10}>
                    <p className="no-contrat-in-litige">{t('frontoffice.home.contracts.table.no_litige_found')}</p>
                  </td>
                </tr>
            
            }
            {data?.map((contrat, index) => {
              let contractBadges = getContractBadges(contrat);

              if(contrat && contrat.negociators !== undefined && contrat.negociators.length>0){
                if (Array.isArray(contrat["negociators"]) && contrat["negociators"].every(item => typeof item === 'string')) {
                  // sortedNamesOrCodes is already a string array
                  // console.log('sortedNamesOrCodes is a string array:', contrat["negociators"]);
                } else {
                  contrat["negociators"] = contrat["negociators"].filter(x=> typeof x === "object" && x.code === undefined).map((negociator) => {
                    // Check if "firstName" and "lastName" exist, otherwise use "code"
                    const nameOrCode = negociator.firstName && negociator.lastName && negociator.firstName !== undefined && negociator.lastName !== undefined
                      ? `${negociator.firstName} ${negociator.lastName}`
                      : (negociator.code || negociator);
                    
                    return nameOrCode.trim(); // Trim to remove extra spaces
                  })
                  .sort();
                  // console.log(contrat["negociators"])
                }
              }

              if(contrat && contrat["signatories"]["signatories"] !== undefined && contrat["signatories"]["signatories"].length>0){
                if (Array.isArray(contrat["signatories"]["signatories"]) && contrat["signatories"]["signatories"].every(item => typeof item === 'string')) {
                  // sortedNamesOrCodes is already a string array
                  // console.log('sortedNamesOrCodes is a string array:', contrat["signatories"]["signatories"]);
                } else {
                  contrat["signatories"]["signatories"] = contrat["signatories"]["signatories"].filter(x=> typeof x === "object" && x.code === undefined).map((signatory) => {
                    // Check if "firstName" and "lastName" exist, otherwise use "code"
                    const nameOrCode = signatory.firstName && signatory.lastName && signatory.firstName !== undefined && signatory.lastName !== undefined
                      ? `${signatory.firstName} ${signatory.lastName}`
                      : (signatory.code || signatory);
                    
                    return nameOrCode.trim(); // Trim to remove extra spaces
                  })
                  .sort();
                  // console.log(contrat["signatories"]["signatories"])
                }
              }


              return (
                ((isDisputedFilter && contrat.isDisputed) || !isDisputedFilter) &&
                <tr contractId={contrat.id} onDoubleClick={() => handleDoubleClick(contrat.hachedId, contrat.avt)} onClick={()=>{removeDuplicateRowsByUlDataIndex('homeDataTable3');rmDupplicated()}} className={currentNegosClientForUser.includes(contrat.hachedId) === true?'nego-bg':''}>
                  <td className={contrat.isDisputed ? "disputed-row":""}>
                    <input type="checkbox" value={contrat.id} onChange={onchangeSystemKeyword} disabled={contrat && contrat.status !== undefined && contrat.status !== 2 && contrat.status !== 3 ? false: true} />
                  </td>
                  {columns.map((column) =>
                    <>
                        {column && column.isVisible && column.id == "number" && <td  className={contrat.isDisputed ? "disputed-row":""}>{contrat.num}</td>}
                        {column && column.isVisible && column.id == "third_party_name" && <td>{contrat.tier}</td>}
                        {column && column.isVisible && column.id == "contract_full_name" && <td>{contrat.name}</td>}
                        {column && column.isVisible && column.id == "category" && <td className="affectation-categories-select ddlist">
                        
                        <CustomDropdownCategories
                          contract={contrat}
                          disabled={contrat.avt === 1 || contrat.accessType !== "UPDATE"}
                          options={contrat.allowedCategories || (contrat.avt === 1 ? { id: contrat["categoryId"], name: contrat["category"] } : []) || []}
                          onSelect={handleDropdownChangeCategories} />

                        </td>}
                        {column && column.isVisible && column.id == "type" && <td>
                          {contrat.avt === 1 ? <strong className='av-style'>{t('frontoffice.home.contracts.table.endorsement')}</strong> : <strong className='no-style'></strong>}
                          {contrat.avt === 0 ? <strong className='co-style'>{t('frontoffice.home.contracts.table.contract')}</strong> : <strong className='no-style'></strong>}
                        </td>}
                        {column && column.isVisible && column.id == "status" && <td>
                          {contrat.status === 0 || contrat.status === 1 ? <strong className='nego-style'>{t('frontoffice.home.contracts.table.nego_in_progress')} ({contrat.pendingNegociationsCount})</strong> : <strong className='no-style'></strong>}
                          {contrat.status === 3 ? <strong className='sign-style'>{t('frontoffice.home.contracts.table.sign_in_progress')}</strong> : <strong className='no-style'></strong>}
                        </td>}
                          {column && column.isVisible && column.id == "speaker" && <td class={"td affectation-select ddlist"+(contrat && contrat["workflow"] && contrat.isManager ? (isDateWorkflowEndDateWithinFourDaysOrLess(contrat["workflow"]) ? " hashed-bg-warning":(isDateWorkflowEndDateExpired(contrat["workflow"]) ? " hashed-bg-danger":" hashed-bg-success")):"")}>
                            { contrat && !contrat["workflow"] && contrat.isManager &&
                              <CustomDropdown contract={contrat} canBeFree={contrat && contrat.handledBy !== undefined && contrat.handledBy !== null ? true:false} isWorkflow={false} position={-1} 
                                handledBy={contrat && contrat.handledBy ? contrat.handledBy: null} 
                                handledByArchivedUser={contrat && contrat.handledBy && contrat.handledBy !== undefined && contrat.handledBy["id"] == undefined ? contrat.handledBy : null}
                                options={(contrat.buyers && contrat.buyers.length > 0 && contrat.buyers.sort((a, b) => a.lastName.localeCompare(b.lastName))) || []}
                                disabled={contrat.status > 1 ? true:false} onSelect={handleDropdownChange} />
                            }
                            { contrat && !contrat["workflow"] && !contrat.isManager &&
                              <p className='text-primary font-weight-bold text-center'>
                                {contrat && contrat.handledBy !== undefined && contrat.handledBy !== null ? (contrat.handledBy["code"] !== undefined ? contrat.handledBy["code"] : (contrat.handledBy["firstName"] + " " + contrat.handledBy["lastName"])):"---" }
                              </p>
                            }
                            { contrat && contrat["workflow"] && contrat.isManager &&
                              calcDateWorkFlow(contrat["workflow"], contrat["id"])
                            }
                            { contrat && contrat["workflow"] && !contrat.isManager &&
                              <p className='font-weight-bold text-center'>Workflow</p>
                            }
                        </td>}
                        {column && column.isVisible && column.id == "cumul_duration" && <td align="center ">
                          <p className='cum_time'>
                            {contrat && contrat.workflow !== undefined ? calculateCumulatedTime(contrat.workflow) : "---"} / {contrat && contrat.firstAssignDate ? calculateCalendarTime(contrat.firstAssignDate) : "---"}
                          </p>
                        </td>}
                        {column && column.isVisible && column.id == "tag" && <td align="center" className='tags small' onMouseEnter={()=>moreTags(index, [...new Set(contrat.tags)])} onMouseLeave={()=> setSelectedContractTags(null)} data-search={contrat.tags && contrat?.tags.length > 0 ? ("tags #"+([...new Set(contrat.tags)].join(" #"))).toString() :""}> 
                          { contrat.tags && contrat?.tags.length > 0 &&
                            <span className={'color'}>
                              {'#'+[...new Set(contrat.tags)].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))[0]}
                              {contrat.tags && [...new Set(contrat.tags)].length > 1 &&
                                <small>
                                  {selectedContractTags !== null && selectedContractTags["index"] === index  ? "-" : ("+"+([...new Set(contrat.tags)].length-1))}
                                </small>
                              }
                            </span>
                          }
                          {selectedContractTags !== null && selectedContractTags["index"] === index && selectedContractTags.othertags && selectedContractTags.othertags.map(othertag=>{
                            return <span className={'color3'}>{'#' + othertag}</span>
                          })}
                        </td>}

                        {column && column.isVisible && column.id == "negociatories" && <td align="center" className='tags nego-tags small' onMouseEnter={()=>moreNegociators(index, [...new Set(contrat.negociators)])} onMouseLeave={()=> setSelectedContractNegociators(null)} data-search={contrat.negociators && contrat?.negociators.length > 0 ? (""+([...new Set(contrat.negociators)].join(" -"))).toString() :""}> 
                          { contrat.negociators && contrat?.negociators.length > 0 &&
                            <span className={'color'}>
                              {''+[...new Set(contrat.negociators)].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))[0]}
                              {contrat.negociators && [...new Set(contrat.negociators)].length > 1 &&
                                <small>
                                  {selectedContractNegociators !== null && selectedContractNegociators["index"] === index  ? "-" : ("+"+([...new Set(contrat.negociators)].length-1))}
                                </small>
                              }
                            </span>
                          }
                          {selectedContractNegociators !== null && selectedContractNegociators["index"] === index && selectedContractNegociators.otherNegociators && selectedContractNegociators.otherNegociators.map(othernego=>{
                            return <span className={'color3'}>{'' + othernego}</span>
                          })}
                        </td>}

                        {column && column.isVisible && column.id == "signatories" && <td align="center" className='tags sign-tags small' onMouseEnter={()=>moreSignatories(index, [...new Set(contrat["signatories"]["signatories"])])} onMouseLeave={()=> setSelectedContractSignatories(null)}  data-search={contrat["signatories"]["signatories"] && contrat?.signatories.length > 0 ? (""+([...new Set(contrat["signatories"]["signatories"])].join(" -"))).toString() :""}> 
                          { contrat["signatories"]["signatories"] && contrat["signatories"]["signatories"].length > 0 &&
                            <span className={'color'}>
                              {''+[...new Set(contrat["signatories"]["signatories"])].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))[0]}
                              {contrat["signatories"]["signatories"] && [...new Set(contrat["signatories"]["signatories"])].length > 1 &&
                                <small>
                                  {selectedContractSignatories !== null && selectedContractSignatories["index"] === index  ? "-" : ("+"+([...new Set(contrat["signatories"]["signatories"])].length-1))}
                                </small>
                              }
                            </span>
                          }
                          {selectedContractSignatories !== null && selectedContractSignatories["index"] === index && selectedContractSignatories.otherSignatories && selectedContractSignatories.otherSignatories.map(othersignatory=>{
                            return <span className={'color3'}>{'' + othersignatory}</span>
                          })}
                        </td>}   
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>

        <ExportModal isOpen={isExportModalOpen} onRequestClose={() => setOpenExportModal(false)} />
        <SearchModal isOpen={isSearchModalOpen} keywordsDB={keywordsDB} onRequestClose={() => setOpenSearchModal(false)} doSearch={doSearch} doClearSearch={doClearSearch} tabKey={"in_negociation_contracts"} homeTabsSearch={homeTabsSearch}/>
        <ColumnsModal isOpen={isColumnsModalOpen} onRequestClose={() => setOpenColumnsModal(false)} columns={columns} setColumns={setColumns} setOrderContractColumns={setOrderContractColumns}/>
      </div>
    </>
  );
};

export default withTranslation()(ListingInNegociationContract);
