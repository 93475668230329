import React from "react";

const AlertIcon = ({color}) => (
  <svg
    width="22"
    height="26"
    viewBox="0 0 22 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.17227 18.868V11.3079C4.17347 9.49779 4.89338 7.76221 6.17376 6.48267C7.45414 5.20314 9.19021 4.48437 11.0003 4.48438C12.8105 4.48438 14.5465 5.20314 15.8269 6.48267C17.1073 7.76221 17.8272 9.49779 17.8284 11.3079V18.868C17.8289 19.3643 18.0263 19.8401 18.3772 20.191C18.7281 20.5419 19.2039 20.7393 19.7002 20.7399C19.8753 20.7399 20.0431 20.8094 20.1669 20.9332C20.2907 21.057 20.3603 21.2249 20.3603 21.4C20.3603 21.575 20.2907 21.7429 20.1669 21.8667C20.0431 21.9905 19.8753 22.06 19.7002 22.06H2.3002C2.12514 22.06 1.95725 21.9905 1.83346 21.8667C1.70968 21.7429 1.64014 21.575 1.64014 21.4C1.64014 21.2249 1.70968 21.057 1.83346 20.9332C1.95725 20.8094 2.12514 20.7399 2.3002 20.7399C2.79652 20.7394 3.27238 20.542 3.62335 20.1911C3.97431 19.8401 4.17173 19.3643 4.17227 18.868Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.54883 22.1304C8.59457 22.7495 8.87253 23.3285 9.32703 23.7513C9.78153 24.1741 10.379 24.4096 10.9998 24.4106C11.6205 24.4096 12.218 24.1741 12.6725 23.7513C13.127 23.3285 13.405 22.7495 13.4507 22.1304"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5601 4.66887V3.25574C12.5601 2.84199 12.3957 2.44518 12.1031 2.15261C11.8106 1.86004 11.4138 1.69568 11 1.69568C10.5862 1.69568 10.1894 1.86004 9.89687 2.15261C9.6043 2.44518 9.43994 2.84199 9.43994 3.25574V4.67107"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AlertIcon;
