import axios from 'axios';
import { API_URL } from "../config/common";
import { getConnectedUserAccessToken, getCurrentClientID } from "./QueryAuthParameters"
axios.defaults.timeout = 100000;



export const getNegociationsByUserType = (userId, userType) => {
    const requestUrl = API_URL + `/user/getNegociations?id=` + userId + `&type=` + userType;
    return axios.get(requestUrl);
}
;
export const addContractToNegociations = (contractId) => {
    const requestUrl = API_URL + `/contract/addToNegociations/` + contractId + `?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl);
};

export const newProposition = (payload) => {
    const requestUrl = API_URL + `/negociation?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', payload.contract); //Id du contrat.
    formData.append('type', payload.type); // Type de négociation (REQUEST, UPDATE, ou ADD)
    formData.append('createdByType', payload.createdByType); // Le type de l'utilisateur qui l'a crée (USER ou GUEST)
    formData.append('sectionNumber', payload.sectionNumber); // Numéro de l'article
    formData.append('sectionTitle', payload.sectionTitle); // Le titre de l'article
    if(payload.fieldType === "object")
        formData.append('content', JSON.stringify(payload.content)); // Le contenu de la proposition
    else
        formData.append('content', payload.content); // Le contenu de la proposition
    formData.append('annexContent', payload.annexContent); // Le contenu annex de la proposition
    if(payload.comment)
        formData.append('comment', payload.comment); // Le comment de la proposition
    if(payload.originalText){
        if(payload.fieldType === "object")
            formData.append('originalText', JSON.stringify(payload.originalText)); // Le text selecionné
        else
            formData.append('originalText', payload.originalText); // Le text selecionné
    }
    if(payload.keyword)
        formData.append('keyword', payload.keyword); //  Le id du mot clé contrat

    console.log("newProposition")
    console.log('%cFormData values:','font-weight: bold; color: red;font-size: 28px');

    for (const [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
    }
    return axios.post(requestUrl, formData);
};

export const editProposition = (negociationId,payload) => {
    const requestUrl = API_URL + `/negociation/${negociationId}?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', payload.contract); //Id du contrat.
    // formData.append('type', payload.type); // Type de négociation (REQUEST, UPDATE, ou ADD)
    formData.append('createdByType', payload.createdByType); // Le type de l'utilisateur qui l'a crée (USER ou GUEST)
    formData.append('sectionNumber', payload.sectionNumber); // Numéro de l'article
    formData.append('sectionTitle', payload.sectionTitle); // Le titre de l'article
    if(payload.fieldType === "object")
        formData.append('content', JSON.stringify(payload.content)); // Le contenu de la proposition
    else
        formData.append('content', payload.content); // Le contenu de la proposition
    formData.append('annexContent', payload.annexContent); // Le contenu annex de la proposition
    if(payload.comment)
        formData.append('comment', payload.comment); // Le comment de la proposition
    if(payload.originalText)
        if(payload.fieldType === "object")
            formData.append('originalText', JSON.stringify(payload.originalText)); // Le text selecionné
        else
            formData.append('originalText', payload.originalText); // Le text selecionné
    if(payload.keyword)
        formData.append('keyword', payload.keyword); //  Le id du mot clé contrat
    return axios.post(requestUrl, formData);
};

export const replyProposition = (payload) => {
    const requestUrl = API_URL + `/proposition?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('negociation', payload.negociationId); //Id de la négociation.
    if(payload.fieldType === "object")
        formData.append('content', JSON.stringify(payload.content)); // Le contenu de la proposition
    else
        formData.append('content', payload.content); // Le contenu de la contre proposition
    formData.append('annexContent', payload.annexContent); // Le contenu annex de la contre proposition
    formData.append('createdByType', payload.createdByType); // Le type de créateur (USER ou GUEST)
    if(payload.comment)
        formData.append('comment', payload.comment); // Le comment de la proposition

    console.log("replyProposition")
    console.log('%cFormData values:', 'font-weight: bold; color: red;font-size: 28px');

    for (const [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
    }
    return axios.post(requestUrl, formData);
};

export const detailListProposition = (contractId, createdByType) => {
    const requestUrl = API_URL + `/negociationsByContract/${contractId}?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('createdByType', createdByType); // Le type de créateur (USER ou GUEST)
    return axios.post(requestUrl, formData);
};

export const newGuest = (payload) => {
    const requestUrl = API_URL + `/negociator?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', payload.contract); //Id du contrat.
    formData.append('email', payload.email); // email de l'invité 
    formData.append('firstName', payload.firstName); // Le prénom de l'invité 
    formData.append('lastName', payload.lastName); // Le nom de l'invité 
    formData.append('phoneNumber', payload.phoneNumber); // Le numéro de téléphone de l'invité 
    formData.append('createdByType', payload.createdByType); // Le type de l'utilisateur qui l'a crée (USER ou GUEST)
    return axios.post(requestUrl, formData);
};

export const editGuest = (negociatorId, payload) => {
    const requestUrl = API_URL + `/negociator/${negociatorId}?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', payload.contract); //Id du contrat.
    formData.append('email', payload.email); // Le mail de l'invité 
    formData.append('firstName', payload.firstName); // Le prénom de l'invité 
    formData.append('lastName', payload.lastName); // Le nom de l'invité 
    formData.append('createdByType', payload.createdByType); // Le type de l'utilisateur qui l'a crée (USER ou GUEST)
    formData.append('phoneNumber', payload.phoneNumber); // Le numéro de téléphone de l'invité 
    return axios.post(requestUrl, formData);
};

export const deleteGuest = (negociatorId) => {
    const formData = new FormData()
    formData.append('id', negociatorId)
    const requestUrl = API_URL + `/negociator/${negociatorId}?access_token=` + getConnectedUserAccessToken();
    return axios.delete(requestUrl, formData);
  };

export const sendInvitationSMS = (negociatorId) => {
    const requestUrl = API_URL + `/negociator/sendSMS/` + negociatorId + `?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl);
};

export const newFeedbackForProposition = (payload) => {
    const requestUrl = API_URL + `/feedback?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('negociation', payload.negociationId); // Id de la négociation en question
    formData.append('feedback', payload.feedback); // Le type du feedback 
    formData.append('createdByType', payload.createdByType); // Le type de l'utilisateur qui l'a crée (USER ou GUEST)
    return axios.post(requestUrl, formData);
};

export const launchContractSignature = (payload) => {
    const requestUrl = API_URL + `/contract/launchContractSignature?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', payload.contract); // Le id du contrat 
    if ( payload.allGuests && payload.allGuests.toString() === 'true') {
        formData.append('allGuests', 1);
      }else{
        formData.append('allGuests', 0);
      }
       // Le flag indiquant si tous les invités vont etre des signataires ou pas 
    // formData.append('signatories', payload.signatories); // La liste des signataires (cas de allGuests == false) avec les champs suivants: firstName, lastName, email, phoneNumber
    return axios.post(requestUrl, formData);
};

export const cancelSignature = (contractId) => {
    const requestUrl = API_URL + `/contract/cancelSignature/` + contractId + `?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl);
};

export const doSigningRequest = (contractId) => {
    const requestUrl = API_URL + `/contract/remindSignature/` + contractId + `?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl);
};
 
export const remindSignature = (contractId) => {
    const requestUrl = API_URL + `/contract/remindSignature/` + contractId + `?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl);
};

export const validateContractWithoutSignature = (payload) => {
    const requestUrl = API_URL + `/contract/updateContractValidationsContent?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', payload.hachedId); // Le id du contrat 
    formData.append('htmlValidationContent', payload.htmlValidationContent); //old contract html (annex not included) 
    formData.append('annex', payload.annex); // Annexe modificatrice new contract html (annex included) 
    return axios.post(requestUrl, formData);
};

export const setSignatoriesList = (payload) => {
    const requestUrl = API_URL + `/contract/setSignatories?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', payload.contract); //Id du contrat.
    formData.append('createdByType', payload.createdByType); 
    if(payload.signatories && payload.signatories.length === 0){
        formData.append('signatories', JSON.stringify([]));
    }else{
        payload.signatories.forEach((signatory, index) => {
            if(signatory && signatory.email !== undefined && signatory.email !== null && signatory.email !== ""){
                signatory.firstSignatory = signatory.firstSignatory ? 1:0 
                signatory.secondSignatory = signatory.secondSignatory ? 1:0 
                formData.append('signatories['+index.toString()+']', JSON.stringify(signatory)); //  Array des signataires
            }
        });
    }
    return axios.post(requestUrl, formData);
};

// export const  getSignedContract = (contractId) => {
//     const requestUrl = API_URL + `/contract/getSignedContract/` + contractId + `?access_token=` + getConnectedUserAccessToken();
//     return axios.get(requestUrl);
// };

export const NotifyNegociatorsByMail = (contractId, createdByType) => {
    var formData = new FormData();
    formData.append('createdByType', createdByType);
    const requestUrl = API_URL + `/negociation/notify/` + contractId + `?access_token=` + getConnectedUserAccessToken();
    return axios.post(requestUrl,formData);
};

export const saveNegociatorActivity = (contractId, activity, createdByType=null) => {
    var formData = new FormData();
    formData.append('contract', contractId);
    formData.append('activity', activity);
    // formData.append('createdByType', createdByType);
    const requestUrl = API_URL + `/contract/saveActivity?access_token=` + getConnectedUserAccessToken();
    return axios.post(requestUrl,formData);
};