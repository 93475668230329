import React from "react";
import { Chart } from "react-google-charts";

const ChartPie3d = ({
  title,
  subTitle,
  data,
  displayValues,
  displayLegend,
  colors
}) => (
  <Chart
    chartType="PieChart"
    width="100%"
    height="100%"
    data={getFormattedData(data)}
    options={{
      chart: {
        title: title,
        subtitle: subTitle,
      },
      colors: colors.flat(),
      legend: { position: displayLegend ? "right" : "none" },
      is3D: true,
      pieSliceText: displayValues ? null : "none",
      animation: {
        startup: true,
        easing: 'linear',
        duration: 500,
      }
    }}
  />
);

export default ChartPie3d;

const getFormattedData = (data) =>{
  if(data && data.length==2){
    const array1 = data[0];
    const array2 = data[1];

    const arrayOfObjects = array1.map((element, index) => {
      if(element && element !== undefined && typeof element === 'string' && element.length>1)
        return [element,array2[index]]
    });

    const filteredArr = arrayOfObjects.filter(element => {
      return element !== undefined;
    });
    filteredArr.unshift(['Task', 'PieChart']);
    return filteredArr
  }else{
    return data
  }
}