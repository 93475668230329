import React, { Component } from "react";

import "./ConfirmAlert.scss";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { t } from "i18next";

class ConfirmAlert extends Component {
  constructor() {
    super();
  }

  show = (
    message,
    title = t('common.modals.confirmation'),
    TextConfirm = t('common.modals.yes'),
    TextCancel = t('common.modals.no')
  ) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: title,
        message: message,
        buttons: [
          {
            label: TextConfirm,
            onClick: () => resolve(),
          },
          {
            label: TextCancel,
            onClick: () => reject(),
          },
        ],
        customUI: ({ onClose }) => {
          return (
            <div className="confirm-alert">
              <h1>{title}</h1>
              <p dangerouslySetInnerHTML={{ __html: message }}></p>
              {TextCancel && (
                <button
                  onClick={() => {
                    onClose();
                    reject();
                  }}
                >
                  {TextCancel}
                </button>
              )}
              {TextConfirm && (
                <button
                  onClick={() => {
                    resolve();
                    onClose();
                  }}
                >
                  {TextConfirm}
                </button>
              )}
            </div>
          );
        },
        closeOnClickOutside: false
      });
    });
  };
}
export default new ConfirmAlert();
