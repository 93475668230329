import React, { forwardRef, useImperativeHandle, useState } from "react";

const ZoomContent = forwardRef((props, ref) => {

  const [zoom, setZoom] = useState(1)

  useImperativeHandle(ref, ()=>({
    zoomIn(){
      if( zoom < 1.8 ) setZoom(zoom + 0.2)
    },
    zoomOut(){
      if( zoom > 1 ) setZoom(zoom - 0.2)
    },
    resetZoom(){
      setZoom(1)
    }
  }))

  if( zoom > 1.8 ) setZoom(1.8)
  if( zoom < 1 ) setZoom(1)

  const style = {
    zoom: zoom > 1.8 ? 1.8 : zoom, 
    // width: !!window.chrome ? zoom > 1 ? `${(zoom*100) - 10}%` : '100%' : null, 
    width: !!window.chrome ? zoom > 1 ? `100%` : '100%' : null, 
    MozTransform: `scale(${zoom})`,
    OTransform: `scale(${zoom})`,  
    MozTransformOrigin: '0 0'
  }

  return(
    <div style={style}>
      {props.children}
    </div>
  )
})

export default ZoomContent;
