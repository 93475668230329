import React from 'react';
import { Sticky } from 'react-sticky';
import TimeLineHead from './TimeLineHead';
import TimeLineData from './TimeLineData';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

const TimeLine = ({
  isTimeLineSticky,

  onChangeTimeLineUnit,
  showAllContracts,
  onPressshowAllContracts,

  timelineData,
  setNewList,

  displayTimeline,
  pinTimeline,
  toggleTimeline
}) => (
  <>
    <div className="mb-3 card">
      <TimeLineHead
        onChangeTimeLineUnit={onChangeTimeLineUnit}
        showAllContracts={showAllContracts}
        displayTimeline={displayTimeline}
        pinTimeline={pinTimeline}
        toggleTimeline={toggleTimeline}
        onPressshowAllContracts={onPressshowAllContracts}
      />

      {displayTimeline &&
      <div className="card-body pt-0 pb-0">
        <div className="tab-content">
          <div className="time-line-tab tab-pane active" id="time-line-jours" role="tabpanel">
            <TimeLineData
              data={timelineData?.days}
              setNewList={setNewList}
              translateInterval={(interval) =>
                t(`frontoffice.home.timeLine.intervals.days.${interval}`)
              }
            />
          </div>
          <div className="time-line-tab tab-pane" id="time-line-semaine" role="tabpanel">
            <TimeLineData
              data={timelineData?.weeks}
              setNewList={setNewList}
              translateInterval={(interval) => {
                let splitedString = interval.split(' ');
                if (splitedString.length > 1) {
                  return (
                    t(`frontoffice.home.timeLine.intervals.weeks.Sem`) + ' ' + splitedString[1]
                  );
                } else {
                  return interval;
                }
              }}
            />
          </div>
          <div className="time-line-tab tab-pane" id="time-line-mois" role="tabpanel">
            <TimeLineData
              data={timelineData?.months}
              setNewList={setNewList}
              translateInterval={(interval) =>
                t(`frontoffice.home.timeLine.intervals.months.${interval}`)
              }
            />
          </div>
          <div className="time-line-tab tab-pane" id="time-line-trimestre" role="tabpanel">
            <TimeLineData
              data={timelineData?.trimesters}
              setNewList={setNewList}
              translateInterval={(interval) => {
                return (
                  t(`frontoffice.home.timeLine.intervals.quarters.Q`) + interval.substring(1)
                );
              }}
            />
          </div>
          <div className="time-line-tab tab-pane" id="time-line-annee" role="tabpanel">
            <TimeLineData
              data={timelineData?.years}
              setNewList={setNewList}
              translateInterval={(interval) => interval}
            />
          </div>
        </div>
      </div>}
    </div>
  </>
);

export default withTranslation()(TimeLine);
