
import UploadIcon from './upload.icon'
import DupplicateIcon from './dupplicate.icon'
import NewIcon from './new.icon'
import DeleteIcon from './delete.icon'

export {
  UploadIcon,
  DupplicateIcon,
  NewIcon,
  DeleteIcon
};
