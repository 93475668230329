import React, { Component } from 'react';
import { BrowserRouter as Router, Link, withRouter } from "react-router-dom";

import './Tags.scss'

import { t } from "i18next";
import { withTranslation } from "react-i18next";

// components
import Header from '../../../../../components/layouts/header/Header'
import Sidebar from '../../../../../components/layouts/sidebar/Sidebar'
import ContractTagsSetting from '../../../../../components/layouts/FO-Manage-Contract/Tags/FoManageTags'
import ConfirmAlert from '../../../../../components/confirm-alert/ConfirmAlert'
import ErrorAlert from "../../../../../components/confirm-alert/error-alert";
import { getContractInfo, createIndex, getContractWithIndexDetail, associateContractToIndex, dissociateContractToIndex, deleteTag, exportTags } from "../../../../../api/tag"
import LoaderComponent from '../../../../../components/loader/Loader'
import { downloadContractFromS3Server } from '../../../../../api/validateContract'
import EditMode from '../../../../../components/edit-mode/EditMode';

// XLSX library
import * as XLSX from 'xlsx';
import Modal from '../../../../../components/Modal';

// plugins
import * as moment from 'moment/min/moment-with-locales';
import FoManageContract from "../../../../../components/layouts/FO-Manage-Contract/FoManageContract"; moment.locale('fr-fr');

const ObjectsToCsv = require('objects-to-csv');


// form validation
const ReactFormWithConstraints =  require('react-form-with-constraints');

/* eslint-disable import/first */

const { FormWithConstraints, FieldFeedbacks, FieldFeedback } = ReactFormWithConstraints;

class Tags extends Component {

    constructor (props) {
        super(props);
        this.state = {
            default: 123,
            showFormAddTag: false,
            editMode: false,
            contract: null,
            tags: [],
            selectedTags: [],
            scrolling: false,
            showModal:false,
            isAccessDenied: false
        }
        this.alltags = this.state.tags;
    }

    componentDidMount() {
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        if(parsed.contract) {
            LoaderComponent.show();
            getContractInfo(parsed.contract).then((response) => {this.setState({
                ...this,
                contract: response.data,
                //editMode: response.data.ifUpdateRightIsPresent,
            }, () => {
                // if(this.state.contract.status === 2) {
                // } else {
                //     LoaderComponent.hide();
                //     ErrorAlert.show("Ce contrat n'est pas encore validé", "Attention");
                //     setTimeout(() => {
                //         this.props.history.push("/home");
                //     }, 3000);
                // }
                getContractWithIndexDetail(parsed.contract).then((response) => {
                    LoaderComponent.hide();
                    this.setState({
                    ...this,
                    tags: response.data.indexes,
                    selectedTags: response.data.contractIndexes,
                    isAccessDenied: false
                }) }, (error) => {
                    LoaderComponent.hide();
                    // ErrorAlert.show(error.response.data, "Erreur API");
                    if(error.response && error.response.status == 400){
                        // this.props.history.push("/home");
                        this.setState({
                            ...this,
                            isAccessDenied: true
                        })
                    }
                   
                  });
            })
        },  (error) => {
            LoaderComponent.hide();
            ErrorAlert.show(error.response.data, "Erreur API");
            setTimeout(() => {
                this.props.history.push("/home");
             }, 3000);
          }  );

        } else {
            ErrorAlert.show("Vous devez fournir l'identifiant du contrat", "Attention");
            setTimeout(() => {
                this.props.history.push("/home");
             }, 3000);
        }
    }

    changeEditMode = (checked) => {
        this.setState({
            ...this.state,
            editMode: checked
        })
    }

    enterPressed = (event)=>{
        var code = event.which;
        if(code === 13) { //13 is the enter keycode
            this.addNewTag();
        }
    }

    addNewTag = () => {
        let newTag = this.newTagInput.value;
        if( newTag.trim() && this.state.editMode ){
            LoaderComponent.show();
            createIndex(this.state.contract.hachedId, newTag).then((response) => {
                getContractWithIndexDetail(this.state.contract.hachedId).then((response) => {this.setState({
                    ...this,
                    tags: response.data.indexes,
                    selectedTags: response.data.contractIndexes
                },()=>{
                    LoaderComponent.hide();
                    this.newTagInput.value = ""
                    this.newTagInput.focus()
                }) });
              }, (error) => {
                LoaderComponent.hide();
                ErrorAlert.show(error.response.data, "Erreur API");
              });



            /*let tags = this.state.tags;
            tags.unshift({
                name: newTag,
                contracts: []
            })
            this.setState({
                ...this.state,
                tags: tags
            },()=>{
                this.newTagInput.value = ""
                this.newTagInput.focus()
            })*/
        }
    }

    addTagToContract = (tag)=>{
        let finded = this.state.selectedTags.find(item => item.name === tag.name)
        console.log('tag', tag)
        if(!finded && this.state.editMode){
            LoaderComponent.show();
            associateContractToIndex(this.state.contract.hachedId, tag.id).then((response) => {
                getContractWithIndexDetail(this.state.contract.hachedId).then((response) => {
                    LoaderComponent.hide();
                    this.setState({
                    ...this,
                    tags: response.data.indexes,
                    selectedTags: response.data.contractIndexes
                }) });
              }, (error) => {
                LoaderComponent.hide();
                ErrorAlert.show(error.response.data, "Erreur API");
              });

            /*let selectedTags = this.state.selectedTags;
            selectedTags.unshift(tag)
            this.setState({
                ...this.state,
                selectedTags: selectedTags
            })*/
        }
    }

    sortTags = (sortby, sort) => {
        let tags = this.state.tags;
        tags.sort(function(a, b){
            if(a[sortby] < b[sortby]) return sort == 'ASC' ? -1 : 1;
            if(a[sortby] > b[sortby]) return sort == 'ASC' ? 1 : -1;
            return 0;
        })
        this.setState({
            ...this.state,
            tags: tags
        })
    }


    exportTag = () => {
        // to do
    }

    removeTag = (tag, index) => {
        if(this.state.editMode ){
            //ConfirmAlert.show(`Voulez vous vraiment supprimer le tag: "${tag.name}" ?`).then(()=>{
                deleteTag(tag.id, this.state.contract.hachedId).then((response) => {
                    LoaderComponent.show();
                    getContractWithIndexDetail(this.state.contract.hachedId).then((response) => {
                        LoaderComponent.hide();
                        this.setState({
                        ...this,
                        tags: response.data.indexes,
                        selectedTags: response.data.contractIndexes
                    }) });
                  }, (error) => {
                    LoaderComponent.hide();
                    ErrorAlert.show(error.response.data, "Erreur API");
                  });
                /*let tags = this.state.tags;
                tags.splice(index, 1) ;
                this.setState({
                    ...this.state,
                    tags: tags
                }) */
            //});
        }
    }

    removeSelectedTag = (tag, index) => {
        if(this.state.editMode ){
            LoaderComponent.show();
            dissociateContractToIndex(this.state.contract.hachedId, tag.id).then((response) => {
                getContractWithIndexDetail(this.state.contract.hachedId).then((response) => {
                    LoaderComponent.hide();
                    this.setState({
                        tags: response.data.indexes,
                        selectedTags: response.data.contractIndexes
                    })
                });
            }, (error) => {
                LoaderComponent.hide();
                ErrorAlert.show(error.response.data, "Erreur API");
            });
        }
    }

    downloadContract = ()=>{
        downloadContractFromS3Server(this.state.contract?.id);
    }


    exportTags = () => {
        this.handleShow()
    }

    doExportComments = (fileExtension = '')=>{
        LoaderComponent.show()
        exportTags(this.state.contract.id).then((result) => {
            LoaderComponent.hide()
            if(fileExtension === 'csv'){
              const fileName = `Export-tags-${moment().format('DD-MM-YYYY')}.csv`;
              if (result) this.fileDownload(result.data, fileName);
            }
            if(fileExtension === 'xlsx'){
              const fileNameExcel = `Export-tags-${moment().format('DD-MM-YYYY')}.xlsx`;
              if (result) this.convertCsvToExcelBuffer(result.data, fileNameExcel)
            }
            this.handleClose()
        },
        error=>{
            LoaderComponent.hide()
            ErrorAlert.show(error.response.data, "Erreur API");
        });
      }

    fileDownload = (data, fileName) => {
        var universalBOM = '\uFEFF';
        var a = window.document.createElement('a');
        a.setAttribute(
            'href',
            'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + data)
        );
        a.setAttribute('download', fileName);
        window.document.body.appendChild(a);
        a.click();
    };

    convertCsvToExcelBuffer = (csvString, fileNameExcel) => {
        const arrayOfArrayCsv = csvString.split("\n").map((row) => {
          return row.split(";")
        });
        const wb = XLSX.utils.book_new();
        const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
        XLSX.utils.book_append_sheet(wb, newWs);
        const rawExcel = XLSX.write(wb, { type: 'base64' })
        console.log('rawExcel',rawExcel)
        XLSX.writeFile(wb, fileNameExcel);
        return rawExcel
      }
  
    handleShow = () => this.setState(
      {
        ...this.state,
        showModal: true
      }
    );
  
    handleClose = () => this.setState(
      {
        ...this.state,
        showModal: false
      }
    );
  
    onDownloadCSVFile = () => {
      this.doExportComments('csv')
    }
  
    onDownloadXLSXFile = () => {
      this.doExportComments('xlsx')
    }

    render() {

        return (
            <div className="app-container contract-settings page-tags app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
                <Header />
                {
                    this.state.isAccessDenied &&
                    <div className="restricted-content">
                      <div class="lock"></div>
                      <div class="message">
                        <h1>{t('restricted.title')}</h1>
                        <p>{t('restricted.msg')}</p>
                      </div>
                    </div>
                }
                {!this.state.isAccessDenied &&
                <div className="app-main">
                    <div className="app-sidebar sidebar-shadow">
                        <Sidebar />
                    </div>
                    <div className="app-main__outer">
                        <EditMode
                            isEditMode={this.state.editMode}
                            onChange={(checked)=>{
                                this.changeEditMode(checked)
                            }}
                            disabled={!this.state.contract?.ifUpdateRightIsPresent}
                        />
                        <div className="app-main__inner">

                            <div className="row">
                                <div className={`col-sm-5 left-side ${this.state.scrolling ? 'scrolling' : ''}`}>
                                    <div className="sub-menu">
                                        {this.state.contract?.status !== null && (
                                            <FoManageContract
                                                activeMenu="tags"
                                                contractStatus={this.state.contract?.status}
                                                inNegociationBy={this.state.contract?.inNegociationBy}
                                            />
                                        )}
                                    </div>
                                    <div className="sub-menu">
                                        <ContractTagsSetting activeMenu="index"></ContractTagsSetting>
                                    </div>
                                    <div className="actions">
                                        <div className="sorting">
                                            <button onClick={()=>{ this.sortTags('name', 'ASC') }} className="btn btn-sorting">
                                                <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.64706 3.08294V20.2112C5.64706 20.554 5.33102 20.8318 4.94118 20.8318C4.55133 20.8318 4.23529 20.554 4.23529 20.2112V3.08294L1.20502 5.74683C0.929352 5.98916 0.482412 5.98916 0.206748 5.74683C-0.0689161 5.5045 -0.0689161 5.11159 0.206748 4.86926L4.44204 1.14605C4.71771 0.903711 5.16465 0.903711 5.44031 1.14605L9.67561 4.86926C9.95127 5.11159 9.95127 5.5045 9.67561 5.74683C9.39994 5.98916 8.953 5.98916 8.67734 5.74683L5.64706 3.08294ZM12 3.44644C11.6102 3.44644 11.2941 3.16861 11.2941 2.8259C11.2941 2.48319 11.6102 2.20537 12 2.20537H23.2941C23.684 2.20537 24 2.48319 24 2.8259C24 3.16861 23.684 3.44644 23.2941 3.44644H12ZM12 8.41072C11.6102 8.41072 11.2941 8.1329 11.2941 7.79019C11.2941 7.44747 11.6102 7.16965 12 7.16965H20.4706C20.8604 7.16965 21.1765 7.44747 21.1765 7.79019C21.1765 8.1329 20.8604 8.41072 20.4706 8.41072H12ZM12 13.375C11.6102 13.375 11.2941 13.0972 11.2941 12.7545C11.2941 12.4118 11.6102 12.1339 12 12.1339H17.6471C18.0369 12.1339 18.3529 12.4118 18.3529 12.7545C18.3529 13.0972 18.0369 13.375 17.6471 13.375H12ZM12 18.3393C11.6102 18.3393 11.2941 18.0615 11.2941 17.7188C11.2941 17.376 11.6102 17.0982 12 17.0982H14.8235C15.2134 17.0982 15.5294 17.376 15.5294 17.7188C15.5294 18.0615 15.2134 18.3393 14.8235 18.3393H12Z" fill="#5367F4"/>
                                                </svg>
                                            </button>
                                            <button onClick={()=>{ this.sortTags('name', 'DESC') }} className="btn btn-sorting">
                                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.84923 20.9721L4.82229 20.9994L4.79982 21.0304C4.76761 21.0749 4.71905 21.1 4.66667 21.1C4.61428 21.1 4.56573 21.0749 4.53352 21.0304L4.51105 20.9994L4.48411 20.9721L0.550913 16.9918C0.483029 16.9231 0.483029 16.8093 0.550914 16.7406C0.61553 16.6752 0.717803 16.6752 0.78242 16.7406L3.64435 19.6368L4.5 20.5027V19.2854V0.674649C4.5 0.572566 4.58021 0.5 4.66667 0.5C4.75312 0.5 4.83333 0.572566 4.83333 0.674649V19.2854V20.5027L5.68898 19.6368L8.55091 16.7406C8.61553 16.6752 8.7178 16.6752 8.78242 16.7406C8.85031 16.8093 8.85031 16.9231 8.78242 16.9918L4.84923 20.9721ZM12.6667 2.19859C12.5802 2.19859 12.5 2.12603 12.5 2.02395C12.5 1.92186 12.5802 1.8493 12.6667 1.8493H23.3333C23.4198 1.8493 23.5 1.92186 23.5 2.02395C23.5 2.12603 23.4198 2.19859 23.3333 2.19859H12.6667ZM12.6667 7.59578C12.5802 7.59578 12.5 7.52322 12.5 7.42114C12.5 7.31905 12.5802 7.24649 12.6667 7.24649H20.6667C20.7531 7.24649 20.8333 7.31905 20.8333 7.42114C20.8333 7.52322 20.7531 7.59578 20.6667 7.59578H12.6667ZM12.6667 12.993C12.5802 12.993 12.5 12.9204 12.5 12.8183C12.5 12.7162 12.5802 12.6437 12.6667 12.6437H18C18.0865 12.6437 18.1667 12.7162 18.1667 12.8183C18.1667 12.9204 18.0865 12.993 18 12.993H12.6667ZM12.6667 18.3902C12.5802 18.3902 12.5 18.3176 12.5 18.2155C12.5 18.1134 12.5802 18.0409 12.6667 18.0409H15.3333C15.4198 18.0409 15.5 18.1134 15.5 18.2155C15.5 18.3176 15.4198 18.3902 15.3333 18.3902H12.6667Z" fill="black" stroke="#5367F4"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="search-input">
                                            <input type="text" placeholder={t('frontoffice.tags.new')} ref={(input)=>{this.newTagInput = input}} onKeyPress={(e)=>{ this.enterPressed(e) }} />
                                        </div>
                                        <button onClick={()=>{this.addNewTag()}} className="btn-icon btn-pill btn btn-danger" disabled={!this.state.editMode}>
                                            <svg className="inner" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13 7H1M7 1V13V1Z" stroke="#FFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                            <svg className="outer" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="36" height="36" rx="18" fill="#5367F4"></rect>
                                            </svg>
                                            {t('common.add')}
                                        </button>
                                    </div>
                                    <div className="scrolling-verticaly">

                                        <div className="tags row">
                                            { this.state.tags.map((tag, index)=>{
                                                return(
                                                    <div key={index} className="col-md-6">
                                                        <div key={index} className="tag">
                                                            <h3 onClick={()=>{this.addTagToContract(tag)}}>{ tag.name }</h3>
                                                            {
                                                                (!tag.contracts || tag.contracts?.length == 0) && this.state.editMode &&
                                                                <button className="btn btn-default" onClick={()=>{this.removeTag(tag, index)}}>
                                                                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect width="30" height="30" rx="15" transform="matrix(0.710669 -0.703526 0.710669 0.703526 -5.45605 15.2116)" fill="#F66031"/>
                                                                        <path d="M19.2931 11.7116L12.2221 18.7116M12.2221 11.7116L19.2931 18.7116L12.2221 11.7116Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    </svg>
                                                                </button>
                                                            }
                                                            {
                                                                tag.contracts?.length > 0 &&
                                                                <div>
                                                                    <button className="btn btn-default">
                                                                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect width="30" height="30" rx="15" transform="matrix(0.710669 -0.703526 0.710669 0.703526 -5.45605 15.1058)" fill="white"/>
                                                                            <path d="M22.2002 21.4058V4.60577H8.2002V22.1058C8.2002 23.034 8.56894 23.9243 9.22532 24.5806C9.8817 25.237 10.7719 25.6058 11.7002 25.6058" stroke="#202020" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                            <path d="M15.2002 21.4058V22.1058C15.2002 22.5654 15.1097 23.0205 14.9338 23.4452C14.7579 23.8698 14.5001 24.2556 14.1751 24.5806C13.8501 24.9056 13.4642 25.1634 13.0396 25.3393C12.6149 25.5152 12.1598 25.6058 11.7002 25.6058H24.3002C25.2285 25.6058 26.1187 25.237 26.7751 24.5806C27.4314 23.9243 27.8002 23.034 27.8002 22.1058V21.4058H15.2002Z" stroke="#202020" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                            <path d="M11.7002 18.6058H18.7002M11.7002 8.10577H18.7002H11.7002ZM11.7002 11.6058H18.7002H11.7002ZM11.7002 15.1058H18.7002H11.7002Z" stroke="#202020" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        </svg>
                                                                    </button>
                                                                    <div className="linked-contracts">
                                                                        <h4>{t('frontoffice.tags.count_index_contracts',{tag_contracts_count: tag.contracts?.length})}</h4>
                                                                        <ul>
                                                                            {tag.contracts.map((contractName, index)=>{
                                                                                return(
                                                                                    <li>{contractName}</li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }) }
                                            { this.state.tags.length == 0 &&
                                                <div className="col-sm-12 no-resut-found">
                                                    {t('common.no_result_found')}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-7 viewer">
                                        <h1>{t('frontoffice.tags.index_of')} <strong>{this.state.contract?.name}</strong>
                                        {this.state.selectedTags && this.state.selectedTags.length > 0 &&
                                            <div className="sorting w-auto">
                                                <button
                                                    onClick={() => {
                                                        this.exportTags();
                                                    }}
                                                    className="btn btn-sorting"
                                                >
                                                    <svg width="24" heght="24" viewBox="0 0 112 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M58.8333 66.3752L71.3543 53.8543C72.4933 52.7152 74.34 52.7152 75.4791 53.8543C76.6181 54.9933 76.6181 56.84 75.4791 57.9791L57.9791 75.4791C56.84 76.6181 54.9933 76.6181 53.8543 75.4791L36.3543 57.9791C35.2152 56.84 35.2152 54.9933 36.3543 53.8543C37.4933 52.7152 39.34 52.7152 40.4791 53.8543L53 66.3752V3.41667C53 1.80584 54.3058 0.5 55.9167 0.5C57.5275 0.5 58.8333 1.80584 58.8333 3.41667V66.3752ZM90.9167 18C89.3058 18 88 16.6942 88 15.0833C88 13.4725 89.3058 12.1667 90.9167 12.1667H96.75C104.804 12.1667 111.333 18.6958 111.333 26.75V85.0833C111.333 93.1375 104.804 99.6667 96.75 99.6667H15.0833C7.02918 99.6667 0.5 93.1375 0.5 85.0833V26.75C0.5 18.6958 7.02918 12.1667 15.0833 12.1667H20.9167C22.5275 12.1667 23.8333 13.4725 23.8333 15.0833C23.8333 16.6942 22.5275 18 20.9167 18H15.0833C10.2508 18 6.33333 21.9175 6.33333 26.75V85.0833C6.33333 89.9158 10.2508 93.8333 15.0833 93.8333H96.75C101.583 93.8333 105.5 89.9158 105.5 85.0833V26.75C105.5 21.9175 101.583 18 96.75 18H90.9167Z"
                                                            fill="#FF8A00" />
                                                    </svg>

                                                </button>
                                            </div>
                                        }
                                        </h1>
                                    {this.state.showModal &&
                                        <Modal
                                            title={t('common.export')}
                                            isOpen={this.state.showModal}
                                            onRequestClose={() => this.handleClose()}
                                            footer={
                                                <>
                                                    <button onClick={() => this.onDownloadCSVFile()}>
                                                        CSV
                                                    </button>
                                                    <button onClick={() => this.onDownloadXLSXFile()}>
                                                        XLSX
                                                    </button>
                                                </>
                                            }
                                            isFullScreen={false}
                                        >
                                            <div>
                                                <p className="text-center font-weight-bold">
                                                {t('common.select_form')}
                                                </p>
                                            </div>
                                        </Modal>
                                    }
                                    <div className="controls d-flex">
                                        <div className="fileName">{this.state.contract?.originalFileName}</div>
                                        <div className="exportBtns">
                                            {/* <button onClick={()=>{this.downloadContract()}} className="btn btm-light"><i className="lnr-download"></i></button> */}
                                            <button onClick={()=>{this.downloadContract()}} className="btn btm-light"><i className="lnr-printer"></i></button>
                                        </div>
                                    </div>
                                    <div className="row selectedTags">
                                        {this.state.selectedTags.map((tag, index) =>{
                                            return(
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="tag">
                                                        <h3>{tag.name}</h3>
                                                        {
                                                            this.state.editMode && (
                                                                <button className="btn btn-default" onClick={()=>{this.removeSelectedTag(tag, index)}}>
                                                                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect width="30" height="30" rx="15" transform="matrix(0.710669 -0.703526 0.710669 0.703526 -5.45605 15.1058)" fill="#F66031"/>
                                                                        <path d="M5.875 18C5.39175 18 5 16.6569 5 15C5 13.3431 5.39175 12 5.875 12H25.125C25.6082 12 26 13.3431 26 15C26 16.6569 25.6082 18 25.125 18H5.875Z" fill="white"/>
                                                                    </svg>
                                                                </button>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}

//export default Tags;
export default withTranslation()(withRouter(Tags));
