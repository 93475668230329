import React, { useState } from 'react';
import './menu.scss';

//icons
import homeIcon from '../../../icons/home.svg';
import graphIcon from '../../../icons/graph-menu.svg';
import eyeOffIcon from '../../../icons/eye-off.svg';
import { Link } from 'react-router-dom';

const menuItems = [
  { key: 'users', url: './dash-bord', icon: graphIcon, label: 'UTILISATEURS' },
  { key: 'hidden', url: '/dash-bord', icon: eyeOffIcon, label: 'Masqués' },
];

const Menu = ({ onchange }) => {
  const [active, setActive] = useState(0);

  return (
    <div className='dash-bord-menu'>
      <ul>
        <li>
         <Link to={'./home'}>
            <span className='icon-container'>
              <img src={homeIcon} alt='' />
            </span>
         </Link>
        </li>
        {menuItems.map((item, index) => (
          <li
            key={index}
            className={index === active ? 'active' : ''}
            onClick={() => {
              setActive(index);
              onchange(item.key)
            }}
          >
            <span className='icon-container'>
              <img src={item.icon} alt='' />
            </span>
            <span className='label'>{item.label}</span>
          </li>
        ))}
      </ul>
      <div className='hr'>
        <hr />
      </div>
    </div>
  );
};

export default Menu;
