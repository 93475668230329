import React from 'react'
export default () => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.84923 20.9721L4.82229 20.9994L4.79982 21.0304C4.76761 21.0749 4.71905 21.1 4.66667 21.1C4.61428 21.1 4.56573 21.0749 4.53352 21.0304L4.51105 20.9994L4.48411 20.9721L0.550913 16.9918C0.483029 16.9231 0.483029 16.8093 0.550914 16.7406C0.61553 16.6752 0.717803 16.6752 0.78242 16.7406L3.64435 19.6368L4.5 20.5027V19.2854V0.674649C4.5 0.572566 4.58021 0.5 4.66667 0.5C4.75312 0.5 4.83333 0.572566 4.83333 0.674649V19.2854V20.5027L5.68898 19.6368L8.55091 16.7406C8.61553 16.6752 8.7178 16.6752 8.78242 16.7406C8.85031 16.8093 8.85031 16.9231 8.78242 16.9918L4.84923 20.9721ZM12.6667 2.19859C12.5802 2.19859 12.5 2.12603 12.5 2.02395C12.5 1.92186 12.5802 1.8493 12.6667 1.8493H23.3333C23.4198 1.8493 23.5 1.92186 23.5 2.02395C23.5 2.12603 23.4198 2.19859 23.3333 2.19859H12.6667ZM12.6667 7.59578C12.5802 7.59578 12.5 7.52322 12.5 7.42114C12.5 7.31905 12.5802 7.24649 12.6667 7.24649H20.6667C20.7531 7.24649 20.8333 7.31905 20.8333 7.42114C20.8333 7.52322 20.7531 7.59578 20.6667 7.59578H12.6667ZM12.6667 12.993C12.5802 12.993 12.5 12.9204 12.5 12.8183C12.5 12.7162 12.5802 12.6437 12.6667 12.6437H18C18.0865 12.6437 18.1667 12.7162 18.1667 12.8183C18.1667 12.9204 18.0865 12.993 18 12.993H12.6667ZM12.6667 18.3902C12.5802 18.3902 12.5 18.3176 12.5 18.2155C12.5 18.1134 12.5802 18.0409 12.6667 18.0409H15.3333C15.4198 18.0409 15.5 18.1134 15.5 18.2155C15.5 18.3176 15.4198 18.3902 15.3333 18.3902H12.6667Z"
      fill="black"
      stroke="#5367F4"
    />
  </svg>
);
