import React from "react";
import { LockIcon } from "../../../assets/icons";

const ButtonLock = ({ onPress}) => (
  <button
    type="button"
    className={`btn btn-default icon-restriction`}
    onClick={onPress}
  >
    <LockIcon />
  </button>
);

export default ButtonLock;
