/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Switcher from "../../../../../../../components/switcher/Switcher";

import "./dash-bords-listing.scss";

const DashBordsListing = ({ items, screen, onToggle, currentDash, activePage, isToogleActive }) => { 
  return (
    <div className="dash-bord-consult-listing">
      {items.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => (
        <DashBordItem
          key={index}
          name={item.name}
          checked={(activePage && activePage['detail'] && activePage['detail'].find(x => x.dashboard === item.id)) || (currentDash && currentDash.id == item.id) 
            || (isToogleActive && isToogleActive.dashboard == item.id)  ? true : false}
          disabled={(!screen && !item.screen && !(activePage && activePage['detail'] && activePage['detail'].find(x => x.dashboard === item.id)) ? true : false)
          }
          onChange={(value) => onToggle(value, item, activePage && activePage['detail'] && activePage['detail'].find(x => x.dashboard === item.id) ? true : false)}
        />
      ))}
    </div>
  )
};

const DashBordItem = ({ key, name, checked, disabled, onChange }) => (
  <div className="dash-bord-consult-listing-item">
    <div
      key={key}
      className="d-flex justify-content-between align-items-center"
    >
      <h3>{name}</h3>

      {disabled ? (
        <OverlayTrigger
          placement={"top"}
          overlay={
            <Tooltip className="dash-bord-tooltip">
              {!checked && "Veuillez sélectionnez une zone tout d'abord"}
            </Tooltip>
          }
        >
          <span>
            <span style={{ pointerEvents: disabled ? "none" : "all" }}>
              <Switcher
                checked={checked}
                offstyle={"outline-light"}
                onChange={onChange}
              />
            </span>
          </span>
        </OverlayTrigger>
      ) : (
        <Switcher
          checked={checked}
          offstyle={"outline-light"}
          onChange={onChange}
        />
      )}
    </div>
  </div>
);

export default DashBordsListing;
