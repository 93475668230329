import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../../components/Modal';
import { withTranslation } from 'react-i18next';


const ArchiveDeleteModal = ({ isOpen, onRequestClose, archiveSelectedList, actions, onManageArchiveSelected }) => {

  const [isIRrevAction, setIsIRrevAction] = useState(false);

  let msg = "Êtes vous sur de bien vouloir " + ( actions["isDeleteAction"] === false && actions["archive"] === true ? "archiver": (actions["isDeleteAction"] === false && actions["unarchive"] === true ? "désarchiver": "supprimer") ) + " la selection ?";


  return (
    <Modal
      title={"Confirmation"}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      isFullScreen={false}
      footer={
        <>
          {actions["isDeleteAction"] === false && actions["archive"] === true && <button className='m-auto' onClick={() => onManageArchiveSelected("ARCHIVE")}>Oui</button>}
          {actions["isDeleteAction"] === false && actions["unarchive"] === true && <button className='m-auto' onClick={() => onManageArchiveSelected("UNARCHIVE")}>Oui</button>}
          {actions["isDeleteAction"] === true && !isIRrevAction && <button className='m-auto' onClick={() => setIsIRrevAction(true)}>Oui</button>}
          {actions["isDeleteAction"] === true && isIRrevAction && <button className='m-auto' onClick={() => onManageArchiveSelected("DELETE")}>Supprimer</button>}
          {actions["isDeleteAction"] === true && isIRrevAction && <button className='m-auto' onClick={() => {setIsIRrevAction(false);onRequestClose(false);}}>Annuler</button>}
          {!isIRrevAction && <button className='m-auto' onClick={() => onRequestClose(false)}>Non</button>}
        </>
      }
    >
      <table className="export-home w-auto">
        <tbody>
          <tr>
            <td className="zone-details">
              <div>
                <a class="ui teal ribbon label">Contrats selectionnés ({archiveSelectedList.length}) </a>
                <ul className="export-contracts-fiels p-4">
                  <p className={isIRrevAction ? "font-weight-bold text-danger":"font-weight-bold"}>
                    {isIRrevAction? "Attention, cette action est irréversible !":msg}
                  </p>
                  <li>
                    <hr />
                  </li>
                  {archiveSelectedList && archiveSelectedList.map((contract, key) => (
                    <li key={key}>
                      <label>
                        <span className='ml-2'>{"* " + contract.name}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default withTranslation()(ArchiveDeleteModal);
