import React, { useState } from 'react'
import ReactMultiselect from '../../../../../components/react-multiselect';
import { t } from "i18next";
import { withTranslation, useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

import Moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr"; // the locale you want
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';

const SearchForm = ({users, categories, distinctModules, onFormSubmit}) => {
  const { i18n } = useTranslation();
  const [render, setRender] = useState(true)

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [user, setUser] = useState(null)
  const [category, setCategory] = useState(null)
  const [contract, setContract] = useState('')
  const [selectedModules, setSelectedModules] = useState([])


  const onSubmit = () => {
    onFormSubmit({
      startDate,
      endDate,
      user,
      category,
      contract,
      selectedModules
    })
  }

  const onReset = () => {
    setStartDate('')
    setEndDate('')
    setUser(null)
    setCategory(null)
    setContract('')
    setSelectedModules([])
    onFormSubmit({
      startDate: '',
      endDate: '',
      user: null,
      category: null,
      contract: '',
      selectedModules: []
    })
    setRender(false)
    setTimeout(() => setRender(true));
  }
  
  return render ? (
    <form
      className=""
      noValidate
    >
      <h2>{t("backoffice.history.range_date")}</h2>
      <div className="row">
        <div className="col-lg-6 custom-range-style">
          <label>{t("backoffice.history.from")}</label>
          <DatePicker
            ref={(date) => { }}
            locale={i18n.language ? (i18n.language === "en" ? en : (i18n.language === "es" ? es : fr)) : fr}
            dateFormat="dd/MM/yyyy"
            className="form-control"
            autoComplete="off"
            name="startDate"
            selected={startDate ? new Date(startDate) : null}
            onChange={(dateValue) => {
              const formattedDate = Moment(dateValue).format("YYYY-MM-DD");
              setStartDate(formattedDate);
            }}
            placeholderText={t("backoffice.history.from")}
            required
            maxDate={endDate ? new Date(endDate) : null}
          />

          {/* <input
            type="date"
            name="startDate"
            value={startDate}
            onChange={(e)=>setStartDate(e.target.value)}
            className="form-control"
          /> */}
        </div>
        <div className="col-lg-6 custom-range-style">
          <label>{t("backoffice.history.to")}</label>
          <DatePicker
            ref={(date) => { }}
            locale={i18n.language ? (i18n.language === "en" ? en : (i18n.language === "es" ? es : fr)) : fr}
            dateFormat="dd/MM/yyyy"
            className="form-control d-block"
            autoComplete="off"
            name="endDate"
            selected={endDate ? new Date(endDate) : null}
            onChange={(dateValue) => {
              const formattedDate = Moment(dateValue).format("YYYY-MM-DD");
              setEndDate(formattedDate);
            }}
            placeholderText={t("backoffice.history.to")}
            required
            minDate={startDate ? new Date(startDate) : null} // Set minDate to startDate
          />


          {/* <input
            type="date"
            name="endDate"
            value={endDate}
            onChange={(e)=>{
              setEndDate(e.target.value)
            }}
            className="form-control"
          /> */}
        </div>
      </div>
      <div className="row selects">
        <div className="col-lg-6">
          <h2>{t("backoffice.history.user")}</h2>
          <div className="multi-select-checkboxes">
            <ReactMultiselect
              isMulti={false}
              placeholderButtonLabel={user !== null ? user.label:t("backoffice.history.select")}
              closeMenuOnSelect={true}
              placeholder={t("common.search")}
              onChange={setUser}
              className="multi-select-options"
              options={users}
              value={user}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <h2>{t("backoffice.history.category")}</h2>
          <div className="multi-select-checkboxes">
            <ReactMultiselect
              isMulti={false}
              placeholderButtonLabel={category !== null ? category.label:t("backoffice.history.select")}
              closeMenuOnSelect={true}
              placeholder={t("common.search")}
              onChange={setCategory}
              className="multi-select-options"
              options={categories}
              value={category}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <h2>{t("backoffice.history.contract")}</h2>
          <div className="multi-select-checkboxes">
            <input
              type="text"
              name="contract"
              value={contract}
              onChange={(e)=>{
                setContract(e.target.value)
              }}
              className="form-control"
            ></input>
          </div>
        </div>
        <div className="col-lg-6">
          <h2>{t("backoffice.history.functionality")}</h2>
          <div className="multi-select-checkboxes">
            <ReactMultiselect
              placeholderButtonLabel={t("backoffice.history.select")}
              tabSelectsValue="true"
              placeholder={t("common.search")}
              value={selectedModules}
              onChange={(list) => {
                setSelectedModules(list)
              }}
              className="multi-select-options"
              options={distinctModules  }
              getDropdownButtonLabel={selectedOption => {
                if (selectedOption && selectedOption.value !== undefined) {
                  if (selectedOption["value"].length == 0)
                    return t("backoffice.history.select")
                  // else if (selectedOption["value"].length == 1)
                  //   return selectedOption["value"][0].label
                  else
                    return selectedOption["value"].length + " " + t("backoffice.history.selected")
                } else {
                  return t("backoffice.history.select")
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <button
            type="button"
            onClick={onReset}
            className="btn btn-light btn-block btn-reset"
          >
            {t("common.reinit")}
          </button>
        </div>
        <div className="col-lg-8">
          <button
            type="button"
            onClick={onSubmit}
            className="btn btn-warning btn-block btn-search"
          >
            {t("common.display")}
          </button>
        </div>
      </div>
    </form>
  ):null;
}


export default withTranslation()(withRouter(SearchForm))