import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../../components/Modal';
import $ from 'jquery';
import moment from 'moment';
import { toast, ToastContainer } from "react-toastify";

import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

import { SearchIcon } from '../../../../assets/icons';
import errorAlert from '../../../../../../../components/confirm-alert/error-alert';
import { deleteHomeTabsSearch, manageHomeTabsSearch } from '../../../../../../../api/home';

const SearchModal = ({ isOpen, onRequestClose, keywordsDB, doSearch, doClearSearch, tabKey, homeTabsSearch }) => {
  
  const [searchDataList, setSearchDataList] = useState(homeTabsSearch || []);
  const [currentSearchData, setCurrentSearchData] = useState({name: "", fields: [], andContains: true, orContains: false, doesntContain: false, tabKey: tabKey, id:null});
  const [searchingkey, setSearchingkey] = useState("");
  const [showCustomClauses, setShowCustomClauses] = useState(false);

  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);


  
  // 'id', 'user', 'client', 'name', 'andContains', 'orContains', 'doesntContain', 'fields'
  //Reformat
  const categoriesWithKeywordsAndSynonyms = (data) => {
    let formattedData = []
    // Iterate over each item
    data.forEach(item => {
      // Iterate over categories of the item
      item.categories.forEach(category => {
        let existingCategory = formattedData.find(cat => cat.id === category.id);
        if (!existingCategory) {
          // If category doesn't exist in the result array, create it
          existingCategory = {
            id: category.id,
            name: category.name,
            keywords: [],
            selected:false
          };
          formattedData.push(existingCategory);
        }

        // Add item's name, id, and synonyms to the category
        let synonyms = item.synonyms.map(synonym => {
          return { name: synonym, selected: false };
      });

        // Add item's name, id, and synonyms to the category
        existingCategory.keywords.push({
          id: item.id,
          name: item.name,
          selected:false,
          synonyms: synonyms
        });
      });
    });
    return formattedData
  }

  const [searchData, setSearchData] = useState(categoriesWithKeywordsAndSynonyms(keywordsDB));

   // Start New code 
   const doSearchData = () => {
    doSearch(currentSearchData)
  }
  const doClearSearchData = () => {
    setSearchData(categoriesWithKeywordsAndSynonyms(keywordsDB))
    setCurrentSearchData({...currentSearchData, name: "", fields: searchData,andContains: true, orContains: false, doesntContain: false, id: null})
    doClearSearch()
  }
  const deleteSearchData = () => {
    deleteHomeTabsSearch(currentSearchData.id).then(response=>{
      setSearchDataList(organizeSearchConfigurations(response["data"]) || searchDataList)
      toast.success("Recherche supprimé avec succès", {
        position: toast.POSITION.TOP_RIGHT
      });
      setShowConfirmDeletion(false);
      doClearSearchData();
    })
  }
  const saveSearchData = () => {
    if(currentSearchData.name === null || currentSearchData.name === "" || !currentSearchData.name === null){
      toast.warn("Nom requis !", {
        position: toast.POSITION.TOP_RIGHT
      });
    }else{
      manageHomeTabsSearch(currentSearchData).then(response=>{
        setSearchDataList(organizeSearchConfigurations(response["data"]) || searchDataList)     
        toast.success("Mise à jours effectuée avec succès !", {
          position: toast.POSITION.TOP_RIGHT
        });
      })
    }
  }

  const doSaveAndSearchData = () => {
    if(currentSearchData.name === null || currentSearchData.name === "" || !currentSearchData.name === null){
      toast.warn("Nom requis !", {
        position: toast.POSITION.TOP_RIGHT
      });
    }else{
      manageHomeTabsSearch(currentSearchData).then(response=>{
        setSearchDataList(organizeSearchConfigurations(response["data"]) || searchDataList)     
        toast.success("Mise à jours effectuée avec succès !", {
          position: toast.POSITION.TOP_RIGHT
        });
        doSearchData();
        setShowConfirmDeletion(false)
        onRequestClose(false)
      })
    }
  }

  // Function to organize search configurations by key
  const organizeSearchConfigurations = (searchConfigurations) => {
    const organizedConfigurations = {};
    searchConfigurations.forEach(config => {
      const parsedConfig = { ...config }; // Create a shallow copy
      parsedConfig.fields = JSON.parse(config.fields);
      const key = parsedConfig.fields.key;
      if (!organizedConfigurations[key]) {
        organizedConfigurations[key] = { key: key, configurationsList: [] };
      }
      organizedConfigurations[key].configurationsList.push(parsedConfig);
    });
    return (Object.values(organizedConfigurations)).find(x=>x.key === tabKey);
  }

  const onchangeCategory = (e, index) => {
    let selected = e.target.checked;
    setSearchData(prevItems => {
      const newItems = [...prevItems]; // Create a copy of the array
      newItems[index] = { ...newItems[index], selected: selected }; // Update the name of the item at the specified index
      return newItems; // Return the updated array
    });
  };
  const onchangeCategoryKeyword = (e, indexCategory, indexKeyword) => {
    let selected = e.target.checked;
    setSearchData(prevItems => {
      const newItems = [...prevItems]; // Create a copy of the array
      newItems[indexCategory].keywords[indexKeyword] = { ...newItems[indexCategory].keywords[indexKeyword], selected: selected }; // Update the name of the item at the specified index
      return newItems; // Return the updated array
    });
  };

  const onchangeCategoryKeywordSynonym = (e, indexCategory, indexKeyword, indexSynonym) => {
    let selected = e.target.checked;
    setSearchData(prevItems => {
      const newItems = [...prevItems]; // Create a copy of the array
      newItems[indexCategory].keywords[indexKeyword].synonyms[indexSynonym] = { ...newItems[indexCategory].keywords[indexKeyword].synonyms[indexSynonym], selected: selected }; // Update the name of the item at the specified index
      return newItems; // Return the updated array
    });
  };
  
  const onchangeAllCategories = (e) => {
    let selected = e.target.checked;
    let listing = searchData.map((item) => {
      return { ...item, selected: selected };
    });
    setSearchData(listing);
  };
  
  const onchangeAllKeywords = (e, indexCategory) => {
    let selected = e.target.checked;
    let keywords = searchData[indexCategory].keywords.map((item) => {
      return { ...item, selected: selected };
    });

    searchData[indexCategory].keywords = keywords

    setSearchData(prevItems => {
      const newItems = [...prevItems]; // Create a copy of the array
      newItems[indexCategory] = searchData[indexCategory]; // Update the name of the item at the specified index
      return newItems; // Return the updated array
    });
  };
  
  const onchangeAllSynonyms = (e, indexCategory, indexKeyword) => {
    let selected = e.target.checked;
    let synonyms = searchData[indexCategory].keywords[indexKeyword].synonyms.map((item) => {
      return { ...item, selected: selected };
    });

    searchData[indexCategory].keywords[indexKeyword].synonyms = synonyms

    setSearchData(prevItems => {
      const newItems = [...prevItems]; // Create a copy of the array
      newItems[indexCategory] = searchData[indexCategory]; // Update the name of the item at the specified index
      return newItems; // Return the updated array
    });
  };

  // End New code 

  const loadSearchData= (item) => {
    setShowCustomClauses(true)
    setSearchData(item.fields.searchContent)
    setCurrentSearchData({
      name: item.name,
      fields: item.fields.searchContent,
      andContains: (item.andContains === "1" || item.andContains === 1 || item.andContains === "true" || item.andContains === true)? true:false,
      orContains: (item.orContains === "1" || item.orContains === 1 || item.orContains === "true" || item.orContains === true)? true:false,
      doesntContain: (item.doesntContain === "1" || item.doesntContain === 1 || item.doesntContain === "true" || item.doesntContain === true)? true:false,
      tabKey: tabKey,
      id: item.id})
  }

  const toogleFilter = (e, key=null) => {
    // 'andContains', 'orContains', 'doesntContain'
    let selected = e.target.checked;
    switch (key) {
      case 'andContains':
        if(currentSearchData && currentSearchData.name !== undefined){
          setCurrentSearchData({...currentSearchData, fields: searchData, andContains: selected, orContains: false, doesntContain: false})
        }else{
          setCurrentSearchData({...currentSearchData, name: "", fields: searchData, andContains: selected, orContains: false, doesntContain: false })
        }  
        break;
      case 'orContains':
        if(currentSearchData && currentSearchData.name !== undefined){
          setCurrentSearchData({...currentSearchData, fields: searchData, andContains: false, orContains: selected, doesntContain: false})
        }else{
          setCurrentSearchData({...currentSearchData, name: "", fields: searchData, andContains: false, orContains: selected, doesntContain: false })
        }  
        break;
      case 'doesntContain':
        if(currentSearchData && currentSearchData.name !== undefined){
          setCurrentSearchData({...currentSearchData, fields: searchData, andContains: false, orContains: false, doesntContain: selected})
        }else{
          setCurrentSearchData({...currentSearchData, name: "", fields: searchData, andContains: false, orContains: false, doesntContain: selected })
        }  
        break;
      default:
        break;
    }

  }
  

  useEffect(() => {
    if(currentSearchData && currentSearchData.name !== undefined){
      setCurrentSearchData({...currentSearchData, fields: searchData})
    }else{
      setCurrentSearchData({...currentSearchData, name: "", fields: searchData})
    }  
  }, [searchData]);

  return (
    <Modal
      title={showConfirmDeletion ? t("frontoffice.home.advanced_search.title_confirmation_action_delete"):t("frontoffice.home.advanced_search.title")}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      isFullScreen={false}
      footer={
        <>
          {(!showConfirmDeletion) && <button onClick={() => { saveSearchData();}}>{t("common.save")}</button>}
          {(!showConfirmDeletion) && <button onClick={() => { doSaveAndSearchData() }}>{t("common.search_and_save")}</button>}
          {(!showConfirmDeletion) && <button onClick={() => { doSearchData(); onRequestClose(false); }}>{t("common.search")}</button>}
          {showConfirmDeletion && <button onClick={()=>deleteSearchData()}>{t("common.modals.yes")}</button>}
          {showConfirmDeletion && <button onClick={() => setShowConfirmDeletion(false)}>{t("common.modals.no")}</button>}
        </>
      }
    >
      <ToastContainer className="toast-styled-popup"/>
      <table className="advanced-search">
        <tbody>
          {showConfirmDeletion &&
            <tr>
              <td>
                <p className="font-weight-bold text-center text-danger">
                 {t("frontoffice.home.advanced_search.confirmation_action_delete") + currentSearchData.name} ?
                </p>
              </td>
            </tr>
          }
          {!showConfirmDeletion &&
            <tr>
              <td>
                <div className="control-area">
                  <a className="new-item" onClick={() => doClearSearchData()}>
                    {currentSearchData && currentSearchData.id !== undefined && currentSearchData.id !== null &&
                      <svg width="28" height="28" viewBox="-25 -12 96 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M40.8333 35.5H72.9167C74.5275 35.5 75.8333 36.7965 75.8333 38.3958C75.8333 39.9952 74.5275 41.2917 72.9167 41.2917H40.8333V73.1458C40.8333 74.7452 39.5275 76.0417 37.9167 76.0417C36.3058 76.0417 35 74.7452 35 73.1458V41.2917H2.91667C1.30584 41.2917 0 39.9952 0 38.3958C0 36.7965 1.30584 35.5 2.91667 35.5H35V3.64583C35 2.04651 36.3058 0.75 37.9167 0.75C39.5275 0.75 40.8333 2.04651 40.8333 3.64583V35.5Z" fill="#FFFFFF" />
                      </svg>
                    }
                    {(currentSearchData.id === undefined || currentSearchData.id === null || currentSearchData.id === "") &&
                      <svg width="30" height="30" viewBox="-7 -15 140 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M99.1667 33.8066V49.9373C99.1667 51.5596 97.8608 52.8748 96.25 52.8748C94.6392 52.8748 93.3333 51.5596 93.3333 49.9373V26.4373C93.3333 24.8149 94.6392 23.4998 96.25 23.4998H119.583C121.194 23.4998 122.5 24.8149 122.5 26.4373C122.5 28.0596 121.194 29.3748 119.583 29.3748H103.003C115.188 39.2958 122.5 54.276 122.5 70.4998C122.5 99.7018 98.9949 123.375 70 123.375C41.0051 123.375 17.5 99.7018 17.5 70.4998C17.5 42.446 39.2544 19.3173 66.9395 17.7132C68.5477 17.62 69.9264 18.8575 70.0189 20.4771C70.1114 22.0968 68.8827 23.4853 67.2746 23.5785C42.6716 25.004 23.3333 45.564 23.3333 70.4998C23.3333 96.4571 44.2267 117.5 70 117.5C95.7733 117.5 116.667 96.4571 116.667 70.4998C116.667 56.0015 110.102 42.6268 99.1667 33.8066Z" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="#FFFFFF" />
                      </svg>
                    }
                  </a>
                  <a className="save" onClick={() => saveSearchData()}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10 -10 52 52" id="save">
                      <g data-name="Layer 5">
                        <path d="M29.77,9.06,22.94,2.23a2.48,2.48,0,0,0-1.77-.73H4A2.5,2.5,0,0,0,1.5,4V28A2.5,2.5,0,0,0,4,30.5H28A2.5,2.5,0,0,0,30.5,28V10.83A2.49,2.49,0,0,0,29.77,9.06ZM7.5,2.5h11V9a.5.5,0,0,1-.5.5H8A.5.5,0,0,1,7.5,9ZM29.5,28A1.5,1.5,0,0,1,28,29.5H4A1.5,1.5,0,0,1,2.5,28V4A1.5,1.5,0,0,1,4,2.5H6.5V9A1.5,1.5,0,0,0,8,10.5H18A1.5,1.5,0,0,0,19.5,9V2.5h1.67a1.51,1.51,0,0,1,1.06.44l6.83,6.83a1.49,1.49,0,0,1,.44,1.06Z" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.7"></path>
                        <rect width="2" height="4" x="14.5" y="3.5" rx=".5" ry=".5" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.7"></rect>
                        <path d="M25,13.5H7A1.5,1.5,0,0,0,5.5,15V26A1.5,1.5,0,0,0,7,27.5H25A1.5,1.5,0,0,0,26.5,26V15A1.5,1.5,0,0,0,25,13.5ZM25.5,26a.5.5,0,0,1-.5.5H7a.5.5,0,0,1-.5-.5V15a.5.5,0,0,1,.5-.5H25a.5.5,0,0,1,.5.5Z" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.7"></path>
                        <path d="M21 17.5H13a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1zM21 20.5H10a.5.5 0 0 0 0 1H21a.5.5 0 0 0 0-1zM21 23.5H10a.5.5 0 0 0 0 1H21a.5.5 0 0 0 0-1z" fill="#FFFFFF" stroke="#FFFFFF" stroke-width="0.7"></path>
                      </g>
                    </svg>
                  </a>
                  {currentSearchData && currentSearchData.id !== undefined && currentSearchData.id !== null &&
                    <a className="delete" onClick={() => setShowConfirmDeletion(true)}>
                      <svg width="36" height="36" viewBox="-7 -7 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M50.6666 16V49.3333C50.6666 53.0152 47.6819 56 44 56H20C16.3181 56 13.3333 53.0152 13.3333 49.3333V16H12C11.2636 16 10.6667 15.403 10.6667 14.6667C10.6667 13.9303 11.2636 13.3333 12 13.3333H24V12C24 9.79086 25.7908 8 28 8H36C38.2091 8 40 9.79086 40 12V13.3333H52C52.7364 13.3333 53.3333 13.9303 53.3333 14.6667C53.3333 15.403 52.7364 16 52 16H50.6666ZM16 16V49.3333C16 51.5425 17.7908 53.3333 20 53.3333H44C46.2091 53.3333 48 51.5425 48 49.3333V16H16ZM37.3333 13.3333V12C37.3333 11.2636 36.7364 10.6667 36 10.6667H28C27.2636 10.6667 26.6667 11.2636 26.6667 12V13.3333H37.3333ZM37.3333 25.3333C37.3333 24.597 37.9303 24 38.6667 24C39.403 24 40 24.597 40 25.3333V44C40 44.7364 39.403 45.3333 38.6667 45.3333C37.9303 45.3333 37.3333 44.7364 37.3333 44V25.3333ZM24 25.3333C24 24.597 24.5969 24 25.3333 24C26.0697 24 26.6667 24.597 26.6667 25.3333V44C26.6667 44.7364 26.0697 45.3333 25.3333 45.3333C24.5969 45.3333 24 44.7364 24 44V25.3333Z" stroke="#FFFFFF" stroke-width="1" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="#FFFFFF" />
                      </svg>

                      {/* <svg width="36" height="36" viewBox="0 0 139 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M69.5 65.4045L93.5148 41.3897C94.6457 40.2588 96.4793 40.2588 97.6101 41.3897C98.741 42.5206 98.741 44.3541 97.6101 45.485L73.5953 69.4998L97.6101 93.5147C98.741 94.6456 98.741 96.4791 97.6101 97.61C96.4793 98.7409 94.6457 98.7409 93.5148 97.61L69.5 73.5952L45.4852 97.61C44.3543 98.7409 42.5207 98.7409 41.3898 97.61C40.2589 96.4791 40.2589 94.6456 41.3898 93.5147L65.4047 69.4998L41.3898 45.485C40.2589 44.3541 40.2589 42.5206 41.3898 41.3897C42.5207 40.2588 44.3543 40.2588 45.4852 41.3897L69.5 65.4045Z" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="#FFFFFF" />
                    </svg> */}
                    </a>
                  }
                </div>
              </td>
              <td className="zone-details">
                <div>
                  <a class="ui teal ribbon label">
                    {t("frontoffice.home.advanced_search.query")}
                    {currentSearchData && currentSearchData.id === null ?
                      <small className='ml-1 font-weight-bold'>({t("common.new")})</small> :
                      <small className='ml-1 font-weight-bold'>({t("common.edit")})</small>
                    }
                  </a>
                  <ul className="export-contracts-fiels p-4">
                    <li>
                      <div className="input-item input-item-styled">
                        <label>
                          {t("common.label")}
                        </label>
                        <div className="d-flex">
                          <input
                            required
                            className="form-control"
                            name="libelle"
                            value={currentSearchData && currentSearchData.name !== undefined ? currentSearchData["name"] : ""}
                            placeholder={t("frontoffice.home.advanced_search.enter_query_name")}
                            onChange={(e) => {
                              setCurrentSearchData({ ...currentSearchData, name: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                    </li>
                    <li>
                      <hr />
                    </li>
                    <li>
                      <p className="text-center font-weight-bold">{t("common.filters")}</p>
                    </li>
                    <li>
                      <div className="content-ios-7">
                        <input onChange={(e) => toogleFilter(e, "andContains")} type="checkbox" name="andContains" className="sw" id="toggle-1"
                          disabled={currentSearchData.andContains}
                          checked={currentSearchData.andContains && currentSearchData.andContains !== undefined && currentSearchData.andContains !== false} />
                        <label for="toggle-1"><span>{t("frontoffice.home.advanced_search.contains_checked_clauses")}</span>
                        </label>
                      </div>
                      <br />
                      <div className="content-ios-7">
                        <input onChange={(e) => toogleFilter(e, "orContains")} type="checkbox" name="orContains" className="sw" id="toggle-2"
                          disabled={currentSearchData.orContains}
                          checked={currentSearchData.orContains && currentSearchData.orContains !== undefined && currentSearchData.orContains !== false} />
                        <label for="toggle-2"><span>{t("frontoffice.home.advanced_search.contains_more_one_clauses")}</span>
                        </label>
                      </div>
                      <br />
                      <div className="content-ios-7">
                        <input onChange={(e) => toogleFilter(e, "doesntContain")} type="checkbox" name="doesntContain" className="sw" id="toggle-3"
                          disabled={currentSearchData.doesntContain}
                          checked={currentSearchData.doesntContain && currentSearchData.doesntContain !== undefined && currentSearchData.doesntContain !== false} />
                        <label for="toggle-3"><span>{t("frontoffice.home.advanced_search.not_contains_checked_clauses")}</span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <hr />
                    </li>
                    <li>
                      <p className="text-center font-weight-bold">{t("frontoffice.home.advanced_search.categories_clauses_synonyms")}</p>
                    </li>

                    <li className={showCustomClauses ? 'active' : ''}>
                      <label>
                        <input type="checkbox" className="input-customclauses check-categories" onChange={onchangeAllCategories}
                          checked={searchData.filter(row => row.selected).length === searchData.length} />
                        <small className="font-weight-bold" style={{ color: "#F66031" }}>{t("frontoffice.home.advanced_search.categories")} {searchData ? ` (${searchData.filter(row => row.selected && row.selected !== undefined && row.selected !== false).length})` : ""}</small>
                        &nbsp;&nbsp;
                        <button onClick={() => setShowCustomClauses(!showCustomClauses)}>
                          {showCustomClauses ? (
                            <i className="fa fa-chevron-down"></i>
                          ) : (
                            <i className="fa fa-chevron-right"></i>
                          )}
                        </button>
                      </label>
                      {showCustomClauses && (
                        <ul>
                          {searchData?.map((category, indexCategory) => (
                            <li key={indexCategory}>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={category.selected && category.selected !== undefined && category.selected !== false}
                                  onChange={(e) => onchangeCategory(e, indexCategory)}
                                  className="check-categories"
                                />
                                {category.name}
                              </label>
                              {category["keywords"] && (
                                <>
                                  <input type="checkbox" className="input-customclauses ml-4 check-keywords" onChange={(e) => onchangeAllKeywords(e, indexCategory)}
                                    checked={category["keywords"].filter(row => row.selected).length === category["keywords"].length} />
                                  <small className="ml-2 font-weight-bold" style={{ color: "#5367F4" }}>{t("frontoffice.home.advanced_search.clauses")} {category["keywords"] ? ` (${category["keywords"].filter(row => row.selected && row.selected !== undefined && row.selected !== false).length})` : ""}</small>
                                  <i className="fa fa-chevron-down ml-2"></i>
                                  <ul>
                                    {category["keywords"]?.map((keyword, indexKeyword) => (
                                      <li key={indexKeyword}>
                                        <label>
                                          <input
                                            type="checkbox"
                                            checked={keyword.selected && keyword.selected !== undefined && keyword.selected !== false}
                                            onChange={(e) => onchangeCategoryKeyword(e, indexCategory, indexKeyword)}
                                            className="check-keywords"
                                          />
                                          {keyword.name}
                                        </label>

                                        {keyword["synonyms"] && (
                                          <>
                                            <input type="checkbox" className="input-customclauses ml-4 check-synonyms" onChange={(e) => onchangeAllSynonyms(e, indexCategory, indexKeyword)}
                                              checked={keyword["synonyms"].filter(row => row.selected).length === keyword["synonyms"].length} />
                                            <small className="ml-2 font-weight-bold" style={{ color: "#5CC973" }}>{t("frontoffice.home.advanced_search.synonyms")} {keyword["synonyms"] ? ` (${keyword["synonyms"].filter(row => row.selected && row.selected !== undefined && row.selected !== false).length})` : ""}</small>
                                            <i className="fa fa-chevron-down ml-2"></i>
                                            <ul>
                                              {keyword["synonyms"]?.map((keyword, indexSynonym) => (
                                                <li key={indexSynonym}>
                                                  <label>
                                                    <input
                                                      type="checkbox"
                                                      checked={keyword.selected && keyword.selected !== undefined && keyword.selected !== false}
                                                      onChange={(e) => onchangeCategoryKeywordSynonym(e, indexCategory, indexKeyword, indexSynonym)}
                                                      className="check-synonyms"
                                                    />
                                                    {keyword.name}
                                                  </label>
                                                </li>
                                              ))}
                                            </ul>
                                          </>
                                        )}

                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>

                  </ul>
                </div>
              </td>
              <td></td>
              <td className="zone-tags" width="50%">
                <div>
                  <a class="ui teal ribbon label">{t("common.records")}</a>
                  <div className="my-4 p-4">
                    <div className="search-input search-export">
                      <SearchIcon />
                      <input
                        className="form-control"
                        type="text"
                        placeholder={t("frontoffice.home.advanced_search.search_by_query")}
                        onChange={(e) => {
                          setSearchingkey(e && e.target !== undefined && e.target.value !== undefined && e.target.value !== null ? e.target.value : "")
                        }}
                      />
                    </div>
                    <hr />
                    <p className="box-tags">
                      {searchDataList && searchDataList["configurationsList"] &&
                        searchDataList["configurationsList"].map(item => {
                          if (item && item.name.toLowerCase().includes(searchingkey.toLowerCase()))
                            return (<span className={(item && item === currentSearchData) ? "export-tag-name selected" : "export-tag-name"} onClick={() => { loadSearchData(item) }}>{item.name}</span>)
                        })
                      }
                      {
                        searchDataList && searchDataList.length > 0 && searchingkey.trim() !== "" && !searchDataList.some(obj => obj.name.toLowerCase().includes(searchingkey.toLowerCase())) &&
                        <span className="text-danger">{t("common.no_result_found")}</span>
                      }
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </Modal>
  );
};

export default withTranslation()(SearchModal);
