import React, { Component } from 'react';
import { BrowserRouter as Router, Link, withRouter } from "react-router-dom";

import './Organizations.scss'

// components
import Header from '../../../../../components/layouts/header/Header'
import Sidebar from '../../../../../components/layouts/sidebar/Sidebar'
import ContractTagsSetting from '../../../../../components/layouts/FO-Manage-Contract/Tags/FoManageTags'
import ConfirmAlert from '../../../../../components/confirm-alert/ConfirmAlert'
import ErrorAlert from "../../../../../components/confirm-alert/error-alert";
import { getContractInfo, getContractWithTagDetail, associateContractToTag, dissociateContractToTag, deleteOrganization } from "../../../../../api/tag"
import LoaderComponent from '../../../../../components/loader/Loader'
import { downloadContractFromS3Server } from '../../../../../api/validateContract'
import EditMode from '../../../../../components/edit-mode/EditMode';

// plugins
import * as moment from 'moment/min/moment-with-locales'; moment.locale('fr-fr');
const ObjectsToCsv = require('objects-to-csv');


// form validation
const ReactFormWithConstraints =  require('react-form-with-constraints');

/* eslint-disable import/first */
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import FoManageContract from "../../../../../components/layouts/FO-Manage-Contract/FoManageContract";
const { FormWithConstraints, FieldFeedbacks, FieldFeedback } = ReactFormWithConstraints;

class Organizations extends Component {

    constructor (props) {
        super(props);
        this.state = {
            default: 123,
            showFormAddTag: false,
            editMode: false,
            contract: null,
            tags: [],
            selectedTags: [],
            scrolling: false
        }
        this.alltags = this.state.tags;
    }

    componentDidMount() {
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        if(parsed.contract) {
            LoaderComponent.show();
            getContractInfo(parsed.contract).then((response) => {this.setState({
                ...this,
                contract: response.data,
                //editMode: response.data.ifUpdateRightIsPresent,
            }, () => {
                // if(this.state.contract.status === 2) {
                // } else {
                //     LoaderComponent.hide();
                //     ErrorAlert.show("Ce contrat n'est pas encore validé", "Attention");
                //     setTimeout(() => {
                //         this.props.history.push("/home");
                //     }, 3000);
                // }
                getContractWithTagDetail(parsed.contract).then((response) => {
                    LoaderComponent.hide();
                    this.setState({
                    ...this,
                    tags: response.data.tags,
                    selectedTags: response.data.contractTags
                }) });
            })
        },  (error) => {
            LoaderComponent.hide();
            ErrorAlert.show(error.response.data, "Erreur API");
            setTimeout(() => {
                this.props.history.push("/home");
             }, 3000);
          }  );

        } else {
            ErrorAlert.show("Vous devez fournir l'identifiant du contrat", "Attention");
            setTimeout(() => {
                this.props.history.push("/home");
             }, 3000);
        }
    }

    changeEditMode = (checked) => {
        this.setState({
            ...this.state,
            editMode: checked
        })
    }

    addTagToContract = (tag)=>{
        let finded = this.state.selectedTags.find(item => item.name === tag.name)
        if(this.state.editMode){
            LoaderComponent.show();
            associateContractToTag(this.state.contract.hachedId, tag.id).then((response) => {
                getContractWithTagDetail(this.state.contract.hachedId).then((response) => {
                    LoaderComponent.hide();
                    this.setState({
                    ...this,
                    tags: response.data.tags,
                    selectedTags: response.data.contractTags
                }) });
              }, (error) => {
                LoaderComponent.hide();
                ErrorAlert.show(error.response.data, "Erreur API");
              });

            /*let selectedTags = this.state.selectedTags;
            selectedTags.unshift(tag)
            this.setState({
                ...this.state,
                selectedTags: selectedTags
            })*/
        }
    }

    sortTags = (sortby, sort) => {
        let tags = this.state.tags;
        tags.sort(function(a, b){
            if(a[sortby] < b[sortby]) return sort == 'ASC' ? -1 : 1;
            if(a[sortby] > b[sortby]) return sort == 'ASC' ? 1 : -1;
            return 0;
        })
        this.setState({
            ...this.state,
            tags: tags
        })
    }


    exportTag = () => {
        // to do
    }

    removeSelectedTag = (tag, index) => {
        if(this.state.editMode ){
            LoaderComponent.show();
            dissociateContractToTag(this.state.contract.hachedId, tag.id).then((response) => {
                getContractWithTagDetail(this.state.contract.hachedId).then((response) => {
                    LoaderComponent.hide();
                    this.setState({
                        tags: response.data.tags,
                        selectedTags: response.data.contractTags
                    })
                });
            }, (error) => {
                LoaderComponent.hide();
                ErrorAlert.show(error.response.data, "Erreur API");
            });
        }
    }

    downloadContract = ()=>{
        downloadContractFromS3Server(this.state.contract?.id);
    }

    removeTag = (tag, index) => {
        if(this.state.editMode ){
            ConfirmAlert.show(`Voulez vous vraiment supprimer le tag: "${tag.name}" ?`).then(()=>{
                deleteOrganization(tag.id, this.state.contract.hachedId).then((response) => {
                    LoaderComponent.show();
                    getContractWithTagDetail(this.state.contract.hachedId).then((response) => {
                        LoaderComponent.hide();
                        this.setState({
                            ...this,
                            tags: response.data.tags,
                            selectedTags: response.data.contractTags
                        }) });
                }, (error) => {
                    LoaderComponent.hide();
                    ErrorAlert.show(error.response.data, "Erreur API");
                });
            });
        }
    }


    render() {

        return (
            <div className="app-container contract-settings page-organisations app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
                <Header />

                <div className="app-main">
                    <div className="app-sidebar sidebar-shadow">
                        <Sidebar />
                    </div>
                    <div className="app-main__outer">
                        <EditMode
                            isEditMode={this.state.editMode}
                            onChange={(checked)=>{
                                this.changeEditMode(checked)
                            }}
                            disabled={!this.state.contract?.ifUpdateRightIsPresent}
                        />
                        <div className="app-main__inner">

                            <div className="row">
                                <div className={`col-sm-5 left-side ${this.state.scrolling ? 'scrolling' : ''}`}>
                                    <div className="sub-menu">
                                        {this.state.contract?.status !== null && (
                                            <FoManageContract
                                                activeMenu="tags"
                                                contractStatus={this.state.contract?.status}
                                                inNegociationBy={this.state.contract?.inNegociationBy}
                                            />
                                        )}
                                    </div>
                                    <div className="sub-menu">
                                        <ContractTagsSetting activeMenu="organization"></ContractTagsSetting>
                                    </div>
                                    <div className="actions">
                                        <div className="sorting">
                                            <button onClick={()=>{ this.sortTags('name', 'ASC') }} className="btn btn-sorting">
                                                <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.64706 3.08294V20.2112C5.64706 20.554 5.33102 20.8318 4.94118 20.8318C4.55133 20.8318 4.23529 20.554 4.23529 20.2112V3.08294L1.20502 5.74683C0.929352 5.98916 0.482412 5.98916 0.206748 5.74683C-0.0689161 5.5045 -0.0689161 5.11159 0.206748 4.86926L4.44204 1.14605C4.71771 0.903711 5.16465 0.903711 5.44031 1.14605L9.67561 4.86926C9.95127 5.11159 9.95127 5.5045 9.67561 5.74683C9.39994 5.98916 8.953 5.98916 8.67734 5.74683L5.64706 3.08294ZM12 3.44644C11.6102 3.44644 11.2941 3.16861 11.2941 2.8259C11.2941 2.48319 11.6102 2.20537 12 2.20537H23.2941C23.684 2.20537 24 2.48319 24 2.8259C24 3.16861 23.684 3.44644 23.2941 3.44644H12ZM12 8.41072C11.6102 8.41072 11.2941 8.1329 11.2941 7.79019C11.2941 7.44747 11.6102 7.16965 12 7.16965H20.4706C20.8604 7.16965 21.1765 7.44747 21.1765 7.79019C21.1765 8.1329 20.8604 8.41072 20.4706 8.41072H12ZM12 13.375C11.6102 13.375 11.2941 13.0972 11.2941 12.7545C11.2941 12.4118 11.6102 12.1339 12 12.1339H17.6471C18.0369 12.1339 18.3529 12.4118 18.3529 12.7545C18.3529 13.0972 18.0369 13.375 17.6471 13.375H12ZM12 18.3393C11.6102 18.3393 11.2941 18.0615 11.2941 17.7188C11.2941 17.376 11.6102 17.0982 12 17.0982H14.8235C15.2134 17.0982 15.5294 17.376 15.5294 17.7188C15.5294 18.0615 15.2134 18.3393 14.8235 18.3393H12Z" fill="#5367F4"/>
                                                </svg>
                                            </button>
                                            <button onClick={()=>{ this.sortTags('name', 'DESC') }} className="btn btn-sorting">
                                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.84923 20.9721L4.82229 20.9994L4.79982 21.0304C4.76761 21.0749 4.71905 21.1 4.66667 21.1C4.61428 21.1 4.56573 21.0749 4.53352 21.0304L4.51105 20.9994L4.48411 20.9721L0.550913 16.9918C0.483029 16.9231 0.483029 16.8093 0.550914 16.7406C0.61553 16.6752 0.717803 16.6752 0.78242 16.7406L3.64435 19.6368L4.5 20.5027V19.2854V0.674649C4.5 0.572566 4.58021 0.5 4.66667 0.5C4.75312 0.5 4.83333 0.572566 4.83333 0.674649V19.2854V20.5027L5.68898 19.6368L8.55091 16.7406C8.61553 16.6752 8.7178 16.6752 8.78242 16.7406C8.85031 16.8093 8.85031 16.9231 8.78242 16.9918L4.84923 20.9721ZM12.6667 2.19859C12.5802 2.19859 12.5 2.12603 12.5 2.02395C12.5 1.92186 12.5802 1.8493 12.6667 1.8493H23.3333C23.4198 1.8493 23.5 1.92186 23.5 2.02395C23.5 2.12603 23.4198 2.19859 23.3333 2.19859H12.6667ZM12.6667 7.59578C12.5802 7.59578 12.5 7.52322 12.5 7.42114C12.5 7.31905 12.5802 7.24649 12.6667 7.24649H20.6667C20.7531 7.24649 20.8333 7.31905 20.8333 7.42114C20.8333 7.52322 20.7531 7.59578 20.6667 7.59578H12.6667ZM12.6667 12.993C12.5802 12.993 12.5 12.9204 12.5 12.8183C12.5 12.7162 12.5802 12.6437 12.6667 12.6437H18C18.0865 12.6437 18.1667 12.7162 18.1667 12.8183C18.1667 12.9204 18.0865 12.993 18 12.993H12.6667ZM12.6667 18.3902C12.5802 18.3902 12.5 18.3176 12.5 18.2155C12.5 18.1134 12.5802 18.0409 12.6667 18.0409H15.3333C15.4198 18.0409 15.5 18.1134 15.5 18.2155C15.5 18.3176 15.4198 18.3902 15.3333 18.3902H12.6667Z" fill="black" stroke="#5367F4"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="scrolling-verticaly">

                                        <div className="tags row">
                                            { this.state.tags.map((tag, index)=>{
                                                return(
                                                    <div key={index} className="col-md-6">
                                                        <div key={index} className="tag">
                                                            <h3 onClick={()=>{this.addTagToContract(tag)}}>{ tag.name }</h3>
                                                            {
                                                                (!tag.users || tag.users?.length == 0) && this.state.editMode &&
                                                                <button className="btn btn-default" onClick={()=>{this.removeTag(tag, index)}}>
                                                                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect width="30" height="30" rx="15" transform="matrix(0.710669 -0.703526 0.710669 0.703526 -5.45605 15.2116)" fill="#F66031"/>
                                                                        <path d="M19.2931 11.7116L12.2221 18.7116M12.2221 11.7116L19.2931 18.7116L12.2221 11.7116Z" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                    </svg>
                                                                </button>
                                                            }

                                                            {
                                                                tag.users?.length > 0 &&
                                                                <div>
                                                                    <button className="btn btn-default">
                                                                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect width="30" height="30" rx="15" transform="matrix(0.710669 -0.703526 0.710669 0.703526 -5.45605 15.1058)" fill="white"/>
                                                                            <path d="M22.2002 21.4058V4.60577H8.2002V22.1058C8.2002 23.034 8.56894 23.9243 9.22532 24.5806C9.8817 25.237 10.7719 25.6058 11.7002 25.6058" stroke="#202020" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                            <path d="M15.2002 21.4058V22.1058C15.2002 22.5654 15.1097 23.0205 14.9338 23.4452C14.7579 23.8698 14.5001 24.2556 14.1751 24.5806C13.8501 24.9056 13.4642 25.1634 13.0396 25.3393C12.6149 25.5152 12.1598 25.6058 11.7002 25.6058H24.3002C25.2285 25.6058 26.1187 25.237 26.7751 24.5806C27.4314 23.9243 27.8002 23.034 27.8002 22.1058V21.4058H15.2002Z" stroke="#202020" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                            <path d="M11.7002 18.6058H18.7002M11.7002 8.10577H18.7002H11.7002ZM11.7002 11.6058H18.7002H11.7002ZM11.7002 15.1058H18.7002H11.7002Z" stroke="#202020" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                                        </svg>
                                                                    </button>
                                                                    <div className="linked-contracts">
                                                                        <h4>{tag.users?.length} utilisateur(s):</h4>
                                                                        <ul>
                                                                            {tag.users.map((user, index)=>{
                                                                                return(
                                                                                    <li>{user}</li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }) }
                                            { this.state.tags.length == 0 &&
                                                <div className="col-sm-12 no-resut-found">
                                                    Aucun résultat n'a été trouvé
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-7 viewer">
                                        <h1>Index de <strong>{this.state.contract?.name}</strong></h1>
                                    <div className="controls d-flex">
                                        <div className="fileName">{this.state.contract?.originalFileName}</div>
                                        <div className="exportBtns">
                                            <button onClick={()=>{this.downloadContract()}} className="btn btm-light"><i className="lnr-download"></i></button>
                                            <button onClick={()=>{this.printContract()}} className="btn btm-light"><i className="lnr-printer"></i></button>
                                        </div>
                                    </div>
                                    <div className="row selectedTags">
                                        {this.state.selectedTags.map((tag, index) =>{
                                            return(
                                                <div className="col-md-6 col-lg-4">
                                                    <div className="tag">
                                                        <h3>{tag.name}</h3>
                                                        {this.state.editMode && (
                                                            <button className="btn btn-default" onClick={()=>{this.removeSelectedTag(tag, index)}}>
                                                                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <rect width="30" height="30" rx="15" transform="matrix(0.710669 -0.703526 0.710669 0.703526 -5.45605 15.1058)" fill="#F66031"/>
                                                                    <path d="M5.875 18C5.39175 18 5 16.6569 5 15C5 13.3431 5.39175 12 5.875 12H25.125C25.6082 12 26 13.3431 26 15C26 16.6569 25.6082 18 25.125 18H5.875Z" fill="white"/>
                                                                </svg>
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//export default Organizations;
export default withRouter(Organizations);
