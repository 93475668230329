import React from "react";

//css
import "./chart-data.scss";

//icons
import infoIcon from "../../../icons/info.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ColumnTitle = ({ title, info, onPressToggle }) => (
  <div className="column-title">
    <div className="d-flex justify-content-between align-items-center">
      <h3>{title}</h3>
      {info ? (
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip className="dash-bord-tooltip">{info}</Tooltip>}
        >
          <button className="btn btn-link btn-info-bull">
            <img src={infoIcon} alt={""} />
          </button>
        </OverlayTrigger>
      ) : null}
    </div>
    {onPressToggle ? (
      <div className="d-flex justify-content-between align-items-center">
        <button className="btn btn-link" onClick={()=>onPressToggle(true)}>Tout déplier</button>
        <button className="btn btn-link" onClick={()=>onPressToggle(false)}>Tout rétracter</button>
      </div>
    ) : null}
  </div>
);

export default ColumnTitle;
