import React from "react";

const CloseCarreIcon = ({color = "#ffff", width="24", height="24"}) => (
    <>

        <svg width={width} height={height} viewBox="0 0 140 139" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M74.1248 69.5L86.6457 81.9315C87.7848 83.0624 87.7848 84.8959 86.6457 86.0268C85.5067 87.1577 83.66 87.1577 82.5209 86.0268L70 73.5953L57.4791 86.0268C56.34 87.1577 54.4933 87.1577 53.3543 86.0268C52.2152 84.8959 52.2152 83.0624 53.3543 81.9315L65.8752 69.5L53.3543 57.0685C52.2152 55.9376 52.2152 54.1041 53.3543 52.9732C54.4933 51.8423 56.34 51.8423 57.4791 52.9732L70 65.4047L82.5209 52.9732C83.66 51.8423 85.5067 51.8423 86.6457 52.9732C87.7848 54.1041 87.7848 55.9376 86.6457 57.0685L74.1248 69.5ZM17.5 31.8542C17.5 23.8575 24.0292 17.375 32.0833 17.375H107.917C115.971 17.375 122.5 23.8575 122.5 31.8542V107.146C122.5 115.142 115.971 121.625 107.917 121.625H32.0833C24.0292 121.625 17.5 115.142 17.5 107.146V31.8542ZM23.3333 31.8542V107.146C23.3333 111.944 27.2508 115.833 32.0833 115.833H107.917C112.749 115.833 116.667 111.944 116.667 107.146V31.8542C116.667 27.0562 112.749 23.1667 107.917 23.1667H32.0833C27.2508 23.1667 23.3333 27.0562 23.3333 31.8542Z" fill={color} />
        </svg>

    </>
);

export default CloseCarreIcon;