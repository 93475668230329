import React from "react";

const UnpinIcon = () => (
  <svg width="25px" height="25px" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M56.24 21.827L41.72 7.31104C41.2715 6.86093 40.7302 6.51413 40.1339 6.29485C39.5375 6.07557 38.9005 5.9891 38.2672 6.04147C37.634 6.09384 37.0198 6.2838 36.4676 6.59807C35.9154 6.91235 35.4384 7.34337 35.07 7.86104L22.11 26.051C22.0751 26.0984 22.0301 26.1374 21.9781 26.1651C21.9262 26.1928 21.8688 26.2085 21.81 26.211L16.24 26.251C15.3787 26.258 14.5386 26.5194 13.8255 27.0024C13.1124 27.4855 12.558 28.1686 12.232 28.9659C11.906 29.7632 11.8231 30.639 11.9935 31.4833C12.164 32.3276 12.5803 33.1027 13.19 33.711L20.1 40.621L22.93 43.451L29.84 50.361C30.4484 50.9708 31.2234 51.3871 32.0677 51.5575C32.912 51.728 33.7879 51.645 34.5852 51.319C35.3824 50.9931 36.0655 50.4387 36.5486 49.7255C37.0316 49.0124 37.2931 48.1724 37.3 47.311L37.34 41.741C37.3426 41.6823 37.3583 41.6248 37.386 41.5729C37.4137 41.521 37.4526 41.4759 37.5 41.441L55.69 28.481C56.207 28.1113 56.6375 27.6336 56.9517 27.0811C57.266 26.5286 57.4565 25.9144 57.51 25.281C57.5656 24.6473 57.4805 24.0092 57.261 23.4121C57.0415 22.8151 56.6928 22.2738 56.24 21.827Z" fill="#5367F4" />
    <path d="M22.93 43.451L8.94 57.441C8.56472 57.8163 8.05572 58.0271 7.52499 58.0271C6.99427 58.0271 6.48527 57.8163 6.10999 57.441C5.7347 57.0657 5.5239 56.5567 5.5239 56.026C5.5239 55.4952 5.7347 54.9863 6.10999 54.611L20.1 40.621L22.93 43.451Z" fill="#5367F4" />
  </svg>
);

export default UnpinIcon;
