import React from "react";
import {PencilIcon } from "../../../assets/icons";
const ButtonEdit = ({ onPress, disabled }) => (
  <button
    type="button"
    className={`btn btn-default ${disabled ? "" : ""}`}
    onClick={onPress}
  >
    <PencilIcon />
  </button>
);

export default ButtonEdit;
