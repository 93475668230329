/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { BrowserRouter as Router, Link } from "react-router-dom";

import "./headerBankStatements.scss";
import DataContext from "../../../DataManagement/DataContext";

//i18n
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import logo from "./logo/docatweb-logo.png";

import Cookies from "js-cookie";

class headerBankStatements extends Component {
  constructor() {
    super();
   
  }

  componentWillMount() {
    
      // let currentClientCookie = Cookies.get("currentClient");
      // if (currentClientCookie) {
        
      // }
    
  }


 

  render() {
    return (
      <div className="app-header header-shadow header-visitor">
        <div className="app-header__logo">
          <div className="logo">
            <Link to="/" className="nav-link-header-visitor">
              <img src={logo}></img>
            </Link>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button
              type="button"
              className="hamburger hamburger--elastic mobile-toggle-nav"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
          <div className="logo">
            <Link to="/home" className="nav-link-header-visitor">
              <img src={logo}></img>
            </Link>
          </div>
        </div>
        <div className="app-header__menu">
          <span>
            <button
              type="button"
              className="btn-icon btn-icon-only btn btn-default btn-sm mobile-toggle-header-nav"
            >
              <span className="btn-icon-wrapper">
                <i className="fa fa-ellipsis-v fa-w-6"></i>
              </span>
            </button>
          </span>
        </div>
        <div className="app-header__content">
          
          
         
          <div className="app-header-middle">
            <div className="inline-btn">
              <div className="widget-content p-0">
                <p className="header-title">
                  <svg width="29" height="24" viewBox="0 0 29 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.2194 14.0258L26.2011 7.76471L14.2194 1.50356L2.2376 7.76471L14.2194 14.0258ZM0.379977 7.14006L13.8882 0.0812342C14.0955 -0.0270781 14.3432 -0.0270781 14.5505 0.0812342L28.0587 7.14006C28.5652 7.4047 28.5652 8.12471 28.0587 8.38935L14.5505 15.4482C14.3432 15.5565 14.0955 15.5565 13.8882 15.4482L0.379977 8.38935C-0.126467 8.12471 -0.126467 7.4047 0.379977 7.14006ZM14.2194 18.2611L27.3965 11.3754C27.7439 11.1938 28.1739 11.3263 28.3568 11.6712C28.5396 12.0162 28.4062 12.4431 28.0587 12.6246L14.5505 19.6835C14.3432 19.7918 14.0955 19.7918 13.8882 19.6835L0.379977 12.6246C0.0325114 12.4431 -0.100914 12.0162 0.0819623 11.6712C0.264839 11.3263 0.694766 11.1938 1.04223 11.3754L14.2194 18.2611ZM14.2194 22.4964L27.3965 15.6106C27.7439 15.4291 28.1739 15.5615 28.3568 15.9065C28.5396 16.2515 28.4062 16.6784 28.0587 16.8599L14.5505 23.9188C14.3432 24.0271 14.0955 24.0271 13.8882 23.9188L0.379977 16.8599C0.0325114 16.6784 -0.100914 16.2515 0.0819623 15.9065C0.264839 15.5615 0.694766 15.4291 1.04223 15.6106L14.2194 22.4964Z" fill="#7177A5" />
                  </svg>
                  Exports Relevés Bancaires
                </p>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    );
  }
}
headerBankStatements.contextType = DataContext;
export default withRouter(withTranslation()(headerBankStatements));
