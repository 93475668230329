import React, { useEffect, useState } from 'react';
import { t } from "i18next";
import { withTranslation } from 'react-i18next';
import "./ResumeDataModal.scss";
import Modal from '../Modal';


const ResumeDataModal = ({ title, isOpen, onRequestClose, onRequestCancel, doSave, requiredFields=0, fields=[], action="add" }) => {

  const [theme, setTheme] = useState("resume-data-modal-dark")
  

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      styleClass={theme}
      isFullScreen={true}
      footer={
        <>
        <button onClick={() => {onRequestClose()}}>
            { t("common.modals.return_to_entry")}
        </button>
        <button className={((action === "add" && requiredFields === 0) || (requiredFields>0 && action === "add" && (fields.filter(x=>x.value !== null && x.value !== undefined && x.value !== "")).length !== requiredFields)) ? "disabled-save":""}
           onClick={() => {doSave()}} disabled={(action === "add" && requiredFields === 0) || (requiredFields>0 && action === "add" && (fields.filter(x=> x.value !== null && x.value !== undefined && x.value !== "")).length !== requiredFields)}>
            {t("common.save")}
        </button>
        <button onClick={() => {onRequestCancel()}}>
            {t("common.abort")}
        </button>
    </>
      }
    >
      {/* <div className="theme-switcher">
          <span onClick={()=>setTheme("resume-data-modal-dark")}><i className="fa fa-moon" aria-hidden="true"></i></span>
          <span onClick={()=>setTheme("resume-data-modal-light")}><i className="fa fa-sun" aria-hidden="true"></i></span>
      </div> */}
      {
        action === "add" &&
        <div className="list-clauses">
          <p className="title-box">
          { t("common.modals.lose_data")}
          </p>
          <table>
            <thead>
              <tr>
                <td>
                  <p>{ t("common.modals.fields")}</p>
                </td>
                <td>
                  <p>{ t("common.modals.value")}</p>
                </td>
              </tr>
            </thead>
            <tbody>
              {
                fields.map((field, index) => {
                  const fieldValue = field.value && field.value !== null && field.value !== "" && field.value !== undefined ? field.value : "______";
                  return (
                    <tr className="fieldRow">
                      <td className={index === 0 ? "text-center pt-3 fieldName" : "text-center fieldName"}>
                        <p>
                          {field.txtLabel}
                        </p>
                      </td>
                      <td className={index === 0 ? "text-center pt-3" : "text-center"}>
                        <p className={field.value && field.value !== null && field.value !== "" && field.value !== undefined ? "" : "custom-text-danger"}>
                          {
                            (typeof fieldValue === "string" && fieldValue.includes("\n")) ?
                              (fieldValue.split('\n').map((line, index) => (
                                <p className="m-auto" key={index}>{line}</p>
                              )))
                              :
                              (typeof fieldValue === "boolean" ?  (fieldValue ? "√" : "x") : fieldValue)
                          }
                        </p>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      }
      {
        action === "edit" &&
        <div className="list-clauses">
          <p className="title-box">
          { t("common.modals.review_data")}
          </p>
          <table>
            <thead>
              <tr>
                <td>
                  <p>{ t("common.modals.fields")}</p>
                </td>
                <td>
                  <p>{ t("common.modals.old_value")}</p>
                </td>
                <td>
                  <p>{ t("common.modals.new_value")}</p>
                </td>
              </tr>
            </thead>
            <tbody>
              {
                fields.map((field, index) => {
                  const oldValue = (field.originValue && field.originValue !== null && field.originValue !== "" && field.originValue !== undefined ? (typeof field.originValue === "object" ? "champs de type objet" : field.originValue ) : "______")
                  const newValue = (field.value && field.value !== null && field.value !== "" && field.value !== undefined ? (typeof field.value === "object" ? "champs de type objet" : field.value ): "______")
                  return (
                    <tr className="fieldRow">
                      <td className="fieldName" style={{fontStyle: field.isExtraKeywords?"italic":"normal"}}>
                        <p>
                          {field.txtLabel}
                        </p>
                      </td>
                      <td>
                        <p className={field.originValue && field.originValue !== null && field.originValue !== "" && field.originValue !== undefined ? "" : "custom-text-danger"}>
                          {
                            (typeof oldValue === "string" && oldValue.includes("\n")) ?
                              (oldValue.split('\n').map((line, index) => (
                                <p className="m-auto" key={index}>{line}</p>
                              )))
                              :
                              (typeof oldValue === "boolean" ?  (oldValue ? "√" : "x") : oldValue)
                          }
                        </p>
                      </td>
                      <td>
                        <p className={field.value && field.value !== null && field.value !== "" && field.value !== undefined ? "" : "custom-text-danger"}>
                          {
                            (typeof newValue === "string" && newValue.includes("\n")) ?
                              (newValue.split('\n').map((line, index) => (
                                <p className="m-auto" key={index}>{line}</p>
                              )))
                              :
                              (typeof newValue === "boolean" ?  (newValue ? "√" : "x") : newValue)
                          }
                        </p>
                      </td>
                    </tr>
                  )
                })
              }
              {
                fields.length === 0 && 
                <tr className="fieldRow">
                  <td className="text-center" colSpan={3}>
                    <p>{ t("common.modals.no_edited_data")}</p>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      }
    </Modal>
  );
};

export default withTranslation()(ResumeDataModal);
