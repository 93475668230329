import React, { Component } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import "./History.scss";

// components
import Header from "../../../../components/layouts/header/Header";
import Sidebar from "../../../../components/layouts/sidebar/Sidebar";

/* eslint-disable import/first */
import LoaderComponent from "../../../../components/loader/Loader";
import { getHistory, getCategories, exportHistories } from "../../../../api/userHistory";
import { getUsers } from "../../../../api/team";
import SearchForm from "./parts/SearchForm";
import ActionIcon from "./parts/ActionIcon";
import moment from "moment";
import errorAlert from "../../../../components/confirm-alert/error-alert";

// XLSX library
import * as XLSX from 'xlsx';
import Modal from "../../../../components/Modal";

import { t } from "i18next";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      categories: [],
      history: [],
      distinctModules: [],
      showModal:false,
    };
    this.form = {};
  }

  async componentDidMount() {
    LoaderComponent.show();

    const historyRes = await getHistory(this.form)
    console.log('historyRes', historyRes);
    this.setState({
      history: historyRes,
    },()=>{
      this.getDistinctModules(historyRes)
    });

    

    const categoriesRes = await getCategories()
    this.setState({
      categories: categoriesRes
    });
    LoaderComponent.hide();
    const usersRes = await getUsers()
    this.setState({
      users: usersRes.data
    });
  }

  getDistinctModules = (historyRes) => {
    const distinctModules = new Set();

    historyRes.forEach(row => {
      row.logs.forEach(log => {
        distinctModules.add(log.logAction.module);
      });
    });

    const distinctModulesArray = Array.from(distinctModules).map(module => ({
      label: this.getTranslatedLocation(module),
      value: module
    }));

    this.setState({...this.state, distinctModules: distinctModulesArray});
  };

  selectUser = (user) => {
    this.form.user = user?.id;
  };

  selectCategory = (category) => {
    this.form.category = category?.value;
  };

  onFormSubmit = (data) => {
    this.form.startDate = data.startDate;
    this.form.endDate = data.endDate;
    this.form.user = data.user?.id;
    this.form.category = data.category?.value;
    this.form.contract = data.contract;
    this.form.selectedModules = data.selectedModules;
    this.search();
  };

  search = () => {
    LoaderComponent.show();
      getHistory(this.form).then((response) => {
      LoaderComponent.hide();
      this.setState({
        history: response,
      });
    });
  };

  exportHistory = () => {
    this.handleShow()
  };

  doExportHistory = (fileExtension = '')=>{
    LoaderComponent.show()
    exportHistories(this.form).then((result) => {
        LoaderComponent.hide()
        if(fileExtension === 'csv'){
          const fileName = `Export-historiques-${moment().format('DD-MM-YYYY')}.csv`;
          if (result) this.fileDownload(result, fileName);
        }
        if(fileExtension === 'xlsx'){
          const fileNameExcel = `Export-historiques-${moment().format('DD-MM-YYYY')}.xlsx`;
          if (result) this.convertCsvToExcelBuffer(result, fileNameExcel)
        }
        this.handleClose()
    },
    error=>{
        LoaderComponent.hide()
        errorAlert.show(error.response.data, "Erreur API");
    });
  }

  fileDownload = (data, fileName) => {
    var universalBOM = '\uFEFF';
    var a = window.document.createElement('a');
    a.setAttribute(
      'href',
      'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + data)
    );
    a.setAttribute('download', fileName);
    window.document.body.appendChild(a);
    a.click();
  };

  convertCsvToExcelBuffer = (csvString, fileNameExcel) => {
    const arrayOfArrayCsv = csvString.split("\n").map((row) => {
      return row.split(";")
    });
    const wb = XLSX.utils.book_new();
    const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
    XLSX.utils.book_append_sheet(wb, newWs);
    const rawExcel = XLSX.write(wb, { type: 'base64' })
    console.log('rawExcel', rawExcel)
    XLSX.writeFile(wb, fileNameExcel);
    return rawExcel
  }

  handleShow = () => this.setState(
    {
      ...this.state,
      showModal: true
    }
  );

  handleClose = () => this.setState(
    {
      ...this.state,
      showModal: false
    }
  );

  onDownloadCSVFile = () => {
    this.doExportHistory('csv')
  }

  onDownloadXLSXFile = () => {
    this.doExportHistory('xlsx')
  }

  printHistory = () => { };

  getTranslatedAction = (action) =>{
    switch (action) {
      case "Océrise":
        return t("backoffice.history.action.ocrise")
        break;
      case "Importe":
        return t("backoffice.history.action.import")
        break;
      case "Crée":
        return t("backoffice.history.action.create")
        break;
      case "Modifie":
        return t("backoffice.history.action.update")
        break;
      case "Supprime":
        return t("backoffice.history.action.delete")
        break;
      case "Valide":
        return t("backoffice.history.action.validate")
        break;
      case "Archive":
        return t("backoffice.history.action.archive")
        break;
      case "Désarchive":
        return t("backoffice.history.action.unarchive")
        break;
    
      default:
        return action
        break;
    }
  }

  getTranslatedLocation = (location, isForSelectTag=false) =>{
    
    switch (location) {
      case "Import":
        return t("backoffice.history.log_module.import")
        break;
      case "Tag":
        return location+(isForSelectTag ? "s" : "")
        break;
      case "Tag index":
        return isForSelectTag ? t("backoffice.history.log_module.tag_index_s") : t("backoffice.history.log_module.tag_index")
        break;
      case "Tag orga":
        return isForSelectTag ? t("backoffice.history.log_module.tag_orga_s") : t("backoffice.history.log_module.tag_orga")
        break;
      case "Commentaire":
        return isForSelectTag ? t("backoffice.history.log_module.comment_s") : t("backoffice.history.log_module.comment")
        break;
      case "Alerte":
        return isForSelectTag ? t("backoffice.history.log_module.alert_s") : t("backoffice.history.log_module.alert")
        break;
      case "Négociation":
        return t("backoffice.history.log_module.negotiation")
        break;
      case "Contrat/Avenant":
        return isForSelectTag ? t("backoffice.history.log_module.contract_s") : t("backoffice.history.log_module.contract")
        break;
      case "Document lié":
        return isForSelectTag ? t("backoffice.history.log_module.document_s") : t("backoffice.history.log_module.document")
        break;
      case "Archive":
        return t("backoffice.history.log_module.archive")
        break;
      case "Connexion":
        return t("backoffice.history.log_module.connection")
        break;
    
      default:
        return location
        break;
    }
  }

  render() {
    return (
      <div className="app-container history  app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
        <Header />

        <div className="app-main">
          <div className="app-sidebar sidebar-shadow">
            <Sidebar />
          </div>
          <div className="app-main__outer">
            <div className="bg-under-header editModeTitle">
              <span className="empty-header"> </span> 
            </div>
            <div className="app-main__inner">
              <div className="row">
                <div className="col-sm-5 left-side">
                  <div className="head d-flex">
                    <Link to="/back-office/home">
                      <button className="btn btn-light btn-back">
                        <svg
                          width="10"
                          height="17"
                          viewBox="0 0 10 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.9375 1.8125L1.0625 8.55913L8.9375 15.3125"
                            stroke="#7077A5"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </Link>
                    <h2>{t("backoffice.history.title")}</h2>
                  </div>

                  <SearchForm
                    users={this.state.users}
                    categories={this.state.categories}
                    distinctModules={(this.state.distinctModules && this.state.distinctModules.length>0) ? this.state.distinctModules.map(module => ({label: this.getTranslatedLocation(module.value, true),value: module.value})):[]}
                    onHandleChangeInput={this.handleChangeInput}
                    onSelectUser={this.selectUser}
                    onSelectCategory={this.selectCategory}
                    onFormSubmit={this.onFormSubmit}
                  />
                </div>
                {this.state.showModal &&
                    <Modal
                      title={"Export"}
                      isOpen={this.state.showModal}
                      onRequestClose={() => this.handleClose()}
                      footer={
                        <>
                          <button onClick={() => this.onDownloadCSVFile()}>
                            CSV
                          </button>
                          <button onClick={() => this.onDownloadXLSXFile()}>
                            XLSX
                          </button>
                        </>
                      }
                      isFullScreen={false}
                    >
                      <div>
                        <p className="text-center font-weight-bold">
                          {t("common.select_form")}
                        </p>
                      </div>
                    </Modal>
                  }
                <div className="col-sm-7 viewer">
                  <div className="head d-flex">
                    <h2>{t("backoffice.history.title")}</h2>
                    <div className="exportBtns">
                      <button
                        onClick={() => {
                          this.exportHistory();
                        }}
                        className="btn btm-light"
                      >
                        <i className="lnr-download"></i>
                      </button>
                      {/* <button
                        onClick={() => {
                          this.printHistory();
                        }}
                        className="btn btm-light"
                      >
                        <i className="lnr-printer"></i>
                      </button> */}
                    </div>
                  </div>
                  <table
                    width="100%"
                    id="example"
                    className="table table-hover"
                    data-searching="false"
                    data-select="false"
                    data-paging="false"
                    data-info="false"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t("backoffice.history.table.date")}</th>
                        <th>{t("backoffice.history.table.hour")}</th>
                        <th>{t("backoffice.history.table.user")}</th>
                        <th>{t("backoffice.history.table.action")}</th>
                        <th>{t("backoffice.history.table.location")}</th>
                        <th>{t("backoffice.history.table.doc_name")}</th>
                      </tr>
                    </thead>
                    {this.state.history?.map((item, index) => {
                      return (
                        <tbody>
                          {index > 0 && (
                            <tr>
                              <td colspan="7" className="date_row">
                                <span className="btn btn-danger">
                                  {item.date}
                                </span>
                              </td>
                            </tr>
                          )}

                          {item.logs.map((log, logIndex) => {
                            return (
                              <tr>
                                <td className="td_actionIcon">
                                  <ActionIcon
                                    action={log.logAction.action}
                                    module={
                                      log.logAction.module == "Tag index" ||
                                      log.logAction.module == "Tag orga"
                                        ? "Tag"
                                        : log.logAction.module
                                    }
                                  />
                                </td>
                                <td>{log.date}</td>
                                <td>{log.time}</td>
                                <td>{log.user}</td>
                                <td>{this.getTranslatedAction(log.action)}</td>
                                <td>{this.getTranslatedLocation(log.location)}</td>
                                <td>{log.docName}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(History));
