import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../components/Modal';
import { t } from "i18next";
import { withTranslation } from 'react-i18next';
import DataTable from '../../../../../../components/dataTable/DataTable';


const ContractsConvertModal = ({ isOpen, onRequestClose, contracts, setContracts, onSaveLinkedContract }) => {

  

  const onChangeContractLinkStatus = (e, contractsId) => {
    let listing = contracts.map((item, i) => {
      return { ...item, linked: item.id === contractsId  ? true : false };
    });
    setContracts(listing);
  };


  return (
    <Modal
      title={t('frontoffice.contract_linked_docs.to_linked_doc.convert_to_doc')}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      isFullScreen={false}
      footer={
        <>
          <button className='m-auto' onClick={() => onSaveLinkedContract()}> {t('common.convert')}</button>
          <button className='m-auto' onClick={() => onRequestClose(false)}> {t('common.cancel')}</button>
        </>
      }
    >
      <table className="convert-contract-modal w-auto">
        <tbody>
          <tr>
            <td className="zone-details">
              <div>
                <a class="ui teal ribbon label">
                  {t('frontoffice.contract_linked_docs.to_linked_doc.conversion_to_doc')}
                </a>
                <ul className="convert-contract-fiels p-4">
                  <p>
                    {t('frontoffice.contract_linked_docs.to_linked_doc.choose_linked_contract')}
                  </p>
                  <hr />
                  {contracts.map((field, key) => {
                    return (
                      <li key={key}>
                        <label className="label-radio-check">
                          <input
                            type="radio"
                            checked={(field.linked && field.linked !== undefined && field.linked !== false)}
                            onChange={(e) => onChangeContractLinkStatus(e, field.id)}
                          />
                          <span>
                            {field.name}
                          </span>
                        </label>
                      </li>
                    )
                  }
                  )}
                </ul>
              </div>
            </td>
            
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default withTranslation()(ContractsConvertModal);
