import React from "react";

import { Dropdown } from "react-bootstrap";
import Cookies from "js-cookie";
import { useLocation } from 'react-router-dom';
import PinIcon from "../svg-icons/pin.svg";
import UnpinIcon from "../svg-icons/unpin.svg";
import { t } from "i18next";
import { withTranslation } from "react-i18next";
const CompaniesDropDown = ({ items, value, onSelect, roleType, negociations, onPinUnpinClt, defaultClient = null, defaultRightType = null }) => {
  const location = useLocation();
  const DropdownItem = ({ index, item, roleType, isNegos=false }) => {
    const isAdmin = item?.roleType === "ADMIN_CLIENT";
    if(roleType !== "GUEST" && isNegos == true){
      return (
        <Dropdown.Item
          key={index}
          onClick={() => {
            onSelect(item.contractId,true,item.isAvt == 1 ? true:false);
            localStorage.setItem("selectedClientContract", JSON.stringify({clientTxt: item.client, contratId: item.contractId}))
          }}
          className="guest"
        >
            <span>NEGO</span>  {' - '+item.client + ' - ' + item.contract}
        </Dropdown.Item>
      );
    }
    else if(roleType === "GUEST"){
      return (
        <Dropdown.Item
          key={index}
          onClick={() => {
            onSelect(item.contractId,true,item.isAvt == 1 ? true:false);
          }}
          className="guest"
        >
            <span>NEGO</span>  {' - '+item.client + ' - ' + item.contract}
        </Dropdown.Item>
      );
    }else{
      return (
        <Dropdown.Item
          key={index}
          
          className={`${isAdmin ? "admin" : "society"}`}
        >
          {isAdmin ? (
            <>
              <span className="pr-2"
                onClick={() => {
                  onSelect(item.client.id, false, false, item.roleType);
                }}
              >
                <i className="fa fa-cog"></i>
                {item.client.companyName}
              </span>
              <span className="pin_unpin">
                {
                  defaultClient && defaultClient !== undefined && defaultClient !== null && defaultClient !== "" && defaultClient == item.client.id && defaultRightType == "ADMIN_CLIENT" &&
                  <small onClick={()=>{onPinUnpinClt(item.client.id,  "unpin", "ADMIN_CLIENT")}}>
                    <UnpinIcon />
                  </small>
                }
                {
                  (
                    defaultClient == null ||
                    defaultRightType !== "ADMIN_CLIENT" ||
                    (defaultClient && defaultClient !== undefined && defaultClient !== null && defaultClient !== "" && defaultClient !== item.client.id && defaultRightType == "ADMIN_CLIENT")
                  ) &&
                  <small onClick={()=>{onPinUnpinClt(item.client.id,  "pin", "ADMIN_CLIENT")}}>
                    <PinIcon />
                  </small>
                }
              </span>
            </>
          ) : (
            <>
              <span className="pr-2"
                onClick={() => {
                  onSelect(item.client.id, false, false, item.roleType);
                }}
              >
                {item.client.companyName}
                {negociations && negociations.filter(x => x.client == item.client.companyName).length > 0 ? <small className="user-nego">{negociations.filter(x => x.client == item.client.companyName).length + ' ' +  t('header.negotiations_in_progress')}</small> : ''}
              </span>
              <span className="pin_unpin">
                {
                  defaultClient && defaultClient !== undefined && defaultClient !== null && defaultClient !== "" && defaultClient == item.client.id && defaultRightType == "USER" &&
                  <small onClick={()=>{onPinUnpinClt(item.client.id,  "unpin", "USER")}}>
                    <UnpinIcon />
                  </small>
                }
                {
                  (
                    defaultClient == null ||
                    defaultRightType !== "USER" ||
                    (defaultClient && defaultClient !== undefined && defaultClient !== null && defaultClient !== "" && defaultClient !== item.client.id && defaultRightType == "USER")
                  ) &&
                  <small onClick={()=>{onPinUnpinClt(item.client.id,  "pin", "USER")}}>
                    <PinIcon />
                  </small>
                }
              </span>
            </>
          )}
        </Dropdown.Item>
      );
    }
    
  };
  if(roleType === "GUEST"){
    let uniqueDataArray = [];
    
    const searchParams = new URLSearchParams(location.search);
    const contractId = searchParams.get('contract');
    let strMsg = t('header.negotiations')
    if(contractId && items && items.length>0){
      let found = items.find(x=>x.contract)
      if(found)
        strMsg = "NEGO - " + found.client 
    }
    return (
      <Dropdown>
        <Dropdown.Toggle variant="default">
          <i className="nav-link-icon fa fa-briefcase"></i>{" "}
          {items && items?.length > 0 && Cookies.get("currentNegosClientForGuest") ? JSON.parse(Cookies.get("currentNegosClientForGuest"))[0].client : strMsg}
        </Dropdown.Toggle>
  
        <Dropdown.Menu className="dropdown-list">
          {
          items && items?.length > 0 &&
          items.map((item, index) => {
              return (
                <DropdownItem 
                  onClick={() => {
                    onSelect(item.contractId,true,item.isAvt == 1 ? true:false);
                  }}
                  key={index}
                  index={index}
                  item={item}
                  roleType={roleType} />
              )
          }
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  }else{
    let selectedClient = JSON.parse(localStorage.getItem("selectedClientContract")) || undefined
    const searchParams = new URLSearchParams(location.search);
    const contractId = searchParams.get('contract');
    if(selectedClient == undefined && negociations && negociations.length>0 ){
      let itemCltLoaded = negociations.find(x=>x.contractId == contractId)
      if(itemCltLoaded && itemCltLoaded.client !== undefined){
        localStorage.setItem("selectedClientContract", JSON.stringify({clientTxt: itemCltLoaded.client, contratId: itemCltLoaded.contractId}))
        selectedClient = {clientTxt: itemCltLoaded.client, contratId: itemCltLoaded.contractId}
      }
    }
    let msgSelectedClt = value?.client ? value.client.companyName : t('header.choose_client')
    if(selectedClient !== undefined && selectedClient.contratId !== undefined && selectedClient.contratId == contractId && selectedClient.clientTxt !== msgSelectedClt){
      msgSelectedClt = "NEGO - " + selectedClient.clientTxt
    }
    return (
      <Dropdown>
        <Dropdown.Toggle variant="default">
          <i className="nav-link-icon fa fa-briefcase"></i>{" "}
          {msgSelectedClt}
        </Dropdown.Toggle>
  
        <Dropdown.Menu className="dropdown-list" style={{maxHeight:'90vh', overflowY:'scroll'}}>
          {items && items.map((item, index) => (
            <DropdownItem 
            onClick={() => {
              onSelect(index);
            }}
            key={index} 
            index={index} 
            item={item} 
            roleType={roleType} />
          ))}
          {negociations && negociations.length>0 && negociations.sort((a, b) => a.client.localeCompare(b.client)).map((item, index) => (
            <DropdownItem 
            onClick={() => {
              onSelect(item.contractId,true,item.isAvt == 1 ? true:false);
            }}
            key={index} 
            index={index} 
            item={item} 
            roleType={roleType} 
            isNegos={true}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  
};

export default withTranslation()(CompaniesDropDown);
