import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useState } from "react";

// components
import EditMode from "../../../../../../components/edit-mode/EditMode";
import Header from "../../../../../../components/layouts/header/Header";
import Sidebar from "../../../../../../components/layouts/sidebar/Sidebar";

//icons
import dashBordIcon from "../../icons/dash-bord.svg";

//css
import "./layout.scss";

const PageLayout = ({ leftArea, rightArea, editMode, onChangeEditMode }) => {
  return (
    <div className="app-container dash-bord  app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
      <Header />

      <div className="app-main">
        <div className="app-sidebar sidebar-shadow">
          <Sidebar />
        </div>
        <div className="app-main__outer">
          <div
            className={`bg-under-header editModeTitle ${
              editMode ? "editMode" : ""
            }`}
          >
            <div className="_container" style={{ maxWidth: "100%" }}>
              <div className="row">
                <div className="col-sm-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div></div>
                    <div className="edit-mode">
                      <BootstrapSwitchButton
                        onChange={onChangeEditMode}
                        checked={editMode}
                        disabled={false}
                      />
                      {editMode ? "Désactiver" : "Activer"} le mode édition
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="app-main__inner">
            <div className="row">
              <div className="col-md-4 left-side">
                <div className="head d-flex">
                  <img src={dashBordIcon} alt="" />
                  <h2>Tableaux de Bord</h2>
                </div>
                {leftArea}
              </div>
              <div className="col-md-8 viewer">
                <div className="ribbon"><span>Contrats</span></div>
                {rightArea}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
