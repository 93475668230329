import React from "react";
import Slider from "react-slick";
import sliderOptions from './options.json'

import ChartPreview from "../../../../../back-office/dash-bord/components/charts";

const CarouselTimelineDashBords = ({ timeline, dashBords }) => {

  return (
    <div className="carousel-timeline-container">
      <Slider {...sliderOptions}>
        {/* Render timeline */}
        {timeline}

        {/* Render dashBords */}
        {[...dashBords]?.map((item,index) => (
          <div className="home-dash-bord-item" key={index+1}>
            <div className="card-body timeLineHead">
              <h1 className="tab-title">
                {item.name} <i className="fa fa-angle-down"></i>
              </h1>
            </div>
            <ChartPreview {...item} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CarouselTimelineDashBords;
