import React, { Component } from 'react'; 

import './NotifyAlert.css';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


class NotifyAlert extends Component {  

    constructor(){
        super()
    }

    show = (message, title, TextConfirm = "D'accord", TextCancel = "Non") => {
      return new Promise( (resolve, reject) => {
          confirmAlert({
              title: title,
              message: message,
              buttons: [
                  {
                      label: TextConfirm,
                      onClick: () => resolve()
                  },
                  {
                      label: TextCancel,
                      onClick: () => reject()
                  }
              ],
              customUI: ({ onClose }) => {
                  return (
                    <div className='confirm-alert'>
                      <h1>{ title  }</h1>
                      <p>{ message  }</p>
                      <button
                        onClick={() => {
                          resolve();
                          onClose();
                        }}
                      >
                        { TextConfirm }
                      </button>
                    </div>
                  );
                }
          });
      }) 
  }
    
} 
export default new NotifyAlert(); 