import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken} from "./QueryAuthParameters"
import Moment from 'moment';

export const getEndorsementsList = (contract, isParentContract) => {
    const requestUrl = API_URL+`/contract/getEndorsementsList?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contract);
    formData.append('isParentContract', isParentContract);
    return axios.post(requestUrl, formData);
  };

  export const createEndorsement = (contract, parentContract) => {
    const requestUrl = API_URL+`/contract/createEndorsement?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contract);
    if(parentContract.id) {
      formData.append('parentContract', parentContract.id);
    } else {
      formData.append('parentContract', parentContract);
    }

    return axios.post(requestUrl, formData);
  };

  export const dissociateEndorsement = (contract) => {
    const requestUrl = API_URL+`/contract/dissociateEndorsement/`+contract+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

  export const getContractInfo = (contract) => {
    const requestUrl = API_URL+`/contract/getContractInfo?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contract);
    return axios.post(requestUrl, formData);
  };

  export const saveEndorsementDraft = (contract, signatory1, signatory2, amount, contractDuration, priorNoticeDuration, negociationDuration, endingDate, negociationBeginingDate, contractDurationType, negociationDurationType, priorNoticeDurationType, priorNoticeDate, extraKeywordsWithValues, pdfValidationContent, beginningDate, objet, contractCode, renewal, dueAmount, dueFrequency, dueFrequencyType, totalAmoutInitialPeriod, amountType, amountTerm, updateContractDate, wedge, contractAlerts) => {
    const requestUrl = API_URL+`/contract/saveEndorsementValidationDraft?access_token=`+getConnectedUserAccessToken();
    var keywordsValues = [];
    var docAtWebKeywordsValues = [];
    var clientKeywordsValues = [];
    var i;
    var formData = new FormData();

    formData.append('contract', contract.id);
    if (contract.name) {
      formData.append('name', contract.name);
    }
    if (contract.endorsementNumber) {
      formData.append('endorsementNumber', contract.endorsementNumber);
    }
    if (contract.endorsementBeginningDate) {
      formData.append('endorsementBeginningDate', contract.endorsementBeginningDate);
    }

    if(contractAlerts){
      formData.append('alerts[negociation]', contractAlerts.negciationAlert);
      formData.append('alerts[priorNotice]', contractAlerts.priorNoticeAlert);
      formData.append('alerts[ending]', contractAlerts.endingAlert);
      formData.append('alerts[isValidated]', 1);
    }

    for (i = 0; i < contract.keywords.docAtWebKeywords.length; i++) {
      var singleKeywordValue = null;
      var durationType = null;
      var durationJson = null;
      if (contract.keywords.docAtWebKeywords[i].name == "Nom Signataire Fournisseur" && signatory1 && signatory1 != "") {
        singleKeywordValue = signatory1.split("'").join(' ')
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Nom Signataire interne" && signatory2 && signatory2 != "") {
        singleKeywordValue = signatory2.split("'").join(' ')
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Durée" && contractDuration) {
        singleKeywordValue = JSON.stringify(contractDuration)
        durationType = contractDurationType
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Préavis") {
        if(priorNoticeDuration == 0) {
          singleKeywordValue = JSON.stringify(0)
          durationType = priorNoticeDurationType
        }
        if(priorNoticeDuration) {
          singleKeywordValue = JSON.stringify(priorNoticeDuration)
          durationType = priorNoticeDurationType
        }
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Négociation" && negociationDuration) {
        singleKeywordValue = JSON.stringify(negociationDuration)
        durationType = negociationDurationType
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Fréquence échéances" && dueFrequency) {
        singleKeywordValue = JSON.stringify(dueFrequency)
        durationType = dueFrequencyType
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Date de fin" && endingDate && endingDate != "") {
        singleKeywordValue = endingDate
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Date de début" && beginningDate && beginningDate != "") {
        singleKeywordValue = beginningDate
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Objet" && objet && objet != "") {
        singleKeywordValue = objet
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Numéro du contrat" && contractCode && contractCode != "") {
        singleKeywordValue = contractCode
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Renouvellement" /*&& renewal && renewal != ""*/) {
        singleKeywordValue = renewal
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Montant échéances" && dueAmount && dueAmount != "") {
        singleKeywordValue = dueAmount
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Montant Global du Contrat" && amount && amount != "") {
        singleKeywordValue = amount
      }
      if (contract.keywords.docAtWebKeywords[i].name == "negociationBeginingDate" && negociationBeginingDate && negociationBeginingDate != "") {
        singleKeywordValue = negociationBeginingDate
      }

      if (contract.keywords.docAtWebKeywords[i].name == "priorNoticeDate" && priorNoticeDate && priorNoticeDate != "") {
        singleKeywordValue = priorNoticeDate
      }

      if (contract.keywords.docAtWebKeywords[i].name === "Frais Installation/FAS") {
        durationJson = '{'
            +'"amount":"'+totalAmoutInitialPeriod+'",'
            +'"type":"'+amountType+'",'
            +'"term":"'+amountTerm+'",'
            +'"wedge":"'+wedge+'"'
            +'}';
        var keywordValue = '"'+(i+1)+'":{'
            +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
            +'"value":'+durationJson+','
            +'"position":""'
            +'}';
        keywordsValues.push(keywordValue.toString())
      }

      if (singleKeywordValue && singleKeywordValue != "") {
        if(contract.keywords.docAtWebKeywords[i].name !== "Préavis" && contract.keywords.docAtWebKeywords[i].name !== "Négociation" && contract.keywords.docAtWebKeywords[i].name !== "Durée" && contract.keywords.docAtWebKeywords[i].name !== "Fréquence échéances" ) {
          var keywordValue = '"'+(i+1)+'":{'
           +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
           +'"value":"'+singleKeywordValue+'",'
           +'"position":""'
           +'}';
           keywordsValues.push(keywordValue.toString())
        } else {
          if((JSON.parse(singleKeywordValue).duration && JSON.parse(singleKeywordValue).duration != "") || JSON.parse(singleKeywordValue).duration == 0) {
            durationJson = '{'
              +'"duration":"'+JSON.parse(singleKeywordValue).duration+'",'
              +'"durationType":"'+durationType+'"'
              +'}';
              var keywordValue = '"'+(i+1)+'":{'
              +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
              +'"value":'+durationJson+','
              +'"position":""'
              +'}';
              keywordsValues.push(keywordValue.toString())
          } else if (!JSON.parse(singleKeywordValue).hasOwnProperty("duration") && singleKeywordValue != "") {
            durationJson = '{'
              +'"duration":'+singleKeywordValue+','
              +'"durationType":"'+durationType+'"'
              +'}';
              var keywordValue = '"'+(i+1)+'":{'
              +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
              +'"value":'+durationJson+','
              +'"position":""'
              +'}';
              keywordsValues.push(keywordValue.toString())
          }
        }
      }
    }

    let docatwebKeywordIndex = i;
    let clientKeywordIndex = 0
    for (let j = 0; j < extraKeywordsWithValues.length; j++) {
      let keywordValueElement = extraKeywordsWithValues[j].keywordValue
      if(extraKeywordsWithValues[j].isdate === true && extraKeywordsWithValues[j].keywordValue && !extraKeywordsWithValues[j].keywordValue.includes("/")) {
        keywordValueElement = Moment(extraKeywordsWithValues[j].keywordValue).format('DD/MM/YYYY')
      }
        if(extraKeywordsWithValues[j].type === "docAtWebKeyword") {
          var keywordValue = '"'+(docatwebKeywordIndex+1)+'":{'
           +'"id":"'+extraKeywordsWithValues[j].value+'",'
           +'"value":"'+(keywordValueElement).split("'").join(' ')+'",'
           +'"position":""'
           +'}';
           keywordsValues.push(keywordValue.toString())
           docatwebKeywordIndex++
        } else {
          var clientKeyword = '"'+(clientKeywordIndex+1)+'":{'
           +'"id":"'+extraKeywordsWithValues[j].value+'",'
           +'"value":"'+(keywordValueElement ? (keywordValueElement).split("'").join(' '):"")+'",'
           +'"position":""'
           +'}';
           clientKeywordsValues.push(clientKeyword)
           clientKeywordIndex++
        }
    }

    if (keywordsValues.length) {
      formData.append('keywords[docAtWebKeywords]', '{'+keywordsValues.toString()+'}');
    }

    if (clientKeywordsValues.length) {
      formData.append('keywords[clientKeywords]', '{'+clientKeywordsValues.toString()+'}');
    }

    formData.append('htmlValidationContent', pdfValidationContent);

    formData.append('updateContractDate', updateContractDate ? 1:0)

    return axios.post(requestUrl, formData);
  };

  export const validateEndorsement = (contract, signatory1, signatory2, amount, contractDuration, priorNoticeDuration, negociationDuration,
                                      endingDate, negociationBeginingDate, contractDurationType, negociationDurationType, priorNoticeDurationType,
                                      priorNoticeDate, extraKeywordsWithValues, pdfValidationContent, beginningDate, objet, contractCode, renewal,
                                      dueAmount, dueFrequency, dueFrequencyType, totalAmoutInitialPeriod, amountType, amountTerm, contractAlerts, updateContractDate, wedge, sign=false, allGuests='', canDuplicateName=false, canDuplicateNumber=false, changesAcceptedKeywords=[] ) => {
    const requestUrl = API_URL+`/contract/validateEndorsement?access_token=`+getConnectedUserAccessToken();
    var keywordsValues = [];
    var docAtWebKeywordsValues = [];
    var clientKeywordsValues = [];
    var i;
    var formData = new FormData();

    if ((contract.inNegociationBy && sign.toString() === 'true') || sign.toString() === 'true') {
      formData.append('sign', 1);
    }

    if (canDuplicateName && canDuplicateName.toString() !== '') {
      if (canDuplicateName.toString() === 'true') {
        formData.append('canDuplicateName', 1);
      }
      if (canDuplicateName.toString() === 'false') {
        formData.append('canDuplicateName', 0);
      }
    }
    if (canDuplicateNumber && canDuplicateNumber.toString() !== '') {
      if (canDuplicateNumber.toString() === 'true') {
        formData.append('canDuplicateNumber', 1);
      }
      if (canDuplicateNumber.toString() === 'false') {
        formData.append('canDuplicateNumber', 0);
      }
    }

    if (allGuests && allGuests.toString() !== '') {
      if (allGuests.toString() === 'true') {
        formData.append('allGuests', 1);
      }
      if (allGuests .toString() === 'false') {
        formData.append('allGuests', 0);
      }
    }

    formData.append('contract', contract.id);
    if (contract.name) {
      formData.append('name', contract.name);
    }

    if (contract.endorsementNumber) {
      formData.append('endorsementNumber', contract.endorsementNumber);
    }
    if (contract.endorsementBeginningDate) {
      formData.append('endorsementBeginningDate', contract.endorsementBeginningDate);
    }

    for (i = 0; i < contract.keywords.docAtWebKeywords.length; i++) {
      var singleKeywordValue = null;
      var durationType = null;
      var durationJson = null;
      if (contract.keywords.docAtWebKeywords[i].name == "Nom Signataire Fournisseur" && signatory1 && signatory1 != "") {
        singleKeywordValue = signatory1.split("'").join(' ')
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Nom Signataire interne" && signatory2 && signatory2 != "") {
        singleKeywordValue = signatory2.split("'").join(' ')
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Durée" && contractDuration) {
        singleKeywordValue = JSON.stringify(contractDuration)
        durationType = contractDurationType
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Préavis") {
        if(priorNoticeDuration == 0) {
          singleKeywordValue = JSON.stringify(0)
          durationType = priorNoticeDurationType
        }
        if(priorNoticeDuration) {
          singleKeywordValue = JSON.stringify(priorNoticeDuration)
          durationType = priorNoticeDurationType
        }
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Négociation" && negociationDuration) {
        singleKeywordValue = JSON.stringify(negociationDuration)
        durationType = negociationDurationType
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Fréquence échéances" && dueFrequency) {
        singleKeywordValue = JSON.stringify(dueFrequency)
        durationType = dueFrequencyType
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Date de fin" && endingDate && endingDate != "") {
        singleKeywordValue = endingDate
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Date de début" && beginningDate && beginningDate != "") {
        singleKeywordValue = beginningDate
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Objet" && objet && objet != "") {
        singleKeywordValue = objet
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Numéro du contrat" && contractCode && contractCode != "") {
        singleKeywordValue = contractCode
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Renouvellement" /*&& renewal && renewal != ""*/) {
        singleKeywordValue = renewal
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Montant échéances" && dueAmount && dueAmount != "") {
        singleKeywordValue = dueAmount
      }
      if (contract.keywords.docAtWebKeywords[i].name == "Montant Global du Contrat" && amount && amount != "") {
        singleKeywordValue = amount
      }

      if (contract.keywords.docAtWebKeywords[i].name == "negociationBeginingDate" && negociationBeginingDate && negociationBeginingDate != "") {
        singleKeywordValue = negociationBeginingDate
      }

      if (contract.keywords.docAtWebKeywords[i].name == "priorNoticeDate" && priorNoticeDate && priorNoticeDate != "") {
        singleKeywordValue = priorNoticeDate
      }

      if (contract.keywords.docAtWebKeywords[i].name === "Frais Installation/FAS") {
        durationJson = '{'
            +'"amount":"'+(typeof totalAmoutInitialPeriod === "object" ? totalAmoutInitialPeriod["amount"]:totalAmoutInitialPeriod)+'",'
            +'"type":"'+amountType+'",'
            +'"term":"'+amountTerm+'",'
            +'"wedge":"'+wedge+'"'
            +'}';
        var keywordValue = '"'+(i+1)+'":{'
            +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
            +'"value":'+durationJson+','
            +'"position":""'
            +'}';
        keywordsValues.push(keywordValue.toString())
      }

      if (singleKeywordValue && singleKeywordValue != "") {
        if(contract.keywords.docAtWebKeywords[i].name !== "Préavis" && contract.keywords.docAtWebKeywords[i].name !== "Négociation" && contract.keywords.docAtWebKeywords[i].name !== "Durée" && contract.keywords.docAtWebKeywords[i].name !== "Fréquence échéances" ) {
          var keywordValue = '"'+(i+1)+'":{'
           +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
           +'"value":"'+singleKeywordValue+'",'
           +'"position":""'
           +'}';
           keywordsValues.push(keywordValue.toString())
        } else {
          if((JSON.parse(singleKeywordValue).duration && JSON.parse(singleKeywordValue).duration != "") || JSON.parse(singleKeywordValue).duration == 0) {
            durationJson = '{'
              +'"duration":"'+JSON.parse(singleKeywordValue).duration+'",'
              +'"durationType":"'+durationType+'"'
              +'}';
              var keywordValue = '"'+(i+1)+'":{'
              +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
              +'"value":'+durationJson+','
              +'"position":""'
              +'}';
              keywordsValues.push(keywordValue.toString())
          } else if (!JSON.parse(singleKeywordValue).hasOwnProperty("duration") && singleKeywordValue != "") {
            durationJson = '{'
              +'"duration":'+singleKeywordValue+','
              +'"durationType":"'+durationType+'"'
              +'}';
              var keywordValue = '"'+(i+1)+'":{'
              +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
              +'"value":'+durationJson+','
              +'"position":""'
              +'}';
              keywordsValues.push(keywordValue.toString())
          }
        }
      }
    }

    let docatwebKeywordIndex = i;
    let clientKeywordIndex = 0
    for (let j = 0; j < extraKeywordsWithValues.length; j++) {
      let keywordValueElement = extraKeywordsWithValues[j].keywordValue
      if(extraKeywordsWithValues[j].isdate === true && extraKeywordsWithValues[j].keywordValue && !extraKeywordsWithValues[j].keywordValue.includes("/")) {
        keywordValueElement = Moment(extraKeywordsWithValues[j].keywordValue).format('DD/MM/YYYY')
      }
        if(extraKeywordsWithValues[j].type === "docAtWebKeyword") {
          var keywordValue = '"'+(docatwebKeywordIndex+1)+'":{'
           +'"id":"'+extraKeywordsWithValues[j].value+'",'
           +'"value":"'+(keywordValueElement).split("'").join(' ')+'",'
           +'"position":""'
           +'}';
           keywordsValues.push(keywordValue.toString())
           docatwebKeywordIndex++
        } else {
          var clientKeyword = '"'+(clientKeywordIndex+1)+'":{'
           +'"id":"'+extraKeywordsWithValues[j].value+'",'
           +'"value":"'+(keywordValueElement).split("'").join(' ')+'",'
           +'"position":""'
           +'}';
           clientKeywordsValues.push(clientKeyword)
           clientKeywordIndex++
        }
    }

    formData.append('htmlValidationContent', pdfValidationContent);

    if (keywordsValues.length) {
      formData.append('keywords[docAtWebKeywords]', '{'+keywordsValues.toString()+'}');
    }

    if (clientKeywordsValues.length) {
      formData.append('keywords[clientKeywords]', '{'+clientKeywordsValues.toString()+'}');
    }
    formData.append('alerts[negociation]', contractAlerts.negciationAlert);
    formData.append('alerts[priorNotice]', contractAlerts.priorNoticeAlert);
    formData.append('alerts[ending]', contractAlerts.endingAlert);
    formData.append('alerts[isValidated]', 1);
    console.log("changesAcceptedKeywords=======>",changesAcceptedKeywords)
    if(changesAcceptedKeywords && changesAcceptedKeywords.length > 0){
      let changedKeywords = changesAcceptedKeywords.filter(x=>x.changed === true)
      if(changedKeywords && changedKeywords.length>0){
        changedKeywords = changedKeywords.map(nego => {
          return {
            negociation: nego.negociation,
            value: nego.value
          };
        });
        console.log("changedKeywords=======>",changedKeywords)
        for (let index = 0; index < changedKeywords.length; index++) {
          const element = changedKeywords[index];
          formData.append('changes['+ index +']', JSON.stringify(element));
        }
      }
    }


    // Display FormData entries in the console
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }
    formData.append('updateContractDate', updateContractDate ? 1:0);
    // console.log('test', '{'+keywordsValues.toString()+'}')
    // console.log('test client', '{'+clientKeywordsValues.toString()+'}')
    return axios.post(requestUrl, formData);
  };

  export const addContractToTheRecentsList = (contractID) => {
    const requestUrl = API_URL+`/contract/addContractToTheRecentsList/`+contractID+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

  export const updateEndorsementRestriction = (keywordId, isRestricted) => {
    const requestUrl = API_URL + `/contractKeyword/updateRestriction?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('id', keywordId);
    if (isRestricted)
      formData.append('restrict', 1);
    else
      formData.append('restrict', 0);
    return axios.post(requestUrl, formData);
  };