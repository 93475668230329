import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"
var followRedirects = require('follow-redirects');
followRedirects.maxBodyLength = 100 * 1024 * 1024; // 100 MB

export const importContract = (file, category) => {
    const requestUrl = API_URL+`/contract/oserizeContract?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('client', getCurrentClientID());
    formData.append('contractFile', file);
    formData.append('category', category);
    return axios({
      method: 'post',
      url: requestUrl,
      data: formData,
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
      timeout: Infinity,
  });
  };

export const importContracts = (files, category) => {
    const requestUrl = API_URL+`/contract/oserizeContracts?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('client', getCurrentClientID());
    files.forEach((file,index) => {
      formData.append('contractFiles['+index+']', file);
    });
    formData.append('category', category);
    return axios({
      method: 'post',
      url: requestUrl,
      data: formData,
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
      timeout: Infinity,
  });
  };

export const importMixedContracts = (freeFiles, sectionsFiles, category) => {
    const requestUrl = API_URL + `/contract/ocerizeGroupedContracts?access_token=` + getConnectedUserAccessToken();
    let files = [];
    let indexFreeFiles = sectionsFiles ? sectionsFiles.length-1 : -1
    let highestSectiontId = sectionsFiles.length> 0 ? Math.max(...sectionsFiles.map(section => section.sectionId)):0;
    var formData = new FormData();
    formData.append('client', getCurrentClientID());
    formData.append('category', category);
    if(sectionsFiles && sectionsFiles.length>0){
      sectionsFiles.forEach((section, index) => {
        formData.append('contracts' + '[' + index + ']', JSON.stringify({ "contractName": section.name, "listName": section.sectionId + "_list" }));
        if (section.files && section.files.length > 0) {
          section.files.forEach(doc => {
            files.push(doc);
          });
        }
      });
  
      let indexFile = 0;
      console.log(files)
      files.forEach((file, index) => {
  
        if (files[index - 1] && files[index - 1].sectionId !== undefined && file.sectionId !== files[index - 1].sectionId) {
          indexFile = 0
        } else {
          if(index > 0 && indexFile >= 0)
          indexFile++
        }
        formData.append(file.sectionId + '_list' + '[' + indexFile + ']', file.file);
      });
    }

    freeFiles.forEach((file, index) => {
      indexFreeFiles++
      highestSectiontId++
      formData.append('contracts' + '[' + indexFreeFiles + ']', JSON.stringify({ "contractName": file["file"].name, "listName": highestSectiontId+"_list" }));
      formData.append(highestSectiontId + '_list' + '[0]', file.file);

    });

      // console.log("Sent Data \n\n\n")
      // // Display FormData entries in the console
      // for (let [key, value] of formData.entries()) {
      //   console.log(key, value);
      // }

      return axios({
        method: 'post',
        url: requestUrl,
        data: formData,
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        timeout: Infinity,
    });
};

  export const getCategoriesByImportRightForUser = () => {
    const requestUrl = API_URL+`/category/getCategoriesForContractValidation?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('client', getCurrentClientID());
    return axios.post(requestUrl, formData);
  };

  export const getRecentUploadedContractsByUser = () => {
    const requestUrl = API_URL+`/user/getUploadedFilesByUserByClient/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl)
  };