import React from "react";
const ImportIcon = () => (
  <svg
    width="28"
    height="25"
    viewBox="0 0 28 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0009 12.2751V23.7353C14.0009 24.1414 13.6717 24.4706 13.2656 24.4706C12.8595 24.4706 12.5303 24.1414 12.5303 23.7353V12.2751L9.37379 15.4317C9.08664 15.7188 8.62108 15.7188 8.33393 15.4317C8.04678 15.1445 8.04678 14.679 8.33393 14.3918L12.7457 9.98005C13.0328 9.6929 13.4984 9.6929 13.7856 9.98005L18.1973 14.3918C18.4845 14.679 18.4845 15.1445 18.1973 15.4317C17.9102 15.7188 17.4446 15.7188 17.1575 15.4317L14.0009 12.2751ZM19.5067 5.35293H20.6186C24.2734 5.35293 27.2362 8.31575 27.2362 11.9706C27.2362 15.6254 24.2734 18.5882 20.6186 18.5882C20.2125 18.5882 19.8833 18.259 19.8833 17.8529C19.8833 17.4468 20.2125 17.1176 20.6186 17.1176C23.4612 17.1176 25.7656 14.8132 25.7656 11.9706C25.7656 9.12793 23.4612 6.82351 20.6186 6.82351H19.8429C19.8696 7.06493 19.8833 7.31027 19.8833 7.55881C19.8833 7.9649 19.5541 8.2941 19.148 8.2941C18.7419 8.2941 18.4127 7.9649 18.4127 7.55881C18.4127 4.71617 16.1083 2.41175 13.2656 2.41175H12.5303C9.2816 2.41175 6.64798 5.04537 6.64798 8.2941V9.02939C6.64798 9.43549 6.31878 9.76469 5.91268 9.76469C3.88223 9.76469 2.23621 11.4107 2.23621 13.4412C2.23621 15.4716 3.88223 17.1176 5.91268 17.1176C6.31878 17.1176 6.64798 17.4468 6.64798 17.8529C6.64798 18.259 6.31878 18.5882 5.91268 18.5882C3.07004 18.5882 0.765625 16.2838 0.765625 13.4412C0.765625 10.8482 2.68306 8.70301 5.17739 8.34622C5.17739 4.23318 8.46941 0.941162 12.5303 0.941162H13.2656C16.147 0.941162 18.5983 2.78265 19.5067 5.35293Z"
      fill="#ffffff"
      stroke="#ffffff"
    />
  </svg>
);

export default ImportIcon
