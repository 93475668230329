import React from 'react';
import './style.scss';
import LoaderComponent from '../../../../../../components/loader/Loader';
import ChartBar from './ChartBar';
import ChartBarHorizontal from './ChartBarHorizontal';
import ChartDonut from './ChartDonut';
import ChartLine from './ChartLine';
import ChartPie3d from './ChartPie3d';
import colorsListFromJson from "./colors.json";
import Gradient from "javascript-color-gradient";
const unities = {
  "calendar": " Jrs",
  "activity": " Heure",
  "euro": '€',
  'k-euro': 'K€',
  'm-euro': 'M€',
  percent: '%',
  qty: '',
};

const ChartPreview = ({
  type,
  title,
  data,
  loadingData,
  chartData,
  averageTime,
  displayValues,
  displayLegend,
  isCalendar,
  unity,
  colorsList
}) => {

 

  const hoursToHoursMinutes = (decimalHours) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours % 1) * 60);

    // Use padStart to ensure that the hours and minutes always have two digits
    const formattedTime = `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m`;

    return formattedTime;
  }

  const getValue = (value, displayUnity) => {
    let _unity = unity ? unities[unity] : '';
    const formatNumber = (number, divider) =>
      number === 0 ? 0 : Math.floor(parseFloat(number / divider) * 100);

    const formatToFixedNumber = (number) =>
      number ? (number === 0 ? 0 : parseFloat(number)):0;
      buildColors()

    switch (unity) {
      case 'calendar':
        return displayUnity ? `${formatToFixedNumber(value)}${_unity}` : value;
      case 'activity':
        return displayUnity ? `${hoursToHoursMinutes(value)}` : value;
      case 'euro':
        return displayUnity ? `${formatToFixedNumber(value)}${_unity}` : value;
      case 'k-euro':
        return displayUnity ? `${formatNumber(value, 1000)}${_unity}` : formatNumber(value, 1000);
      case 'm-euro':
        return displayUnity
          ? `${formatNumber(value, 1000000)}${_unity}`
          : formatNumber(value, 1000000);
      case 'percent':
        return displayUnity ? `${formatToFixedNumber(value)}${_unity}` : value;
      case 'qty':
        return value;
      default:
        return value;
    }
  };

  const getTotalPeriod = (_data) =>
    _data.reduce((accumulator, object) => {
      return (
        accumulator +
        object.cryptedData.reduce((accumulator, object) => {
          return (
            accumulator +
            object.values.reduce((accumulator, object) => {
              return accumulator + object.amount;
            }, 0)
          );
        }, 0)
      );
    }, 0);

  const getTotalItemPeriod = (periodItem) =>
    periodItem.cryptedData.reduce((accumulator, object) => {
      return (
        accumulator +
        object.values.reduce((accumulator, object) => {
          return accumulator + object.amount;
        }, 0)
      );
    }, 0);

  const isMltipleCryptedData = (_data) => {
    if (_data.length === 0) return false;
    let cryptedData = _data[0].cryptedData.filter((itm) => itm.values.length > 0);
    if (cryptedData.length === 0) return false;
    let values = cryptedData[0].values;

    if (cryptedData.length > 1) return true;
    if (values.length > 1) {
      let field = chartData?.find((itm) => itm.name === cryptedData[0].name);
      if (field?.group) return false;
      else return true;
    }
    return false;
  };

  const calculatePercent = (amount, total) => {
    return parseFloat(((amount * 100) / total).toFixed(2));
  };

  const calcGroupedData = ((databuyers, totalContractLength) => {
    let sumActivity = databuyers.reduce((sum, contract) => sum + contract.assignments.reduce((acc, assignment) => acc + (assignment.activity ? parseFloat(assignment.activity) : 0), 0), 0);
    let sumCalendar = databuyers.reduce((sum, contract) => sum + contract.assignments.reduce((acc, assignment) => acc + assignment.calendar, 0), 0);

    sumCalendar = sumCalendar/86400
    sumActivity = sumActivity/3600
    
    if(averageTime && totalContractLength){
      sumCalendar = sumCalendar/totalContractLength > 0 ? totalContractLength : 1
      sumActivity = sumActivity/totalContractLength > 0 ? totalContractLength : 1

    }
    return  isCalendar ? sumCalendar : sumActivity;
  });

  const buildData = () => {
    let _data = [];
    if (Array.isArray(data) && data.length > 0) {
      /// set bars names
      _data.push([
        '',
        ...[].concat(
          [],
          ...data[0].cryptedData
            .filter((itm) => itm.values.length > 0)
            .map((item) => {
              let field = chartData?.find((itm) => itm.name === item.name);
              if (field?.group) return [item.isRGPD ? "RGPD":item.name];
              else return item.values.map((val) => val.isRGPD ? "RGPD" : val.name);
            })
        ),
      ]);

      let toalPeriod = getTotalPeriod(data);
      let multipleCryptedData = isMltipleCryptedData(data);
      //console.log('multipleCryptedData', multipleCryptedData);
      //console.log('data', data);
      
      data.forEach((dashbordItem) => {
        let toalPeriodItem = getTotalItemPeriod(dashbordItem);
        //console.log('dashbordItem', dashbordItem);
        //console.log('unity', unity);
        _data.push([
          dashbordItem.isRGPD ? "RGPD": dashbordItem.scale,
          ...[].concat(
            [],
            ...dashbordItem.cryptedData
              .filter((itm) => itm.values.length > 0)
              .map((item) => {
                let field = chartData?.find((itm) => itm.name === item.name);
                //console.log("item===================>>>>>>>>>>>",item)
                //console.log("field===================>>>>>>>>>>>",field)
                
                if (field?.group) {
                  //console.log(">>>>>>>>>>>>>>>>>>>>>>>>Here we go<<<<<<<<<<<<<<<<<<<<<<<",item)
                  const totalContractLength = item.values.reduce((sum, buyer) => sum + buyer.contracts.length, 0);
                  return [
                    item.values.reduce((accumulator, object) => {
                      return accumulator + calcGroupedData(object.databuyers,totalContractLength);
                    }, 0),
                  ];
                 
                
                } else {
                  return item.values.map((val) => {
                    if (unity === 'qty') return val.count;
                    let sumActivity = val["databuyers"].reduce((sum, contract) => sum + contract.assignments.reduce((acc, assignment) => acc + (assignment.activity ? parseFloat(assignment.activity) : 0), 0), 0);
                    let sumCalendar = val["databuyers"].reduce((sum, contract) => sum + contract.assignments.reduce((acc, assignment) => acc + (assignment.calendar ? parseFloat(assignment.calendar) : 0), 0), 0);

                    sumCalendar = sumCalendar/86400
                    sumActivity = sumActivity/3600
                    //console.log("Sum of Activity:", sumActivity);
                    // console.log("Sum of Calendar:", sumCalendar);
                    if(averageTime){
                      sumCalendar = sumCalendar/(val["contracts"] && val["contracts"].length > 0 ? val["contracts"].length : 1)
                      sumActivity = sumActivity/(val["contracts"] && val["contracts"].length > 0 ? val["contracts"].length : 1)

                    }
                    return  isCalendar ? Math.floor(parseFloat(sumCalendar) * 100) / 100 : Math.floor(parseFloat(sumActivity) * 100) / 100;
                  });
                }
              })
          ),
        ]);
      });
    }

    const formatedData = [];
    _data.forEach((row, index) => {
      row.forEach((col, key) => {
        if (key === 0) {
          formatedData.push([col]);
        } else {
          if (index === 0) {
            formatedData[index].push(col);
            if (displayValues) {
              formatedData[index].push({ role: 'annotation' });
            }
          } else {
            formatedData[index].push(getValue(col, false));
            if (displayValues) {
              formatedData[index].push(getValue(col, true));
            }
          }
        }
      });
    });
    return formatedData;
  };

  const buildColors = () => {
    let colors = [];
    if(colorsList !== undefined){
      if (Array.isArray(chartData) && chartData.length > 0) {
        chartData.forEach((d, index)=>{
            colors.push(getGradienBuilderColor(colorsList.find(x => x.key && x.key == d.key && x.name == d.name) ? colorsList.find(x => x.key && x.key == d.key && x.name == d.name).color:'orangered' || null,d.group ? 1:d.data.filter(x=>x.selected).length))
        })
      }
    }else{
      let _colors = colorsListFromJson[1];
      if (Array.isArray(chartData) && chartData.length > 0) {
        chartData.forEach((d, index)=>{
            colors.push(getGradienBuilderColor(d.color && d.color !== "" ? d.color : _colors[index],d.group ? 1:d.data.filter(x=>x.selected).length))
        })
      }
    }
    return colors || []
  }

  const getGradienBuilderColor = (color,entries_count) => {

    let colorArr = new Gradient()
      .setColorGradient(color, "#FFFFFF")
      .setMidpoint(entries_count)
      .getColors();

    colorArr.unshift(color);
    colorArr.splice(-1)
    return colorArr;

  }

  // const getOriginalData = (data) => {
  //   if (Array.isArray(data) && data.length > 0) {
  //     return data[0].cryptedData.map(x=>x.values)
  //   }else{
  //     return null
  //   }
  // }

  // const originalData = getOriginalData(data);

  const getProps = () => {
    return {
      title: title,
      data: buildData(),
      displayValues: displayValues,
      displayLegend: displayLegend,
      colors: buildColors()
    };
  };

  const getTotalDisputedAmount = (myMap) => {
    return Array.from(myMap.values())
    .filter(item => item.isDisputed)
    .reduce((total, item) => total + item.amount, 0);
  };

  return (
    <>
    <div className="chart-preview">
      {loadingData ? (
        <Loader />
      ) : (
        <>
          {!!data && (
            <>
              {(() => {
                switch (type) {
                  case 1:
                    return <ChartBar {...getProps()} />;
                  case 2:
                    return <ChartLine {...getProps()} />;
                  case 3:
                    return <ChartBarHorizontal {...getProps()} />;
                  case 4:
                    return <ChartDonut {...getProps()} />;
                  case 5:
                    return <ChartPie3d {...getProps()} />;

                  default:
                    return null;
                }
              })()}
            </>
          )}
        </>
      )}
    </div>
      {/* {
        displayDetailAmount && originalData && buildColors() && buildColors().length > 0 &&
        <>
          <p className="title-details-amount">Détails des litiges</p>
          <div className="row">
            {originalData[0].map((d, index) => {
              return (
                <div className="col-12">
                  <p className="bloc-details-amount">
                    <span className="key-legend" style={{ backgroundColor: buildColors()[0][index] }}></span>
                    <strong className="name-legend">{d.name}</strong>
                    {d.contracts && (d.contracts.length < 1 || (d.contracts.length > 0 && getTotalDisputedAmount(d.contracts) <= 0)) &&
                      <>
                        <small className='no-disputed-tag'>pas en litige</small>
                      </>
                    }
                    {d.contracts && d.contracts.length > 0 && getTotalDisputedAmount(d.contracts) > 0 &&
                      <>
                        <strong>
                          {" " + getTotalDisputedAmount(d.contracts)}
                          {"€ "}
                        </strong>
                        <small className='disputed-tag'>en litige</small>
                      </>
                    }
                  </p>
                </div>
              )
            })
            }

          </div>

        </>
      } */}
    </>
  );
};

const Loader = () => <div className="loading-container">{LoaderComponent.getLoader()}</div>;

export default ChartPreview;
