import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"

export const getClientKeywords = () => {
    const requestUrl = API_URL+`/clientKeyword/getClientKeywordByClient/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

export const createClientKeyword = (clientKeywordForm) => {
    console.log('clientKeywordForm', clientKeywordForm)
    var index;
    var addedSynonym = false;
    const requestUrl = API_URL+`/clientKeyword?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    if(clientKeywordForm.id) {
        formData.append('id', clientKeywordForm.id);
    }
    formData.append('name', clientKeywordForm.name);
    formData.append('type', clientKeywordForm.type);
    if (clientKeywordForm.comment) {
        formData.append('comment', clientKeywordForm.comment);
    }
    
    if (clientKeywordForm.clauseType) {
        formData.append('clauseType', clientKeywordForm.clauseType);
    }

    if (clientKeywordForm.isObligatory) {
        formData.append('isObligatory', 1);
    } else {
        formData.append('isObligatory', 0);
    }

    if (clientKeywordForm.isDate) {
        formData.append('isDate', 1);
    }else {
        formData.append('isDate', 0);
    }

    if (clientKeywordForm.isFirstPage) {
        formData.append('isFirstPage', 1);
    }else {
        formData.append('isFirstPage', 0);
    }

    if (clientKeywordForm.isLinkedToPricePrevision) {
        formData.append('isLinkedToPricePrevision', 1);
    }else {
        formData.append('isLinkedToPricePrevision', 0);
    }

    if (clientKeywordForm?.system) {
        formData.append('system', clientKeywordForm.system);
    }
    formData.append('client', getCurrentClientID());
    if (clientKeywordForm.synonyms) {
        if(clientKeywordForm.synonyms && clientKeywordForm.synonyms.length) {
            for (index = 0; index < clientKeywordForm.synonyms.length; ++index) {
                if(!clientKeywordForm.synonyms[index].unselected || clientKeywordForm.synonyms[index].unselected == false ) {
                    formData.append('synonyms['+ index +']', JSON.stringify({label: clientKeywordForm.synonyms[index].label, priority: clientKeywordForm.synonyms[index].priority}));
                    addedSynonym = true;
                }
            }
        }
        if (addedSynonym == false) {
            formData.append('synonyms', []);
          }
    }
    let addedCategory = false;
    if (clientKeywordForm.selectedCategories) {
        if(clientKeywordForm.selectedCategories && clientKeywordForm.selectedCategories.length) {
            for (index = 0; index < clientKeywordForm.selectedCategories.length; ++index) {
                if(!clientKeywordForm.selectedCategories[index].unselected || clientKeywordForm.selectedCategories[index].unselected == false ) {
                    formData.append('categories['+ index +']', clientKeywordForm.selectedCategories[index].value);
                    addedCategory = true;
                }
            }
        }
        if (addedCategory == false) {
            formData.append('categories', []);
        }

    }
    // Display FormData entries in the console
    // for (let [key, value] of formData.entries()) {
    //     console.log(key, value);
    // }
    return axios.post(requestUrl, formData);
}

export const checkDeleteUnlinkedClientKeyword = (keywordID) => {
    const requestUrl = API_URL+`/deleteOrDisable/`+keywordID+`?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    if(keywordID) {
        formData.append('action', 'CHECK');
    }
    return axios.post(requestUrl, formData);
  };

export const disableClientKeyword = (keywordID) => {
    const requestUrl = API_URL+`/deleteOrDisable/`+keywordID+`?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    if(keywordID) {
        formData.append('action', 'DISABLE');
    }
    return axios.post(requestUrl, formData);
  };

export const forceDeleteLinkedClientKeyword = (keywordID) => {
    const requestUrl = API_URL+`/deleteOrDisable/`+keywordID+`?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    if(keywordID) {
        formData.append('action', 'DELETE');
    }
    return axios.post(requestUrl, formData);
  };

export const deleteClientKeyword = (keywordID) => {
    const requestUrl = API_URL+`/clientKeyword/`+keywordID+`?access_token=`+getConnectedUserAccessToken();
    return axios.delete(requestUrl);
  };


  export const exportClientKeywords = () => {
    const requestUrl = API_URL+`/clientKeyword/export/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

  export const updateClientKeywordTypes = (types) => {
    const requestUrl = API_URL+`/client/updateKeywordTypes/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('types', JSON.stringify(types.map(x=>x.type)));
    // for (let index = 0; index < types.length; index++) {
    //         formData.append('types['+ index +']', JSON.stringify(types[index]));
    // }
    return axios.post(requestUrl, formData);
  };