import React from "react";
const TagIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.33224C1 2.4919 2.4919 1 4.33224 1H12.3296C12.5064 1 12.6759 1.07022 12.8009 1.1952L23.8678 12.2622C25.3774 13.7717 25.3774 16.2191 23.8678 17.7287L17.7287 23.8678C16.2191 25.3774 13.7717 25.3774 12.2622 23.8678L1.1952 12.8009C1.07022 12.6759 1 12.5064 1 12.3296V4.33224ZM12.0536 2.3329H4.33224C3.22803 2.3329 2.3329 3.22803 2.3329 4.33224V12.0536L13.2047 22.9253C14.1937 23.9144 15.7972 23.9144 16.7862 22.9253L22.9253 16.7862C23.9144 15.7972 23.9144 14.1937 22.9253 13.2047L12.0536 2.3329ZM7.66449 6.33159C6.92835 6.33159 6.33159 6.92835 6.33159 7.66449C6.33159 8.40063 6.92835 8.99738 7.66449 8.99738C8.40063 8.99738 8.99738 8.40063 8.99738 7.66449C8.99738 6.92835 8.40063 6.33159 7.66449 6.33159ZM7.66449 4.99869C9.13676 4.99869 10.3303 6.19221 10.3303 7.66449C10.3303 9.13676 9.13676 10.3303 7.66449 10.3303C6.19221 10.3303 4.99869 9.13676 4.99869 7.66449C4.99869 6.19221 6.19221 4.99869 7.66449 4.99869Z"
      fill="white"
      stroke="white"
    />
  </svg>
);

export default TagIcon;
