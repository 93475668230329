import React, { Component } from 'react';
import { BrowserRouter as Router,  Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import './FoManageEndorsement.scss';
import ErrorAlert from "../../confirm-alert/error-alert";

class BoEndorsementSettings extends Component {

    constructor (props) {
        super(props)
        this.state = {
            activeMenu: props.activeMenu,
            contractId: null
        }
    }

    componentDidMount() {
        console.log('this.props.contractStatus', this.props.contractStatus)
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        if(parsed.contract) {
            this.setState({
                ...this,
                contractId: parsed.contract,
            })
        } else if (!parsed.contract && !parsed.parentContract) {
            ErrorAlert.show("Vous devez fournir l'identifiant du contrat", "Attention");
            setTimeout(() => {
                this.props.history.push("/");
             }, 3000);
        }
    }

    handleClick = (bloc) => {
        const { onClick } = this.props;
        onClick(bloc);
      }

    render() {
        return (
            <ul className="FoManageEndorsement">
                <li>
                    {/*<Link to={"/manage-contract/validate?contract="+this.state.contractId}> //TOSEE*/}
                    <Link to="/home">
                    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.26953 12.2204L13.9998 1.1875L26.7301 12.2204" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M23.3352 7.12828V3.3092H19.5161" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.66406 12.7156V25.375H10.6049V16.8881H17.3943V25.375H23.3351V12.6799" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </Link>
                </li>
                {this.props.isFromValidationForm && 
                <li className={`${this.props.activeMenu == 'clauses' ? 'active' : ''}`}>
                    <a className={!this.props.editMode ? '' : ''} onClick={()=>this.handleClick(0)}>
                        <svg width="24" height="44" viewBox="0 0 118 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M94 93.2501V15.0625C94 10.2646 90.0825 6.37504 85.25 6.37504H22.4392C23.4319 8.0788 24 10.0569 24 12.1667V104.833C24 108.032 26.6117 110.625 29.8334 110.625C33.055 110.625 35.6667 108.032 35.6667 104.833V96.1459C35.6667 94.5466 36.9725 93.2501 38.5834 93.2501H94ZM99.8334 93.2501H114.417C116.028 93.2501 117.333 94.5466 117.333 96.1459V101.938C117.333 109.934 110.804 116.417 102.75 116.417H29.8334C23.39 116.417 18.1667 111.231 18.1667 104.833V23.75H12.3334C5.89003 23.75 0.666687 18.564 0.666687 12.1667C0.666687 5.76941 5.89003 0.583374 12.3334 0.583374H85.25C93.3042 0.583374 99.8334 7.06592 99.8334 15.0625V93.2501ZM41.5 99.0417V104.833C41.5 106.943 40.9319 108.921 39.9392 110.625H102.75C107.583 110.625 111.5 106.736 111.5 101.938V99.0417H41.5ZM18.1667 17.9584V12.1667C18.1667 8.96806 15.555 6.37504 12.3334 6.37504C9.11169 6.37504 6.50002 8.96806 6.50002 12.1667C6.50002 15.3654 9.11169 17.9584 12.3334 17.9584H18.1667ZM38.5834 29.5417C36.9725 29.5417 35.6667 28.2452 35.6667 26.6459C35.6667 25.0466 36.9725 23.75 38.5834 23.75H79.4167C81.0275 23.75 82.3334 25.0466 82.3334 26.6459C82.3334 28.2452 81.0275 29.5417 79.4167 29.5417H38.5834ZM38.5834 46.9167C36.9725 46.9167 35.6667 45.6202 35.6667 44.0209C35.6667 42.4216 36.9725 41.125 38.5834 41.125H79.4167C81.0275 41.125 82.3334 42.4216 82.3334 44.0209C82.3334 45.6202 81.0275 46.9167 79.4167 46.9167H38.5834ZM38.5834 64.2917C36.9725 64.2917 35.6667 62.9952 35.6667 61.3959C35.6667 59.7966 36.9725 58.5 38.5834 58.5H79.4167C81.0275 58.5 82.3334 59.7966 82.3334 61.3959C82.3334 62.9952 81.0275 64.2917 79.4167 64.2917H38.5834ZM38.5834 81.6667C36.9725 81.6667 35.6667 80.3702 35.6667 78.7709C35.6667 77.1716 36.9725 75.8751 38.5834 75.8751H67.75C69.3609 75.8751 70.6667 77.1716 70.6667 78.7709C70.6667 80.3702 69.3609 81.6667 67.75 81.6667H38.5834Z" fill={this.props.showBloc === 0 ? '#F66031' : '#5367F4'} />
                        </svg>
                        <span className="active-tab">Clauses</span>
                    </a>
                </li>}
                {!this.props.isFromValidationForm && 
                <li className={`${this.props.activeMenu == 'clauses' ? 'active' : ''}`}>
                    <Link className={!this.props.editMode ? '' : ''} to={"/manage-contract/endorsements?contract="+this.state.contractId}>
                        <svg width="24" height="44" viewBox="0 0 118 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M94 93.2501V15.0625C94 10.2646 90.0825 6.37504 85.25 6.37504H22.4392C23.4319 8.0788 24 10.0569 24 12.1667V104.833C24 108.032 26.6117 110.625 29.8334 110.625C33.055 110.625 35.6667 108.032 35.6667 104.833V96.1459C35.6667 94.5466 36.9725 93.2501 38.5834 93.2501H94ZM99.8334 93.2501H114.417C116.028 93.2501 117.333 94.5466 117.333 96.1459V101.938C117.333 109.934 110.804 116.417 102.75 116.417H29.8334C23.39 116.417 18.1667 111.231 18.1667 104.833V23.75H12.3334C5.89003 23.75 0.666687 18.564 0.666687 12.1667C0.666687 5.76941 5.89003 0.583374 12.3334 0.583374H85.25C93.3042 0.583374 99.8334 7.06592 99.8334 15.0625V93.2501ZM41.5 99.0417V104.833C41.5 106.943 40.9319 108.921 39.9392 110.625H102.75C107.583 110.625 111.5 106.736 111.5 101.938V99.0417H41.5ZM18.1667 17.9584V12.1667C18.1667 8.96806 15.555 6.37504 12.3334 6.37504C9.11169 6.37504 6.50002 8.96806 6.50002 12.1667C6.50002 15.3654 9.11169 17.9584 12.3334 17.9584H18.1667ZM38.5834 29.5417C36.9725 29.5417 35.6667 28.2452 35.6667 26.6459C35.6667 25.0466 36.9725 23.75 38.5834 23.75H79.4167C81.0275 23.75 82.3334 25.0466 82.3334 26.6459C82.3334 28.2452 81.0275 29.5417 79.4167 29.5417H38.5834ZM38.5834 46.9167C36.9725 46.9167 35.6667 45.6202 35.6667 44.0209C35.6667 42.4216 36.9725 41.125 38.5834 41.125H79.4167C81.0275 41.125 82.3334 42.4216 82.3334 44.0209C82.3334 45.6202 81.0275 46.9167 79.4167 46.9167H38.5834ZM38.5834 64.2917C36.9725 64.2917 35.6667 62.9952 35.6667 61.3959C35.6667 59.7966 36.9725 58.5 38.5834 58.5H79.4167C81.0275 58.5 82.3334 59.7966 82.3334 61.3959C82.3334 62.9952 81.0275 64.2917 79.4167 64.2917H38.5834ZM38.5834 81.6667C36.9725 81.6667 35.6667 80.3702 35.6667 78.7709C35.6667 77.1716 36.9725 75.8751 38.5834 75.8751H67.75C69.3609 75.8751 70.6667 77.1716 70.6667 78.7709C70.6667 80.3702 69.3609 81.6667 67.75 81.6667H38.5834Z" fill={this.props.showBloc === 0 ? '#F66031' : '#5367F4'} />
                        </svg>
                        <span className="active-tab">Clauses</span>
                    </Link>
                </li>}
                {this.props.isFromValidationForm && !this.props.inNegociationBy &&
                    <li className={`${this.props.activeMenu == 'signatories' ? 'active' : ''}`}>
                        <a className={!this.props.editMode && this.props.contractStatus < 2 ? 'disabled-link' : ''} onClick={() => this.handleClick(1)}>
                            <svg width="29" height="44" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="1.27132" y1="24.7448" x2="16.1966" y2="24.3173" stroke={this.props.showBloc === 1 ? '#F66031' : '#5367F4'} stroke-linecap="round" />
                                <line x1="0.5" y1="-0.5" x2="5.75095" y2="-0.5" transform="matrix(-0.39392 0.919145 -0.919145 -0.393919 2.46237 21.1086)" stroke={this.props.showBloc === 1 ? '#F66031' : '#5367F4'} stroke-linecap="round" />
                                <line x1="5.57036" y1="22.1628" x2="4.6903" y2="24.5096" stroke={this.props.showBloc === 1 ? '#F66031' : '#5367F4'} stroke-linecap="round" />
                                <line x1="8.82282" y1="21.7652" x2="6.75437" y2="26.5916" stroke={this.props.showBloc === 1 ? '#F66031' : '#5367F4'} stroke-linecap="round" />
                                <path d="M18.8171 21.479L15.8741 18.4844C14.7034 19.8805 14.0149 21.6322 13.9186 23.4688C15.7235 23.3709 17.445 22.6702 18.8171 21.479ZM16.7747 17.531L19.754 20.5626L34.4342 5.62483C34.7894 5.2634 34.7894 4.67739 34.4342 4.31595L32.7412 2.59324C32.386 2.23181 31.8101 2.23181 31.4549 2.59324L16.7747 17.531ZM27.9296 4.31055C26.9614 3.39569 25.4473 3.41759 24.5052 4.37622L20.2134 8.74327C19.9597 9.00144 19.5484 9.00144 19.2946 8.74327C19.0409 8.4851 19.0409 8.06652 19.2946 7.80835L23.5864 3.44131C25.036 1.9663 27.3728 1.94426 28.8488 3.37521L30.5361 1.65833C31.3988 0.780556 32.7974 0.780556 33.66 1.65833L35.353 3.38104C36.2157 4.25882 36.2157 5.68197 35.353 6.55975L20.2134 21.9649C18.4276 23.7821 16.0056 24.8029 13.4801 24.8029H13.2572C12.8984 24.8029 12.6075 24.5069 12.6075 24.1418V23.915C12.6075 21.3452 13.6107 18.8806 15.3965 17.0635L27.9296 4.31055Z" fill={this.props.showBloc === 1 ? '#F66031' : '#5367F4'} stroke={this.props.showBloc === 1 ? '#F66031' : '#5367F4'} />
                            </svg>
                            <span className="active-tab">Signataires</span>
                        </a>
                    </li>
                }
                {!this.props.isRestrictedUser && this.props.inNegociationBy && <li className={`${this.props.activeMenu == 'negociations' ? 'active' : ''}`}>
                    <Link to={"/manage-contract/negociations?contract="+this.state.contractId}>
                        <svg width="34" height="21" viewBox="0 0 34 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V2.75C6.5 4.82107 4.82107 6.5 2.75 6.5H2V15.5H2.81207C4.88314 15.5 6.56207 17.1789 6.56207 19.25V20H27.5V19.25C27.5 17.1789 29.1789 15.5 31.25 15.5H32V6.5H31.25C29.1789 6.5 27.5 4.82107 27.5 2.75V2H6.5ZM5 2H4.25C3.00736 2 2 3.00736 2 4.25V5H2.75C3.99264 5 5 3.99264 5 2.75V2ZM5.06207 20V19.25C5.06207 18.0074 4.05471 17 2.81207 17H2V17.75C2 18.9926 3.00736 20 4.25 20H5.06207ZM6.08773 21.5C6.0024 21.5337 5.9094 21.5523 5.81207 21.5523C5.71474 21.5523 5.62174 21.5337 5.53641 21.5H4.25C2.17893 21.5 0.5 19.8211 0.5 17.75V4.25C0.5 2.17893 2.17893 0.5 4.25 0.5H29.75C31.8211 0.5 33.5 2.17893 33.5 4.25V17.75C33.5 19.8211 31.8211 21.5 29.75 21.5H6.08773ZM29 20H29.75C30.9926 20 32 18.9926 32 17.75V17H31.25C30.0074 17 29 18.0074 29 19.25V20ZM29 2V2.75C29 3.99264 30.0074 5 31.25 5H32V4.25C32 3.00736 30.9926 2 29.75 2H29ZM17 17C13.6863 17 11 14.3137 11 11C11 7.68629 13.6863 5 17 5C20.3137 5 23 7.68629 23 11C23 14.3137 20.3137 17 17 17ZM17 15.5C19.4853 15.5 21.5 13.4853 21.5 11C21.5 8.51472 19.4853 6.5 17 6.5C14.5147 6.5 12.5 8.51472 12.5 11C12.5 13.4853 14.5147 15.5 17 15.5Z" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>Négociation</span>
                    </Link>
                </li>}
                {!this.props.isRestrictedUser && <li className={`${this.props.activeMenu == 'tags' ? 'active' : ''}`}>
                    <Link to={"/manage-contract/tags?contract="+this.props.contractParentId}>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 4.33224C1 2.4919 2.4919 1 4.33224 1H12.3296C12.5064 1 12.6759 1.07022 12.8009 1.1952L23.8678 12.2622C25.3774 13.7717 25.3774 16.2191 23.8678 17.7287L17.7287 23.8678C16.2191 25.3774 13.7717 25.3774 12.2622 23.8678L1.1952 12.8009C1.07022 12.6759 1 12.5064 1 12.3296V4.33224ZM12.0536 2.3329H4.33224C3.22803 2.3329 2.3329 3.22803 2.3329 4.33224V12.0536L13.2047 22.9253C14.1937 23.9144 15.7972 23.9144 16.7862 22.9253L22.9253 16.7862C23.9144 15.7972 23.9144 14.1937 22.9253 13.2047L12.0536 2.3329ZM7.66449 6.33159C6.92835 6.33159 6.33159 6.92835 6.33159 7.66449C6.33159 8.40063 6.92835 8.99738 7.66449 8.99738C8.40063 8.99738 8.99738 8.40063 8.99738 7.66449C8.99738 6.92835 8.40063 6.33159 7.66449 6.33159ZM7.66449 4.99869C9.13676 4.99869 10.3303 6.19221 10.3303 7.66449C10.3303 9.13676 9.13676 10.3303 7.66449 10.3303C6.19221 10.3303 4.99869 9.13676 4.99869 7.66449C4.99869 6.19221 6.19221 4.99869 7.66449 4.99869Z" fill="#5367F4" stroke="#5367F4"/>
                        </svg>
                        <span>Tags</span>
                    </Link>
                </li>}
            </ul>
        )
    }
}

export default withRouter(BoEndorsementSettings)
