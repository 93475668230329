import React, { useEffect } from "react";

import "./graph-screens.scss";
import Screen from "./Screen";

const GraphScreens = ({ items, screen, onSelectScreen, activePage, currentDash, loadPages, toast, isToogleActive }) => {

  useEffect(() => {
    getScreens()
  }, [currentDash])
  
  
  const getScreens = () => {
    let array = new Array(items.length).fill(null)
    items.map(item=>{
      if( item?.id == currentDash?.id ){
        array[(screen-1)] = currentDash
      }else if(activePage?.detail){
        let find = activePage['detail'].find(x=>x.dashboard === item?.id)
        if(find)
          array[(find['position']-1)] = item
      }
      return item
    })
    return array;
  }

  return(
    <div className="graph-screens">
      {getScreens().map((item, index) => (
        <Screen
          key={index}
          item={item}
          activePage={activePage}
          selectedScreen={screen}
          selectedDash={(item?.id == currentDash?.id) ? item:{}}
          active={screen === index+1}
          onSelect={!item ? () => onSelectScreen(index+1) : null}
          loadPages={loadPages}
          toast={toast}
          isToogleActive={isToogleActive}
        />
      ))}
    </div>
  );
}

export default GraphScreens;
