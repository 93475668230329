import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"
import Moment from 'moment'

export const getContractOcerizationInfo = (contractHashedID) => {
    var formData = new FormData();
    const requestUrl = API_URL+`/contract/getContractOserizationInfo?access_token=`+getConnectedUserAccessToken();
    formData.append('contractHashedID', contractHashedID);
    return axios.post(requestUrl, formData);
  };

export const getAlertsCSVFile = (contractID) => {
    const requestUrl = API_URL+`/contract/getAlertsCSVFile/`+contractID+`?access_token=`+getConnectedUserAccessToken();
    window.open(requestUrl, '_blank');
  };

export const getAlertsListByContract = (contractID) => {
  const requestUrl = API_URL+`/alert/getAlertsListByContract/`+contractID+`?access_token=`+getConnectedUserAccessToken();
  return axios.get(requestUrl);
};

export const saveNewAlert = (contractHashedID, alertObject) => {
  var formData = new FormData();
  var receipients = [];
  const requestUrl = API_URL+`/alert?access_token=`+getConnectedUserAccessToken();
  formData.append('contract', contractHashedID);
  formData.append('title', alertObject.title);
  formData.append('date', Moment(alertObject.date).format('YYYY-MM-DD'));

  for (let i = 0; i < alertObject.recipients.length; i++) {
    let rdvPeriod = (alertObject.recipients[i].rdvPeriod === "morning") ? 0 : 1;
    let receipientAlertType = '{'
    +'"sms":'+alertObject.recipients[i].type.sms+','
    +'"rdv":'+alertObject.recipients[i].type.rdv+','
    +'"email":'+ (alertObject.recipients[i].type.email && (parseInt(alertObject.recipients[i].type.email) == 1) ? 1:('"'+alertObject.recipients[i].type.email+'"'))
    +'}';
    var newReceipient = '"'+(i+1)+'":{'
         +'"fname":"'+alertObject.recipients[i].fname.split("'").join(' ')+'",'
         +'"lname":"'+alertObject.recipients[i].lname.split("'").join(' ')+'",'
         +'"email":"'+alertObject.recipients[i].email+'",'
         +'"phoneNumber":"'+alertObject.recipients[i].phoneNumber+'",'
         +'"rdvPeriod":"'+rdvPeriod+'",'
         +'"type":'+receipientAlertType
         +'}';
         receipients.push(newReceipient.toString())
  }
  console.log('receipients', '{'+receipients.toString()+'}')
  formData.append('receipients', '{'+receipients.toString()+'}');

  if (alertObject.isRecurrent && alertObject.frequencyType !== undefined) {
    formData.append('isRecurrent', 1);
    formData.append('frequencyType', alertObject.frequencyType);
    formData.append('frequency', parseInt(alertObject.frequency));

    if (alertObject.endWithContract !== undefined && (alertObject.endWithContract.toString() === '1' || alertObject.endWithContract.toString() === 'true')) {
      formData.append('endWithContract', 1);
    }else  if (alertObject.endDate !== null && alertObject.endDate !== "" && alertObject.endDate !== undefined) {
      const date = Moment(alertObject.endDate, 'DD/MM/YYYY', true);
      // Check if the date is valid
      if (!date.isValid()) {
        formData.append('endDate', Moment(alertObject.endDate).format('YYYY-MM-DD'));
      } else {
        formData.append('endDate', date.format('YYYY-MM-DD'));
      }
    }else if (alertObject.repeatCount !== null && alertObject.repeatCount !== "" && alertObject.repeatCount !== undefined) {
      formData.append('repeatCount', parseInt(alertObject.repeatCount));
    }

    if (alertObject.frequencyType === "DAILY") {
      formData.append('repeatAtDay', alertObject.repeatAtDay);
    }

    if (alertObject.frequencyType === "WEEKLY") {
      formData.append('repeatAtDays', JSON.stringify(alertObject.repeatAtDays));
    }

    if (alertObject.frequencyType === "MONTHLY") {
      formData.append('repeatAtDay', alertObject.repeatAtDay);
    }
  }

  console.log('%cFormData alert values:','font-weight: bold; color: salmon;font-size: 28px');
   // Display FormData entries in the console
  for (let [key, value] of formData.entries()) {
    console.log(key, value);
  }
  return axios.post(requestUrl, formData);
};

export const deleteAlert = (alertID) => {
  const requestUrl = API_URL+`/alert/`+alertID+`?access_token=`+getConnectedUserAccessToken();
  return axios.delete(requestUrl);
};

export const editAlert = (alertObject) => {
  var formData = new FormData();
  var receipients = [];
  var newReceipient = null;
  const requestUrl = API_URL+`/alert/editAlert?access_token=`+getConnectedUserAccessToken();

  formData.append('alert', alertObject.id);
  formData.append('date', Moment(alertObject.date).format('YYYY-MM-DD'));

  if(alertObject && alertObject.recipients !== undefined && alertObject.recipients.length>0)
    alertObject["recipients"] = alertObject["recipients"].filter(x=>x.rgpd == undefined)


  for (let i = 0; i < alertObject.recipients.length; i++) {
    let rdvPeriod = (alertObject.recipients[i].rdvPeriod === "morning") ? 0 : 1;
    let receipientAlertType = '{'
    +'"sms":'+alertObject.recipients[i].type.sms+','
    +'"rdv":'+alertObject.recipients[i].type.rdv+','
    +'"email":'+ (alertObject.recipients[i].type.email && (parseInt(alertObject.recipients[i].type.email) == 1) ? 1:('"'+alertObject.recipients[i].type.email+'"'))
    +'}';
    if(alertObject.recipients[i].id) {
      newReceipient = '"'+(i+1)+'":{'
      +'"id":"'+alertObject.recipients[i].id+'",'
      +'"fname":"'+alertObject.recipients[i].fname.split("'").join(' ')+'",'
      +'"lname":"'+alertObject.recipients[i].lname.split("'").join(' ')+'",'
      +'"email":"'+alertObject.recipients[i].email+'",'
      +'"phoneNumber":"'+alertObject.recipients[i].phoneNumber+'",'
      +'"rdvPeriod":"'+rdvPeriod+'",'
      +'"type":'+receipientAlertType
      +'}';
    } else {
      newReceipient = '"'+(i+1)+'":{'
      +'"fname":"'+alertObject.recipients[i].fname.split("'").join(' ')+'",'
      +'"lname":"'+alertObject.recipients[i].lname.split("'").join(' ')+'",'
      +'"email":"'+alertObject.recipients[i].email+'",'
      +'"phoneNumber":"'+alertObject.recipients[i].phoneNumber+'",'
      +'"rdvPeriod":"'+rdvPeriod+'",'
      +'"type":'+receipientAlertType
      +'}';
    }

    receipients.push(newReceipient.toString())
  }
  console.log('receipients', '{'+receipients.toString()+'}')
  formData.append('receipients', '{'+receipients.toString()+'}');

  if (alertObject.isRecurrent && alertObject.frequencyType !== undefined) {
    formData.append('isRecurrent', 1);
    formData.append('frequencyType', alertObject.frequencyType);
    formData.append('frequency', parseInt(alertObject.frequency));
    
    if (alertObject.endWithContract !== undefined && (alertObject.endWithContract.toString() === '1' || alertObject.endWithContract.toString() === 'true')) {
      formData.append('endWithContract', 1);
    }else if (alertObject.endDate !== null && alertObject.endDate !== "" && alertObject.endDate !== undefined) {
      const date = Moment(alertObject.endDate, 'DD/MM/YYYY', true);
      // Check if the date is valid
      if (!date.isValid()) {
        formData.append('endDate', Moment(alertObject.endDate).format('YYYY-MM-DD'));
      } else {
        formData.append('endDate', date.format('YYYY-MM-DD'));
      }
    }else if (alertObject.repeatCount !== null && alertObject.repeatCount !== "" && alertObject.repeatCount !== undefined) {
      formData.append('repeatCount', parseInt(alertObject.repeatCount));
    }

    if (alertObject.frequencyType === "DAILY") {
      formData.append('repeatAtDay', alertObject.repeatAtDay);
    }

    if (alertObject.frequencyType === "WEEKLY") {
      formData.append('repeatAtDays', JSON.stringify(alertObject.repeatAtDays));
    }

    if (alertObject.frequencyType === "MONTHLY") {
      formData.append('repeatAtDay', alertObject.repeatAtDay);
    }
  }

  console.log('%cFormData alert (EDIT) values:','font-weight: bold; color: salmon;font-size: 28px');
   // Display FormData entries in the console
  for (let [key, value] of formData.entries()) {
    console.log(key, value);
  }
  return axios.post(requestUrl, formData);
};

export const resendEmail = (alertId) => {
  const requestUrl = API_URL+`/alert/resendEmail/`+alertId+`?access_token=`+getConnectedUserAccessToken();
  return axios.get(requestUrl);
};

export const exportAlerts = () => {
  console.log('here')
  const requestUrl = API_URL+`/alert/export/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
  return axios.get(requestUrl)
};

export const getRecipientListByEmail = (email) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/alert/recipientsByEmail?access_token=`+getConnectedUserAccessToken();
  formData.append('email', email);
  formData.append('client', getCurrentClientID());
  return axios.post(requestUrl, formData);
};