import axios from 'axios';
import { API_URL } from "../../config/common";

export const importStatements = (user, requestsSt, onUploadProgress) => {
    const requestUrl = API_URL + `/dest`;
    var formData = new FormData();
    let files = [];
    let statements = [];
    
    let userData = {
        "firstName":user.firstName,
        "lastName":user.lastName,
        "company":user.company, 
        "function":user.job,
        "emailAddress":user.email
    }  
    formData.append('userData', JSON.stringify(userData));
    console.log(requestsSt)
    requestsSt.forEach(request => {
        statements.push({
            "monthPeriod": request.period.month,
            "yearPeriod": request.period.year,
            "bankName": request.infos.bank,
            "accountNumber": request.infos.account,
            "folderName": request.infos.folder,
            "files_path": request.fileId + "_files"
        })
        request.uploadedDocs.forEach(doc => {
            files.push({
                ...doc,
                fileId: request.fileId
            });
        });
        // Remove the uploadedDocs property from the request
        delete request.uploadedDocs;

    });

    statements.forEach((request, index) => {
        formData.append('requests' + '[' + index + ']', JSON.stringify(request));
    });

    let indexFile = 0;
    files.forEach((file, index) => {
        
        if(files[index-1] && files[index-1].fileId !== undefined && file.fileId !== files[index-1].fileId){
            indexFile = 0
        }else{
            indexFile++
        }
        formData.append(file.fileId + '_files' + '[' + indexFile + ']', file.file);
    });

    // Display FormData entries in the console
    for (let [key, value] of formData.entries()) {
        console.log(key, value);
    }
    return axios({
        method: 'post',
        url: requestUrl,
        data: formData,
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        timeout: Infinity,
        onUploadProgress
    });
};

