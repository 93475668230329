import React, { useEffect, useState } from 'react';

import Modal from '../../../../../../../components/Modal';
import { t } from 'i18next';

import { withTranslation } from 'react-i18next';


const ColumnsModal = ({ isOpen, onRequestClose, columns, setColumns, setOrderContractColumns, isDocumentsTab=false }) => {

  

  const onchangeSubOtherClauses = (e, index) => {
    let isVisible = e.target.checked;
    let listing = columns.map((item, i) => {
      return { ...item, isVisible: i === index ? isVisible : (item.isVisible !== undefined ? item.isVisible:false) };
    });
    setColumns(listing);
    setOrderContractColumns(listing.filter(x => x.isVisible).map(item => item.id))
  };

  const visibilityCol = columns.filter(x=>x.isVisible)

  return (
    <Modal
      title={t("common.columns_to_display")}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      isFullScreen={false}
      footer={
        <>
          <button className='m-auto' onClick={() => onRequestClose(false)}>{t("common.close")}</button>
        </>
      }
    >
      <table className="export-home w-auto">
        <tbody>
          <tr>
            {/* <td>
              <div className="control-area">
                <a className="save">
                  <svg width="36" height="36" viewBox="0 0 140 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M61.25 86.9082L100.021 47.8604C101.16 46.7132 103.007 46.7132 104.146 47.8604C105.285 49.0075 105.285 50.8675 104.146 52.0146L63.3124 93.1396C62.1733 94.2868 60.3266 94.2868 59.1876 93.1396L41.6876 75.5146C40.5486 74.3675 40.5486 72.5075 41.6876 71.3604C42.8266 70.2132 44.6733 70.2132 45.8124 71.3604L61.25 86.9082Z" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="#FFFFFF" />
                  </svg>
                </a>
              </div>
            </td> */}
            <td className="zone-details">
              <div>
                <a class="ui teal ribbon label">{t("common.details")}</a>
                <ul className="export-contracts-fiels p-4">
                  <li>
                    <hr />
                  </li>
                  {columns.map((field, key) => (
                    <li key={key}>
                      <label>
                        <input
                          type="checkbox"
                          disabled={visibilityCol.length == 1 && field.isVisible}
                          checked={field.isVisible && field.isVisible !== undefined && field.isVisible !== false}
                          onChange={(e) => onchangeSubOtherClauses(e, key)}
                        />
                        <span>
                          {/* {field.label} */}
                          {isDocumentsTab ? t('frontoffice.home.contracts.table.document.'+field.id) :t('frontoffice.home.contracts.table.'+field.id)}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default withTranslation()(ColumnsModal);
