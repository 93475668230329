import React, { useEffect, useState } from "react";
import "./date-range.scss";
const DateRange = ({ start, end, editable, onchangeDate }) => {

  const [dateStart, setDateStart] = useState(start);
  const [dateEnd, setDateEnd] = useState(end);

  useEffect(()=>{
    setDateStart(start)
    setDateEnd(end)
  },[start, end])

  return (
    <div className="graph-date-range">
      <div className="d-flex justify-content-center">
        <div>
          <div className="d-flex align-items-center">
            <label>Date Début :</label>
            <input
              type="date"
              value={dateStart || ''}
              onChange={(e) => {
                setDateStart(e.target.value);
                onchangeDate(e.target.value, dateEnd);
              }}
              name="startDate"
              className="form-control"
              readOnly={!editable}
            ></input>
          </div>
        </div>
        <div>
          <div className="d-flex align-items-center">
            <label>Date fin :</label>
            <input
              type="date"
              name="endDate"
              value={dateEnd || ''}
              onChange={(e) => {
                setDateEnd(e.target.value);
                onchangeDate(dateStart, e.target.value);
              }}
              className="form-control"
              readOnly={!editable}
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRange;
