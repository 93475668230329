import React from 'react'

//Icons
import { ImportIcon, ContractIcon, TagIcon, CommentIcon, AlertIcon, NegociationIcon, DocumentIcon, ArchiveIcon, AuthenticationIcon } from '../icons'


const ActionIcon = ({action, module}) => (
    <span
        className={`actionIcon 
            ${action ==='Connecté(e)'? 'bgGreen': ''}
            ${action ==='Erreur connexion'? 'bgRed': ''}
            ${action ==='Océrise'? 'bgOrange': ''}
            ${action ==='Modifie'   ? 'bgOrange': ''}
            ${action ==='Importe' ? 'bgGreen' : ''} 
            ${action ==='Crée' ? 'bgGreen' : ''}
            ${module !=="Archivage" && module !=="Archives" && action ==='Supprime' ? 'bgRed'   : ''}
            ${(module ==="Archivage" || module ==="Archives") && action ==='Supprime' ? 'bgGreen'   : ''}
            ${action ==='Archive' ? 'bgOrange'   : ''}
            ${action ==='Désarchive' ? 'bgRed'   : ''}
        `} 
    >
        {module ==="Connexion" && <AuthenticationIcon />}
        {module ==="Import" && <ImportIcon />}
        {module ==="Contrat/Avenant" && <ContractIcon />}
        {module ==="Tag" && <TagIcon />}
        {module ==="Commentaire" && <CommentIcon />}
        {module ==="Alerte" && <AlertIcon />}
        {module ==="Négociation" && <NegociationIcon />}
        {module ==="Document lié" && <DocumentIcon />}
        {(module ==="Archivage" || module ==="Archives") && <ArchiveIcon />}
    </span>
)

export default ActionIcon