import React from "react";
const ContractIcon = () => (
  <svg
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8125 15.0556V4.05556C18.8125 3.04303 18.015 2.22222 17.0312 2.22222H3.96875C2.98499 2.22222 2.1875 3.04303 2.1875 4.05556V19.9444C2.1875 20.957 2.98499 21.7778 3.96875 21.7778H12.2812C13.265 21.7778 14.0625 20.957 14.0625 19.9444C14.0625 18.2569 15.3917 16.8889 17.0312 16.8889C18.015 16.8889 18.8125 16.0681 18.8125 15.0556ZM18.2205 17.8561C17.8564 18.0201 17.4541 18.1111 17.0312 18.1111C16.0475 18.1111 15.25 18.9319 15.25 19.9444C15.25 20.3797 15.1616 20.7937 15.0022 21.1684C16.4225 20.4976 17.5687 19.3179 18.2205 17.8561ZM1 4.05556C1 2.36802 2.32915 1 3.96875 1H17.0312C18.6708 1 20 2.36802 20 4.05556V15.0556C20 19.4432 16.5442 23 12.2812 23H3.96875C2.32915 23 1 21.632 1 19.9444V4.05556ZM6.34375 8.33333C6.01583 8.33333 5.75 8.05973 5.75 7.72222C5.75 7.38471 6.01583 7.11111 6.34375 7.11111H14.6562C14.9842 7.11111 15.25 7.38471 15.25 7.72222C15.25 8.05973 14.9842 8.33333 14.6562 8.33333H6.34375ZM6.34375 12C6.01583 12 5.75 11.7264 5.75 11.3889C5.75 11.0514 6.01583 10.7778 6.34375 10.7778H14.6562C14.9842 10.7778 15.25 11.0514 15.25 11.3889C15.25 11.7264 14.9842 12 14.6562 12H6.34375ZM6.34375 15.6667C6.01583 15.6667 5.75 15.3931 5.75 15.0556C5.75 14.718 6.01583 14.4444 6.34375 14.4444H12.2812C12.6092 14.4444 12.875 14.718 12.875 15.0556C12.875 15.3931 12.6092 15.6667 12.2812 15.6667H6.34375Z"
      fill="white"
      stroke="white"
    />
  </svg>
);

export default ContractIcon;
