import axios from "axios";
import { API_URL } from "../config/common";
import {
  getConnectedUserAccessToken,
  getCurrentClientID,
  isAdmin,
  isClientAdmin,
} from "./QueryAuthParameters";

export const getHistory = (form) => {
  console.log("form", form);
  var formData = new FormData();
  if (form.user) {
    formData.append("user", form.user);
  }
  if (form.category) {
    formData.append("category", form.category);
  }
  if (form.startDate) {
    formData.append("startDate", form.startDate);
  }
  if (form.endDate) {
    formData.append("endDate", form.endDate);
  }
  if (form.contract) {
    formData.append("contract", form.contract);
  }
  if (form.selectedModules && form.selectedModules.length>0) {
    form.selectedModules.forEach((moduleHisto, index) => {
      formData.append("modules["+index+"]", moduleHisto["value"]);
    })
  }
  const is_admin = isAdmin() || isClientAdmin();
  const endPoint = is_admin
    ? "userHistoryByClient"
    : "getHistoryByClientFromFO";
  const requestUrl = `${API_URL}/userHistory/${endPoint}/${getCurrentClientID()}?access_token=${getConnectedUserAccessToken()}`;
  return new Promise((resolve) => {
    axios
      .post(requestUrl, formData)
      .then((res) => res.data)
      .then((res) => {
        resolve(res);
      });
  });
};

export const exportHistories = (form) => {
  // console.log("form", form);
  
  var formData = new FormData();
  if (form.user) {
    formData.append("user", form.user);
  }
  if (form.category) {
    formData.append("category", form.category);
  }
  if (form.startDate) {
    formData.append("startDate", form.startDate);
  }
  if (form.endDate) {
    formData.append("endDate", form.endDate);
  }
  if (form.contract) {
    formData.append("contract", form.contract);
  }
  if (form.selectedModules && form.selectedModules.length>0) {
    form.selectedModules.forEach((moduleHisto, index) => {
      formData.append("modules["+index+"]", moduleHisto["value"]);
    })
  }

    // console.log('%cFormData values:','font-weight: bold; color: red;font-size: 28px');

    // for (const [key, value] of formData.entries()) {
    //     console.log(`${key}: ${value}`);
    // }
    
  const is_admin = isAdmin() || isClientAdmin();
  const endPoint = is_admin
    ? "export"
    : "exportFromFo";
  const requestUrl = `${API_URL}/userHistory/${endPoint}/${getCurrentClientID()}?access_token=${getConnectedUserAccessToken()}`;
  return new Promise((resolve) => {
    axios
      .post(requestUrl, formData)
      .then((res) => res.data)
      .then((res) => {
        resolve(res);
      });
  });
};

export const getCategories = () => {
  const is_admin = isAdmin() || isClientAdmin();
  const endPoint = is_admin ? "getCategoriesByClient" : "getCategoriesByUser";
  const requestUrl = `${API_URL}/category/${endPoint}/${getCurrentClientID()}?access_token=${getConnectedUserAccessToken()}`;
  return new Promise((resolve) => {
    axios
      .get(requestUrl)
      .then((res) => res.data)
      .then((res) => {
        resolve(
          is_admin
            ? res.categories
            : res.map((cat) => {
                return { ...cat, value: cat.id, label: cat.name };
              })
        );
      });
  });
};



