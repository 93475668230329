import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../components/Modal';
import { t } from "i18next";
import { withTranslation } from 'react-i18next';


const LinkedDocumentsModal = ({ isOpen, onRequestClose, existsLinkedDocument, setExistsLinkedDocument, currentDocument, onLinkExistsLinkedDocumentToContract }) => {

  

  const onChangeDocumentLinkStatus = (e, documentID) => {
    let listing = existsLinkedDocument.map((item, i) => {
      return { ...item, linked: item.id === documentID  ? true : false };
    });
    setExistsLinkedDocument(listing);
  };


  return (
    <Modal
      title={t('frontoffice.contract_linked_docs.new_linked_doc.new_doc')}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      isFullScreen={false}
      footer={
        <>
          <button className='m-auto' onClick={() => onLinkExistsLinkedDocumentToContract()}>{t('common.modals.ok')}</button>
          <button className='m-auto' onClick={() => onRequestClose(false)}>{t('common.modals.ignore')}</button>
        </>
      }
    >
      <table className="linked-docs-modal w-auto">
        <tbody>
          <tr>
            <td className="zone-details">
              <div>
                {/* <a class="ui teal ribbon label">Détails</a> */}
                <p>
                  {t('frontoffice.contract_linked_docs.new_linked_doc.choose_linked_doc')}
                </p>
                <hr/>
                <ul className="linked-doc-fiels p-4">
                  {existsLinkedDocument.map((field, key) => {
                    return (
                      <li key={key}>
                        <label className="label-radio-check">
                          <input
                            type="radio"
                            checked={(field.linked && field.linked !== undefined && field.linked !== false)}
                            onChange={(e) => onChangeDocumentLinkStatus(e, field.id)}
                          />
                          <span className={!field.name && 'text-danger'}>
                            {field.name ? field.name: "Sans nom"} <small>{" (" + field.date + ")"}</small>
                          </span>
                        </label>
                      </li>
                    )
                  }
                  )}
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default withTranslation()(LinkedDocumentsModal);
