import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken} from "./QueryAuthParameters"

let cancelTokenSource = axios.CancelToken.source();

export const getRgpdUsers = () => {
    const requestUrl = API_URL+`/user/rgpd/getUsers?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

export const getRgpdUserClients = (userID) => {
    const requestUrl = API_URL+`/user/rgpd/getUserClients/`+userID+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl, {cancelToken: cancelTokenSource.token});
  };


  export const archiveUserDAW = (userID, final=0) => {
    const requestUrl = API_URL + `/user/archiveUser/` + userID + `?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    // if (userID) {
    //   formData.append('user', userID);
    // }
    if (final !== 0) {
      formData.append('final', final);
    }
    return axios.post(requestUrl, formData);
  };
  
  export const unArchiveUserDAW = (userID) => {
    const requestUrl = API_URL + `/user/unarchiveUser/` + userID + `?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };
  
  export const getUsersCSVFileForAllClients = () => {
    const requestUrl = API_URL + `/user/rgpd/getUsersCSVFileForAllClients?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

  export const cancelRequests = () => {
    cancelTokenSource.cancel('Request canceled');
    cancelTokenSource = axios.CancelToken.source(); // Create a new cancel token
    return Promise.resolve(); // Resolves immediately for chaining purposes
  };