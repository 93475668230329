import React, { Component } from 'react'; 
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';

class UnconnectUser extends Component { 

    constructor () {
        super();
        this.state = {} 
    }

    componentWillMount() {
        if (Cookies.get("systemKeyWords") !== undefined) {
            Cookies.remove("systemKeyWords")
        }
        if (Cookies.get("customClauses") !== undefined) {
            Cookies.remove("customClauses")
        }
        if (Cookies.get("othersClauses") !== undefined) {
            Cookies.remove("othersClauses")
        }
        Cookies.remove("userRights");
     
        const cookieConsent = localStorage.getItem('cookieConsent');
        localStorage.clear();
        if (cookieConsent !== null) {
          localStorage.setItem('cookieConsent', cookieConsent);
        }
        
        if(Cookies.get("pinTimeline")) {
          Cookies.remove("pinTimeline");
        }

        if(Cookies.get("currentClient")) {
            Cookies.remove("currentClient");
        } 
        if(Cookies.get("currentNegosClient")) {
            Cookies.remove("currentNegosClient");
        }
        if (Cookies.get("currentUserInformation")) {
            Cookies.remove("currentUserInformation");
        }
        if (Cookies.get("itemsColumnsByClient")) {
            Cookies.remove("itemsColumnsByClient");
        }
        if (Cookies.get("isDefaultClientLoaded")) {
            Cookies.remove("isDefaultClientLoaded");
        }
        this.props.history.push('/home');
    }

    render() {
        return (
            <div> </div>
        )
    }
}

export default withRouter(UnconnectUser);
