import React from "react";
import { ChartEvolutionIcon } from "../../../assets/icons";

const ButtonIndiceEvolution = ({title, onPress}) => (
  <button
    title={title}
    type="button"
    className={`btn btn-default icon-restriction ml-2`}
    onClick={onPress}
  >
    <ChartEvolutionIcon />
  </button>
);

export default ButtonIndiceEvolution;
