import React from "react";
const CommenIcon = () => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1V14.5H7.075V18.2125L11.125 14.5H21.25V1H1Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.05078 11.125H13.1508M5.05078 4.375H17.2008H5.05078ZM5.05078 7.75H17.2008H5.05078Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CommenIcon;
