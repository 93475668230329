import React from "react";

const LegendIcon = ({color = "#ffff", width="24", height="24"}) => (
    <>

        <svg width={width} height={height} viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.625 35.25C17.625 32.0053 20.2553 29.375 23.5 29.375H29.375C32.6197 29.375 35.25 32.0053 35.25 35.25V41.125C35.25 44.3697 32.6197 47 29.375 47H23.5C20.2553 47 17.625 44.3697 17.625 41.125V35.25ZM49.9375 41.125C48.3152 41.125 47 39.8098 47 38.1875C47 36.5652 48.3152 35.25 49.9375 35.25H120.438C122.06 35.25 123.375 36.5652 123.375 38.1875C123.375 39.8098 122.06 41.125 120.438 41.125H49.9375ZM17.625 64.625C17.625 61.3803 20.2553 58.75 23.5 58.75H29.375C32.6197 58.75 35.25 61.3803 35.25 64.625V70.5C35.25 73.7447 32.6197 76.375 29.375 76.375H23.5C20.2553 76.375 17.625 73.7447 17.625 70.5V64.625ZM49.9375 70.5C48.3152 70.5 47 69.1848 47 67.5625C47 65.9402 48.3152 64.625 49.9375 64.625H120.438C122.06 64.625 123.375 65.9402 123.375 67.5625C123.375 69.1848 122.06 70.5 120.438 70.5H49.9375ZM17.625 94C17.625 90.7553 20.2553 88.125 23.5 88.125H29.375C32.6197 88.125 35.25 90.7553 35.25 94V99.875C35.25 103.12 32.6197 105.75 29.375 105.75H23.5C20.2553 105.75 17.625 103.12 17.625 99.875V94ZM23.5 35.25V41.125H29.375V35.25H23.5ZM23.5 64.625V70.5H29.375V64.625H23.5ZM23.5 94V99.875H29.375V94H23.5ZM49.9375 99.875C48.3152 99.875 47 98.5598 47 96.9375C47 95.3152 48.3152 94 49.9375 94H120.438C122.06 94 123.375 95.3152 123.375 96.9375C123.375 98.5598 122.06 99.875 120.438 99.875H49.9375Z" fill={color} />
        </svg>


    </>
);

export default LegendIcon;