import React, { Component } from 'react'; 
import { withRouter } from 'react-router-dom';
import './ResetPassword.scss';
import { getPasswordConfigurationByRequestId, verifyAndResetPasswordRequest } from '../../../api/user'
import Cookies from 'js-cookie';
import ErrorAlert from '../../../components/confirm-alert/error-alert'
import LoaderComponent from '../../../components/loader/Loader' 
import HeaderVisitor from '../../../components/layouts/header-visitor/HeaderVisitor';
import NotifyAlert from '../../../components/confirm-alert/notify-alert/NotifyAlert';
import PassRulesMeasure from '../../dashboard/front-office/profile/parts/PassRules/PassRulesMeasure';

class ResetPassword extends Component { 

    constructor () {
        super();
        this.state = { 
            passwordReset: false,
            confirmPasswordError: false,
            newPassword: "",
            newPasswordType: "password",
            confrimedPasswordType: "password",
            userPasswordConfiguration: null
        } 
        this.form = {
            newPassword: null,
            confirmPassword: null
        }
    }

    componentDidMount() {
        let currentClientCookie = Cookies.get("currentClient");
        if (currentClientCookie) {
            NotifyAlert.show(
            "Vous êtes déjà connecté par un autre compte, veuillez vous déconnecter et ressayer avec le lien reçu par email",
            'Information'
            ).then(() => {});
            this.props.history.push(
            "/home"
            );
        }else{
            const queryString = require('query-string');
            var parsed = queryString.parse(this.props.location.search);
            if(parsed.request) {
                if(Cookies.get("userRights")) {
                    Cookies.remove("userRights");
                }
                if(Cookies.get("apiToken")) {
                    const cookieConsent = localStorage.getItem('cookieConsent');
                    localStorage.clear();
                    if (cookieConsent !== null) {
                    localStorage.setItem('cookieConsent', cookieConsent);
                    }    
                }
                
                if(Cookies.get("pinTimeline")) {
                    Cookies.remove("pinTimeline");
                }
                if(Cookies.get("currentClient")) {
                    Cookies.remove("currentClient");
                }
                if(Cookies.get("currentNegosClient")) {
                    Cookies.remove("currentNegosClient");
                }
                if (Cookies.get("isDefaultClientLoaded")) {
                    Cookies.remove("isDefaultClientLoaded");
                }
                LoaderComponent.show();
                verifyAndResetPasswordRequest(parsed.request, null, null).then((response) => { 
                    this.getPasswordRules(parsed.request)
                    LoaderComponent.hide();
                  }, (error) => {
                    LoaderComponent.hide();
                    if(error && error.response){
                        ErrorAlert.show(error.response.data, "Erreur API");
                        setTimeout(() => { 
                            this.props.history.push("/home");
                        }, 3000); 
                    }
                  });
            } else {
                ErrorAlert.show("La demande de réinitialisation du mot de passe n'est pas valide, veuillez réessayer", "Attention");
                    setTimeout(() => { 
                        this.props.history.push("/home");
                    }, 3000);
            }
            //this.props.history.push('/home');   
            //document.getElementById("form-reset-password").reset();
        }
    }

    getPasswordRules = (reqId) => {
        if(reqId){
          getPasswordConfigurationByRequestId(reqId).then((response) => {
            this.setState({
              ...this,
              userPasswordConfiguration: response.data,
            });
          });
        }
      }

    onSubmit = (e)=>{
        e.preventDefault(); 
        console.log("newPassword: ", this.form.newPassword.value)
        console.log("confirmPassword: ", this.form.confirmPassword.value)
        if (this.form.newPassword.value != this.form.confirmPassword.value) {
            this.setState({ 
                ...this.state, 
                confirmPasswordError: true
            })
        } else {
            const queryString = require('query-string');
            var parsed = queryString.parse(this.props.location.search);
            LoaderComponent.show();
            verifyAndResetPasswordRequest(parsed.request, this.form.newPassword.value, this.form.confirmPassword.value).then((response) => { 
                LoaderComponent.hide();
                this.setState({ 
                    ...this.state, 
                    confirmPasswordError: false,
                    passwordReset: true
                });
                setTimeout(()=>{
                    this.props.history.push("/home");
                },3000)
            }, (error) => {
                LoaderComponent.hide();
              ErrorAlert.show(error?.response?.data, "Erreur API"); 
            });
        }
    }

    showPassword(e, passLabel) {
        e.preventDefault();
        this.setState({
            ...this.state,
            [passLabel]: this.state[passLabel] == "password" ? "text" : "password",
        });
    }

    onGetCopiedPass = (value) => {
        this.setState({...this.state, newPassword: value})
        this.form.newPassword.value = value
    }

    render() {
        return (
            <div className="wave-bg vh-100">
                <HeaderVisitor />
                <form method="post" action="#" className="reset-password-form" onSubmit={this.onSubmit}>
                    <center>
                        <h3 className="black-text">Réinitialisation de mot de passe</h3>
                    </center>
                    {
                        this.state.passwordReset &&
                        <div className="password-reset">
                            Félicitations, votre mot de passe a été réinitialisé.
                        </div>
                    }
                    <div className="form-group">
                        {/* <label>Nouveau mot de passe</label> */}
                        <input ref={(input) => { this.form.newPassword = input }} className="form-control" type={this.state.newPasswordType} name="newPassword" placeholder="Nouveau mot de passe" onChange={(e)=>{this.setState({...this.state, newPassword: e.target.value})}} required></input>
                        <div className="input-group-addon">
                          <a href="#" onClick={(e)=>this.showPassword(e, "newPasswordType")}>
                            <i
                              className={
                                this.state.newPasswordType == "password"
                                  ? "fa fa-eye-slash"
                                  : "fa fa-eye"
                              }
                            ></i>
                          </a>
                        </div>
                    </div>
                    <div className="form-group">
                        <PassRulesMeasure password={this.state.newPassword || ""} userPasswordConfiguration={this.state.userPasswordConfiguration} onGetCopiedPass={(value)=>this.onGetCopiedPass(value)} />
                    </div>
                    <div className={`form-group input-group ${this.state.confirmPasswordError ? 'error' : ''}`}>
                        {/* <label>Confirmation</label> */}
                        <input ref={(input) => { this.form.confirmPassword = input }} className="form-control" type={this.state.confrimedPasswordType} name="confirmPassword" placeholder="Confirmation" required></input>
                        <div className="input-group-addon">
                          <a href="#" onClick={(e)=>this.showPassword(e, "confrimedPasswordType")}>
                            <i
                              className={
                                this.state.confrimedPasswordType == "password"
                                  ? "fa fa-eye-slash"
                                  : "fa fa-eye"
                              }
                            ></i>
                          </a>
                        </div>
                        <span className="error">Le mot de passe de confirmation est erroné</span>
                    </div>

                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6 col-sm-12">
                            <button className="btn btn-primary" type="submit">Valider</button>
                        </div>
                        <div className="col-md-3"></div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-center">
                            <a href="mailto:contact@docatweb.fr" className="btn btn-link black-text">Nous contacter</a>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default withRouter(ResetPassword);
