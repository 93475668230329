import React, { Component } from "react";
import { BrowserRouter as Router, Link, withRouter } from "react-router-dom";
import ErrorAlert from "../../../../../components/confirm-alert/error-alert";
import ConfirmAlert from "../../../../../components/confirm-alert/ConfirmAlert";
import NotifyAlert from "../../../../../components/confirm-alert/notify-alert/NotifyAlert";
//import DatePicker from "react-datepicker";

import "./ExchangesContract.scss";

// components
import Header from "../../../../../components/layouts/header/Header";
import Sidebar from "../../../../../components/layouts/sidebar/Sidebar";
import FoManageContract from "../../../../../components/layouts/FO-Manage-Contract/FoManageContract";
import {
  getCategoriesForContractValidation,
  getContractOcerizationInfo,
  downloadContractFromS3Server,
  getOtherClientContracts,
  getClientKeywordByCategory,
  saveContractDraft,
  calculateTheContractAlerts,
  calculateContractsAmount,
  validateContract,
  calculateTheContractEndingDateAndDuration,
  handleContractOcerizationContentInTheBackGround,
  calculateTheContractTotalAmount,
  ResiliateContract as ResiliateContractApi,
  downloadSignedContractFromDocusignServer,
  renewContract,
  toggleContractDispute,
  checkContractParameters,
  updateContractRestriction,
  affectContractByWorkflow,
  taskValidationByWorkflow,
  affectContractWithoutWorkflow,
  updateContractRestrictionList,
  getContractHTMLFile
} from "../../../../../api/validateContract";
import { createEndorsement } from "../../../../../api/endorsement";
import LoaderComponent from "../../../../../components/loader/Loader";
import EditMode from "../../../../../components/edit-mode/EditMode";
import ZoomContent from "../../../../../components/zoom-content/ZoomContent";
import ControlPanel from "../components/ControlPanel";
import InfoTooltip from "./parts/InfoTooltip";
import ButtonCible from "./parts/ButtonCible";
import ResiliateContract from "./parts/ResiliateContract";

import {
  addContractToNegociations, cancelSignature, detailListProposition, doSigningRequest, setSignatoriesList
} from "../../../../../api/negociation";

//icons
import { AlertIcon, EyeOnIcon } from "../../assets/icons";

// plugins
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Moment from "moment";
import highlightRange from "dom-highlight-range";
import uuid from "react-uuid";
import $, { isNumeric } from "jquery";
import Mark from "mark.js";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr"; // register it with the name you want
import accents from "remove-accents";
import {t} from "i18next";
import { withTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";

import CurrencyInput from 'react-currency-input-field';
import Switcher from "../../../../../components/switcher/Switcher";
import ButtonUp from "./parts/ButtonUp";
import ButtonDown from "./parts/ButtonDown";
import Modal from "../../../../../components/Modal";

import ReactToPrint from 'react-to-print';
import ButtonLock from "./parts/ButtonLock";
import ButtonUnLock from "./parts/ButtonUnlock";

import copy from 'clipboard-copy';
import ButtonCopy from "./parts/ButtonCopy";
import { associateContractToFavouritesList, dissociateContractToFavouritesList } from "../../../../../api/home";
import CustomDropdown from "../negociations/parts/CustomDropdown";
import CustomDropdownKeywords from "../parts/CustomDropdownKeywords";

import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Packer, Document, Paragraph, AlignmentType, TextRun, Table, TableRow, TableCell, BorderStyle } from "docx";
import { getConvertedAmount, getExchangeTimeSeriesData } from "../../../../../api/exchanges";
import Chart from "react-google-charts";

registerLocale("fr", fr);

// form validation
const ReactFormWithConstraints = require("react-form-with-constraints");
const { FormWithConstraints } = ReactFormWithConstraints;

class ExchangesContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canDuplicateName:false,
      canDuplicateNumber:false,
      errorMsgLitige: null,
      isCalendarOpened:false,
      signatoryObject:{
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        firstSignatory: false,
        secondSignatory: false
      },
      signatoriesListToAdd:[],
      action: 'addSignatory',
      showBloc:0,
      zonesClauses:{
        classement_org:false,
        details_contract:false,
        amounts:true,
        duration:false,
        clauses_facultatives:false
      },
      currentPage:1,
      editMode: false,
      contract: {},
      categories: [],
      selectedCategory: null,
      subCategories: [],
      selectedSubCategory: null,
      clientOtherContracts: [],
      signatory1: null,
      signatory2: null,
      amount: null,
      dueAmount: null,
      dueFrequency: null,
      dueFrequencyType: 1,
      contractDuration: null,
      priorNoticeDuration: null,
      negociationDuration: null,
      contractDurationType: 2,
      negociationDurationType: 1,
      priorNoticeDurationType: 1,
      endingDate: null,
      resiliationDate: null,
      beginingDate: null,
      objet: null,
      contractCode: null,
      renewal: null,
      totalAmoutInitialPeriod: null,
      negociationBeginingDate: null,
      priorNoticeDate: null,
      selectedOptionalKeywods: [],
      contractAlerts: {},
      contractHTMLContent: [],
      //contractAlerts:{},
      extraKeywordsWithValues: [],
      selectedParentContract: null,
      validatedAlerts: false,
      informationsKey: [],
      initialAmountType: 1,
      avtChecked: false,
      savingDocument: false,
      term: 1,
      scrolling: {
        leftSide: false,
        rightSide: false,
      },
      markers: [],
      search: {
        totalOccurence: 0,
        currentOccurence: 0,
        priority: 1,
      },
      negociationsDetail: {},
      userRights: JSON.parse(Cookies.get("userRights")),
      isRenewOther: false,
      showModal: false,
      showModalLitige: false,
      isRestrictedView: false,
      isContractInfoVisible: true,
      showModalResilationDoc: false,
      exchangesParams:{
        currencies:[
          {
            from:"EUR",
            to:"USD",
            selected: true
          },
          {
            from:"EUR",
            to:"CAD",
            selected: false
          },
          {
            from:"EUR",
            to:"GBP",
            selected: false
          },
          {
            from:"EUR",
            to:"JPY",
            selected: false
          },
          {
            from:"EUR",
            to:"CHF",
            selected: false
          },
          {
            from:"EUR",
            to:"HKD",
            selected: false
          },
          {
            from:"EUR",
            to:"SDG",
            selected: false
          },
        ],
        range:[
          {
            duration: "1month",
            libelle: "1 Mois",
            selected: true
          },
          {
            duration: "3months",
            libelle: "3 Mois",
            selected: false
          },
          {
            duration: "6months",
            libelle: "6 Mois",
            selected: false
          },
        ],
        isFromEUR: true,
        data: [],
        convertedData:null,
      },
      echeancesList:[]
    };
    setTimeout(() => {
      this.setState({
        ...this.state,
        ocerisedContract: this.state.ocerisedContract_,
      });
    }, 2000);

    this.pdfContent = React.createRef();
    this.selectedText = "";
    this.handleSavingDocumentStatus = this.handleSavingDocumentStatus.bind(this);

    this.handleIsFromEURChange = this.handleIsFromEURChange.bind(this);
    /// After Load document
    /*setTimeout(()=>{
            this.extractMarkers();
            window.addEventListener('resize', this.extractMarkers);
        })*/
  }
  // start will be deleted later
  triggerClick(){
    if(this.pdfContent && this.pdfContent.current){
      document.addEventListener('click', this.getNbPages);
      this.pdfContent.current.click();
    }
  }

  getNbPages = event => {
    if(this.state.contract.nbPages && event.currentTarget.querySelector("div#page-container") && event.currentTarget.querySelector("div#page-container").children){
      const nbPages = event.currentTarget.querySelector("div#page-container").children.length;
      if(this.state.contract.nbPages !== nbPages){
        this.setState(
          {
            ...this.state,
            contract: {
              ...this.state.contract,
              nbPages: nbPages,
            },
          }
        )
      }
    }
  }

  handleScrollBisTODO = () => {
    // Check if the body's overflow is hidden
    if (document.body.style.overflow === 'hidden') {
      // If overflow is hidden, remove the scroll event listener
      window.removeEventListener('scroll', this.handleScrollBisTODO);
      window.location.reload()
    } else {
      // Your scroll handling logic goes here
      console.log('Scrolled!');
    }
  };

  componentWillUnmount() {
     document.removeEventListener('click', this.getNbPages, false);
     // Remove the scroll event listener when the component is about to unmount
     window.removeEventListener('scroll', this.handleScrollBisTODO);
  }
  // end will be deleted later

  componentDidMount() {

    // Add a scroll event listener when the component mounts
    window.addEventListener('scroll', this.handleScrollBisTODO);

    const queryString = require("query-string");
    var parsed = queryString.parse(this.props.location.search);

    getCategoriesForContractValidation().then((response) => {
      this.setState({
        ...this,
        categories: response.data ? response.data.sort((a, b) => a.name.localeCompare(b.name)):[],
      });
    });

    if(parsed.editMode) {
      if(parsed.editMode == 1) {
        this.setState(
            {
              ...this.state,
              editMode: true,
            }
        )
      }
    }

    if (parsed && parsed.renew_success && parsed.renew_success.toString() === '1') {
        NotifyAlert.show(
          "Un avenant de reconduction a été  géneré automatiquement dans Docatweb et les échéances de votre contrat sont décalées d'un an.",
          'Reconduction'
        ).then(() => {});
    }

    if (parsed.contract) {
      getContractOcerizationInfo(parsed.contract).then(
        (response) => {
          if(response && response.data !== undefined){

            if (response.data.avt === true) {
              this.props.history.push(
                "/manage-contract/endorsements?contract=" + response.data.hachedId
              );
            }else if(response.data.inNegociationBy && response.data.status !== 2){
              this.props.history.push(
                "/manage-contract/negociations?contract=" + response.data.hachedId
              );
            } else {
              this.setState(
                {
                  contract: response.data,
                  signatory1: this.getKeywordByName(
                    response.data,
                    "Nom Signataire Fournisseur"
                  ),
                  signatory2: this.getKeywordByName(
                    response.data,
                    "Nom Signataire interne"
                  ),
                  amount: this.getKeywordByName(
                    response.data,
                    "Montant Global du Contrat"
                  ),
                  dueAmount: this.getKeywordByName(
                    response.data,
                    "Montant échéances"
                  ),
                  dueFrequency: this.getKeywordByName(
                    response.data,
                    "Fréquence échéances"
                  ),
                  dueFrequencyType: this.getDurationType(
                    this.getKeywordByName(response.data, "Fréquence échéances")
                  ),
                  contractDuration: this.getKeywordByName(response.data, "Durée"),
                  priorNoticeDuration: this.getKeywordByName(
                    response.data,
                    "Préavis"
                  ),
                  negociationDuration: this.getKeywordByName(
                    response.data,
                    "Négociation"
                  ),
                  endingDate: this.getKeywordByName(response.data, "Date de fin"),
                  resiliationDate: response.data?.resiliationDate,
                  beginingDate: this.getKeywordByName(
                    response.data,
                    "Date de début"
                  ),
                  negociationBeginingDate: this.getKeywordByName(
                    response.data,
                    "negociationBeginingDate"
                  ),
                  priorNoticeDate: this.getKeywordByName(
                    response.data,
                    "priorNoticeDate"
                  ),
                  objet: this.getKeywordByName(response.data, "Objet"),
                  contractCode: this.getKeywordByName(
                    response.data,
                    "Numéro du contrat"
                  ),
                  renewal: this.getKeywordByName(response.data, "Renouvellement"),
                  totalAmoutInitialPeriod: this.getKeywordByName(
                    response.data,
                    "Frais Installation/FAS"
                  ),
                  negociationDurationType: this.getDurationType(
                    this.getKeywordByName(response.data, "Négociation")
                  ),
                  contractDurationType: this.getContractDurationType(
                    this.getKeywordByName(response.data, "Durée")
                  ),
                  priorNoticeDurationType: this.getDurationType(
                    this.getKeywordByName(response.data, "Préavis")
                  ),
                  informationsKey: this.getInformationsKey(response.data),
                  extraKeywordsWithValues: this.getKeywordsValues(response.data),
                  validatedAlerts: response.data.status === 2 ? true : false,
                  initialAmountType: this.getContractAmountType(
                    this.getKeywordByName(response.data, "Frais Installation/FAS")
                  ),
                  term: this.getTheContractAmountTerm(
                    this.getKeywordByName(response.data, "Frais Installation/FAS")
                  ),
                  savingDocument:
                    response.data.updatedHTMLValidationStatus === 0 ? false : true,
                  //editMode: response.data.ifUpdateRightIsPresent,
                },
                async () => {
                  this.getExchangesByRange(this.generateDateRange(this.state.exchangesParams.range.find(r => r.selected).duration));
  
                  // Start calc echeance
                  const freq = this.state.dueFrequency?.value?.duration + (this.state.dueFrequency?.value?.durationType?.toString() === "1" ? " mois" : " ans")
                  const dateDebut = this.state.beginingDate?.value
                  const dateFin = this.state.endingDate?.value
                  const typeEcheance = this.state.dueFrequencyType?.toString() === "1" ? "a_echoir" : "a_echu"
                  if (freq && dateDebut && typeEcheance && dateFin) {
                    const echList = await this.genererDatesEcheances(freq, dateFin, typeEcheance, dateDebut)
                    console.log(echList)
                    this.setState({
                      ...this.state,
                      echeancesList: echList
                    })
                  }
                  // End calc echeance
                  // Exemple d'utilisation
                  // const dates = this.genererDatesEcheances("2 mois", "01/03/2023", "a_echoir", "28/02/2027");
                  // console.log(dates);
  
                  // const dates2 = this.genererDatesEcheances('3 ans', '01/04/2027', 'a_echu', '01/04/2024');
                  // console.log(dates2);
  
                  // get Contract HTML File
                  getContractHTMLFile(this.state.contract.hachedId, true).then(
                    htmlFile => {
                      if (htmlFile["data"] !== undefined)
                        this.setState({
                          ...this.state,
                          contract: {
                            ...this.state.contract,
                            htmlValidationContent: htmlFile["data"]
                          }
                        })
                    }
                  )
  
                  // set required clauses perso
                  let extraKeywordsWithValues = this.state.extraKeywordsWithValues || []
                  let informationsKey = this.state.informationsKey || []
                  informationsKey.forEach((x, index) => {
                    if (x.isRequired) {
                      informationsKey[index].isDisabled = true;
                      let found = (extraKeywordsWithValues.find(y => y.value == x.value)) ? true : false
                      if (!found)
                        extraKeywordsWithValues.push(x)
                    }
                  })
                  this.setState({
                    ...this.state,
                    extraKeywordsWithValues: extraKeywordsWithValues,
                    informationsKey: informationsKey
                  })
                  // get the parent div element
                  const parentDiv = document.querySelector("#page-container");
                  if (parentDiv) {
                    parentDiv.classList.add('mixed-print-layout')
                    // get all the child div elements
                    const childDivs = parentDiv.querySelectorAll('div');
  
                    // loop through each child div and get its width
                    childDivs.forEach(div => {
                      if (div.id) {
                        const rect = div.getBoundingClientRect();
                        const width = rect.width;
                        if (width > 685) {
                          div.classList.add('landscape-page')
                        } else {
                          div.classList.add('portrait-page')
                        }
                      }
                    });
  
                  }
                  setTimeout(() => {
                    if (this.state.renewal && this.state.renewal.value !== null &&
                      this.state.renewal.value !== '' && this.state.renewal?.value !== "Tacite" &&
                      this.state.renewal?.value !== "Expresse" &&
                      this.state.renewal?.value !== "Prorogation" &&
                      this.state.renewal?.value !== "Autre") {
                      this.setState({
                        ...this.state,
                        isRenewOther: true
                      })
                    }
                    this.extractMarkers();
                    window.addEventListener("resize", this.extractMarkers);
                  });
                  //this.handleDatesCalculation()
                  this.setState(
                    {
                      ...this,
                      subCategories: this.state.contract.category.subCategories ? this.state.contract.category.subCategories.sort((a, b) => a.name.localeCompare(b.name)) : [],
                      signatoriesListToAdd: response['data']['signatories']['signatories']
                    },
                    () => {
                      this.calculateTheContractAlerts();
                      this.handleAmountsCalculation();
                      this.handleTotalAmountCalculation();
                      //this.handleSavingDocumentStatus(response.data);
                    }
                  );
                  if (this.state.contract.category) {
                    getOtherClientContracts(this.state.contract.category.id).then(
                      (response) => {
                        this.setState({
                          ...this,
                          clientOtherContracts: response.data ? response.data.sort((a, b) => a.name.localeCompare(b.name)) : [],
                        });
                      }
                    );
                  }
                  if (this.state.savingDocument === true) {
                    let contract = response.data;
                    const interval = setInterval(
                      function () {
                        handleContractOcerizationContentInTheBackGround(
                          contract.hachedId,
                          1
                        ).then((response) => {
                          if (response.data.updatedHTMLValidationStatus === 0) {
                            this.setState({
                              ...this,
                              savingDocument: false,
                            });
                            this.setState(
                              {
                                ...this,
                                contract: {
                                  ...contract,
                                  htmlValidationContent:
                                    response.data.newFileContent,
                                },
                              },
                              () => {
                                this.extractMarkers();
                              }
                            );
                            clearInterval(interval);
                          }
                        });
                      }.bind(this),
                      5000
                    );
                  }
                }
              );
              if (this.state.contract.inNegociationBy) {
                detailListProposition(this.state.contract.id, this.state.userRights.type).then((propositions) => {
                  this.setState({
                    ...this,
                    negociationsDetail: propositions.data
                  });
                });
              }
              // start will be deleted later
              this.triggerClick()
              // end will be deleted later
            }
          }
        },
        (error) => {
          if (error?.response?.data === "IMPORT_ONLY") {
            NotifyAlert.show("Le contrat a été correctement importé", "Succès");
          } else {
            ErrorAlert.show(error?.response?.data?.message || error?.response?.data, "Erreur API");
          }
          setTimeout(() => {
            this.props.history.push("/home");
          }, 3000);
        }
      );
    } else {
      ErrorAlert.show(
        "Vous devez fournir l'identifiant du contrat",
        "Attention"
      );
      setTimeout(() => {
        this.props.history.push("/home");
      }, 3000);
    }
  }

  resetContractData() {
    getContractOcerizationInfo(this.state.contract?.hachedId, 1).then(
      (response) => {
        this.setState(
          {
            ...this,
            contract: response.data,
            informationsKey: this.getInformationsKey(response.data),
            extraKeywordsWithValues: this.getKeywordsValues(response.data),
            //editMode: response.data.ifUpdateRightIsPresent,
          },
          () => {
            setTimeout(() => {
              this.extractMarkers();
              window.addEventListener("resize", this.extractMarkers);
            });
            //this.handleDatesCalculation()

            // get Contract HTML File
              getContractHTMLFile(this.state.contract.hachedId, true).then(
                htmlFile=>{
                  if(htmlFile["data"] !== undefined)
                  this.setState({
                    ...this.state,
                    contract:{
                      ...this.state.contract,
                      htmlValidationContent:htmlFile["data"]
                    }
                  })
                }
              )

            this.setState(
              {
                ...this,
                subCategories: this.state.contract.category.subCategories ? this.state.contract.category.subCategories.sort((a, b) => a.name.localeCompare(b.name)):[],
              },
              () => {
                this.calculateTheContractAlerts();
                this.handleAmountsCalculation();
                this.handleTotalAmountCalculation();
                //this.handleSavingDocumentStatus(response.data);
              }
            );
            if (this.state.contract.category) {
              getOtherClientContracts(this.state.contract.category.id).then(
                (response) => {
                  this.setState({
                    ...this,
                    clientOtherContracts: response.data ? response.data.sort((a, b) => a.name.localeCompare(b.name)):[],
                  });
                }
              );
            }
          }
        );
      },
      (error) => {
        ErrorAlert.show(error?.response?.data?.message || error?.response?.data, "Erreur API");
        setTimeout(() => {
          this.props.history.push("/home");
        }, 3000);
      }
    );
  }

  getContractAmountType(initialAmount) {
    if (initialAmount) {
      if (initialAmount.value?.type) {
        return initialAmount.value.type;
      }
    }
    return 1;
  }

  getTheContractAmountTerm(initialAmount) {
    if (initialAmount) {
      if(initialAmount.isRestricted){
        if (initialAmount.value?.term) 
          return initialAmount.value.term;
        else
          return initialAmount.value
      }else if (initialAmount.value?.term) {
        return initialAmount.value.term;
      }
    }
    return 1;
  }

  getInformationsKey(contract) {
    var i;
    var informationKeys = [];

    if(contract && contract.keywords && contract.keywords['clientKeywords'] && contract.keywords.clientKeywords.length>0)
      contract.keywords['clientKeywords'].sort((a, b) => a.name.localeCompare(b.name))
    //informationKeys.push(contract.keywords.clientKeywords);
    for (i = 0; i < contract.keywords.clientKeywords.length; i++) {
      informationKeys.push({
        label: contract.keywords.clientKeywords[i].name,
        description: contract.keywords.clientKeywords[i].comment,
        fieldName: contract.keywords.clientKeywords[i].name,
        value: contract.keywords.clientKeywords[i].id,
        type: "clientKeyword",
        isdate: contract.keywords.clientKeywords[i].isDate,
        synonyms: contract.keywords.clientKeywords[i].synonyms,
        isFirstPage: contract.keywords.clientKeywords[i].isFirstPage,
        isRequired: contract.keywords.clientKeywords[i].isObligatory,
        keywordType: contract.keywords.clientKeywords[i].type ? contract.keywords.clientKeywords[i].type: "Non associé"
      });
    }

    if(contract && contract.keywords && contract.keywords['docAtWebKeywords'] && contract.keywords.docAtWebKeywords.length>0)
      contract.keywords['docAtWebKeywords'].sort((a, b) => a.name.localeCompare(b.name))
    for (i = 0; i < contract.keywords.docAtWebKeywords.length; i++) {
      if (contract.keywords.docAtWebKeywords[i].isObligatory === false) {
        informationKeys.push({
          label: contract.keywords.docAtWebKeywords[i].name,
          description: contract.keywords.docAtWebKeywords[i].comment,
          fieldName: contract.keywords.docAtWebKeywords[i].name,
          value: contract.keywords.docAtWebKeywords[i].id,
          type: "docAtWebKeyword",
          isdate: contract.keywords.docAtWebKeywords[i].isDate,
          synonyms: contract.keywords.docAtWebKeywords[i].synonyms,
          isFirstPage: contract.keywords.docAtWebKeywords[i].isFirstPage,
          keywordType: contract.keywords.docAtWebKeywords[i].type ? contract.keywords.docAtWebKeywords[i].type: "Non associé"
        });
      }
    }
    return informationKeys;
  }

  getKeywordsValues(contract) {
    var i;
    var informationKeys = [];
    for (i = 0; i < contract.keywordsValues.length; i++) {
      if (
        contract.keywordsValues[i].value.keywordType === "DOCATWEB" &&
        contract.keywordsValues[i].keyword.isObligatory === false
      ) {
        informationKeys.push({
          keywordId: (contract.keywordsValues[i] !== undefined && contract.keywordsValues[i] && contract.keywordsValues[i].value !== undefined && contract.keywordsValues[i].value.id !== undefined) ? contract.keywordsValues[i].value.id : null,
          label: contract.keywordsValues[i].keyword.name,
          description: contract.keywordsValues[i].keyword.comment,
          fieldName: contract.keywordsValues[i].keyword.name,
          value: contract.keywordsValues[i].keyword.id,
          type: "docAtWebKeyword",
          keywordValue: contract.keywordsValues[i].value.value,
          isdate: contract.keywordsValues[i].keyword.isDate,
          isFirstPage: contract.keywordsValues[i].keyword.isFirstPage,
          isRestricted:(contract.keywordsValues[i] && contract.keywordsValues[i].isRestricted !== undefined && ((contract.keywordsValues[i].isRestricted).toString() === "1" || (contract.keywordsValues[i].isRestricted).toString() === "true")) ? true:false
        });
      } else if (contract.keywordsValues[i].value.keywordType === "CLIENT") {
        informationKeys.push({
          keywordId: (contract.keywordsValues[i] !== undefined && contract.keywordsValues[i] && contract.keywordsValues[i].value !== undefined && contract.keywordsValues[i].value.id !== undefined) ? contract.keywordsValues[i].value.id : null,
          label: contract.keywordsValues[i].keyword.name,
          description: contract.keywordsValues[i].keyword.comment,
          fieldName: contract.keywordsValues[i].keyword.name,
          value: contract.keywordsValues[i].keyword.id,
          type: "clientKeyword",
          keywordValue: contract.keywordsValues[i].value.value,
          isdate: contract.keywordsValues[i].keyword.isDate,
          isFirstPage: contract.keywordsValues[i].keyword.isFirstPage,
          isRequired: contract.keywordsValues[i].keyword.isObligatory,
          synonyms: contract.keywordsValues[i].keyword.synonyms,
          isRestricted:(contract.keywordsValues[i] && contract.keywordsValues[i].isRestricted !== undefined && ((contract.keywordsValues[i].isRestricted).toString() === "1" || (contract.keywordsValues[i].isRestricted).toString() === "true")) ? true:false
        });
      }
    }
    return informationKeys;
  }

  //handleChangeInput = e => {
  //   this.form.validateFields(e.target);
  changeEditMode = (checked) => {
    this.setState({
      ...this,
      editMode: checked,
    });
  };

  changeDisputedMode = (checked) => {
      this.setState({
        ...this,
        errorMsgLitige: null,
      });
      if(!this.state.contract.dispute['date'] || this.state.contract.dispute['date'] === undefined || this.state.contract.dispute['date'] === null || this.state.contract.dispute['date'] === ""){
        // toast.warn('Date litige requise', {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        this.setState({
          ...this,
          errorMsgLitige: 'Date litige requise',
        });
        return false;
      }

      if(!this.state.contract.dispute['reason'] || this.state.contract.dispute['reason'] === undefined || this.state.contract.dispute['reason'] === null || this.state.contract.dispute['reason'] === ""){
        // toast.warn('Motif litige requis', {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        this.setState({
          ...this,
          errorMsgLitige: 'Motif litige requis',
        });
        return false;
      }

      let disputedAt = checked && this.state.contract.dispute['date'] ? this.state.contract.dispute['date']:(checked && (!this.state.contract.dispute || (this.state.contract.dispute && this.state.contract.dispute['date'] == undefined)) ? Moment(Moment(new Date(),"DD/MM/YYYY").toDate()).format("DD/MM/YYYY"):Moment(Moment(new Date(),"DD/MM/YYYY").toDate()).format("DD/MM/YYYY"))
      
      toggleContractDispute(this.state.contract?.id, checked ? 1:0, this.state.contract.dispute['reason'], disputedAt).then(
        (response) => {
          this.setState({
            ...this,
            showModalLitige:false,
            contract:{
              ...this.state.contract,
              dispute:{
                ...this.state.contract.dispute,
                isDisputed: checked ? 1:0
              }      
            }
          });
          toast.success(checked ? 'Litige ouvert avec succès':'Litige cloturé avec succès', {
            position: toast.POSITION.TOP_RIGHT
          });
        },
        (error) => {
          this.setState({
            ...this,
            contract:{
              ...this.state.contract,
              dispute:{
                ...this.state.contract.dispute,
                isDisputed: 0
              }      
            }
          });
          ErrorAlert.show(error, "Erreur API");
        })
    
    
  };

  handleChangeInput = (e) => {
    this.setState({
      ...this.state,
      contract: {
        ...this.state.contract,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeDate = (name, value) => {
    var dateState = this.state[name];
    if (value) {
      dateState.value = Moment(value).format("DD/MM/YYYY");
    } else {
      dateState.value = null;
    }
    this.setState(
      {
        ...this,
        [name]: dateState,
      },
      () => {
        setTimeout(() => {
          this.handleDatesCalculationV2(true, false);
        }, 1000);
      }
    );
    setTimeout(() => {
      this.calculateTheContractAlerts();
    }, 1000);
  };

  handleDatesCalculation() {
    let contractDuration = this.state.contractDuration?.value;
    if (contractDuration.hasOwnProperty("duration")) {
      contractDuration = this.state.contractDuration.value.duration;
    }
    if (this.state.beginingDate.value) {
      if (!(!this.state.endingDate && !contractDuration)) {
        calculateTheContractEndingDateAndDuration(
          contractDuration,
          this.state.contractDurationType,
          this.state.beginingDate?.value,
          this.state.endingDate?.value
        ).then(
          (response) => {
            if (!this.state.endingDate.value) {
              let endingDateValue = this.state.endingDate;
              endingDateValue.value = response.data.endingDate;
              this.setState({
                ...this,
                endingDate: endingDateValue,
              });
            } else if (!contractDuration) {
              let contractDurationValue = this.state.contractDuration;
              contractDurationValue.value = response.data.contractDuration;
              this.setState({
                ...this,
                contractDuration: contractDurationValue,
                contractDurationType: response.data.contractDurationType,
              });
            }
          },
          (error) => {
            console.log("error.response.data", error?.response?.data);
          }
        );
      }
    }
  }

  handleDatesCalculationV2(updatedEndingDate, updatedContractDuration) {
    let contractDuration = this.state.contractDuration?.value;
    if (contractDuration.hasOwnProperty("duration")) {
      contractDuration = this.state.contractDuration.value.duration;
    }
    if (this.state.beginingDate.value) {
      if (updatedEndingDate) {
        calculateTheContractEndingDateAndDuration(
          null,
          null,
          this.state.beginingDate?.value,
          this.state.endingDate?.value
        ).then(
          (response) => {
            let contractDurationValue = this.state.contractDuration;
            contractDurationValue.value = response.data.contractDuration;
            this.setState(
              {
                ...this,
                contractDuration: contractDurationValue,
                contractDurationType: response.data.contractDurationType,
              },
              () => {
                this.calculateTheContractAlerts();
              }
            );
          },
          (error) => {
            console.log("error.response.data", error?.response?.data);
          }
        );
      } else {
        calculateTheContractEndingDateAndDuration(
          contractDuration,
          this.state.contractDurationType,
          this.state.beginingDate?.value
        ).then(
          (response) => {
            let endingDateValue = this.state.endingDate;
            endingDateValue.value = response.data.endingDate;
            this.setState(
              {
                ...this,
                endingDate: endingDateValue,
              },
              () => {
                this.calculateTheContractAlerts();
              }
            );
          },
          (error) => {
            console.log("error.response.data", error?.response?.data);
          }
        );
      }
    }
  }

  handleAmountsCalculation() {
    let contractDuration = this.state.contractDuration?.value;
    if (contractDuration && contractDuration.hasOwnProperty("duration")) {
      contractDuration = this.state.contractDuration.value.duration;
    }
    let dueFrequencyValue = this.state.dueFrequency?.value;
    if (dueFrequencyValue && dueFrequencyValue.hasOwnProperty("duration")) {
      dueFrequencyValue = this.state.dueFrequency.value.duration;
    }
    let initialAmount = this.state.totalAmoutInitialPeriod?.value;
    if (initialAmount && initialAmount.hasOwnProperty("amount")) {
      initialAmount = this.state.totalAmoutInitialPeriod?.value?.amount;
    }
    if (contractDuration && dueFrequencyValue) {
      if (!(!this.state.dueAmount && !this.state.totalAmoutInitialPeriod)) {
        calculateContractsAmount(
          this.state.contractDuration.value,
          this.state.contractDurationType,
          this.state.dueFrequency.value,
          this.state.dueFrequencyType,
          this.state.dueAmount?.value,
          initialAmount
        ).then(
          (response) => {
            if (!this.state.dueAmount.value) {
              let dueAmountValue = this.state.dueAmount;
              dueAmountValue.value = response.data.dueAmount;
              this.setState({
                ...this,
                dueAmount: dueAmountValue,
              });
            } else if (!initialAmount) {
              let amountValue = this.state.totalAmoutInitialPeriod;
              amountValue.value = response.data.totalInitialAmount;
              this.setState({
                ...this,
                totalAmoutInitialPeriod: amountValue,
              });
            }
          },
          (error) => {
            console.log("error.response.data", error?.response?.data);
          }
        );
      }
    }
  }

  handleTotalAmountCalculation() {
    if (!this.state.amount?.value) {
      let contractDuration = this.state.contractDuration?.value;
      if (contractDuration && contractDuration.hasOwnProperty("duration")) {
        contractDuration = this.state.contractDuration.value.duration;
      }
      let dueFrequencyValue = this.state.dueFrequency?.value;
      if (dueFrequencyValue && dueFrequencyValue.hasOwnProperty("duration")) {
        dueFrequencyValue = this.state.dueFrequency.value.duration;
      }

      if (
        contractDuration &&
        dueFrequencyValue &&
        this.state.dueAmount?.value
      ) {
        let amountState = this.state.amount;
        calculateTheContractTotalAmount(
          this.state.contractDuration.value,
          this.state.contractDurationType,
          this.state.dueFrequency.value,
          this.state.dueFrequencyType,
          this.state.dueAmount?.value
        ).then(
          (response) => {
            amountState.value = response.data;
            this.setState({
              ...this,
              amount: amountState,
            });
          },
          (error) => {
            console.log("error.response.data", error?.response?.data);
          }
        );
      }
    }
  }

  handleContractsStatesUpdate = (e, isFromMaskedField=false) => {
    var inputVal = '';
    var contractState = null;
    if(isFromMaskedField){
      e = {
        target: {
          name: e.name,
          value: e.value
        }
      }
      contractState = this.state[e.target.name]
      inputVal = e.target.value || '';
    }else{
      contractState = this.state[e.target.name]
      inputVal = e.target.value || '';
    }

    if (
      e.target.name === "priorNoticeDuration" ||
      e.target.name === "contractDuration" ||
      e.target.name === "negociationDuration" ||
      e.target.name === "dueFrequency"
    ) {
      inputVal = Math.floor(inputVal.split(",").join(".").replace(/ /g, ""));
    }
    if (
      e.target.name === "amount" ||
      e.target.name === "totalAmoutInitialPeriod" ||
      e.target.name === "dueAmount"
    ) {
      inputVal = inputVal.split(".").join(",").replace(/ /g, "");
    }
    if(e.target.name === "totalAmoutInitialPeriod" && contractState.value instanceof Object  && contractState.value.amount !== undefined){
      contractState.value.amount = isNumeric(inputVal) || inputVal ==='' ? inputVal:0 ;
    }else{
      contractState.value = inputVal;
    }
    this.form.validateFields(e.target);
    this.setState(
      {
        ...this,
        [e.target.name]: contractState,
      },
      () => {
        setTimeout(() => {
          this.handleDatesCalculationV2(false, true);
          this.handleAmountsCalculation();
          this.handleTotalAmountCalculation();
        }, 2000);
      }
    );

    setTimeout(() => {
      this.calculateTheContractAlerts();
    }, 1000);
  };

  handleContractDurationType = (contractDurationType) => {
    this.setState(
      {
        ...this,
        contractDurationType: contractDurationType,
      },
      () => {
        this.handleDatesCalculationV2(false, true);
        if (this.state.contract.status !== 2) {
          this.calculateTheContractAlerts();
        }
      }
    );
  };

  handleDueFrequencyType = (dueFrequencyType) => {
    this.setState({
      ...this,
      dueFrequencyType: dueFrequencyType,
    });
  };

  handleNegociationDurationType = (negociationDurationType) => {
    this.setState(
      {
        ...this,
        negociationDurationType: negociationDurationType,
      },
      () => {
        if (this.state.contract.status !== 2) {
          this.calculateTheContractAlerts();
        }
      }
    );
  };
  handlePriorNoticeType = (priorNoticeDurationType) => {
    this.setState(
      {
        ...this,
        priorNoticeDurationType: priorNoticeDurationType,
      },
      () => {
        if (this.state.contract.status !== 2) {
          this.calculateTheContractAlerts();
        }
      }
    );
  };
  handleRenewalUpdate = (newRenewal) => {
    if(newRenewal == 'Autre'){
      this.setState({
        ...this,
        isRenewOther: true
      });
      newRenewal = null;
    }else{
      this.setState({
        ...this,
        isRenewOther: false
      });
    }
    let renewalState = this.state.renewal;
    renewalState.value = newRenewal;
    this.setState({
      ...this,
      renewal: renewalState,
    });
    /*if(!newRenewal) {
            document.getElementById('renewal-input').value = ''
        }*/
    if(document.getElementById("renewal-input"))
      document.getElementById("renewal-input").value = "";
  };

  getKeywordByName(contract, keywordName) {
    var i;
    var foundValue = false;
    for (i = 0; i < contract.keywordsValues.length; i++) {
      if (contract.keywordsValues[i].keyword.name === keywordName) {
        foundValue = true;
        if (
          keywordName === "Préavis" ||
          keywordName === "Négociation" ||
          keywordName === "Durée" ||
          keywordName === "Fréquence échéances"
        ) {
          return {
            keywordId: (contract.keywordsValues[i] !== undefined && contract.keywordsValues[i] && contract.keywordsValues[i].value !== undefined && contract.keywordsValues[i].value.id !== undefined) ? contract.keywordsValues[i].value.id : null,
            value: contract.keywordsValues[i].value.value && (contract.keywordsValues[i].value.value).trim() !== "" ? JSON.parse(contract.keywordsValues[i].value.value):contract.keywordsValues[i].value.value,
            synonyms: contract.keywordsValues[i].keyword.synonyms,
            description: contract.keywordsValues[i].keyword.comment,
            isFirstPage: contract.keywordsValues[i].keyword.isFirstPage,
            isRestricted:(contract.keywordsValues[i] && contract.keywordsValues[i].isRestricted !== undefined && ((contract.keywordsValues[i].isRestricted).toString() === "1" || (contract.keywordsValues[i].isRestricted).toString() === "true")) ? true:false
          };
        } else if (keywordName === "Frais Installation/FAS") {
          return {
            keywordId: (contract.keywordsValues[i] !== undefined && contract.keywordsValues[i] && contract.keywordsValues[i].value !== undefined && contract.keywordsValues[i].value.id !== undefined) ? contract.keywordsValues[i].value.id : null,
            value:  contract.keywordsValues[i].value.value && (contract.keywordsValues[i].value.value).trim() !== "" ? JSON.parse(contract.keywordsValues[i].value.value):(contract.keywordsValues[i].value.value ? contract.keywordsValues[i].value.value : "0"),
            synonyms: contract.keywordsValues[i].keyword.synonyms,
            updatedByEndorsement:
              contract.keywordsValues[i].value.updatedByEndorsement,
            description: contract.keywordsValues[i].keyword.comment,
            isFirstPage: contract.keywordsValues[i].keyword.isFirstPage,
            isRestricted:(contract.keywordsValues[i] && contract.keywordsValues[i].isRestricted !== undefined && ((contract.keywordsValues[i].isRestricted).toString() === "1" || (contract.keywordsValues[i].isRestricted).toString() === "true")) ? true:false
          };
        } else {
          return {
            keywordId: (contract.keywordsValues[i] !== undefined && contract.keywordsValues[i] && contract.keywordsValues[i].value !== undefined && contract.keywordsValues[i].value.id !== undefined) ? contract.keywordsValues[i].value.id : null,
            value: contract.keywordsValues[i].value.value,
            synonyms: contract.keywordsValues[i].keyword.synonyms,
            description: contract.keywordsValues[i].keyword.comment,
            isFirstPage: contract.keywordsValues[i].keyword.isFirstPage,
            isRestricted:(contract.keywordsValues[i] && contract.keywordsValues[i].isRestricted !== undefined && ((contract.keywordsValues[i].isRestricted).toString() === "1" || (contract.keywordsValues[i].isRestricted).toString() === "true")) ? true:false
          };
        }
      }
    }
    if (!foundValue) {
      for (let j = 0; j < contract.keywords.docAtWebKeywords.length; j++) {
        if (contract.keywords.docAtWebKeywords[j].name === keywordName) {
          if (keywordName === "Négociation") {
            let categoryNegociationData =
              this.calculateContractCategoryDuration(
                contract?.category?.negociationTime,
                contract?.category?.negociationTimeUnit
              );
            //let categoryNegociationData = this.calculateContractCategoryDuration(6, 2)
            return {
              keywordId: (contract.keywordsValues[i] !== undefined && contract.keywordsValues[i] && contract.keywordsValues[i].value !== undefined && contract.keywordsValues[i].value.id !== undefined) ? contract.keywordsValues[i].value.id : null,
              value: {
                duration: categoryNegociationData[0],
                durationType: categoryNegociationData[1],
              },
              synonyms: contract.keywords.docAtWebKeywords[j].synonyms,
              description: contract.keywords.docAtWebKeywords[j].comment,
              isFirstPage: contract.keywords.docAtWebKeywords[j].isFirstPage,
              isRestricted:(contract.keywordsValues[i] && contract.keywordsValues[i].isRestricted !== undefined && ((contract.keywordsValues[i].isRestricted).toString() === "1" || (contract.keywordsValues[i].isRestricted).toString() === "true")) ? true:false
            };
          } else if (
            keywordName === "Préavis" ||
            keywordName === "Durée" ||
            keywordName === "Fréquence échéances"
          ) {
            return {
              keywordId: (contract.keywordsValues[i] !== undefined && contract.keywordsValues[i] && contract.keywordsValues[i].value !== undefined && contract.keywordsValues[i].value.id !== undefined) ? contract.keywordsValues[i].value.id : null,
              value: { duration: null, durationType: null },
              synonyms: contract.keywords.docAtWebKeywords[j].synonyms,
              description: contract.keywords.docAtWebKeywords[j].comment,
              isFirstPage: contract.keywords.docAtWebKeywords[j].isFirstPage,
              isRestricted:(contract.keywordsValues[i] && contract.keywordsValues[i].isRestricted !== undefined && ((contract.keywordsValues[i].isRestricted).toString() === "1" || (contract.keywordsValues[i].isRestricted).toString() === "true")) ? true:false
            };
          } else if (keywordName === "Montant Global du Contrat") {
            return {
              keywordId: (contract.keywordsValues[i] !== undefined && contract.keywordsValues[i] && contract.keywordsValues[i].value !== undefined && contract.keywordsValues[i].value.id !== undefined) ? contract.keywordsValues[i].value.id : null,
              value: "0",
              synonyms: contract.keywords.docAtWebKeywords[j].synonyms,
              description: contract.keywords.docAtWebKeywords[j].comment,
              isFirstPage: contract.keywords.docAtWebKeywords[j].isFirstPage,
              isRestricted:(contract.keywordsValues[i] && contract.keywordsValues[i].isRestricted !== undefined && ((contract.keywordsValues[i].isRestricted).toString() === "1" || (contract.keywordsValues[i].isRestricted).toString() === "true")) ? true:false
            };
          } else if (keywordName === "Frais Installation/FAS") {
            return {
              keywordId: (contract.keywordsValues[i] !== undefined && contract.keywordsValues[i] && contract.keywordsValues[i].value !== undefined && contract.keywordsValues[i].value.id !== undefined) ? contract.keywordsValues[i].value.id : null,
              value: "0",
              synonyms: contract.keywords.docAtWebKeywords[j].synonyms,
              description: contract.keywords.docAtWebKeywords[j].comment,
              isFirstPage: contract.keywords.docAtWebKeywords[j].isFirstPage,
              isRestricted:(contract.keywordsValues[i] && contract.keywordsValues[i].isRestricted !== undefined && ((contract.keywordsValues[i].isRestricted).toString() === "1" || (contract.keywordsValues[i].isRestricted).toString() === "true")) ? true:false
            };
          } else {
            return {
              keywordId: (contract.keywordsValues[i] !== undefined && contract.keywordsValues[i] && contract.keywordsValues[i].value !== undefined && contract.keywordsValues[i].value.id !== undefined) ? contract.keywordsValues[i].value.id : null,
              value: null,
              synonyms: contract.keywords.docAtWebKeywords[j].synonyms,
              description: contract.keywords.docAtWebKeywords[j].comment,
              isFirstPage: contract.keywords.docAtWebKeywords[j].isFirstPage,
              isRestricted:(contract.keywordsValues[i] && contract.keywordsValues[i].isRestricted !== undefined && ((contract.keywordsValues[i].isRestricted).toString() === "1" || (contract.keywordsValues[i].isRestricted).toString() === "true")) ? true:false
            };
          }
        }
      }
    }
    //return {"value": null, "synonyms": []}
  }

  calculateContractCategoryDuration(negociationTime, negociationUnit) {
    if (negociationUnit === 3) {
      return [negociationTime, 1];
    } else if (negociationUnit === 2) {
      if (negociationTime % 4 === 0) {
        return [negociationTime / 4, 1];
      } else {
        return [negociationTime * 7, 0];
      }
    } else if (negociationUnit === 1) {
      return [negociationTime, 0];
    } else {
      return [negociationTime * 12, 1];
    }
  }

  getDurationType(keywordDurationValues) {
    if (keywordDurationValues) {
      if (keywordDurationValues.value?.durationType !== null) {
        return keywordDurationValues.value.durationType;
      }
    }
    return 1;
  }

  getContractDurationType(keywordDurationValues) {
    if (keywordDurationValues) {
      if (keywordDurationValues.value?.durationType !== null) {
        return keywordDurationValues.value.durationType;
      }
    }
    return 2;
  }

  handleCategoryUpdate = (e) => {
    this.form.validateFields(e.target);
    this.setState(
      {
        ...this,
        contract: {
          ...this.state.contract,
          [e.target.name]: e.target.value,
          subCategory: null,
        },
        selectedCategory: e.target.value,
        selectedSubCategory: null,
      },
      () => {
        this.setNewNegociationTime();
      }
    );
    this.setSubCategoriesList(e.target.value);
    let clientKeywords = [];
    getOtherClientContracts(e.target.value).then((response) => {
      this.setState({
        ...this,
        clientOtherContracts: response.data ? response.data.sort((a, b) => a.name.localeCompare(b.name)):[],
        selectedParentContract: null,
        contract: {
          ...this.state.contract,
          parentContract: null,
        },
      });
    });
    var informationKeys = [];
    getClientKeywordByCategory(e.target.value).then((response) => {
      clientKeywords = response.data;
      var i;
      let contract = this.state.contract;

      for (i = 0; i < response.data.length; i++) {
        informationKeys.push({
          label: response.data[i].name,
          description: response.data[i].comment,
          fieldName: response.data[i].name,
          value: response.data[i].id,
          type: "clientKeyword",
          isdate: response.data[i].isDate,
          synonyms: response.data[i].synonyms,
          isFirstPage: response.data[i].isFirstPage,
        });
      }
      for (i = 0; i < contract.keywords.docAtWebKeywords.length; i++) {
        if (contract.keywords.docAtWebKeywords[i].isObligatory === false) {
          informationKeys.push({
            label: contract.keywords.docAtWebKeywords[i].name,
            description: contract.keywords.docAtWebKeywords[i].comment,
            fieldName: contract.keywords.docAtWebKeywords[i].name,
            value: contract.keywords.docAtWebKeywords[i].id,
            type: "docAtWebKeyword",
            isdate: contract.keywords.docAtWebKeywords[i].isDate,
            synonyms: contract.keywords.docAtWebKeywords[i].synonyms,
            isFirstPage: contract.keywords.docAtWebKeywords[i].isFirstPage,
          });
        }
      }

      this.setState(
        {
          ...this,
          informationsKey: informationKeys,
        },
        () => {
          let isFound = false;
          let extraKeywordsWithValues = this.state.extraKeywordsWithValues;
          for (var i = 0; i < extraKeywordsWithValues.length; i++) {
            isFound = false;
            let j = 0;
            let informationKey = clientKeywords;
            for (j = 0; j < informationKey.length; j++) {
              if (informationKey[j].id === extraKeywordsWithValues[i].value) {
                isFound = true;
              }
            }
            if (!isFound) {
              extraKeywordsWithValues.splice(i);
            }
          }
          this.setState({
            ...this,
            extraKeywordsWithValues: extraKeywordsWithValues,
          });
        }
      );
    });
  };

  setNewNegociationTime() {
    var i;
    let negociationState = this.state.negociationDuration;
    for (i = 0; i < this.state.categories.length; i++) {
      if (this.state.categories[i].id === this.state.selectedCategory) {
        let categoryNegociationData = this.calculateContractCategoryDuration(
          this.state.categories[i]?.negociationTime,
          this.state.categories[i]?.negociationTimeUnit
        );
        negociationState.value = categoryNegociationData[0];
        this.setState(
          {
            ...this,
            negociationDuration: negociationState,
            negociationDurationType: categoryNegociationData[1],
          },
          () => {}
        );
        return 0;
      }
    }
  }

  handleSubCategoryUpdate = (e) => {
    this.form.validateFields(e.target);
    this.setState({
      ...this,
      contract: {
        ...this.state.contract,
        [e.target.name]: e.target.value,
      },
      selectedSubCategory: e.target.value,
    });
  };

  setSubCategoriesList(categoryID) {
    var i;
    for (i = 0; i < this.state.categories.length; i++) {
      if (this.state.categories[i].id.toString() == categoryID.toString()) {
        this.setState({
          ...this,
          subCategories: this.state.categories[i].subCategories ? this.state.categories[i].subCategories.sort((a, b) => a.name.localeCompare(b.name)):[],
        });
      }
    }
  }

  handleNonObligatoryKeywords(keyword, e) {
    let keywordsWithValues = this.state.extraKeywordsWithValues;
    if (e?.target?.value) {
      keyword.keywordValue = e.target.value;
    } else {
      keyword.keywordValue = e;
    }
    if(typeof keyword['keywordValue'] === 'object'){
      keyword.keywordValue = ''
    }

    let newKeywordValuesList = this.setTheKeywordValue(
      keyword,
      keywordsWithValues
    );
    this.setState({
      ...this,
      extraKeywordsWithValues: newKeywordValuesList,
    });
  }

  setTheKeywordValue(keyword, keywordsWithValues) {
    let foundKeyword = false;
    if (keyword.isdate === true) {
      if (keyword.keywordValue) {
        //keyword.keywordValue = Moment(keyword.keywordValue).format('DD/MM/YYYY')
        keyword.keywordValue = keyword.keywordValue;
      } else {
        keyword.keywordValue = "";
      }
    }
    for (let i = 0; i < keywordsWithValues.length; i++) {
      if (keywordsWithValues[i].value === keyword.value) {
        keywordsWithValues[i].keywordValue = keyword.keywordValue;
        foundKeyword = true;
      }
    }
    if (foundKeyword === false) {
      keywordsWithValues.push(keyword);
    }
    return keywordsWithValues;
  }

  changeInformationsKey = (list) => {
    let newKeywordsWithValuesList = this.updateSelectedKeywords(list);

    if (this.state.extraKeywordsWithValues) {
      this.state.extraKeywordsWithValues.map((elm) => {
        let exists = list.find((o) => {
          return o.value === elm.value;
        });
        if (!exists) this.deleteOldMarker(elm.value);
      });
    }

    this.setState(
      {
        ...this.state,
        contract: {
          ...this.state.contract,
          informationsKey: list,
        },
        extraKeywordsWithValues: newKeywordsWithValuesList,
      },
      () => {
        $(".scrolling-verticaly").scrollTop(
          $(".scrolling-verticaly")[0].scrollHeight
        );
        // if(this.state.contract.status && this.state.contract.status.toString() === '2')
        //   this.validate(false,'',false)
        // else
        //   this.putInHold(false, false)
      }
    );
  };

  updateSelectedKeywords(newKeywordsList) {
    let oldKeywordsWithValues = this.state.extraKeywordsWithValues;
    let newKeywordsWithValuesList = [];
    for (let i = 0; i < oldKeywordsWithValues.length; i++) {
      let foundKeyword = false;
      for (let j = 0; j < newKeywordsList.length; j++) {
        if (oldKeywordsWithValues[i].value === newKeywordsList[j].value) {
          foundKeyword = true;
        }
      }
      if (foundKeyword === true) {
        newKeywordsWithValuesList.push(oldKeywordsWithValues[i]);
      }
    }

    for (let i = 0; i < newKeywordsList.length; i++) {
      let foundKeyword = false;
      for (let j = 0; j < newKeywordsWithValuesList.length; j++) {
        if (newKeywordsList[i].value === newKeywordsWithValuesList[j].value) {
          foundKeyword = true;
        }
      }
      if (foundKeyword === false) {
        newKeywordsList[i].keywordValue = "";
        newKeywordsWithValuesList.push(newKeywordsList[i]);
      }
    }
    return newKeywordsWithValuesList;
  }

  unselectInformationKey = (index) => {
    let items = this.state.contract.informationsKey;
    items[index].unselected = !items[index].unselected;
    this.setState({
      ...this.state,
      contract: {
        ...this.state.contract,
        informationsKey: items,
      },
    });
  };


  checkBeforeValidateFields = async () => {
    let requiredFilds = []
    if(!this.state.contract?.name)
      requiredFilds.push("Nom de contrat")
    if(!this.state.contractCode?.value)
      requiredFilds.push("Nº de contrat")
    if(!this.state.signatory1?.value)
      requiredFilds.push("Nom Signataire Fournisseur")
    if(!this.state.signatory2.value)
      requiredFilds.push("Nom Signataire interne")
    if(!this.state.objet.value )
      requiredFilds.push("Objet")
    if(!this.state.dueAmount?.value)
      requiredFilds.push("Mt échéances")
    if(!this.state.dueFrequency?.value?.duration)
      requiredFilds.push("Fréquence")
    if(!this.state.amount?.value)
      requiredFilds.push("Montant total marché ou budget")
    if(!this.state.totalAmoutInitialPeriod?.value)
      requiredFilds.push("Frais installation/FAS")
    if(!this.state.beginingDate?.value)
      requiredFilds.push("Date début")
    if(!this.state.contractDuration?.value)
      requiredFilds.push("Durée de contrat")
    if(!this.state.endingDate?.value)
      requiredFilds.push("Date de fin")
    if(!(this.state.priorNoticeDuration || this.state.priorNoticeDuration.value.duration))
      requiredFilds.push("Préavis")
    if(!(this.state.negociationDuration.value.duration || this.state.negociationDuration?.value))
      requiredFilds.push("Durée de négo.")
    if(!(this.state.validatedAlerts))
      requiredFilds.push("Confirmation de correction des dates")

    // Clauses perso

    this.state.extraKeywordsWithValues.forEach(x=>{
      if(x.isRequired && (!x.keywordValue || x.keywordValue === null || x.keywordValue === "")){
        requiredFilds.push(x.label)
      }
    })

    if (requiredFilds.length<1) {
      return false;
    }
    ErrorAlert.show(
      "Vous devez renseigner toutes les clauses obligatoires pour pouvoir valider et/ou signer le contrat. Les clauses suivants sont manquantes : ",
      "Informations obligatoires manquantes",
      "D'ACCORD",
      null,
      requiredFilds
    );
    return true;
  };

  putInHold = async (isEndorsement = false, showValidationMsg = true, addedtoNego = false) => {

    if(this.state.contract && (this.state.contract.htmlValidationContent === null || this.state.contract.htmlValidationContent === undefined || this.state.contract.htmlValidationContent === "")){
      ErrorAlert.show(
        "Le document contrat est requise",
        "Attention"
      );
      return;
    }
   
    if(this.state.priorNoticeDuration && this.state.priorNoticeDuration.value){
      if(parseInt(this.state.priorNoticeDuration.value)<1 ||
      parseInt(this.state.priorNoticeDuration.value.duration)<1){
        ErrorAlert.show(
          "Le préavis ne doit pas être inférieur à 1",
          "Attention"
        );
        return;
      }
    }
    this.resetSearch();
    if (this.state.contract?.status !== 2) {
      let amountTest = this.state.totalAmoutInitialPeriod?.value;
      if (this.state.totalAmoutInitialPeriod?.value?.amount) {
        amountTest = this.state.totalAmoutInitialPeriod.value.amount;
      }
      if (
        false &&
        this.state.amount.value &&
        this.state.amount.value !== "" &&
        this.state.totalAmoutInitialPeriod.value &&
        this.state.totalAmoutInitialPeriod.value !== "" &&
        parseInt(this.state.amount.value) < parseInt(amountTest)
      ) {
        ErrorAlert.show(
          "Le montant total ne doit pas être inférieur au montant des frais installation/FAS",
          "Attention"
        );
      } else {
        LoaderComponent.show();
        this.setState({
          ...this.state,
          savingDocument: true,
        });
        //ConfirmAlert.show("Voulez vous vraiment executer cette fonction ?").then(()=>{
        saveContractDraft(
          this.state.contract,
          this.state.signatory1?.value,
          this.state.signatory2?.value,
          this.state.amount?.value,
          this.state.contractDuration?.value,
          this.state.priorNoticeDuration.value,
          this.state.negociationDuration?.value,
          this.state.endingDate?.value,
          this.state.negociationBeginingDate?.value,
          this.state.contractDurationType,
          this.state.negociationDurationType,
          this.state.priorNoticeDurationType,
          this.state.priorNoticeDate?.value,
          this.state.extraKeywordsWithValues && this.state.extraKeywordsWithValues.length>0 ? this.state.extraKeywordsWithValues.filter(x=>x.keywordValue !== undefined):[],
          document.querySelector("#pdf-contentV2").innerHTML,
          this.state.beginingDate?.value,
          this.state.objet?.value,
          this.state.contractCode?.value,
          this.state.renewal?.value,
          this.state.dueAmount?.value,
          this.state.dueFrequency?.value,
          this.state.dueFrequencyType,
          (typeof this.state.totalAmoutInitialPeriod?.value === 'object' && this.state.totalAmoutInitialPeriod?.value !== null)?
           this.state.totalAmoutInitialPeriod?.value.amount : this.state.totalAmoutInitialPeriod?.value,
          this.state.initialAmountType,
          this.state.term,
          this.state.contractAlerts
        ).then(
          (response) => {
            if (!isEndorsement) {
              LoaderComponent.hide();
            }
            if(showValidationMsg){
              NotifyAlert.show(
                "Vos modifications sont enregistrées avec succès",
                "Mise en attente"
              );
            }
            this.setState({
              ...this.state,
              savingDocument: false,
            });
          },
          (error) => {
            LoaderComponent.hide();
            ErrorAlert.show(error?.response?.data, "Erreur API");
          }
        );
        if (isEndorsement) {
          this.setState({
            ...this,
            avtChecked: !this.state.avtChecked,
          });
          setTimeout(() => {
            //LoaderComponent.show();
            createEndorsement(
              this.state.contract?.hachedId,
              this.state.contract?.parentContract
            ).then((response) => {
              LoaderComponent.hide();
              this.props.history.push(
                "/manage-contract/endorsements?contract=" +
                  this.state.contract.hachedId+"&editMode=1"
              );
            });
          }, 1000);
        }
        if(addedtoNego){
            LoaderComponent.hide();
           //Redirect to page nego
           this.props.history.push("/manage-contract/negociations?contract="+this.state.contract?.hachedId);
        }
        //});
      }
    } else {
      this.resetContractData();
    }
  };

  handleSavingDocumentStatus(contract) {
    let that = this;
    if (this.state.savingDocument === true) {
      const interval = setInterval(function () {
        handleContractOcerizationContentInTheBackGround(
          contract.hachedId,
          1
        ).then((response) => {
          if (response.data.updatedHTMLValidationStatus === 0) {
            that.setState({
              ...this.state,
              savingDocument: false,
            });
            /*this.setState({
                            ...this.state,
                            contract:{
                                ...contract,
                                htmlValidationContent: response.data.newFileContent
                            },
                        }, () => {
                            this.extractMarkers();
                        })*/
            clearInterval(interval);
          }
        });
      }, 3000);
    }
  }
  validate = async (sign=false, allGuests='', showValidationMsg = true) => {
    
    this.handleClose();

    let checkF = await this.checkBeforeValidateFields()

    if(checkF){
      return true
    }

    if(this.state.priorNoticeDuration && this.state.priorNoticeDuration.value){
      if(parseInt(this.state.priorNoticeDuration.value)< 1 ||
      parseInt(this.state.priorNoticeDuration.value.duration)<1){
        ErrorAlert.show(
          "Le préavis ne doit pas être inférieur à 1",
          "Attention"
        );
        return;
      }
    }

    if(this.state.contract && (this.state.contract.htmlValidationContent === null || this.state.contract.htmlValidationContent === undefined || this.state.contract.htmlValidationContent === "")){
      ErrorAlert.show(
        "Le document contrat est requise",
        "Attention"
      );
      return;
    }
    
    if (
      this.state.contract &&
      this.state.contract?.category &&
      this.state.contract?.category !== "" &&
      this.state.signatory1?.value &&
      this.state.signatory2.value &&
      this.state.contractDuration?.value &&
      this.state.priorNoticeDuration?.value &&
      this.state.negociationDuration?.value &&
      this.state.endingDate?.value &&
      this.state.objet?.value &&
      this.state.contractCode?.value &&
      /*this.state.renewal?.value &&*/ this.state.dueAmount?.value &&
      this.state.dueFrequency?.value &&
      this.state.totalAmoutInitialPeriod?.value &&
      this.state.extraKeywordsWithValues &&
      this.state.contractAlerts &&
      this.state.validatedAlerts === true &&
      this.state.beginingDate?.value
    ) {
      this.resetSearch();
      let amountTest = this.state.totalAmoutInitialPeriod?.value;
      if (this.state.totalAmoutInitialPeriod?.value?.amount) {
        amountTest = this.state.totalAmoutInitialPeriod.value.amount;
      }
      if (
        this.state.amount.value &&
        this.state.amount.value !== "" &&
        this.state.totalAmoutInitialPeriod.value &&
        this.state.totalAmoutInitialPeriod.value !== "" &&
        parseInt(this.state.amount.value) < parseInt(amountTest)
      ) {
        ErrorAlert.show(
          "Le montant total ne doit pas être inférieur au montant total période initial",
          "Attention"
        );
      } else {
        if (
          (this.state.selectedCategory &&
            this.state.contract?.ifContratIsContractParentToOtherContracts ===
              1) ||
          (this.state.selectedSubCategory &&
            this.state.contract?.ifContratIsContractParentToOtherContracts ===
              1)
        ) {
          ConfirmAlert.show(
            "Vous avez modifié la catégorie ou la sous catégorie du contrat que les avenants rattachés seront mis à jour?"
          ).then(() => {
            LoaderComponent.show();
            this.setState({
              ...this.state,
              savingDocument: true,
            });
            validateContract(
              this.state.contract,
              this.state.signatory1?.value,
              this.state.signatory2?.value,
              this.state.amount?.value,
              this.state.contractDuration?.value,
              this.state.priorNoticeDuration?.value,
              this.state.negociationDuration?.value,
              this.state.endingDate?.value,
              this.state.negociationBeginingDate?.value,
              this.state.contractDurationType,
              this.state.negociationDurationType,
              this.state.priorNoticeDurationType,
              this.state.priorNoticeDate?.value,
              this.state.extraKeywordsWithValues && this.state.extraKeywordsWithValues.length>0 ? this.state.extraKeywordsWithValues.filter(x=>x.keywordValue !== undefined):[],
              document.querySelector("#pdf-contentV2").innerHTML,
              this.state.contractAlerts,
              this.state.beginingDate?.value,
              this.state.objet?.value,
              this.state.contractCode?.value,
              this.state.renewal?.value,
              this.state.dueAmount?.value,
              this.state.dueFrequency?.value,
              this.state.dueFrequencyType,
              (typeof this.state.totalAmoutInitialPeriod?.value === 'object' && this.state.totalAmoutInitialPeriod?.value !== null)?
              this.state.totalAmoutInitialPeriod?.value.amount : this.state.totalAmoutInitialPeriod?.value,
              this.state.initialAmountType,
              this.state.term,
              sign,
              allGuests,
              this.state.canDuplicateName,
              this.state.canDuplicateNumber
            ).then(
              (response) => {
                this.handleClose()
                LoaderComponent.hide();
                if(showValidationMsg){
                  NotifyAlert.show(
                    "La validation du contrat s'est terminée avec succès",
                    "Validation"
                  );
                }
                this.setState({
                  ...this.state,
                  savingDocument: false,
                });
               
                if (sign) {
                  toast.success('Contrat envoyés pour signature avec succès', {
                    position: toast.POSITION.TOP_RIGHT
                  });
                }
                this.setState({
                  ...this.state,
                  contract: {
                    ...this.state.contract,
                    status: sign ? 3:2,
                    //ifContratIsContractParentToOtherContracts: response.data.ifContratIsContractParentToOtherContracts
                  },
                  editMode: showValidationMsg ? false: this.state.editMode,
                });
              },
              (error) => {
                LoaderComponent.hide();
                let msgError = ''
                if(error && error.response && error.response !== undefined && error.response !== null && error.response.data && error.response.data !== undefined && error.response.data !== null && error?.response?.data instanceof Array && error.response.data.length>0){
                  if (error.response.data[0].includes("portant le meme nom exist déjà"))
                    msgError = "Un autre contrat ou avenant porte le même nom. Veuillez le modifier pour pouvoir valider."
                  else
                    msgError = error?.response?.data[0]
                }else{
                  msgError = error?.response?.data
                }
                if (msgError !== undefined && msgError !== null && typeof msgError === "string" && msgError.includes("portant le meme numéro exist déjà")){
                  checkContractParameters(
                    this.state.contract.id,
                    this.state.contract.name,
                    this.state.contractCode.value).then(
                      (res) => {
                        let response = res.data
                        if (response.numberExists !== null) {
                          ConfirmAlert.show(
                            response.numberExists + " , voulez-vous continuer à utiliser le même numéro?",
                            "Attention",
                            'Oui',
                            'Non',
                          ).then(yes => {
                            this.setState({
                              canDuplicateNumber: true
                            },()=> {
                                this.validate(sign, allGuests, showValidationMsg)
                            })
                          }, no => {
                            this.setState({
                              canDuplicateNumber: false
                            },()=> ErrorAlert.show(msgError, "ERREUR VALIDATION CONTRAT"))
                          });
                        } else {
                          this.setState({
                            canDuplicateNumber: false
                          },()=> ErrorAlert.show(msgError, "ERREUR VALIDATION CONTRAT"))
                        }
                      }
                    )
                
                }else{
                  ErrorAlert.show(msgError, "ERREUR VALIDATION CONTRAT");
                }
              }
            );
          });
        } else {
          LoaderComponent.show();
          this.setState({
            ...this.state,
            savingDocument: true,
          });
          validateContract(
            this.state.contract,
            this.state.signatory1?.value,
            this.state.signatory2?.value,
            this.state.amount?.value,
            this.state.contractDuration?.value,
            this.state.priorNoticeDuration?.value,
            this.state.negociationDuration?.value,
            this.state.endingDate?.value,
            this.state.negociationBeginingDate?.value,
            this.state.contractDurationType,
            this.state.negociationDurationType,
            this.state.priorNoticeDurationType,
            this.state.priorNoticeDate?.value,
            this.state.extraKeywordsWithValues && this.state.extraKeywordsWithValues.length>0 ? this.state.extraKeywordsWithValues.filter(x=>x.keywordValue !== undefined):[],
            document.querySelector("#pdf-contentV2").innerHTML,
            this.state.contractAlerts,
            this.state.beginingDate?.value,
            this.state.objet?.value,
            this.state.contractCode?.value,
            this.state.renewal?.value,
            this.state.dueAmount?.value,
            this.state.dueFrequency?.value,
            this.state.dueFrequencyType,
            (typeof this.state.totalAmoutInitialPeriod?.value === 'object' && this.state.totalAmoutInitialPeriod?.value !== null)?
           this.state.totalAmoutInitialPeriod?.value.amount : this.state.totalAmoutInitialPeriod?.value,
            this.state.initialAmountType,
            this.state.term,
            sign,
            allGuests,
            this.state.canDuplicateName,
            this.state.canDuplicateNumber
          ).then(
            (response) => {
              this.handleClose()
              LoaderComponent.hide();
              if(showValidationMsg){
                NotifyAlert.show(
                  "La validation du contrat s'est terminée avec succès",
                  "Validation"
                );
              }
              this.setState({
                ...this.state,
                savingDocument: false,
              });
              
              if (sign) {
                toast.success('Contrat envoyés pour signature avec succès', {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
              this.setState({
                ...this.state,
                contract: {
                  ...this.state.contract,
                  status: sign ? 3:2,
                },
                editMode: showValidationMsg ? false: this.state.editMode,
              });
            },
            (error) => {
              LoaderComponent.hide();
              let msgError = ''
              if(error && error.response && error.response !== undefined && error.response !== null && error.response.data && error.response.data !== undefined && error.response.data !== null && error?.response?.data instanceof Array  && error.response.data.length>0){
                if (error.response.data[0].includes("portant le meme nom exist déjà"))
                  msgError = "Un autre contrat ou avenant porte le même nom. Veuillez le modifier pour pouvoir valider."
                else
                  msgError = error?.response?.data[0]
              }else{
                msgError = error?.response?.data
              }
              if (msgError !== undefined && msgError !== null && typeof msgError === "string" && msgError.includes("portant le meme numéro exist déjà")){
                checkContractParameters(
                  this.state.contract.id,
                  this.state.contract.name,
                  this.state.contractCode.value).then(
                    (res) => {
                      let response = res.data
                      if (response.numberExists !== null) {
                        ConfirmAlert.show(
                          response.numberExists + " , voulez-vous continuer à utiliser le même numéro?",
                          "Attention",
                          'Oui',
                          'Non',
                        ).then(yes => {
                          this.setState({
                            canDuplicateNumber: true
                          },()=> {
                              this.validate(sign, allGuests, showValidationMsg)
                          })
                        }, no => {
                          this.setState({
                            canDuplicateNumber: false
                          },()=> ErrorAlert.show(msgError, "ERREUR VALIDATION CONTRAT"))
                        });
                      } else {
                        this.setState({
                          canDuplicateNumber: false
                        },()=> ErrorAlert.show(msgError, "ERREUR VALIDATION CONTRAT"))
                      }
                    }
                  )
              
              }else{
                ErrorAlert.show(msgError, "ERREUR VALIDATION CONTRAT");
              }
            }
          );
        }
      }
    }
  };
  manageValidateButton = () => {
    if (
      this.state.contract &&
      this.state.contract?.name &&
      this.state.contract?.category &&
      this.state.contract?.category !== "" &&
      this.state.signatory1?.value &&
      this.state.signatory2.value &&
      this.state.contractDuration?.value &&
      this.state.priorNoticeDuration?.value &&
      this.state.negociationDuration?.value &&
      this.state.endingDate?.value &&
      this.state.objet?.value &&
      this.state.contractCode?.value /*&& this.state.renewal?.value*/ &&
      this.state.dueAmount?.value &&
      this.state.dueFrequency?.value &&
      this.state.totalAmoutInitialPeriod?.value &&
      this.state.extraKeywordsWithValues &&
      this.state.contractAlerts &&
      this.state.validatedAlerts === true &&
      this.state.beginingDate?.value
    ) {
      return false;
    }
    return true;
  };

  ///// Viewer
  downloadContract = () => {
    downloadContractFromS3Server(this.state.contract?.id);
  };

  downloadSignedContract = () => {
    if(this.state.contract?.isSignatory || (this.state.contract.status === 2 && this.state.negociationsDetail))
      downloadSignedContractFromDocusignServer(this.state.contract?.id);
    else
      toast.warn("Le processus de signature est incomplet", {
        position: toast.POSITION.TOP_RIGHT
      });
  };

  printContract = () => {
    this.pdfContent.handlePrint();
  };

  /*findSynonyme = (e, synonymes)=>{
        console.log('synonymes', synonymes)
        if(e.target.value.length === 0 && synonymes.join(' ').trim() !== ''){
            this.searchInContract(null, synonymes)*/
  findSynonyme = (e, synonymes, isFirstPage = false) => {
    if (e.target.value.length === 0 || e.target.value == 0 || e.target.value == '0') {
      if (synonymes.length > 0) {
        this.setState(
          {
            ...this.state,
            search: {
              ...this.state.search,
              currentOccurence: 0,
              totalOccurence: 0,
              priority: 1,
            },
          },
          () => this.searchInContract(null, synonymes)
        );
      }
      if (isFirstPage) $(".pdf-viewer").animate({ scrollTop: 0 }, 300);
    }
  };

  searchInContract = (e, synonymes = null) => {
    // this.panPinchRef.resetZoom();
    try {
      let synonymesList = synonymes
      ? synonymes
      : [{ label: e.target.value, priority: this.state.search.priority || 1 }];

      if(synonymes === null && (e.target.value == null || e.target.value == "")){
        this.resetSearch()
      }
      
      if (synonymesList.length > 0) {
        this.markInstance = new Mark(document.querySelector("#pdf-content"));
        this.markInstance.unmark({
          done: (d) => {
            let totalOccurence = 0;
            let keyWordslabels = synonymesList
              .filter((item) => item.priority === this.state.search.priority)
              .map((keyWord) => keyWord.label);
            this.markInstance.mark(keyWordslabels, {
              separateWordSearch: false,
              acrossElements: true,
              done: (countResults) => {
                if (countResults > 0) {
                  totalOccurence = $("mark.marked").length;
                  this.setState({
                    ...this.state,
                    search: {
                      ...this.state.search,
                      currentOccurence: totalOccurence > 0 ? 1 : 0,
                      totalOccurence: totalOccurence,
                      synonymes: synonymesList,
                    },
                  });
                  //TODO: Here i should do the sort
                  this.setActiveSearchOccurent(0);
                } else {
                  if (this.state.search.priority < 3) {
                    this.setState(
                      {
                        ...this.state,
                        search: {
                          ...this.state.search,
                          priority: this.state.search.priority + 1,
                          currentOccurence: 0,
                          totalOccurence: 0,
                          synonymes: []
                        },
                      },
                      () => {
                        if(e !== undefined && synonymesList.length > 0){
                          if (this.markInstance) 
                            this.markInstance.unmark();
                          this.searchInContract(e, synonymes);
                        }
                      }
                    );
                  }
                }
              },
              each: (elem) => {
                keyWordslabels.map((label) => {
                  const canMarked = (string) => {
                    let txt = accents.remove(string.toLowerCase().trim());
                    let str = accents.remove($(elem).text().toLowerCase().trim());
                    return txt.startsWith(str);
                  };
                  if (canMarked(label)) {
                    $(elem).addClass("marked");
                  }
                });
              },
            });
          },
        });
      } else {
        if (this.markInstance) {
          this.markInstance.unmark();
        }
        this.setState({
          ...this.state,
          search: {
            ...this.state.search,
            currentOccurence: 0,
            totalOccurence: 0,
          },
        });
      }
      
    } catch (error) {
      
    }
  };
  resetSearch = () => {
    if (this.markInstance) {
      this.markInstance.unmark();
      this.searchInput.value = "";
      this.setState({
        ...this.state,
        search: {
          ...this.state.search,
          currentOccurence: 0,
          totalOccurence: 0,
        },
      });
    }
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      });
    });
  };

  setActiveSearchOccurent = (index) => {
    // Remove the "active" class from all elements with data-markjs
    $("#pdf-content mark[data-markjs]").removeClass("active");

    // Get the current element at the specified index
    let current = $("#pdf-content mark[data-markjs].marked:eq(" + index + ")");

    // Add the "active" class to the current element
    current.addClass("active");

    // Calculate the scroll position
    let fieldPosition =
      (current.parents("div.pf").position()?.top || 0) +
      (current.parents("div.t").position()?.top || 0);

    // Scroll to the element with animation
    $(".pdf-viewer").animate(
      {
        scrollTop: fieldPosition,
      },
      300
    );
  };

  prevFindedSearch = () => {
    let currentOccurence = this.state.search.currentOccurence;
    if (currentOccurence > 1) {
      this.setState(
        {
          ...this.state,
          search: {
            ...this.state.search,
            currentOccurence: currentOccurence - 1,
          },
        },
        () => {
          this.setActiveSearchOccurent(currentOccurence - 2);
        }
      );
    } else {
      this.searchInContractByPriority("prev");
    }
  };
  nextFindedSearch = () => {
    let currentOccurence = this.state.search.currentOccurence;
    let totalOccurence = this.state.search.totalOccurence;
    if (currentOccurence < totalOccurence) {
      this.setState(
        {
          ...this.state,
          search: {
            ...this.state.search,
            currentOccurence: currentOccurence + 1,
          },
        },
        () => {
          this.setActiveSearchOccurent(currentOccurence);
        }
      );
    } else {
      this.searchInContractByPriority("next");
    }
  };

  searchInContractByPriority = (type) => {
    const { synonymes, priority } = this.state.search;

    if (type === "next") {
      let nextPriority = (priority + 1)> 3 ? 3 : priority + 1;
      let existsNextPriority = synonymes?.find(
        (item) => item.priority === nextPriority
      );
      if (existsNextPriority) {
        this.setState(
          {
            ...this.state,
            search: {
              ...this.state.search,
              priority: nextPriority,
            },
          },
          () => {
            this.searchInContract(null, this.state.search.synonymes);
          }
        );
      }
    } else {
      let prevPriority = (priority - 1)< 1 ? 1 : priority - 1;
      if (priority > 1) {
        this.setState(
          {
            ...this.state,
            search: {
              ...this.state.search,
              priority: prevPriority,
            },
          },
          () => {
            this.searchInContract(null, this.state.search.synonymes);
          }
        );
      }
    }
  };

  extractMarkers = () => {
    $(".pdf-viewer").animate(
      {
        scrollTop: 0,
      },
      0
    );

    let markers = [];
    $(".pdf-content")
      .find(".highlight-text")
      .each((index, elm) => {
        let markerId = $(elm).attr("dataid");
        let existsMarker = markers.find((m) => {
          return m.id === markerId;
        });
        if (markerId && !existsMarker) {
          let marker = {
            id: markerId,
            fieldName: $(elm).attr("fieldName"),
            top:
            $(elm).parents(".pf").position().top +
            ($(elm).parents("div.t").position().top + $(elm).position().top) + 25
          };
          markers.push(marker);
          this.setState({
            ...this.state,
            markers: markers,
          });
        }
      });
  };

  getSelection = (e) => {
    this.selectedText = window.getSelection().toString().trim();
  };

  setTheNewSelectedText(inputField, inputType) {
    let inputValue;
    if (inputField === "name") {
      let contract = this.state.contract;
      contract[inputField] = this.selectedText;
      this.setState({
        ...this.state,
        contract: contract,
      });
    } else {
      let keywordState = this.state[inputField];
      if (inputType === "date") {
        let dateObject = Moment(this.selectedText, "DD/MM/YYYY");
        if (!dateObject.isValid()) return false;
        inputValue = dateObject.format("DD/MM/YYYY");
      } else if (inputType === "number") {
        let num = parseFloat(
          this.selectedText.replace(/[^\d\.,]*/g, "").replace(",", ".")
        );
        console.log('num',num)
        if (!isNaN(num)) {
          inputValue = num;
        }
      } else {
        inputValue = this.selectedText;
      }
      if (
       inputField === "amount" ||
       inputField === "totalAmoutInitialPeriod" ||
       inputField === "dueAmount"
      ) {
        let tmpValue = this.state[inputField]
        if(inputField === "totalAmoutInitialPeriod" && tmpValue.value.amount !== undefined ){
          tmpValue.value.amount = inputValue
        }else{
          tmpValue.value = inputValue
        }
        this.setState(
          {
            ...this,
            [inputField]: tmpValue,
          },
          () => {
            setTimeout(() => {
              this.handleDatesCalculationV2(false, true);
              this.handleAmountsCalculation();
              this.handleTotalAmountCalculation();
            }, 2000);
          }
        );
    
        setTimeout(() => {
          this.calculateTheContractAlerts();
        }, 1000);
      }


      if (
        inputField === "priorNoticeDuration" ||
        inputField === "contractDuration" ||
        inputField === "negociationDuration" ||
        inputField === "dueFrequency"
      ) {
        inputValue = "{" + '"duration":"' + Math.floor(inputValue) + '"' + "}";
        keywordState.value = JSON.parse(inputValue);
        this.setState(
          {
            ...this,
            [inputField]: keywordState,
          },
          () => {
            this.handleDatesCalculationV2(
              inputType === "date",
              !(inputType === "date")
            );
          }
        );

        setTimeout(() => {
          this.calculateTheContractAlerts();
        }, 1000);
      } else {
        keywordState.value = inputValue;
        this.setState(
          {
            ...this,
            [inputField]: keywordState,
          },
          () => {
            this.handleDatesCalculationV2(
              inputType === "date",
              !(inputType === "date")
            );
          }
        );
        setTimeout(() => {
          this.calculateTheContractAlerts();
        }, 1000);
      }
    }
  }

  deleteOldMarker = (inputField) => {
    let oldMarker = $("#pdf-content").find(
      'span[fieldname="' + inputField + '"]'
    );
    if (oldMarker[0]) {
      let oldMarkerId = oldMarker.attr("dataid");
      $(".vertical-line")
        .find("button[dataid=" + oldMarkerId + "]")
        .remove();
      $.each(oldMarker, (k, elem) => {
        $(elem).replaceWith($(elem).text());
      });
    }
  };

  setTheNonObligatoryKeywordValueFromSelection(keyword) {
    this.handleNonObligatoryKeywords(keyword, this.selectedText);
  }

  setSelectedText = async (
    inputField,
    inputType = "text",
    isOtherKeyword = false
  ) => {
    await this.resetSearch();
    let marker = null;
    let isError = false;
    let isAlreadyValidated = this.state.contract?.status === 2 && inputField !== "contractCode" && inputField.type === undefined  ? true :false
    
    if(isAlreadyValidated){
      this.selectedText = ""
      window.getSelection().removeAllRanges()
      toast.warn("Clause non modifiable car le contrat est déjà validé", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    if (inputType === "date" && !isAlreadyValidated) {
      if(this.selectedText)
        this.selectedText = await this.convertDate(this.selectedText);
      if (!Moment(this.selectedText, "DD/MM/YYYY").isValid()) {
        isError = true;
      }
    }
    if (inputType === "number" && !isAlreadyValidated) {
      if (
        inputField === "priorNoticeDuration" ||
        inputField === "contractDuration" ||
        inputField === "negociationDuration" ||
        inputField === "dueFrequency"
      ) {
        let csDuration =  await this.convertStringToDuration(this.selectedText)
        this.selectedText = csDuration && csDuration.value ? csDuration.value:this.selectedText
        let tmpDuration = this.state[inputField]
        let inputDurationValue = "{" + '"duration":"' + Math.floor(csDuration.value) + '"' + "}";
        tmpDuration.value = JSON.parse(inputDurationValue);
        this.setState(
          {
            ...this,
            [inputField]: tmpDuration,
            [`${inputField}Type`]:csDuration.type
          }
        );
       

      }

      if(this.selectedText)
        this.selectedText = this.convertOrdinalNumberToNumber(this.selectedText.toString().replace(/[^\d\.,]*/g, "").replace(",", "."))

      let num = parseFloat(
        this.selectedText
      );
      if (isNaN(num)) {
        isError = true;
      }
    }
    if (this.selectedText && !isAlreadyValidated) {
      if (!this.state.editMode) {
        this.editModeReminder();
        return;
      }
      if (!isOtherKeyword) {
        if (
          this.state.contract?.status === 2 &&
          inputField !== "name" &&
          inputField !== "contractCode"
        )
          return;
        this.deleteOldMarker(inputField);
        // if (!isError) {
          this.setTheNewSelectedText(inputField, inputType);
        // }
        marker = {
          id: uuid(),
          fieldName: inputField,
        };
      } else {
        this.deleteOldMarker(inputField.value);
        if (!isError) {
          this.setTheNonObligatoryKeywordValueFromSelection(inputField);
        }
        marker = {
          id: uuid(),
          fieldName: inputField.value,
        };
      }
      ///

      const selection = window.getSelection();
      if (!selection.isCollapsed) {
        const range = selection.getRangeAt(0);
        highlightRange(range, "span", {
          class: "highlight-text",
          dataId: marker.id,
          fieldName: marker.fieldName,
        });
        document.getSelection().empty();
      }

      setTimeout(() => {
        let field = $($("[dataId=" + marker.id + "]")[0]);
        let textPosition =
          field.parents(".pf").position()?.top +
          (field.parents("div.t").position()?.top + field.position()?.top);
        marker.top = textPosition;
        this.insertMarker(marker);
      });

      this.selectedText = "";
    } else {
      if (!isOtherKeyword) {
        this.locateTextInDocument(inputField);
      } else {
        this.locateTextInDocument(inputField.value);
      }
    }
  };

  locateTextInDocument = (fieldName) => {
    this.panPinchRef.resetZoom();
    this.state.markers.map((marker) => {
      if (marker.fieldName.toString() === fieldName.toString()) {
        let field = $('span[fieldname="' + fieldName + '"]');
        const targetElement = document.querySelector('span[fieldname="' + fieldName + '"]');
        if (!field[0]) return;
        // let fieldParent = $('#form-validate-contract');
        let fieldPosition =
          $(field[0]).parents(".pf").position().top +
          $(field[0]).parents("div.t").position().top +
          $(field[0]).position().top;
        $(".pdf-viewer").animate(
          {
            scrollTop: fieldPosition,
          },
          'smooth',
          () => {
            if (targetElement) {
              const position = targetElement.getBoundingClientRect();
              console.log('Position:', position);
      
              targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
              const targetRect = targetElement.getBoundingClientRect();
              const scrollOffset = targetRect.top; // Retrieve the scroll position of the target element
              console.log('scrollOffset', scrollOffset)
              document.querySelector(".pdf-viewer").scrollBy({
                top: scrollOffset - 600,
                behavior: 'smooth',
              });
            }
            field.addClass("focus");
            $(".vertical-line button[dataId=" + marker.id + "]").addClass(
              "focus"
            );
            setTimeout(() => {
              field.removeClass("focus");
              $(".vertical-line button[dataId=" + marker.id + "]").removeClass(
                "focus"
              );
            }, 1400);
          }
        );
      }
    });
  };

  insertMarker = (marker) => {
    let markers = this.state.markers;
    markers.push(marker);
    this.setState({
      ...this.state,
      markers: markers,
    });
  };

  scrollToField = (marker) => {
    // Check if an element with the specified name attribute exists
    let targetField = $("[name='" + marker.fieldName + "']").eq(0);

    if (targetField.length > 0) {
      // Find the parent container of the target field
      let field = targetField.parents(".input-item");

      // Find the container that should be scrolled
      let fieldParent = $(".scrolling-verticaly");

      // Calculate the scroll position relative to the parent container
      let fieldPosition = field.position().top;

      // Scroll to the field position with animation
      fieldParent.animate(
        {
          scrollTop: fieldPosition,
        },
        400,
        () => {
          // Add the "focus" class to the target field
          targetField.addClass("focus");

          // Remove the "focus" class after a delay (1.25 seconds in this case)
          setTimeout(() => {
            targetField.removeClass("focus");
          }, 1250);
        }
      );
    }
  };

  onScrolling = (e, side) => {
    if (e.target.class !== "scrolling-verticaly") return;
    let scrolling = this.state.scrolling;
    scrolling[side] = e.target.scrollTop > 100 ? true : false;
    this.setState({
      ...this.state,
      scrolling: scrolling,
    });
  };

  handleScroll = event => {
    const nbPages = event.currentTarget.querySelector("div#page-container").children.length;
    let scrollTop = event.currentTarget.scrollTop;
    let offsetHeight = event.currentTarget.querySelector("div#page-container").offsetHeight/nbPages;
    if(this.state.contract.nbPages !== nbPages){
      this.setState(
        {
          ...this.state,
          contract: {
            ...this.state.contract,
            nbPages: nbPages,
          },
        }
      )
    }

    if(scrollTop>(offsetHeight*this.state.currentPage)){
      let currentP = this.state.currentPage + 1;
      this.setState({
        ...this,
        currentPage:currentP
      })
    }
    else if( this.state.currentPage>1){
      if(parseInt((scrollTop/offsetHeight).toFixed(0))+1 <  parseInt(this.state.currentPage)){
        let currentP = this.state.currentPage - 1;
        this.setState({
          ...this,
          currentPage:currentP
        })
      }
    }

  };

  getContractDurationLabelClassName(labelValue) {
    if (this.state.contractDurationType == labelValue) {
      return "btn active";
    } else {
      return "btn";
    }
  }
  getDueFrequencyLabelClass(labelValue) {
    if (this.state.dueFrequencyType == labelValue) {
      return "btn active";
    } else {
      return "btn";
    }
  }

  getNegociationDurationLabelClassName(labelValue) {
    if (this.state.negociationDurationType == labelValue) {
      return "btn active";
    } else {
      return "btn";
    }
  }
  getPriorNoticeLabelClassName(labelValue) {
    if (this.state.priorNoticeDurationType == labelValue) {
      return "btn active";
    } else {
      return "btn";
    }
  }

  getRenewalClassName(labelValue) {
    if (labelValue !== "None") {
      if (this.state.renewal?.value == labelValue) {
        return "btn active";
      } else {
        return "btn";
      }
    } else {
      if (
        this.state.renewal?.value != "Tacite" &&
        this.state.renewal?.value != "Expresse" &&
        this.state.renewal?.value != "Prorogation"
      ) {
        return "btn active";
      } else {
        return "btn";
      }
    }
  }

  displayContractHTMLContent() {
    const htmlPages = [];
    if (this.state.contract.htmlValidationContent) {
      //return <div> {this.state.contract.htmlValidationContent} </div>
      //return <div dangerouslySetInnerHTML={{__html: this.state.contract.htmlValidationContent}} />
      return (
        <div
          className="pdf-content"
          id="pdf-content"
          onMouseUp={(e) => this.getSelection(e)}
          ref={this.pdfContent}
          dangerouslySetInnerHTML={{
            __html: this.state.contract.htmlValidationContent,
          }}
        ></div>
      );
    }
    /*if(this.state.contract.fileContent) {
        for (let i = 0; i < this.state.contract.fileContent.length; i++) {
            htmlPages.push(
                <div className="page">
                 <div dangerouslySetInnerHTML={{__html: this.state.contract.fileContent[i]}} />
                </div>
            );
        }
        return htmlPages;
        }*/
  }

  calculateTheContractAlerts() {
    if (
      this.state.contract.hachedId &&
      this.state.endingDate?.value &&
      this.state.negociationDuration?.value &&
      this.state.priorNoticeDuration?.value
    ) {
      calculateTheContractAlerts(
        this.state.contract.hachedId,
        this.state.endingDate.value,
        this.state.negociationDuration.value,
        this.state.priorNoticeDuration.value,
        this.state.negociationDurationType,
        this.state.priorNoticeDurationType
      ).then((response) => {
        this.setState({
          ...this,
          contractAlerts: response.data,
        });
      });
    }
  }

  getNonObligatoryInputObject(keyword) {
    if (keyword.isdate === true) {
      return (
        <DatePicker
          onFocus={(e) => {
            this.findSynonyme(
              e,
              this.getSynonyms(keyword),
              keyword.isFirstPage
            );
          }}
          locale="fr"
          dateFormat="dd/MM/yyyy"
          className={`form-control w-100 ${
            this.eligibilityCheck().write ?
            (keyword?.isRequired && !keyword.keywordValue && this.state.editMode ? "required-field" : (keyword?.isRequired && keyword.keywordValue  && this.state.editMode ? "success-field" : "")):
            "restricted-input copy-field"
          }`}
          autoComplete="off"
          selected={
            keyword.keywordValue && keyword.keywordValue !== "Invalid date"
              ? Moment(keyword.keywordValue, "DD/MM/YYYY").toDate()
              : Moment(
                new Date(),
                "DD/MM/YYYY"
              ).toDate()
          }
          name={keyword.value}
          onChange={(e) => {
            this.handleNonObligatoryKeywords(keyword, Moment(e).format("DD/MM/YYYY"));
          }}
          readOnly={!this.eligibilityCheck("extraKeywordsWithValues").write}
          onClick={() => this.copyToClipBoard(keyword.description, keyword.keywordValue)}
        />
      );
    }
    return (
      <textarea
        onFocus={(e) => {
          this.findSynonyme(e, this.getSynonyms(keyword), keyword.isFirstPage);
        }}
        className={`form-control w-100 ${
          this.eligibilityCheck().write ?
          (keyword?.isRequired && !keyword.keywordValue && this.state.editMode ? "required-field" : (keyword?.isRequired && keyword.keywordValue  && this.state.editMode ? "success-field" : "")):
          "restricted-input copy-field"
        }`}
        name={keyword.value}
        value={keyword.keywordValue}
        onChange={(e) => {
          this.handleNonObligatoryKeywords(keyword, e);
        }}
        readOnly={!this.eligibilityCheck("extraKeywordsWithValues").write}
        onClick={() => this.copyToClipBoard(keyword.description, keyword.keywordValue)}
      />
    );
  }

  manageValidateAlertsButton() {
    this.setState({
      ...this,
      validatedAlerts: !this.state.validatedAlerts,
    });
  }

  getSynonyms(keyword) {
    return keyword.synonyms || [];
  }

  isEndorsement = (e) => {
    ///// save data in draft
    //this.putInHold()
    //ConfirmAlert.show("Voulez vous vraiment executer cette fonction ?").then(()=>{
    /// redirect to endorsements page
    if (this.state.contract) {
      if (e.target.checked) {
        this.setState({
          ...this,
          initialAmountType: 2,
        },()=>{
          this.putInHold(true, false);
        });
        /*setTimeout(()=>{
                    createEndorsement(this.state.contract?.hachedId, this.state.contract?.parentContract).then((response) => {
                        this.props.history.push("/manage-contract/endorsements?contract="+this.state.contract.hachedId);
                     });
                },1000)*/
      }
    }
    //});
  };

  ifDurationObjectExists(durationObject) {
    if (durationObject?.value) {
      if (durationObject.value.hasOwnProperty("duration")) {
        if (durationObject.value.duration) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  ifPriorNoticeObjectExists(durationObject) {
    if(durationObject?.value == 0) {
      return true;
    }
    if (durationObject?.value) {
      if (durationObject.value.hasOwnProperty("duration")) {
        if(durationObject.value.duration == 0) {
          return true;
        }
        if (durationObject.value.duration) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  ifAmountObjectExists(amountObject) {
    if (amountObject?.value) {
      if (amountObject.value.hasOwnProperty("amount")) {
        if (amountObject.value.amount) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  handleContractInitialAmountTerm = (term) => {
    this.setState({
      ...this,
      term: term,
    });
  };

  getKeywordDescription(keyword) {
    if (keyword?.description !== null) {
      return keyword.description;
    }
    return null;
  }

  editModeReminder = () => {
    return;
      ConfirmAlert.show(
        "Voulez-vous activer le mode d'édition pour effectuer des modifications ?",
        "Rappel",
        "Activer",
        "Annuler"
      ).then(() => {
        this.setState({
          editMode: true,
        });
      });
  };

  resiliateContract = (date, penality) => {
    
    const { contract } = this.state;
    ConfirmAlert.show(
        t('common.resiliate.confirm')+date+t('common.resiliate.confirm_following'),
        t('common.modals.confirmation'), t('common.modals.confirm'), t('common.modals.cancel')
    ).then(() => {
      LoaderComponent.show();
      ResiliateContractApi(contract.id, date, penality)
          .then(
              (res) => {
                this.setState(
                    {
                      ...this.state,
                      resiliationDate: date,
                      contract:{
                        ...this.state.contract,
                        penalty: penality,
                      },
                      endingDate: {
                        ...this.state.endingDate, //TODO: Should update contract penalty
                        value: date,
                      },
                      showModalResilationDoc: true
                    },
                    () => NotifyAlert.show(res, "Succès")
                );
              },
              (err) => {
                ErrorAlert.show(err);
              }
          )
          .finally(() => {
            LoaderComponent.hide();
          });
    });
  };

  cancelResiliateContract = () => {
    ConfirmAlert.show(
        t('common.resiliate.cancel')+this.state.endingDate?.value+t('common.resiliate.confirm_following'),
        t('common.modals.confirmation'), t('common.modals.confirm'), t('common.modals.cancel')
    ).then(() => {
      this.setState({
        resiliationDate: null,
      });
      const { contract } = this.state;
      LoaderComponent.show();
      ResiliateContractApi(contract.id, "")
        .then(
          (res) => {
            this.setState(
              {
                resiliationDate: null,
              },
              () => {
                this.componentDidMount();
                NotifyAlert.show(res, "Succès");
              }
            );
          },
          (err) => {
            ErrorAlert.show(err);
          }
        )
        .finally(() => {
          LoaderComponent.hide();
        });
    });
  };

  getPriorNoticeValue() {
    if(this.state.priorNoticeDuration?.value) {
      if(this.state.priorNoticeDuration?.value == 0) {
        return 0;
      }
      if(this.state.priorNoticeDuration.value.hasOwnProperty("duration")) {
        return this.state.priorNoticeDuration.value.duration;
      } else {
        return this.state.priorNoticeDuration.value
      }
    }
    return null;
  }

  // add current contract to negociation
  addToNegociations(){
    if(this.state.editMode){
      ConfirmAlert.show(
        'Etes vous sur de bien vouloir ajouter le présent contrat en négociation?',
        'Mise en négociation'
      ).then(() => {
        if (this.state.contract.status === 2) {
          toast.warn('Vous ne pouvez pas mettre en négociation un contrat déjà validé', {
            position: toast.POSITION.TOP_RIGHT
          });
        } else {
          if (this.state.contract.id) {
            addContractToNegociations(this.state.contract.id).then(
              response => {
                this.setState({
                  ...this.state,
                  contract: {
                    ...this.state.contract,
                    inNegociationBy: response
                  }
                },()=>{
                  this.putInHold(false, false, true);
                })
              },
              err => {
                toast.error(err && err.response.data ? err.response.data : 'Une erreur est survenue lors de la mise en négociation', {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
            );
          } else {
            toast.warn('Une erreur est survenue lors de la mise en négociation', {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
      })
    }else{
      this.editModeReminder()
    }

  }

  onKeyDown(e){
    if (e.key === "Tab" && e.target.name !== undefined) {
      e.preventDefault();
      const fieldName = e.target.name;
      switch (fieldName) {
        case 'name':
          this.doFocus('category')
          break;
        case 'category':
          this.doFocus('subCategory')
          break;
        case 'subCategory':
          this.doFocus('parentContract')
          break;
        case 'parentContract':
          this.doFocus('contractCode')
          break;
        case 'contractCode':
          this.doFocus('objet')
          break;
        case 'objet':
          this.doFocus('signatory1')
          break;
        case 'signatory1':
          this.doFocus('signatory2')
          break;
        case 'signatory2':
          this.doFocus('dueAmount')
          break;
        case 'dueAmount':
          this.doFocus('dueFrequency')
          break;
        case 'dueFrequency':
          this.doFocus('amount')
          break;
        case 'amount':
          this.doFocus('totalAmoutInitialPeriod')
          break;
        case 'totalAmoutInitialPeriod':
          this.doFocus('beginingDate')
          break;
        case 'beginingDate':
          this.doFocus('endingDate')
          break;
        case 'endingDate':
          this.doFocus('trueEndingDate')
          break;
        case 'trueEndingDate':
          this.doFocus('contractDuration')
          break;
        case 'contractDuration':
          this.doFocus('renewal')
          break;
        case 'renewal':
          this.doFocus('priorNoticeDuration')
          break;
        case 'priorNoticeDuration':
          this.doFocus('negociationDuration')
          break;
        case 'negociationDuration':
          this.doFocus('name')
          break;
        default:
          break;
      }
      
    }
  };

  doFocus(fieldName){
    const nextfield = document.querySelector(`[name=${fieldName}]`)
    if (nextfield !== null && nextfield !== undefined) {
      nextfield.focus();
    }
  }


  // Start Date Reconize From String


  async convertDate(str) {
    // create an array of possible month names in French and English
    const frDatePattern = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    let matchFR = frDatePattern.exec(str);
    if (matchFR && str.includes('/')) {
      return str
    }
    const months = {
      "janvier": "January",
      "février": "February",
      "mars": "March",
      "avril": "April",
      "mai": "May",
      "juin": "June",
      "juillet": "July",
      "août": "August",
      "septembre": "September",
      "octobre": "October",
      "novembre": "November",
      "décembre": "December",
      "jan": "January",
      "feb": "February",
      "mar": "March",
      "apr": "April",
      "may": "May",
      "jun": "June",
      "jul": "July",
      "aug": "August",
      "sep": "September",
      "oct": "October",
      "nov": "November",
      "dec": "December"
    };

    // detect and convert French and UK month names into English
    for (const [monthFr, monthEn] of Object.entries(months)) {
      str = str.replace(new RegExp(monthFr, "gi"), monthEn);
      str = str.replace(new RegExp(/(\d+)er|ième/, "gi"), "$1");
      str = str.replace(new RegExp(/(\d+)(st|nd|rd|th)/, "gi"), "$1");
    }
    let splitedDate = str.split('-')
    if(splitedDate && parseInt(splitedDate[0])>12){
      str = splitedDate[1]+'-'+splitedDate[0]+'-'+splitedDate[2]
    }
    // parse the date string and return it as a UK short date (DD/MM/YYYY) string
    const date = new Date(str);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const ukDatePattern = /\d{2}-\d{2}-\d{4}/g;
    let match = ukDatePattern.exec(str);
    if (match && parseInt(day.toString().padStart(2, "0")) <= 12 && parseInt(month.toString().padStart(2, "0")) <= 12) {
      const responseAsync = new Promise((res, rej) => {
        ConfirmAlert.show(
          "Veuillez choisir le format de date souhaité pour la chaine [" + str + "] :",
          "Date:",
          `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`,
          `${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")}/${year}`
        ).then(
          yes => {
            res(date ? `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}` : null);
          }, no => {
            res(date ? `${month.toString().padStart(2, "0")}/${day.toString().padStart(2, "0")}/${year}` : null);
          }
        )
      });
      return responseAsync
    } else {
      return date ? `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}` : null;
    }
  }


  convertOrdinalNumberToNumber(text) {
    const frenchOrdinalNumberPattern = /(\d+)ième/g;
    const ukOrdinalNumberPattern = /(\d+)(st|nd|rd|th)/g;

    // Check for French ordinal number pattern
    let match = frenchOrdinalNumberPattern.exec(text);
    if (match) {
      const number = parseInt(match[1]);
      return isNaN(number) ? text : number;
    }

    // Check for UK ordinal number pattern
    match = ukOrdinalNumberPattern.exec(text);
    if (match) {
      const number = parseInt(match[1]);
      return isNaN(number) ? text : number;
    }

    // No match found
    return text;
  }

  convertStringToDuration(text) {
    var calculedPeriod = {};
    var sourceStr = text
    switch (true) {
      case (/mensuel/i.test(sourceStr)):
        calculedPeriod = {
          value: 1,
          type: 1// 1: Mois | 2: Année
        }
        break;
      case (/bimestriel|bimestr/i.test(sourceStr)):
        calculedPeriod = {
          value: 2,
          type: 1// 1: Mois | 2: Année
        }
        break;
      case (/trimestriel|trimestr/i.test(sourceStr)):
        calculedPeriod = {
          value: 3,
          type: 1// 1: Mois | 2: Année
        }
        break;
      case (/semestriel|semestr/i.test(sourceStr)):
        calculedPeriod = {
          value: 6,
          type: 1// 1: Mois | 2: Année
        }
        break;
      case (/annuel/i.test(sourceStr)):
        calculedPeriod = {
          value: 1,
          type: 2// 1: Mois | 2: Année
        }
        break;
      case ((text.toLowerCase()).includes('mois')):
        calculedPeriod = {
          value: text.replace(new RegExp(/(\d+)mois/, "gi"), "$1"),
          type: 1// 1: Mois | 2: Année
        }
        break;
      case ((text.toLowerCase()).includes('ans')):
        calculedPeriod = {
          value: text.replace(new RegExp(/(\d+)ans/, "gi"), "$1"),
          type: 2// 1: Mois | 2: Année
        }
        break;
      case ((text.toLowerCase()).includes('an')):
        calculedPeriod = {
          value: text.replace(new RegExp(/(\d+)an/, "gi"), "$1"),
          type: 2// 1: Mois | 2: Année
        }
        break;

      default:
        calculedPeriod = text
        break;
    }
    return calculedPeriod

  }


  // End Date Reconize From String

  reconduction(){
    ConfirmAlert.show(
          'Voulez vous vraiment lancer la reconduction pour le présent contrat',
          'Reconduction'
      ).then(() => {
        LoaderComponent.show();
        const url = renewContract(this.state.contract.id, this.state.userRights['hashedId'])
        LoaderComponent.hide();
        window.open(url, '_blank');
        // renewContract(this.state.contract.id, this.state.userRights['hashedId']).then(
        //   response => {
        //     LoaderComponent.hide();
        //     toast.success("Le contrat a été renouvellé avec succès", {
        //       position: toast.POSITION.TOP_RIGHT
        //     });
        //   },
        //   err => {
        //     LoaderComponent.hide();
        //     console.log('err',err)
        //     toast.error(err && err.response && err.response.data ? err.response.data : 'Une erreur est survenue lors du renouvellement de contrat', {
        //       position: toast.POSITION.TOP_RIGHT
        //     });
        //   }
        // );
      });
  }

  handleClickFromFoManagerContract = (bloc) => {
    this.setState({
      ...this.state,
      showBloc: bloc
    })
  }

  manageSignatories = () => {
    let signatoriesListToAddTmp = this.state.signatoriesListToAdd;
    let createdByType = (this.state.userRights && this.state.userRights.type !== undefined) ? this.state.userRights.type : this.state.contract.user.type
    
    if(this.state.action === 'addSignatory'){
      signatoriesListToAddTmp.push(this.state.signatoryObject)
      this.setState({
        ...this.state,
        signatoriesListToAdd:signatoriesListToAddTmp
      })

        let payload = {
          contract:this.state.contract.id,
          signatories: signatoriesListToAddTmp,
          createdByType: createdByType
        }

        setSignatoriesList(payload).then(
            response=>{

              toast.success('Signataire ajouté avec succès', {
                position: toast.POSITION.TOP_RIGHT
              });
              let firstSignatory = this.state.signatory1.value
              let secondSignatory =  this.state.signatory2.value
              if(this.state.signatoriesListToAdd && this.state.signatoriesListToAdd.length>0){
                if(this.state.signatoriesListToAdd.find(x=>x.firstSignatory)){
                  firstSignatory = this.state.signatoriesListToAdd.find(x=>x.firstSignatory)
                }
                if(this.state.signatoriesListToAdd.find(x=>x.secondSignatory)){
                  secondSignatory = this.state.signatoriesListToAdd.find(x=>x.secondSignatory)
                }  
              }
              this.setState({
                ...this.state,
                signatory1:{
                  ...this.state.signatory1,
                  value:firstSignatory && firstSignatory.firstName !== undefined ? firstSignatory.firstName + " " + firstSignatory.lastName: firstSignatory
                },
                signatory2:{
                  ...this.state.signatory2,
                  value:secondSignatory && secondSignatory.firstName !== undefined ? secondSignatory.firstName + " " + secondSignatory.lastName: secondSignatory
                }
              })
              }, error =>{
                toast.error(error && error.response.data ? error.response.data : "Une erreur est survenue lors de l'ajout de signataire", {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
          )

        this.clearSignatoryObject()

    }else{
      let objWithIdIndex = this.state.signatoryIndex
      if (objWithIdIndex > -1) {
        signatoriesListToAddTmp[objWithIdIndex]=this.state.signatoryObject;
        this.setState({
          ...this.state,
          signatoriesListToAdd:signatoriesListToAddTmp,
          objWithIdIndex:-1
        })
      }



        let payload = {
          contract:this.state.contract.id,
          signatories: signatoriesListToAddTmp,
          createdByType:createdByType
        }

        setSignatoriesList(payload).then(
            response=>{

              toast.success('Signataire modifié avec succès', {
                position: toast.POSITION.TOP_RIGHT
              });
              let firstSignatory = this.state.signatory1.value
              let secondSignatory =  this.state.signatory2.value
              if(this.state.signatoriesListToAdd && this.state.signatoriesListToAdd.length>0){
                if(this.state.signatoriesListToAdd.find(x=>x.firstSignatory)){
                  firstSignatory = this.state.signatoriesListToAdd.find(x=>x.firstSignatory)
                }
                if(this.state.signatoriesListToAdd.find(x=>x.secondSignatory)){
                  secondSignatory = this.state.signatoriesListToAdd.find(x=>x.secondSignatory)
                }  
              }
              this.setState({
                ...this.state,
                signatory1:{
                  ...this.state.signatory1,
                  value:firstSignatory && firstSignatory.firstName !== undefined ? firstSignatory.firstName + " " + firstSignatory.lastName: firstSignatory
                },
                signatory2:{
                  ...this.state.signatory2,
                  value:secondSignatory && secondSignatory.firstName !== undefined ? secondSignatory.firstName + " " + secondSignatory.lastName: secondSignatory
                }
              })
              this.clearSignatoryObject()
              }, error =>{
                toast.error(error && error.response.data ? error.response.data : "Une erreur est survenue lors de la modification de signataire", {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
          )


    }


  }

  clearSignatoryObject = () => {
    this.setState({
      ...this.state,
      action:'addSignatory',
      signatoryObject: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        firstSignatory: false,
        secondSignatory: false
      },
      validateForm: false
    })

  }

  signNego = async () => {
    let checkF = await this.checkBeforeValidateFields()

    if(checkF){
      return true
    }

    if(this.state.signatoriesListToAdd && this.state.signatoriesListToAdd.length<2){
      toast.error("Veuillez définir au moins 2 signataires avant de lancer la signature", {
        position: toast.POSITION.TOP_RIGHT
      });
      this.setState({
        ...this.state,
        showBloc: 1
      })
    }else{
      this.handleShow();
    }
  }

  cancelSigning  = () => {
    ConfirmAlert.show("Etes vous sur de bien vouloir annuler le processus de signature?").then(yes => {
      cancelSignature(this.state.contract.id
      ).then(
        response=>{
          toast.success('Processus de signature annulé avec succès', {
            position: toast.POSITION.TOP_RIGHT
          });
          window.location.reload();
          }, error =>{
            toast.error(error && error.response.data ? error.response.data : "Une erreur est survenue lors de l'annulation de signature", {
              position: toast.POSITION.TOP_RIGHT
            });
          }
      )
    }, no =>{

    });
  }

  doSigningRequest = () => {
    doSigningRequest(this.state.contract.id
    ).then(
      response => {
        toast.success('Signataire relancé avec succès', {
          position: toast.POSITION.TOP_RIGHT
        });
      }, error => {
        toast.error(error && error.response.data ? error.response.data : "Une erreur est survenue lors de la relance pour signature", {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    )
  }

  handleClose = () => this.setState(
    {
      ...this.state,
      showModal: false,
      showModalLitige: false,
      showModalResilationDoc: false
    }
  );
  handleShow = () => this.setState(
    {
      ...this.state,
      showModal: true,
      showModalLitige: true
    }
  );
  
  handleShow = () => this.setState(
    {
      ...this.state,
      showModal: true
    }
  );
  handleLitigeModalClose = () => this.setState(
    {
      ...this.state,
      showModalLitige: false
    }
  );
  handleLitigeModalShow = () => this.setState(
    {
      ...this.state,
      showModalLitige: true
    }
  );

  handleChangeDisputedAt = (value) => {
    
    var dateState = null
    if (value) {
      dateState = Moment(value).format("DD/MM/YYYY");
    } 
    console.log('dateState',dateState)
    this.setState(
      {
        ...this.state,
        contract:{
          ...this.state.contract,
          dispute:{
            ...this.state.contract.dispute,
            date: dateState
          }      
        }
      });
  };

  toggleCalendar = (status) => this.setState(
    {
      ...this.state,
      isCalendarOpened: status == 'open' ? true : false
    }
  );

  handleCheckContractParameters = (zone="both", txtValue=null) =>{
    if(this.state.contract.name || this.state.contractCode.value){

      let textValueName = (zone === "contractName") ? txtValue.target.value : txtValue
      let textValueNumber = (zone === "contractNumber" ) ? txtValue.target.value : txtValue
      
      checkContractParameters(
          this.state.contract.id,
          textValueName ? textValueName : this.state.contract.name,
          textValueNumber ? textValueNumber : this.state.contractCode.value).then(
        (res) => {
          let response = res.data
          if(zone == "both" || zone == "contractName"){
            if(response.nameExists !== null){
              ConfirmAlert.show(
                response.nameExists + " , voulez-vous continuer à utiliser le même nom?",
                "Attention",
                'Oui',
                'Non',
              ).then(yes=>{
                this.setState({
                  canDuplicateName: true
                })
  
              },no=>{
                this.setState({
                  canDuplicateName: false
                })
              });
            }else{
              this.setState({
                canDuplicateName: false
              })
            }
          }else if(zone == "both" || zone == "contractNumber"){
            if(response.numberExists !== null){
              ConfirmAlert.show(
                response.numberExists + " , voulez-vous continuer à utiliser le même numéro?" ,
                "Attention",
                'Oui',
                'Non',
              ).then(yes=>{
                this.setState({
                  canDuplicateNumber: true
                })
              },no=>{
                this.setState({
                  canDuplicateNumber: false
                })
              });
            }else{
              this.setState({
                canDuplicateNumber: false
              })
            }
          }
        }
      )
    }
  }

  checkCible = (keyword) =>{
    const element = document.querySelector('span[fieldname="'+ keyword +'"]');
    if(element && element !== undefined && element.getAttribute("fieldname") == keyword){
      return true;
    }else{
      return false;
    }
  }

  eligibilityCheck = (fieldName) =>{
    if(this.state.contract && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'restricted' && this.state.isRestrictedView){
      return {
        read:true,
        write:false
      }
    }else{
      return {
        read:true,
        write:true
      }
    }
  }

  viewAs = (profile=null, isForced=false) => {
    if(this.state.contract && this.state.contract.status.toString() !== '2' && isForced){
      toast.warn('Cette action sera disponible une fois le contrat validé', {
        position: toast.POSITION.TOP_RIGHT
      });
      return {
        restrictedView: false
      }
    }else if(this.state.contract && this.state.contract.user && this.state.contract.accessType && this.state.userRights){
      if(profile == null)
      profile = (this.state.contract.user.id === this.state.userRights.id)? 'owner':'guest'
      
      if(isForced){
        this.setState({
          ...this.state,
          isRestrictedView: !this.state.isRestrictedView
        })
      }else{
        if(profile == 'owner' && (this.state.contract.accessType && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update')){
            return {
              restrictedView: false
            }
        }else if(this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'restricted'){
          if(!this.state.isRestrictedView){
            this.setState({
              ...this.state,
              isRestrictedView: true
            })
          }
          return {
            restrictedView: true
          }
        }else{
          return {
            restrictedView: false
          }
        }
      }

    }
  }

  lockUnlockKeyword = (keywordId, isLocked=false, inputField=null) =>{
    if(this.state.contract && this.state.contract.status.toString() !== '2'){
      toast.warn('Cette action sera disponible une fois le contrat validé', {
        position: toast.POSITION.TOP_RIGHT
      });
    }else if(keywordId == null || keywordId == undefined){
      toast.warn("Sauvegarder d'abord le contrat", {
        position: toast.POSITION.TOP_RIGHT
      });
    }else if(keywordId !== null && inputField !== null){
      if(this.state.editMode){
        updateContractRestriction(keywordId, isLocked).then(
          res=>{
            toast.success(isLocked ? 'Champs verouillé avec succès!': 'Champs devérouillé avec succès!', {
              position: toast.POSITION.TOP_RIGHT
            });
            if(inputField == 'extraKeywordsWithValues'){
              const updatedItems = this.state.extraKeywordsWithValues.map((item) => {
                if (item.keywordId === keywordId) {
                  return { ...item, isRestricted: isLocked };
                }
                return item; 
              });
              this.setState(
                {
                  ...this,
                  [inputField]: updatedItems,
                },
                () => {
                  setTimeout(() => {
                   this.componentDidMount()
                  }, 2000);
                }
              );
            }else{
              this.setState(
                {
                  ...this,
                  [inputField]: {
                    ...this.state[inputField],
                    isRestricted: isLocked
                  },
                },
                () => {
                  setTimeout(() => {
                   this.componentDidMount()
                  }, 2000);
                }
              );
            }
          },err=>{
            toast.error(err && err.response && err.response.data ? err.response.data : 'Une erreur est survenue lors de changement de restriction', {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        )
      }else if(this.state.contract.accessType && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update'){
        this.editModeReminder()
      }
    }
  }

  lockUnlockKeywordsList = (keywordsArray = [], isLocked=false) =>{
    if(this.state.contract && this.state.contract.status.toString() !== '2'){
      toast.warn('Cette action sera disponible une fois le contrat validé', {
        position: toast.POSITION.TOP_RIGHT
      });
    }else if(keywordsArray && (keywordsArray.some(item=> item.keywordId == null) || keywordsArray.some(item=> item.keywordId == undefined))){
      toast.warn("Sauvegarder d'abord le contrat", {
        position: toast.POSITION.TOP_RIGHT
      });
    }else if(keywordsArray && keywordsArray.some(item=> item.keywordId !== null) && keywordsArray.some(item=> item.keywordName !== null)){
      if(this.state.editMode){
        updateContractRestrictionList(keywordsArray, isLocked).then(
          res=>{
            toast.success(isLocked ? 'Champs verouillé avec succès!': 'Champs devérouillé avec succès!', {
              position: toast.POSITION.TOP_RIGHT
            });
            for (let index = 0; index < keywordsArray.length; index++) {
              const element = keywordsArray[index];
              if (element.keywordName == 'extraKeywordsWithValues') {
                const updatedItems = this.state.extraKeywordsWithValues.map((item) => {
                  if (item.keywordId === element.keywordId) {
                    return { ...item, isRestricted: isLocked };
                  }
                  return item;
                });
                this.setState(
                  {
                    ...this,
                    [element.keywordName]: updatedItems,
                  });
              } else {
                this.setState(
                  {
                    ...this,
                    [element.keywordName]: {
                      ...this.state[element.keywordName],
                      isRestricted: isLocked
                    },
                  });

              }
            }
            setTimeout(() => {
              this.componentDidMount()
            }, 3000);
              
          },err=>{
            toast.error(err && err.response && err.response.data ? err.response.data : 'Une erreur est survenue lors de changement de restriction', {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        )
      }else if(this.state.contract.accessType && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update'){
        this.editModeReminder()
      }
    }
  }

  copyToClipBoard = (inputLabel, inputValue) =>{
    if(this.state.contract.accessType && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'restricted'){
      copy(inputValue);
      toast.info(inputLabel + ' copié(e) avec succès', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  countUnValidatedPreviousRows(arr, position) {
    if (position < 0 || position >= arr.length) {
      // Invalid position, return 0 or handle the error as needed.
      return 0;
    }
  
    let count = 0;
  
    // Iterate through the rows before the specified position.
    for (let i = 0; i < position; i++) {
      if (arr[i].hasOwnProperty('isValidated') && arr[i].isValidated === false) {
        count++;
      }
    }
  
    return count;
  }

  handleManageAffected = (index, action=null) =>{
    let affectedList = this.state.contract && this.state.contract["workflow"] ? this.state.contract["workflow"] : []
    if(action === "add"){
      const newObject = { endDate: "", position: index+1, canParticipate: false, affectedBy: this.state.contract["user"]};
      affectedList.splice(index + 1, 0, newObject);
      this.setState({
        ...this.state,
        contract:{
          ...this.state.contract,
          workflow: [...affectedList]
        }
      })
    } else if (action === "validate") {
      const positionToCheck = index; // Change this to the desired position
      const result = this.countUnValidatedPreviousRows(affectedList, positionToCheck);
      if(result === 0){
        // this.newAffectationWorkflow()
        // toast.success("Validation effectué avec succès", {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        this.validateTask()
      }else if(result && result > 0){
        toast.warn("Vous avez déjà " + result + " intervenant(s) en attente de validation, veuillez commencer par le 1er élement non validé de la liste", {
          position: toast.POSITION.TOP_RIGHT
        });
      }else{
        toast.warning("Une erreur est survenue lors de la validation", {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } else if (action === "delete") {
      let indexToRemove = index; 
      let idToBeDeleted = affectedList && affectedList.length>0 && affectedList[indexToRemove].id !== undefined ? affectedList[indexToRemove].id: null;
      if (indexToRemove >= 0 && indexToRemove < affectedList.length) {
        if(idToBeDeleted){
          affectedList.splice(indexToRemove, 1);
          this.setState({
            ...this.state,
            contract: {
              ...this.state.contract,
              workflow: [...affectedList]
            }
          }, () => {})
          // deleteAffectContractByWorkflow(idToBeDeleted).then(
          //   data=>{
          //     console.log("data--->",data)
          //     affectedList.splice(indexToRemove, 1);
          //     this.setState({
          //       ...this.state,
          //       contract:{
          //         ...this.state.contract,
          //         workflow: [...affectedList]
          //       }
          //     },()=>{
          //       toast.success("Suppression effectué avec succès", {
          //         position: toast.POSITION.TOP_RIGHT
          //       });
          //       // affectContractByWorkflow({
          //       //   contractID: this.state.contract.id,
          //       //   assignments: this.state.contract["workflow"]
          //       // }).then((response) => {
          //       //   toast.success("Affectations synchronisés avec succès", {
          //       //     position: toast.POSITION.TOP_RIGHT
          //       //   });
          //       // })
          //     })
          //   }
          // ).catch(error =>{
          //   toast.error(error && error.response.data ? error.response.data : "Une erreur est survenue lors de la suppression", {
          //     position: toast.POSITION.TOP_RIGHT
          //   });
          // })
          
        }else{
          affectedList.splice(indexToRemove, 1);
          this.setState({
            ...this.state,
            contract:{
              ...this.state.contract,
              workflow: [...affectedList]
            }
          },()=>{})
        }
        
      } else {
        alert("Invalid index");
      }
    }
  }

  handleManageAffectedDetailsRow = async (e, index=null, keyName) =>{
    let affectedList = this.state.contract && this.state.contract["workflow"] ? this.state.contract["workflow"] : []
    let newValue =  keyName && keyName.toLowerCase().includes("date") ? e: e.target.value;

    if(keyName == "toggleControl"){
      if(affectedList[index])
        affectedList[index].selected = !affectedList[index].selected
      this.setState({
        ...this.state,
        contract:{
          ...this.state.contract,
          workflow: [...affectedList]
        }
      })
    }else if(keyName == "position"){
      if(newValue == "up"){
        this.setState({
          ...this.state,
          contract:{
            ...this.state.contract,
            workflow: await this.moveRow(affectedList,index,index-1)
          }
        })
      }
      if(newValue == "down"){
        this.setState({
          ...this.state,
          contract:{
            ...this.state.contract,
            workflow: await this.moveRow(affectedList,index,index+1)
          }
        })
      }
    }else{
      if(keyName && keyName.toLowerCase().includes("date")){
        if (newValue) {
          newValue = Moment(newValue).format("DD/MM/YYYY");
        } else {
          newValue = null;
        }
      }
     if (index !== null) {
  
        let indexToEdit = index; 
        if (indexToEdit >= 0 && indexToEdit < affectedList.length) {
          affectedList[indexToEdit][keyName] = newValue || "";
          this.setState({
            ...this.state,
            contract:{
              ...this.state.contract,
              workflow: [...affectedList]
            }
          })
        } else {
          console.log("Invalid index");
        }
      }
    }
  }

  moveRow = async (array, fromIndex, toIndex) => {
    // Check if fromIndex and toIndex are valid
    if (
      fromIndex < 0 ||
      fromIndex >= array.length ||
      toIndex < 0 ||
      toIndex >= array.length ||
      fromIndex === toIndex
    ) {
      console.error("Invalid indices or same position.");
      return array;
    }
    array[fromIndex].selected = false
    array[toIndex].selected = true
    // Remove the row from the current position
    const rowToMove = array.splice(fromIndex, 1)[0];
  
    // Insert the row into the desired position
    array.splice(toIndex, 0, rowToMove);
    array[toIndex].selected = true
    return array
  }

  handleDropdownChange = (selectedOption, position) => {
    if(selectedOption == null){
      affectContractWithoutWorkflow({
        contractID:  this.state.contract.id,
        contributor: null,
        replace: true
      }).then((response) => {
        this.setState({
          ...this,
          contract:{
            ...this.state.contract,
            handledBy: null,
            workflow: null
          }
        },()=>{
          toast.success("Contrat libéré avec succès", "Information", {
            position: toast.POSITION.TOP_RIGHT
          });
        });
      });
  }else{
    let affectedList = this.state.contract && this.state.contract["workflow"] ? this.state.contract["workflow"] : []
      if(affectedList && affectedList.length>1){
        affectedList[position].handledBy = selectedOption
        affectedList[position].position = position>0 ? position:1
        if(!affectedList[position].affectedBy)
          affectedList[position].affectedBy = this.state.contract["user"]
        this.setState({
          ...this,
          contract:{
            ...this.state.contract,
            workflow: [...affectedList]
          }
        })
      }else{
        const newObject = { endDate: "", position: position ? position:1, canParticipate: false, handledBy: selectedOption, affectedBy: this.state.contract["user"]};
        affectedList[0] = newObject;
        this.setState({
          ...this.state,
          contract:{
            ...this.state.contract,
            workflow: affectedList
          }
        })
      }
  }
  };

  newAffectationWorkflow = async () =>{
    ConfirmAlert.show(
      'Voulez vous vraiment mettre à jours le workflow ?',
      'Workflow'
    ).then(async yes => {
        if (this.state.contract && this.state.contract["workflow"]) {
          if (await this.checkAffectations()) {
            affectContractByWorkflow({
              contractID: this.state.contract.id,
              assignments: this.state.contract["workflow"]
            },
            this.state.contract && this.state.contract["handledBy"] !== undefined && this.state.contract["handledBy"] !== null && this.state.contract["handledBy"] !== "" ? true:false
            ).then((response) => {
              if(response && response["data"]){
                this.setState({
                  ...this.state,
                  contract:{
                    ...this.state.contract,
                    workflow: response["data"]
                  }
                })
                toast.success("Affectation effectuée avec succès", "Information", {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
            }, error => {
              toast.error(error && error.response.data ? error.response.data : "Une erreur est survenue lors de l'affectation", {
                position: toast.POSITION.TOP_RIGHT
              });
            });
          } else {
            toast.error("Une erreur est survenue lors de l'affectation", {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
    },no=>{})
  }

  newAffectationNormal = async () =>{
    if(this.state.contract["workflow"] == undefined || this.state.contract["workflow"].length == 0 || this.state.contract["workflow"][0] == undefined || this.state.contract["workflow"][0]["handledBy"] == undefined || this.state.contract["workflow"][0]["handledBy"]["id"] == undefined){
      toast.warning("Affectation echouée (Intervenant requise)", "Attention", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if (this.state.contract && this.state.contract.status < 2) {
      affectContractWithoutWorkflow({
        contractID: this.state.contract.id,
        contributor: this.state.contract["workflow"][0]["handledBy"]["id"],
        replace: false
      }).then((response) => {
        toast.success("Affectation effectuée avec succès", "Information", {
          position: toast.POSITION.TOP_RIGHT
        });
      });
    }else{
      toast.warning("Affectation echouée (Non éligible)", "Attention", {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
  }

  checkAffectations = async () => {
    return new Promise((resolve)=>{
      let workflowList = this.state.contract["workflow"];
      let canSave = true;
      workflowList.forEach((element, index) => {
        workflowList[index].canBeSaved = {}
        if(!element.handledBy || element.handledBy.id === undefined || element.handledBy.id === null || element.handledBy.id === ""){
          workflowList[index].canBeSaved["handledBy"] = false
          canSave = canSave ? false: canSave
        }else{
          workflowList[index].canBeSaved["handledBy"] = true
        }
  
        if((!element.endDate || element.endDate === undefined || element.endDate === null || element.endDate === "") && (!element.id || element.id === undefined || element.id === null || element.id === "")){
          workflowList[index].canBeSaved["endDate"] = false
          canSave = canSave ? false: canSave
        }else{
          workflowList[index].canBeSaved["endDate"] = true
        }
  
        if(element.canParticipate === undefined || element.canParticipate === false || element.canParticipate === null || element.canParticipate === ""){
          workflowList[index].canBeSaved["canParticipate"] = false
          // canSave = canSave ? false: canSave
        }else{
          workflowList[index].canBeSaved["canParticipate"] = true
        }
      });
      this.setState({
        ...this,
        contract:{
          ...this.state.contract,
          workflow: [...workflowList]
        }
      },()=>{
        console.log("canSave",canSave)
        resolve(canSave)
      })
    })
    
  }

  validateTask= () =>{

    ConfirmAlert.show(
      'Voulez vous vraiment mettre fin au traitement au première tâche non encore validé du workflow ?',
      'Traitement workflow'
    ).then(async yes => {
        taskValidationByWorkflow(this.state.contract).then((response) => {
          let workflowList = this.state.contract["workflow"];
          
          // Find the index of the first row where isValidated is false
          const indexToUpdate = workflowList.findIndex(item => item.isValidated === false);
          
          // Update the isValidated property of the first matching row
          if (indexToUpdate !== -1) {
            workflowList[indexToUpdate].isValidated = true; // Change it to true
          }
          this.setState({
            ...this,
            contract:{
              ...this.state.contract,
              workflow: [...workflowList]
            }
          },()=>{
            
            if(this.state.contract.inNegociationBy){
              detailListProposition(this.state.contract.id, this.state.userRights.type).then((propositions) => {
                this.setState({
                  ...this,
                  negociationsDetail:propositions.data
                },()=>{
                  toast.success("Traitement terminé avec succès", "Information", {
                    position: toast.POSITION.TOP_RIGHT
                  });
                });
              });
            }else{
              toast.success("Traitement terminé avec succès", "Information", {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          })
    
        }, error => {
          toast.error(error && error.response.data ? error.response.data : "Une erreur est survenue lors de la validation de traitement", {
            position: toast.POSITION.TOP_RIGHT
          });
        });
    },no=>{})

  }

  suivieCalc = (assignmentObject) => {
    let msg = "-"
    let classCss = "normal"
    if(assignmentObject && assignmentObject.isValidated !== undefined && assignmentObject.isValidated !== null && assignmentObject.endDate !== undefined && assignmentObject.endDate !== null){
      let daysDifference = 0;
      // Define your deadline date (replace with your actual deadline)
      if(assignmentObject.realEndDate && assignmentObject.realEndDate !== null && assignmentObject.realEndDate !== ""){
        const d1 = assignmentObject.realEndDate;
        const parts1 = d1.split('/');
        const realEndDate = new Date(`${parts1[2]}-${parts1[1]}-${parts1[0]}`);
        // Define your deadline date (replace with your actual deadline)
        const d = assignmentObject.endDate;
        const parts = d.split('/');
        const deadlineDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
  
        // Calculate the time difference in milliseconds
        const timeDifference = deadlineDate - realEndDate;
        
        // Convert milliseconds to days
        daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      }else{
        // Define your deadline date (replace with your actual deadline)
        const d = assignmentObject.endDate;
        const parts = d.split('/');
        const deadlineDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
        
        // Calculate the time difference in milliseconds
        const timeDifference = deadlineDate - new Date();
        
        // Convert milliseconds to days
        daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      }
      

      // Determine the status of the task
      if(assignmentObject.isValidated){
        if (daysDifference > 0) {
          msg = `Fini ${Math.abs(daysDifference*1>0?daysDifference:daysDifference*-1)} jours en avance.`;
          classCss="success";
        } else if (daysDifference === 0) {
          // The task is on time
          msg = 'Fini à temps.';
          classCss="success"
        } else {
          msg = `Fini après ${daysDifference*1>0?daysDifference:daysDifference*-1} jours de retard.`;
            classCss='success'
        }
      }else{
        if (daysDifference < 0) {
          // The task is late
          msg = `Retard ${Math.abs(daysDifference*1>0?daysDifference:daysDifference*-1)} jours.`;
          classCss="danger";
        } else if (daysDifference === 0) {
          // The task is on time
          msg = 'Reste 0 jours.';
          classCss="danger"
        } else if (daysDifference<3 && daysDifference>0) {
          // The task is not late but will be completed in the future
          msg = `Reste moins de 3 jours.`;
          if(daysDifference>=0 && daysDifference<=3){
            classCss='warning'
          }
        } else {
          // The task is not late but will be completed in the future
          msg = `Reste ${daysDifference*1>0?daysDifference:daysDifference*-1} jours.`;
          if(daysDifference>=0 && daysDifference<=3){
            classCss='warning'
          }
        }
      }
      


      return(
        <p className={"suivie-"+(classCss)}>{msg} </p>
      )
    }
  }

  isWorkflowActionsAllowed = () => {
    let assignments = this.state.contract && this.state.contract["workflow"] ? this.state.contract["workflow"] : []
    if(assignments.length<=0){
      return false
    }else{
      let unValidatedRows = assignments.filter(element=>(!element.isValidated || element.isValidated == false)) || []
      if(unValidatedRows && unValidatedRows.length>0){
        return true
      }else{
        return false
      }
    }
  }

  isTheLastInvalidatedBuyer = () => {
    let assignments = this.state.contract && this.state.contract["workflow"] ? this.state.contract["workflow"] : []
    if(assignments.length<=0){
      return false
    }else{
      let unValidatedRows = assignments.filter(element=>(!element.isValidated || element.isValidated == false)) || []
      if(unValidatedRows && unValidatedRows.length == 1){
        let lastOne = unValidatedRows[0] || unValidatedRows
        if(lastOne.handledBy !== undefined && this.state.userRights !== undefined && lastOne.handledBy["id"].toString() === this.state.userRights["id"].toString())
          return true
        else
          return false
      }else{
        return false
      }
    }
  }

  handleFavoritedStatusChange = (isFavorited) => {
     if (isFavorited === true) {
      associateContractToFavouritesList(this.state.contract.id).then(
        (response) => {
          this.setState({
            ...this.state,
            contract:{
              ...this.state.contract,
              favorite: true
            }
          },()=>{
            toast.info('Contrat mise en favori avec succès!', {
              position: toast.POSITION.TOP_RIGHT
            });
          });
        },
        (error) => {
          toast.error(error && error.response && error.response.data ? error.response.data : 'Une erreur est survenue lors de la mise du présent contrat en favori', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      );
    } else {
      dissociateContractToFavouritesList(this.state.contract.id).then(
        (response) => {
          this.setState({
            ...this.state,
            contract:{
              ...this.state.contract,
              favorite: false
            }
          },()=>{
            toast.info('Contrat retiré des favoris avec succès!', {
              position: toast.POSITION.TOP_RIGHT
            });
          });
        },
        (error) => {
          toast.error(error && error.response && error.response.data ? error.response.data : 'Une erreur est survenue lors de la mise du présent contrat en favori', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      );
    }
  }


 setDateLimite = (e) =>{
  if(e !== null){
    const newObject = {
       endDate: Moment(e).format("DD/MM/YYYY"),
       position: 0,
       canParticipate: false,
       handledBy: this.state.contract["handledById"] !== undefined ? {id: this.state.contract["handledById"], code: this.state.contract["handledBy"]}: this.state.contract["handledBy"],
       affectedBy: this.state.contract["user"]
    };
    this.setState({
      ...this.state,
      contract:{
        ...this.state.contract,
        workflow: [newObject]
      }
    })
  }
 }

 actionButtonControl = (txtButton) => {
   let actionWorkflowAllowed = (!this.state.contract["workflow"] && this.state.contract["handledBy"] && this.state.contract["handledBy"].id !== undefined && this.state.userRights !== undefined && this.state.contract.handledBy["id"].toString() !== this.state.userRights["id"].toString()  && !this.state.contract.onlyManagerAccess) ? true : (this.isWorkflowActionsAllowed() ? true: false);
   let isLastBuyerWorkflow = this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 && (this.isTheLastInvalidatedBuyer()) ? true : false;
   let isPendingBuyerWorkflow = this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 && (this.state.contract["workflow"].some(item => item.isValidated === false && item.pending === true && (item.handledBy !== undefined && this.state.userRights !== undefined && item.handledBy["id"].toString() === this.state.userRights["id"].toString()))) ? true : false;
   let isParticipateAtWorkflow = this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 && (this.state.contract["workflow"].some(item => item && (item.handledBy !== undefined && this.state.userRights !== undefined && item.handledBy["id"].toString() === this.state.userRights["id"].toString()))) ? true : false;
   let isAffectedByCurrentUser = this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 && (this.state.contract["workflow"].some(item => item && (item.affectedBy !== undefined && this.state.userRights !== undefined && item.affectedBy["id"].toString() === this.state.userRights["id"].toString()))) ? true : false;

  switch (txtButton) {
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case "Enregistrer":
      if (this.state.contract && ((this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 && isPendingBuyerWorkflow) || (!this.state.contract["workflow"] && this.state.contract["handledBy"] && this.state.contract["handledBy"].id !== undefined && this.state.userRights !== undefined && this.state.contract.handledBy["id"].toString() === this.state.userRights["id"].toString()  && !this.state.contract.onlyManagerAccess) || (!this.state.contract["workflow"] && !this.state.contract["handledBy"]) ) && this.state.contract?.status !== 2)
        return {
          isVisibled: true,
          isActionAllowed: true
        }
      else
        return {
          isVisibled: false,
          isActionAllowed: false
        }
      break;
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case "Modifier le contrat":
      if (this.state.contract && this.state.contract.status === 2 && (isLastBuyerWorkflow || !actionWorkflowAllowed || (this.state.userRights['id'])))
        return {
          isVisibled: true,
          isActionAllowed: this.state.contract?.status === 3 ||
            (this.state.contract?.status === 3 && this.manageValidateButton()) ? false : true
        }
      else
        return {
          isVisibled: false,
          isActionAllowed: false
        }
      break;
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case "Valider sans signature":
      if (this.state.contract && this.state.contract.status !== 2 && (isLastBuyerWorkflow || !actionWorkflowAllowed || (this.state.userRights['id'])))
        return {
          isVisibled: true,
          isActionAllowed: this.state.contract?.status === 3 ||
            (this.state.contract?.status === 3 && this.manageValidateButton()) ? false : true
        }
      else
        return {
          isVisibled: false,
          isActionAllowed: false
        }
      break;
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case "Signer":
      if (
        this.state.editMode && this.state.userRights && this.state.userRights.type !== 'GUEST' && ((isLastBuyerWorkflow || !actionWorkflowAllowed) ||
          (this.state.userRights['id'])) && this.state.contract.status < 2
      )
        return {
          isVisibled: true,
          isActionAllowed: (this.manageValidateButton() || (this.state.contract && (this.state.contract.status == 2 || this.state.contract.status == 3))) ? false : true
        }
      else
        return {
          isVisibled: false,
          isActionAllowed: false
        }
      break;
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case "Annuler la signature":
      if (
        this.state.contract && this.state.contract.status === 3 &&
        ((!this.state.contract["workflow"]) || (this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 && (isLastBuyerWorkflow || this.state.contract.isManager))) &&
        this.state.userRights && this.state.userRights.type !== 'GUEST' &&
        this.state.contract.signatories && this.state.contract.signatories['signatories'].length > 0
      )
        return {
          isVisibled: true,
          isActionAllowed: !this.state.editMode || this.state.editMode && (this.state.contract.signatories['signaturesCount'] === this.state.contract.signatories['totalSignatories']) ? false : true
        }
      else
        return {
          isVisibled: false,
          isActionAllowed: false
        }
      break;
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case "Màj workflow":
      if (this.state.contract && this.state.contract.isManager &&
        (this.state.contract["workflow"] && ((this.state.contract["workflow"].length > 1 && this.state.contract["isManager"] && isAffectedByCurrentUser) || (this.state.contract["workflow"].length === 1 && this.state.contract["workflow"][0].endDate !== undefined && this.state.contract["workflow"][0].endDate !== "" && this.state.contract["workflow"][0].endDate !== null)) && actionWorkflowAllowed && this.state.contract.status && parseInt(this.state.contract.status) < 2)
      )
        return {
          isVisibled: true,
          isActionAllowed: true
        }
      else
        return {
          isVisibled: false,
          isActionAllowed: false
        }
      break;
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case "Affectation normal":
      if (
        this.state.contract["workflow"] && this.state.contract["workflow"].length === 1 && this.state.contract["workflow"][0].endDate !== undefined && (this.state.contract["workflow"][0].endDate == "" || this.state.contract["workflow"][0].endDate == null) && actionWorkflowAllowed && this.state.contract.status && parseInt(this.state.contract.status) < 2
      )
        return {
          isVisibled: true,
          isActionAllowed: true
        }
      else
        return {
          isVisibled: false,
          isActionAllowed: false
        }
      break;
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    case "Fin traitement":
      if (this.state.contract && this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 && actionWorkflowAllowed && (!isLastBuyerWorkflow && isPendingBuyerWorkflow))
        return {
          isVisibled: true,
          isActionAllowed: this.state.contract?.status === 3 || (this.state.contract?.status === 3 && this.manageValidateButton()) ||
            (this.state.contract?.status !== 2 && this.state.negociationsDetail && this.state.negociationsDetail.pendingNegociations && this.state.negociationsDetail.pendingNegociations.length > 0) ? false : true
        }
      else
        return {
          isVisibled: false,
          isActionAllowed: false
        }
      break;
    default:
      break;
  }
}

setSignatoryType = (signatoryType = null) =>{
  if(signatoryType == "Externe" || signatoryType == "Interne"){
    let findSignatoryExterne = this.state.signatoriesListToAdd.find(x=>x.firstSignatory && x.email !== this.state.signatoryObject.email)
    let findSignatoryInterne = this.state.signatoriesListToAdd.find(x=>x.secondSignatory && x.email !== this.state.signatoryObject.email)
    if(findSignatoryExterne || findSignatoryInterne){
      let msg = ""
      // let msgToast = ""
      if(findSignatoryExterne && signatoryType == "Externe"){
        msg = findSignatoryExterne.firstName + " " + findSignatoryExterne.lastName + " est déjà enregistré comme signataire externe, souhaitez vous remplacer ce dernier ?"
      }
      if(findSignatoryExterne && signatoryType == "Interne"){
        msg = findSignatoryExterne.firstName + " " + findSignatoryExterne.lastName + " est déjà enregistré comme signataire externe, souhaitez vous remplacer ce dernier comme signataire interne ?"
      }
      if(findSignatoryInterne && signatoryType == "Interne"){
        msg = findSignatoryInterne.firstName + " " + findSignatoryInterne.lastName + " est déjà enregistré comme signataire interne, souhaitez vous remplacer ce dernier ?"
      }
      if(findSignatoryInterne && signatoryType == "Externe"){
        msg = findSignatoryInterne.firstName + " " + findSignatoryInterne.lastName + " est déjà enregistré comme signataire interne, souhaitez vous remplacer ce dernier comme signataire externe ?"
      }
      
      ConfirmAlert.show(
        msg,
        "Attention",
        'Oui',
        'Non',
      ).then(yes => {
        this.setState({ ...this.state, signatoryObject: { ...this.state.signatoryObject, firstSignatory: signatoryType == "Externe", secondSignatory: signatoryType == "Interne" } },()=>{
          if(findSignatoryExterne && findSignatoryExterne.email !== undefined){
            let oldSignatoryIndex = this.state.signatoriesListToAdd.findIndex(x=>x.email == findSignatoryExterne.email)
            let newSignatoryIndex = this.state.signatoriesListToAdd.findIndex(x=> this.state.signatoryObject.email !== undefined && x.email == this.state.signatoryObject.email)
            let signatoriesListToAddTmp = this.state.signatoriesListToAdd
            signatoriesListToAddTmp[oldSignatoryIndex].firstSignatory = false
            signatoriesListToAddTmp[oldSignatoryIndex].secondSignatory = false
            if(newSignatoryIndex > -1){
              signatoriesListToAddTmp[newSignatoryIndex].firstSignatory = signatoryType == "Externe"
              signatoriesListToAddTmp[newSignatoryIndex].secondSignatory = signatoryType == "Interne"
            }
            this.setState({
              ...this.state,
              signatoriesListToAdd: signatoriesListToAddTmp
            })
          }else if(findSignatoryInterne && findSignatoryInterne.email !== undefined){
            let oldSignatoryIndex = this.state.signatoriesListToAdd.findIndex(x=>x.email == findSignatoryInterne.email)
            let newSignatoryIndex = this.state.signatoriesListToAdd.findIndex(x=> this.state.signatoryObject.email !== undefined && x.email == this.state.signatoryObject.email)
            let signatoriesListToAddTmp = this.state.signatoriesListToAdd
            signatoriesListToAddTmp[oldSignatoryIndex].firstSignatory = false
            signatoriesListToAddTmp[oldSignatoryIndex].secondSignatory = false
            if(newSignatoryIndex > -1){
              signatoriesListToAddTmp[newSignatoryIndex].firstSignatory = signatoryType == "Externe"
              signatoriesListToAddTmp[newSignatoryIndex].secondSignatory = signatoryType == "Interne"
            }
            this.setState({
              ...this.state,
              signatoriesListToAdd: signatoriesListToAddTmp
            })
          }
        })
      }, no => {
        this.setState({ ...this.state, signatoryObject: { ...this.state.signatoryObject, firstSignatory: false, secondSignatory: false } })
      });
    }else{
      this.setState({ ...this.state, signatoryObject: { ...this.state.signatoryObject, firstSignatory: signatoryType == "Externe", secondSignatory: signatoryType == "Interne" } })
    }
  }else{
    this.setState({ ...this.state, signatoryObject: { ...this.state.signatoryObject, firstSignatory: signatoryType == "Externe", secondSignatory: signatoryType == "Interne" } })
  }
} 

  handleScrollCtrl = (e) => {
    // Check if the Ctrl or Command key is pressed
    if (e.ctrlKey || e.metaKey) {
      // // Handle the (Ctrl or Command) + Scroll event
      // if(e.ctrlKey)
      //   console.log('Ctrl + Scroll Detected');
      // if(e.metaKey)
      //   console.log('Command + Scroll Detected');
      // Determine the scroll direction (up or down)
      const delta = e.deltaY > 0 ? -0.1 : 0.1;
      // Adjust the zoom : in and out
      if (delta > 0) {
        this.panPinchRef.zoomOut();
      } else {
        this.panPinchRef.zoomIn();
      }
    }
  };

  reformatInfosKeys(keywordsArray){
    const groupedByKeywordType = keywordsArray.reduce((acc, obj) => {
      const { keywordType } = obj;
      if (!acc[keywordType]) {
        acc[keywordType] = { keywordType, keywords: [] };
      }
      acc[keywordType].keywords.push(obj);
      return acc;
    }, {});
    if(groupedByKeywordType && groupedByKeywordType.length>1)
      return Object.values(groupedByKeywordType).sort((a, b) => a.keywordType.localeCompare(b.keywordType));
    else
      return Object.values(groupedByKeywordType);
  }

  handleDropdownChangeKeywordList(selectedItem){
    
    if(this.state.extraKeywordsWithValues.some(x=>x.value === selectedItem["selectedOption"].value)){
      if(selectedItem["selectedOption"].isRequired || selectedItem["selectedOption"].isDisabled)
        toast.warn("Mot clé obligatoire !", "Information", {
          position: toast.POSITION.TOP_RIGHT
        });
      else
        this.changeInformationsKey(this.state.extraKeywordsWithValues.filter(x=>x.value !== selectedItem["selectedOption"].value));
    }else{
      delete selectedItem["selectedOption"].id
      selectedItem["selectedOption"].keywordValue = ""
      let list = this.state.extraKeywordsWithValues || []
      list.push(selectedItem["selectedOption"])
      this.changeInformationsKey(list);
    }
  }

  fileDownload(data, fileName){
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  createResiliationRequestDocument(
    internalSignatory,
    externalSignatory,
    clientAddress,
    clientPostalCode,
    clientCity,
    contractNumber,
    contractObject,
    contractStartDate,
    contractDuration,
    noticePeriod,
    contractEndDate
  ) {
    const cellBorderStyle = {
      style: BorderStyle.NONE,
      size: 0,
      color: 'FFFFFF',  // Set the color to match the cell background color
    };
    // Create a table for the document content
    const table = new Table({
      rows: [
        // Row for signatories
        new TableRow({
          children: [
            // Cell for internal signatory (left-aligned)
            new TableCell({
              children: [
                new Paragraph({
                  spacing: { before: 2000 },
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${internalSignatory}`,
                    }),
                  ],
                }),
              ],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
            // Cell for external signatory (right-aligned)
            new TableCell({
              children: [
                new Paragraph({
                  spacing: { before: 2000 },
                  // alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun({
                      text: `${externalSignatory}`,
                    }),
                  ],
                }),
              ],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ],
        }),
        // Row for client address
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph(clientAddress)],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
            new TableCell({
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph(`${clientPostalCode} ${clientCity}`)],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
            new TableCell({
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        // Row for contract details
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph(`N° Contrat : ${contractNumber}`)
              ],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
            new TableCell({
              children: [
              new Paragraph({
                children: [
                  new TextRun(`${clientCity}, ${new Date().toLocaleDateString("fr-FR")}`),
                  new TextRun({ text: "\t", tabStop: { position: 4320, type: AlignmentType.CENTER } }), // Tab for alignment
                ],
                alignment: AlignmentType.CENTER,
              })
            ],
            borders: {
              top: cellBorderStyle,
              bottom: cellBorderStyle,
              left: cellBorderStyle,
              right: cellBorderStyle,
            },}), 
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        // Row for resiliation request
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text:"COURRIER RECOMMANDE AVEC ACCUSE DE RECEPTION",
                      bold: true,
                      underline: true
                    }),
                  ],
                }),
              ],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        // Row for resiliation request
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [
                new Paragraph({
                  children: [
                    new TextRun(
                      `Madame, Monsieur,`
                    )
                  ],
                }),
              ],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        // Row for resiliation request
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [
                new Paragraph({
                  children: [
                    new TextRun(
                      `Par la présente, je vous demande de bien vouloir mettre fin au contrat : ${contractObject} correspondant au numéro cité en référence, que nous avons souscrit le ${contractStartDate}.`
                    ),
                  ],
                }),
              ],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        // Row for resiliation request
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [
                new Paragraph({
                  children: [
                    new TextRun(
                      `La possibilité de résilier ce contrat m’est offerte, puisque nous avions souscrit ce contrat pour une durée de ${contractDuration} et que nous respectons le préavis de ${noticePeriod}.`
                    ),
                  ],
                }),
              ],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        // Row for resiliation request
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [
                new Paragraph({
                  children: [
                    new TextRun(
                      `J’ai bien noté, qu‘en application des dispositions contractuelles qui nous lient, cette résiliation devrait prendre effet le ${contractEndDate}.`
                    ),
                  ],
                }),
              ],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        // Row for resiliation request
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [
                new Paragraph({
                  children: [
                    new TextRun(
                      `Je vous prie de croire, Madame, Monsieur, en l’expression de nos salutations les meilleures`
                    ),
                  ],
                }),
              ],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
        // Row for resiliation request
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [
                new Paragraph({
                  children: [
                    new TextRun(`${internalSignatory}`),
                  ],
                }),
              ],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              columnSpan: 2,
              children: [],
              borders: {
                top: cellBorderStyle,
                bottom: cellBorderStyle,
                left: cellBorderStyle,
                right: cellBorderStyle,
              },
            }),
          ]
        }),
      ],
      width: { size: 100, type: "pct" }, // Set table width to 100% of the page
      borders: {
        top: { style: BorderStyle.NONE },
        bottom: { style: BorderStyle.NONE },
        left: { style: BorderStyle.NONE },
        right: { style: BorderStyle.NONE },
      },
    });

    // Create the document with the table
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [table],
        },
      ],
    });

    return doc;
  };

  doDownloadResiliationDoc(docType){
    const content = document.getElementById('content-resiliation');
    const pdfWidth = content.offsetWidth;
    const pdfHeight = content.offsetHeight;
    switch (docType) {
      case "word":
        // Example usage
        const internalSignatory = this.state.signatory2?.value;
        const externalSignatory = this.state.signatory1?.value;
        const clientAddress = this.state.contract?.clientAddress?.address ? this.state.contract?.clientAddress?.address : "";
        const clientPostalCode = this.state.contract?.clientAddress?.postalCode ? this.state.contract?.clientAddress?.postalCode : "";
        const clientCity = this.state.contract?.clientAddress?.state ? this.state.contract?.clientAddress?.state : "";
        const contractNumber = this.state.contractCode?.value;
        const contractObject = this.state.objet?.value;
        const contractStartDate = this.state.beginingDate?.value;
        const contractDuration = (this.state.contractDuration?.value ? this.state.contractDuration.value.hasOwnProperty("duration") ? this.state.contractDuration.value.duration : this.state.contractDuration.value : "") + (this.state.contractDurationType?.toString() === "1" ? " mois " : (this.state.contractDurationType?.toString() === "2" ? " ans " : ""));
        const noticePeriod = this.getPriorNoticeValue() + (this.state.priorNoticeDurationType?.toString() === "1" ? " mois " : (this.state.priorNoticeDurationType?.toString() === "2" ? " ans " : ""));
        const contractEndDate = this.state.endingDate?.value;

        // Create the document
        const doc = this.createResiliationRequestDocument(
          internalSignatory,
          externalSignatory,
          clientAddress,
          clientPostalCode,
          clientCity,
          contractNumber,
          contractObject,
          contractStartDate,
          contractDuration,
          noticePeriod,
          contractEndDate
        );
        // Pack the document and save as .docx file
        Packer.toBlob(doc).then(blob => {
          saveAs(blob, 'Demande_Resiliation_'+this.state.contractCode.value+'[' + Moment(Moment(new Date(), "DD/MM/YYYY").toDate()).format("DD-MM-YYYY") + '].docx');
          this.handleClose()
        });

        break;
      case "pdf":

        html2canvas(content, { scale: 3, width: pdfWidth, height: pdfHeight }).then((canvas) => {
          const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);

          // Calculate the scale to fit content properly
          const scale = Math.min(pdf.internal.pageSize.getWidth() / canvas.width, pdf.internal.pageSize.getHeight() / canvas.height);

          // Add image to PDF with calculated scale
          pdf.addImage(canvas.toDataURL('image/png', 1.0), 3, 0, canvas.width * scale, canvas.height * scale);
          pdf.save('Demande_Resiliation_'+this.state.contractCode.value+'[' + Moment(Moment(new Date(), "DD/MM/YYYY").toDate()).format("DD-MM-YYYY") + '].pdf');
          this.handleClose()
        });

        break;
      case "txt":

        // Get the plain text content of the container
        const textContent = content.innerText;

        // Create a Blob with the plain text content
        const blob = new Blob([textContent], { type: 'text/plain;charset=utf-8' });

        // Determine the filename
        const filename = 'Demande_Resiliation_'+this.state.contractCode.value+'[' + Moment(Moment(new Date(), "DD/MM/YYYY").toDate()).format("DD-MM-YYYY") + '].txt';

        // Use FileSaver.js to save the Blob as a file
        saveAs(blob, filename);
        this.handleClose()
        break;

      default:
        break;
    }
  }

  handleIsFromEURChange() {
    // Update the state to reverse 'from' and 'to' values in currencies
    this.setState(
      prevState => {
      const updatedCurrencies = prevState.exchangesParams.currencies.map((currency) => ({
        ...currency,
        from: currency.to,
        to: currency.from
      }));

      return {
        exchangesParams: {
          ...prevState.exchangesParams,
          currencies: updatedCurrencies,
          isFromEUR: !prevState.exchangesParams.isFromEUR
        }
      };
    },()=>{
      this.getExchangesByRange(this.generateDateRange(this.state.exchangesParams.range.find(r=> r.selected).duration));
    });
  }

  handleChangeSelectedCurrencies = (index) => {
    this.setState(prevState => {
      const updatedCurrencies = prevState.exchangesParams.currencies.map((currency, i) => {
        if (i === index) {
          return {
            ...currency,
            selected: true
          };
        }
        return {
          ...currency,
          selected: false
        };
      });
  
      return {
        exchangesParams: {
          ...prevState.exchangesParams,
          currencies: updatedCurrencies
        }
      };
    },()=>{
      this.getExchangesByRange(this.generateDateRange(this.state.exchangesParams.range.find(r=> r.selected).duration));
    });
  };

  handleChangeSelectedRange = (index) => {
    this.setState(prevState => {
      const updatedRanges = prevState.exchangesParams.range.map((range, i) => {
        if (i === index) {
          return {
            ...range,
            selected: true
          };
        }
        return {
          ...range,
          selected: false
        };
      });
  
      return {
        exchangesParams: {
          ...prevState.exchangesParams,
          range: updatedRanges
        }
      };
    },()=>{
      this.getExchangesByRange(this.generateDateRange(this.state.exchangesParams.range.find(r=> r.selected).duration));
    });
  };

  getExchangesByRange = (range) =>{
    getExchangeTimeSeriesData(range,this.state.exchangesParams.currencies.find(c=> c.selected)).then(res=>{
      
      let resData = res.data
      resData.rates = Object.entries(resData.rates).map(([date, value]) => ({ date, value }));
      const lastRow = resData.rates[resData.rates.length - 1];
      resData.convertedTo = this.state.exchangesParams.currencies.find(c=>c.selected).to.toString()
      resData.lastCurrencyValue = lastRow.value[this.state.exchangesParams.currencies.find(c=>c.selected).to.toString()]
      //start states

      const secondToLastRow = resData.rates[resData.rates.length - 2];

      const lastValue = lastRow.value[this.state.exchangesParams.currencies.find(c=>c.selected).to.toString()];
      const secondToLastValue = secondToLastRow.value[this.state.exchangesParams.currencies.find(c=>c.selected).to.toString()];

      let comparisonResult;
      let cssClass;
      let gapNumber;
      let gapPercentage;
      let colorGraph;

      if (lastValue < secondToLastValue) {
        comparisonResult = "lower";
        cssClass = "states-lower";
        gapNumber = secondToLastValue - lastValue;
        gapPercentage = (gapNumber / secondToLastValue) * 100;
        colorGraph="#EB3A59";
      } else if (lastValue > secondToLastValue) {
        comparisonResult = "greater";
        cssClass = "states-greater";
        gapNumber = lastValue - secondToLastValue;
        gapPercentage = (gapNumber / secondToLastValue) * 100;
        colorGraph="#5CC973";
      } else {
        comparisonResult = "equals";
        cssClass = "states-equals";
        gapNumber = 0;
        gapPercentage = 0;
        colorGraph="#6E4CF5";
      }


      resData.stateGap = {
        comparisonResult: comparisonResult,
        gapNumber: gapNumber,
        secondToLastValue: secondToLastValue,
        cssClass: cssClass,
        gapPercentage: gapPercentage ? (gapPercentage.toFixed(2) + "%") :  "-",
        colorGraph: colorGraph
      }

      
      this.setState({
        ...this.state,
        exchangesParams:{
          ...this.state.exchangesParams,
          data: resData
        }
      })
      if(this.state.exchangesParams.data["base"] === "EUR")
        this.getConvertedDueAmountFromEUR()
      //end states
    },err=>{
      toast.error('Une erreur est survenue', {
        position: toast.POSITION.TOP_RIGHT
      });
    })
  }

  getConvertedDueAmountFromEUR(){
    let dueAmountEUR = this.state.dueAmount?.value && !isNaN(this.state.dueAmount?.value) ? this.state.dueAmount?.value : 0
      getConvertedAmount(this.state.exchangesParams.currencies.find(c=> c.selected),dueAmountEUR).then(conv=>{
        this.setState({
          ...this.state,
          exchangesParams:{
            ...this.state.exchangesParams,
            convertedData: conv["data"]
          }
        }, ()=>{
          const range = this.generateDateRange("2daysago")
          getExchangeTimeSeriesData(range,this.state.exchangesParams.currencies.find(c=> c.selected),dueAmountEUR).then(result=>{
            const data = result["data"].rates
          
            const dataArray = Object.entries(data).map(([date, value]) => ({
              date: Moment(date).format('DD/MM/YYYY'),
              currency: Object.keys(value)[0],
              value: value[Object.keys(value)[0]]
            }));
          

          result["data"].rates = dataArray.sort((a, b) => Moment(a.date, 'DD/MM/YYYY').valueOf() - Moment(b.date, 'DD/MM/YYYY').valueOf());
          
            this.setState({
              ...this.state,
              exchangesParams:{
                ...this.state.exchangesParams,
                convertedDataTwoDaysAgo: result["data"]
              }
            })
          })
        })
      },errorConv=>{
        toast.error('Une erreur est survenue lors de conversion d\échéances', {
          position: toast.POSITION.TOP_RIGHT
        });
        return undefined
      })
  }
  
  generateDateRange(selectedRange) {
    const currentDate = Moment(); // Current date
    let endDate = Moment(); // End date is the current date
    let startDate;
    // Adjust the end date based on the selected range
    switch (selectedRange) {
      case '2daysago':
        startDate = endDate.clone().subtract(2, 'days');
        break;
      case '1month':
        startDate = endDate.clone().subtract(1, 'months');
        break;
      case '3months':
        startDate = endDate.clone().subtract(3, 'months');
        break;
      case '6months':
        startDate = endDate.clone().subtract(6, 'months');
        break;
      default:
        // Default to 1 month if no valid range is provided
        startDate = endDate.clone().subtract(1, 'months');
    }
  
    // Return the start and end dates
    return {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    };
  }

  getEcheancesListTable = () => {

    
    if (this.state.echeancesList) {
      let  data = this.state.echeancesList
      data = data.map(item=>{
        return {...item, amount:this.state.dueAmount?.value && !isNaN(this.state.dueAmount?.value) ? this.state.dueAmount?.value : 0}
      })
      return(
        <div className='custom-table-echeances'>
          <table className='table'>
            <thead>
              <th>Date échéance</th>
              <th>Montant échéance (prévus)</th>
              {/* <th>Montant échéance (payé)</th> */}
            </thead>
            <tbody>
                {
                  data.map((item, index) => (
                    <tr key={index} className={(index%2 == 0) ? "colored-tr":""}>
                      <td>{item.dateEcheance}</td>
                      <td>{item.amount}</td>
                      {item.editEnabled && <td>
                        <div className="edit-echeance-input" >
                          <input type="number" placeholder="Montant réel" value={item.paidAmount} onChange={e=>{
                            this.setState({
                              ...this.state, echeancesList: this.state.echeancesList.map((val, i) => {
                                return { ...val, paidAmount: index === i ? e.target.value: val.paidAmount };
                              })
                            })
                          }} />
                          <span onClick={() => {
                          this.setState({
                            ...this.state, echeancesList: this.state.echeancesList.map((val, i) => {
                              return { ...val, editEnabled: false };
                            })
                          })
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10 -10 52 52" id="save">
                              <g data-name="Layer 5">
                                <path d="M29.77,9.06,22.94,2.23a2.48,2.48,0,0,0-1.77-.73H4A2.5,2.5,0,0,0,1.5,4V28A2.5,2.5,0,0,0,4,30.5H28A2.5,2.5,0,0,0,30.5,28V10.83A2.49,2.49,0,0,0,29.77,9.06ZM7.5,2.5h11V9a.5.5,0,0,1-.5.5H8A.5.5,0,0,1,7.5,9ZM29.5,28A1.5,1.5,0,0,1,28,29.5H4A1.5,1.5,0,0,1,2.5,28V4A1.5,1.5,0,0,1,4,2.5H6.5V9A1.5,1.5,0,0,0,8,10.5H18A1.5,1.5,0,0,0,19.5,9V2.5h1.67a1.51,1.51,0,0,1,1.06.44l6.83,6.83a1.49,1.49,0,0,1,.44,1.06Z" fill="#5367F4" stroke="#5367F4" stroke-width="0.7"></path>
                                <rect width="2" height="4" x="14.5" y="3.5" rx=".5" ry=".5" fill="#5367F4" stroke="#5367F4" stroke-width="0.7"></rect>
                                <path d="M25,13.5H7A1.5,1.5,0,0,0,5.5,15V26A1.5,1.5,0,0,0,7,27.5H25A1.5,1.5,0,0,0,26.5,26V15A1.5,1.5,0,0,0,25,13.5ZM25.5,26a.5.5,0,0,1-.5.5H7a.5.5,0,0,1-.5-.5V15a.5.5,0,0,1,.5-.5H25a.5.5,0,0,1,.5.5Z" fill="#5367F4" stroke="#5367F4" stroke-width="0.7"></path>
                                <path d="M21 17.5H13a.5.5 0 0 0 0 1h8a.5.5 0 0 0 0-1zM21 20.5H10a.5.5 0 0 0 0 1H21a.5.5 0 0 0 0-1zM21 23.5H10a.5.5 0 0 0 0 1H21a.5.5 0 0 0 0-1z" fill="#5367F4" stroke="#5367F4" stroke-width="0.7"></path>
                              </g>
                            </svg>
                          </span>
                        </div>
                        </td>}
                      {/* {!item.editEnabled && <td>
                        <div className="edit-echeance-button" onClick={() => {
                          this.setState({
                            ...this.state, echeancesList: this.state.echeancesList.map((val, i) => {
                              return { ...val, editEnabled: i === index ? true : false };
                            })
                          })
                        }}>
                          <span>{item.paidAmount}</span>
                          <svg width="15" height="15" viewBox="0 0 94 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.5419 87.25L86.6252 20.6458L73.25 7.36616L6.16665 73.9703V87.25H19.5419ZM0.333313 90.1458V72.7708C0.333313 72.0028 0.640604 71.2662 1.18759 70.7232L71.1876 1.22317C72.3266 0.0922767 74.1733 0.0922767 75.3124 1.22317L92.8124 18.5982C93.9514 19.7291 93.9514 21.5626 92.8124 22.6935L22.8124 92.1935C22.2654 92.7366 21.5235 93.0417 20.75 93.0417H3.24998C1.63915 93.0417 0.333313 91.7452 0.333313 90.1458ZM3.24998 104.625C1.63915 104.625 0.333313 103.328 0.333313 101.729C0.333313 100.13 1.63915 98.8333 3.24998 98.8333H61.6238C63.2346 98.8333 64.5405 100.13 64.5405 101.729C64.5405 103.328 63.2346 104.625 61.6238 104.625H3.24998Z" fill="#5367F4" />
                          </svg>
                        </div>
                        </td>} */}
                    </tr>
                  ))
                }
            </tbody>
          </table>
        </div>
      )
    }
    return <p className="text-danger font-weight-normal">Aucun élément trouvé</p>;
};
  


  genererDatesEcheances(frequence, dateFin, typeEcheance, dateDebut) {
    console.log("frequence", frequence)
    console.log("typeEcheance", typeEcheance)
    console.log("dateDebut", dateDebut)
    console.log("dateFin", dateFin)
    let datesEcheances = [];
    let date = Moment(dateDebut, 'DD/MM/YYYY').startOf('year');
    const dateFinMoment = Moment(dateFin, 'DD/MM/YYYY');

    while (date <= dateFinMoment) {
        if (typeEcheance === 'a_echoir') {
            if (frequence.includes('mois')) {
                date.add(parseInt(frequence), 'months');
            } else if (frequence.includes('ans')) {
                date.add(parseInt(frequence), 'years');
            }
        } else if (typeEcheance === 'a_echu') {
            // if (frequence.includes('mois')) {
            //     date.subtract(parseInt(frequence), 'months');
            // } else if (frequence.includes('ans')) {
            //     date.subtract(parseInt(frequence), 'years');
            // }
            if (frequence.includes('mois')) {
              date.add(parseInt(frequence), 'months');
            } else if (frequence.includes('ans')) {
              date.add(parseInt(frequence), 'years');
            }
        } else {
            throw new Error('Type d\'échéance non valide.');
        }
        
      if (date <= dateFinMoment) {
        datesEcheances.push({
          dateEcheance: date.format('DD/MM/YYYY'),
          amount: 30,
          paidAmount: 0,
          editEnabled: false
        });
      }
        // Ajouter une condition pour générer une date d'échéance si la date de début est après la date de fin
      if (datesEcheances.length === 0 && date > dateFinMoment) {
        datesEcheances.push({
          dateEcheance: date.format('DD/MM/YYYY'),
          amount: 30,
          paidAmount: 0,
          editEnabled: false
        });
      }
    }
    
    return datesEcheances;
  }

  render() {
    const saveBtnControl = this.actionButtonControl("Enregistrer")
    const updateAfterValidationBtnControl = this.actionButtonControl("Modifier le contrat")
    const validateWithoutSignBtnControl = this.actionButtonControl("Valider sans signature")
    const signBtnControl = this.actionButtonControl("Signer")
    const cancelSignBtnControl = this.actionButtonControl("Annuler la signature")
    const majWorkflowBtnControl = this.actionButtonControl("Màj workflow")
    const affectationNormalBtnControl = this.actionButtonControl("Affectation normal")
    const taskValidationWorkflowBtnControl = this.actionButtonControl("Fin traitement")
    // const informNegociatorsBtnControl = this.actionButtonControl("Informer négociateurs")
    const canCurrentUserDoAffectation = this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 && (this.state.contract["workflow"].some(item =>  item && (item.affectedBy !== undefined && this.state.userRights !== undefined && item.affectedBy["id"].toString() === this.state.userRights["id"].toString()))) ? true : false;
    const canCurrentUserDeleteAffectation = this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 && (this.state.contract["workflow"].some(item =>  item && (item.affectedBy !== undefined && this.state.userRights !== undefined && item.affectedBy["id"].toString() === this.state.userRights["id"].toString()))) ? true : false;
    // const canUpdateNegociatoriesWF =  this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 && (this.state.contract.isManager || (this.state.contract["workflow"].some(item =>  item && (item.handledBy !== undefined && this.state.userRights !== undefined && item.handledBy["id"].toString() === this.state.userRights["id"].toString())))) ? true : false
    const canUpdateSignatoriesWF =  this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 && (this.state.contract.isManager || (this.state.contract["workflow"].some(item =>  item && (item.handledBy !== undefined && this.state.userRights !== undefined && item.handledBy["id"].toString() === this.state.userRights["id"].toString())))) ? true : false

    return (
      <div className="manage-exchanges-style" onWheel={this.handleScrollCtrl}>
        <div className="app-container manage-contract validate-contract  app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
          <button className="mobile-btn-view btn-icon btn-pill btn toggle-btn-display" onClick={()=>this.setState({...this.state, isContractInfoVisible: !this.state.isContractInfoVisible})}>
            {this.state.isContractInfoVisible &&
              <svg width="30" height="30" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M67.0833 58.3333C65.4725 58.3333 64.1667 57.0275 64.1667 55.4167C64.1667 53.8058 65.4725 52.5 67.0833 52.5H96.25C97.8608 52.5 99.1667 53.8058 99.1667 55.4167C99.1667 57.0275 97.8608 58.3333 96.25 58.3333H67.0833ZM55.4167 75.8333C53.8059 75.8333 52.5 74.5275 52.5 72.9167C52.5 71.3058 53.8059 70 55.4167 70H96.25C97.8608 70 99.1667 71.3058 99.1667 72.9167C99.1667 74.5275 97.8608 75.8333 96.25 75.8333H55.4167ZM55.4167 93.3333C53.8059 93.3333 52.5 92.0275 52.5 90.4167C52.5 88.8058 53.8059 87.5 55.4167 87.5H84.5833C86.1942 87.5 87.5 88.8058 87.5 90.4167C87.5 92.0275 86.1942 93.3333 84.5833 93.3333H55.4167ZM67.0833 29.1667C65.4725 29.1667 64.1667 27.8608 64.1667 26.25C64.1667 24.6392 65.4725 23.3333 67.0833 23.3333H107.917C115.971 23.3333 122.5 29.8625 122.5 37.9167V113.75C122.5 121.804 115.971 128.333 107.917 128.333H43.75C35.6959 128.333 29.1667 121.804 29.1667 113.75V84.5833C29.1667 82.9725 30.4725 81.6667 32.0834 81.6667C33.6942 81.6667 35 82.9725 35 84.5833V113.75C35 118.583 38.9175 122.5 43.75 122.5H107.917C112.749 122.5 116.667 118.583 116.667 113.75V37.9167C116.667 33.0842 112.749 29.1667 107.917 29.1667H67.0833ZM35 49.5833C35 51.1942 33.6942 52.5 32.0834 52.5C30.4725 52.5 29.1667 51.1942 29.1667 49.5833V29.1667C29.1667 22.7233 34.39 17.5 40.8333 17.5C47.2767 17.5 52.5 22.7233 52.5 29.1667V49.5833C52.5 60.8591 43.3592 70 32.0834 70C20.8075 70 11.6667 60.8591 11.6667 49.5833V20.4167C11.6667 18.8058 12.9725 17.5 14.5834 17.5C16.1942 17.5 17.5 18.8058 17.5 20.4167V49.5833C17.5 57.6375 24.0292 64.1667 32.0834 64.1667C40.1375 64.1667 46.6667 57.6375 46.6667 49.5833V29.1667C46.6667 25.945 44.055 23.3333 40.8333 23.3333C37.6117 23.3333 35 25.945 35 29.1667V49.5833Z" fill="white" />
            </svg>}
            {!this.state.isContractInfoVisible &&
              <svg width="30" height="30" viewBox="0 0 141 141" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.625 35.25C17.625 32.0053 20.2553 29.375 23.5 29.375H29.375C32.6197 29.375 35.25 32.0053 35.25 35.25V41.125C35.25 44.3697 32.6197 47 29.375 47H23.5C20.2553 47 17.625 44.3697 17.625 41.125V35.25ZM49.9375 41.125C48.3152 41.125 47 39.8098 47 38.1875C47 36.5652 48.3152 35.25 49.9375 35.25H120.438C122.06 35.25 123.375 36.5652 123.375 38.1875C123.375 39.8098 122.06 41.125 120.438 41.125H49.9375ZM17.625 64.625C17.625 61.3803 20.2553 58.75 23.5 58.75H29.375C32.6197 58.75 35.25 61.3803 35.25 64.625V70.5C35.25 73.7447 32.6197 76.375 29.375 76.375H23.5C20.2553 76.375 17.625 73.7447 17.625 70.5V64.625ZM49.9375 70.5C48.3152 70.5 47 69.1848 47 67.5625C47 65.9402 48.3152 64.625 49.9375 64.625H120.438C122.06 64.625 123.375 65.9402 123.375 67.5625C123.375 69.1848 122.06 70.5 120.438 70.5H49.9375ZM17.625 94C17.625 90.7553 20.2553 88.125 23.5 88.125H29.375C32.6197 88.125 35.25 90.7553 35.25 94V99.875C35.25 103.12 32.6197 105.75 29.375 105.75H23.5C20.2553 105.75 17.625 103.12 17.625 99.875V94ZM23.5 35.25V41.125H29.375V35.25H23.5ZM23.5 64.625V70.5H29.375V64.625H23.5ZM23.5 94V99.875H29.375V94H23.5ZM49.9375 99.875C48.3152 99.875 47 98.5598 47 96.9375C47 95.3152 48.3152 94 49.9375 94H120.438C122.06 94 123.375 95.3152 123.375 96.9375C123.375 98.5598 122.06 99.875 120.438 99.875H49.9375Z" fill="white" />
            </svg>}
          </button>
          <Header />
          <div className="app-main">
            <div className="app-sidebar sidebar-shadow">
              <Sidebar />
            </div>
            <div className="app-main__outer">
              
              <EditMode
                isEditMode={this.state.editMode}
                onChange={(checked) => {
                  this.changeEditMode(checked);
                }}
                isDisputedMode={this.state.contract.dispute && this.state.contract.dispute["isDisputed"] !== undefined ? this.state.contract.dispute["isDisputed"]:0}
                onChangeDisputedMode={(checked) => {
                  this.changeDisputedMode(checked);
                }}
                disabled={!this.state.contract?.ifUpdateRightIsPresent}
                disableAction={true}
                isContractPage={true}
                isRestrictedMode={this.state.contract.accessType && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'restricted'}
              />
              <div className="app-main__inner">
                <div className="row">
                  <div
                    className={`col-md-5 left-side ${
                     ( this.state.scrolling.leftSide ? "scrolling" : "") +
                     (this.state.isContractInfoVisible ? "" : "mobile-screen-hide-view")}`}>
                     <div className="head d-flex">
                      {this.state.userRights && this.state.userRights.type &&
                        <button className="btn btn-light btn-back" onClick={() => { 
                         
                            this.props.history.push(
                              "/manage-contract/validate?contract=" + this.state.contract.hachedId
                            );
                        
                        }}>
                          <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.9375 1.8125L1.0625 8.55913L8.9375 15.3125" stroke="#7077A5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </button>
                      }
                      <h2 id="negoTitle">Cours bourses</h2>
                    </div>
                    <div className="boxed" style={{zoom:'0.85'}}>
                      
                      { this.state.showBloc === 0 &&
                        <div
                        className="scrolling-verticaly"
                        onScroll={(e) => {
                          this.onScrolling(e, "leftSide");
                        }}
                      >
                        
                        
                        <h1>Informations clés</h1>

                        <FormWithConstraints
                          ref={(formWithConstraints) =>
                            (this.form = formWithConstraints)
                          }
                          id="form-validate-contract"
                          className={!this.state.editMode ? "consultation" : ""}
                          noValidate
                        >
                          <div
                            className="hideFormInputs"
                            onClick={this.editModeReminder}
                          ></div>
                          <h2 className="disabled-action">Classement/Organisation
                            <div className="zone-switch d-flex align-items-center">
                              {this.state.zonesClauses.classement_org && 
                              <div className="replier">
                                <ButtonUp 
                                  onPress={() => 
                                    this.setState({
                                      ...this.state,
                                      zonesClauses:{
                                        ...this.state.zonesClauses,
                                        classement_org:false
                                      }
                                    })
                                  }
                                  disabled={!this.state.zonesClauses.classement_org}
                                  color="#6E4CF5"/>
                              </div>}
                              {!this.state.zonesClauses.classement_org && 
                              <div className="deplier">
                                <ButtonDown 
                                  onPress={() => 
                                    this.setState({
                                      ...this.state,
                                      zonesClauses:{
                                        ...this.state.zonesClauses,
                                        classement_org:true
                                      }
                                    })}
                                  disabled={this.state.zonesClauses.classement_org}
                                  color="#6E4CF5"/>
                              </div>}
                            </div>
                          </h2>
                          {
                            this.state.zonesClauses.classement_org && 
                              <>
                                <div
                                  className={`input-item input-item-styled ${
                                    (!this.state?.contract?.name && this.state.editMode) ? "required" : ""
                                  }`}
                                >
                                  <label>
                                    Nom de contrat
                                    <InfoTooltip content={"Le nom du contrat"} />
                                  </label>
                                  <div className="d-flex">
                                    {/* <ButtonCible
                                      onPress={() => this.setSelectedText("name")}
                                      disabled={false}
                                    /> */}
                                    <input
                                      required
                                      /*disabled={this.state.contract?.status === 2 ? true : false}*/
                                      className={`form-control w-100 ${
                                        this.eligibilityCheck().write ?
                                        (!this.state.contract?.name && this.state.editMode ? "required-field" : (this.state.contract?.name && this.state.editMode ? "success-field":""))
                                        : "restricted-input copy-field"
                                      }`}
                                      name="name"
                                      value={
                                        this.state.contract.name
                                          ? this.state.contract.name
                                          : ""
                                      }
                                      onChange={(e) => {
                                        this.handleChangeInput(e);
                                        this.handleCheckContractParameters("contractName", e);
                                      }}
                                      onKeyDown={(e) => {this.onKeyDown(e)}}
                                      readOnly={!this.eligibilityCheck("contractName").write}
                                      onClick={() => this.copyToClipBoard('Nom de contrat', this.state.contract.name)}
                                    />
                                    <div style={{ width: 58 }}></div>
                                  </div>
                                </div>
                                <div
                                  className={`input-item input-item-styled ${
                                    !this.state.contract?.category && this.state.editMode ? "required" : ""
                                  }`}
                                >
                                  <label>
                                    Catégorie de contrat
                                    <InfoTooltip content={"La catégorie de contrat"} />
                                  </label>
                                  <div className="d-flex">
                                    <select
                                      /*disabled={this.state.contract?.status === 2 ? true : false}*/ className="form-control"
                                      name="category"
                                      value={
                                        this.state.contract.category
                                          ? this.state.contract.category.id
                                          : ""
                                      }
                                      onChange={(e) => {
                                        this.handleCategoryUpdate(e);
                                      }}
                                      onKeyDown={(e) => {this.onKeyDown(e)}}
                                      disabled={!this.eligibilityCheck("category").write}
                                    >
                                      <option value="">
                                        Catégorie de contrat
                                      </option>
                                      {
                                        this.state.contract && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'restricted' && this.state.contract.category && this.state.contract.category['id'] !== undefined ?
                                          <option value={this.state.contract.category.id}>
                                            {this.state.contract.category.name}
                                          </option>
                                        :
                                        this.state.categories.map((category, key) => (
                                          <option value={category.id} key={key}>
                                            {category.name}
                                          </option>
                                        ))
                                      }
                                    </select>
                                    <div style={{ width: 58 }}></div>
                                  </div>
                                </div>
                                <div className="input-item input-item-styled">
                                  <label>
                                      Lier à un contrat existant
                                      <InfoTooltip content={"Lier à un contrat existant"} />
                                  </label>
                                  <div className="d-flex">
                                    <select
                                      /*disabled={this.state.contract?.status === 2 ? true : false}*/ className="form-control"
                                      name="parentContract"
                                      value={
                                        this.state.contract.parentContract
                                          ? this.state.contract.parentContract.id
                                          : ""
                                      }
                                      onChange={(e) => {
                                        this.handleChangeInput(e);
                                      }}
                                      onKeyDown={(e) => {this.onKeyDown(e)}}
                                      disabled={!this.eligibilityCheck("parentContract").write}
                                    >
                                      <option value="">
                                        Lier à un contrat existant
                                      </option>
                                      {
                                        this.state.contract && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'restricted' && this.state.contract.parentContract && this.state.contract.parentContract['id'] !== undefined ?
                                        <option value={this.state.contract.category.id}>
                                          {this.state.contract.parentContract.name}
                                        </option>
                                      :
                                      this.state.clientOtherContracts.map(
                                        (contract) => {
                                          return (
                                            <option value={contract.id}>
                                              {contract.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                    <div style={{ width: 58 }}></div>
                                  </div>
                                </div>
                                <div className="input-item input-item-styled">
                                  <label>
                                      Sous catégorie de contrat
                                      <InfoTooltip content={"La sous catégorie de contrat"} />
                                  </label>
                                  <div className="d-flex">
                                    <select
                                      /*disabled={this.state.contract?.status === 2 ? true : false}*/ className="form-control"
                                      name="subCategory"
                                      value={
                                        this.state.contract.subCategory
                                          ? this.state.contract.subCategory.id
                                          : ""
                                      }
                                      onChange={(e) => {
                                        this.handleSubCategoryUpdate(e);
                                      }}
                                      onKeyDown={(e) => {this.onKeyDown(e)}}
                                      disabled={!this.eligibilityCheck("subCategory").write}
                                    >
                                      <option value="">
                                        S/ catégorie de contrat
                                      </option>
                                      {
                                        this.state.contract && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'restricted' && this.state.contract.subCategory && this.state.contract.subCategory['id'] !== undefined ?
                                          <option value={this.state.contract.subCategory.id}>
                                            {this.state.contract.subCategory.name}
                                          </option>
                                        :
                                      this.state.subCategories.map((subCategory) => {
                                        return (
                                          <option value={subCategory.id}>
                                            {subCategory.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <div className="avt-checkbox">
                                      <div className="custom-checkbox">
                                        <label>
                                          <input
                                            disabled={
                                              this.state.contract?.parentContract &&
                                              this.state.contract?.status !== 2 &&
                                              this.state.contract?.avt !== true &&
                                              this.state.editMode
                                                ? false
                                                : true
                                            }
                                            type="checkbox"
                                            onChange={(e) => {
                                              this.isEndorsement(e);
                                            }}
                                            checked={this.state.avtChecked}
                                          ></input>
                                          <span className="cr">
                                            <i className="cr-icon fa fa-check"></i>
                                          </span>
                                          Avt
                                        </label>
                                      </div>
                                    </div>
                                    
                                  </div>
                                </div>
                                {this.state.contract && (this.state.contract.isManager || this.state.contract["workflow"] || this.state.contract["handledBy"]) &&
                                  <div className="affectation-zone">
                                    <h2 className="pt-3 pb-2">Workflow</h2>
                                    <table>
                                      <thead>
                                        <th></th>
                                        <th>
                                          <label>
                                            Affectation(s)
                                            <InfoTooltip content={"Sélectionner ou saisir le nom 'un gestionnaire ou d'un acheteur. l'icone + permet d'insérer une personne juste au dessus. Les gestionnaires apparaissent en Noir let les Acheteurs en Bleu"} />
                                          </label>
                                        </th>
                                        <th></th>
                                        {this.state.contract && this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 &&
                                          ((this.state.contract["workflow"][0] !== undefined && this.state.contract["workflow"][0].endDate !== undefined && this.state.contract["workflow"][0].endDate !== "") || this.state.contract["workflow"].length > 1) &&
                                          <th>
                                            <label>
                                              Participe
                                              <InfoTooltip content={"Indique si l'intervenant participera au futur négociations"} />
                                            </label>
                                          </th>
                                        }
                                        <th>
                                          <label>
                                            Date limite
                                            <InfoTooltip content={"Saisir la date à laquelle le gestionnaire ou l'acheteur doivent terminer leur étude/négociation du contrat"} />
                                          </label>
                                        </th>
                                        {this.state.contract && this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 &&
                                          ((this.state.contract["workflow"][0] !== undefined && this.state.contract["workflow"][0].endDate !== undefined && this.state.contract["workflow"][0].endDate !== "") || this.state.contract["workflow"].length > 1) &&
                                          <th>Suivie</th>
                                        }
                                      </thead>
                                      <tbody>
                                        {
                                          (this.state.contract && this.state.contract["handledBy"] && (!this.state.contract["workflow"] || this.state.contract["workflow"] == undefined || (this.state.contract && this.state.contract["workflow"] && this.state.contract["workflow"].length == 0))) && this.state.contract.buyers && this.state.contract.buyers.length > 0 &&
                                          <>
                                            <tr>
                                              <td>
                                                {this.state.contract["handledBy"] &&
                                                  <svg width="30" height="30" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M59 117.333C26.7834 117.333 0.666687 91.2165 0.666687 58.9999C0.666687 26.7832 26.7834 0.666504 59 0.666504C91.2166 0.666504 117.333 26.7832 117.333 58.9999C117.333 91.2165 91.2166 117.333 59 117.333ZM59 111.5C87.995 111.5 111.5 87.9948 111.5 58.9999C111.5 30.0049 87.995 6.49984 59 6.49984C30.0051 6.49984 6.50002 30.0049 6.50002 58.9999C6.50002 87.9948 30.0051 111.5 59 111.5ZM77.3543 42.3541C78.4933 41.2151 80.3401 41.2151 81.4791 42.3541C82.6181 43.4931 82.6181 45.3399 81.4791 46.4789L52.3124 75.6456C51.1734 76.7846 49.3267 76.7846 48.1876 75.6456L36.521 63.9789C35.3819 62.8399 35.3819 60.9932 36.521 59.8541C37.66 58.7151 39.5067 58.7151 40.6458 59.8541L50.25 69.4584L77.3543 42.3541Z" fill="#5CC973" />
                                                  </svg>
                                                }
                                              </td>
                                              <td className="ddlist">
                                                <CustomDropdown contract={this.state.contract} canBeFree={this.state.contract && this.state.contract.handledBy !== undefined && this.state.contract.handledBy !== null && (this.state.contract.workflow == undefined || (this.state.contract.workflow && this.state.contract.workflow.length == 1 && !this.state.contract.workflow["endDate"])) ? true:false} isWorkflow={true} 
                                                  handledBy={this.state.contract && this.state.contract.handledBy && this.state.contract.handledBy !== undefined ? { id: this.state.contract.handledBy["id"] } : null}
                                                  handledByArchivedUser={this.state.contract && this.state.contract.handledBy && this.state.contract.handledBy !== undefined && this.state.contract.handledBy["id"] == undefined ? this.state.contract.handledBy : null}
                                                  options={(this.state.contract.buyers && this.state.contract.buyers.length > 0 && this.state.contract.buyers.sort((a, b) => a.lastName.localeCompare(b.lastName))) || []}
                                                  onSelect={this.handleDropdownChange} />
                                              </td>
                                              <td></td>

                                              <td>
                                                <DatePicker
                                                  disabled={!this.state.contract.isManager}
                                                  minDate={new Date()}
                                                  locale="fr"
                                                  dateFormat="dd/MM/yyyy"
                                                  className={`form-control w-100"`}
                                                  autoComplete="off"
                                                  selected={""}
                                                  name="endDate"
                                                  onChange={(e) => {
                                                    this.setDateLimite(e)
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          </>
                                        }
                                        {
                                          (this.state.contract && !this.state.contract["handledBy"] && (!this.state.contract["workflow"] || this.state.contract["workflow"] == undefined || (this.state.contract && this.state.contract["workflow"] && this.state.contract["workflow"].length == 0))) && this.state.contract.buyers && this.state.contract.buyers.length > 0 &&
                                          <tr>
                                            <td>
                                              <span className="insertion-btn-default" onClick={() => this.handleManageAffected(0, "add")}>+</span>
                                            </td>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        }
                                        {
                                          this.state.contract && this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 &&
                                          this.state.contract["workflow"].map((assignment, index) => {
                                            return (
                                              <tr key={index}>
                                                {!assignment.isValidated &&
                                                  <td>
                                                    <div class="centered">
                                                      <div class={assignment && assignment.selected !== undefined && assignment.selected == true ? " plus plus--active" : "plus"} onClick={() => this.handleManageAffectedDetailsRow({ target: { value: "selected" } }, index, "toggleControl")} id="plus">
                                                        <>
                                                          <div className="plus__dots--v">
                                                            <div class="plus__dot"></div>
                                                            <div class="plus__dot"></div>
                                                            <div class="plus__dot"></div>
                                                          </div>
                                                          <div class="plus__line plus__line--v">

                                                            {
                                                              this.state.contract && (!this.state.contract.isManager || !canCurrentUserDoAffectation) &&
                                                              this.state.contract["workflow"] && this.state.contract["workflow"].length > 1 && (!this.state.contract.isManager || !canCurrentUserDeleteAffectation) &&
                                                              assignment && !assignment.pending && taskValidationWorkflowBtnControl.isVisibled &&
                                                              <span className="text-danger">Aucune action disponible</span>
                                                            }

                                                            {
                                                              this.state.contract && this.state.contract.isManager && canCurrentUserDoAffectation &&

                                                              <a href="#" class="plus__link" onClick={() => this.handleManageAffected(index, "add")}>
                                                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="30" height="42" viewBox="0 0 597.000000 666.000000" preserveAspectRatio="xMidYMid meet">
                                                                  <g transform="translate(0.000000,666.000000) scale(0.100000,-0.100000)"
                                                                    fill="#000000" stroke="none">
                                                                    <path d="M2560 6614 c-19 -7 -55 -13 -80 -14 -25 0 -56 -7 -70 -15 -14 -8 -36 -14 -50 -15 -14 0 -58 -14 -99 -30 -40 -17 -77 -30 -82 -30 -17 0 -248 -116
                                                                    -274 -137 -5 -5 -28 -20 -50 -33 -22 -13 -42 -27 -45 -30 -3 -3 -43 -35 -90 -72 -192 -149 -359 -362 -473 -601 -26 -53 -47 -103 -47 -110 0 -7 -4 -17 -9
                                                                    -22 -16 -18 -48 -134 -92 -335 -16 -72 -22 -355 -10 -460 20 -174 46 -284 103 -435 43 -115 115 -258 171 -342 90 -134 245 -307 340 -380 30 -23 33 -50 7
                                                                    -64 -64 -32 -208 -99 -215 -99 -8 0 -60 -24 -241 -111 -45 -21 -85 -39 -89 -39 -8 0 -61 -25 -241 -111 -45 -21 -85 -39 -89 -39 -7 0 -33 -12 -245 -113
                                                                    -276 -131 -415 -271 -508 -512 -41 -106 -45 -137 -48 -416 -3 -256 -2 -280 17 -340 61 -188 111 -278 219 -393 85 -89 257 -205 308 -206 9 0 26 -7 36 -15 11
                                                                    -8 30 -15 42 -15 12 0 38 -7 57 -15 32 -13 167 -15 1012 -15 l976 0 34 26 c41 31 85 103 85 138 0 39 -37 102 -80 136 l-38 30 -933 0 c-831 0 -938 2 -988 16
                                                                    -160 47 -246 104 -318 212 -90 135 -103 189 -103 426 0 220 9 265 78 405 17 33 94 118 142 155 28 23 193 106 209 106 6 0 37 13 68 29 131 65 253 121 262
                                                                    121 6 0 37 13 68 29 131 65 253 121 262 121 6 0 37 13 68 29 131 65 253 121 262 121 6 0 37 13 68 29 238 118 255 124 284 107 15 -9 34 -16 42 -16 9 0 28
                                                                    -6 44 -14 39 -19 91 -36 141 -47 23 -5 51 -13 61 -19 11 -5 37 -10 58 -10 21 0 59 -7 84 -14 66 -20 457 -20 517 0 23 8 62 14 85 14 23 1 53 7 67 15 14 8
                                                                    36 14 49 15 24 0 156 47 218 77 29 14 35 14 67 -1 19 -9 41 -16 49 -16 8 0 20 -7 27 -15 7 -8 20 -15 29 -15 9 0 43 -14 76 -30 33 -16 67 -30 76 -30 9 0 22
                                                                    -7 29 -15 7 -8 20 -15 29 -15 9 0 43 -14 76 -30 33 -16 67 -30 76 -30 9 0 22 -7 29 -15 7 -8 20 -15 29 -15 9 0 43 -14 76 -30 33 -16 67 -30 76 -30 9 0 22
                                                                    -7 29 -15 7 -8 20 -15 29 -15 9 0 43 -14 76 -30 33 -16 67 -30 76 -30 9 0 22 -7 29 -15 7 -8 19 -15 28 -15 8 0 44 -14 79 -30 34 -17 69 -30 76 -30 6 0 21
                                                                    -7 31 -15 11 -8 34 -15 51 -15 40 0 126 57 144 95 8 17 18 32 23 33 16 7 9 91 -9 111 -10 11 -26 30 -35 44 -18 26 -84 61 -233 122 -22 9 -56 24 -75 32 -35
                                                                    17 -62 28 -135 58 -22 9 -56 24 -75 32 -19 9 -48 22 -65 29 -66 26 -174 73 -210 89 -16 7 -48 21 -70 30 -102 42 -112 54 -69 87 90 69 271 269 329 363 10
                                                                    17 33 53 50 80 38 59 125 240 125 258 0 7 4 17 9 23 16 17 48 133 92 334 16 72 22 355 10 460 -20 174 -46 284 -103 435 -109 292 -297 552 -528 733 -47 37
                                                                    -91 72 -98 80 -8 7 -28 20 -45 28 -18 9 -39 22 -47 29 -22 19 -191 105 -205 105 -7 0 -18 7 -25 15 -7 8 -21 15 -30 15 -10 0 -31 6 -47 14 -39 19 -91 36
                                                                    -141 47 -23 5 -51 13 -61 19 -11 5 -37 10 -58 10 -21 0 -59 7 -84 14 -62 19 -468 19 -519 0z m410 -325 c25 -5 63 -12 85 -15 76 -10 190 -37 210 -51 11 -7
                                                                    28 -13 37 -13 10 0 26 -4 36 -9 9 -6 53 -27 97 -48 44 -21 94 -47 110 -57 141 -89 252 -181 333 -277 88 -103 179 -242 216 -329 11 -25 28 -64 38 -87 10 -23
                                                                    18 -47 18 -53 0 -5 4 -18 9 -28 30 -56 71 -307 71 -432 0 -133 -39 -362 -76 -447 -8 -17 -14 -36 -14 -42 0 -6 -4 -19 -9 -29 -6 -9 -27 -53 -48 -97 -21
                                                                    -44 -47 -93 -57 -110 -89 -141 -181 -253 -277 -333 -81 -69 -183 -142 -198 -142 -5 0 -16 -7 -25 -15 -17 -18 -129 -75 -145 -75 -6 0 -28 -9 -48 -20 -21
                                                                    -11 -45 -20 -53 -20 -9 0 -20 -4 -25 -9 -10 -9 -132 -37 -200 -45 -22 -3 -60 -10 -85 -15 -58 -13 -242 -13 -300 0 -25 5 -63 12 -85 15 -76 10 -190 37 -210
                                                                    51 -11 7 -28 13 -37 13 -10 0 -26 4 -36 9 -9 6 -53 27 -97 48 -44 21 -93 47 -110 57 -141 89 -252 181 -333 277 -88 103 -179 242 -216 329 -11 25 -28 64
                                                                    -38 87 -10 23 -18 47 -18 53 0 5 -4 18 -9 28 -30 56 -71 307 -71 432 0 133 39 362 76 447 8 17 14 36 14 42 0 6 4 19 9 29 6 9 27 53 48 97 21 44 47 94 57
                                                                    110 89 141 181 253 277 333 81 69 183 142 198 142 5 0 16 7 25 15 17 18 129 75 145 75 6 0 28 9 48 20 21 11 45 20 53 20 9 0 20 4 26 9 9 9 129 36 199 46
                                                                    22 2 58 9 80 14 55 12 250 12 305 0z"/>
                                                                    <path d="M4668 2494 c-16 -8 -37 -32 -48 -54 -19 -38 -20 -58 -20 -529 0 -381 -3 -492 -12 -498 -7 -4 -237 -8 -512 -9 l-499 0 -33 -37 c-28 -30 -34 -45 -34
                                                                    -79 0 -25 8 -56 19 -74 26 -43 41 -44 592 -44 416 0 468 -2 473 -16 3 -9 6 -230 6 -491 0 -517 2 -536 54 -580 25 -21 37 -24 72 -19 52 7 91 24 101 45 4
                                                                    9 10 247 13 529 4 441 7 514 20 522 9 6 207 10 485 10 446 1 544 5 566 27 5 5 15 34 21 65 11 53 10 58 -13 92 -13 20 -33 39 -44 43 -11 3 -242 7 -513 7
                                                                    -477 1 -494 2 -508 20 -12 17 -14 100 -14 495 0 280 -4 490 -10 510 -15 54 -47 81 -94 81 -23 -1 -53 -8 -68 -16z"/>
                                                                  </g>
                                                                </svg>
                                                              </a>
                                                            }

                                                            {
                                                              this.state.contract["workflow"] && this.state.contract["workflow"].length > 1 && this.state.contract && this.state.contract.isManager && canCurrentUserDeleteAffectation &&
                                                              <a href="#" class="plus__link" onClick={() => this.handleManageAffected(index, "delete")}>
                                                                <svg width="30" height="42" viewBox="0 0 100 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <path d="M74.9585 90.7501L59.5209 75.3125C58.3819 74.1735 58.3819 72.3268 59.5209 71.1878C60.6599 70.0487 62.5067 70.0487 63.6457 71.1878L79.0833 86.6254L94.5209 71.1878C95.66 70.0487 97.5067 70.0487 98.6457 71.1878C99.7847 72.3268 99.7847 74.1735 98.6457 75.3125L83.2081 90.7501L98.6457 106.188C99.7847 107.327 99.7847 109.174 98.6457 110.313C97.5067 111.452 95.66 111.452 94.5209 110.313L79.0833 94.8749L63.6457 110.313C62.5067 111.452 60.6599 111.452 59.5209 110.313C58.3819 109.174 58.3819 107.327 59.5209 106.188L74.9585 90.7501ZM58.9146 56.1302C55.2769 57.7602 51.2443 58.6668 47 58.6668C42.7642 58.6668 38.7394 57.7639 35.1074 56.14L11.2959 66.9634C8.17217 68.3833 6.16665 71.4979 6.16665 74.9291V79.0835C6.16665 83.916 10.0842 87.8335 14.9166 87.8335H44.0833C45.6941 87.8335 47 89.1393 47 90.7501C47 92.361 45.6941 93.6668 44.0833 93.6668H14.9166C6.86249 93.6668 0.333313 87.1376 0.333313 79.0835V74.9291C0.333313 69.2104 3.67585 64.0194 8.88202 61.653L29.0459 52.4876C22.2205 47.1491 17.8333 38.837 17.8333 29.5002C17.8333 13.3919 30.8917 0.333496 47 0.333496C63.1083 0.333496 76.1666 13.3919 76.1666 29.5002C76.1666 38.7922 71.8214 47.0694 65.0521 52.4105L80.2302 58.9018C81.7113 59.5352 82.3984 61.2493 81.765 62.7304C81.1316 64.2114 79.4175 64.8986 77.9364 64.2652L58.9146 56.1302ZM47 52.8335C59.8866 52.8335 70.3333 42.3868 70.3333 29.5002C70.3333 16.6135 59.8866 6.16683 47 6.16683C34.1133 6.16683 23.6666 16.6135 23.6666 29.5002C23.6666 42.3868 34.1133 52.8335 47 52.8335Z" fill="black" />
                                                                </svg>
                                                              </a>

                                                            }

                                                            {
                                                              assignment && assignment.pending && taskValidationWorkflowBtnControl.isVisibled &&
                                                              <a href="#" class="plus__link" onClick={() => this.handleManageAffected(index, "validate")}>
                                                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                                  width="30" height="42" viewBox="0 0 597.000000 666.000000"
                                                                  preserveAspectRatio="xMidYMid meet">

                                                                  <g transform="translate(0.000000,666.000000) scale(0.100000,-0.100000)"
                                                                    fill="#000000" stroke="none">
                                                                    <path d="M2560 6614 c-19 -7 -55 -13 -80 -14 -25 0 -56 -7 -70 -15 -14 -8 -36
                                                                              -14 -50 -15 -14 0 -58 -14 -99 -30 -40 -17 -77 -30 -82 -30 -17 0 -248 -116
                                                                              -274 -137 -5 -5 -28 -20 -50 -33 -22 -13 -42 -27 -45 -30 -3 -3 -43 -35 -90
                                                                              -72 -192 -149 -359 -362 -473 -601 -26 -53 -47 -103 -47 -110 0 -7 -4 -17 -9
                                                                              -22 -16 -18 -48 -134 -92 -335 -16 -72 -22 -355 -10 -460 20 -174 46 -284 103
                                                                              -435 43 -115 115 -258 171 -342 90 -134 245 -307 340 -380 30 -23 33 -50 7
                                                                              -64 -64 -32 -208 -99 -215 -99 -8 0 -60 -24 -241 -111 -45 -21 -85 -39 -89
                                                                              -39 -8 0 -61 -25 -241 -111 -45 -21 -85 -39 -89 -39 -7 0 -33 -12 -245 -113
                                                                              -272 -129 -410 -266 -504 -500 -45 -113 -51 -161 -51 -449 0 -208 3 -280 13
                                                                              -290 6 -6 12 -22 12 -34 0 -29 36 -124 73 -194 100 -190 248 -319 462 -405 11
                                                                              -5 49 -17 85 -28 63 -20 95 -21 1033 -24 l968 -3 46 26 c29 17 50 39 59 60 8
                                                                              18 18 35 24 39 15 9 12 71 -4 84 -8 7 -17 23 -20 37 -4 13 -25 38 -47 54 l-41
                                                                              30 -940 0 c-1002 0 -963 -1 -1078 47 -73 30 -87 40 -155 108 -51 50 -80 91
                                                                              -106 145 -59 121 -61 134 -56 370 3 201 11 271 37 318 5 9 17 35 27 57 9 22
                                                                              49 72 88 110 70 70 130 105 345 202 92 41 112 50 195 88 110 50 184 84 220
                                                                              100 17 8 66 30 110 50 110 50 184 84 220 100 17 8 66 30 110 50 102 47 167 76
                                                                              230 104 28 13 72 34 98 47 56 28 74 29 101 9 11 -8 30 -15 42 -15 12 0 27 -7
                                                                              34 -15 7 -8 25 -15 39 -15 15 0 36 -7 47 -15 10 -8 35 -15 55 -15 19 0 44 -7
                                                                              55 -15 11 -8 41 -14 71 -15 29 0 72 -6 95 -14 61 -20 441 -20 480 0 15 8 51
                                                                              15 80 15 29 1 65 8 80 15 15 8 42 14 61 14 19 0 40 7 47 15 7 8 21 15 32 15
                                                                              20 0 66 17 121 46 33 16 60 18 85 5 52 -28 104 -51 114 -51 11 0 115 -46 170
                                                                              -76 14 -8 32 -14 39 -14 12 0 114 -45 171 -76 14 -8 32 -14 39 -14 12 0 114
                                                                              -45 171 -76 14 -8 32 -14 39 -14 12 0 114 -45 171 -76 14 -8 32 -14 39 -14 6
                                                                              0 41 -14 76 -30 34 -17 69 -30 77 -30 7 0 19 -7 26 -15 14 -17 71 -20 87 -4 5
                                                                              5 25 18 44 27 34 17 87 98 88 134 1 25 -35 91 -67 120 -25 24 -109 68 -130 68
                                                                              -5 0 -15 7 -22 15 -7 8 -21 15 -31 15 -11 0 -27 4 -37 10 -29 16 -110 50 -120
                                                                              50 -5 0 -15 7 -22 15 -7 8 -21 15 -31 15 -11 0 -27 4 -37 10 -29 16 -110 50
                                                                              -120 50 -5 0 -15 7 -22 15 -7 8 -21 15 -31 15 -11 0 -27 4 -37 10 -9 5 -39 19
                                                                              -67 30 -27 12 -63 27 -80 35 -67 30 -103 45 -112 45 -5 0 -20 11 -32 24 l-23
                                                                              24 70 56 c104 82 245 249 332 391 48 78 140 267 140 287 0 8 4 18 9 24 5 5 18
                                                                              43 30 84 12 41 26 89 32 105 10 28 15 61 39 238 11 89 11 247 0 347 -16 138
                                                                              -55 327 -71 345 -5 5 -9 18 -9 28 0 10 -6 33 -14 50 -7 18 -23 57 -35 87 -86
                                                                              215 -285 491 -453 628 -147 118 -187 147 -298 211 -56 33 -204 101 -219 101
                                                                              -10 0 -24 7 -31 15 -7 8 -25 15 -39 15 -15 0 -36 7 -47 15 -10 8 -35 15 -54
                                                                              15 -20 0 -45 6 -56 14 -11 8 -42 14 -70 14 -27 1 -70 7 -94 16 -60 19 -447 20
                                                                              -500 0z m410 -325 c25 -5 63 -12 85 -15 76 -10 190 -37 210 -51 11 -7 28 -13
                                                                              37 -13 10 0 26 -4 36 -9 9 -6 53 -27 97 -48 44 -21 94 -47 110 -57 141 -89
                                                                              252 -181 333 -277 88 -103 179 -242 216 -329 11 -25 28 -64 38 -87 10 -23 18
                                                                              -47 18 -53 0 -5 4 -18 9 -28 30 -56 71 -307 71 -432 0 -133 -39 -362 -76 -447
                                                                              -8 -17 -14 -36 -14 -42 0 -6 -4 -19 -9 -29 -6 -9 -27 -53 -48 -97 -21 -44 -47
                                                                              -93 -57 -110 -89 -141 -181 -253 -277 -333 -81 -69 -183 -142 -198 -142 -5 0
                                                                              -16 -7 -25 -15 -17 -18 -129 -75 -145 -75 -6 0 -28 -9 -48 -20 -21 -11 -45
                                                                              -20 -53 -20 -9 0 -20 -4 -25 -9 -10 -9 -132 -37 -200 -45 -22 -3 -60 -10 -85
                                                                              -15 -58 -13 -242 -13 -300 0 -25 5 -63 12 -85 15 -76 10 -190 37 -210 51 -11
                                                                              7 -28 13 -37 13 -10 0 -26 4 -36 9 -9 6 -53 27 -97 48 -44 21 -93 47 -110 57
                                                                              -141 89 -252 181 -333 277 -88 103 -179 242 -216 329 -11 25 -28 64 -38 87
                                                                              -10 23 -18 47 -18 53 0 5 -4 18 -9 28 -30 56 -71 307 -71 432 0 133 39 362 76
                                                                              447 8 17 14 36 14 42 0 6 4 19 9 29 6 9 27 53 48 97 21 44 47 94 57 110 89
                                                                              141 181 253 277 333 81 69 183 142 198 142 5 0 16 7 25 15 17 18 129 75 145
                                                                              75 6 0 28 9 48 20 21 11 45 20 53 20 9 0 20 4 26 9 9 9 129 36 199 46 22 2 58
                                                                              9 80 14 55 12 250 12 305 0z"/>
                                                                    <path d="M4808 1870 c-353 -357 -648 -650 -654 -650 -5 0 -125 117 -266 260
                                                                              -160 162 -267 262 -283 266 -34 8 -69 -8 -91 -41 -35 -54 -21 -73 305 -405
                                                                              272 -277 307 -310 336 -310 28 0 77 47 498 473 256 259 570 576 696 702 258
                                                                              257 257 257 195 319 -68 68 -22 107 -736 -614z"/>
                                                                  </g>
                                                                </svg>
                                                              </a>
                                                            }
                                                            {/* <a href="#" class="plus__link" onClick={() => this.handleManageAffectedDetailsRow({ target: { value: "up" } }, index, "position")}>
                                                            <svg width="30" height="30" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <path d="M59.375 34.1543V85.8125C59.375 87.4349 58.0598 88.75 56.4375 88.75C54.8152 88.75 53.5 87.4349 53.5 85.8125V34.1543L35.0146 52.6396C33.8675 53.7868 32.0075 53.7868 30.8604 52.6396C29.7132 51.4925 29.7132 49.6325 30.8604 48.4854L54.3604 24.9854C55.5075 23.8382 57.3675 23.8382 58.5146 24.9854L82.0146 48.4854C83.1618 49.6325 83.1618 51.4925 82.0146 52.6396C80.8675 53.7868 79.0076 53.7868 77.8604 52.6396L59.375 34.1543ZM112.25 56.4375C112.25 87.2619 87.2619 112.25 56.4375 112.25C25.6131 112.25 0.625 87.2619 0.625 56.4375C0.625 25.6131 25.6131 0.625 56.4375 0.625C87.2619 0.625 112.25 25.6131 112.25 56.4375ZM106.375 56.4375C106.375 28.8578 84.0172 6.5 56.4375 6.5C28.8578 6.5 6.5 28.8578 6.5 56.4375C6.5 84.0172 28.8578 106.375 56.4375 106.375C84.0172 106.375 106.375 84.0172 106.375 56.4375Z" fill="black" />
                                                            </svg>
                                                          </a>
                                                          <a href="#" class="plus__link" onClick={() => this.handleManageAffectedDetailsRow({ target: { value: "down" } }, index, "position")}>
                                                            <svg width="30" height="30" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <g transform="rotate(180 56.4375 56.4375)">
                                                                <path d="M59.375 34.1543V85.8125C59.375 87.4349 58.0598 88.75 56.4375 88.75C54.8152 88.75 53.5 87.4349 53.5 85.8125V34.1543L35.0146 52.6396C33.8675 53.7868 32.0075 53.7868 30.8604 52.6396C29.7132 51.4925 29.7132 49.6325 30.8604 48.4854L54.3604 24.9854C55.5075 23.8382 57.3675 23.8382 58.5146 24.9854L82.0146 48.4854C83.1618 49.6325 83.1618 51.4925 82.0146 52.6396C80.8675 53.7868 79.0076 53.7868 77.8604 52.6396L59.375 34.1543ZM112.25 56.4375C112.25 87.2619 87.2619 112.25 56.4375 112.25C25.6131 112.25 0.625 87.2619 0.625 56.4375C0.625 25.6131 25.6131 0.625 56.4375 0.625C87.2619 0.625 112.25 25.6131 112.25 56.4375ZM106.375 56.4375C106.375 28.8578 84.0172 6.5 56.4375 6.5C28.8578 6.5 6.5 28.8578 6.5 56.4375C6.5 84.0172 28.8578 106.375 56.4375 106.375C84.0172 106.375 106.375 84.0172 106.375 56.4375Z" fill="black" />
                                                              </g>
                                                            </svg>

                                                          </a> */}
                                                          </div>
                                                        </>
                                                        <div class="plus__line plus__line--h"></div>
                                                      </div>
                                                    </div>
                                                    {/* {assignment &&
                                                    <span className="suppression-btn-danger mr-1" onClick={() => this.handleManageAffected(index, "delete")}>-</span>
                                                  } */}
                                                    {/* {(assignment.endDate && assignment.handledBy) &&
                                                    <span className="insertion-btn-default" onClick={()=>this.handleManageAffected(index,"add")}>+</span>
                                                  } */}
                                                  </td>
                                                }
                                                {assignment.isValidated &&
                                                  <svg width="30" height="30" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M59 117.333C26.7834 117.333 0.666687 91.2165 0.666687 58.9999C0.666687 26.7832 26.7834 0.666504 59 0.666504C91.2166 0.666504 117.333 26.7832 117.333 58.9999C117.333 91.2165 91.2166 117.333 59 117.333ZM59 111.5C87.995 111.5 111.5 87.9948 111.5 58.9999C111.5 30.0049 87.995 6.49984 59 6.49984C30.0051 6.49984 6.50002 30.0049 6.50002 58.9999C6.50002 87.9948 30.0051 111.5 59 111.5ZM77.3543 42.3541C78.4933 41.2151 80.3401 41.2151 81.4791 42.3541C82.6181 43.4931 82.6181 45.3399 81.4791 46.4789L52.3124 75.6456C51.1734 76.7846 49.3267 76.7846 48.1876 75.6456L36.521 63.9789C35.3819 62.8399 35.3819 60.9932 36.521 59.8541C37.66 58.7151 39.5067 58.7151 40.6458 59.8541L50.25 69.4584L77.3543 42.3541Z" fill="#5CC973" />
                                                  </svg>
                                                }
                                                <td className="ddlist">
                                                  <CustomDropdown contract={this.state.contract} canBeFree={this.state.contract && this.state.contract.handledBy !== undefined && this.state.contract.handledBy !== null && this.state.contract.workflow && this.state.contract.workflow.length == 1 && !this.state.contract.workflow["endDate"] ? true:false} isWorkflow={true} position={index} 
                                                    handledBy={assignment && assignment.handledBy && assignment.handledBy !== undefined ? { id: assignment.handledBy["id"] } : null}
                                                    handledByArchivedUser={this.state.contract && this.state.contract.handledBy && this.state.contract.handledBy !== undefined && this.state.contract.handledBy["id"] == undefined ? this.state.contract.handledBy : null}
                                                    options={(this.state.contract.buyers && this.state.contract.buyers.length > 0 && this.state.contract.buyers.sort((a, b) => a.lastName.localeCompare(b.lastName))) || []}
                                                    disabled={assignment.isValidated || !this.state.contract.isManager || (!canCurrentUserDoAffectation && !canCurrentUserDeleteAffectation)} onSelect={this.handleDropdownChange} />
                                                </td>
                                                <td>
                                                  <InfoTooltip content={
                                                    assignment && assignment.handledBy && assignment.handledBy["contracts"] !== undefined && assignment.handledBy["contracts"].length > 0 ?
                                                      assignment.handledBy["contracts"].map(contract => {
                                                        return (
                                                          <p>{"Contrat: " + contract["contract"] + (contract["endDate"] && contract["endDate"] !== null ? " ; Date de fin: " + contract["endDate"] : "")}</p>

                                                        )
                                                      })
                                                      :
                                                      "Aucune information sur les contrats avec date limites de l’intervenant"
                                                  } />
                                                </td>
                                                {this.state.contract && this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 &&
                                                  ((this.state.contract["workflow"][0] !== undefined && this.state.contract["workflow"][0].endDate !== undefined && this.state.contract["workflow"][0].endDate !== "") || this.state.contract["workflow"].length > 1) &&
                                                  <td>
                                                    <label className="switch switch-green">
                                                      <input disabled={assignment.isValidated || !this.state.contract.isManager || (!canCurrentUserDoAffectation && !canCurrentUserDeleteAffectation)} type="checkbox" className="switch-input" checked={assignment.canParticipate} onClick={() => this.handleManageAffectedDetailsRow({ target: { value: !assignment.canParticipate } }, index, "canParticipate")} />
                                                      <span className="switch-label" data-on="Oui" data-off="Non"></span>
                                                      <span className="switch-handle"></span>
                                                    </label>
                                                  </td>
                                                }
                                                <td>
                                                  {assignment.handledBy &&
                                                    <DatePicker
                                                      minDate={new Date()}
                                                      disabled={assignment.isValidated || !this.state.contract.isManager || (!canCurrentUserDoAffectation && !canCurrentUserDeleteAffectation)}
                                                      locale="fr"
                                                      dateFormat="dd/MM/yyyy"
                                                      className={`form-control w-100 ${!assignment.endDate && this.state.editMode ? "required-field" : (assignment.endDate && this.state.editMode ? "success-field" : "")
                                                        }`}
                                                      autoComplete="off"
                                                      selected={
                                                        assignment.endDate
                                                          ? Moment(
                                                            assignment.endDate,
                                                            "DD/MM/YYYY"
                                                          ).toDate()
                                                          : ""
                                                      }
                                                      name="endDate"
                                                      onChange={(e) => {
                                                        this.handleManageAffectedDetailsRow(e, index, "endDate")
                                                      }}
                                                    />
                                                  }
                                                </td>
                                                {this.state.contract && this.state.contract["workflow"] && this.state.contract["workflow"].length > 0 &&
                                                  ((this.state.contract["workflow"][0] !== undefined && this.state.contract["workflow"][0].endDate !== undefined && this.state.contract["workflow"][0].endDate !== "") || this.state.contract["workflow"].length > 1) &&
                                                  <td>
                                                    {assignment ? this.suivieCalc(assignment) : "--"}
                                                  </td>
                                                }
                                              </tr>
                                            )
                                          })
                                        }

                                      </tbody>
                                    </table>
                                  </div>
                                }
                              </>
                          }
                          <hr />
                          <h2 className="disabled-action">Relative au contrat
                            <div className="zone-switch d-flex align-items-center">
                                {this.state.zonesClauses.details_contract &&
                                  <div className="replier">
                                    <ButtonUp
                                      onPress={() =>
                                        this.setState({
                                          ...this.state,
                                          zonesClauses: {
                                            ...this.state.zonesClauses,
                                            details_contract: false
                                          }
                                        })
                                      }
                                      disabled={!this.state.zonesClauses.details_contract}
                                      color="#6E4CF5" />
                                  </div>
                                  }
                                {!this.state.zonesClauses.details_contract &&
                                  <div className="deplier">
                                    <ButtonDown
                                      onPress={() =>
                                        this.setState({
                                          ...this.state,
                                          zonesClauses: {
                                            ...this.state.zonesClauses,
                                            details_contract: true
                                          }
                                        })}
                                      disabled={this.state.zonesClauses.details_contract}
                                      color="#6E4CF5" />
                                  </div>
                                  }
                            </div>
                          </h2>
                          { this.state.zonesClauses.details_contract && 
                            <>
                          
                          <div
                                className={`input-item input-item-styled ${
                                  (!this.state.contractCode?.value && this.state.editMode ? "required" : "") + " " + (this.state.contractCode?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                                style={{maxWidth:'150px'}}
                              >
                                <label>
                                  Contrat nº
                                  <InfoTooltip
                                    content={this.state.contractCode?.description}
                                  />
                                </label>
                                
                              </div>
                          <div
                                className={`input-item input-item-styled ${" " + (this.state.contractCode?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")}`}
                              >
                                
                                <div className="d-flex">
                                  <input
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(this.state.contractCode),
                                        this.state.contractCode.isFirstPage
                                      );
                                    }}
                                    className={`form-control ${
                                      this.eligibilityCheck().write ?
                                      (!this.state.contractCode?.value && this.state.editMode ? "required-field" : (this.state.contractCode?.value && this.state.editMode ? "success-field":"")):
                                      "restricted-input copy-field"
                                    }`}
                                    name="contractCode"
                                    value={
                                      this.state.contractCode?.value
                                        ? this.state.contractCode.value
                                        : ""
                                    }
                                    onChange={(e) => {
                                      this.handleContractsStatesUpdate(e);
                                      this.handleCheckContractParameters("contractNumber", e);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    readOnly={!this.eligibilityCheck("contractNumber").write}
                                    onClick={() => this.copyToClipBoard('Nº de contrat', this.state.contractCode.value)}
                                  />
                                  <div style={{ width: 38 }}></div>
                                  { 
                                    (this.state.editMode || (!this.state.editMode && this.checkCible("contractCode"))) &&
                                      <ButtonCible
                                      onPress={() =>
                                        this.setSelectedText("contractCode")
                                      }
                                      disabled={false}
                                      cibleUsed={this.state.editMode && this.checkCible("contractCode")}
                                    />
                                  }
                                  {
                                    this.state.contractCode?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.contractCode?.keywordId, false, "contractCode")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.contractCode?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.contractCode?.keywordId, true, "contractCode")
                                        }
                                      />
                                  }
                                </div>
                              </div>

                          <div
                                className={`input-item input-item-styled ${
                                  (!this.state.signatory1?.value && this.state.editMode ? "required" : "") + " " + (this.state.signatory1?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                <label>
                                  Signataire externe
                                  <InfoTooltip
                                    content={this.state.signatory1?.description}
                                  />
                                  {
                                    this.state.signatory1?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.signatory1?.keywordId, false, "signatory1")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.signatory1?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.signatory1?.keywordId, true, "signatory1")
                                        }
                                      />
                                  }
                                </label>
                                <div className="d-flex">
                                {
                                    (this.state.editMode || (!this.state.editMode && this.checkCible("signatory1"))) &&
                                    <ButtonCible
                                      onPress={() => this.setSelectedText("signatory1")}
                                      disabled={false}
                                      cibleUsed={this.state.editMode && this.checkCible("signatory1")}
                                    />
                                  }
                                  <input
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(this.state.signatory1),
                                        this.state.signatory1.isFirstPage
                                      );
                                    }}
                                    disabled={
                                      this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ? true : false
                                    }
                                    className={`form-control w-100 ${
                                      this.eligibilityCheck().write ?
                                      (!this.state.signatory1?.value && this.state.editMode ? "required-field" : (this.state.signatory1?.value && this.state.editMode ? "success-field" : "")):
                                      "restricted-input copy-field"
                                    }`}
                                    name="signatory1"
                                    value={
                                      this.state.signatory1
                                        ? this.state.signatory1.value
                                        : ""
                                    }
                                    onChange={(e) => {
                                      this.handleContractsStatesUpdate(e);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    readOnly={!this.eligibilityCheck("signatory1").write}
                                    onClick={() => this.copyToClipBoard('Signataire Fournisseur', this.state.signatory1.value)}
                                  />
                                  <div style={{ width: 68 }}></div>
                                </div>
                              </div>

                              <div
                                className={`input-item input-item-styled ${
                                  (!this.state.signatory2?.value && this.state.editMode ? "required" : "") + " " + (this.state.signatory2?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                <label>
                                  Signataire interne
                                  <InfoTooltip
                                    content={this.state.signatory2?.description}
                                  />
                                  {
                                    this.state.signatory2?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.signatory2?.keywordId, false, "signatory2")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.signatory2?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.signatory2?.keywordId, true, "signatory2")
                                        }
                                      />
                                  }
                                </label>
                                <div className="d-flex">
                                  {
                                    (this.state.editMode || (!this.state.editMode && this.checkCible("signatory2"))) &&
                                    <ButtonCible
                                      onPress={() => this.setSelectedText("signatory2")}
                                      disabled={false}
                                      cibleUsed={this.state.editMode && this.checkCible("signatory2")}
                                    />
                                  }
                                  <input
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(this.state.signatory2),
                                        this.state.signatory2.isFirstPage
                                      );
                                    }}
                                    disabled={
                                      this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ? true : false
                                    }
                                    className={`form-control w-100  ${
                                      this.eligibilityCheck().write ?
                                      (!this.state.signatory2?.value && this.state.editMode ? "required-field" : (this.state.signatory2?.value && this.state.editMode ? "success-field" : "")):
                                      "restricted-input copy-field"
                                    }`}
                                    name="signatory2"
                                    id="signatory2"
                                    value={this.state.signatory2?.value}
                                    onChange={(e) => {
                                      this.handleContractsStatesUpdate(e);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    readOnly={!this.eligibilityCheck("signatory2").write}
                                    onClick={() => this.copyToClipBoard('Signataire interne', this.state.signatory2.value)}
                                  />
                                
                                  <div style={{ width: 68 }}></div>
                                </div>
                              </div>

                              

                              <div
                                className={`input-item input-item-styled col-form-item-12 ${
                                  (!this.state.objet?.value && this.state.editMode ? "required" : "") + " " + (this.state.objet?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                <label>
                                  Objet
                                  <InfoTooltip
                                    content={this.state.objet?.description}
                                  />
                                  {
                                    this.state.objet?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.objet?.keywordId, false, "objet")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.objet?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.objet?.keywordId, true, "objet")
                                        }
                                      />
                                  }
                                </label>
                                <div className="d-flex">
                                  { 
                                    (this.state.editMode || (!this.state.editMode && this.checkCible("objet"))) &&
                                    <ButtonCible
                                      onPress={() => this.setSelectedText("objet")}
                                      disabled={false}
                                      cibleUsed={this.state.editMode && this.checkCible("objet")}
                                    />
                                  }
                                  <textarea
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(this.state.objet),
                                        this.state.objet.isFirstPage
                                      );
                                    }}
                                    disabled={
                                      this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ? true : false
                                    }
                                    className={`form-control w-100  ${
                                      this.eligibilityCheck().write ?
                                      (!this.state.objet?.value  && this.state.editMode ? "required-field" : (this.state.objet?.value  && this.state.editMode ? "success-field" : "")):
                                      "restricted-input copy-field"
                                    }`}
                                    name="objet"
                                    value={
                                      this.state.objet ? this.state.objet.value : ""
                                    }
                                    onChange={(e) => {
                                      this.handleContractsStatesUpdate(e);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    rows={5}
                                    readOnly={!this.eligibilityCheck("objet").write}
                                    onClick={() => this.copyToClipBoard('Objet de contrat', this.state.objet ? this.state.objet.value : "")}
                                  />
                                  <div style={{ width: 60 }}></div>
                                </div>
                              </div>
                              
                          </>     
                          }  

                          <hr />
                          <h2>Montants & échéances
                              {
                                this.state.dueAmount?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                this.state.dueFrequency?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                this.state.totalAmoutInitialPeriod?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                this.state.amount?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                <ButtonLock
                                  onPress={() =>
                                    this.lockUnlockKeywordsList([
                                      {keywordId: this.state.dueAmount?.keywordId, keywordName: "dueAmount"},
                                      {keywordId: this.state.dueFrequency?.keywordId, keywordName: "dueFrequency"},
                                      {keywordId: this.state.totalAmoutInitialPeriod?.keywordId, keywordName: "totalAmoutInitialPeriod"},
                                      {keywordId: this.state.amount?.keywordId, keywordName: "amount"},
                                    ],false)
                                  }
                                />
                              }
                              {
                                !this.state.dueAmount?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                !this.state.dueFrequency?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                !this.state.totalAmoutInitialPeriod?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                !this.state.amount?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                <ButtonUnLock
                                  onPress={() =>
                                    this.lockUnlockKeywordsList([
                                      {keywordId: this.state.dueAmount?.keywordId, keywordName: "dueAmount"},
                                      {keywordId: this.state.dueFrequency?.keywordId, keywordName: "dueFrequency"},
                                      {keywordId: this.state.totalAmoutInitialPeriod?.keywordId, keywordName: "totalAmoutInitialPeriod"},
                                      {keywordId: this.state.amount?.keywordId, keywordName: "amount"},
                                    ],true)
                                  }
                                />
                              }
                            <div className="zone-switch d-flex align-items-center">
                                {this.state.zonesClauses.amounts && 
                                  <div className="replier">
                                    <ButtonUp
                                      onPress={() =>
                                        this.setState({
                                          ...this.state,
                                          zonesClauses: {
                                            ...this.state.zonesClauses,
                                            amounts: false
                                          }
                                        })
                                      }
                                      disabled={!this.state.zonesClauses.amounts}
                                      color="#6E4CF5" />
                                  </div>
                                }
                                {!this.state.zonesClauses.amounts && 
                                  <div className="deplier">
                                    <ButtonDown
                                      onPress={() =>
                                        this.setState({
                                          ...this.state,
                                          zonesClauses: {
                                            ...this.state.zonesClauses,
                                            amounts: true
                                          }
                                        })}
                                      disabled={this.state.zonesClauses.amounts}
                                      color="#6E4CF5" />
                                  </div>
                                }
                            </div>
                          </h2>
                          { this.state.zonesClauses.amounts &&
                            <>
                            
                              <div
                                className={`input-item input-item-styled col-form-item-4 ${
                                  (!this.state.dueAmount?.value && this.state.editMode ? "required" : "") + " " + (this.state.dueAmount?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                <label>
                                  Mt échéances
                                  <InfoTooltip
                                    content={this.state.dueAmount?.description}
                                  />
                                  {/* {
                                    this.state.dueAmount?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.dueAmount?.keywordId, false, "dueAmount")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.dueAmount?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.dueAmount?.keywordId, true, "dueAmount")
                                        }
                                      />
                                  } */}
                                </label>
                                <div className="d-flex">
                                  { 
                                    (this.state.editMode || (!this.state.editMode && this.checkCible("dueAmount"))) &&
                                    <ButtonCible
                                      onPress={() =>
                                        this.setSelectedText("dueAmount", "number")
                                      }
                                      disabled={false}
                                      cibleUsed={this.state.editMode && this.checkCible("dueAmount")}
                                    />
                                  }
                                  <CurrencyInput
                                    allowDecimals = {true}
                                    decimalSeparator=","
                                    groupSeparator=" "
                                    type="text"
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(this.state.dueAmount),
                                        this.state.dueAmount.isFirstPage
                                      );
                                    }}
                                    disabled={
                                      this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ? true : false
                                    }
                                    className={`form-control w-100 ${
                                      this.eligibilityCheck().write ?
                                      !this.state.dueAmount?.value  && this.state.editMode ? "required-field" : (this.state.dueAmount?.value  && this.state.editMode ? "success-field" : ""):
                                      "restricted-input copy-field"
                                    }`}
                                    name="dueAmount"
                                    id="dueAmount"
                                    value={this.state.dueAmount?.value && !isNaN(this.state.dueAmount?.value) ? this.state.dueAmount?.value : 0}
                                    onValueChange={(value, name) => {
                                      this.handleContractsStatesUpdate({
                                        name:name,
                                        value:value
                                      },true);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    readOnly={!this.eligibilityCheck("dueAmount").write}
                                    onClick={() => this.copyToClipBoard('Mt échéances', this.state.dueAmount?.value && !isNaN(this.state.dueAmount?.value) ? this.state.dueAmount?.value : 0)}
                                  />
                                  <div style={{ width: 58 }}></div>
                                </div>
                              </div>

                              <div
                                className={`input-item input-item-styled col-form-item-4 border-bloc ${
                                  (!this.ifDurationObjectExists(
                                    this.state.dueFrequency
                                  ) && this.state.editMode
                                    ? "required"
                                    : "") + " " + (this.state.dueFrequency?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                <label>
                                  Fréquence
                                  <InfoTooltip
                                    content={this.state.dueFrequency?.description}
                                  />
                                  {/* {
                                    this.state.dueFrequency?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.dueFrequency?.keywordId, false, "dueFrequency")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.dueFrequency?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.dueFrequency?.keywordId, true, "dueFrequency")
                                        }
                                      />
                                  } */}
                                </label>
                                <div className="d-flex">
                                  { 
                                    (this.state.editMode || (!this.state.editMode && this.checkCible("dueFrequency"))) &&
                                    <ButtonCible
                                      onPress={() =>
                                        this.setSelectedText("dueFrequency", "number")
                                      }
                                      disabled={false}
                                      cibleUsed={this.state.editMode && this.checkCible("dueFrequency")}
                                    />
                                  }
                                  <input
                                    type="text"
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(this.state.dueFrequency),
                                        this.state.dueFrequency.isFirstPage
                                      );
                                    }}
                                    disabled={
                                      this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ? true : false
                                    }
                                    className={`form-control w-100 ${
                                      this.eligibilityCheck().write ?
                                      (!this.ifDurationObjectExists(this.state.dueFrequency)  && this.state.editMode ? "required-field" : (this.ifDurationObjectExists(this.state.dueFrequency)  && this.state.editMode ? "success-field" : "")):
                                      "restricted-input copy-field"
                                    }`}
                                    name="dueFrequency"
                                    id="dueFrequency"
                                    value={this.state.dueFrequency?.value?.duration}
                                    onChange={(e) => {
                                      this.handleContractsStatesUpdate(e);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    readOnly={!this.eligibilityCheck("dueFrequency").write}
                                    onClick={() => this.copyToClipBoard('Fréquence', this.state.dueFrequency?.value?.duration)}
                                  />

                                  <div
                                    role="group"
                                    className={"btn-group-sm btn-group btn-group-toggle btn-custom-toggle " + ((this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ) ? " restricted-event":"")}
                                    data-toggle="buttons"
                                  >
                                    <label
                                      className={(this.getDueFrequencyLabelClass(1)) + " " + (!this.eligibilityCheck("dueFrequency").write ? 'restricted-event':'')}
                                      onClick={(e) => {
                                        this.handleDueFrequencyType(1);
                                      }}
                                    >
                                      <input type="radio" autoComplete="off" />
                                      <span>Mois</span>
                                    </label>
                                    <label
                                      className={(this.getDueFrequencyLabelClass(2)) + " " +  (!this.eligibilityCheck("dueFrequency").write ? 'restricted-event':'')}
                                      onClick={(e) => {
                                        this.handleDueFrequencyType(2);
                                      }}
                                    >
                                      <input type="radio" autoComplete="off" />
                                      <span>Année</span>
                                    </label>
                                  </div>
                                  <div style={{ width: 58 }}></div>
                                  
                                </div>
                              </div>

                              <div
                                className={`input-item input-item-styled col-form-item-4 border-bloc ${
                                  (!this.state.term && this.state.editMode ? "required" : "") + " " + (this.state.totalAmoutInitialPeriod?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                  <div className="label">
                                    <label>
                                      Terme
                                      {/* {
                                        this.state.totalAmoutInitialPeriod?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                        <ButtonLock
                                          onPress={() =>
                                            this.lockUnlockKeyword(this.state.totalAmoutInitialPeriod?.keywordId, false, "totalAmoutInitialPeriod")
                                          }
                                        />
                                      }
                                      {
                                        !this.state.totalAmoutInitialPeriod?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                        <ButtonUnLock
                                          onPress={() =>
                                            this.lockUnlockKeyword(this.state.totalAmoutInitialPeriod?.keywordId, true, "totalAmoutInitialPeriod")
                                          }
                                        />
                                      } */}
                                    </label>
                                  </div>
                                <div className="d-flex term-bloc">
                                  {/* {
                                    (this.state.editMode || (!this.state.editMode && this.checkCible("term"))) &&
                                    <ButtonCible
                                      onPress={() => this.setSelectedText("term")}
                                      disabled={true}
                                      cibleUsed={this.state.editMode && this.checkCible("term")}
                                    />
                                    } */}
                                  <div
                                    role="group"
                                    className={"btn-group-sm btn-group btn-group-toggle btn-custom-toggle " + ((this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ) ? " restricted-event":"")}
                                    data-toggle="buttons"
                                  >
                                    <label
                                        className={(this.state.term == 2 ? 'btn' : 'active btn')  + " " +  (!this.eligibilityCheck("term").write ? 'restricted-event':'')}
                                      onClick={(e) => {
                                        this.handleContractInitialAmountTerm(1);
                                      }}
                                    >
                                      <input type="radio" autoComplete="off" />
                                      <span>A échoir</span>
                                    </label>
                                    <label
                                      className={(this.state.term == 1 ? 'btn' : 'active btn')  + " " +  (!this.eligibilityCheck("term").write ? 'restricted-event':'')}
                                      onClick={(e) => {
                                        this.handleContractInitialAmountTerm(2);
                                      }}
                                    >
                                      <input type="radio" autoComplete="off" />
                                      <span>Echu</span>
                                    </label>
                                  </div>
                                  <div style={{ width: 68, maxWidth: 62 }}></div>
                                </div>
                              </div>
                              

                              <div
                                className={`input-item input-item-styled  ${
                                  (!this.state.amount?.value && this.state.editMode ? "required" : "") + " " + (this.state.amount?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                <label>
                                  Montant total marché ou budget
                                  <InfoTooltip
                                    content={this.state.amount?.description}
                                  />
                                  {/* {
                                    this.state.amount?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.amount?.keywordId, false, "amount")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.amount?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.amount?.keywordId, true, "amount")
                                        }
                                      />
                                  } */}
                                </label>
                                <div className="d-flex">
                                  {
                                    (this.state.editMode || (!this.state.editMode && this.checkCible("amount"))) &&
                                    <ButtonCible
                                      onPress={() =>
                                        this.setSelectedText("amount", "number")
                                      }
                                      disabled={false}
                                      cibleUsed={this.state.editMode && this.checkCible("amount")}
                                    />
                                  }
                                  <CurrencyInput
                                    allowDecimals = {true}
                                    decimalSeparator=","
                                    groupSeparator=" "
                                    type="text"
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(this.state.amount),
                                        this.state.amount.isFirstPage
                                      );
                                    }}
                                    disabled={
                                      this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ? true : false
                                    }
                                    className={`form-control w-100 ${
                                      this.eligibilityCheck().write ?
                                      (!this.state.amount?.value && this.state.editMode ? "required-field" : (this.state.amount?.value && this.state.editMode ? "success-field" : "")):
                                      "restricted-input copy-field"
                                    }`}
                                    name="amount"
                                    id="amount"
                                    value={this.state.amount?.value && !isNaN(this.state.amount?.value) ? this.state.amount?.value : 0}
                                    onValueChange={(value, name) => {
                                      this.handleContractsStatesUpdate({
                                        name:name,
                                        value:value
                                      },true);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    readOnly={!this.eligibilityCheck("amount").write}
                                    onClick={() => this.copyToClipBoard('Montant total marché ou budget', this.state.amount?.value && !isNaN(this.state.amount?.value) ? this.state.amount?.value : 0)}
                                  />
                                  <div style={{ width: 58}}></div>
                                </div>
                              </div>

                              <div
                                className={`input-item input-item-styled ${
                                  (!this.ifAmountObjectExists(
                                    this.state.totalAmoutInitialPeriod
                                  ) && this.state.editMode
                                    ? "required"
                                    : "") + " " + (this.state.totalAmoutInitialPeriod?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                <label>
                                  Frais installation/FAS
                                  <InfoTooltip
                                    content={
                                      this.state.totalAmoutInitialPeriod?.description
                                    }
                                  />
                                  {/* {
                                    this.state.totalAmoutInitialPeriod?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.totalAmoutInitialPeriod?.keywordId, false, "totalAmoutInitialPeriod")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.totalAmoutInitialPeriod?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.totalAmoutInitialPeriod?.keywordId, true, "totalAmoutInitialPeriod")
                                        }
                                      />
                                  } */}
                                </label>
                                <div className="d-flex">
                                  {
                                    (this.state.editMode || (!this.state.editMode && this.checkCible("totalAmoutInitialPeriod"))) &&
                                      <ButtonCible
                                        onPress={() =>
                                          this.setSelectedText(
                                            "totalAmoutInitialPeriod",
                                            "number"
                                          )
                                        }
                                        disabled={false}
                                        cibleUsed={this.state.editMode && this.checkCible("totalAmoutInitialPeriod")}
                                      />
                                  }
                                  <CurrencyInput
                                    allowDecimals = {true}
                                    decimalSeparator=","
                                    groupSeparator=" "
                                    type="text"
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(
                                          this.state.totalAmoutInitialPeriod
                                        ),
                                        this.state.totalAmoutInitialPeriod
                                          ?.isFirstPage
                                      );
                                    }}
                                    disabled={
                                      this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ? true : false
                                    }
                                    className={`form-control w-100 ${
                                      this.eligibilityCheck().write ?
                                      (!this.ifAmountObjectExists(this.state.totalAmoutInitialPeriod) && this.state.editMode ? "required-field" : (this.ifAmountObjectExists(this.state.totalAmoutInitialPeriod) && this.state.editMode ? "success-field" : "")):
                                      "restricted-input copy-field"
                                    }`}
                                    name="totalAmoutInitialPeriod"
                                    id="totalAmoutInitialPeriod"
                                    value={
                                      this.state.totalAmoutInitialPeriod?.value ? 
                                          (
                                            this.state.totalAmoutInitialPeriod.value instanceof Object
                                              ? ((this.state.totalAmoutInitialPeriod.value.amount && !isNaN(this.state.totalAmoutInitialPeriod.value.amount)) ? this.state.totalAmoutInitialPeriod.value.amount : 0)
                                              : ((this.state.totalAmoutInitialPeriod.value && !isNaN(this.state.totalAmoutInitialPeriod.value)) ? this.state.totalAmoutInitialPeriod.value : 0)
                                          )
                                            : ""
                                    }
                                    onValueChange={(value, name) => {
                                      this.handleContractsStatesUpdate({
                                        name:name,
                                        value:value
                                      },true);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    readOnly={!this.eligibilityCheck("totalAmoutInitialPeriod").write}
                                    onClick={() => this.copyToClipBoard('Frais installation/FAS', this.state.totalAmoutInitialPeriod?.value ? 
                                    (
                                      this.state.totalAmoutInitialPeriod.value instanceof Object
                                        ? ((this.state.totalAmoutInitialPeriod.value.amount && !isNaN(this.state.totalAmoutInitialPeriod.value.amount)) ? this.state.totalAmoutInitialPeriod.value.amount : 0)
                                        : ((this.state.totalAmoutInitialPeriod.value && !isNaN(this.state.totalAmoutInitialPeriod.value)) ? this.state.totalAmoutInitialPeriod.value : 0)
                                    )
                                      : "")}
                                  />
                                  <div style={{ width: 58}}></div>
                                </div>
                              </div>
                              
                            </>
                          }

                          <hr />

                          <h2 className="disabled-action">Dates & durée
                            <div className="zone-switch d-flex align-items-center">
                                {this.state.zonesClauses.duration && 
                                  <div className="replier">
                                    <ButtonUp
                                      onPress={() =>
                                        this.setState({
                                          ...this.state,
                                          zonesClauses: {
                                            ...this.state.zonesClauses,
                                            duration: false
                                          }
                                        })
                                      }
                                      disabled={!this.state.zonesClauses.duration}
                                      color="#6E4CF5" />
                                  </div>
                                }
                                {!this.state.zonesClauses.duration && 
                                  <div className="deplier">
                                    <ButtonDown
                                      onPress={() =>
                                        this.setState({
                                          ...this.state,
                                          zonesClauses: {
                                            ...this.state.zonesClauses,
                                            duration: true
                                          }
                                        })}
                                      disabled={this.state.zonesClauses.duration}
                                      color="#6E4CF5" />
                                  </div>
                                  }
                            </div>
                          </h2>
                          { this.state.zonesClauses.duration &&
                            <>
                            
                              <div
                                className={`input-item input-item-styled col-form-item-4  ${
                                  (!this.state.beginingDate?.value && this.state.editMode ? "required" : "") + " " + (this.state.beginingDate?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                <label>
                                  Date début
                                  <InfoTooltip
                                    content={this.state.beginingDate?.description}
                                  />
                                  {
                                    this.state.beginingDate?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.beginingDate?.keywordId, false, "beginingDate")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.beginingDate?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.beginingDate?.keywordId, true, "beginingDate")
                                        }
                                      />
                                  }
                                </label>
                                <div className="d-flex">
                                  { 
                                    (this.state.editMode || (!this.state.editMode && this.checkCible("beginingDate"))) &&
                                    <ButtonCible
                                      onPress={() =>
                                        this.setSelectedText("beginingDate", "date")
                                      }
                                      disabled={false}
                                      cibleUsed={this.state.editMode && this.checkCible("beginingDate")}
                                    />
                                  }
                                  <DatePicker
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(this.state.beginingDate),
                                        this.state.beginingDate.isFirstPage
                                      );
                                    }}
                                    disabled={
                                      this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ? true : false
                                    }
                                    locale="fr"
                                    dateFormat="dd/MM/yyyy"
                                    className={`form-control w-100 ${
                                      this.eligibilityCheck().write ?
                                      (!this.state.beginingDate?.value  && this.state.editMode ? "required-field" : (this.state.beginingDate?.value  && this.state.editMode ? "success-field" : "")):
                                      "restricted-input copy-field"
                                    }`}
                                    autoComplete="off"
                                    selected={
                                      this.state.beginingDate?.value
                                        ? Moment(
                                            this.state.beginingDate.value,
                                            "DD/MM/YYYY"
                                          ).toDate()
                                        : ""
                                    }
                                    name="beginingDate"
                                    onChange={(e) => {
                                      this.handleChangeDate("beginingDate", e);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    readOnly={!this.eligibilityCheck("beginingDate").write}
                                    onClick={() => this.copyToClipBoard('Date début', this.state.beginingDate.value)}
                                  />
                                  <div style={{ width: 58}}></div>
                                </div>
                              </div>
                              <div
                                className={`input-item input-item-styled-duree col-form-item-4 border-bloc ${
                                  (!this.ifDurationObjectExists(
                                    this.state.contractDuration
                                  ) && this.state.editMode
                                    ? "required"
                                    : "") + " " + (this.state.contractDuration?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                <label>
                                  Durée de contrat
                                  <InfoTooltip
                                    content={this.state.contractDuration?.description}
                                  />
                                  {
                                    this.state.contractDuration?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.contractDuration?.keywordId, false, "contractDuration")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.contractDuration?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.contractDuration?.keywordId, true, "contractDuration")
                                        }
                                      />
                                  }
                                </label>
                                <div className="d-flex">
                                  {
                                    (this.state.editMode || (!this.state.editMode && this.checkCible("contractDuration"))) &&
                                    <ButtonCible
                                      onPress={() =>
                                        this.setSelectedText(
                                          "contractDuration",
                                          "number"
                                        )
                                      }
                                      disabled={false}
                                      cibleUsed={this.state.editMode && this.checkCible("contractDuration")}
                                    />
                                  }
                                  <input
                                    type="text"
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(this.state.contractDuration),
                                        this.state.contractDuration.isFirstPage
                                      );
                                    }}
                                    disabled={
                                      this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ? true : false
                                    }
                                    className={`form-control w-100 ${
                                      this.eligibilityCheck().write ?
                                      (!this.ifDurationObjectExists(this.state.contractDuration)  && this.state.editMode ? "required-field" : (this.ifDurationObjectExists(this.state.contractDuration)  && this.state.editMode ? "success-field" : "")):
                                      "restricted-input copy-field"
                                    }`}
                                    name="contractDuration"
                                    value={
                                      this.state.contractDuration?.value
                                        ? this.state.contractDuration.value.hasOwnProperty(
                                            "duration"
                                          )
                                          ? this.state.contractDuration.value.duration
                                          : this.state.contractDuration.value
                                        : ""
                                    }
                                    onChange={(e) => {
                                      this.handleContractsStatesUpdate(e);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    readOnly={!this.eligibilityCheck("contractDuration").write}
                                    onClick={() => this.copyToClipBoard('Durée de contrat', this.state.contractDuration?.value
                                    ? this.state.contractDuration.value.hasOwnProperty(
                                        "duration"
                                      )
                                      ? this.state.contractDuration.value.duration
                                      : this.state.contractDuration.value
                                    : "")}
                                  />
                                  <div
                                    role="group"
                                    className={"btn-group-sm btn-group btn-group-toggle btn-custom-toggle " + ((this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ) ? " restricted-event":"")}
                                    data-toggle="buttons"
                                  >
                                    <label
                                      className={this.getContractDurationLabelClassName(1) + " " +  (!this.eligibilityCheck("contractDuration").write ? 'restricted-event':'')}
                                      onClick={(e) => {
                                        this.handleContractDurationType(1);
                                      }}
                                    >
                                      <input type="radio" autoComplete="off" />
                                      <span>Mois</span>
                                    </label>
                                    <label
                                      className={this.getContractDurationLabelClassName(2) + " " +  (!this.eligibilityCheck("contractDuration").write ? 'restricted-event':'')}
                                      onClick={(e) => {
                                        this.handleContractDurationType(2);
                                      }}
                                    >
                                      <input type="radio" autoComplete="off" />
                                      <span>Année</span>
                                    </label>
                                  </div>
                                  <div style={{ width: 58}}></div>
                                  
                                </div>
                              </div>
                              <div
                                className={`input-item input-item-styled col-form-item-4 border-bloc ${
                                ( !this.state.endingDate?.value && this.state.editMode ? "required" : "") + " " + (this.state.endingDate?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                <label>
                                  Date de fin
                                  <InfoTooltip
                                    content={this.state.endingDate?.description}
                                  />
                                  {
                                    this.state.endingDate?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.endingDate?.keywordId, false, "endingDate")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.endingDate?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.endingDate?.keywordId, true, "endingDate")
                                        }
                                      />
                                  }
                                </label>
                                <div className="d-flex">
                                  {
                                  (this.state.editMode || (!this.state.editMode && this.checkCible("endingDate"))) &&
                                    <ButtonCible
                                      onPress={() =>
                                        this.setSelectedText("endingDate", "date")
                                      }
                                      disabled={false}
                                      cibleUsed={this.state.editMode && this.checkCible("endingDate")}
                                    />
                                  }
                                  <DatePicker
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(this.state.endingDate),
                                        this.state.endingDate.isFirstPage
                                      );
                                    }}
                                    disabled={
                                      this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ? true : false
                                    }
                                    locale="fr"
                                    dateFormat="dd/MM/yyyy"
                                    className={`form-control w-100 ${
                                      this.eligibilityCheck().write ?
                                      (!this.state.endingDate?.value  && this.state.editMode ? "required-field" : (this.state.endingDate?.value  && this.state.editMode ? "success-field" : "")):
                                      "restricted-input copy-field"
                                    }`}
                                    autoComplete="off"
                                    selected={
                                      this.state.endingDate?.value
                                        ? Moment(
                                            this.state.endingDate.value,
                                            "DD/MM/YYYY"
                                          ).toDate()
                                        : ""
                                    }
                                    name="endingDate"
                                    onChange={(e) => {
                                      this.handleChangeDate("endingDate", e);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    readOnly={!this.eligibilityCheck("endingDate").write}
                                    onClick={() => this.copyToClipBoard('Date de fin', this.state.endingDate.value)}
                                  />
                                  <div style={{ width: 58}}></div>
                                  
                                </div>
                              </div>
      
                              <div className={`input-item ${" " + (this.state.renewal?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")}`}>
                                <label>
                                  Renouvellement
                                  <InfoTooltip
                                    content={this.state.renewal?.description}
                                  />
                                  {
                                    this.state.renewal?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.renewal?.keywordId, false, "renewal")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.renewal?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.renewal?.keywordId, true, "renewal")
                                        }
                                      />
                                  }
                                  <div style={{ width: 28}}></div>
                                </label>
                                <div className="d-flex input-item-styled-renew">
                                  <div
                                    role="group"
                                    className={"btn-group-sm btn-group btn-group-toggle renewal " + ((this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ) ? " restricted-event":"")}
                                    data-toggle="buttons"
                                  >
                                    <label
                                      className={`btn ${((!this.state.isRenewOther && ((!this.state.renewal?.value || this.state.renewal?.value == 'None') || (this.state.renewal?.value != 'Tacite' &&
                                          this.state.renewal?.value != 'Expresse' && this.state.renewal?.value != 'Prorogation' && this.state.renewal?.value != 'Autre'
                                      ))) ? 'active' : '') + " " +  (!this.eligibilityCheck("renewal").write ? 'restricted-event':'')}`}
                                      onClick={(e) => {
                                        this.handleRenewalUpdate(null);
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name="renewal"
                                        autoComplete="off"
                                        checked={this.state.renewal?.value === null}
                                        onChange={() => {}}
                                        uncontrolled
                                      />
                                      <span>Aucun</span>
                                    </label>
                                    <label
                                      className={`btn ${this.getRenewalClassName("Tacite") + " " +  (!this.eligibilityCheck("renewal").write ? 'restricted-event':'')}`}
                                      onClick={(e) => {
                                        this.handleRenewalUpdate("Tacite");
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name="renewal"
                                        value={"Tacite"}
                                        autoComplete="off"
                                        onKeyDown={(e) => {this.onKeyDown(e)}}
                                      />
                                      <span>Tacite</span>
                                    </label>
                                    <label
                                      className={`btn ${this.getRenewalClassName("Expresse") + " " +  (!this.eligibilityCheck("renewal").write ? 'restricted-event':'')}`}
                                      onClick={(e) => {
                                        this.handleRenewalUpdate("Expresse");
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name="renewal"
                                        value={"Expresse"}
                                        autoComplete="off"
                                        onKeyDown={(e) => {this.onKeyDown(e)}}
                                      />
                                      <span>Expresse</span>
                                    </label>
                                    <label
                                      className={`btn ${this.getRenewalClassName("Prorogation") + " " +  (!this.eligibilityCheck("renewal").write ? 'restricted-event':'')}`}
                                      onClick={(e) => {
                                        this.handleRenewalUpdate("Prorogation");
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name="renewal"
                                        value={"Prorogation"}
                                        autoComplete="off"
                                        onKeyDown={(e) => {this.onKeyDown(e)}}
                                      />
                                      <span>Prorogation</span>
                                    </label>
                                    <label
                                      className={`btn ${((this.state.isRenewOther && ((!this.state.renewal?.value || this.state.renewal?.value == 'None') || (this.state.renewal?.value != 'Tacite' &&
                                          this.state.renewal?.value != 'Expresse' && this.state.renewal?.value != 'Prorogation'
                                      ))) ? 'active' : '') + " " +  (!this.eligibilityCheck("renewal").write ? 'restricted-event':'')}`}
                                      onClick={(e) => {
                                        this.handleRenewalUpdate('Autre');
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name="renewal"
                                        autoComplete="off"
                                        checked={this.state.renewal?.value === 'Autre'}
                                        onChange={() => {}}
                                        uncontrolled
                                      />
                                      <span>Autre</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="d-flex input-item-styled-renew">
                                  { (this.state.isRenewOther && this.state.renewal?.value != 'Tacite' && this.state.renewal?.value != 'Expresse' && this.state.renewal?.value != 'Prorogation') &&
                                  <>
                                    {
                                      (this.state.editMode || (!this.state.editMode && this.checkCible("renewal"))) &&
                                      <ButtonCible
                                        onPress={() => this.setSelectedText("renewal")}
                                        disabled={false}
                                        cibleUsed={this.state.editMode && this.checkCible("renewal")}
                                      />
                                    }
                                    <input
                                      onFocus={(e) => {
                                        this.findSynonyme(
                                          e,
                                          this.getSynonyms(this.state.renewal),
                                          this.state.renewal.isFirstPage
                                        );
                                      }}
                                      id="renewal-input"
                                      placeholder="Autre"
                                      className="form-control"
                                      name="renewal"
                                      value={
                                        this.state.renewal?.value !== "Tacite" &&
                                        this.state.renewal?.value !== "Expresse" &&
                                        this.state.renewal?.value !== "Prorogation" &&
                                        this.state.renewal?.value !== "Autre"
                                          ? this.state.renewal?.value
                                          : ""
                                      }
                                      onChange={(e) => {
                                        this.handleContractsStatesUpdate(e);
                                      }}
                                      onKeyDown={(e) => {this.onKeyDown(e)}}
                                      readOnly={!this.eligibilityCheck("renewal").write}
                                    />
                                    
                                  </>}
                                  { this.state.renewal && this.state.renewal?.value === "Tacite" && (this.state.contract.status === 2 || this.state.contract.status === "2") &&
                                    <a onClick={() => this.reconduction()} className={"btn-pill btn btn-danger btn-reconduction"+ " " +  (!this.eligibilityCheck("renewal").write ? 'restricted-event':'')}>
                                      Lancer la reconduction
                                    </a>
                                  }

                                </div>
                              </div>
      
                              <div
                                  className={`input-item input-item-styled col-form-item-4 ${" " + (this.state.trueEndingDate?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")}`}
                              >
                                <label>
                                  Date de fin Effective
                                  {/* {
                                    this.state.trueEndingDate?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.trueEndingDate?.keywordId, false, "trueEndingDate")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.trueEndingDate?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.trueEndingDate?.keywordId, true, "trueEndingDate")
                                        }
                                      />
                                  } */}
                                </label>
                                <div className="d-flex">
                                  {/* <ButtonCible
                                      disabled={true}
                                  /> */}
                                  <DatePicker
                                      disabled={true}
                                      locale="fr"
                                      dateFormat="dd/MM/yyyy"
                                      className={`form-control w-100 ${this.eligibilityCheck().write ? "":"restricted-input copy-field"}`}
                                      autoComplete="off"
                                      selected={
                                        this.state.contract?.trueEndingDate
                                            ? Moment(
                                            this.state.contract?.trueEndingDate,
                                            "DD/MM/YYYY"
                                            ).toDate()
                                            : ""
                                      }
                                      name="trueEndingDate"
                                      onKeyDown={(e) => {this.onKeyDown(e)}}
                                      readOnly={!this.eligibilityCheck("trueEndingDate").write}
                                      onClick={() => this.copyToClipBoard('Date de fin Effective', this.state.contract?.trueEndingDate)}
                                  />
                                  <div style={{ width: 58}}></div>
                                  
                                </div>
                              </div>

                              <div
                                className={`input-item input-item-styled col-form-item-4 border-bloc ${
                                  (!this.ifPriorNoticeObjectExists(
                                    this.state.priorNoticeDuration
                                  ) && this.state.editMode
                                    ? "required"
                                    : "") + " " + (this.state.priorNoticeDuration?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                <label>
                                  Préavis
                                  <InfoTooltip
                                    content={
                                      this.state.priorNoticeDuration?.description
                                    }
                                  />
                                  {
                                    this.state.priorNoticeDuration?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.priorNoticeDuration?.keywordId, false, "priorNoticeDuration")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.priorNoticeDuration?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.priorNoticeDuration?.keywordId, true, "priorNoticeDuration")
                                        }
                                      />
                                  }
                                </label>
                                <div className="d-flex">
                                  { 
                                    (this.state.editMode || (!this.state.editMode && this.checkCible("priorNoticeDuration"))) &&
                                    <ButtonCible
                                      onPress={() =>
                                        this.setSelectedText(
                                          "priorNoticeDuration",
                                          "number"
                                        )
                                      }
                                      disabled={false}
                                      cibleUsed={this.state.editMode && this.checkCible("priorNoticeDuration")}
                                    />
                                  }
                                  <input
                                    type="text"
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(
                                          this.state.priorNoticeDuration
                                        ),
                                        this.state.priorNoticeDuration.isFirstPage
                                      );
                                    }}
                                    disabled={
                                      this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ? true : false
                                    }
                                    className={`form-control w-100 ${
                                      this.eligibilityCheck().write ?
                                      (!this.ifPriorNoticeObjectExists(this.state.priorNoticeDuration) && this.state.editMode ? "required-field" : (this.ifPriorNoticeObjectExists(this.state.priorNoticeDuration) && this.state.editMode ? "success-field" : "")):
                                      "restricted-input copy-field"
                                    }`}
                                    name="priorNoticeDuration"
                                    value={
                                      this.getPriorNoticeValue()
                                    }
                                    onChange={(e) => {
                                      this.handleContractsStatesUpdate(e);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    readOnly={!this.eligibilityCheck("priorNoticeDuration").write}
                                    onClick={() => this.copyToClipBoard('Préavis', this.getPriorNoticeValue())}
                                  />
                                  <div
                                    role="group"
                                    className={"btn-group-sm btn-group btn-group-toggle btn-custom-toggle " + ((this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ) ? " restricted-event":"")}
                                    data-toggle="buttons"
                                  >
                                    <label
                                      className={this.getPriorNoticeLabelClassName(1) + " " +  (!this.eligibilityCheck("priorNoticeDuration").write ? 'restricted-event':'')}
                                      onClick={(e) => {
                                        this.handlePriorNoticeType(1);
                                      }}
                                    >
                                      <input type="radio" autoComplete="off" />
                                      <span>Mois</span>
                                    </label>
                                    <label
                                      className={this.getPriorNoticeLabelClassName(2) + " " +  (!this.eligibilityCheck("priorNoticeDuration").write ? 'restricted-event':'')}
                                      onClick={(e) => {
                                        this.handlePriorNoticeType(2);
                                      }}
                                    >
                                      <input type="radio" autoComplete="off" />
                                      <span>Année</span>
                                    </label>
                                  </div>
                                  <div style={{ width: 58}}></div>
                                </div>
                              </div>
                              {  this.state.priorNoticeDuration &&
                                  (parseInt(this.state.priorNoticeDuration.value) < 1 || parseInt(this.state.priorNoticeDuration.value.duration)<1) &&
                                    <p className={"msg-error " + (this.state.priorNoticeDuration?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")}>
                                      * Le préavis ne peut pas être inférieur à 1
                                    </p>
                                }
      
                              <div
                                className={`input-item input-item-styled col-form-item-4 border-bloc ${
                                  (!this.ifDurationObjectExists(
                                    this.state.negociationDuration
                                  ) && this.state.editMode
                                    ? "required"
                                    : "") + " " + (this.state.negociationDuration?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? "restricted-hidden":"")
                                }`}
                              >
                                <label>
                                  Durée de négo.
                                  <InfoTooltip
                                    content={
                                      this.state.negociationDuration?.description
                                    }
                                  />
                                  {
                                    this.state.negociationDuration?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.negociationDuration?.keywordId, false, "negociationDuration")
                                        }
                                      />
                                  }
                                  {
                                    !this.state.negociationDuration?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                      <ButtonUnLock
                                        onPress={() =>
                                          this.lockUnlockKeyword(this.state.negociationDuration?.keywordId, true, "negociationDuration")
                                        }
                                      />
                                  }
                                </label>
                                <div className="d-flex">
                                  {/* <ButtonCible
                                    onPress={() =>
                                      this.setSelectedText(
                                        "negociationDuration",
                                        "number"
                                      )
                                    }
                                    disabled={false}
                                  /> */}
                                  <input
                                    type="text"
                                    onFocus={(e) => {
                                      this.findSynonyme(
                                        e,
                                        this.getSynonyms(
                                          this.state.negociationDuration
                                        ),
                                        this.state.negociationDuration.isFirstPage
                                      );
                                    }}
                                    disabled={
                                      this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ? true : false
                                    }
                                    className={`form-control w-100 ${
                                      this.eligibilityCheck().write ?
                                      (!this.ifDurationObjectExists(this.state.negociationDuration) && this.state.editMode ? "required-field" : (this.ifDurationObjectExists(this.state.negociationDuration) && this.state.editMode ? "success-field" : "")):
                                      "restricted-input copy-field"
                                    }`}
                                    name="negociationDuration"
                                    value={
                                      this.state.negociationDuration?.value
                                        ? this.state.negociationDuration.value.hasOwnProperty(
                                            "duration"
                                          )
                                          ? this.state.negociationDuration.value
                                              .duration
                                          : this.state.negociationDuration.value
                                        : ""
                                    }
                                    onChange={(e) => {
                                      this.handleContractsStatesUpdate(e);
                                    }}
                                    onKeyDown={(e) => {this.onKeyDown(e)}}
                                    readOnly={!this.eligibilityCheck("negociationDuration").write}
                                    onClick={() => this.copyToClipBoard('Durée de négo.', this.state.negociationDuration.value)}
                                  />
                                  <div
                                    role="group"
                                    className={"btn-group-sm btn-group btn-group-toggle btn-custom-toggle " + ((this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' && this.state.contract?.status === 2 ) ? " restricted-event":"")}
                                    data-toggle="buttons"
                                  >
                                    <label
                                      className={this.getNegociationDurationLabelClassName(0) + " " +  (!this.eligibilityCheck("negociationDuration").write ? 'restricted-event':'')}
                                      onClick={(e) => {
                                        this.handleNegociationDurationType(0);
                                      }}
                                    >
                                      <input type="radio" autoComplete="off" />
                                      <span>Jour</span>
                                    </label>
                                    <label
                                      className={this.getNegociationDurationLabelClassName(1) + " " +  (!this.eligibilityCheck("negociationDuration").write ? 'restricted-event':'')}
                                      onClick={(e) => {
                                        this.handleNegociationDurationType(1);
                                      }}
                                    >
                                      <input type="radio" autoComplete="off" />
                                      <span>Mois</span>
                                    </label>
                                  </div>
                                  <div style={{ width: 58}}></div>
                                </div>
                              </div>
                              
                            

                              <ResiliateContract
                                show={this.state.contract.status === 2}
                                endingDate={this.state.endingDate?.value}
                                resiliationDate={this.state.resiliationDate}
                                resiliationPenalty={this.state.contract?.penalty}
                                onPressResiliate={this.resiliateContract}
                                onPressCancelResiliate={this.cancelResiliateContract}
                                editMode={this.state.editMode}
                                editModeReminder={this.editModeReminder}
                              />

                              {
                                this.state.resiliationDate &&
                                <div  className="row resiliation-area">
                                  <div className="col-6">Demande de résiliation</div>
                                  <div className="col-6">
                                    <a className="btn btn-download-resiliation" onClick={()=>this.setState({
                                      ...this.state,
                                      showModalResilationDoc: true
                                    })}>
                                      Consulter/Télécharger
                                    </a>
                                  </div>
                                </div>
                              }
      
                              {
                                //this.state.negociationBeginingDate?.value &&
                                //this.state.priorNoticeDate?.value &&
                                this.state.contractAlerts.negciationAlert &&
                                  this.state.contractAlerts.endingAlert &&
                                  this.state.contractAlerts.priorNoticeAlert && (
                                    <div
                                      className={`auto-notices ${
                                        !this.state?.validatedAlerts && this.state.editMode ? "required" : ""
                                      }`}
                                    >
                                      <h3>Alertes automatiques</h3>
                                      {
                                        <div className=" d-flex notice-item notice-success">
                                          <AlertIcon color={"#5CC973"} />
                                          <div>
                                            <p>Alerte de lancement de négociation</p>
                                            <span>
                                              {this.state.contractAlerts
                                                .negciationAlert
                                                ? this.state.contractAlerts
                                                    .negciationAlert
                                                : null}
                                            </span>
                                          </div>
                                        </div>
                                      }
                                      {
                                        <div className=" d-flex notice-item notice-danger">
                                          <AlertIcon color={"#EB3A59"} />
                                          <div>
                                            <p>
                                              Alerte de fin de période de préavis{" "}
                                            </p>
                                            <span>
                                              {this.state.contractAlerts
                                                .priorNoticeAlert
                                                ? this.state.contractAlerts
                                                    .priorNoticeAlert
                                                : null}
                                            </span>
                                          </div>
                                        </div>
                                      }
      
                                      {
                                        <div className=" d-flex notice-item notice-secondary">
                                          <AlertIcon color={"#6E4CF5"} />
                                          <div>
                                            <p>Alerte de fin de contrat</p>
                                            <span>
                                              {this.state.contractAlerts.endingAlert
                                                ? this.state.contractAlerts
                                                    .endingAlert
                                                : null}
                                            </span>
                                          </div>
                                        </div>
                                      }
      
                                      <div className={`bg-btn ${this.state.editMode && !this.state.validatedAlerts ? 'border-error':''} `}>
                                        <div className="custom-checkbox">
                                          <label>
                                            <input
                                              disabled={
                                                this.state.contract?.status === 2
                                                  ? true
                                                  : false
                                              }
                                              type="checkbox"
                                              onChange={() => {
                                                this.manageValidateAlertsButton();
                                              }}
                                              checked={this.state.validatedAlerts}
                                            ></input>
                                            <span className="cr">
                                              <i className="cr-icon fa fa-check"></i>
                                            </span>
                                            Ces dates sont correctes
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )
                              }
                            </>
                          }

                          <hr />
                          <h2 className="disabled-action">{t('frontoffice.contract_details.custom_clauses')}
                            <div className="zone-switch d-flex align-items-center">
                                {this.state.zonesClauses.clauses_facultatives && 
                                  <div className="replier">
                                    <ButtonUp
                                      onPress={() =>
                                        this.setState({
                                          ...this.state,
                                          zonesClauses: {
                                            ...this.state.zonesClauses,
                                            clauses_facultatives: false
                                          }
                                        })
                                      }
                                      disabled={!this.state.zonesClauses.clauses_facultatives}
                                      color="#6E4CF5" />
                                  </div>
                                }
                                {!this.state.zonesClauses.clauses_facultatives && 
                                  <div className="deplier">
                                    <ButtonDown
                                      onPress={() =>
                                        this.setState({
                                          ...this.state,
                                          zonesClauses: {
                                            ...this.state.zonesClauses,
                                            clauses_facultatives: true
                                          }
                                        })}
                                      disabled={this.state.zonesClauses.clauses_facultatives}
                                      color="#6E4CF5" />
                                  </div>
                                  }
                            </div>
                          </h2>
                          {
                            this.state.zonesClauses.clauses_facultatives &&
                            <>
                                {
                                  this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                  <div className="w-100">
                                    <div className="input-item input-item-styled d-block m-auto">
                                      <div className="multi-select-checkboxes dl-dropdown-keywords">
                                        {/* <ReactMultiSelectCheckboxes
                                          resetInputOnSelect={true}
                                          onChange={(list) => {
                                            this.changeInformationsKey(list);
                                          }}
                                          value={this.state.extraKeywordsWithValues}
                                          options={this.state.informationsKey}
                                          className="organisation-options"
                                          placeholderButtonLabel="Ajouter une clause"
                                          tabSelectsValue="true"
                                          placeholder="Rechercher"
                                          no-options=""
                                        ></ReactMultiSelectCheckboxes> */}
                                          <CustomDropdownKeywords
                                            selectedValue={null}
                                            isOpen={this.state.isOpenDLKT}
                                            optionValues={this.state.extraKeywordsWithValues}
                                            options={this.reformatInfosKeys(this.state.informationsKey) || []}
                                            // disabled={this.state.selectedKeyword.name === undefined ? true : false}
                                            onSelect={(item) => this.handleDropdownChangeKeywordList(item)}
                                            handleCloseDLKT={(event) => { event.preventDefault(); this.setState({ ...this.state, isOpenDLKT: !this.state.isOpenDLKT }) }}
                                          />
                                      </div>
                                      <a className="btn-close-dropdown-keywords" onClick={() => this.setState({ ...this.state, isOpenDLKT: !this.state.isOpenDLKT })}>
                                        OK
                                      </a>
                                    </div>
                                  </div>
                                }
                            
                              {this.state.extraKeywordsWithValues &&
                                this.state.extraKeywordsWithValues.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        className={`input-item input-item-styled information-key ${
                                          (item?.isRequired &&
                                            (!item.keywordValue || item.keywordValue == null || item.keywordValue == undefined || item.keywordValue == "") &&
                                          this.state.editMode
                                            ? "required"
                                            : "") + (item?.isRestricted && (this.viewAs()?.restrictedView || this.state.isRestrictedView) ? ' restricted-hidden':'')
                                        }`}
                                      >
                                        <label>
                                          {item.label}
                                          <InfoTooltip content={item?.description} />
                                          {
                                            item?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                            <ButtonLock
                                              onPress={() =>
                                                this.lockUnlockKeyword(item?.keywordId , false, "extraKeywordsWithValues")
                                              }
                                            />
                                          }
                                          {
                                            !item?.isRestricted && this.state.contract.accessType && (this.state.contract.accessType).toLowerCase() === 'update' &&
                                            <ButtonUnLock
                                              onPress={() =>
                                                this.lockUnlockKeyword(item?.keywordId , true, "extraKeywordsWithValues")
                                              }
                                            />
                                          }
                                        </label>
                                        <div className="d-flex">
                                          {
                                            (item && item.value !== undefined && (this.state.editMode || (!this.state.editMode && this.checkCible(item.value.toString())))) &&
                                            <ButtonCible
                                              onPress={() => {
                                                item.isdate === true
                                                  ? this.setSelectedText(
                                                      item,
                                                      "date",
                                                      true
                                                    )
                                                  : this.setSelectedText(
                                                      item,
                                                      null,
                                                      true
                                                    );
                                              }}
                                              disabled={false}
                                              cibleUsed={this.state.editMode && this.checkCible(item.value.toString())}
                                            />
                                          }
                                          {this.getNonObligatoryInputObject(item)}
                                          <div style={{ width: 58}}></div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              
                              <div className="vertical-space-mobile"></div>
                            </>
                          }

                          {this.state.editMode && (
                            <div className="fixed-actions-btns">
                              <hr />
                              <div className="d-flex actions-btns">
                                {this.state.contract && this.state.contract.status !== 3 &&
                                  <>
                                    {majWorkflowBtnControl && majWorkflowBtnControl.isVisibled &&
                                      <a className="btn-pill btn btn-danger btn-validate mr-2" disabled={!majWorkflowBtnControl.isActionAllowed} onClick={() => { this.newAffectationWorkflow() }}>
                                        MàJ Workflow
                                      </a>
                                    }
                                    {affectationNormalBtnControl && affectationNormalBtnControl.isVisibled &&
                                      <a className="btn-pill btn btn-danger btn-validate mr-2 center-span-btn" disabled={!affectationNormalBtnControl.isActionAllowed} onClick={() => { this.newAffectationNormal() }}>
                                        {
                                          this.state.contract.workflow[0].handledBy && this.state.contract.workflow[0].handledBy["firstName"] !== undefined && this.state.contract.workflow[0].handledBy && this.state.contract.workflow[0].handledBy["firstName"] !== "" && this.state.contract.workflow[0].handledBy["firstName"] !== null &&
                                            this.state.contract.workflow[0].handledBy && this.state.contract.workflow[0].handledBy["lastName"] !== undefined && this.state.contract.workflow[0].handledBy && this.state.contract.workflow[0].handledBy["lastName"] !== "" && this.state.contract.workflow[0].handledBy["lastName"] !== null
                                            ? <span>{("Affecter à " + this.state.contract.workflow[0].handledBy["firstName"].charAt(0) + ". " + this.state.contract.workflow[0].handledBy["lastName"])}</span> : "Affecter"}
                                      </a>
                                    }
                                    {
                                      saveBtnControl && saveBtnControl.isVisibled &&
                                      <button
                                        disabled={!saveBtnControl.isActionAllowed}
                                        type="button"
                                        onClick={() => {
                                          this.putInHold();
                                        }}
                                        className="btn-pill btn btn-save"
                                      >
                                        Enregistrer
                                      </button>
                                    }
                                    { signBtnControl && signBtnControl.isVisibled &&
                                      <a onClick={() => this.signNego()} className={`btn-pill btn btn-danger btn-signatory center-span-btn ${!signBtnControl.isActionAllowed ? 'disabled-action-btn disabled':''}`}>
                                        <span>
                                          Signer
                                        </span>
                                    </a>}
                                    {((updateAfterValidationBtnControl && updateAfterValidationBtnControl.isVisibled) || (validateWithoutSignBtnControl && validateWithoutSignBtnControl.isVisibled)) &&
                                      <button
                                        type="button"
                                        disabled={!((updateAfterValidationBtnControl && updateAfterValidationBtnControl.isActionAllowed) || (validateWithoutSignBtnControl && validateWithoutSignBtnControl.isActionAllowed))}
                                        onClick={() => {
                                          this.validate();
                                        }}
                                        className="btn-pill btn btn-danger btn-validate-outline"
                                      >
                                        {this.state.contract?.status === 2
                                          ? "Modifier le contrat"
                                          : "Valider sans signature"}
                                      </button>
                                    }
                                </>}
                                  {
                                    taskValidationWorkflowBtnControl && taskValidationWorkflowBtnControl.isVisibled &&
                                    <button
                                      type="button"
                                      disabled={!taskValidationWorkflowBtnControl.isActionAllowed}
                                      onClick={() => {
                                        this.validateTask();
                                      }}
                                      className="btn-pill btn btn-danger btn-validate ml-2"
                                    >
                                      Fin traitement
                                    </button>
                                  }
                                {cancelSignBtnControl && cancelSignBtnControl.isVisibled &&
                                  <>
                                    <p className="signatories-count col-md-4">
                                      Signature en cours... {this.state.contract && this.state.contract.signatories && this.state.contract.signatories['signaturesCount']}/{this.state.contract && this.state.contract.signatories && this.state.contract.signatories['totalSignatories']}
                                    </p>
                                    <a onClick={() => { this.cancelSigning() }} className="btn-pill btn btn-cancel-outline col-md-4 center-span-btn" disabled={!cancelSignBtnControl.isActionAllowed}>
                                      <span>
                                        Annuler la signature
                                      </span>
                                    </a>
                                    <a onClick={() => { this.doSigningRequest() }} className="btn-pill btn btn-cancel-outline ml-1 col-md-4 center-span-btn" disabled={!cancelSignBtnControl.isActionAllowed}>
                                      <span>
                                        Relancer le signataire
                                      </span>
                                    </a>
                                </>}
                              </div>
                            </div>
                          )}
                        </FormWithConstraints>
                      </div>
                      }
                    </div>
                  </div>

                  <div className={"col-md-7 viewer" + (this.state.isContractInfoVisible ? " mobile-screen-hide-view" : "")} style={{overflowY: "auto"}}>
                    {/* <div className="ribbon"><span>Contrat</span></div> */}
                    {this.state.contract &&
                      <div className="fav-zone">
                        <button
                          disabled={true}
                          onClick={()=>this.handleFavoritedStatusChange(!this.state.contract["favorite"])}
                          class={`btn btn-icon btn-icon-only ${this.state.contract && this.state.contract["favorite"] ? 'favorite' : ''}`}
                        >
                          <i className={`lnr-star ${this.state.contract && this.state.contract["favorite"] ? '' : 'notfavorited'}`}></i>
                          <i className="fa fa-star active"></i>
                        </button>
                      </div>
                    }
                    <div className="savingDocument text-right">
                      <ToastContainer className="toast-styled-contract"/>
                     
                    </div>
                    <h1 className="title-right-side">
                      {this.state.contract.name ? this.state.contract.name : null}
                    </h1>
                    {this.state.contract && this.state.contract.accessType && (!this.viewAs()?.restrictedView || !this.state.isRestrictedView) &&
                      <>
                        <div className="row exchanges-content">
                          <div className="col-12">
                            <div className="conv_option">
                              <p className="legend-title">Sens de conversion</p>
                              <div className="input-item input-item-styled m-auto exchanges-switcher">
                                <span className="conv_option">
                                  {"EUR-->"}
                                  <br />
                                  {"-->EUR"}
                                </span>
                                <div className="rotate-switcher conv_option">
                                  <Switcher
                                    checked={this.state.exchangesParams.isFromEUR ? false : true}
                                    onChange={this.handleIsFromEURChange}
                                    disabled={this.state.isRestrictedView}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="conv_option">
                              <p className="legend-title">Sélectionner la devise</p>
                              {
                                this.state.exchangesParams.currencies.map((currency, index) => {
                                  return (
                                    <span className="conv_option text-center" key={index} onClick={() => this.handleChangeSelectedCurrencies(index)}>
                                      {currency.selected &&
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="10.5" cy="10.5" r="10" fill="#FAFCFE" stroke="#190734" />
                                          <circle cx="10.5" cy="10.5" r="7.5" fill="#F66031" />
                                        </svg>
                                      }

                                      {!currency.selected &&
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="10.5" cy="10.5" r="10" fill="#FAFCFE" stroke="#190734" />
                                        </svg>
                                      }
                                      <br />
                                      {currency.from + "/" + currency.to}
                                    </span>
                                  )
                                })
                              }
                            </div>
                          </div>

                          <div className="col-12 text-center mt-4">
                            <p className="legend-title">Sélectionner la durée à afficher</p>
                            {
                              this.state.exchangesParams.range.map((range, index) => {
                                return (
                                  <span className="conv_option text-center" key={index} onClick={() => this.handleChangeSelectedRange(index)}>
                                    {range.selected &&
                                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10.5" cy="10.5" r="10" fill="#FAFCFE" stroke="#190734" />
                                        <circle cx="10.5" cy="10.5" r="7.5" fill="#F66031" />
                                      </svg>
                                    }

                                    {!range.selected &&
                                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10.5" cy="10.5" r="10" fill="#FAFCFE" stroke="#190734" />
                                      </svg>
                                    }
                                    <br />
                                    {range.libelle}
                                  </span>
                                )
                              })
                            }
                          </div>
                          <div className="col-12 mt-5 text-center">
                            <span className="base-currency">
                              1 <sub>{this.state.exchangesParams.data && this.state.exchangesParams.data["base"] ? (this.state.exchangesParams.data["base"]) : "--"}</sub>
                            </span>
                            <span className="asymptoticallyequal">
                              ≃
                            </span>
                            <span className="base-currency">
                              {this.state.exchangesParams.data && this.state.exchangesParams.data["lastCurrencyValue"] ? (this.state.exchangesParams.data["lastCurrencyValue"].toFixed(2)) : "--"} <sub>{this.state.exchangesParams.data && this.state.exchangesParams.data["convertedTo"] ? (this.state.exchangesParams.data["convertedTo"]) : "--"}</sub>
                            </span>
                            <span className={`prev-state-currency ${this.state.exchangesParams.data && this.state.exchangesParams.data["stateGap"] ? this.state.exchangesParams.data["stateGap"].cssClass : "states-equals"}`}>
                              <svg width="18" height="35" viewBox="0 0 18 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 35L17.6603 20H0.339746L9 35ZM7.5 0L7.5 21.5H10.5L10.5 0L7.5 0Z" />
                              </svg>
                              <span>
                                {
                                  this.state.exchangesParams.data && this.state.exchangesParams.data["stateGap"] && this.state.exchangesParams.data["stateGap"].secondToLastValue ?
                                    (this.state.exchangesParams.data["stateGap"].secondToLastValue.toFixed(2) + " (" + this.state.exchangesParams.data["stateGap"].gapPercentage + ")")
                                    :
                                    ""
                                }
                              </span>
                            </span>
                            <ul className="histo-mt-echeances">
                              {this.state.exchangesParams.convertedDataTwoDaysAgo !== undefined && this.state.exchangesParams.convertedDataTwoDaysAgo["rates"] && this.state.exchangesParams.convertedDataTwoDaysAgo["rates"].length > 0 &&
                                this.state.exchangesParams.convertedDataTwoDaysAgo["rates"].map((item, index) => (
                                  <li className={((this.state.exchangesParams.convertedDataTwoDaysAgo["rates"].length - index) % 2 == 0) ? "mt-echeance-day-2" : "mt-echeance-day-1"}>
                                    <span className="next-echaeance-label">
                                      {"J-" + (this.state.exchangesParams.convertedDataTwoDaysAgo["rates"].length - index)}
                                    </span>
                                    <span className="next-echaeance-value">
                                      {this.state.exchangesParams && this.state.exchangesParams["convertedData"] ? (this.state.exchangesParams["convertedData"]["query"].amount.toFixed(2)) : "--"} <sub>{this.state.exchangesParams && this.state.exchangesParams["convertedData"] ? (this.state.exchangesParams["convertedData"]["query"].from) : "--"}</sub>
                                    </span>
                                    <span className="next-echaeance-value">
                                      {this.state.exchangesParams && this.state.exchangesParams["convertedData"] ? ("/ " + item.value.toFixed(2)) : "--"} <sub>{this.state.exchangesParams && this.state.exchangesParams["convertedData"] ? (this.state.exchangesParams["convertedData"]["query"].to) : "--"}</sub>
                                    </span>
                                  </li>
                                ))
                              }

                              <li>
                                <span className="next-echaeance-label">
                                  Montant échéance au {Moment(Moment(new Date(), "DD/MM/YYYY").toDate()).format("DD/MM/YYYY")}
                                </span>
                                <span className="next-echaeance-value">
                                  {this.state.exchangesParams && this.state.exchangesParams["convertedData"] ? (this.state.exchangesParams["convertedData"]["query"].amount.toFixed(2)) : "--"} <sub>{this.state.exchangesParams && this.state.exchangesParams["convertedData"] ? (this.state.exchangesParams["convertedData"]["query"].from) : "--"}</sub>
                                </span>
                                <span className="next-echaeance-value">
                                  {this.state.exchangesParams && this.state.exchangesParams["convertedData"] ? ("/ " + this.state.exchangesParams["convertedData"]["result"].toFixed(2)) : "--"} <sub>{this.state.exchangesParams && this.state.exchangesParams["convertedData"] ? (this.state.exchangesParams["convertedData"]["query"].to) : "--"}</sub>
                                </span>
                              </li>
                              {/* <li>
                            <span className="next-echaeance-label">
                              Prochaine échéance dans [NB] jours, le [DATE PROCHAINE ECHEANCE]
                            </span>
                          </li> */}
                            </ul>
                          </div>
                          {this.state.exchangesParams.data["rates"] && this.state.exchangesParams.data["stateGap"] &&
                            <div className="col-12 mt-5">
                              <Chart
                                chartType="LineChart"
                                loader={<div>Chargement en cours...</div>}
                                width="100%"
                                height="100%"
                                data={
                                  [
                                    ['Date', this.state.exchangesParams.data["convertedTo"]],
                                    ...this.state.exchangesParams.data["rates"].sort((a, b) => new Date(a.date) - new Date(b.date)).map(item => [Moment(new Date(item.date)).format('DD/MM/YYYY'), item.value[this.state.exchangesParams.data["convertedTo"].toString()]])
                                  ]
                                }
                                options={{
                                  chart: {
                                    title: "Bourse",
                                    subtitle: "",
                                  },
                                  hAxis: {
                                    title: this.state.exchangesParams["range"].find(r => r.selected).libelle,
                                    textStyle: {
                                      fontSize: 12, // Adjust font size
                                      bold: true, // Apply bold style
                                      color: 'transparent', // Apply color
                                    },
                                  },
                                  vAxis: {
                                    title: this.state.exchangesParams.data["convertedTo"],
                                  },
                                  colors: [this.state.exchangesParams.data["stateGap"].colorGraph].flat(),
                                  legend: { position: (this.state.exchangesParams.data["rates"]) ? "right" : "none" },
                                  animation: {
                                    startup: true,
                                    easing: 'linear',
                                    duration: 500,
                                  }
                                }}
                              />
                            </div>}
                        </div>
                        <div className="row mt-3 echeancier-content">
                          <p className="title">Tableau d'échéances:</p>
                          <div className="w-100 text-center mt-3">
                            {this.getEcheancesListTable()}
                          </div>
                        </div>
                      </>
                    }
                    {
                      this.state.contract && this.state.contract.accessType && (this.viewAs()?.restrictedView || this.state.isRestrictedView) &&
                      <div className="restricted-content">
                        <div class="lock"></div>
                        <div class="message">
                          <h1>Accès restreint</h1>
                          <p>Vous n'avez pas accès au cours de bourses.</p>
                        </div>
                      </div>
                    }
                   
                    
                  </div>
                  
                </div>
                {this.state.showModal &&
                  <Modal
                    title={"Mise en signature"}
                    isOpen={this.state.showModal}
                    onRequestClose={() => this.handleClose()}
                    footer={
                      <>
                        <button onClick={() => this.setState({
                          ...this.state,
                          showBloc: 1,
                          showModal: false
                        })}>
                          Modifier
                        </button>
                        <button onClick={() => this.validate(true,'false')}>
                          Signer
                        </button>
                      </>
                    }
                    isFullScreen={false}
                  >
                    <div>
                      <p>
                        Les personnes suivantes sont actuellement désignées comme signataire du contrat :
                      </p>
                      <ul>
                        {
                          this.state.contract && this.state.contract.signatories && this.state.contract.signatories['signatories'].map(
                            (signatory, index) => {
                              return(
                                <li>
                                  <p className="font-weight-bold">
                                    {signatory.firstName + ' ' + signatory.lastName.toUpperCase() + '   -   ' + signatory.email}
                                  </p>
                                </li>
                              )
                            })
                        }
                      </ul>
                    </div>
                  </Modal>
                }
                {this.state.showModalResilationDoc &&
                  <Modal
                    title={"Demande de résiliation"}
                    isOpen={this.state.showModalResilationDoc}
                    onRequestClose={() => this.handleClose()}
                    footer={
                      <>
                        <button onClick={()=>{this.doDownloadResiliationDoc("word")}}>
                          .docx
                        </button>
                        <button onClick={()=>{this.doDownloadResiliationDoc("pdf")}}>
                          .pdf
                        </button>
                        <button onClick={()=>{this.doDownloadResiliationDoc("txt")}}>
                          .txt
                        </button>
                      </>
                    }
                    isFullScreen={false}
                  >
                    <div id="content-resiliation" className="doc-viewer-resiliation">
                      <div className="row">
                        <div className="col-4">
                            <p className="mb-1">{this.state.signatory2?.value}</p>
                            <p className="mb-1">{this.state.contract?.clientAddress?.address ? this.state.contract?.clientAddress?.address : ""}</p>
                            <p className="mb-1">{(this.state.contract?.clientAddress?.postalCode ? this.state.contract?.clientAddress?.postalCode:"") + " " + (this.state.contract?.clientAddress?.state ? this.state.contract?.clientAddress?.state:"")}	</p>
                        </div>
                        <div className="col-4"></div>
                        <div className="col-4">
                          <p className="mb-1">{this.state.signatory1?.value}</p>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-4">
                          <p className="mt-4 mb-1"> N° Contrat : {this.state.contractCode?.value} </p>
                        </div>
                        <div className="col-4"></div>
                        <div className="col-4">
                          <p className="mt-4 mb-1">{this.state.contract?.clientAddress?.state  ?( this.state.contract?.clientAddress?.state+ " ,"):""} {Moment(Moment(new Date(),"DD/MM/YYYY").toDate()).format("DD/MM/YYYY")}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8">
                          <p className="mt-4 pt-4 mb-1 font-weight-bold object-doc"> COURRIER RECOMMANDE AVEC ACCUSE DE RECEPTION</p>
                        </div>
                        <div className="col-4"></div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <p className="mt-4 pt-4">Madame, Monsieur,</p>
                        </div>
                        <div className="col-4"></div>
                        <div className="col-4"></div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p className="mt-4 text-justify">
                            Par la présente, je vous demande de bien vouloir mettre fin au contrat : {this.state.objet?.value} correspondant au numéro cité en référence, que nous avons souscrit le {this.state.beginingDate?.value}.
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p className="mt-2 text-justify">
                            La possibilité de résilier ce contrat m’est offerte, puisque nous avions souscrit ce contrat pour une durée de {this.state.contractDuration?.value ? this.state.contractDuration.value.hasOwnProperty("duration")? this.state.contractDuration.value.duration : this.state.contractDuration.value: ""} {this.state.contractDurationType?.toString() === "1" ? " mois " : (this.state.contractDurationType?.toString() === "2" ? " ans " :"")}, 
                            et que nous respectons le préavis de {this.getPriorNoticeValue()} {this.state.priorNoticeDurationType?.toString() === "1" ? " mois " : (this.state.priorNoticeDurationType?.toString() === "2" ? " ans " :"")}.
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p className="mt-2 text-justify">
                            J’ai bien noté, qu'en application des dispositions contractuelles qui nous lient, cette résiliation devrait prendre effet le {this.state.endingDate?.value}.
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p className="mt-2 text-justify">
                            Je vous prie de croire, Madame, Monsieur, en l’expression de nos salutations les meilleures
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <p className="mt-4">{this.state.signatory2?.value}</p>
                        </div>
                        <div className="col-4"></div>
                        <div className="col-4"></div>
                      </div>
                    </div>
                  </Modal>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ExchangesContract));
