import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { InfoIcon } from "../../../assets/icons";
const InfoTooltip = ({ content }) =>
  content ? (
    <OverlayTrigger overlay={<Tooltip>{content}</Tooltip>}>
      <span className="d-inline-block svg">
        <InfoIcon />
      </span>
    </OverlayTrigger>
  ) : null;

export default InfoTooltip;
