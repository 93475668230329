import { Record, List } from 'immutable';
import moment from 'moment';
import { getCurrentUserID } from '../../QueryAuthParameters';

class DashBord extends Record({
  id: 0,
  name: '',
  isPrivate: false,

  fromDate: null,
  toDate: null,

  type: 1,
  displayValues: true,
  displayLegend: false,
  unity: 'euro',

  filters: new List(),
  cryptedData: new List(),
  scale: null,

  cryptedBy: null,
  createdAt: null,
  updatedAt: null,

  isFavorite: false,
  hidden: false,
  editable: false,
}) {
  constructor(values) {
    const currentUserId = getCurrentUserID();
    console.log('currentUserId', currentUserId);
    let record = super(values);
    record = record
      .set('isFavorite', values?.isFavourite)
      .set('type', parseInt(values.type))
      .set('unity', values.unity ? values.unity : 'euro')
      .set('fromDate', moment(values?.fromDate, 'DD/MM/YYYY').format('YYYY-MM-DD'))
      .set('toDate', moment(values?.toDate, 'DD/MM/YYYY').format('YYYY-MM-DD'))
      .set('hidden', values?.isHidden)
      .set('editable', values?.cryptedBy?.id === currentUserId);
  
    return record;
  }
}

export default DashBord;
