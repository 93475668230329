import React, { Component } from 'react';
import { BrowserRouter as Router,  Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import './FoManageTags.scss';
import ErrorAlert from "../../../confirm-alert/error-alert";

class ContractTagsSetting extends Component {

    constructor (props) {
        super(props)
        this.state = {
            activeMenu: props.activeMenu,
            contractId: null,
        }
    }

    componentDidMount() {
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        if(parsed.contract) {
            this.setState({
                ...this,
                contractId: parsed.contract,
            })
        } else {
            ErrorAlert.show("Vous devez fournir l'identifiant du contrat", "Attention");
            setTimeout(() => {
                this.props.history.push("/");
            }, 3000);
        }
    }

    render() {
        return (
            <ul className="FoManageContractTags">
                <li className={`${this.state.activeMenu == 'index' ? 'active' : ''}`}>
                    <Link to={"/manage-contract/tags?contract="+this.state.contractId}>
                        <span>INDEX</span>
                    </Link>
                </li>
                <li className={`${this.state.activeMenu == 'organization' ? 'active' : ''}`}>
                    <Link to={"/manage-contract/organisations?contract="+this.state.contractId}>
                        <span>ORGANISATION</span>
                    </Link>
                </li>
            </ul>
        )
    }
}

//export default ContractTagsSetting;
export default withRouter(ContractTagsSetting)
