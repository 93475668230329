import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken} from "./QueryAuthParameters"

export const importAndOserizeDocument = (contract, documentFile) => {
    const requestUrl = API_URL+`/LinkedDocument/importAndOserizeDocument?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contract);
    formData.append('documentFile', documentFile);
    return axios({
        method: 'post',
        url: requestUrl,
        data: formData,
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
        timeout: Infinity,
    });
}