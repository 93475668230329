import React from "react";

const MenuIcon = ({ color }) => (
  <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_8924_32064)">
      <line x1="1" y1="2.5" x2="16" y2="2.5" stroke="black" />
    </g>
    <g filter="url(#filter1_d_8924_32064)">
      <line x1="1" y1="5.5" x2="16" y2="5.5" stroke="black" />
    </g>
    <g filter="url(#filter2_d_8924_32064)">
      <line x1="1" y1="8.5" x2="16" y2="8.5" stroke="black" />
    </g>
    <g filter="url(#filter3_d_8924_32064)">
      <line x1="1" y1="11.5" x2="16" y2="11.5" stroke="black" />
    </g>
    <defs>
      <filter id="filter0_d_8924_32064" x="0" y="0" width="17" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8924_32064" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8924_32064" result="shape" />
      </filter>
      <filter id="filter1_d_8924_32064" x="0" y="3" width="17" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8924_32064" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8924_32064" result="shape" />
      </filter>
      <filter id="filter2_d_8924_32064" x="0" y="6" width="17" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8924_32064" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8924_32064" result="shape" />
      </filter>
      <filter id="filter3_d_8924_32064" x="0" y="9" width="17" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-1" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8924_32064" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8924_32064" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default MenuIcon;
