import React from "react";

//css
import "./chart-controls.scss";

//icons
import chartColumn from "./icons/chart-column.svg";
import chartLine from "./icons/chart-line.svg";
import chartBar from "./icons/chart-bar.svg";
import chartDonut from "./icons/chart-donut​.svg";
import chartPie from "./icons/chart-pie.svg";
import euroIcon from "./icons/euro.svg";
import litigeIcon from "./icons/litige.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ChartControls = ({
  editable,
  type,
  onChangeType,
  displayValues,
  onToggleValues,
  displayLegend,
  onToggleLegend,
  displayDetailAmount,
  onToggleAmount,
  unity,
  onChangeUnity,
  isScaleDate
}) => {
  const chartTypes = [
    { icon: chartColumn, type: 1, isDisabled: false},
    { icon: chartLine, type: 2, isDisabled: false},
    { icon: chartBar, type: 3, isDisabled: false },
    { icon: chartDonut, type: 4, isDisabled: isScaleDate},
    { icon: chartPie, type: 5, isDisabled: isScaleDate},
  ];

  const unities = [
    { unity: "euro", label: "€" },
    { unity: "k-euro", label: "K€" },
    { unity: "m-euro", label: "M€" },
    { unity: "percent", label: "%" },
    { unity: "qty", label: "Qté" },
  ];

  return (
    <div className="dash-bord-chart-controls">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {chartTypes.map((item, index) => (
            <ButtonChart
              icon={item.icon}
              active={type === item.type}
              onPress={() => editable ? onChangeType(item.type) : null}
              isDisabled={item.isDisabled}
            />
          ))}
        </div>
        <div>
          <div className="d-flex">
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip className="dash-bord-tooltip">
                  {displayValues ? "Marquer" : "Afficher"} {"les valeurs"}
                </Tooltip>
              }
            >
              <button style={{height:"42px"}}
                className={`btn btn-light btn-toggle-values ${
                  !displayValues ? "hide-values" : ""
                }`}
                onClick={() => editable ? onToggleValues(!displayValues) : null}
              >
                <img src={euroIcon} alt="" />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip className="dash-bord-tooltip">
                  {displayLegend ? "Marquer" : "Afficher"} {"la légende"}
                </Tooltip>
              }
            >
              <button style={{height:"42px"}}
                className={`btn btn-light btn-toggle-values ${
                  !displayLegend ? "hide-values" : ""
                }`}
                onClick={() => editable ? onToggleLegend(!displayLegend) : null}
              >
                <i class="fas fa-list" style={{ fontSize: 25 }}></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip className="dash-bord-tooltip">
                  {displayDetailAmount ? "Marquer" : "Afficher"} {"le détails litige"}
                </Tooltip>
              }
            >
              <button style={{height:"42px"}}
                className={`btn btn-light btn-litige-ico btn-toggle-values ${
                  !displayDetailAmount ? "hide-values" : ""
                }`}
                onClick={() => editable ? onToggleAmount(!displayDetailAmount) : null}
              >
                <img src={litigeIcon} alt="" />
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <div>
          {unities.map((item, index) => (
            <ButtonUnity
              icon={item.label}
              active={unity === item.unity}
              onPress={() => editable ? onChangeUnity(item.unity) : null}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const ButtonChart = ({ icon, onPress, active, isDisabled }) => (
  <button
    className={`${isDisabled? "disabled-action":""} btn btn-light btn-chart-type ${active ? "active" : ""}`}
    onClick={onPress}
  >
    <img src={icon} alt="" />
  </button>
);

const ButtonUnity = ({ icon, onPress, active }) => (
  <button
    className={`btn btn-light btn-unity ${active ? "active" : ""}`}
    onClick={onPress}
  >
    {icon}
  </button>
);

export default ChartControls;
