import React from "react";
const CloseIcon = ({color = null}) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3920_24349)">
            <rect opacity="0.1" width="36" height="36" rx="18" fill={color || "#5367F4"} />
            <path d="M21.75 21.7507L13.5004 13.5M21.75 13.5008L13.5 21.7507L21.75 13.5008Z" stroke={color || "#7077A5"} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_3920_24349">
                <rect width="36" height="36" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default CloseIcon;
