import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../../components/Modal';
import { withTranslation } from 'react-i18next';


const DocumentViewModal = ({ isOpen, onRequestClose, document }) => {

  

  

  return (
    <Modal
      title={"Document non associé [Mode lecture]"}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      isFullScreen={true}
      footer={
        <>
          <button className='m-auto' onClick={() => onRequestClose(false)}>Fermer</button>
        </>
      }
    >
      <div className="pdf-document-content">
        <div className="page">
          <div
            id="pdf-contentV2"
            dangerouslySetInnerHTML={{
              __html:
                document?.pdfContent?.replace(/\\r\\n/g, ""),
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default withTranslation()(DocumentViewModal);
