import React, { Component } from 'react'; 
import './ForgotPassword.scss';
import { checkIfUserEmailExists, createThePAsswordResetLink } from "../../../api/user"
import LoaderComponent from '../../../components/loader/Loader' 
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import HeaderVisitor from '../../../components/layouts/header-visitor/HeaderVisitor';
import errorAlert from '../../../components/confirm-alert/error-alert';

class ForgotPassword extends Component { 

    constructor () {
        super();
        this.state = { 
            mailSent: false,
            emailError: false,
        } 
        this.form = {
            email: null
        }
    }

    onSubmit = (e)=>{ 
        e.preventDefault(); 
        LoaderComponent.show();
        checkIfUserEmailExists(this.form.email.value).then((response) => {
            if(response.data == '-1') {
                LoaderComponent.hide();
                this.setState({ 
                    ...this,
                    emailError: true,
                    mailSent: false, 
                })

            } else  {
                createThePAsswordResetLink(this.form.email.value).then((response) => {
                    LoaderComponent.hide();
                    this.setState({ 
                        ...this,
                        emailError: false,
                        mailSent: true,
                    })
                    document.getElementById("form-reset-password").reset();
                  },
                  (error) => {
                    LoaderComponent.hide();
                    errorAlert.show(error.response.data, "Erreur API");
                  }
                );
            }
          });
        
        // after email sent
        /*this.setState({  
            ...this.state,
            mailSent: true
        })*/
        
    }

    render() {
        return (
            <div className="wave-bg vh-100">
                <HeaderVisitor />
                <form method="post" action="#" className="forgot-password-form" onSubmit={this.onSubmit} id="form-reset-password">
                    <center>
                        <h3 className="black-text">{t("forgot_password.title")}</h3>
                    </center>
                    {
                        this.state.mailSent &&
                        <div className="mail-sent">
                            {t("forgot_password.email_sent")}
                        </div>
                    }
                    <div className={`form-group input-group ${this.state.emailError ? 'error' : ''}`}>
                        {/* <label>{t("forgot_password.email")}</label> */}
                        <input ref={(input) => { this.form.email = input }} className="form-control" type="email" name="email" placeholder={t("forgot_password.email")} required></input>
                        <span className="error">{t('authentication.errors.email_not_found')}</span>
                    </div>

                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6 col-sm-12">
                            <button className="btn btn-primary" type="submit">{t('forgot_password.validate')}</button>
                        </div>
                        <div className="col-md-3"></div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-center">
                            <a href="mailto:contact@docatweb.fr" className="btn btn-link black-text">{t("authentication.contact_us")}</a>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default withTranslation()(ForgotPassword);