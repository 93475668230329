import React from "react";

const ExportIcon = () => (
  <svg
    width="21"
    height="26"
    viewBox="0 0 21 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.19995 12.6L10.2 19.4L16.2 13M10.2 1V19.4V1Z"
      stroke="#5367F4"
      stroke-width="1.7"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 18.6001V25.0001H19.4V18.6001"
      stroke="#5367F4"
      stroke-width="1.7"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default ExportIcon;
