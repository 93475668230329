import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"

export const getContracts = () => {
    const requestUrl = API_URL+`/contract/getContractsByClientHomePage/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  }; 

export const associateContractToFavouritesList = (contractID) => {
    const requestUrl = API_URL+`/contract/associateContractToFavouritesList/`+contractID+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

export const dissociateContractToFavouritesList = (contractID) => {
    const requestUrl = API_URL+`/contract/dissociateContractToFavouritesList/`+contractID+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

export const getTimeLineData = () => {
    const requestUrl = API_URL+`/contract/getTimeLineData/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

export const associateDocumentToFavouritesList = (documentID) => {
    const requestUrl = API_URL+`/LinkedDocument/associateDocumentToFavouritesList/`+documentID+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

export const dissociateDocumentToFavouritesList = (documentID) => {
    const requestUrl = API_URL+`/LinkedDocument/dissociateDocumentToFavouritesList/`+documentID+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };


export const manageHomeTabsAndCompanies = (userId, tabList, client, defaultRightType) => {
  const requestUrl = API_URL + `/userProfile?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  formData.append('id', userId);
  formData.append('tabs', tabList);
  if (client && defaultRightType && client !== undefined && defaultRightType !== undefined && client !== null && defaultRightType !== null) {
    formData.append('client', client);
    formData.append('defaultRightType', defaultRightType);
  }
  return axios.post(requestUrl, formData);
};

export const manageHomeTabsColumns = (tabsColumns) => {
  const requestUrl = API_URL + `/homePage?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  formData.append('tabs', tabsColumns);
  formData.append('client', getCurrentClientID());
  return axios.post(requestUrl, formData);
};

export const getHomeTabsColumns = () => {
  const requestUrl = API_URL+`/homePage/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
  return axios.get(requestUrl);
};

export const getHomeTabsSearch = () => {
  const requestUrl = API_URL+`/clientKeyword/getSearchKeywordsHistoryByClient/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
  return axios.get(requestUrl);
};

export const manageHomeTabsSearch = (tabsSearch) => {
  const requestUrl = API_URL + `/user/saveNewSearch?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  if(tabsSearch && tabsSearch.id !== undefined && tabsSearch.id !== null && tabsSearch.id !== "")
    formData.append('id', tabsSearch.id);
  formData.append('client', getCurrentClientID());
  formData.append('name', tabsSearch.name);
  formData.append('fields', JSON.stringify({key: tabsSearch.tabKey, searchContent: tabsSearch.fields}));
  formData.append('andContains', tabsSearch.andContains ? 1:0);
  formData.append('orContains', tabsSearch.orContains ? 1:0);
  formData.append('doesntContain', tabsSearch.doesntContain ? 1:0);
  return axios.post(requestUrl, formData);
};


export const deleteHomeTabsSearch = (searchID) => {
  const requestUrl = API_URL+`/user/deleteSearch/`+searchID+`?access_token=`+getConnectedUserAccessToken();
  return axios.delete(requestUrl);
};
