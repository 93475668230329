import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { getContractOcerizationInfo, getContractComments, getCurrentUser, createComment, editComment, deleteComment, updateContractCommentsContent, exportContractComments } from "../../../../../api/comment"
import { downloadContractFromS3Server, getContractHTMLFile, handleContractOcerizationContentInTheBackGround } from "../../../../../api/validateContract"
import './Comments.scss'

import { t } from "i18next";
import { withTranslation } from "react-i18next";

// components
import Header from '../../../../../components/layouts/header/Header'
import Sidebar from '../../../../../components/layouts/sidebar/Sidebar'
import ConfirmAlert from '../../../../../components/confirm-alert/ConfirmAlert'
import ErrorAlert from "../../../../../components/confirm-alert/error-alert"
import LoaderComponent from '../../../../../components/loader/Loader'
import EditMode from '../../../../../components/edit-mode/EditMode';

// plugins
import Moment from 'moment';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Popover from 'react-text-selection-popover';
import uuid from 'react-uuid'
import highlightRange from 'dom-highlight-range';
import $ from 'jquery'
import Mark from "mark.js";
import ControlPanel from '../components/ControlPanel';
import ZoomContent from '../../../../../components/zoom-content/ZoomContent';
import FoManageContract from "../../../../../components/layouts/FO-Manage-Contract/FoManageContract";
import moment from 'moment';

// XLSX library
import * as XLSX from 'xlsx';
import Modal from '../../../../../components/Modal';
import ResumeDataModal from '../../../../../components/resumeDataModal/ResumeDataModal';

// form validation
const ReactFormWithConstraints =  require('react-form-with-constraints');
const { FormWithConstraints, FieldFeedbacks, FieldFeedback } = ReactFormWithConstraints;


class comments extends Component {

    constructor (props) {
        super(props);
        this.state = {
            editMode: false,
            showFormAddComment: false,
            contract: {},
            comments: [],
            markers:[],
            search: {
                totalOccurence: 0,
                currentOccurence: 0
            },
            savingDocument: false,
            selectedText: null,
            contractId: null,
            currentUser: null,
            showModal:false,
            isPopoverOpen: false,
            resolveBeforeUnload: null,
            allowClose: false,
            showResumeModal: false,
            actionData: "add", // add | edit | delete
            formComment: [
                {name: "title", txtLabel: t("frontoffice.contract_comments.title"), value: null, edited: false},
                {name: "content", txtLabel: t("frontoffice.contract_comments.comment"), value: null, edited: false}
            ] // will be object like {name, value, isRequired}
        }
        setTimeout(()=>{
            this.setState({
                ...this.state,
                ocerisedContract: this.state.ocerisedContract_
            })
        },2000)

        this.formAddComment = {}
        this.pdfContent = React.createRef();

        this.selectedText = null;

        /// After Load document
        setTimeout(()=>{
            this.extractMarkers();
            window.addEventListener('resize', this.extractMarkers);
        })

    }

    componentDidMount() {
        // window.addEventListener('beforeunload', this.handleBeforeUnload);
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        if(parsed.contract) {
            this.setState({
                ...this,
                contractId: parsed.contract,
            })
            getContractComments(parsed.contract).then((response) => {
                this.setState({
                    ...this,
                    comments: response.data,
                })
        },  (error) => {
             console.log(error)
          }  );

          getCurrentUser().then((response) => {
            this.setState({
                ...this,
                currentUser: response.data,
            })
          });

          getContractOcerizationInfo(parsed.contract).then((response) => {
              this.setState({
            ...this,
            contract: response.data,
            //editMode: response.data.ifUpdateRightIsPresent,
            savingDocument: (response.data.updatedHTMLCommentStatus === 0 ? false : true),
        }, () => {
            // get Contract HTML File
            getContractHTMLFile(this.state.contract.hachedId, false, true, false).then(
                htmlFile => {
                if (htmlFile["data"] !== undefined)
                    this.setState({
                    ...this.state,
                    contract: {
                        ...this.state.contract,
                        htmlValidationContent: htmlFile["data"]
                    }
                    })
                }
            )
            this.extractMarkers();
            if(this.state.contract.status === 2) {
            } else {
                ErrorAlert.show("Ce contrat n'est pas encore validé", "Attention");
                setTimeout(() => {
                    this.props.history.push("/home");
                }, 3000);
            }
            if(this.state.savingDocument === true) {
                let contract = response.data;
              const interval = setInterval(function() {
                  handleContractOcerizationContentInTheBackGround(contract.hachedId, 2).then((response) => {
                      if(response.data.updatedHTMLCommentStatus == 0) {
                          this.setState({
                              ...this,
                              savingDocument: false,
                          })
                          this.setState({
                              ...this,
                              contract:{
                                  ...contract,
                                  hmtlCommentsContent: response.data.newFileContent
                              },
                          }, () => {
                            this.extractMarkers();
                          })
                          clearInterval(interval);
                      }
                  });
                }.bind(this), 5000);
          }
        })},  (error) => {
            ErrorAlert.show(error.response.data, "Erreur API");
            setTimeout(() => {
                this.props.history.push("/home");
             }, 3000);
                        }  );
        } else {
            ErrorAlert.show("Vous devez fournir l'identifiant du contrat", "Attention");
            setTimeout(() => {
                this.props.history.push("/home");
             }, 3000);
        }

    }

    componentWillUnmount() {
        // window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (event) => {

        if (!this.state.allowClose) {
            event.preventDefault();
            event.returnValue = ''; // Some browsers require this for the confirmation dialog
            this.setState({ ...this.state, showResumeModal: true  });
            return ''; // Ensure the default dialog is shown
          }
    };

    changeEditMode = (checked) => {
        this.setState({
            ...this.state,
            editMode: checked
        })
    }

    showFormAddComment = ()=>{
        this.setState({
            ...this.state,
            showFormAddComment: true
        })
        this.formAddComment.title.value ="";
        this.formAddComment.highlightElementId.value ="";
        this.formAddComment.content.value =""
    }

    exportComments = ()=>{
        this.handleShow()
    }

    doExportComments = (fileExtension = '')=>{
        LoaderComponent.show()
        exportContractComments(this.state.contract.id).then((result) => {
            LoaderComponent.hide()
            if(fileExtension === 'csv'){
              const fileName = `Export-commentaires-${this.state.contract.name + '-'+ moment().format('DD-MM-YYYY')}.csv`;
              if (result) this.fileDownload(result.data, fileName);
            }
            if(fileExtension === 'xlsx'){
              const fileNameExcel = `Export-commentaires-${this.state.contract.name + '-'+ moment().format('DD-MM-YYYY')}.xlsx`;
              if (result) this.convertCsvToExcelBuffer(result.data, fileNameExcel)
            }
            this.handleClose()
        },
        error=>{
            LoaderComponent.hide()
            ErrorAlert.show(error.response.data, "Erreur API");
        });
      }

    fileDownload = (data, fileName) => {
        var universalBOM = '\uFEFF';
        var a = window.document.createElement('a');
        a.setAttribute(
          'href',
          'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + data)
        );
        a.setAttribute('download', fileName);
        window.document.body.appendChild(a);
        a.click();
      };

      convertCsvToExcelBuffer = (csvString, fileNameExcel) => {
        const arrayOfArrayCsv = csvString.split("\n").map((row) => {
          return row.split(";")
        });
        const wb = XLSX.utils.book_new();
        const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
        XLSX.utils.book_append_sheet(wb, newWs);
        const rawExcel = XLSX.write(wb, { type: 'base64' })
        console.log('rawExcel',rawExcel)
        XLSX.writeFile(wb, fileNameExcel);
        return rawExcel
      }
  
    handleShow = () => this.setState(
      {
        ...this.state,
        showModal: true
      }
    );
  
    handleClose = () => this.setState(
      {
        ...this.state,
        showModal: false,
        showResumeModal: false
      }
    );
    handleCancel = () => {
        let comments = this.state.comments;
        let index = comments.findIndex(x => x.edit)
        if (index > -1) {
            comments[index] = comments[index].originValue;
            comments[index].edit = false
            this.setState({
                ...this.state,
                comments: comments,
            })
        }
        this.setState(
            {
                ...this.state,
                showResumeModal: false,
                showFormAddComment: false,
                actionData: "add", // add | edit | delete
                formComment: [
                    { name: "title", txtLabel: t("frontoffice.contract_comments.title"), value: null, edited: false },
                    { name: "content", txtLabel: t("frontoffice.contract_comments.comment"), value: null, edited: false }
                ]
            }
        )
    }
  
    onDownloadCSVFile = () => {
      this.doExportComments('csv')
    }
  
    onDownloadXLSXFile = () => {
      this.doExportComments('xlsx')
    }

    showAllComments = ()=>{
        let comments = this.state.comments;
        comments.map(comment =>{  comment.show = true; comment.edit = false; return comment})
        this.setState({
            ...this.state,
            comments: comments
        })
    }
    hideAllComments = ()=>{
        let comments = this.state.comments;
        comments.map(comment =>{  comment.show = false; comment.edit = false; return comment})
        this.setState({
            ...this.state,
            comments: comments
        })
    }

    showComment = (index)=>{
        let comments = this.state.comments;
        comments[index].show = !comments[index].show
        comments[index].edit = false
        this.setState({
            ...this.state,
            comments: comments
        })
    }

    cancelAddedComment = ()=>{
        let title = this.formAddComment.title.value;
        let comment = this.formAddComment.content.value;
        if(this.state.showFormAddComment && (title || comment)){
            this.setState({
                ...this.state,
                showResumeModal: true
            })
            // ConfirmAlert.show(
            //     t("common.modals.entry_will_be_lost"),
            //     t("common.modals.attention"),
            //     t("common.modals.abort_entry"),
            //     t("common.modals.return_to_entry")
            // ).then(yes=>{
            //     console.log(yes)
            //     this.setState({
            //         ...this.state,
            //         showFormAddComment: false
            //     })
            // })
        }else{
            this.setState({
                ...this.state,
                showFormAddComment: false,
                actionData: "add", // add | edit | delete
                formComment: [
                    { name: "title", txtLabel: t("frontoffice.contract_comments.title"), value: null, edited: false },
                    { name: "content", txtLabel: t("frontoffice.contract_comments.comment"), value: null, edited: false }
                ]
            })
        }
    }

    saveAddedComment = ()=>{
        this.resetSearch();
        this.setState({
            ...this.state,
            savingDocument: true
        })
        setTimeout(()=>{
            this.setState({
                ...this.state,
                savingDocument: false
            })
        }, 3000)
        this.formAddCommentValidation.validateFields();
        setTimeout(()=>{
            if( !this.formAddCommentValidation.isValid() ) return;

            this.highlightCommentedText().then(marker=>{

                let comments = this.state.comments;
                let comment = {
                    title: this.formAddComment.title.value,
                    date: new Date(),
                    user: this.state.currentUser,
                    content: this.formAddComment.content.value,
                    highlightElementId: marker ? this.formAddComment.highlightElementId.value : null,
                    show: true
                }
                LoaderComponent.show();
                createComment(this.formAddComment, this.state.contract.hachedId, document.querySelector("#pdf-content").innerHTML).then((response) => {
                    getContractComments(this.state.contract.hachedId).then((response) => {
                        LoaderComponent.hide();
                        this.setState({
                        ...this,
                        comments: response.data,
                    }) });

                    this.setState({
                        ...this.state,
                        savingDocument: true
                    })
                    updateContractCommentsContent(this.state.contract.hachedId, document.querySelector("#pdf-content").innerHTML).then((response) => {
                        this.setState({
                            ...this.state,
                            savingDocument: false
                        })
                    });
                  }, (error) => {
                    ErrorAlert.show(error.response.data, "Erreur API");
                    getContractComments(this.state.contract.hachedId).then((response) => {
                        LoaderComponent.hide();
                        this.setState({
                        ...this,
                        comments: response.data,
                    }) });
                  });
                this.cancelAddedComment();
            })
        })
    }

    editComment = (index)=>{
        let comments = this.state.comments;
        comments[index].originValue =  {...comments[index]};
        comments[index].edit = !comments[index].edit
        comments[index].show = true

        let formComment = [
            {name: "title", txtLabel: t("frontoffice.contract_comments.title"), value: comments[index].title, originValue: comments[index].title, edited: false},
            {name: "content", txtLabel: t("frontoffice.contract_comments.comment"), value: comments[index].content, originValue: comments[index].content, edited: false}
        ]

        this.setState({
            ...this.state,
            comments: comments,
            formComment: formComment,
            actionData: "edit"
        })
    }

    ifUserIsTheCommentCreator(index) {
        let comment = this.state.comments[index];
        if(comment.user.id) {
            if(comment.user.id === this.state.currentUser?.id) {
                return true;
            }
        }
        return false;
    }

    handleEditFormChangeInput = (e, index)=>{

        let comments = this.state.comments;

        comments[index][e.target.name] = e.target.value;
        this.setState({
            ...this.state,
            comments: comments
        })
        this.setFormComment(e, "edit")
    }

    saveEditedComment = (index)=>{

        let comments = this.state.comments;

        let formEditValidation = comments[index].formEditValidation;
        formEditValidation.validateFields();

        setTimeout(()=>{
            if( !formEditValidation.isValid() ) return;

            LoaderComponent.show();
            editComment(comments[index]).then((response) => {
                console.log('response', response)
                getContractComments(this.state.contract.hachedId).then((response) => {
                    LoaderComponent.hide();
                    this.setState({
                    ...this,
                    comments: response.data,
                    showResumeModal: false,
                    showFormAddComment: false,
                    actionData: "add", // add | edit | delete
                    formComment: [
                        { name: "title", txtLabel: t("frontoffice.contract_comments.title"), value: null, edited: false },
                        { name: "content", txtLabel: t("frontoffice.contract_comments.comment"), value: null, edited: false }
                    ]
                }) });
              }, (error) => {
                ErrorAlert.show(error.response.data, "Erreur API");
                getContractComments(this.state.contract.hachedId).then((response) => {
                    LoaderComponent.hide();
                    this.setState({
                    ...this,
                    comments: response.data,
                    showResumeModal: false,
                    showFormAddComment: false,
                    actionData: "add", // add | edit | delete
                    formComment: [
                        { name: "title", txtLabel: t("frontoffice.contract_comments.title"), value: null, edited: false },
                        { name: "content", txtLabel: t("frontoffice.contract_comments.comment"), value: null, edited: false }
                    ]
                }) });
              });

            /*comments[index].edit = false;
            this.setState({
                ...this.state,
                comments: comments
            })*/


        })

    }

    cancelEdit = (index)=>{
        let formComment = this.state.formComment;
        if(formComment.some(x=> x.value !== x.originValue)){
            this.setState({
                ...this.state,
                showResumeModal: true
            })
        }else{
            let comments = this.state.comments;
            comments[index] = comments[index].originValue;
            comments[index].edit = false
            this.setState({
                ...this.state,
                comments: comments,
                actionData: "add", // add | edit | delete
                formComment: [
                    { name: "title", txtLabel: t("frontoffice.contract_comments.title"), value: null, edited: false },
                    { name: "content", txtLabel: t("frontoffice.contract_comments.comment"), value: null, edited: false }
                ]
            })
        }
    }

    deleteComment = (index)=>{
        ConfirmAlert.show(t("frontoffice.contract_comments.confirmation_action_delete")).then(()=>{
            let comments = this.state.comments;


            //remove marker
            let comment = comments[index];
            console.log(this.state.markers)
            this.state.markers.find((marker, k)=>{
                console.log(marker, k)
                if( marker && marker.id == comment.highlightElementId ){
                    this.state.markers.splice(k, 1) ;
                    $('#pdf-content [dataId='+comment.highlightElementId+']').removeAttr('class').removeAttr('dataId')
                }
            })
            // remove comment
            /*comments.splice(index, 1) ;
            this.setState({
                ...this.state,
                comments: comments,
            })  */
            LoaderComponent.show();
            deleteComment(this.state.comments[index].id).then((response) => {
                getContractComments(this.state.contract.hachedId).then((response) => {
                    LoaderComponent.hide();
                    this.setState({
                    ...this,
                    comments: response.data,
                }) });

                this.setState({
                    ...this.state,
                    savingDocument: true
                })
                updateContractCommentsContent(this.state.contract.hachedId, document.querySelector("#pdf-content").innerHTML).then((response) => {
                    this.setState({
                        ...this.state,
                        savingDocument: false
                    })
                });
              }, (error) => {
                LoaderComponent.hide();
                ErrorAlert.show(error.response.data, "Erreur API");
              });
        });
    }
    onDragEnd = (result)=>{
        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };
        const newReorde = reorder(
            this.state.comments,
            result.source.index,
            result.destination.index
        );

        this.setState({
            ...this.state,
            comments:newReorde
        });
    }


    displayContractHTMLContent() {
        const htmlPages = [];
        if(this.state.contract.hmtlCommentsContent) {
            //return this.state.contract.htmlValidationContent
            return <div dangerouslySetInnerHTML={{__html: this.state.contract.hmtlCommentsContent}} />
        }
        if(this.state.contract.fileContent) {
            for (let i = 0; i < this.state.contract.fileContent.length; i++) {
                htmlPages.push(
                    <div className="page">
                     <div dangerouslySetInnerHTML={{__html: this.state.contract.fileContent[i]}} />
                    </div>
                );
            }
            return htmlPages;
        }
    }

    ///// Viewer
    downloadContract = ()=>{
    }
    printContract = ()=>{
        downloadContractFromS3Server(this.state.contract?.id);
    }

   searchInContract = (e)=>{
       this.panPinchRef.resetZoom()
       let input =  e.target.value;
       if( input ){
           this.markInstance = new Mark(document.querySelector("#pdf-content"));
           this.markInstance.unmark({
               done: (e) => {
                   this.markInstance.mark(input, {
                       separateWordSearch: false,
                       acrossElements: true,
                       done: (countResults)=>{
                           this.setState({
                               ...this.state,
                               search: {
                                    currentOccurence: countResults > 0 ? 1 : 0,
                                    totalOccurence: countResults
                               }
                           })
                           this.setActiveSearchOccurent(0);
                       }
                   })
               }
           });
       }else{
           if( this.markInstance ){
              this.markInstance.unmark()
           }
           this.setState({
                ...this.state,
                search: {
                    currentOccurence: 0,
                    totalOccurence: 0
                }
            })

       }
   }
   resetSearch = ()=>{
        if( this.markInstance ) {
            this.markInstance.unmark();
            this.searchInput.value = "";
            this.setState({
                ...this.state,
                search: {
                    currentOccurence: 0,
                    totalOccurence: 0
                }
            })
        }
        return new Promise((resolve, reject)=>{
            setTimeout(()=>{
                resolve();
            })
        })
   }
   setActiveSearchOccurent = (index)=>{
        $('#pdf-content mark[data-markjs]').removeClass('active');
        let current = $($('#pdf-content mark[data-markjs]')[index]);
        current.addClass('active');
        let fieldPosition = index == 0 ? 0 : current?.parents('div.pf')?.position()?.top + current?.parents('div.t')?.position()?.top || 0
        $('.pdf-viewer').animate({
            scrollTop: fieldPosition
        }, 300);
   }
   prevFindedSearch = ()=>{
       let currentOccurence = this.state.search.currentOccurence;
       if(currentOccurence > 1){
           this.setState({
               ...this.state,
               search:{
                   ...this.state.search,
                   currentOccurence: currentOccurence - 1
               }
           }, ()=>{
               this.setActiveSearchOccurent(currentOccurence - 2);
           })
       }
   }
   nextFindedSearch = ()=>{
       let currentOccurence = this.state.search.currentOccurence;
       let totalOccurence = this.state.search.totalOccurence;
       if(currentOccurence < totalOccurence){
           this.setState({
               ...this.state,
               search:{
                   ...this.state.search,
                   currentOccurence: currentOccurence + 1
               }
           }, ()=>{
               this.setActiveSearchOccurent(currentOccurence);
           })
       }
   }

   extractMarkers = ()=>{
       $('.pdf-viewer').animate({
            scrollTop: 0
        },0);
        let markers = [];
        $('.pdf-content').find('.highlight-text.commented-text').each((index, elm) =>{
           let markerId = $(elm).attr('dataid');
           let existsMarker = markers.find((m)=>{ return m.id == markerId })
           if( markerId && !existsMarker ){
            let marker = {
                id: markerId,
                top: $(elm).parents('.pf').position().top + ($(elm).parents('div.t').position().top + $(elm).position().top) + 12
            }
            markers.push(marker)
            this.setState({
                ...this.state,
                markers: markers
            })
           }
        })
   }

   scrollToSpecificComment = (marker)=>{
       if( $('[highlightElementId='+marker.id+']')[0] ){
            let field = $('[highlightElementId='+marker.id+']').parent();
            let fieldParent = $('.scrolling-verticaly');
            let fieldPosition = (field.position().top - fieldParent.position().top) + 20  ;

            $('[highlightElementId='+marker.id+']').addClass('show');
            fieldParent.animate({
                scrollTop: fieldPosition
            }, 400, ()=>{
                $('[highlightElementId='+marker.id+']').addClass('focus').addClass('show');
                setTimeout(()=>{
                    $('[highlightElementId='+marker.id+']').removeClass('focus');
                },1250)
            });
       }
    }

    locateTextInDocument = (highlightElementId) =>{
        this.panPinchRef.resetZoom()
        this.state.markers.find(marker => {
            if(marker.id == highlightElementId){
                let field = $('span[dataid='+highlightElementId+']');
                // let fieldParent = $('#form-validate-contract');
                let fieldPosition = $(field[0]).parents('.pf').position().top + $(field[0]).parents('div.t').position().top + $(field[0]).position().top;
                $('.pdf-viewer').animate({
                    scrollTop: fieldPosition
                }, 400, ()=>{
                    field.addClass('focus');
                    $('.vertical-line button[dataId='+marker.id+']').addClass('focus')
                    setTimeout(()=>{
                        field.removeClass('focus');
                        $('.vertical-line button[dataId='+marker.id+']').removeClass('focus')
                    },1400)
                });
            }
        })
    }

    insertMarker = (marker)=>{
        let markers = this.state.markers;
        markers.push(marker);
        this.setState({
            ...this.state,
            markers: markers
        })
    }

    highlightText = ()=>{
        this.resetSearch()
        this.resetSearch();
        this.setState({
            ...this.state,
            savingDocument: true
        })
        setTimeout(()=>{
            this.setState({
                ...this.state,
                savingDocument: false
            })
        }, 3000)
        const selection = window.getSelection();
        if (!selection.isCollapsed) {
                const range = selection.getRangeAt(0);
                highlightRange(range, 'span', {
                    class: 'highlight-text'
                });
                this.hidePopover()

                this.setState({
                    ...this.state,
                    savingDocument: true
                })
                updateContractCommentsContent(this.state.contract.hachedId, document.querySelector("#pdf-content").innerHTML).then((response) => {
                    this.setState({
                        ...this.state,
                        savingDocument: false
                    })
                });
        }
    }

    highlightCommentedText = ()=>{
        return new Promise((resolve, reject)=>{
            console.log(this.selectedText)
            if(this.selectedText){

                let marker = {
                    id: uuid(),
                }

                const range = this.selectedText;
                highlightRange(range, 'span', {
                    class: 'highlight-text commented-text',
                    dataId: marker.id
                });
                this.hidePopover()

                this.formAddComment.highlightElementId.value = marker.id;

                setTimeout(()=>{
                    let field = $('[dataId='+marker.id+']');
                    let textPosition = field.parents('.pf').position().top + (field.parents('div.t').position().top + field.position().top)
                    marker.top = textPosition;
                    this.insertMarker(marker);

                    this.setState({
                        ...this.state,
                        selectedText: null
                    }, ()=>{
                        resolve(marker)
                    })
                })

            }else{
                resolve(null);
            }
        })
    }

    addNewComment = ()=>{
        this.resetSearch()
        this.showFormAddComment();
        setTimeout(()=>{
            this.selectedText = window.getSelection().getRangeAt(0)
            let selectedText = window.getSelection().toString().trim();
            this.formAddComment.content.value = selectedText;
        })
    }

    onTextSelect = ()=>{
        this.setState({
            ...this.state,
            isPopoverOpen: true
        })
    }
    onTextUnselect = ()=>{
        this.setState({
            ...this.state,
            isPopoverOpen: false
        })
    }

    hidePopover = ()=>{
        document.getSelection().empty()
    }

    handleScrollCtrl = (e) => {
        // Check if the Ctrl or Command key is pressed
        if (e.ctrlKey || e.metaKey) {
            // // Handle the (Ctrl or Command) + Scroll event
            // if(e.ctrlKey)
            //   console.log('Ctrl + Scroll Detected');
            // if(e.metaKey)
            //   console.log('Command + Scroll Detected');
            // Determine the scroll direction (up or down)
            const delta = e.deltaY > 0 ? -0.1 : 0.1;
            // Adjust the zoom : in and out
            if (delta > 0) {
                this.panPinchRef.zoomOut();
            } else {
                this.panPinchRef.zoomIn();
            }
        }
    };

    setFormComment = (e, action) =>{
        let name = e.target.name;
        let value = e.target.value;
        let formComment = this.state.formComment
        let findIndex = formComment.findIndex(x=>x.name === name);
        if(findIndex>-1){
            formComment[findIndex].value = value
            if(action === "edit"){
                formComment[findIndex].edited = true
            }else{
                formComment[findIndex].edited = false
            }
        }else{
            formComment.push({
                name: name,
                value: value,
                isRequired: true
            })
        }
        this.setState({
            ...this.state,
            actionData: action,
            formComment:formComment
        })
    }

    doSave = () =>{
        if(this.state.actionData === "add"){
            this.saveAddedComment()
            this.handleCancel()
        }
        if(this.state.actionData === "edit"){
            let comments = this.state.comments;
            let index = comments.findIndex(x => x.edit)
            if (index > -1) {
                this.saveEditedComment(index)
            }
        }
    }

    render() {

        const getItemStyle = (isDragging, draggableStyle) => ({
            // background: isDragging ? '#F66031' : 'white',
            // color: '#fff'
        });

        const getListStyle = isDraggingOver => ({ });

        return (
            <div className="comments-page-style" onWheel={this.handleScrollCtrl}>
                <div className="app-container manage-contract comments-contract  app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
                    <Header />

                    <div className="app-main">
                        <div className="app-sidebar sidebar-shadow">
                            <Sidebar />
                        </div>
                        <div className="app-main__outer">
                            <EditMode
                                isEditMode={this.state.editMode}
                                onChange={(checked)=>{
                                    this.changeEditMode(checked)
                                }}
                                disabled={!this.state.contract?.ifUpdateRightIsPresent}
                                isContractPage={true}
                            />
                            <div className="app-main__inner">
                                <div className="row">
                                    <div className={`col-md-5 left-side ${this.state.showFormAddComment === true ? 'form-add-comment' : ''}`}>
                                            <div className="sub-menu">
                                                {this.state.contract?.status !== null && (
                                                    <FoManageContract
                                                        activeMenu="comments"
                                                        contractStatus={this.state.contract?.status}
                                                        inNegociationBy={this.state.contract?.inNegociationBy}
                                                    />
                                                )}
                                            </div>

                                        <div className="head d-flex w-100">
                                            <button disabled={!this.state.editMode} className="btn btn-light btn-add" onClick={()=>{ this.showFormAddComment() }}>
                                                {t("common.add")}
                                            </button>
                                            <button disabled={!this.state.editMode || (this.state.editMode && this.state.comments && this.state.comments.length === 0)} className="btn btn-light btn-add" onClick={()=>{ this.exportComments() }}>
                                                {t("common.export")}
                                            </button>
                                        </div>
                                        {
                                            this.state.comments.length > 0 &&
                                            <ul>
                                                <li><button onClick={()=>{this.showAllComments()}} className="btn btn-default">{t("common.expand_all")}</button></li>
                                                <li><button onClick={()=>{this.hideAllComments()}} className="btn btn-default">{t("common.retract_all")}</button></li>
                                            </ul>
                                        }

                                        {this.state.showModal &&
                                            <Modal
                                                title={t("common.export")+"..."}
                                                isOpen={this.state.showModal}
                                                onRequestClose={() => this.handleClose()}
                                                footer={
                                                    <>
                                                        <button onClick={() => this.onDownloadCSVFile()}>
                                                            CSV
                                                        </button>
                                                        <button onClick={() => this.onDownloadXLSXFile()}>
                                                            XLSX
                                                        </button>
                                                    </>
                                                }
                                                isFullScreen={false}
                                            >
                                                <div>
                                                    <p className="text-center font-weight-bold">
                                                        {t("common.select_form")}
                                                    </p>
                                                </div>
                                            </Modal>
                                        }

                                        <div className="comments">
                                            {/* //////////////////////// Form Add comment ////////////////////////////// */}
                                            <FormWithConstraints
                                            ref={formWithConstraints => this.formAddCommentValidation = formWithConstraints}
                                            className={`comment add-comment ${this.state.showFormAddComment === true ? '' : 'hidden'}`}
                                            noValidate>
                                                <input type="hidden"  ref={(input)=>{this.formAddComment.highlightElementId = input}} />
                                                <h3>{t("frontoffice.contract_comments.new_comment")}</h3>
                                                <div className="comment-head d-flex">
                                                    <div className="comment-title">
                                                        <input ref={(title)=>{this.formAddComment.title = title}} type="text" name="title" className="form-control" placeholder={t("frontoffice.contract_comments.title")} onChange={(e)=>this.setFormComment(e, "add")} required></input>
                                                        <FieldFeedbacks for="title">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>
                                                        <p>{ Moment(new Date()).format('DD/MM/YYYY') }</p>
                                                        {/* {console.log('this.state.currentUser', this.state.currentUser)} */}
                                                    </div>
                                                </div>
                                                <div className="comment-body">
                                                    <div className="user d-flex">
                                                        <img src={this.state.currentUser?.image} />
                                                        <h4>{this.state.currentUser?.name}</h4>
                                                    </div>
                                                    <p>
                                                        <textarea ref={(content)=>{this.formAddComment.content = content}} placeholder={t("frontoffice.contract_comments.your_comment")} name="content" className="form-control" rows="4" onChange={(e)=>this.setFormComment(e, "add")} required></textarea>
                                                        <FieldFeedbacks for="content">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>
                                                    </p>
                                                </div>
                                                <div className="submit-actions">
                                                    <button title={t('common.cancel')}  type="button" className="btn btn-default btn-cancel"  onClick={()=>{this.cancelAddedComment()}}>
                                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.75 9.75075L1.50037 1.5M9.75 1.50075L1.5 9.75075L9.75 1.50075Z" stroke="#7077A5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </button>
                                                    <button title={t('common.save')} type="button" className="btn btn-default btn-save" onClick={()=>{this.saveAddedComment()}}>
                                                        <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 5.375L5.125 9.5L13 1.25" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </FormWithConstraints>
                                        </div>

                                        <div className="scrolling-verticaly">
                                            <div className="comments">
                                            <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Droppable droppableId="droppable">

                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >

                                                    {/* //////////////////////// Comments list ////////////////////////////// */}
                                                    { this.state.comments.map((comment, index) =>{
                                                            return(
                                                                <Draggable key={`key-${index}`} draggableId={`index-${index}`} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div key={`key-${index}`}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}

                                                                        >
                                                                            <div
                                                                                className={`comment ${comment?.show ? 'show' : ''}`}
                                                                                style={getItemStyle(
                                                                                    snapshot.isDragging,
                                                                                    provided.draggableProps.style
                                                                                )}
                                                                                highlightElementId={comment.highlightElementId}
                                                                            >
                                                                                <div className="comment-head d-flex">
                                                                                    <div className="comment-title" onClick={()=>{this.showComment(index)}}>
                                                                                        <h3>{comment.title}</h3>
                                                                                        <p>{ Moment(comment.date).format('DD/MM/YYYY') }</p>
                                                                                    </div>
                                                                                    {
                                                                                        comment.highlightElementId &&
                                                                                        <button onClick={()=>{this.locateTextInDocument(comment.highlightElementId)}} className="btn btn-default">
                                                                                            <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M12 1.4375C5.55 1.4375 0.9375 8 0.9375 8C0.9375 8 5.55 14.5625 12 14.5625C18.45 14.5625 23.0625 8 23.0625 8C23.0625 8 18.45 1.4375 12 1.4375Z" stroke="#7077A5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                                <path d="M12 12.3125C14.4853 12.3125 16.5 10.2978 16.5 7.8125C16.5 5.32722 14.4853 3.3125 12 3.3125C9.51472 3.3125 7.5 5.32722 7.5 7.8125C7.5 10.2978 9.51472 12.3125 12 12.3125Z" stroke="#7077A5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            </svg>
                                                                                        </button>
                                                                                    }
                                                                                </div>
                                                                                <div className="comment-body">
                                                                                    <div className="user d-flex">
                                                                                        {comment.user.image !== undefined && <img src={comment.user?.image}/>}
                                                                                        {comment.user.image == undefined &&
                                                                                        <span className="avatar_archived">
                                                                                            <svg width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M87.954 75.4876L108.118 84.653C113.324 87.0194 116.667 92.2104 116.667 97.9291V102.083C116.667 110.138 110.137 116.667 102.083 116.667H37.9166C29.8625 116.667 23.3333 110.138 23.3333 102.083V97.9291C23.3333 92.2104 26.6759 87.0194 31.882 84.653L52.0459 75.4876C45.2205 70.1491 40.8333 61.837 40.8333 52.5002C40.8333 36.3919 53.8917 23.3335 70 23.3335C86.1083 23.3335 99.1666 36.3919 99.1666 52.5002C99.1666 61.837 94.7794 70.1491 87.954 75.4876ZM58.1074 79.14L34.2959 89.9634C31.1722 91.3833 29.1666 94.4979 29.1666 97.9291V102.083C29.1666 106.916 33.0842 110.833 37.9166 110.833H102.083C106.916 110.833 110.833 106.916 110.833 102.083V97.9291C110.833 94.4979 108.828 91.3833 105.704 89.9634L81.8926 79.14C78.2606 80.7639 74.2357 81.6668 70 81.6668C65.7642 81.6668 61.7394 80.7639 58.1074 79.14ZM70 75.8335C82.8866 75.8335 93.3333 65.3868 93.3333 52.5002C93.3333 39.6135 82.8866 29.1668 70 29.1668C57.1133 29.1668 46.6666 39.6135 46.6666 52.5002C46.6666 65.3868 57.1133 75.8335 70 75.8335Z" fill="black" />
                                                                                            </svg>
                                                                                        </span>}
                                                                                        <h4>{comment.user.name}</h4>
                                                                                    </div>
                                                                                    <p>
                                                                                        {comment.content}
                                                                                    </p>
                                                                                </div>

                                                                                {
                                                                                    this.state.editMode && this.ifUserIsTheCommentCreator(index) === true &&
                                                                                    <button type="button" className="btn btn-default btn-edit" onClick={()=>{this.editComment(index)}}>
                                                                                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M18.0156 6.98339L18.3012 6.69771C19.4054 5.59321 19.121 4.08827 18.0156 2.98377C16.9101 1.87928 15.4046 1.59328 14.3013 2.69839L14.0156 2.98407" stroke="#F66031" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            <path d="M16.2662 8.73364L18.0168 6.98339L14.0166 2.98376L12.2666 4.73371" stroke="#F66031" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            <path d="M2.23073 14.7695L6.23005 18.7691M6.23005 18.7691L16.2661 8.73363L12.2665 4.7337L2.23073 14.7695L0.999023 20.0014L6.23005 18.7691Z" stroke="#F66031" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                        </svg>
                                                                                    </button>
                                                                                }
                                                                            </div>

                                                                            {/* //////////////////////// form edit comment ////////////////////////////// */}
                                                                            {
                                                                                this.state.editMode && comment?.edit &&

                                                                                <FormWithConstraints
                                                                                    ref={form => comment.formEditValidation = form}
                                                                                    className='comment edit-comment'
                                                                                    noValidate>

                                                                                    <div className="comment-head d-flex">
                                                                                        <div className="comment-title">
                                                                                            <input type="text" name="title" className="form-control" value={comment.title}  onChange={(e)=>{this.handleEditFormChangeInput(e, index)}} placeholder={t("frontoffice.contract_comments.title")} required></input>
                                                                                            <FieldFeedbacks for="title">
                                                                                                <FieldFeedback when="*" />
                                                                                            </FieldFeedbacks>
                                                                                            <p>{ Moment(comment.date).format('DD/MM/YYYY') }</p>
                                                                                        </div>
                                                                                        {
                                                                                            comment.highlightElementId &&
                                                                                            <button type="button" onClick={()=>{this.locateTextInDocument(comment.highlightElementId)}} className="btn btn-default">
                                                                                                <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M12 1.4375C5.55 1.4375 0.9375 8 0.9375 8C0.9375 8 5.55 14.5625 12 14.5625C18.45 14.5625 23.0625 8 23.0625 8C23.0625 8 18.45 1.4375 12 1.4375Z" stroke="#7077A5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                                    <path d="M12 12.3125C14.4853 12.3125 16.5 10.2978 16.5 7.8125C16.5 5.32722 14.4853 3.3125 12 3.3125C9.51472 3.3125 7.5 5.32722 7.5 7.8125C7.5 10.2978 9.51472 12.3125 12 12.3125Z" stroke="#7077A5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                                </svg>
                                                                                            </button>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="comment-body">
                                                                                        <div className="user d-flex">
                                                                                            <img src={this.state.currentUser?.image} />
                                                                                            <h4>{comment.user.name}</h4>
                                                                                        </div>
                                                                                        <p>
                                                                                            <textarea name="content" className="form-control" rows="4" value={comment.content} onChange={(e)=>{this.handleEditFormChangeInput(e, index)}} placeholder={t("frontoffice.contract_comments.your_comment")} required></textarea>
                                                                                            <FieldFeedbacks for="content">
                                                                                                <FieldFeedback when="*" />
                                                                                            </FieldFeedbacks>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="submit-actions">
                                                                                        <button title={t('common.save')} type="button" className="btn btn-default btn-save" onClick={()=>{this.saveEditedComment(index)}}>
                                                                                            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M1 5.375L5.125 9.5L13 1.25" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            </svg>
                                                                                        </button>
                                                                                        <button title={t('common.cancel')} type="button" className="btn btn-default btn-cancel"  onClick={()=>{this.cancelEdit(index)}}>
                                                                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M9.75 9.75075L1.50037 1.5M9.75 1.50075L1.5 9.75075L9.75 1.50075Z" stroke="#7077A5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            </svg>
                                                                                        </button>
                                                                                        <button title={t('common.delete')} type="button" className="btn btn-default btn-delete"  onClick={()=>{this.deleteComment(index)}}>
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </button>

                                                                                    </div>
                                                                                </FormWithConstraints>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        }) }

                                                    {provided.placeholder}
                                                </div>
                                            )}


                                            </Droppable>
                                        </DragDropContext>
                                        </div>
                                        </div>

                                    </div>

                                    <div className="col-md-7 viewer">

                                        <div className="savingDocument text-right">
                                            {
                                                this.state.savingDocument === true &&
                                                <div>
                                                    {t("common.saving_doc")} { LoaderComponent.getLoader() }
                                                </div>
                                            }
                                        </div>
                                        <h1>{this.state.contract.name ? this.state.contract.name : null}</h1>
                                        <ControlPanel
                                            fileName={this.state.contract.originalFileName}
                                            onPressDownload={this.downloadContract}
                                            onPressPrint={this.printContract}

                                            onPressZoomOut={()=>{this.panPinchRef.zoomOut()}}
                                            onPressZoomIn={()=>{this.panPinchRef.zoomIn()}}

                                            onChangeSearchInput={(e)=>{this.searchInContract(e)}}
                                            refInput={(input)=>{this.searchInput = input}}
                                            onCleanSearch={this.resetSearch}

                                            onPressPrevResult={this.prevFindedSearch}
                                            currentResult={this.state.search.currentOccurence}
                                            totalResults={this.state.search.totalOccurence}
                                            onPressNextResult={this.nextFindedSearch}
                                        />


                                        <ZoomContent ref={(ref) => { this.panPinchRef = ref }}>

                                            <div className="pdf-viewer d-flex">
                                                <div className="vertical-line">
                                                    {this.state.markers.map((marker, index) => {
                                                        return (
                                                            <button key={index} style={{ top: marker.top }} onClick={() => { this.scrollToSpecificComment(marker) }} dataId={marker.id} className="btn btn-default">
                                                                <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12 1.4375C5.55 1.4375 0.9375 8 0.9375 8C0.9375 8 5.55 14.5625 12 14.5625C18.45 14.5625 23.0625 8 23.0625 8C23.0625 8 18.45 1.4375 12 1.4375Z" stroke="#7077A5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M12 12.3125C14.4853 12.3125 16.5 10.2978 16.5 7.8125C16.5 5.32722 14.4853 3.3125 12 3.3125C9.51472 3.3125 7.5 5.32722 7.5 7.8125C7.5 10.2978 9.51472 12.3125 12 12.3125Z" stroke="#7077A5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </button>
                                                        )
                                                    })}
                                                </div>
                                                <div className="pdf-content" id="pdf-content" ref={this.pdfContent}>
                                                    {
                                                        !this.state.contract?.hmtlCommentsContent &&
                                                        <center className="loading-contract">
                                                            <p>{t("common.preparing_doc")} </p>
                                                            {LoaderComponent.getLoader()}
                                                        </center>
                                                    }

                                                    <div
                                                        className="page"
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.state.contract?.hmtlCommentsContent,
                                                        }}
                                                    />

                                                    {
                                                        this.state.editMode &&
                                                        <Popover
                                                            className="comments-popover-container"
                                                            isOpen={this.state.isPopoverOpen}
                                                            onTextSelect={() => this.onTextSelect()}
                                                            onTextUnselect={() => this.onTextUnselect()}
                                                            selectionRef={this.pdfContent}>
                                                            <ul className="popover-options">
                                                                <li>
                                                                    <button className="btn btn-default btn-marker" title="Surligner" onClick={() => { this.highlightText() }}>
                                                                        <svg width="94" height="105" viewBox="0 0 94 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M19.5419 87.25L86.6252 20.6458L73.25 7.36616L6.16665 73.9703V87.25H19.5419ZM0.333313 90.1458V72.7708C0.333313 72.0028 0.640604 71.2662 1.18759 70.7232L71.1876 1.22317C72.3266 0.0922767 74.1733 0.0922767 75.3124 1.22317L92.8124 18.5982C93.9514 19.7291 93.9514 21.5626 92.8124 22.6935L22.8124 92.1935C22.2654 92.7366 21.5235 93.0417 20.75 93.0417H3.24998C1.63915 93.0417 0.333313 91.7452 0.333313 90.1458ZM3.24998 104.625C1.63915 104.625 0.333313 103.328 0.333313 101.729C0.333313 100.13 1.63915 98.8333 3.24998 98.8333H61.6238C63.2346 98.8333 64.5405 100.13 64.5405 101.729C64.5405 103.328 63.2346 104.625 61.6238 104.625H3.24998Z" fill="#FF8A00" />
                                                                        </svg>
                                                                    </button>
                                                                </li>
                                                                <li>
                                                                    <button className="btn btn-default" title="Créer un commentaire" onClick={() => { this.addNewComment() }}>
                                                                        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M1 1V14.5H7.075V18.2125L11.125 14.5H21.25V1H1Z" stroke="#FF8A00" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path d="M5.05078 11.125H13.1508M5.05078 4.375H17.2008H5.05078ZM5.05078 7.75H17.2008H5.05078Z" stroke="#FF8A00" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </Popover>
                                                    }
                                                </div>
                                            </div>
                                        </ZoomContent>



                                    </div>
                                    
                                        <ResumeDataModal title={t("common.modals.confirm_data")} 
                                             isOpen={this.state.showResumeModal} 
                                             onRequestClose={()=>this.handleClose()}
                                             onRequestCancel={()=>this.handleCancel()} 
                                             requiredFields={this.state.actionData === "add" ? this.state.formComment.length:0} 
                                             doSave={()=>this.doSave()} 
                                             fields={this.state.actionData === "add" ? this.state.formComment : this.state.formComment.filter(x=>x.edited)} 
                                             action={this.state.actionData} 
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//export default comments;
export default withTranslation()(withRouter(comments))
