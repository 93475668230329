import React from "react";
import { NegoIcon } from "../../../assets/icons";
const ButtonNego = ({ onPress, disabled, isInproposition, isInAcceptedproposition, isInRefusedproposition }) => (
  <button
    type="button"
    className={`btn btn-default button_nego ${(disabled && disabled !== undefined) || (isInAcceptedproposition && isInAcceptedproposition !== undefined) || (isInRefusedproposition && isInRefusedproposition !== undefined) ? "disabled-event" : ""}`}
    onClick={onPress}
    disabled={(disabled && disabled !== undefined) || (isInAcceptedproposition && isInAcceptedproposition !== undefined) || (isInRefusedproposition && isInRefusedproposition !== undefined)}
  >
    <NegoIcon isInproposition={isInproposition} isInAcceptedproposition={isInAcceptedproposition} isInRefusedproposition={isInRefusedproposition} />
  </button>
);

export default ButtonNego;
