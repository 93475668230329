import React from "react";

const SearchIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7488 12.7482L19.0001 19"
      stroke="#5367F4"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.88235 14.7647C11.6834 14.7647 14.7647 11.6834 14.7647 7.88235C14.7647 4.08133 11.6834 1 7.88235 1C4.08133 1 1 4.08133 1 7.88235C1 11.6834 4.08133 14.7647 7.88235 14.7647Z"
      stroke="#5367F4"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchIcon;
