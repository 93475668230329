import React from "react";
import { CancelIcon } from "../../../assets/icons";
const ButtonCancel = ({ onPress, disabled }) => (
  <button
    style={{display:'flex'}}
    type="button"
    className={`btn btn-default ${disabled ? "" : ""}`}
    onClick={onPress}
  >
    <CancelIcon />
  </button>
);

export default ButtonCancel;
