import React from "react";
const NegociationIcon = () => (
  <svg
    width="29"
    height="19"
    viewBox="0 0 29 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.90909 2.21127V2.81691C5.90909 4.48932 4.53542 5.84508 2.84091 5.84508H2.22727V13.1127H2.8917C4.58621 13.1127 5.95988 14.4685 5.95988 16.1409V16.7465H23.0909V16.1409C23.0909 14.4685 24.4646 13.1127 26.1591 13.1127H26.7727V5.84508H26.1591C24.4646 5.84508 23.0909 4.48932 23.0909 2.81691V2.21127H5.90909ZM4.68182 2.21127H4.06818C3.05148 2.21127 2.22727 3.02473 2.22727 4.02818V4.63381H2.84091C3.85762 4.63381 4.68182 3.82035 4.68182 2.81691V2.21127ZM4.7326 16.7465V16.1409C4.7326 15.1374 3.9084 14.324 2.8917 14.324H2.22727V14.9296C2.22727 15.9331 3.05148 16.7465 4.06818 16.7465H4.7326ZM5.57178 17.9578C5.50196 17.985 5.42587 18 5.34624 18C5.26661 18 5.19052 17.985 5.1207 17.9578H4.06818C2.37367 17.9578 1 16.602 1 14.9296V4.02818C1 2.35576 2.37367 1 4.06818 1H24.9318C26.6263 1 28 2.35576 28 4.02818V14.9296C28 16.602 26.6263 17.9578 24.9318 17.9578H5.57178ZM24.3182 16.7465H24.9318C25.9485 16.7465 26.7727 15.9331 26.7727 14.9296V14.324H26.1591C25.1424 14.324 24.3182 15.1374 24.3182 16.1409V16.7465ZM24.3182 2.21127V2.81691C24.3182 3.82035 25.1424 4.63381 26.1591 4.63381H26.7727V4.02818C26.7727 3.02473 25.9485 2.21127 24.9318 2.21127H24.3182ZM14.5 14.324C11.7888 14.324 9.59091 12.1548 9.59091 9.47889C9.59091 6.80303 11.7888 4.63381 14.5 4.63381C17.2112 4.63381 19.4091 6.80303 19.4091 9.47889C19.4091 12.1548 17.2112 14.324 14.5 14.324ZM14.5 13.1127C16.5334 13.1127 18.1818 11.4858 18.1818 9.47889C18.1818 7.47199 16.5334 5.84508 14.5 5.84508C12.4666 5.84508 10.8182 7.47199 10.8182 9.47889C10.8182 11.4858 12.4666 13.1127 14.5 13.1127Z"
      fill="white"
      stroke="white"
    />
  </svg>
);

export default NegociationIcon;
