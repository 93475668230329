import React, { Component } from 'react'; 

import './ConfirmAlert.scss';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { t } from 'i18next';


class ErrorAlert extends Component {  
 
    constructor(){
        super()
    }

    show = (message, title, TextConfirm = t('common.modals.okey'), TextCancel = t('common.modals.no'), olAftermsg = null) => {
        return new Promise( (resolve, reject) => {
            confirmAlert({
                title: typeof title == 'string' ? title : t('common.modals.error'),
                message: typeof message == 'string' ? message : '',
                buttons: [
                    {
                        label: TextConfirm,
                        onClick: () => resolve()
                    },
                    {
                        label: TextCancel,
                        onClick: () => reject()
                    }
                ],
                customUI: ({ onClose }) => {
                    return (
                      <div className='error-alert'>
                        <h1>{typeof title == 'string' ? title : t('common.modals.error')}</h1>
                        <p>{typeof message == 'string' ? message : ''}</p>
                        { olAftermsg && olAftermsg !== null &&
                          <>
                            <hr/>
                            <ul style={{textAlign:"left"}}>
                              {
                                olAftermsg.map(item=>{
                                  return <li key={item}>{item}</li>;
                                })
                              }
                            </ul>
                            <hr/>
                          </>
                        }
                        <button
                          onClick={() => {
                            resolve();
                            onClose();
                          }}
                        >
                          { TextConfirm }
                        </button>
                      </div>
                    );
                  }
            });
        }) 
    }
    
} 
export default new ErrorAlert(); 