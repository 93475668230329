import { Record, List } from 'immutable';

class Field  extends Record({
  key: 0,
  name: "",
  isScale: false,
  isCryptedData: true,
  isMultipleSelection: true,
  group: false,
  filter: false,
  color: "",
  data: new List()
}){
  constructor(values){
    let record = super(values);
    record = record
    .set('key', values?.parameter.key)
    .set('name', values?.parameter.name)
    .set('isScale', !!values?.parameter.isScale)
    .set('isCryptedData', !!values?.parameter.isCryptedData)
    .set('isMultipleSelection', values?.parameter.isMultipleSelection == 1)
    .set('group', !!values?.parameter.isGrouped)
    .set('filter', !!values?.parameter.isFiltered)
    .set('color', values?.parameter.color)
    .set('data', values?.values?.map(item => {
      if( typeof item == 'string' ){
        return{
          name: item,
          selected: false
        }
      }else return {
        ...item,
        selected: false
      }
    }))

    return record
  }
}

export default Field