import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"

export const getUsers = () => {
    const requestUrl = API_URL+`/user/getUsersByClient/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

  export const exportUsers = () => {
    const requestUrl = API_URL+`/user/getUsersCSVFile/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl)
  };

  export const exportUser = (userID) => {
      var addedUser = false;
      const requestUrl = API_URL+`/user/getUserRightsCSVFile?access_token=`+getConnectedUserAccessToken();
      var formData = new FormData();
      formData.append('client', getCurrentClientID());
   
      formData.append('user', userID);
      return axios.post(requestUrl, formData);
  }

export const getOrganizations = () => {
    const requestUrl = API_URL+`/organization/getOrganizationsByClient/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };


export const createOrganization = (organizationForm) => {
    console.log('organizationForm', organizationForm)
    var addedUser = false;
    const requestUrl = API_URL+`/organization?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('clientID', getCurrentClientID());
    if(organizationForm.value) {
        formData.append('id', organizationForm.value);
    }
    if (organizationForm.users) {
        if(organizationForm.users && organizationForm.users.length) {
            for (let index = 0; index < organizationForm.users.length; ++index) {
                if(!organizationForm.users[index].unselected || organizationForm.users[index].unselected == false ) {
                    formData.append('users['+ index +']', organizationForm.users[index].value);
                    addedUser = true;
                }
            }
        }
        if (addedUser == false) {
            formData.append('users', []);
        }

    }
    formData.append('name', organizationForm.label);
    return axios.post(requestUrl, formData);
}

export const deleteUser = (userID) => {
    const requestUrl = API_URL+`/user/`+userID+`?access_token=`+getConnectedUserAccessToken();
    return axios.delete(requestUrl);
};

export const createUser = (userForm) => {
    var index;
    let addedOrganization = false;
    const requestUrl = API_URL+`/user?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    if(userForm.id) {
        formData.append('id', userForm.id);
    }
    formData.append('firstName', userForm.firstName);
    formData.append('lastName', userForm.lastName);
    formData.append('email', userForm.email);
    formData.append('clientID', getCurrentClientID());
    if(userForm.limitDate) {
        formData.append('limitDate', userForm.limitDate);
    }

    if (userForm.selectedOrganisations) {
        if(userForm.selectedOrganisations && userForm.selectedOrganisations.length) {
            for (index = 0; index < userForm.selectedOrganisations.length; ++index) {
                if(!userForm.selectedOrganisations[index].unselected || userForm.selectedOrganisations[index].unselected == false ) {
                    console.log('userForm.selectedOrganisations[index].unselected', userForm.selectedOrganisations[index])
                    formData.append('organizations['+ index +']', userForm.selectedOrganisations[index].value);
                    addedOrganization = true;
                }
            }
        }
        if (addedOrganization == false) {
            formData.append('organizations', []);
          }

    }

    return axios.post(requestUrl, formData);
}

export const deleteOrganization = (organizationID) => {
    const requestUrl = API_URL+`/organization/`+organizationID+`?access_token=`+getConnectedUserAccessToken();
    return axios.delete(requestUrl);
};

export const dissociateUserToClient = (userID) => {
    var index;
    const requestUrl = API_URL+`/user/dossociateUserToClient?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('id', userID);
    formData.append('clientID', getCurrentClientID());
    return axios.post(requestUrl, formData);
}

export const getUserById = (userID) => {
    const requestUrl = API_URL+`/user/`+userID+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

export const getGuestById = (guestId) => {
    const requestUrl = API_URL+`/guest/`+guestId+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
};

export const checkIfUserEmailAndPasswordAreCorrect = (email, password) => {
    const requestUrl = API_URL+`/user/checkIfUserEmailAndPasswordAreCorrect`;
    var formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    return axios.post(requestUrl, formData);
  };

export const connectUserByPassword = (email, password) => {
    const requestUrl = API_URL+`/user/connectUserByPassword`;
    var formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    return axios.post(requestUrl, formData);
  };

export const getUserRights = () => {
    const requestUrl = API_URL+`/user/getUserRights?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl)
  };

  export const updateUserInformation = (userWithNewInformation) => {
    const requestUrl = API_URL+`/user/updateUserInformation`+`?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('id', userWithNewInformation.id);
    if(userWithNewInformation.firstName) {
        formData.append('firstName', userWithNewInformation.firstName);
    }
    if(userWithNewInformation.lastName) {
        formData.append('lastName', userWithNewInformation.lastName);
    }
    if(userWithNewInformation.phoneNumber) {
        formData.append('phoneNumber', userWithNewInformation.phoneNumber);
    }
    if(userWithNewInformation.oldPassword) {
        formData.append('oldPassword', userWithNewInformation.oldPassword);
    }
      if(userWithNewInformation.type) {
          formData.append('type', userWithNewInformation.type);
      }
    if(userWithNewInformation.newPassword && userWithNewInformation.confirmNewPassword) {
        formData.append('newPassword', userWithNewInformation.newPassword);
        formData.append('confirmNewPassword', userWithNewInformation.confirmNewPassword);
    }
    if(userWithNewInformation.avatar) {
        formData.append('avatar', userWithNewInformation.avatar);
    }
    return axios.post(requestUrl, formData);
  };

  export const checkIfUserEmailExists = (email) => {
    const requestUrl = API_URL+`/user/checkIfUserEmailExists`;
    var formData = new FormData();
    formData.append('email', email);
    return axios.post(requestUrl, formData);
  };

  export const createThePAsswordResetLink = (email) => {
    const requestUrl = API_URL+`/user/createThePAsswordResetLink`;
    var formData = new FormData();
    formData.append('email', email);
    return axios.post(requestUrl, formData);
  };

  export const verifyAndResetPasswordRequest = (request, newPassword, confirmNewPassword) => {
    const requestUrl = API_URL+`/user/verifyAndResetPasswordRequest`;
    var formData = new FormData();
    formData.append('request', request);
    if (newPassword && newPassword != "") {
        formData.append('newPassword', newPassword);
    }
    if (confirmNewPassword && confirmNewPassword != "") {
        formData.append('confirmNewPassword', confirmNewPassword);
    }
    return axios.post(requestUrl, formData);
  };

  export const verifyGuest = (id,code) => {
    const requestUrl = API_URL+`/user/verifyGuest`;
    var formData = new FormData();
    formData.append('id', id);
    formData.append('code', code);
    return axios.post(requestUrl, formData);
  };

  export const getGuestPhone = (id) => {
    const requestUrl = API_URL+`/guest/getPhoneNumber/${id}`;
    return axios.get(requestUrl);
  };

  export const exportOrganization = (contractID) => {
    const requestUrl = API_URL + `/organization/export/` + contractID + `?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl)
  };

  export const exportOrganizations = () => {
    const requestUrl = API_URL + `/organizations/export/` + getCurrentClientID() + `?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl)
  };

  export const setPasswordConfiguration = (passwordConfig) => {
    const requestUrl = API_URL+`/passwordConfiguration?access_token=` + getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('client', getCurrentClientID());
    formData.append('length', passwordConfig["length"]);
    formData.append('containsLetters', passwordConfig["containsLetters"]? 1:0);
    formData.append('containssymbols', passwordConfig["containssymbols"]? 1:0);
    formData.append('containsNumbers', passwordConfig["containsNumbers"]? 1:0);
    formData.append('containsSimilarCharacters', passwordConfig["containsSimilarCharacters"]? 1:0);
    return axios.post(requestUrl, formData);
  };

  export const getPasswordConfigurationByClient = () => {
    const requestUrl = API_URL+`/passwordConfiguration/getByClient/` + getCurrentClientID() + `?access_token=` + getConnectedUserAccessToken();;
    return axios.get(requestUrl);
  };

  export const getPasswordConfigurationByMail = (email) => {
    const requestUrl = API_URL+`/passwordConfiguration/get`;
    var formData = new FormData();
    formData.append('email', email);
    return axios.post(requestUrl, formData);
  };

  export const getPasswordConfigurationByRequestId = (reqId) => {
    const requestUrl = API_URL+`/passwordConfiguration/get`;
    var formData = new FormData();
    formData.append('resetPasswordParameter', reqId);
    return axios.post(requestUrl, formData);
  };

