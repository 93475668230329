import React from "react";
const AlertIcon = () => (
  <svg
    width="17"
    height="21"
    viewBox="0 0 17 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.0257 14.7379V8.68986C3.02666 7.24175 3.60259 5.85328 4.6269 4.82966C5.65121 3.80603 7.04006 3.23102 8.48816 3.23102C9.93627 3.23102 11.3251 3.80603 12.3494 4.82966C13.3737 5.85328 13.9497 7.24175 13.9506 8.68986V14.7379C13.951 15.1349 14.1089 15.5156 14.3897 15.7963C14.6704 16.0771 15.051 16.235 15.448 16.2354C15.5881 16.2354 15.7224 16.2911 15.8214 16.3901C15.9205 16.4891 15.9761 16.6234 15.9761 16.7635C15.9761 16.9035 15.9205 17.0379 15.8214 17.1369C15.7224 17.2359 15.5881 17.2915 15.448 17.2915H1.52805C1.388 17.2915 1.25369 17.2359 1.15466 17.1369C1.05563 17.0379 1 16.9035 1 16.7635C1 16.6234 1.05563 16.4891 1.15466 16.3901C1.25369 16.2911 1.388 16.2354 1.52805 16.2354C1.92511 16.235 2.30579 16.0771 2.58657 15.7964C2.86734 15.5156 3.02527 15.135 3.0257 14.7379Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.52734 17.3478C6.56394 17.8431 6.7863 18.3063 7.1499 18.6445C7.5135 18.9828 7.99148 19.1712 8.4881 19.172C8.98471 19.1712 9.46269 18.9828 9.82629 18.6445C10.1899 18.3063 10.4123 17.8431 10.4488 17.3478"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.73438 3.37856V2.24805C9.73438 1.91705 9.60289 1.5996 9.36883 1.36555C9.13478 1.13149 8.81733 1 8.48633 1C8.15533 1 7.83788 1.13149 7.60383 1.36555C7.36977 1.5996 7.23828 1.91705 7.23828 2.24805V3.38032"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AlertIcon;
