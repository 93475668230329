import React from 'react';

// icons
import SortUpIcon from './icons/SortUp.icon';
import SortDownIcon from './icons/SortDown.icon';
import SearchIcon from './icons/Search.icon';
import PlusIcon from './icons/Plus.icon';

//scss
import './searchForm.scss'

const SearchForm = ({ editMode, onSort, onSearch, onAdd }) => (
  <div className='bo-search-form'>
    <div className='actions'>
      <div className='sorting'>
        <button
          onClick={() => {
            onSort(1);
          }}
          className='btn btn-sorting'
        >
          <SortUpIcon />
        </button>
        <button
          onClick={() => {
            onSort(-1);
          }}
          className='btn btn-sorting'
        >
          <SortDownIcon />
        </button>
      </div>
      <div className='search-input'>
        <SearchIcon />
        <input
          id='search-categories-input'
          type='text'
          placeholder=''
          onKeyUp={ e => onSearch(e.target.value)}
        />
      </div>
      <button onClick={editMode ? onAdd : null} className='btn-icon btn-pill btn btn-danger'>
        <PlusIcon />
        Ajouter
      </button>
    </div>
  </div>
);

export default SearchForm;
