import React from "react";

const PinIcon = ({ }) => (
  <svg width="25px" height="25px" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.925 40.979L7.52399 56.381" stroke="#5367F4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.599 32.653L31.252 49.306C31.5821 49.6361 32.0024 49.8613 32.46 49.9534C32.9177 50.0454 33.3923 50.0002 33.8244 49.8234C34.2564 49.6466 34.6266 49.346 34.8884 48.9596C35.1502 48.5731 35.292 48.1179 35.296 47.6511L35.344 42.0791C35.3473 41.705 35.4392 41.337 35.6119 41.0053C35.7847 40.6735 36.0335 40.3873 36.338 40.1701L54.53 27.2031C54.8095 27.0031 55.0421 26.7447 55.2118 26.4459C55.3815 26.1471 55.4842 25.8149 55.5127 25.4724C55.5412 25.13 55.495 24.7854 55.3771 24.4626C55.2592 24.1398 55.0725 23.8465 54.83 23.6031L40.306 9.07505C40.0625 8.83253 39.7692 8.64585 39.4464 8.52797C39.1236 8.41008 38.7791 8.3638 38.4366 8.39234C38.0941 8.42088 37.762 8.52355 37.4632 8.69324C37.1643 8.86293 36.906 9.09558 36.706 9.37505L23.738 27.5671C23.521 27.8716 23.235 28.1205 22.9034 28.2932C22.5718 28.466 22.2039 28.5578 21.83 28.5611L16.258 28.6091C15.7909 28.6122 15.3351 28.7535 14.948 29.015C14.5609 29.2765 14.2598 29.6467 14.0825 30.0789C13.9052 30.5111 13.8596 30.986 13.9515 31.4441C14.0434 31.9021 14.2687 32.3227 14.599 32.653V32.653Z" stroke="#5367F4" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

export default PinIcon;
