import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"

let cancelTokenSource = axios.CancelToken.source();

export const getRgpdUsersByClient = () => {
  const requestUrl = API_URL + `/user/getAllUsersByClient/` + getCurrentClientID() + `?access_token=` + getConnectedUserAccessToken();
  return axios.get(requestUrl);
};

export const getRgpdUserCategoriesByClient = (userID) => {
  const requestUrl = API_URL + `/user/rgpd/getUserCategoriesByClient/` + getCurrentClientID() + `?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  if (userID) {
    formData.append('user', userID);
  }

  return axios.post(requestUrl, formData, {cancelToken: cancelTokenSource.token});
};

export const getRgpdUserContractsByClient = (userID) => {
  const requestUrl = API_URL + `/user/rgpd/getUserContractsByClient/` + getCurrentClientID() + `?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  if (userID) {
    formData.append('user', userID);
  }

  return axios.post(requestUrl, formData, {cancelToken: cancelTokenSource.token});
};

export const archiveUserByClient = (userID, final=0) => {
  const requestUrl = API_URL + `/user/archiveUserByClient/` + getCurrentClientID() + `?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  if (userID) {
    formData.append('user', userID);
  }
  if (final !== 0) {
    formData.append('final', final);
  }
  return axios.post(requestUrl, formData);
};

export const unArchiveUserByClient = (userID, final=0) => {
  const requestUrl = API_URL + `/user/unarchiveUserByClient/` + getCurrentClientID() + `?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  if (userID) {
    formData.append('user', userID);
  }
  if (final !== 0) {
    formData.append('final', final);
  }
  return axios.post(requestUrl, formData);
};

export const exportContractsByClient = async (contracts) => {
  const requestUrl = API_URL + `/user/rgpd/exportContracts/` + getCurrentClientID() + `?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  contracts.forEach((contractId, index) => {
    formData.append('contracts[' + index.toString() + ']', JSON.stringify(contractId));
  });

  const response = await axios.post(requestUrl, formData, {
    cancelToken: cancelTokenSource.token,
    withCredentials: false,
    responseType: 'blob'
  });
  return response;
  
};

export const requestExportContractsByClient = async (userID, contracts) => {
  const requestUrl = API_URL + `/user/rgpd/scheduleContractExport/` + getCurrentClientID() + `?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  contracts.forEach((contractId, index) => {
    formData.append('contracts[' + index.toString() + ']', JSON.stringify(contractId));
  });
  formData.append('user',userID)
  const response = await axios.post(requestUrl, formData);
  return response;
  
};


export const cancelRequests = () => {
  cancelTokenSource.cancel('Request canceled');
  cancelTokenSource = axios.CancelToken.source(); // Create a new cancel token
  return Promise.resolve(); // Resolves immediately for chaining purposes
};