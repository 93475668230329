import React, { Component } from "react";
import "./Security.scss";
import ErrorAlert from "../../../../../components/confirm-alert/error-alert";

// components
import Header from "../../../../../components/layouts/header/Header";
import Sidebar from "../../../../../components/layouts/sidebar/Sidebar";
import BoRightsManagementMenu from "../../../../../components/layouts/BO-Rights-Management/BoRightsManagementMenu";
import ConfirmAlert from "../../../../../components/confirm-alert/ConfirmAlert";
import LoaderComponent from "../../../../../components/loader/Loader";
import EditMode from "../../../../../components/edit-mode/EditMode";

import { withTranslation } from "react-i18next";
import { t } from "i18next";

import { getPasswordConfigurationByClient, setPasswordConfiguration } from "../../../../../api/user";

// plugins
import * as moment from "moment/min/moment-with-locales";
moment.locale("fr-fr");

// form validation
const ReactFormWithConstraints = require("react-form-with-constraints");


const { FormWithConstraints, FieldFeedbacks, FieldFeedback } =
  ReactFormWithConstraints;

class Security extends Component {
  constructor(props) {
    super(props);
    this.state = {
      securityMenu:[
        {
          key: "pass_config",
          name: "Paramètres de mot de passe"
        },
        {
          key: "mfa",
          name: "MFA"
        }
      ],
      selectedMenuItem: null,
      passwordParams:{},
      showFormAddSecurity: false,
      editMode: false,
      scrolling: false,
      isMFAEnabled:false
    };
  }

  componentDidMount() {
    LoaderComponent.show();
    getPasswordConfigurationByClient().then(
      (response) => {
        this.setState({
          ...this.state,
          passwordParams: response.data,
        });
       LoaderComponent.hide();
      },
      (error) => {
        LoaderComponent.hide();
        ErrorAlert.show(error.response.data, t("common.modals.api_error"));
      }
      );
  
  }


  changeEditMode = (checked) => {
    this.setState({
      ...this.state,
      editMode: checked,
    });
  };

  
  handleChangeInput = (e) => {
    this.form.validateFields(e.target);
    this.setState({
      ...this.state,
      passwordParams: {
        ...this.state.passwordParams,
        [e.target.name]: e.target.value,
      },
    });
  };

  optionnalRules = (e) => {
    this.form.validateFields(e.target);
    this.setState({
      ...this.state,
      passwordParams: {
        ...this.state.passwordParams,
        [e.target.name]: e.target.checked
      },
    });
  };

  viewMenuItem = async (item) => {
    this.setState({
      ...this.state,
      selectedMenuItem: item,
      showFormAddSecurity: true
    })
    if(document.getElementById("form-security"))
      document.getElementById("form-security").reset();
    
  };

  updatePasswordParams = () => {
    let passwordParams = this.state.passwordParams;
    LoaderComponent.show();
    setPasswordConfiguration(passwordParams).then(
      (response) => {
       LoaderComponent.hide();
      },
      (error) => {
        LoaderComponent.hide();
        ErrorAlert.show(error.response.data, t("common.modals.api_error"));
      }
      );
  }


  onScrolling = (e) => {
    this.setState({
      ...this.state,
      scrolling: e.target.scrollTop > 100 ? true : false,
    });
  };

  
  editModeReminder = () => {
    ConfirmAlert.show(
      "Voulez-vous activer le mode d'édition pour effectuer des modifications ?",
      "Rappel",
      "Activer",
      "Annuler"
    ).then(() => {
      this.setState({
        ...this.state,
        editMode: true,
      });
    });
  };



  handleCancel = () => {
    document.getElementById("form-security").reset();
    this.setState({
      ...this,
      showFormAddSecurity: false,
      selectedMenuItem: {}
    })
  }

  toogleMFA = (e) => {
    const mfaStatus = e.target.checked
    ConfirmAlert.show(
      "Voulez-vous vraiment " + (this.state.isMFAEnabled ? "désactiver":"activer") + " l'authentification MFA ?",
      "Confirmation",
      (this.state.isMFAEnabled ? "Désactiver":"Activer"),
      "Annuler"
    ).then(() => {
      this.setState({
        ...this,
        isMFAEnabled: mfaStatus
      })
    });
  }
  

  render() {
    return (
      <div className="app-container backoffice-rights-access page-security app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
        <Header />

        <div className="app-main">
          <div className="app-sidebar sidebar-shadow">
            <Sidebar />
          </div>
          <div className="app-main__outer">
            <EditMode
              isEditMode={this.state.editMode}
              onChange={(checked) => {
                this.changeEditMode(checked);
              }}
            />
            <div className="app-main__inner">
              <div className="row">
                <div
                  className={`col-sm-5 left-side ${
                    this.state.scrolling ? "scrolling" : ""
                  }`}
                >
                  
                  <h1 className="side-menu-title-style">
                    <svg
                      width="43"
                      height="42"
                      viewBox="0 0 43 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.7924 15.3392L32.125 19.3125C33.3142 20.2044 34.0684 21.5478 34.2212 23.0098C35.5929 22.6766 37.0258 22.5 38.5 22.5H39.1224L34.5612 17.9388C34.1463 17.5239 34.1463 16.8511 34.5612 16.4362C34.9761 16.0213 35.6489 16.0213 36.0638 16.4362L42.4388 22.8112C42.8537 23.2261 42.8537 23.8989 42.4388 24.3138L36.0638 30.6888C35.6489 31.1037 34.9761 31.1037 34.5612 30.6888C34.1463 30.2739 34.1463 29.6011 34.5612 29.1862L39.1224 24.625H38.5C29.698 24.625 22.5625 31.7605 22.5625 40.5625C22.5625 41.1493 22.0868 41.625 21.5 41.625C20.9132 41.625 20.4375 41.1493 20.4375 40.5625C20.4375 31.7605 13.302 24.625 4.5 24.625H3.8776L8.4388 29.1862C8.85373 29.6011 8.85373 30.2739 8.4388 30.6888C8.02387 31.1037 7.35113 31.1037 6.9362 30.6888L0.561199 24.3138C0.146267 23.8989 0.146267 23.2261 0.561199 22.8112L6.9362 16.4362C7.35113 16.0213 8.02387 16.0213 8.4388 16.4362C8.85373 16.8511 8.85373 17.5239 8.4388 17.9388L3.8776 22.5H4.5C5.97418 22.5 7.40711 22.6766 8.77879 23.0098C8.93161 21.5478 9.68591 20.2043 10.876 19.3118L16.1968 15.3306C14.2482 13.7731 13 11.3761 13 8.6875C13 3.99308 16.8056 0.1875 21.5 0.1875C26.1944 0.1875 30 3.99308 30 8.6875C30 11.381 28.7472 13.7819 26.7924 15.3392ZM24.8158 16.5165C23.797 16.9485 22.6764 17.1875 21.5 17.1875C20.3174 17.1875 19.1913 16.946 18.1681 16.5096L12.15 21.0125C11.3474 21.6145 10.875 22.5592 10.875 23.5625C10.875 23.5939 10.8736 23.625 10.871 23.6557C15.8056 25.5161 19.7113 29.475 21.5 34.4444C23.2887 29.475 27.1944 25.5161 32.129 23.6557C32.1264 23.625 32.125 23.5939 32.125 23.5625C32.125 22.5592 31.6526 21.6145 30.8527 21.0145L24.8158 16.5165ZM21.5 15.0625C25.0208 15.0625 27.875 12.2083 27.875 8.6875C27.875 5.16668 25.0208 2.3125 21.5 2.3125C17.9792 2.3125 15.125 5.16668 15.125 8.6875C15.125 12.2083 17.9792 15.0625 21.5 15.0625Z"
                        fill="black"
                      />
                    </svg>
                    <span>{t("header.menu.rights_management")} :</span>
                    {t("Sécurité")}
                  </h1>

                  <div className="sub-menu">
                    <BoRightsManagementMenu activeMenu="security"></BoRightsManagementMenu>
                  </div>
                  <hr />
                 
                  <div
                    className={`scrolling-verticaly ${
                      this.state.showFormAddSecurity ? "showFormAddRecord" : ""
                    }`}
                    onScroll={(e) => {
                      this.onScrolling(e);
                    }}
                  >
                    {this.state.showFormAddSecurity && this.state.selectedMenuItem.key ===  "pass_config" && (
                      <div className="new-security add-record">
                        <h2>
                          {this.state.selectedMenuItem.name ||
                            t("Paramètres de mot de passe")}
                        </h2>
                        <div>
                          <button
                            onClick={() => {
                                this.handleCancel()
                            }}
                            className="btn-pill btn btn-cancel"
                          >
                            {t("common.cancel")}
                          </button>
                          <button
                            onClick={() => {
                              this.updatePasswordParams()
                            }}
                            className="btn-pill btn btn-danger btn-save"
                            disabled={!this.state.editMode || !this.state.selectedMenuItem?.name}
                          >
                            {t("common.save")}
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="securities records">
                      {this.state.securityMenu.map((secureMenu, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              this.viewMenuItem(secureMenu);
                            }}
                            className={`security side-menu-style mb-0 ${
                              secureMenu.key == this.state.securityMenu.key 
                                ? "active"
                                : ""
                            }`}
                          >
                            <h3 className="title-stage-one">{secureMenu.name}</h3>
                            <p className="title-stage-two">
                              -------------------------------
                            </p>
                          </div>
                        );
                      })}
                      
                    </div>
                  </div>
                </div>

                <div className="col-sm-7 viewer">
                  <h1>
                    {this.state.selectedMenuItem?.name ||
                      t("Sécurité")}
                  </h1>
                  {this.state.selectedMenuItem && this.state.selectedMenuItem.key === "mfa" &&
                    <div className="row">
                      <div className="col-lg-12 text-center pt-4 font-weight-bold" onClick={()=>{if(!this.state.editMode) this.editModeReminder()}}>
                          <div>
                            <p>Activer ou désactiver l'authentification multi-factor pour les comptes appartenant à ce client.</p>
                          </div>
                          <div>
                          <div className="content-ios-7">
                            <input onChange={(e) => this.toogleMFA(e)} type="checkbox" name="mfa" className="sw" id="toggle-1"
                              disabled={!this.state.editMode}
                              checked={this.state.isMFAEnabled} />
                            <label for="toggle-1"><span>Multi-Factor</span>
                            </label>
                          </div>
                            <small className="mt-2 text-sm text-danger">
                              L'authentification multifactorielle ajoute une couche supplémentaire de sécurité à votre compte. Lorsqu'elle est activée, vous devrez fournir un code à usage unique en plus de votre mot de passe lors de la connexion.
                            </small>
                          </div>
                      </div>
                    </div>
                  }

                  {this.state.selectedMenuItem && this.state.selectedMenuItem.key ===  "pass_config" &&
                    <FormWithConstraints
                    ref={(formWithConstraints) =>
                      (this.form = formWithConstraints)
                    }
                    id="form-security"
                    className={!this.state.editMode ? "consultation" : ""}
                    noValidate
                  >
                    <div
                      className="hideFormInputs"
                      onClick={this.editModeReminder}
                    ></div>
                    

                    <div className="row">
                      <div className="col-lg-12">
                        <label>
                        {t("password_generator.modal_generator.password_length")}*
                        </label>
                        <input
                          value={this.state.passwordParams["length"]}
                          onChange={(e) => {
                            this.handleChangeInput(e);
                          }}
                          type="number"
                          min="6"
                          name="length"
                          className="form-control"
                          required
                        />
                        <FieldFeedbacks for="length">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                    </div>


                    <div className="row">
                      
                      <div className="col-lg-12 access-types">
                        <label>
                          {t("Critères de mot de passe:")}
                        </label>
                       
                        
                        <div className="custom-checkbox">
                          <label>
                            {
                              <input
                                type="checkbox"
                                name="containsSimilarCharacters"
                                onChange={(e) => {
                                  this.optionnalRules(e);
                                }}
                                checked={
                                  this.state.passwordParams.containsSimilarCharacters
                                }
                              ></input>
                            }
                            {
                              <span className="cr">
                                <i className="cr-icon fa fa-check"></i>
                              </span>
                            }
                            {t("password_generator.modal_generator.includes_lowercase")}
                          </label>
                        </div>
                        
                        <div className="custom-checkbox">
                          <label>
                            {
                              <input
                                type="checkbox"
                                name="containsLetters"
                                onChange={(e) => {
                                  this.optionnalRules(e);
                                }}
                                checked={
                                  this.state.passwordParams.containsLetters
                                }
                              ></input>
                            }
                            {
                              <span className="cr">
                                <i className="cr-icon fa fa-check"></i>
                              </span>
                            }
                            {t("password_generator.modal_generator.includes_uppercase")}
                          </label>
                        </div>
                        
                        <div className="custom-checkbox">
                          <label>
                            {
                              <input
                                type="checkbox"
                                name="containsNumbers"
                                onChange={(e) => {
                                  this.optionnalRules(e);
                                }}
                                checked={
                                  this.state.passwordParams.containsNumbers
                                }
                              ></input>
                            }
                            {
                              <span className="cr">
                                <i className="cr-icon fa fa-check"></i>
                              </span>
                            }
                            {t("password_generator.modal_generator.includes_numbers")}
                          </label>
                        </div>
                        
                        <div className="custom-checkbox">
                          <label>
                            {
                              <input
                                type="checkbox"
                                name="containssymbols"
                                onChange={(e) => {
                                  this.optionnalRules(e);
                                }}
                                checked={
                                  this.state.passwordParams.containssymbols
                                }
                              ></input>
                            }
                            {
                              <span className="cr">
                                <i className="cr-icon fa fa-check"></i>
                              </span>
                            }
                            {t("password_generator.modal_generator.includes_sp_chars")}
                          </label>
                        </div>


                      </div>
                    </div>
                   
                  </FormWithConstraints>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Security);
