import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Modal from '../../../../../../components/Modal';
import { CopyIcon } from '../../../assets/icons';
import "./ClauseTypeModal.scss";


const ClauseTypeModal = ({ isOpen, onRequestClose, negoObject, onSetAsProposition, negoObjName }) => {

  


  return (
    <Modal
      title={"Clause Type"}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      isFullScreen={false}
      footer={
        <>
          <button className='m-auto' onClick={() => onSetAsProposition(negoObjName)}>Maintenir</button>
          <button className='m-auto' onClick={() => onRequestClose(false)}>Annuler</button>
        </>
      }
    >
      <div>
        <p className="font-weight-bold">
          Voulez avez une suggestion de proposion :
        </p>
        <hr/>
        <div dangerouslySetInnerHTML={{ __html: negoObject.htmlCTContent }} />
      </div>
    </Modal>
  );
};

export default withTranslation()(ClauseTypeModal);
