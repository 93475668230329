import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../../components/Modal';
import $ from 'jquery';
import moment from 'moment';

// API
import {
  deleteExport,
  generateAndDownloadExport,
  getContractKeywords,
  saveNewExport,
} from '../../../../../../../api/exports/contracts';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

// XLSX library
import * as XLSX from 'xlsx';

import Cookies from "js-cookie";
import { SearchIcon } from '../../../../assets/icons';
import errorAlert from '../../../../../../../components/confirm-alert/error-alert';

const ExportModal = ({ isOpen, onRequestClose }) => {
  const [exportDataList, setExportDataList] = useState([]);
  const [currentExportData, setCurrentExportData] = useState({});

  const [searchingkey, setSearchingkey] = useState("");
  
  
  const [systemKeywords, setSystemKeyWords] = useState([]);
  const [customClauses, setCustomClauses] = useState([]);
  const [othersClauses, setCothersClauses] = useState([]);

  const [showSystemKeyword, setShowSystemKeyword] = useState(false);
  const [showCustomClauses, setShowCustomClauses] = useState(false);

  const [downloading, setDownloading] = useState(false);

  const onchangeSystemKeyword = (e) => {
    let selected = e.target.checked;
    let listing = systemKeywords.map((item) => {
      return { ...item, selected: selected };
    });
    setSystemKeyWords(listing);
    setCurrentExportData({
      ...currentExportData,
      fields:{
        ...currentExportData.fields,
        systemKeywords: listing
       }
    })
    // Cookies.set("systemKeyWords", listing, { expires: 7 })
  };

  const onchangeSubSystemKeyword = (e, index) => {
    let selected = e.target.checked;
    let listing = systemKeywords.map((item, i) => {
      return { ...item, selected: i === index ? selected : (item.selected !== undefined ? item.selected:false) };
    });
    setSystemKeyWords(listing);
    setCurrentExportData({
      ...currentExportData,
      fields:{
        ...currentExportData.fields,
        systemKeywords: listing
       }
    })
    // Cookies.set("systemKeyWords", listing, { expires: 7 })
    let systemKeywordsSelectedLength = listing.filter(row => row.selected && row.selected !== undefined && row.selected !== false).length
    if(systemKeywordsSelectedLength && systemKeywordsSelectedLength>0 && systemKeywordsSelectedLength < listing.length){
        document.getElementsByClassName("input-systemclauses")[0].indeterminate = true;
    }else{
      document.getElementsByClassName("input-systemclauses")[0].indeterminate = false;
    }
  };

  const onchangeSubCustomClauses = (e, index) => {
    let selected = e.target.checked;
    let listing = customClauses.map((item, i) => {
      return { ...item, selected: i === index ? selected : (item.selected !== undefined ? item.selected:false) };
    });
    setCustomClauses(listing);
    setCurrentExportData({
      ...currentExportData,
      fields:{
        ...currentExportData.fields,
        customClauses: listing
       }
    })
    // Cookies.set("customClauses", listing, { expires: 7 })
    let systemKeywordsSelectedLength = listing.filter(row => row.selected && row.selected !== undefined && row.selected !== false).length
    if(systemKeywordsSelectedLength && systemKeywordsSelectedLength>0 && systemKeywordsSelectedLength < listing.length){
        document.getElementsByClassName("input-customclauses")[0].indeterminate = true;
    }else{
      document.getElementsByClassName("input-customclauses")[0].indeterminate = false;
    }
  };

  const onchangeCustomClauses = (e) => {
    let selected = e.target.checked;
    let listing = customClauses.map((item) => {
      return { ...item, selected: selected };
    });
    setCustomClauses(listing);
    setCurrentExportData({
      ...currentExportData,
      fields:{
        ...currentExportData.fields,
        customClauses: listing
       }
    })
    // Cookies.set("customClauses", listing, { expires: 7 })
  };

  const onchangeSubOtherClauses = (e, index) => {
    let selected = e.target.checked;
    let listing = othersClauses.map((item, i) => {
      return { ...item, selected: i === index ? selected : (item.selected !== undefined ? item.selected:false) };
    });
    setCothersClauses(listing);
    setCurrentExportData({
      ...currentExportData,
      fields:{
        ...currentExportData.fields,
        others: listing
       }
    })
    // Cookies.set("othersClauses", listing, { expires: 7 })
  };

  const loadContractKeywords = async () => {
    setShowSystemKeyword(false)
    setShowCustomClauses(false)
    let keywords = await getContractKeywords();
    let systemKeyWordsValues = keywords.systemKeywords
    let customClausesValues = keywords.customClauses
    let othersClausesValues =   keywords.others
    setSystemKeyWords(systemKeyWordsValues);
    setCustomClauses(customClausesValues);
    setCothersClauses(othersClausesValues);
    setExportDataList(keywords.exports.map(row => {
      const fieldsData = JSON.parse(row.fields);
      return {
        id: row.id,
        name: row.name,
        fields: fieldsData
      };
    }))
    setCurrentExportData({
      name: "",
      fields:{
        "systemKeywords":systemKeyWordsValues,
        "customClauses":customClausesValues,
        "others":othersClausesValues,
      }
    })
    if (document.getElementsByClassName("input-systemclauses").length > 0) {
      document.getElementsByClassName("input-systemclauses")[0].indeterminate = false;
    }
    if (document.getElementsByClassName("input-customclauses").length > 0) {
      document.getElementsByClassName("input-customclauses")[0].indeterminate = false;
    }
  };

  const downladExport = async (fileExtension = '') => {
    setDownloading(true);
    let response = await generateAndDownloadExport({
      systemKeywords: systemKeywords.filter((item) => item.selected),
      customClauses: customClauses.filter((item) => item.selected),
      othersClauses: othersClauses.filter((item) => item.selected),
      contractsIds: $('#homeDataTable1 tr')
        .map((index, contract) => $(contract).attr('contractId'))
        .toArray(),
    });
    if(fileExtension === 'csv'){
      const fileName = `Export-contrats-${moment().format('DD-MM-YYYY')}.csv`;
      if (response.data) fileDownload(response.data, fileName);
    }
    if(fileExtension === 'xlsx'){
      const fileNameExcel = `Export-contrats-${moment().format('DD-MM-YYYY')}.xlsx`;
      if (response.data) convertCsvToExcelBuffer(response.data, fileNameExcel)
    }
    setDownloading(false);
    onRequestClose(false);
  };

  const fileDownload = (data, fileName) => {
    var universalBOM = "\uFEFF";
    var a = window.document.createElement('a');
    a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+data));
    a.setAttribute('download', fileName);
    window.document.body.appendChild(a);
    a.click();
  }

  const convertCsvToExcelBuffer = (csvString, fileNameExcel) => {
    const arrayOfArrayCsv = csvString.split("\n").map((row) => {
      return row.split(";")
    });
    const wb = XLSX.utils.book_new();
    const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
    XLSX.utils.book_append_sheet(wb, newWs);
    const rawExcel = XLSX.write(wb, { type: 'base64' })
    console.log('rawExcel', rawExcel)
    XLSX.writeFile(wb, fileNameExcel);
    return rawExcel
  }

  const saveExportData = () => {
    if(!currentExportData){
      alert("Ajout echoué")
      return;
    }
    if(currentExportData.name == undefined || currentExportData.name == null || currentExportData.name == ""){
      alert("Nom d'export requise")
      return;
    }

    saveNewExport(currentExportData).then(
      (response) => {
        setExportDataList([])
        setCurrentExportData({})
        // setSystemKeyWords([]);
        // setCustomClauses([]);
        // setCothersClauses([]);
        loadContractKeywords()
        if (document.getElementsByClassName("input-systemclauses").length > 0) {
          document.getElementsByClassName("input-systemclauses")[0].indeterminate = false;
        }
        if (document.getElementsByClassName("input-customclauses").length > 0) {
          document.getElementsByClassName("input-customclauses")[0].indeterminate = false;
        }
      },
      (error) => {
        errorAlert.show(error.response.data, 'Erreur API');
      }
    );
  }
  const newExportData = () => {
    setExportDataList([])
    setCurrentExportData({})
    loadContractKeywords()
    if(document.getElementsByClassName("input-systemclauses").length >0){
        document.getElementsByClassName("input-systemclauses")[0].indeterminate = false;
    }
    if(document.getElementsByClassName("input-customclauses").length >0){
        document.getElementsByClassName("input-customclauses")[0].indeterminate = false;
    }
  }
  const deleteExportData = () => {
    deleteExport(currentExportData["id"]).then(
      (response) => {
        setExportDataList([])
        setCurrentExportData({})
        // setSystemKeyWords([]);
        // setCustomClauses([]);
        // setCothersClauses([]);
        loadContractKeywords()
        if (document.getElementsByClassName("input-systemclauses").length > 0) {
          document.getElementsByClassName("input-systemclauses")[0].indeterminate = false;
        }
        if (document.getElementsByClassName("input-customclauses").length > 0) {
          document.getElementsByClassName("input-customclauses")[0].indeterminate = false;
        }
      },
      (error) => {
        errorAlert.show(error.response.data, 'Erreur API');
      }
    );
  }
  const loadExportData = (item) => {
    
    setCurrentExportData(item)
    setSystemKeyWords(item["fields"].systemKeywords || []);
    setCustomClauses(item["fields"].customClauses || []);
    setCothersClauses(item["fields"].others || []);
    let systemKeywordsSelectedLength = item["fields"].systemKeywords.filter(row => row.selected && row.selected !== undefined && row.selected !== false).length
    if(document.getElementsByClassName("input-systemclauses").length >0){
      if(systemKeywordsSelectedLength && systemKeywordsSelectedLength>0 && systemKeywordsSelectedLength < item["fields"].systemKeywords.length){
          document.getElementsByClassName("input-systemclauses")[0].indeterminate = true;
      }else{
        document.getElementsByClassName("input-systemclauses")[0].indeterminate = false;
      }
    }
    let customKeywordsSelectedLength = item["fields"].customClauses.filter(row => row.selected && row.selected !== undefined && row.selected !== false).length
    if(document.getElementsByClassName("input-customclauses").length >0){
      if(customKeywordsSelectedLength && customKeywordsSelectedLength>0 && customKeywordsSelectedLength < item["fields"].customClauses.length){
          document.getElementsByClassName("input-customclauses")[0].indeterminate = true;
      }else{
        document.getElementsByClassName("input-customclauses")[0].indeterminate = false;
      }
    }
  }

  useEffect(() => {
    loadContractKeywords();
  }, []);

  return (
    <Modal
      title={t('frontoffice.home.contracts.table.export.export_fields')}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      isFullScreen={false}
      footer={
        <>
          <button onClick={()=>downladExport('csv')} disabled={downloading}>
            {!downloading ? t('CSV') : <i class="fas fa-circle-notch fa-spin"></i>}
          </button>
          <button onClick={()=>downladExport('xlsx')} disabled={downloading}>
            {!downloading ? t('XLSX') : <i class="fas fa-circle-notch fa-spin"></i>}
          </button>
          <button onClick={() => onRequestClose(false)}>{t('common.modals.ignore')}</button>
        </>
      }
    >
      <table className="export-home">
        <tbody>
          <tr>
            <td>
              <div className="control-area">
                <a className="new-item" onClick={() => newExportData()}>
                  <svg width="30" height="30" viewBox="-7 -15 140 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M99.1667 33.8066V49.9373C99.1667 51.5596 97.8608 52.8748 96.25 52.8748C94.6392 52.8748 93.3333 51.5596 93.3333 49.9373V26.4373C93.3333 24.8149 94.6392 23.4998 96.25 23.4998H119.583C121.194 23.4998 122.5 24.8149 122.5 26.4373C122.5 28.0596 121.194 29.3748 119.583 29.3748H103.003C115.188 39.2958 122.5 54.276 122.5 70.4998C122.5 99.7018 98.9949 123.375 70 123.375C41.0051 123.375 17.5 99.7018 17.5 70.4998C17.5 42.446 39.2544 19.3173 66.9395 17.7132C68.5477 17.62 69.9264 18.8575 70.0189 20.4771C70.1114 22.0968 68.8827 23.4853 67.2746 23.5785C42.6716 25.004 23.3333 45.564 23.3333 70.4998C23.3333 96.4571 44.2267 117.5 70 117.5C95.7733 117.5 116.667 96.4571 116.667 70.4998C116.667 56.0015 110.102 42.6268 99.1667 33.8066Z" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="#FFFFFF" />
                  </svg>
                </a>
                <a className="save" onClick={() => saveExportData()}>
                  <svg width="36" height="36" viewBox="0 0 140 141" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M61.25 86.9082L100.021 47.8604C101.16 46.7132 103.007 46.7132 104.146 47.8604C105.285 49.0075 105.285 50.8675 104.146 52.0146L63.3124 93.1396C62.1733 94.2868 60.3266 94.2868 59.1876 93.1396L41.6876 75.5146C40.5486 74.3675 40.5486 72.5075 41.6876 71.3604C42.8266 70.2132 44.6733 70.2132 45.8124 71.3604L61.25 86.9082Z" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="#FFFFFF" />
                  </svg>
                </a>
                {currentExportData && currentExportData.id !== undefined &&
                  <a className="delete" onClick={() => deleteExportData()}>
                    <svg width="36" height="36" viewBox="-7 -7 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M50.6666 16V49.3333C50.6666 53.0152 47.6819 56 44 56H20C16.3181 56 13.3333 53.0152 13.3333 49.3333V16H12C11.2636 16 10.6667 15.403 10.6667 14.6667C10.6667 13.9303 11.2636 13.3333 12 13.3333H24V12C24 9.79086 25.7908 8 28 8H36C38.2091 8 40 9.79086 40 12V13.3333H52C52.7364 13.3333 53.3333 13.9303 53.3333 14.6667C53.3333 15.403 52.7364 16 52 16H50.6666ZM16 16V49.3333C16 51.5425 17.7908 53.3333 20 53.3333H44C46.2091 53.3333 48 51.5425 48 49.3333V16H16ZM37.3333 13.3333V12C37.3333 11.2636 36.7364 10.6667 36 10.6667H28C27.2636 10.6667 26.6667 11.2636 26.6667 12V13.3333H37.3333ZM37.3333 25.3333C37.3333 24.597 37.9303 24 38.6667 24C39.403 24 40 24.597 40 25.3333V44C40 44.7364 39.403 45.3333 38.6667 45.3333C37.9303 45.3333 37.3333 44.7364 37.3333 44V25.3333ZM24 25.3333C24 24.597 24.5969 24 25.3333 24C26.0697 24 26.6667 24.597 26.6667 25.3333V44C26.6667 44.7364 26.0697 45.3333 25.3333 45.3333C24.5969 45.3333 24 44.7364 24 44V25.3333Z" stroke="#FFFFFF" stroke-width="1" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="#FFFFFF" />
                    </svg>

                    {/* <svg width="36" height="36" viewBox="0 0 139 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M69.5 65.4045L93.5148 41.3897C94.6457 40.2588 96.4793 40.2588 97.6101 41.3897C98.741 42.5206 98.741 44.3541 97.6101 45.485L73.5953 69.4998L97.6101 93.5147C98.741 94.6456 98.741 96.4791 97.6101 97.61C96.4793 98.7409 94.6457 98.7409 93.5148 97.61L69.5 73.5952L45.4852 97.61C44.3543 98.7409 42.5207 98.7409 41.3898 97.61C40.2589 96.4791 40.2589 94.6456 41.3898 93.5147L65.4047 69.4998L41.3898 45.485C40.2589 44.3541 40.2589 42.5206 41.3898 41.3897C42.5207 40.2588 44.3543 40.2588 45.4852 41.3897L69.5 65.4045Z" stroke="#FFFFFF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" fill="#FFFFFF" />
                    </svg> */}
                  </a>
                }
              </div>
            </td>
            <td className="zone-details">
              <div>
                <a class="ui teal ribbon label">{t("common.details")}</a>
                <ul className="export-contracts-fiels p-4">
                  <li>
                    <div className="input-item input-item-styled">
                      <label>
                      {t("common.label")}
                      </label>
                      <div className="d-flex">
                        <input
                          required
                          className="form-control"
                          name="libelle"
                          value={currentExportData && currentExportData.name !== undefined ? currentExportData["name"] : ""}
                          placeholder={t('frontoffice.home.contracts.table.export.enter_export_name')}
                          onChange={(e) => {
                            setCurrentExportData({ ...currentExportData, name: e.target.value })
                          }}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <hr />
                  </li>
                  <li className={showSystemKeyword ? 'active' : ''}>
                    <label>
                      <input type="checkbox" className="input-systemclauses" onChange={onchangeSystemKeyword}
                        checked={systemKeywords.filter(row => row.selected).length === systemKeywords.length}
                      />
                      <span>{t('frontoffice.home.contracts.table.export.system_clauses')}{systemKeywords ? ` (${systemKeywords.filter(row => row.selected && row.selected !== undefined && row.selected !== false).length})` : ""}</span>
                      &nbsp;&nbsp;
                      <button onClick={() => setShowSystemKeyword(!showSystemKeyword)}>
                        {showSystemKeyword ? (
                          <i className="fa fa-chevron-down"></i>
                        ) : (
                          <i className="fa fa-chevron-right"></i>
                        )}
                      </button>
                    </label>
                    {showSystemKeyword && (
                      <ul>
                        {systemKeywords?.map((field, key) => (
                          <li key={key}>
                            <label>
                              <input
                                type="checkbox"
                                checked={field.selected && field.selected !== undefined && field.selected !== false}
                                onChange={(e) => onchangeSubSystemKeyword(e, key)}
                              />
                              {field.label}
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                  <li className={showCustomClauses ? 'active' : ''}>
                    <label>
                      <input type="checkbox" className="input-customclauses" onChange={onchangeCustomClauses}
                        checked={customClauses.filter(row => row.selected).length === customClauses.length} />
                      <span>{t('frontoffice.home.contracts.table.export.custom_clauses')} {customClauses ? ` (${customClauses.filter(row => row.selected && row.selected !== undefined && row.selected !== false).length})` : ""}</span>
                      &nbsp;&nbsp;
                      <button onClick={() => setShowCustomClauses(!showCustomClauses)}>
                        {showCustomClauses ? (
                          <i className="fa fa-chevron-down"></i>
                        ) : (
                          <i className="fa fa-chevron-right"></i>
                        )}
                      </button>
                    </label>
                    {showCustomClauses && (
                      <ul>
                        {customClauses?.map((field, key) => (
                          <li key={key}>
                            <label>
                              <input
                                type="checkbox"
                                checked={field.selected && field.selected !== undefined && field.selected !== false}
                                onChange={(e) => onchangeSubCustomClauses(e, key)}
                              />
                              {field.label}
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                  {/* {others.map((keyword,key) => ()} */}
                  {othersClauses.map((field, key) => (
                    <li key={key}>
                      <label>
                        <input
                          type="checkbox"
                          checked={field.selected && field.selected !== undefined && field.selected !== false}
                          onChange={(e) => onchangeSubOtherClauses(e, key)}
                        />
                        <span>
                          {t('frontoffice.home.contracts.table.export.' + field.code.toLowerCase())}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </td>
            <td></td>
            <td className="zone-tags" width="50%">
              <div>
                <a class="ui teal ribbon label">{t("common.records")}</a>
                <div className="my-4 p-4">
                  <div className="search-input search-export">
                    <SearchIcon />
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t('frontoffice.home.contracts.table.export.search_by_export_name')}
                      onChange={(e) => {
                        setSearchingkey(e && e.target !== undefined && e.target.value !== undefined && e.target.value !== null ? e.target.value : "")
                      }}
                    />
                  </div>
                  <hr />
                  <p className="box-tags">
                    {
                      exportDataList.map(item => {
                        if (item && item.name.toLowerCase().includes(searchingkey.toLowerCase()))
                          return (<span className={(item && item === currentExportData) ? "export-tag-name selected" : "export-tag-name"} onClick={() => { loadExportData(item) }}>{item.name}</span>)
                      })
                    }
                    {
                      exportDataList && exportDataList.length > 0 && searchingkey.trim() !== "" && !exportDataList.some(obj => obj.name.toLowerCase().includes(searchingkey.toLowerCase())) &&
                      <span className="text-danger">Aucun résultat trouvée</span>
                    }
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default withTranslation()(ExportModal);
