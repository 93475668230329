import React, { useState } from 'react';

//icons
import starIcon from '../../../icons/star.svg';
import starIconOff from '../../../icons/star-off.svg';

// scss
import './form-add-graph.scss';
import ConfirmAlert from '../../../../../../../components/confirm-alert/ConfirmAlert';

const FormAddGraph = ({
  show,
  editMode,
  isNew,
  name,
  isFavorite,
  editable,
  onSetFavorite,
  onPressCancel,
  onPressSave,
  onPressDuplicate,
}) => {
  const _onPressDuplicate = () => {
    ConfirmAlert.show('Voulez vous vraiment duppliquer ce tableau de bord ?').then(
      onPressDuplicate
    );
  };

  return show ? (
    <div className="graph-new-record add-record">
      <div className="record-name">
        <div className="d-flex justify-content-between">
          <h2>{name || 'Nouveau'}</h2>

          <button
            className="btn btn-link favorite"
            onClick={() => (editable ? onSetFavorite(!isFavorite) : null)}
          >
            {isFavorite ? <img src={starIcon} alt="" /> : <img src={starIconOff} alt="" />}
          </button>
        </div>
        {editable && <small>{isNew ? 'En création' : 'En modification'}</small>}
      </div>
      <div className="d-flex">
        <button onClick={onPressCancel} className="btn-pill btn btn-cancel">
          Annuler
        </button>
        {editable && (
          <button
            onClick={onPressSave}
            className="btn-pill btn btn-danger btn-save"
            disabled={!editMode}
          >
            Enregistrer
          </button>
        )}

        {!isNew && (
          <button
            onClick={_onPressDuplicate}
            className="btn-pill btn btn-danger btn-duplicate"
            disabled={!editMode}
          >
            Dupliquer
          </button>
        )}
      </div>
    </div>
  ) : null;
};

export default FormAddGraph;
