import React, { Component } from "react";
import {
  getPerimeters,
  getCategories,
  createPerimeter,
  deletePerimeter,
  exportPerimeters
} from "../../../../../api/perimeter";
import "./Perimeter.scss";
import ErrorAlert from "../../../../../components/confirm-alert/error-alert";

// components
import Header from "../../../../../components/layouts/header/Header";
import Sidebar from "../../../../../components/layouts/sidebar/Sidebar";
import BoRightsManagementMenu from "../../../../../components/layouts/BO-Rights-Management/BoRightsManagementMenu";
import ConfirmAlert from "../../../../../components/confirm-alert/ConfirmAlert";
import LoaderComponent from "../../../../../components/loader/Loader";
import EditMode from "../../../../../components/edit-mode/EditMode";

// plugins
import * as moment from "moment/min/moment-with-locales";
moment.locale("fr-fr");
const ObjectsToCsv = require("objects-to-csv");

// form validation
const ReactFormWithConstraints = require("react-form-with-constraints");

/* eslint-disable import/first */
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

// XLSX library
import * as XLSX from 'xlsx';

import Modal from "../../../../../components/Modal";
import ResumeDataModal from "../../../../../components/resumeDataModal/ResumeDataModal";

const { FormWithConstraints, FieldFeedbacks, FieldFeedback } =
  ReactFormWithConstraints;

class Perimeter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      default: 123,
      showFormAddPerimeter: false,
      editMode: false,
      perimeters: [],
      selectedPerimeter: {},
      selectedPerimeterIndex: null,
      isNewPerimeter: true,
      categories: [],
      showUnselectedCategories: false,
      scrolling: false,
      unselectCategories: null,
      unselectCategoriesList: [],
      showModal:false,
      downloadStage: 0, // 0: no stage selected | 1: exportUser | 2: exportUsers
      showResumeModal: false,
      actionData: "add", // add | edit | delete
      formPerimeter: [
        { name: "name", txtLabel: "Nom Périmètre", value: null, edited: false },
        { name: "designation", txtLabel: "Désignation", value: null, edited: false },
        { name: "categories", txtLabel: "Catégorie(s)", value: null, edited: false }
      ]
    };

    this.allPerimeters = this.state.perimeters;
  }

  componentDidMount() {
    LoaderComponent.show();
    getPerimeters().then((response) => {
      getCategories().then((response) => {
        LoaderComponent.hide();
        this.setState({
          ...this.state,
          categories: response.data["categories"],
          unselectCategories: response.data["unassociatedCategriesCount"],
          unselectCategoriesList: response.data["unassociatedCategries"],
        });
      });
      this.setState(
        {
          ...this.state,
          perimeters: response.data,
        },
        () => {
          this.allPerimeters = [...this.state.perimeters];
          document.getElementById("search-perimeters-input").value = "";
        }
      );
    });
  }

  changeEditMode = (checked) => {
    this.setState({
      ...this.state,
      editMode: checked,
    });
  };

  exitForm = () => {
    return new Promise((resolve, reject) => {
      if (
        !this.state.showFormAddPerimeter ||
        !this.state.editMode ||
        Object.keys(this.state.selectedPerimeter).length == 0
      ) {
        resolve();
      } else {
        if (
          this.state.selectedPerimeter !==
          this.state.perimeters[this.state.selectedPerimeterIndex]
        ) {
          ConfirmAlert.show(t('common.modals.confirm_action')).then(
            resolve,
            reject
          );
        } else {
          resolve();
        }
      }
    });
  };

  newPerimeter = () => {
    this.exitForm().then(() => {
      document.getElementById("form-perimeter").reset();
      this.setState({
        ...this.state,
        actionData: "add",
        isNewPerimeter: true,
        showFormAddPerimeter: true,
        showUnselectedCategories: false,
        selectedPerimeter: {
          categories: [],
        },
      });
    });
  };

  cancelNewPerimeter = () => {
    this.exitForm().then(() => {
      document.getElementById("form-perimeter").reset();
      this.setState({
        ...this.state,
        isNewPerimeter: false,
        showFormAddPerimeter: false,
        selectedPerimeter: {},
      });
    });
  };

  handleChangeInput = (e) => {
    if (!this.state.selectedPerimeter.hasOwnProperty("name")) {
      this.setState({
        ...this.state,
        isNewPerimeter: true,
        showFormAddPerimeter: true,
        selectedPerimeter: {
          categories: [],
        },
      });
    }
    this.form.validateFields(e.target);
    this.setState({
      ...this.state,
      selectedPerimeter: {
        ...this.state.selectedPerimeter,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeEmails = (e, index) => {
    let emails = this.state.selectedPerimeter.emails;
    emails[index] = e.target.value;
    this.setState({
      ...this.state,
      selectedPerimeter: {
        ...this.state.selectedPerimeter,
        emails: emails,
      },
    });
  };

  addNewPerimeter = () => {
    this.form.validateFields();

    setTimeout(() => {
      if (this.form.isValid()) {
        if (this.state.isNewPerimeter) {
          LoaderComponent.show();
          createPerimeter(this.state.selectedPerimeter).then(
            (response) => {
              getPerimeters().then((response) => {
                getCategories().then((response) => {
                  LoaderComponent.hide();
                  this.setState({
                    ...this.state,
                    categories: response.data["categories"],
                    unselectCategories:
                      response.data["unassociatedCategriesCount"],
                    unselectCategoriesList:
                      response.data["unassociatedCategries"],
                    //showUnselectedCategories: false,
                  });
                });
                this.setState(
                  {
                    ...this.state,
                    perimeters: response.data,
                    showResumeModal: false
                  },
                  () => {
                    this.allPerimeters = [...this.state.perimeters];
                    document.getElementById("search-perimeters-input").value =
                      "";
                  }
                );
              });
              this.setState({
                showFormAddPerimeter: !this.state.showFormAddPerimeter,
                selectedPerimeter: {
                  categories: [],
                },
              });
              document.getElementById("form-perimeter").reset();
            },
            (error) => {
              LoaderComponent.hide();
              ErrorAlert.show(error.response.data, t('common.modals.confirm_action'));
              getPerimeters().then((response) => {
                getCategories().then((response) => {
                  this.setState({
                    ...this.state,
                    categories: response.data["categories"],
                    unselectCategories:
                      response.data["unassociatedCategriesCount"],
                    unselectCategoriesList:
                      response.data["unassociatedCategries"],
                    //showUnselectedCategories: false,
                  });
                });
                this.setState(
                  {
                    ...this.state,
                    perimeters: response.data,
                    showResumeModal: false
                  },
                  () => {
                    this.allPerimeters = [...this.state.perimeters];
                    document.getElementById("search-perimeters-input").value =
                      "";
                  }
                );
              });
            }
          );
        } else {
          this.UpdatePerimeter();
        }
      }
    });
  };

  viewPerimeterInfo = (Perimeter, index) => {
    if (!Perimeter.categories) Perimeter.categories = [];
    this.exitForm().then(() => {
      this.setState({
        ...this.state,
        isNewPerimeter: false,
        showFormAddPerimeter: true,
        selectedPerimeter: Perimeter,
        selectedPerimeterIndex: index,
        showUnselectedCategories: false,
        actionData: "edit",
        formPerimeter: [
          { name: "name", txtLabel: "Nom Périmètre", value: null, originValue: Perimeter?.name, edited: false },
          { name: "designation", txtLabel: "Désignation", value: null, originValue: Perimeter?.designation, edited: false },
          {
            name: "categories", txtLabel: "Catégorie(s)", value: null,
            originValue: (Array.isArray(Perimeter?.categories) ? Perimeter?.categories?.map(category => `${category.label}`).join('\n ') : Perimeter?.categories),
            edited: false
          }
        ]
      });
      document.getElementById("form-perimeter").reset();
    });
  };

  UpdatePerimeter = () => {
    LoaderComponent.show();
    createPerimeter(this.state.selectedPerimeter).then(
      (response) => {
        getPerimeters().then((response) => {
          getCategories().then((response) => {
            LoaderComponent.hide();
            this.setState({
              ...this.state,
              categories: response.data["categories"],
              unselectCategories: response.data["unassociatedCategriesCount"],
              unselectCategoriesList: response.data["unassociatedCategries"],
              //showUnselectedCategories: false,
            });
          });
          this.setState(
            {
              ...this.state,
              perimeters: response.data,
              showResumeModal: false
            },
            () => {
              this.allPerimeters = [...this.state.perimeters];
              document.getElementById("search-perimeters-input").value = "";
            }
          );
        });
      },
      (error) => {
        LoaderComponent.hide();
        ErrorAlert.show(error.response.data, t('common.modals.api_error'));
        getPerimeters().then((response) => {
          getCategories().then((response) => {
            this.setState({
              ...this.state,
              categories: response.data["categories"],
              unselectCategories: response.data["unassociatedCategriesCount"],
              unselectCategoriesList: response.data["unassociatedCategries"],
              //showUnselectedCategories: false,
            });
          });
          this.setState(
            {
              ...this.state,
              perimeters: response.data,
              showResumeModal: false
            },
            () => {
              this.allPerimeters = [...this.state.perimeters];
              document.getElementById("search-perimeters-input").value = "";
            }
          );
        });
      }
    );
    let perimeters = this.state.perimeters;
    perimeters[this.state.selectedPerimeterIndex] =
      this.state.selectedPerimeter;
    this.setState({
      ...this.state,
      isNewPerimeter: false,
      showFormAddPerimeter: false,
      selectedPerimeterIndex: null,
      perimeters: perimeters,
      selectedPerimeter: {},
      showResumeModal: false
    });
    document.getElementById("form-perimeter").reset();
  };

  viewNextPerimeter = () => {
    let index = parseInt(this.state.selectedPerimeterIndex);
    let perimeters = this.state.perimeters;
    console.log(index + 1);
    if (index >= 0 && index < perimeters.length - 1) {
      this.setState({
        ...this.state,
        selectedPerimeter: perimeters[index + 1],
        selectedPerimeterIndex: [index + 1],
        actionData: "edit",
        formPerimeter:[
          { name: "name", txtLabel: "Nom Périmètre", value: null, originValue:perimeters[index + 1]?.name, edited: false },
          { name: "designation", txtLabel: "Désignation", value: null, originValue:perimeters[index + 1]?.designation, edited: false },
          { name: "categories", txtLabel: "Catégorie(s)", value: null,
          originValue:(Array.isArray(perimeters[index + 1]?.categories) ? perimeters[index + 1]?.categories?.map(category => `${category.label}`).join('\n ') : perimeters[index + 1]?.categories),
          edited: false }
        ]
        //showUnselectedCategories: false,
      });
    }
  };

  viewPrevPerimeter = () => {
    let index = parseInt(this.state.selectedPerimeterIndex);
    let perimeters = this.state.perimeters;
    console.log(index - 1);
    if (index >= 1 && index <= perimeters.length) {
      this.setState({
        ...this.state,
        selectedPerimeter: perimeters[index - 1],
        selectedPerimeterIndex: [index - 1],
        actionData: "edit",
        formPerimeter:[
          { name: "name", txtLabel: "Nom Périmètre", value: null, originValue:perimeters[index - 1]?.name, edited: false },
          { name: "designation", txtLabel: "Désignation", value: null, originValue:perimeters[index - 1]?.designation, edited: false },
          { name: "categories", txtLabel: "Catégorie(s)", value: null,
          originValue:(Array.isArray(perimeters[index - 1]?.categories) ? perimeters[index - 1]?.categories?.map(category => `${category.label}`).join('\n ') : perimeters[index - 1]?.categories),
          edited: false }
        ]
        //showUnselectedCategories: false,
      });
    }
    document.getElementById("form-perimeter").reset();
  };

  sortPerimeters = (sortby, sort) => {
    let perimeters = this.state.perimeters;
    perimeters.sort(function (a, b) {
      if (a[sortby] < b[sortby]) return sort == "ASC" ? -1 : 1;
      if (a[sortby] > b[sortby]) return sort == "ASC" ? 1 : -1;
      return 0;
    });
    this.setState({
      ...this.state,
      perimeters: perimeters,
    });
  };

  searchPerimeters = (e) => {
    let perimeters = this.allPerimeters;
    let searchInput = e.target.value;
    let foundedPerimeters = [];
    perimeters.map((perimeter, index) => {
      if (perimeter.name.toLowerCase().includes(searchInput.toLowerCase()))
        foundedPerimeters.push(perimeter);
    });

    console.log(perimeters);
    this.setState({
      ...this.state,
      perimeters: foundedPerimeters,
    });
  };

  exportPerimeter = () => {
    this.handleShow(1)
  };

  doExportPerimeter = (fileExtension = '') => {
    let data = [this.state.selectedPerimeter];
    const csv = new ObjectsToCsv(data).toString();
    csv.then((csvString) => {
      if (fileExtension === 'csv') {
        const fileName = `Export-perimetre-${this.state.selectedPerimeter.name +"-"+moment().format('DD-MM-YYYY')}.csv`;
        if (csvString) this.fileDownload(csvString, fileName);
      }
      if (fileExtension === 'xlsx') {
        const fileNameExcel = `Export-perimetre-${this.state.selectedPerimeter.name +"-"+moment().format('DD-MM-YYYY')}.xlsx`;
        if (csvString) this.convertCsvToExcelBuffer(csvString, fileNameExcel)
      }
      this.handleClose()
    })
  }

  deletePerimeter = () => {
    ConfirmAlert.show(t('common.modals.confirm_action')).then(
      () => {
        let perimeters = this.state.perimeters;
        LoaderComponent.show();
        deletePerimeter(this.state.selectedPerimeter.id).then(
          (response) => {
            getPerimeters().then((response) => {
              getCategories().then((response) => {
                LoaderComponent.hide();
                this.setState({
                  ...this.state,
                  categories: response.data["categories"],
                  unselectCategories:
                    response.data["unassociatedCategriesCount"],
                  unselectCategoriesList:
                    response.data["unassociatedCategries"],
                  //showUnselectedCategories: false,
                });
              });
              this.setState(
                {
                  ...this.state,
                  perimeters: response.data,
                },
                () => {
                  this.allPerimeters = [...this.state.perimeters];
                  document.getElementById("search-perimeters-input").value = "";
                }
              );
            });
          },
          (error) => {
            LoaderComponent.hide();
            ErrorAlert.show(error.response.data, t('common.modals.api_error'));
          }
        );

        document.getElementById("form-perimeter").reset();
        this.setState({
          ...this.state,
          perimeters: perimeters,
          isNewPerimeter: false,
          showFormAddPerimeter: false,
          selectedPerimeter: {},
          selectedPerimeterIndex: null,
        });
      }
    );
  };

  changeCategories = (categories) => {
    this.setState({
      ...this.state,
      selectedPerimeter: {
        ...this.state.selectedPerimeter,
        categories: categories,
      },
    });
  };

  unselectCategory = (index) => {
    let categories = this.state.selectedPerimeter.categories;
    categories[index].unselected = !categories[index].unselected;
    this.setState({
      ...this.state,
      selectedPerimeter: {
        ...this.state.selectedPerimeter,
        categories: categories,
      },
    });
  };

  showUnselectedCategories = (e) => {
    this.setState({
      showUnselectedCategories: e.target.checked,
    });
  };

  onScrolling = (e) => {
    this.setState({
      ...this.state,
      scrolling: e.target.scrollTop > 100 ? true : false,
    });
  };

  exportPerimeters = ()=>{
    this.handleShow(2)
  }
  doExportPerimeters = (fileExtension = '')=>{
    LoaderComponent.show()
    exportPerimeters().then((result) => {
        LoaderComponent.hide()
        if(fileExtension === 'csv'){
          const fileName = `Export-perimetres-${moment().format('DD-MM-YYYY')}.csv`;
          if (result) this.fileDownload(result.data, fileName);
        }
        if(fileExtension === 'xlsx'){
          const fileNameExcel = `Export-perimetres-${moment().format('DD-MM-YYYY')}.xlsx`;
          if (result) this.convertCsvToExcelBuffer(result.data, fileNameExcel)
        }
        this.handleClose()
    },
    error=>{
        LoaderComponent.hide()
        ErrorAlert.show(error.response.data, "Erreur API");
    });
  }

  fileDownload = (data, fileName) => {
      var universalBOM = '\uFEFF';
      var a = window.document.createElement('a');
      a.setAttribute(
        'href',
        'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + data)
      );
      a.setAttribute('download', fileName);
      window.document.body.appendChild(a);
      a.click();
    };

    convertCsvToExcelBuffer = (csvString, fileNameExcel) => {
      const arrayOfArrayCsv = csvString.split("\n").map((row) => {
        return row.split(";")
      });
      const wb = XLSX.utils.book_new();
      const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
      XLSX.utils.book_append_sheet(wb, newWs);
      const rawExcel = XLSX.write(wb, { type: 'base64' })
      console.log('rawExcel',rawExcel)
      XLSX.writeFile(wb, fileNameExcel);
      return rawExcel
    }

    handleShow = (stage = 0) => this.setState(
      {
        ...this.state,
        showModal: true,
        downloadStage: stage
      }
    );
  
    handleClose = () => this.setState(
      {
        ...this.state,
        showModal: false,
        downloadStage: 0,
        showResumeModal: false
      }
    );
  
    onDownloadCSVFile = () => {
      if (this.state.downloadStage === 1) this.doExportPerimeter('csv')
      if (this.state.downloadStage === 2) this.doExportPerimeters('csv')
    }
  
    onDownloadXLSXFile = () => {
      if (this.state.downloadStage === 1) this.doExportPerimeter('xlsx')
      if (this.state.downloadStage === 2) this.doExportPerimeters('xlsx')
    }

    editModeReminder = () => {
      ConfirmAlert.show(
        "Voulez-vous activer le mode d'édition pour effectuer des modifications ?",
        "Rappel",
        "Activer",
        "Annuler"
      ).then(() => {
        this.setState({
          ...this.state,
          editMode: true,
        });
      });
    };


  setFormPerimeter = (e, action) => {
    let name = e.target.name;
    let value = e.target.value;
    let formPerimeter = this.state.formPerimeter
    let findIndex = formPerimeter.findIndex(x => x.name === name);
    if (findIndex > -1) {
      if (Array.isArray(value)) {
        value = value.map(category => `${category.label}`).join('\n ');
      }
      formPerimeter[findIndex].value = value

      if (action === "edit") {
        formPerimeter[findIndex].edited = true
      } else {
        formPerimeter[findIndex].edited = false
      }
    } else {
      formPerimeter.push({
        name: name,
        value: value,
        isRequired: true
      })
    }
    this.setState({
      ...this.state,
      actionData: action,
      formPerimeter: formPerimeter
    })
  }

  doSave = () => {
    this.addNewPerimeter();
  }

  handleCancel = () => {
    document.getElementById("form-perimeter").reset();
    this.setState({
      ...this,
      isNewPerimeter: false,
      showFormAddPerimeter: false,
      selectedPerimeter: {},
      showResumeModal: false,
      actionData: "add", // add | edit | delete
      formPerimeter: [
        { name: "name", txtLabel: "Nom Périmètre", value: null, edited: false },
        { name: "designation", txtLabel: "Désignation", value: null, edited: false },
        { name: "categories", txtLabel: "Catégorie(s)", value: null, edited: false }
      ]
    });
  }

  render() {
    return (
      <div className="app-container backoffice-rights-access page-perimeter app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
        <Header />

        <div className="app-main">
          <div className="app-sidebar sidebar-shadow">
            <Sidebar />
          </div>
          <div className="app-main__outer">
            <EditMode
              isEditMode={this.state.editMode}
              onChange={(checked) => {
                this.changeEditMode(checked);
              }}
            />
            <div className="app-main__inner">
              <div className="row">
                <div
                  className={`col-sm-5 left-side ${
                    this.state.scrolling ? "scrolling" : ""
                  }`}
                >
                  {this.state.showModal &&
                    <Modal
                      title={"Export"}
                      isOpen={this.state.showModal}
                      onRequestClose={() => this.handleClose()}
                      footer={
                        <>
                          <button onClick={() => this.onDownloadCSVFile()}>
                            CSV
                          </button>
                          <button disabled={this.state.downloadStage === 1} onClick={() => this.onDownloadXLSXFile()}>
                            XLSX
                          </button>
                        </>
                      }
                      isFullScreen={false}
                    >
                      <div>
                        <p className="text-center font-weight-bold">
                          Veuillez choisir le format souhaité
                        </p>
                      </div>
                    </Modal>
                  }
                  <h1 className="side-menu-title-style">
                    <svg
                      width="43"
                      height="42"
                      viewBox="0 0 43 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.7924 15.3392L32.125 19.3125C33.3142 20.2044 34.0684 21.5478 34.2212 23.0098C35.5929 22.6766 37.0258 22.5 38.5 22.5H39.1224L34.5612 17.9388C34.1463 17.5239 34.1463 16.8511 34.5612 16.4362C34.9761 16.0213 35.6489 16.0213 36.0638 16.4362L42.4388 22.8112C42.8537 23.2261 42.8537 23.8989 42.4388 24.3138L36.0638 30.6888C35.6489 31.1037 34.9761 31.1037 34.5612 30.6888C34.1463 30.2739 34.1463 29.6011 34.5612 29.1862L39.1224 24.625H38.5C29.698 24.625 22.5625 31.7605 22.5625 40.5625C22.5625 41.1493 22.0868 41.625 21.5 41.625C20.9132 41.625 20.4375 41.1493 20.4375 40.5625C20.4375 31.7605 13.302 24.625 4.5 24.625H3.8776L8.4388 29.1862C8.85373 29.6011 8.85373 30.2739 8.4388 30.6888C8.02387 31.1037 7.35113 31.1037 6.9362 30.6888L0.561199 24.3138C0.146267 23.8989 0.146267 23.2261 0.561199 22.8112L6.9362 16.4362C7.35113 16.0213 8.02387 16.0213 8.4388 16.4362C8.85373 16.8511 8.85373 17.5239 8.4388 17.9388L3.8776 22.5H4.5C5.97418 22.5 7.40711 22.6766 8.77879 23.0098C8.93161 21.5478 9.68591 20.2043 10.876 19.3118L16.1968 15.3306C14.2482 13.7731 13 11.3761 13 8.6875C13 3.99308 16.8056 0.1875 21.5 0.1875C26.1944 0.1875 30 3.99308 30 8.6875C30 11.381 28.7472 13.7819 26.7924 15.3392ZM24.8158 16.5165C23.797 16.9485 22.6764 17.1875 21.5 17.1875C20.3174 17.1875 19.1913 16.946 18.1681 16.5096L12.15 21.0125C11.3474 21.6145 10.875 22.5592 10.875 23.5625C10.875 23.5939 10.8736 23.625 10.871 23.6557C15.8056 25.5161 19.7113 29.475 21.5 34.4444C23.2887 29.475 27.1944 25.5161 32.129 23.6557C32.1264 23.625 32.125 23.5939 32.125 23.5625C32.125 22.5592 31.6526 21.6145 30.8527 21.0145L24.8158 16.5165ZM21.5 15.0625C25.0208 15.0625 27.875 12.2083 27.875 8.6875C27.875 5.16668 25.0208 2.3125 21.5 2.3125C17.9792 2.3125 15.125 5.16668 15.125 8.6875C15.125 12.2083 17.9792 15.0625 21.5 15.0625Z"
                        fill="black"
                      />
                    </svg>
                    <span>{t("header.menu.rights_management")} :</span>
                    {t("backoffice.rights_management.perimeters.title")}
                  </h1>

                  <div className="sub-menu">
                    <BoRightsManagementMenu activeMenu="perimeters"></BoRightsManagementMenu>
                  </div>
                  <hr />
                  <div className="actions side-menu-actions">
                    <div className="sorting w-auto">
                      <button
                        onClick={() => {
                          this.exportPerimeters();
                        }}
                        className="btn btn-sorting"
                      >
                        <svg width="24" heght="24" viewBox="0 0 112 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M58.8333 66.3752L71.3543 53.8543C72.4933 52.7152 74.34 52.7152 75.4791 53.8543C76.6181 54.9933 76.6181 56.84 75.4791 57.9791L57.9791 75.4791C56.84 76.6181 54.9933 76.6181 53.8543 75.4791L36.3543 57.9791C35.2152 56.84 35.2152 54.9933 36.3543 53.8543C37.4933 52.7152 39.34 52.7152 40.4791 53.8543L53 66.3752V3.41667C53 1.80584 54.3058 0.5 55.9167 0.5C57.5275 0.5 58.8333 1.80584 58.8333 3.41667V66.3752ZM90.9167 18C89.3058 18 88 16.6942 88 15.0833C88 13.4725 89.3058 12.1667 90.9167 12.1667H96.75C104.804 12.1667 111.333 18.6958 111.333 26.75V85.0833C111.333 93.1375 104.804 99.6667 96.75 99.6667H15.0833C7.02918 99.6667 0.5 93.1375 0.5 85.0833V26.75C0.5 18.6958 7.02918 12.1667 15.0833 12.1667H20.9167C22.5275 12.1667 23.8333 13.4725 23.8333 15.0833C23.8333 16.6942 22.5275 18 20.9167 18H15.0833C10.2508 18 6.33333 21.9175 6.33333 26.75V85.0833C6.33333 89.9158 10.2508 93.8333 15.0833 93.8333H96.75C101.583 93.8333 105.5 89.9158 105.5 85.0833V26.75C105.5 21.9175 101.583 18 96.75 18H90.9167Z" 
                          fill="#FF8A00"/>
                        </svg>

                      </button>
                    </div>
                    <div className="sorting">
                      <button
                        onClick={() => {
                          this.sortPerimeters("name", "ASC");
                        }}
                        className="btn btn-sorting"
                      >
                        <svg
                          width="24"
                          height="21"
                          viewBox="0 0 24 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.64706 3.08294V20.2112C5.64706 20.554 5.33102 20.8318 4.94118 20.8318C4.55133 20.8318 4.23529 20.554 4.23529 20.2112V3.08294L1.20502 5.74683C0.929352 5.98916 0.482412 5.98916 0.206748 5.74683C-0.0689161 5.5045 -0.0689161 5.11159 0.206748 4.86926L4.44204 1.14605C4.71771 0.903711 5.16465 0.903711 5.44031 1.14605L9.67561 4.86926C9.95127 5.11159 9.95127 5.5045 9.67561 5.74683C9.39994 5.98916 8.953 5.98916 8.67734 5.74683L5.64706 3.08294ZM12 3.44644C11.6102 3.44644 11.2941 3.16861 11.2941 2.8259C11.2941 2.48319 11.6102 2.20537 12 2.20537H23.2941C23.684 2.20537 24 2.48319 24 2.8259C24 3.16861 23.684 3.44644 23.2941 3.44644H12ZM12 8.41072C11.6102 8.41072 11.2941 8.1329 11.2941 7.79019C11.2941 7.44747 11.6102 7.16965 12 7.16965H20.4706C20.8604 7.16965 21.1765 7.44747 21.1765 7.79019C21.1765 8.1329 20.8604 8.41072 20.4706 8.41072H12ZM12 13.375C11.6102 13.375 11.2941 13.0972 11.2941 12.7545C11.2941 12.4118 11.6102 12.1339 12 12.1339H17.6471C18.0369 12.1339 18.3529 12.4118 18.3529 12.7545C18.3529 13.0972 18.0369 13.375 17.6471 13.375H12ZM12 18.3393C11.6102 18.3393 11.2941 18.0615 11.2941 17.7188C11.2941 17.376 11.6102 17.0982 12 17.0982H14.8235C15.2134 17.0982 15.5294 17.376 15.5294 17.7188C15.5294 18.0615 15.2134 18.3393 14.8235 18.3393H12Z"
                            fill="#5367F4"
                          />
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          this.sortPerimeters("name", "DESC");
                        }}
                        className="btn btn-sorting"
                      >
                        <svg
                          width="24"
                          height="22"
                          viewBox="0 0 24 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.84923 20.9721L4.82229 20.9994L4.79982 21.0304C4.76761 21.0749 4.71905 21.1 4.66667 21.1C4.61428 21.1 4.56573 21.0749 4.53352 21.0304L4.51105 20.9994L4.48411 20.9721L0.550913 16.9918C0.483029 16.9231 0.483029 16.8093 0.550914 16.7406C0.61553 16.6752 0.717803 16.6752 0.78242 16.7406L3.64435 19.6368L4.5 20.5027V19.2854V0.674649C4.5 0.572566 4.58021 0.5 4.66667 0.5C4.75312 0.5 4.83333 0.572566 4.83333 0.674649V19.2854V20.5027L5.68898 19.6368L8.55091 16.7406C8.61553 16.6752 8.7178 16.6752 8.78242 16.7406C8.85031 16.8093 8.85031 16.9231 8.78242 16.9918L4.84923 20.9721ZM12.6667 2.19859C12.5802 2.19859 12.5 2.12603 12.5 2.02395C12.5 1.92186 12.5802 1.8493 12.6667 1.8493H23.3333C23.4198 1.8493 23.5 1.92186 23.5 2.02395C23.5 2.12603 23.4198 2.19859 23.3333 2.19859H12.6667ZM12.6667 7.59578C12.5802 7.59578 12.5 7.52322 12.5 7.42114C12.5 7.31905 12.5802 7.24649 12.6667 7.24649H20.6667C20.7531 7.24649 20.8333 7.31905 20.8333 7.42114C20.8333 7.52322 20.7531 7.59578 20.6667 7.59578H12.6667ZM12.6667 12.993C12.5802 12.993 12.5 12.9204 12.5 12.8183C12.5 12.7162 12.5802 12.6437 12.6667 12.6437H18C18.0865 12.6437 18.1667 12.7162 18.1667 12.8183C18.1667 12.9204 18.0865 12.993 18 12.993H12.6667ZM12.6667 18.3902C12.5802 18.3902 12.5 18.3176 12.5 18.2155C12.5 18.1134 12.5802 18.0409 12.6667 18.0409H15.3333C15.4198 18.0409 15.5 18.1134 15.5 18.2155C15.5 18.3176 15.4198 18.3902 15.3333 18.3902H12.6667Z"
                            fill="black"
                            stroke="#5367F4"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="search-input">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.7488 12.7482L19.0001 19"
                          stroke="#5367F4"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.88235 14.7647C11.6834 14.7647 14.7647 11.6834 14.7647 7.88235C14.7647 4.08133 11.6834 1 7.88235 1C4.08133 1 1 4.08133 1 7.88235C1 11.6834 4.08133 14.7647 7.88235 14.7647Z"
                          stroke="#5367F4"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <input
                        type="text"
                        placeholder=""
                        id="search-perimeters-input"
                        onKeyUp={(e) => {
                          this.searchPerimeters(e);
                        }}
                      />
                    </div>
                    <button
                      onClick={() => {
                        this.newPerimeter();
                      }}
                      className="btn-icon btn-pill btn btn-danger"
                    >
                      <svg
                        className="inner"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 7H1M7 1V13V1Z"
                          stroke="#FFF"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <svg
                        className="outer"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="36"
                          height="36"
                          rx="18"
                          fill="#5367F4"
                        ></rect>
                      </svg>
                      {t("common.add")}
                    </button>
                  </div>
                  <div
                    className={`scrolling-verticaly ${
                      this.state.showFormAddPerimeter ? "showFormAddRecord" : ""
                    }`}
                    onScroll={(e) => {
                      this.onScrolling(e);
                    }}
                  >
                    {this.state.showFormAddPerimeter && (
                      <div className="new-perimeter add-record">
                        <h2>
                          {this.state.selectedPerimeter.name ||
                            t(
                              "backoffice.rights_management.perimeters.perimeterName"
                            )}
                        </h2>
                        <div>
                          <button
                            onClick={() => {
                              if(this.state.formPerimeter.some(x=>x.value !== null && x.value !== undefined && x.value !== "" && x.value.length>0))
                                this.setState({...this.state, showResumeModal: true});
                              else{
                                document.getElementById("form-perimeter").reset();
                                this.setState({
                                  ...this,
                                  isNewPerimeter: false,
                                  selectedPerimeter: {},
                                });
                              }
                            }}
                            className="btn-pill btn btn-cancel"
                          >
                            {t("common.cancel")}
                          </button>
                          <button
                            onClick={() => {
                              if(this.state.actionData === "edit"){
                                this.setState({
                                  ...this,
                                  isNewPerimeter: false,
                                  showResumeModal: true
                                });
                              }else{
                                this.addNewPerimeter();
                              }
                            }}
                            className="btn-pill btn btn-danger btn-save"
                            disabled={!this.state.editMode || !this.state.selectedPerimeter.name}
                          >
                            {t("common.save")}
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="perimeters records">
                      {this.state.perimeters.map((Perimeter, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              this.viewPerimeterInfo(Perimeter, index);
                            }}
                            className={`perimeter side-menu-style mb-0 ${
                              index == this.state.selectedPerimeterIndex &&
                              !this.state.isNewPerimeter
                                ? "active"
                                : ""
                            }`}
                          >
                            <h3 className="title-stage-one">{Perimeter.name}</h3>
                            <p className="title-stage-two">
                              {(Perimeter.categories &&
                                Perimeter.categories.length) ||
                                0}{" "}
                              {t(
                                "backoffice.rights_management.perimeters.categories"
                              )}
                            </p>
                          </div>
                        );
                      })}
                      {this.state.perimeters.length == 0 && (
                        <div className="no-resut-found">
                          {t("common.no_result_found")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-sm-7 viewer">
                  <h1>
                    {this.state.selectedPerimeter.name ||
                      t(
                        "backoffice.rights_management.perimeters.perimeterName"
                      )}
                  </h1>
                  <div className={this.state.isNewPerimeter ? "hidden" : ""}>
                    <div className="formHead">
                      <div className="row">
                        <div className="col-sm-4 createdAt"></div>
                        <div className="col-sm-4 exportBtns">
                          {/* <button
                            onClick={() => {
                              this.exportPerimeter();
                            }}
                            className="btn btm-light"
                          >
                            <i className="lnr-download"></i>
                          </button> */}
                          {/* <button className="btn btm-light">
                            <i className="lnr-printer"></i>
                          </button> */}
                        </div>
                        <div className="col-sm-4 navigateBtns">
                          <button
                            onClick={() => {
                              this.viewPrevPerimeter();
                            }}
                            className="btn btm-light"
                          >
                            <i className="fa fa-angle-up"></i>
                          </button>
                          <span>
                            {parseInt(this.state.selectedPerimeterIndex) + 1}/
                            {this.state.perimeters.length}
                          </span>
                          <button
                            onClick={() => {
                              this.viewNextPerimeter();
                            }}
                            className="btn btm-light"
                          >
                            <i className="fa fa-angle-down"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <FormWithConstraints
                    ref={(formWithConstraints) =>
                      (this.form = formWithConstraints)
                    }
                    id="form-perimeter"
                    className={!this.state.editMode ? "consultation" : ""}
                    noValidate
                  >
                    <div
                      className="hideFormInputs"
                      onClick={this.editModeReminder}
                    ></div>
                    {!this.state.isNewPerimeter && this.state.editMode && (
                      <div className="row">
                        <div
                          className="col-lg-12"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            onClick={() => {
                              this.deletePerimeter();
                            }}
                            type="button"
                            className="btn btn-default deletePerimeter"
                          >
                            <svg
                              width="48"
                              height="48"
                              viewBox="0 0 48 48"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21.6622 40.7325L21.6819 40.7341H21.7017H22.3009H22.3207L22.3404 40.7325C23.3253 40.6545 24.2891 40.2356 25.0414 39.4779L26.4334 38.0759L26.7831 37.7237L26.4334 37.3714L10.8578 21.6837L10.5029 21.3264L10.1481 21.6837L8.75614 23.0857C7.07954 24.7744 7.07954 27.5108 8.75614 29.1994L18.9612 39.4779C19.7135 40.2356 20.6773 40.6545 21.6622 40.7325ZM26.0578 39.8818L25.2116 40.7341H26.4126H36.9797C37.2521 40.7341 37.4791 40.9575 37.4791 41.2406C37.4791 41.5238 37.2521 41.7471 36.9797 41.7471H22.3716H22.3556L22.3396 41.7482C22.1143 41.7626 21.8883 41.7626 21.663 41.7482L21.647 41.7471H21.631H10.9967C10.7243 41.7471 10.4974 41.5238 10.4974 41.2406C10.4974 40.9575 10.7243 40.7341 10.9967 40.7341H17.59H18.791L17.9448 39.8818L8.05249 29.9183C5.9825 27.8334 5.9825 24.4517 8.05249 22.3668L22.255 8.06217C24.323 5.97928 27.6744 5.97928 29.7424 8.06217L39.9475 18.3407C42.0175 20.4256 42.0175 23.8073 39.9475 25.8922L26.0578 39.8818ZM11.5614 20.2603L11.2116 20.6125L11.5614 20.9648L27.137 36.6525L27.4918 37.0099L27.8466 36.6525L39.2439 25.1733C40.9205 23.4846 40.9205 20.7482 39.2439 19.0596L29.0388 8.78106C27.3602 7.09041 24.6372 7.09041 22.9586 8.78106L11.5614 20.2603Z"
                                fill="black"
                                stroke="#EB3A59"
                              />
                              <rect
                                opacity="0.05"
                                width="48"
                                height="48"
                                rx="24"
                                fill="#292C41"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-lg-12">
                        <label>
                          {t(
                            "backoffice.rights_management.perimeters.perimeterName"
                          )}
                          *
                        </label>
                        <input
                          value={this.state.selectedPerimeter.name}
                          onChange={(e) => {
                            this.setFormPerimeter(e, this.state.actionData)
                            this.handleChangeInput(e);
                          }}
                          type="text"
                          name="name"
                          placeholder={t(
                            "backoffice.rights_management.perimeters.perimeterName"
                          )}
                          className="form-control"
                          required
                        />
                        <FieldFeedbacks for="name">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <label>{t("common.description")}</label>
                        <input
                          value={this.state.selectedPerimeter.designation}
                          onChange={(e) => {
                            this.setFormPerimeter(e, this.state.actionData)
                            this.handleChangeInput(e);
                          }}
                          type="text"
                          name="designation"
                          placeholder={t("common.description")}
                          className="form-control"
                          required
                        />
                        <FieldFeedbacks for="designation">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <label>
                          {t(
                            "backoffice.rights_management.perimeters.select_categories"
                          )}
                        </label>
                        <div className="multi-select-checkboxes">
                          <ReactMultiSelectCheckboxes
                            hideSelectedOptions={false}
                            placeholderButtonLabel={t("common.select")}
                            tabSelectsValue="true"
                            placeholder={t("common.search")}
                            value={this.state.selectedPerimeter.categories}
                            onChange={(list) => {
                              this.setFormPerimeter({target:{name:"categories", value:list}}, this.state.actionData)
                              this.changeCategories(list);
                            }}
                            className="organisation-options"
                            options={
                              this.state.showUnselectedCategories
                                ? this.state.unselectCategoriesList
                                : this.state.categories
                            }
                            getDropdownButtonLabel={selectedOption => {
                              if (selectedOption && selectedOption.value !== undefined) {
                                  if (selectedOption["value"].length == 0)
                                      return "Sélectionner"
                                  else if (selectedOption["value"].length == 1)
                                      return selectedOption["value"][0].label
                                  else
                                      return selectedOption["value"].length + " séléctionnées"
                              } else {
                                  return "Sélectionner"
                              }
                          }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        {
                          /*this.state.selectedPerimeter.categories && (this.state.categories.length - this.state.selectedPerimeter.categories.length > 0) &&*/
                          this.state.unselectCategories > 0 && (
                            <p className="unselected-categories-text">
                              <div className="custom-checkbox">
                                <label>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: t(
                                        "backoffice.rights_management.perimeters.not_linked_categories",
                                        {
                                          nbr_categories:
                                            this.state.unselectCategories,
                                        }
                                      ),
                                    }}
                                  ></span>
                                  <br />
                                  {t("common.display")}{" "}
                                  {
                                    <input
                                      type="checkbox"
                                      onChange={(e) => {
                                        this.showUnselectedCategories(e);
                                      }}
                                      checked={
                                        this.state.showUnselectedCategories
                                      }
                                    ></input>
                                  }
                                  {
                                    <span className="cr">
                                      <i className="cr-icon fa fa-check"></i>
                                    </span>
                                  }
                                </label>
                              </div>
                            </p>
                          )
                        }
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label>{t("backoffice.rights_management.perimeters.perimeter_categories")}</label>
                      </div>
                      <div className="col-lg-12 selectedOrganisations">
                        {this.state.selectedPerimeter.categories &&
                          this.state.selectedPerimeter.categories.map(
                            (category, index) => {
                              return (
                                <button
                                  onClick={() => {
                                    this.unselectCategory(index);
                                  }}
                                  type="button"
                                  className={`mb-2 mr-2 btn-icon btn-pill btn btn-secondary ${
                                    category.unselected ? "unselected" : ""
                                  }`}
                                >
                                  {category.label}
                                  <svg
                                    width="52"
                                    height="52"
                                    viewBox="0 0 52 52"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="0.542969"
                                      y="26"
                                      width="36"
                                      height="36"
                                      rx="18"
                                      transform="rotate(-45 0.542969 26)"
                                      fill="#F66031"
                                    />
                                    <path
                                      d="M30.2416 21.7574L21.7563 30.2426M21.7563 21.7574L30.2416 30.2426L21.7563 21.7574Z"
                                      stroke="white"
                                      strokeWidth="2"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </FormWithConstraints>
                </div>
              </div>
            </div>
          </div>
          <ResumeDataModal title={"Confirmez vos données saisie"}
            isOpen={this.state.showResumeModal}
            onRequestClose={() => this.handleClose()}
            onRequestCancel={() => this.handleCancel()}
            requiredFields={this.state.actionData === "add" ? this.state.formPerimeter.length : 0}
            doSave={() => this.doSave()}
            fields={this.state.actionData === "add" ? this.state.formPerimeter : this.state.formPerimeter.filter(x => x.edited)}
            action={this.state.actionData}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(Perimeter);
