import React, { Component } from 'react'; 
import { BrowserRouter as Router, Link } from "react-router-dom";

import './Subscription.scss'

// components
import Header from '../../../../components/layouts/header/Header'
import Sidebar from '../../../../components/layouts/sidebar/Sidebar' 

// plugins
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider'
import { SliderRail, Handle, Track, Tick } from './Components' // example render components - source below
import Chart from "react-google-charts";



class SubscriptionBackOffice extends Component {

    constructor (props) { 
        super(props);    
        this.state = {
            editMode: true, 


            chartMode: 'quarterly',
            yearlyChartData : [
                ['', ''],
                ['2016', 100],
                ['2017', 200],
                ['2018', 300],
                ['2019', 400],
                ['2020', 500],
            ],
            quarterlyChartData :[
                ['', ''],
                ['T1/18', 10],
                ['T2/18', 30],
                ['T3/18', 45],
                ['T4/18', 70],

                ['T1/19', 10],
                ['T2/19', 30],
                ['T3/19', 45],
                ['T4/19', 70],

                ['T1/20', 10],
                ['T2/20', 30],
                ['T3/20', 45],
                ['T4/20', 70],
            ]
        } 
 
    }   

    changeGraphMode = (isYearly) => { 
        setTimeout(()=>{
            this.setState({
                ...this.state,
                chartMode: isYearly ? 'yearly' : 'quarterly'
            })
        })
    }
    changeEditMode = (checked) => { 
        this.setState({
            ...this,
            editMode: checked
        }) 
    }








    export = ()=>{
        // to do
    }
    print = ()=>{
        // to do
    }

    render() {
        const sliderStyle = {
            position: 'relative',
            width: '100%',
          }
        return (
            <div className="app-container page-subscription app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
                <Header />

                <div className="app-main">
                    <div className="app-sidebar sidebar-shadow">
                        <Sidebar />
                    </div>
                    <div className="app-main__outer">
                        <div className={ `bg-under-header editModeTitle ${this.state.editMode ? 'editMode' : ''}` } >
                           <span> Mode édition</span>
                        </div>
                        <div className="app-main__inner"> 

                            <div className="row">
                                <div className="col-sm-5 left-side">
                                    <h1>
                                        <svg width="35" height="47" viewBox="0 0 35 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M24.9375 23.5H28.125V5.4375C28.125 3.67709 26.6979 2.25 24.9375 2.25H5.8125C4.05209 2.25 2.625 3.67709 2.625 5.4375V23.5H5.8125V22.7188C5.8125 20.8527 6.79159 19.1235 8.39174 18.1634L10.4529 16.9267C9.54518 15.8252 9 14.4138 9 12.875C9 9.35419 11.8542 6.5 15.375 6.5C18.8958 6.5 21.75 9.35419 21.75 12.875C21.75 14.4138 21.2048 15.8252 20.2971 16.9267L22.3583 18.1634C23.9584 19.1235 24.9375 20.8527 24.9375 22.7188V23.5ZM22.8125 23.5V22.7188C22.8125 21.5992 22.225 20.5616 21.265 19.9856L18.5905 18.3809C17.6465 18.9333 16.5477 19.25 15.375 19.25C14.2023 19.25 13.1035 18.9333 12.1595 18.3809L9.48504 19.9856C8.52495 20.5616 7.9375 21.5992 7.9375 22.7188V23.5H22.8125ZM28.125 25.625H2.625V37.3125C2.625 39.0729 4.05209 40.5 5.8125 40.5H24.9375C26.6979 40.5 28.125 39.0729 28.125 37.3125V25.625ZM30.161 4.464C32.6301 4.92126 34.5 7.08601 34.5 9.6875V41.5625C34.5 44.4965 32.1215 46.875 29.1875 46.875H10.0625C7.46101 46.875 5.29626 45.0051 4.839 42.536C2.36991 42.0787 0.5 39.914 0.5 37.3125V5.4375C0.5 2.50349 2.87849 0.125 5.8125 0.125H24.9375C27.539 0.125 29.7037 1.99491 30.161 4.464ZM7.05638 42.625C7.49395 43.863 8.67464 44.75 10.0625 44.75H29.1875C30.9479 44.75 32.375 43.3229 32.375 41.5625V9.6875C32.375 8.29964 31.488 7.11895 30.25 6.68138V37.3125C30.25 40.2465 27.8715 42.625 24.9375 42.625H7.05638ZM15.375 17.125C17.7222 17.125 19.625 15.2222 19.625 12.875C19.625 10.5278 17.7222 8.625 15.375 8.625C13.0278 8.625 11.125 10.5278 11.125 12.875C11.125 15.2222 13.0278 17.125 15.375 17.125ZM7.9375 32C7.3507 32 6.875 31.5243 6.875 30.9375C6.875 30.3507 7.3507 29.875 7.9375 29.875H22.8125C23.3993 29.875 23.875 30.3507 23.875 30.9375C23.875 31.5243 23.3993 32 22.8125 32H7.9375ZM7.9375 36.25C7.3507 36.25 6.875 35.7743 6.875 35.1875C6.875 34.6007 7.3507 34.125 7.9375 34.125H18.5625C19.1493 34.125 19.625 34.6007 19.625 35.1875C19.625 35.7743 19.1493 36.25 18.5625 36.25H7.9375Z" fill="black"/>
                                        </svg> 
                                        Fiche Entreprise
                                    </h1>

                                    <div className="edit-mode">
                                        <BootstrapSwitchButton
                                            onChange={(checked)=>{this.changeEditMode(checked)}} 
                                            checked={this.state.editMode} 
                                            />
                                        { this.state.editMode ? 'Désactiver' : 'Activer' } le mode édition
                                    </div>

                                    <div className="sub-menu">
                                        <ul>
                                            <li>
                                                <Link to="/back-office/home">
                                                    <i className="fa fa-home"></i>
                                                    Accueil
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <hr /> 

                                    <div className="actions">
                                        <div className="sorting">
                                            <button onClick={()=>{ this.sortClients('name', 'ASC') }} className="btn btn-sorting">
                                                <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.64706 3.08294V20.2112C5.64706 20.554 5.33102 20.8318 4.94118 20.8318C4.55133 20.8318 4.23529 20.554 4.23529 20.2112V3.08294L1.20502 5.74683C0.929352 5.98916 0.482412 5.98916 0.206748 5.74683C-0.0689161 5.5045 -0.0689161 5.11159 0.206748 4.86926L4.44204 1.14605C4.71771 0.903711 5.16465 0.903711 5.44031 1.14605L9.67561 4.86926C9.95127 5.11159 9.95127 5.5045 9.67561 5.74683C9.39994 5.98916 8.953 5.98916 8.67734 5.74683L5.64706 3.08294ZM12 3.44644C11.6102 3.44644 11.2941 3.16861 11.2941 2.8259C11.2941 2.48319 11.6102 2.20537 12 2.20537H23.2941C23.684 2.20537 24 2.48319 24 2.8259C24 3.16861 23.684 3.44644 23.2941 3.44644H12ZM12 8.41072C11.6102 8.41072 11.2941 8.1329 11.2941 7.79019C11.2941 7.44747 11.6102 7.16965 12 7.16965H20.4706C20.8604 7.16965 21.1765 7.44747 21.1765 7.79019C21.1765 8.1329 20.8604 8.41072 20.4706 8.41072H12ZM12 13.375C11.6102 13.375 11.2941 13.0972 11.2941 12.7545C11.2941 12.4118 11.6102 12.1339 12 12.1339H17.6471C18.0369 12.1339 18.3529 12.4118 18.3529 12.7545C18.3529 13.0972 18.0369 13.375 17.6471 13.375H12ZM12 18.3393C11.6102 18.3393 11.2941 18.0615 11.2941 17.7188C11.2941 17.376 11.6102 17.0982 12 17.0982H14.8235C15.2134 17.0982 15.5294 17.376 15.5294 17.7188C15.5294 18.0615 15.2134 18.3393 14.8235 18.3393H12Z" fill="#5367F4"/>
                                                </svg> 
                                            </button>
                                            <button onClick={()=>{ this.sortClients('name', 'DESC') }} className="btn btn-sorting">
                                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.84923 20.9721L4.82229 20.9994L4.79982 21.0304C4.76761 21.0749 4.71905 21.1 4.66667 21.1C4.61428 21.1 4.56573 21.0749 4.53352 21.0304L4.51105 20.9994L4.48411 20.9721L0.550913 16.9918C0.483029 16.9231 0.483029 16.8093 0.550914 16.7406C0.61553 16.6752 0.717803 16.6752 0.78242 16.7406L3.64435 19.6368L4.5 20.5027V19.2854V0.674649C4.5 0.572566 4.58021 0.5 4.66667 0.5C4.75312 0.5 4.83333 0.572566 4.83333 0.674649V19.2854V20.5027L5.68898 19.6368L8.55091 16.7406C8.61553 16.6752 8.7178 16.6752 8.78242 16.7406C8.85031 16.8093 8.85031 16.9231 8.78242 16.9918L4.84923 20.9721ZM12.6667 2.19859C12.5802 2.19859 12.5 2.12603 12.5 2.02395C12.5 1.92186 12.5802 1.8493 12.6667 1.8493H23.3333C23.4198 1.8493 23.5 1.92186 23.5 2.02395C23.5 2.12603 23.4198 2.19859 23.3333 2.19859H12.6667ZM12.6667 7.59578C12.5802 7.59578 12.5 7.52322 12.5 7.42114C12.5 7.31905 12.5802 7.24649 12.6667 7.24649H20.6667C20.7531 7.24649 20.8333 7.31905 20.8333 7.42114C20.8333 7.52322 20.7531 7.59578 20.6667 7.59578H12.6667ZM12.6667 12.993C12.5802 12.993 12.5 12.9204 12.5 12.8183C12.5 12.7162 12.5802 12.6437 12.6667 12.6437H18C18.0865 12.6437 18.1667 12.7162 18.1667 12.8183C18.1667 12.9204 18.0865 12.993 18 12.993H12.6667ZM12.6667 18.3902C12.5802 18.3902 12.5 18.3176 12.5 18.2155C12.5 18.1134 12.5802 18.0409 12.6667 18.0409H15.3333C15.4198 18.0409 15.5 18.1134 15.5 18.2155C15.5 18.3176 15.4198 18.3902 15.3333 18.3902H12.6667Z" fill="black" stroke="#5367F4"/>
                                                </svg> 
                                            </button>
                                        </div>
                                        <div className="search-input"> 
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.7488 12.7482L19.0001 19" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M7.88235 14.7647C11.6834 14.7647 14.7647 11.6834 14.7647 7.88235C14.7647 4.08133 11.6834 1 7.88235 1C4.08133 1 1 4.08133 1 7.88235C1 11.6834 4.08133 14.7647 7.88235 14.7647Z" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg> 
                                            <input type="text" placeholder="" onKeyUp={(e)=>{this.searchClient(e)}} />
                                        </div> 
                                    </div>

                                    <div className="records">
                                        
                                        <div className="record active">
                                            <h2>Picard</h2>
                                            <div className="sub-records">
                                                <div className="sub-record">
                                                    <h3>Extension 10 contrats/100 pages</h3>
                                                    <p>18 Avril 2020 - 60 contrats/600 pages</p> 
                                                </div>
                                                <div className="sub-record">
                                                    <h3>Ajout Utilisateurs</h3>
                                                    <p>18 Avril 2020 - 2 Utilisateurs ajoutés</p> 
                                                </div>
                                                <div className="sub-record">
                                                    <h3>Pack Pro</h3>
                                                    <p>3 Juillet 2019 - 50 contrats/500 pages</p> 
                                                </div>
                                                <div className="sub-record">
                                                    <h3>Starter Pack</h3>
                                                    <p>13 Mars 2019 - 15 contrats/150 pages</p> 
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="record">
                                            <h2>Auchan</h2>
                                            <div className="sub-records">
                                                <div className="sub-record">
                                                    <h3>Extension 10 contrats/100 pages</h3>
                                                    <p>18 Avril 2020 - 60 contrats/600 pages</p> 
                                                </div>
                                                <div className="sub-record">
                                                    <h3>Ajout Utilisateurs</h3>
                                                    <p>18 Avril 2020 - 2 Utilisateurs ajoutés</p> 
                                                </div>
                                                <div className="sub-record">
                                                    <h3>Pack Pro</h3>
                                                    <p>3 Juillet 2019 - 50 contrats/500 pages</p> 
                                                </div> 
                                            </div>
                                        </div> 
                                        <div className="record">
                                            <h2>Célio</h2>
                                            <div className="sub-records">
                                                <div className="sub-record">
                                                    <h3>Extension 10 contrats/100 pages</h3>
                                                    <p>18 Avril 2020 - 60 contrats/600 pages</p> 
                                                </div>
                                                <div className="sub-record">
                                                    <h3>Ajout Utilisateurs</h3>
                                                    <p>18 Avril 2020 - 2 Utilisateurs ajoutés</p> 
                                                </div> 
                                            </div>
                                        </div> 
                                    </div>

                                     
                                </div>

                                <div className="col-sm-7 viewer">

                                    <h2>Montant Actuel : 112,40 € HT</h2>
                                    
                                    <div className="head">
                                        <button onClick={()=>{this.export()}} className="btn btm-light"><i className="lnr-download"></i></button>
                                        <button onClick={()=>{this.print()}} className="btn btm-light"><i className="lnr-printer"></i></button>
                                    </div> 

                                    <section className="regulations-information">
                                        <h3>Informations règlements</h3>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <strong>Echéances :</strong>
                                            </div>
                                            <div className="col-sm-9">
                                                <div className="switchButton">
                                                    <label>Mensuelles</label>
                                                        <BootstrapSwitchButton/>
                                                    <label>Annuelles (remise <input type="text" className="form-control shortInput" value="3"></input>%)</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3">
                                                <strong>Type règlement :</strong>
                                            </div>
                                            <div className="col-sm-9">
                                                <div className="switchButton">
                                                    <label>Autre</label>
                                                        <BootstrapSwitchButton/>
                                                    <label>Prélèvements (remise <input type="text" className="form-control shortInput" value="3" />%)</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3">
                                                <strong>Engagement</strong>
                                            </div>
                                            <div className="col-sm-9">
                                                <div className="switchButton">
                                                    <label>3 ans</label>
                                                        <BootstrapSwitchButton/>
                                                    <label>5 ans (remise <input type="text" className="form-control shortInput" value="100"/>%)</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <strong>Remise Commerciale <input type="text" className="form-control shortInput" value="5"/> %</strong>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="subscription">
                                        <h3>Sélection abonnement</h3>

                                        <div className="switchButton disabled"> 
                                            <BootstrapSwitchButton/>
                                            <label>Starter Pack  (15 contrats ou 150 pages*)  pour <input type="text" className="form-control shortInput" value="26.18"/> € HT/mois*</label>
                                        </div>

                                        <div className="switchButton disabled"> 
                                            <BootstrapSwitchButton/>
                                            <label>Starter Pack  Avancé (30 contrats ou 300 pages) pour <input type="text" className="form-control shortInput" value="52.35"/> € HT/mois*</label>
                                        </div>

                                        
                                        
                                        <div className="switchButton rangeInputContainer"> 
                                            <BootstrapSwitchButton checked={true}/>
                                            <label>Pack  Pro 50 (50 contrats ou 500 pages) pour <strong><input type="text" className="form-control shortInput" value="123.00"/> €HT/mois*</strong></label>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <label className="rangelabel">
                                                        Extension
                                                    </label>
                                                </div>
                                                <div className="col-sm-8">
                                                <Slider
                                                    className="rangeInput"
                                                    mode={2}
                                                    step={5}
                                                    domain={[0, 35]}
                                                    rootStyle={sliderStyle}
                                                    onUpdate={this.onUpdate}
                                                    onChange={this.onChange}
                                                    values={[10]}
                                                    >
                                                    <Rail>
                                                        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                                                    </Rail>
                                                    <Handles>
                                                        {({ handles, getHandleProps }) => (
                                                        <div className="slider-handles">
                                                            {handles.map(handle => (
                                                            <Handle
                                                                key={handle.id}
                                                                handle={handle}
                                                                domain={[0, 35]}
                                                                getHandleProps={getHandleProps}
                                                            />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Handles>
                                                    <Tracks right={false}>
                                                        {({ tracks, getTrackProps }) => (
                                                        <div className="slider-tracks">
                                                            {tracks.map(({ id, source, target }) => (
                                                            <Track
                                                                key={id}
                                                                source={source}
                                                                target={target}
                                                                getTrackProps={getTrackProps}
                                                            />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Tracks>
                                                    <Ticks count={5}>
                                                        {({ ticks }) => (
                                                        <div className="slider-ticks">
                                                            {ticks.map(tick => (
                                                            <Tick key={tick.id} tick={tick} count={ticks.length} />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Ticks>
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>




                                        <div className="switchButton rangeInputContainer"> 
                                            <BootstrapSwitchButton checked={true}/>
                                            <label>Pack  Pro 100 (100 contrats ou 1 000 pages) à partir de <input type="text" className="form-control shortInput" value="143.18"/> €/mois*</label>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <label className="rangelabel">
                                                        Extension
                                                    </label>
                                                </div>
                                                <div className="col-sm-8">
                                                <Slider
                                                    className="rangeInput"
                                                    mode={2}
                                                    step={10}
                                                    domain={[0, 70]}
                                                    rootStyle={sliderStyle}
                                                    onUpdate={this.onUpdate}
                                                    onChange={this.onChange}
                                                    values={[0]}
                                                    >
                                                    <Rail>
                                                        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                                                    </Rail>
                                                    <Handles>
                                                        {({ handles, getHandleProps }) => (
                                                        <div className="slider-handles">
                                                            {handles.map(handle => (
                                                            <Handle
                                                                key={handle.id}
                                                                handle={handle}
                                                                domain={[0, 70]}
                                                                getHandleProps={getHandleProps}
                                                            />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Handles>
                                                    <Tracks right={false}>
                                                        {({ tracks, getTrackProps }) => (
                                                        <div className="slider-tracks">
                                                            {tracks.map(({ id, source, target }) => (
                                                            <Track
                                                                key={id}
                                                                source={source}
                                                                target={target}
                                                                getTrackProps={getTrackProps}
                                                            />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Tracks>
                                                    <Ticks count={5}>
                                                        {({ ticks }) => (
                                                        <div className="slider-ticks">
                                                            {ticks.map(tick => (
                                                            <Tick key={tick.id} tick={tick} count={ticks.length} />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Ticks>
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="switchButton rangeInputContainer"> 
                                            <BootstrapSwitchButton checked={true}/>
                                            <label>Pack  Pro 200 (200 contrats ou 2 000 pages) à partir de <input type="text" className="form-control shortInput" value="246.00"/>,00 € HT/mois*</label>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <label className="rangelabel">
                                                        Extension
                                                    </label>
                                                </div>
                                                <div className="col-sm-8">
                                                <Slider
                                                    className="rangeInput"
                                                    mode={2}
                                                    step={20}
                                                    domain={[0, 140]}
                                                    rootStyle={sliderStyle}
                                                    onUpdate={this.onUpdate}
                                                    onChange={this.onChange}
                                                    values={[0]}
                                                    >
                                                    <Rail>
                                                        {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                                                    </Rail>
                                                    <Handles>
                                                        {({ handles, getHandleProps }) => (
                                                        <div className="slider-handles">
                                                            {handles.map(handle => (
                                                            <Handle
                                                                key={handle.id}
                                                                handle={handle}
                                                                domain={[0, 140]}
                                                                getHandleProps={getHandleProps}
                                                            />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Handles>
                                                    <Tracks right={false}>
                                                        {({ tracks, getTrackProps }) => (
                                                        <div className="slider-tracks">
                                                            {tracks.map(({ id, source, target }) => (
                                                            <Track
                                                                key={id}
                                                                source={source}
                                                                target={target}
                                                                getTrackProps={getTrackProps}
                                                            />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Tracks>
                                                    <Ticks count={5}>
                                                        {({ ticks }) => (
                                                        <div className="slider-ticks">
                                                            {ticks.map(tick => (
                                                            <Tick key={tick.id} tick={tick} count={ticks.length} />
                                                            ))}
                                                        </div>
                                                        )}
                                                    </Ticks>
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="switchButton"> 
                                            <BootstrapSwitchButton/>
                                            <label>Pack  illimité (sans limite d‘utilisateurs, de contrats ou de pages) </label> 
                                            <p className="pack-description">Cette offre vous reviendrait à <input type="text" className="form-control shortInput" value="1.20"/> % du montant annuel** de tous vos contrats soit <strong>232,00 € HT/mois*</strong></p>
                                        </div>

                                        <div className="switchButton"> 
                                            <BootstrapSwitchButton/>
                                            <label>Offre Personnalisée : <input type="text" className="form-control shortInput" value="1.20"/> Contrats <input type="text" className="form-control shortInput" value="750"/> € HT/Mois (hors remise) </label> 
                                        </div>
 

                                        <div className="update-subscription">
                                            <button className="btn btn-large btn-primary">Modifier l’abonnement</button>
                                        </div>

                                    </section>

                                    <section className="manage-users">
                                        <h3>Gestion des utilisateurs</h3>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                Nombre d’utilisateurs Max : 7
                                            </div>
                                            <div className="col-sm-6">
                                                Nombre d’utilisateurs créés : 5
                                            </div>
                                        </div>
                                        <form>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    Demande d’utilisateurs supplémentaires :
                                                </div>
                                                <div className="col-sm-6">
                                                   <input type="number" className="form-control" value="3"></input> x <input type="text" className="form-control shortInput" value="10.00"/> € HT/mois
                                                </div>
                                                <div className="col-sm-12 add-users">
                                                        <button className="btn btn-large btn-primary">Modifier l’abonnement</button>
                                                </div>
                                            </div>
                                        </form> 
                                    </section>


                                    <section className="graphs">
                                        <h3>Evolution d'abonnement</h3>
                                        {
                                            this.state?.chartMode == 'quarterly' && 
                                            <Chart
                                                width={'100%'}
                                                height={400}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.state.quarterlyChartData}
                                                options={{
                                                    title: '',
                                                    chartArea: { width: '80%' },
                                                    hAxis: {
                                                        title: 'Montant abonnement',
                                                        minValue: null,
                                                    },
                                                    vAxis: {
                                                        title: 'En (€)',
                                                    },
                                                    titleTextStyle: { color: '#7077A5' } ,
                                                    colors: ['#F66031']
                                                }}
                                                legendToggle
                                            />
                                        }
                                        {
                                            this.state?.chartMode == 'yearly' &&  
                                            <Chart
                                                width={'100%'}
                                                height={400}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.state.yearlyChartData}
                                                options={{
                                                    title: '',
                                                    chartArea: { width: '80%' },
                                                    hAxis: {
                                                        title: 'Montant abonnement',
                                                        minValue: null,
                                                    },
                                                    vAxis: {
                                                        title: 'En (€)',
                                                    },
                                                    titleTextStyle: { color: '#7077A5' } ,
                                                    colors: ['#F66031']
                                                }}
                                                legendToggle
                                            />
                                        }
                                    
                                        <center className="switchButton toggleColored">
                                            <label>Par Trimestres</label>
                                                <BootstrapSwitchButton checked={this.state?.chartMode == 'yearly' }  onChange={(checked)=>{this.changeGraphMode(checked)}} />
                                            <label>Par ans</label>
                                        </center>
                                        
                                        <center>
                                            <p>
                                                Doc @t Web vous permet de sécuriser un montant Total de contrat de <br />
                                                <strong>214 240 €</strong>
                                            </p>
                                        </center>

                                    </section>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SubscriptionBackOffice;