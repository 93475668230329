import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import {saveClient, getClients, updateClient, calculateSubscriptionEndingDate, deleteClient, getArchiveHistoryByClient, exportArchiveHistoryByClient} from "../../../../api/client";
import './Companies.scss'
import ErrorAlert from "../../../../components/confirm-alert/error-alert"
import { withRouter } from 'react-router-dom';

// components
import Header from '../../../../components/layouts/header/Header'
import Sidebar from '../../../../components/layouts/sidebar/Sidebar'
import ConfirmAlert from '../../../../components/confirm-alert/ConfirmAlert'
import LoaderComponent from '../../../../components/loader/Loader'
import NotifyAlert from '../../../../components/confirm-alert/notify-alert/NotifyAlert'
import EditMode from '../../../../components/edit-mode/EditMode';

// plugins
import * as moment from 'moment/min/moment-with-locales';
import Moment from "moment";
import DatePicker from "react-datepicker";
import Switcher from "../../../../components/switcher/Switcher";
import {calculateTheContractEndingDateAndDuration} from "../../../../api/validateContract";
import {deleteDocAtWebKeyword, getDocAtWebKeywords} from "../../../../api/keyword";import DataTable from '../../../../components/dataTable/DataTable';
 moment.locale('fr-fr');
const ObjectsToCsv = require('objects-to-csv');

// form validation
const ReactFormWithConstraints =  require('react-form-with-constraints');
const { FormWithConstraints, FieldFeedbacks, FieldFeedback } = ReactFormWithConstraints;

class Companies extends Component {

    constructor (props) {
        super(props);
        this.state = {
            default: 123,
            showFormAddCompany: false,
            editMode: false,
            companies: [],
            selectedCompany: {},
            selectedCompanyIndex: null,
            isNewCompany: true,
            companiesFetched: false,
            isBillingAddressChecked: false,
            scrolling: false,
            idemCommercialIsChecked: false,
            signMethod: null,
            selectedCompanyAdminIndex: 0,
            idemCommercialIsCheckedIndexList:[]
        }

        this.allCompanies = this.state.companies;
    }

    componentDidMount() {
        LoaderComponent.show();
        getClients().then((response) => {
            LoaderComponent.hide();
            this.setState({
            ...this,
            companies: response.data,
            companiesFetched: true,
        }, () => {
            this.allCompanies = [...this.state.companies]
        }) });
    }

    changeEditMode = (checked) => {
        this.setState({
            ...this,
            editMode: checked
        })
    }

    exitForm = (companyIndex=null) => {
        // console.log("From", this.state.selectedCompanyIndex)
        // console.log("Move to: ",companyIndex)

        let notEditedObj = false;
        if(this.state.selectedCompanyIndex !== null){
            // compare old and edited object
            notEditedObj = this.deepCompareObjects(this.state.companies[this.state.selectedCompanyIndex],this.state.selectedCompany)
        }
        return new Promise((resolve, reject) => {

            if( !this.state.showFormAddCompany || !this.state.editMode || Object.keys(this.state.selectedCompany).length == 0 || notEditedObj){
                resolve();
            }else{
                if( this.state.selectedCompany !== this.state.companies[this.state.selectedCompanyIndex] ){
                    ConfirmAlert.show("Voulez vous vraiment faire cette action ?").then(resolve, reject);
                }else{
                    resolve();
                }
            }
        })
    }

    // Function to compare two objects
    deepCompareObjects = (obj1, obj2) => {
        // Check if both values are null or undefined
        if (obj1 === null && obj2 === null) {
            return true;
        }

        // If only one of the values is null or undefined, they are not equal
        if (obj1 === null || obj2 === null || obj1 === undefined || obj2 === undefined) {
            return false;
        }
        // console.log("obj1", obj1)
        // console.log("obj2", obj2)

        // Get the keys of both objects
        var keys1 = Object.keys(obj1);
        var keys2 = Object.keys(obj2);
        keys1 = keys1.filter(item => item !== 'archiveHistoList');
        keys2 = keys2.filter(item => item !== 'archiveHistoList');
        // console.log("keys1", keys1)
        // console.log("keys2", keys2)

        // If the number of keys is different, objects are not equal
        if (keys1.length !== keys2.length) {
            return false;
        }

        // Loop through all keys and compare their values
        for (var i = 0; i < keys1.length; i++) {
            var key = keys1[i];
            var val1 = obj1[key];
            var val2 = obj2[key];

            // Check if both values are objects
            if (typeof val1 === 'object' && typeof val2 === 'object') {
                // If both values are arrays, compare them element by element
                if (Array.isArray(val1) && Array.isArray(val2)) {
                    if (!this.arraysAreEqual(val1, val2)) {
                        return false;
                    }
                } else {
                    // Recursively compare nested objects
                    if (!this.deepCompareObjects(val1, val2)) {
                        return false;
                    }
                }
            } else {
                // Compare primitive values
                if (val1 !== val2) {
                    return false;
                }
            }
        }

        // If all keys and values are the same, objects are equal
        return true;
    }

    arraysAreEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (var i = 0; i < arr1.length; i++) {
            if (!this.deepCompareObjects(arr1[i], arr2[i])) {
                return false;
            }
        }
        return true;
    }


    newCompany = () => {
        this.exitForm().then(()=>{
            document.getElementById("form-company").reset();
            this.setState({
                ...this,
                isNewCompany: true,
                showFormAddCompany: true,
                selectedCompany:{
                    companyName: ""
                }
            })
        })
    }

    cancelNewCompany = () => {
        this.exitForm().then(()=>{
            document.getElementById("form-company").reset();
            this.setState({
                ...this,
                isNewCompany: true,
                showFormAddCompany: false,
                selectedCompany: {
                    companyName: ""
                },
                isBillingAddressChecked: false,
                selectedCompanyAdminIndex: 0,
                idemCommercialIsCheckedIndexList:[],
                selectedCompanyIndex: null
            })
        })
    }

    isJSON = (val) => {
        try {
          JSON.parse(val);
          return true;
        } catch (error) {
          return false;
        }
      }

    handleChangeInput = e => {
        if( !this.state.selectedCompany.hasOwnProperty('companyName') ){
            this.setState({
                ...this,
                isNewCompany: true,
                showFormAddCompany: true
            })
        }
        this.form.validateFields(e.target);
        let domain_name = this.state.selectedCompany["domainNames"] || [];
        if (this.state && this.state.selectedCompany && this.state.selectedCompany !== undefined && this.state.selectedCompany["domainNames"] !== undefined && this.state.selectedCompany["domainNames"] && this.isJSON(this.state.selectedCompany["domainNames"])) {
            const parsedDomainName = JSON.parse(this.state.selectedCompany["domainNames"])
            if (parsedDomainName && parsedDomainName.length > 0) {
                domain_name = parsedDomainName.map(domain => { return { label: domain } }).filter(x => !x.unselected || x.unselected === false)
            }
        }

        if((['adminLastName','adminFirstName','adminPhoneNumber','adminHomePhoneNumber','adminExtraPhoneNumber','AdminEmail']).includes(e.target.name)){
            let tmpClientAdmins = this.state.selectedCompany.clientAdmins
            let str = e.target.name;
            let modifiedStr = str.slice(5)

             modifiedStr = (modifiedStr.slice(0, 1)).toLowerCase() + modifiedStr.slice(1)

             console.log(modifiedStr)

            tmpClientAdmins[this.state.selectedCompanyAdminIndex][modifiedStr] = e.target.value
            this.setState({
                ...this,
                selectedCompany:{
                    ...this.state.selectedCompany,
                    clientAdmins: tmpClientAdmins,
                    domainNames: domain_name
                },
            });
        }else{
            this.setState({
                ...this,
                selectedCompany:{
                    ...this.state.selectedCompany,
                    [e.target.name]: e.target.value,
                    domainNames: domain_name
                },
            });
        }
        if(e.target?.name == "subscriptionDuration") {
            this.handleSubscriptionEndingDate(this.state.selectedCompany?.subscriptionDate, e.target.value, this.state.selectedCompany?.isFirstYearFree, this.state.selectedCompany?.unit);
        }
        if(e.target?.name == "signMethod") {
            this.setState({
                ...this,
                signMethod: e.target.value,
            });        }
    }

    handleBillingAddressCheck = isChecked => {
        this.setState({
            ...this,
            isBillingAddressChecked: isChecked,
        });
        if (isChecked === true && this.state.selectedCompany) {
            this.setState({
                ...this,
                selectedCompany:{
                    ...this.state.selectedCompany,
                    billingAddress: this.state.selectedCompany.headquartersAddress,
                    billingZipCode: this.state.selectedCompany.headquartersZipCode,
                    billingCommon: this.state.selectedCompany.headquartersCommon,
                },
            });
        }

    }

    handleIdemCommercialCheck = isChecked => {
        this.setState({
            ...this,
            idemCommercialIsChecked: !this.state.idemCommercialIsChecked,
        });

        let arrayidemCommercialChecked = this.state.idemCommercialIsCheckedIndexList || []

        
        if (isChecked === true && this.state.selectedCompany) {
            arrayidemCommercialChecked.push(this.state.selectedCompanyAdminIndex)
            let tmpSelectedCompany = this.state.selectedCompany.clientAdmins;
            tmpSelectedCompany[this.state.selectedCompanyAdminIndex].firstName = this.state.selectedCompany.signatoryFirstName;
            tmpSelectedCompany[this.state.selectedCompanyAdminIndex].lastName = this.state.selectedCompany.signatoryLastName;
            tmpSelectedCompany[this.state.selectedCompanyAdminIndex].email = this.state.selectedCompany.signatoryEmail;
            tmpSelectedCompany[this.state.selectedCompanyAdminIndex].phoneNumber = this.state.selectedCompany.signatoryPhoneNumber;
            tmpSelectedCompany[this.state.selectedCompanyAdminIndex].homePhoneNumber = this.state.selectedCompany.signatoryHomePhoneNumber;
            tmpSelectedCompany[this.state.selectedCompanyAdminIndex].extraPhoneNumber = this.state.selectedCompany.signatoryExtraPhoneNumber;
            this.setState({
                ...this,
                selectedCompany:{
                    ...this.state.selectedCompany,
                    clientAdmins: tmpSelectedCompany
                },
                idemCommercialIsCheckedIndexList: arrayidemCommercialChecked
            });
        }else if (isChecked === false && this.state.selectedCompany) {
            arrayidemCommercialChecked = arrayidemCommercialChecked.filter((item) => item !== this.state.selectedCompanyAdminIndex)
            let tmpSelectedCompany = this.state.selectedCompany.clientAdmins;
            tmpSelectedCompany[this.state.selectedCompanyAdminIndex].firstName = "";
            tmpSelectedCompany[this.state.selectedCompanyAdminIndex].lastName = "";
            tmpSelectedCompany[this.state.selectedCompanyAdminIndex].email = "";
            tmpSelectedCompany[this.state.selectedCompanyAdminIndex].phoneNumber = "";
            tmpSelectedCompany[this.state.selectedCompanyAdminIndex].homePhoneNumber = "";
            tmpSelectedCompany[this.state.selectedCompanyAdminIndex].extraPhoneNumber = "";
            this.setState({
                ...this,
                selectedCompany:{
                    ...this.state.selectedCompany,
                    clientAdmins: tmpSelectedCompany
                },
                idemCommercialIsCheckedIndexList: arrayidemCommercialChecked
            });
        }

    }

    handleIsFirstYearFree = isChecked => {
        this.setState({
            ...this,
            selectedCompany:{
                ...this.state.selectedCompany,
                isFirstYearFree: isChecked,
            },
        });
        this.handleSubscriptionEndingDate(this.state.selectedCompany?.subscriptionDate, this.state.selectedCompany?.subscriptionDuration, isChecked, this.state.selectedCompany?.unit);

    }

    addNewCompany = () => {

        this.form.validateFields();

        setTimeout(()=>{
            if (this.form.isValid()) {
                if(this.state.isNewCompany){
                    LoaderComponent.show();
                    saveClient(this.state.selectedCompany, this.state.isBillingAddressChecked).then((response) => {
                        NotifyAlert.show("La création de la société s'est terminée avec succès", "Clients");
                        getClients().then((response) => {
                            LoaderComponent.hide();
                            this.setState({
                            ...this,
                            companies: response.data,
                            companiesFetched: true,
                            selectedCompanyAdminIndex: 0
                        }, () => {
                            this.allCompanies = [...this.state.companies]
                        }) });
                        this.setState({
                            showFormAddCompany: !this.state.showFormAddCompany
                        })
                        document.getElementById("form-company").reset();
                        //this.state.companies.unshift(this.state.selectedCompany);
                      }, (error) => {
                        LoaderComponent.hide();
                        ErrorAlert.show(error.response.data, "Erreur API");
                        getClients().then((response) => {this.setState({
                            ...this,
                            companies: response.data,
                            companiesFetched: true,
                        }, () => {
                            this.allCompanies = [...this.state.companies]
                        }) });
                      });
                    //this.state.companies.unshift(this.state.selectedCompany);

                }else{
                    LoaderComponent.show();
                    updateClient(this.state.selectedCompany, this.state.isBillingAddressChecked).then((response) => {
                        NotifyAlert.show("La modification de la société s'est terminée avec succès", "Clients");
                        getClients().then((response) => {
                            LoaderComponent.hide();
                            this.setState({
                            ...this,
                            companies: response.data,
                            companiesFetched: true,
                            selectedCompanyAdminIndex: 0
                        }, () => {
                            this.allCompanies = [...this.state.companies]
                        }) });
                      }, (error) => {
                        LoaderComponent.hide();
                        ErrorAlert.show(error.response.data, "Erreur API");
                        getClients().then((response) => {this.setState({
                            ...this,
                            companies: response.data,
                            companiesFetched: true,
                        }, () => {
                            this.allCompanies = [...this.state.companies]
                        }) });
                      });
                }

            }
        })
    }

    viewCompanyInfo  = (company, index) => {
                this.exitForm(index).then(()=>{
                    this.setState({
                        signMethod: company.signMethod
                    }, () => {
                        let domain_name = [];
                        if(company["domainNames"]){
                            const parsedDomainName = typeof company["domainNames"] === "object" ? company["domainNames"]: JSON.parse(company["domainNames"])
                            if(parsedDomainName && parsedDomainName.length>0){
                                domain_name = parsedDomainName.map(domain => {return { label: domain.label || domain }}).filter(x=>!x.unselected || x.unselected === false)
                                company["domainNames"] = domain_name;
                            }
                        }
                        this.setState({
                            ...this,
                            isNewCompany: false,
                            showFormAddCompany: true,
                            selectedCompany: company,
                            selectedCompanyIndex: index,
                            isBillingAddressChecked: this.ifBillingAddressExists(company),
                            selectedCompanyAdminIndex: 0,
                            idemCommercialIsCheckedIndexList:[]
                        },()=>{
                            getArchiveHistoryByClient(this.state.selectedCompany.id).then(archiveList=>{
                                this.setState({
                                    ...this.state,
                                    selectedCompany:{
                                        ...this.state.selectedCompany,
                                        archiveHistoList : archiveList?.data
                                    }
                                })
                            })
                        })
                        document.getElementById("form-company").reset();
                    });
            })
    }

    ifBillingAddressExists(company) {
        if(company.billingAddress) {
            return true
        } else {
            return false;
        }
    }

    viewNextCompany = () => {
        let index = parseInt(this.state.selectedCompanyIndex);
        let companies = this.state.companies;
        if(index >= 0 && index < companies.length - 1){
            this.setState({
                signMethod: companies[index + 1].signMethod
            }, () => {
                this.setState({
                    ...this,
                    selectedCompany: companies[index + 1],
                    selectedCompanyIndex: [index + 1],
                },()=>{
                    getArchiveHistoryByClient(this.state.selectedCompany.id).then(archiveList=>{
                        this.setState({
                            ...this.state,
                            selectedCompany:{
                                ...this.state.selectedCompany,
                                archiveHistoList : archiveList?.data
                            }
                        })
                    })
                })
            });
        }
    }

    viewPrevCompany = () => {
        let index = parseInt(this.state.selectedCompanyIndex);
        let companies = this.state.companies;
        if(index >= 1 && index <= companies.length){
            this.setState({
                signMethod: companies[index - 1].signMethod
            }, () => {
                this.setState({
                    ...this,
                    selectedCompany: companies[index - 1],
                    selectedCompanyIndex: [index - 1],
                },()=>{
                    getArchiveHistoryByClient(this.state.selectedCompany.id).then(archiveList=>{
                        this.setState({
                            ...this.state,
                            selectedCompany:{
                                ...this.state.selectedCompany,
                                archiveHistoList : archiveList?.data
                            }
                        })
                    })
                })
            });
        }
    }

    sortCompanies = (sortby, sort) => {
        let companies = this.state.companies;
        companies.sort(function(a, b){
            if(a[sortby] < b[sortby]) return sort == 'ASC' ? -1 : 1;
            if(a[sortby] > b[sortby]) return sort == 'ASC' ? 1 : -1;
            return 0;
        })
        this.setState({
            ...this,
            companies: companies
        })
    }

    searchCompanies = (e) => {
       let companies = this.allCompanies;
       let searchInput = e.target.value;
       let foundedCompanies = [];
       companies.map((company, index) => {
            if( company.companyName.toLowerCase().includes(searchInput.toLowerCase()) ) foundedCompanies.push(company);
       })
       this.setState({
           ...this,
           companies: foundedCompanies
       })
    }


    exportCompany = () => {
        let data = [this.state.selectedCompany];
        const csv = new ObjectsToCsv(data).toString();
        // Save to file:
        csv.then((csvString) => {
            let link = document.createElement('a')
            link.id = 'download-csv'
            link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csvString));
            link.setAttribute('download', this.state.selectedCompany.companyName+'.csv');
            document.body.appendChild(link)
            document.querySelector('#download-csv').click();
            setTimeout(()=>{
                document.querySelector('#download-csv').remove();
            })
        })
    }

    onScrolling = (e)=>{
        this.setState({
            ...this,
            scrolling: e.target.scrollTop > 100 ? true : false
        })
    }

    handleChangeDate = (name, value) => {
        if( !this.state.selectedCompany.hasOwnProperty(name) ){
            this.setState({
                ...this,
                isNewCompany: true,
                showFormAddCompany: true
            })
        }
        //this.form.validateFields(e.target);
        var dateValue = null;
        if (value) {
            dateValue = Moment(value).format("DD/MM/YYYY");
        } else {
            dateValue = null;
        }
        this.setState({
            ...this,
            selectedCompany:{
                ...this.state.selectedCompany,
                [name]: dateValue
            },
        },() => {
                if(name == 'subscriptionDate') {
                    this.handleSubscriptionEndingDate(dateValue, this.state.selectedCompany?.subscriptionDuration, this.state.selectedCompany?.isFirstYearFree, this.state.selectedCompany?.unit);
                }
        });
    };


    handleSubscriptionEndingDate = (subscriptionDate, subscriptionDuration, isFirstYearFree, unit) => {
        if(subscriptionDate && subscriptionDuration) {
            calculateSubscriptionEndingDate(subscriptionDate, subscriptionDuration, isFirstYearFree, unit).then(
                (response) => {
                    this.setState({
                        ...this,
                        selectedCompany:{
                            ...this.state.selectedCompany,
                            ['subscriptionEndingDate']: response.data?.endingDate
                        },
                    });
                },
                (error) => {
                    console.log("error.response.data", error?.response?.data);
                }
            );
        }
    };

    deleteCompany = () => {

        ConfirmAlert.show("Voulez vous vraiment supprimer ce client ?").then(()=>{
            LoaderComponent.show();
            deleteClient(this.state.selectedCompany?.id).then((response) => {
                LoaderComponent.hide();
                getClients().then((response) => {
                    LoaderComponent.hide();
                    this.setState({
                        ...this,
                        companies: response.data,
                        companiesFetched: true,
                    }, () => {
                        this.allCompanies = [...this.state.companies]
                    }) });
            }, (error) => {
                LoaderComponent.hide();
                ErrorAlert.show(error.response.data, "Erreur API");
            });
        });
    }
    getSignMethod () {
            return <select
                className="form-control"
                name="signMethod"
                value={this.state.signMethod}
                onChange={(e)=>{this.handleChangeInput(e)}}
            >
                <option value="DOCUSIGN" key="DOCUSIGN">
                    Docusign
                </option>
                <option value="UNIVERSIGN" key="UNIVERSIGN">
                    Universign
                </option>
        </select>;
    }

    addAdmin = () => {
        let newObj = 
            {
                firstName : null,
                lastName : null,
                email : null,
                phoneNumber : null,
                homePhoneNumber : null,
                extraPhoneNumber : null
            }
        let found = this.state.selectedCompany.clientAdmins && this.state.selectedCompany.clientAdmins.length>0 ? this.state.selectedCompany.clientAdmins.filter(x=>x.firstName == null && x.lastName == null && x.email == null && x.phoneNumber == null && x.homePhoneNumber == null && x.extraPhoneNumber == null) : []
        if(!this.state.selectedCompany.clientAdmins || (this.state.selectedCompany.clientAdmins && this.state.selectedCompany.clientAdmins.length == 0) ){
            if(!found || found && found.length == 0)
            this.setState({
                ...this,
                selectedCompanyAdminIndex: 0,
                selectedCompany: {
                    ...this.state.selectedCompany,
                    clientAdmins: [newObj]
                },
            });
        } else {
            if (!found || found && found.length == 0)
                this.setState({
                    ...this,
                    selectedCompanyAdminIndex: 0,
                    selectedCompany: {
                        ...this.state.selectedCompany,
                        clientAdmins: [newObj, ...this.state.selectedCompany.clientAdmins]
                    },
                });
        }
    }

    removeAdmin = () => {
        if (this.state.selectedCompany.clientAdmins && this.state.selectedCompany.clientAdmins.length > 1) {
            let tmpClientAdmins = this.state.selectedCompany.clientAdmins;
            tmpClientAdmins.splice([this.state.selectedCompanyAdminIndex], 1);
            this.setState({
                ...this,
                selectedCompanyAdminIndex: 0,
                selectedCompany: {
                    ...this.state.selectedCompany,
                    clientAdmins: tmpClientAdmins
                },
            });
        }
    }

    backAdmin = (index) => {
        if(this.state.selectedCompany.clientAdmins && this.state.selectedCompany.clientAdmins.length>0 && index-1 >= 0){
            this.setState({
             ...this.state,
             selectedCompanyAdminIndex: index-1
            })
        }
    }

    nextAdmin = (index) => {
       if(this.state.selectedCompany.clientAdmins && this.state.selectedCompany.clientAdmins.length>0 && index+1 < this.state.selectedCompany.clientAdmins.length){
           this.setState({
            ...this.state,
            selectedCompanyAdminIndex: index+1
           })
       }
    }

    chooseDurationUnit = (duration) => {
        this.setState({
            ...this,
            selectedCompany: {
                ...this.state.selectedCompany,
                unit: duration
            },
        },()=>{
            if(this.state.selectedCompany?.subscriptionDate){
                this.handleSubscriptionEndingDate(this.state.selectedCompany?.subscriptionDate, this.state.selectedCompany?.subscriptionDuration, this.state.selectedCompany?.isFirstYearFree, this.state.selectedCompany?.unit);
            }
        });
    }

    getClientHistoListTable = () => {
        const histoList = this.state.selectedCompany["archiveHistoList"]
        if(histoList.length == 0 )
            return <p class="warning-msg">Aucun archive pour ce client</p>
        else{
            let mappedList = histoList.map(item=> {
                let actor = item.handledBy["firstName"] + " " + item.handledBy["lastName"]
                let actionActor = item.isFinal == 0 ? " a archivé(e) "  : " a supprimé(e) " 
                return {
                    dateAction: item.createdAt,
                    msg:`${actor}${actionActor}${item.user}.`,
                    section: item.roleType == "ADMIN_CLIENT" ? "Admin client":"Admin Docatweb"
                }
            })
            const columns = [
              { name: 'dateAction', label: "Date" },
              { name: 'section', label: "Section" },
              { name: 'msg', label: "Action" },
            ];
            return (
              <DataTable data={mappedList || []} columns={columns} onSelectedRowsChange={this.handleSelectedRowsChange} selectable={false} allRowsSortable={true} loading={this.state.loadingContractsList} />
            );
        }
      };

      exportClientArchived = () => {
        exportArchiveHistoryByClient(this.state.selectedCompany.id).then(result=>{
            const fileName = `Export-archivage-Histo${"-" + moment().format('DD-MM-YYYY')}.csv`;
            if (result) this.fileDownload(result.data, fileName);
        })
      }

      fileDownload = (data, fileName) => {
        var universalBOM = '\uFEFF';
        var a = window.document.createElement('a');
        a.setAttribute(
          'href',
          'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + data)
        );
        a.setAttribute('download', fileName);
        window.document.body.appendChild(a);
        a.click();
      };

    editModeReminder = () => {
        if (!this.state.editMode)
            ConfirmAlert.show(
                "Voulez-vous activer le mode d'édition pour effectuer des modifications ?",
                "Rappel",
                "Activer",
                "Annuler"
            ).then(() => {
                this.setState({
                    ...this.state,
                    editMode: true,
                });
            });
    };

    addDomain = () => {
        let domainField = document.getElementById('domainNames');
        if (domainField.value.length > 0) {
            let domainNames = this.state.selectedCompany.domainNames || [];

            // check if exists
            let exist = domainNames.find((item) => item.label.trim() === domainField.value.trim());
            if (!exist) {
                domainNames.push({
                    label: domainField.value
                });
                this.setState({
                    ...this,
                    selectedCompany: {
                        ...this.state.selectedCompany,
                        domainNames: domainNames,
                    },
                });
                domainField.value = '';
            } else {
                ErrorAlert.show('Valeur existe déjà', {
                    domain_name: domainField.value
                });
            }
        }
        document.getElementById('domainNames').focus();
    };

    enterPressed = (event) => {
        var code = event.which;
        if (code === 13) {
            //13 is the enter keycode
            this.addDomain();
        }
    };

    unselectDomainNames = (domain) => {
        let domainNames = this.state.selectedCompany.domainNames;
        let index = domainNames.findIndex((item) => item.label === domain.label);
        domainNames[index].unselected = !domainNames[index].unselected;
        this.setState({
          ...this,
          selectedCompany: {
            ...this.state.selectedCompany,
            synonyms: domainNames,
          },
        });
      };

    render() {
            return (
                <div className="app-container page-company app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
                    <Header />
                    <div className="app-main">
                        <div className="app-sidebar sidebar-shadow">
                            <Sidebar />
                        </div>
                        <div className="app-main__outer">
                            <EditMode
                                isEditMode={this.state.editMode}
                                onChange={(checked)=>{
                                    this.changeEditMode(checked)
                                }}
                            />
                            <div className="app-main__inner">

                                <div className="row">
                                <div className={`col-sm-5 left-side ${this.state.scrolling ? 'scrolling' : ''}`}>

                                        <h1 className="side-menu-title-style">
                                            <svg width="35" height="47" viewBox="0 0 35 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.9375 23.5H28.125V5.4375C28.125 3.67709 26.6979 2.25 24.9375 2.25H5.8125C4.05209 2.25 2.625 3.67709 2.625 5.4375V23.5H5.8125V22.7188C5.8125 20.8527 6.79159 19.1235 8.39174 18.1634L10.4529 16.9267C9.54518 15.8252 9 14.4138 9 12.875C9 9.35419 11.8542 6.5 15.375 6.5C18.8958 6.5 21.75 9.35419 21.75 12.875C21.75 14.4138 21.2048 15.8252 20.2971 16.9267L22.3583 18.1634C23.9584 19.1235 24.9375 20.8527 24.9375 22.7188V23.5ZM22.8125 23.5V22.7188C22.8125 21.5992 22.225 20.5616 21.265 19.9856L18.5905 18.3809C17.6465 18.9333 16.5477 19.25 15.375 19.25C14.2023 19.25 13.1035 18.9333 12.1595 18.3809L9.48504 19.9856C8.52495 20.5616 7.9375 21.5992 7.9375 22.7188V23.5H22.8125ZM28.125 25.625H2.625V37.3125C2.625 39.0729 4.05209 40.5 5.8125 40.5H24.9375C26.6979 40.5 28.125 39.0729 28.125 37.3125V25.625ZM30.161 4.464C32.6301 4.92126 34.5 7.08601 34.5 9.6875V41.5625C34.5 44.4965 32.1215 46.875 29.1875 46.875H10.0625C7.46101 46.875 5.29626 45.0051 4.839 42.536C2.36991 42.0787 0.5 39.914 0.5 37.3125V5.4375C0.5 2.50349 2.87849 0.125 5.8125 0.125H24.9375C27.539 0.125 29.7037 1.99491 30.161 4.464ZM7.05638 42.625C7.49395 43.863 8.67464 44.75 10.0625 44.75H29.1875C30.9479 44.75 32.375 43.3229 32.375 41.5625V9.6875C32.375 8.29964 31.488 7.11895 30.25 6.68138V37.3125C30.25 40.2465 27.8715 42.625 24.9375 42.625H7.05638ZM15.375 17.125C17.7222 17.125 19.625 15.2222 19.625 12.875C19.625 10.5278 17.7222 8.625 15.375 8.625C13.0278 8.625 11.125 10.5278 11.125 12.875C11.125 15.2222 13.0278 17.125 15.375 17.125ZM7.9375 32C7.3507 32 6.875 31.5243 6.875 30.9375C6.875 30.3507 7.3507 29.875 7.9375 29.875H22.8125C23.3993 29.875 23.875 30.3507 23.875 30.9375C23.875 31.5243 23.3993 32 22.8125 32H7.9375ZM7.9375 36.25C7.3507 36.25 6.875 35.7743 6.875 35.1875C6.875 34.6007 7.3507 34.125 7.9375 34.125H18.5625C19.1493 34.125 19.625 34.6007 19.625 35.1875C19.625 35.7743 19.1493 36.25 18.5625 36.25H7.9375Z" fill="black"/>
                                            </svg>
                                            Fiche Entreprise
                                        </h1>

                                        <div className="sub-menu">
                                            <ul>
                                                <li>
                                                    <Link to="/back-office/home">
                                                        <i className="fa fa-home mb-1"></i>
                                                        Accueil
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className="actions side-menu-actions">
                                            <div className="sorting">
                                                <button onClick={()=>{ this.sortCompanies('companyName', 'ASC') }} className="btn btn-sorting">
                                                    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.64706 3.08294V20.2112C5.64706 20.554 5.33102 20.8318 4.94118 20.8318C4.55133 20.8318 4.23529 20.554 4.23529 20.2112V3.08294L1.20502 5.74683C0.929352 5.98916 0.482412 5.98916 0.206748 5.74683C-0.0689161 5.5045 -0.0689161 5.11159 0.206748 4.86926L4.44204 1.14605C4.71771 0.903711 5.16465 0.903711 5.44031 1.14605L9.67561 4.86926C9.95127 5.11159 9.95127 5.5045 9.67561 5.74683C9.39994 5.98916 8.953 5.98916 8.67734 5.74683L5.64706 3.08294ZM12 3.44644C11.6102 3.44644 11.2941 3.16861 11.2941 2.8259C11.2941 2.48319 11.6102 2.20537 12 2.20537H23.2941C23.684 2.20537 24 2.48319 24 2.8259C24 3.16861 23.684 3.44644 23.2941 3.44644H12ZM12 8.41072C11.6102 8.41072 11.2941 8.1329 11.2941 7.79019C11.2941 7.44747 11.6102 7.16965 12 7.16965H20.4706C20.8604 7.16965 21.1765 7.44747 21.1765 7.79019C21.1765 8.1329 20.8604 8.41072 20.4706 8.41072H12ZM12 13.375C11.6102 13.375 11.2941 13.0972 11.2941 12.7545C11.2941 12.4118 11.6102 12.1339 12 12.1339H17.6471C18.0369 12.1339 18.3529 12.4118 18.3529 12.7545C18.3529 13.0972 18.0369 13.375 17.6471 13.375H12ZM12 18.3393C11.6102 18.3393 11.2941 18.0615 11.2941 17.7188C11.2941 17.376 11.6102 17.0982 12 17.0982H14.8235C15.2134 17.0982 15.5294 17.376 15.5294 17.7188C15.5294 18.0615 15.2134 18.3393 14.8235 18.3393H12Z" fill="#5367F4"/>
                                                    </svg>
                                                </button>
                                                <button onClick={()=>{ this.sortCompanies('companyName', 'DESC') }} className="btn btn-sorting">
                                                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.84923 20.9721L4.82229 20.9994L4.79982 21.0304C4.76761 21.0749 4.71905 21.1 4.66667 21.1C4.61428 21.1 4.56573 21.0749 4.53352 21.0304L4.51105 20.9994L4.48411 20.9721L0.550913 16.9918C0.483029 16.9231 0.483029 16.8093 0.550914 16.7406C0.61553 16.6752 0.717803 16.6752 0.78242 16.7406L3.64435 19.6368L4.5 20.5027V19.2854V0.674649C4.5 0.572566 4.58021 0.5 4.66667 0.5C4.75312 0.5 4.83333 0.572566 4.83333 0.674649V19.2854V20.5027L5.68898 19.6368L8.55091 16.7406C8.61553 16.6752 8.7178 16.6752 8.78242 16.7406C8.85031 16.8093 8.85031 16.9231 8.78242 16.9918L4.84923 20.9721ZM12.6667 2.19859C12.5802 2.19859 12.5 2.12603 12.5 2.02395C12.5 1.92186 12.5802 1.8493 12.6667 1.8493H23.3333C23.4198 1.8493 23.5 1.92186 23.5 2.02395C23.5 2.12603 23.4198 2.19859 23.3333 2.19859H12.6667ZM12.6667 7.59578C12.5802 7.59578 12.5 7.52322 12.5 7.42114C12.5 7.31905 12.5802 7.24649 12.6667 7.24649H20.6667C20.7531 7.24649 20.8333 7.31905 20.8333 7.42114C20.8333 7.52322 20.7531 7.59578 20.6667 7.59578H12.6667ZM12.6667 12.993C12.5802 12.993 12.5 12.9204 12.5 12.8183C12.5 12.7162 12.5802 12.6437 12.6667 12.6437H18C18.0865 12.6437 18.1667 12.7162 18.1667 12.8183C18.1667 12.9204 18.0865 12.993 18 12.993H12.6667ZM12.6667 18.3902C12.5802 18.3902 12.5 18.3176 12.5 18.2155C12.5 18.1134 12.5802 18.0409 12.6667 18.0409H15.3333C15.4198 18.0409 15.5 18.1134 15.5 18.2155C15.5 18.3176 15.4198 18.3902 15.3333 18.3902H12.6667Z" fill="black" stroke="#5367F4"/>
                                                    </svg>
                                                </button>
                                            </div>
                                            <div className="search-input">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.7488 12.7482L19.0001 19" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M7.88235 14.7647C11.6834 14.7647 14.7647 11.6834 14.7647 7.88235C14.7647 4.08133 11.6834 1 7.88235 1C4.08133 1 1 4.08133 1 7.88235C1 11.6834 4.08133 14.7647 7.88235 14.7647Z" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <input type="text" placeholder="" onKeyUp={(e)=>{this.searchCompanies(e)}} />
                                        </div>
                                        <button onClick={()=>{this.newCompany()}} className="btn-icon btn-pill btn btn-danger">
                                            <svg className="inner" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13 7H1M7 1V13V1Z" stroke="#FFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                            <svg className="outer" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="36" height="36" rx="18" fill="#5367F4"></rect>
                                            </svg>
                                            Ajouter
                                        </button>
                                    </div>
                                    <div className={`scrolling-verticaly ${this.state.showFormAddCompany ? 'showFormAddRecord' : ''}`} onScroll={(e)=>{this.onScrolling(e)}}>
                                        { this.state.showFormAddCompany &&
                                            <div className="new-company add-record">
                                                <h2>{this.state.selectedCompany.companyName || 'Nom Société'}</h2>
                                                <div>
                                                <button onClick={()=>{this.cancelNewCompany()}} className="btn-pill btn btn-cancel">
                                                    Annuler
                                                </button>
                                                <button onClick={()=>{this.addNewCompany()}} className="btn-pill btn btn-danger btn-save" disabled={!this.state.editMode}>
                                                    Enregistrer
                                                </button>

                                                </div>

                                                    </div>
                                        }

                                        <div className="companies records">
                                            { this.state.companies.map((company, index)=>{
                                                return(
                                                    <div key={index} onClick={() => { this.viewCompanyInfo(company, index) }} className={`company ${index == this.state.selectedCompanyIndex && !this.state.isNewCompany ? 'active' : ''}`}>
                                                        <div className="side-menu-style">
                                                            <div className="row">
                                                                <div className="col-lg-0">
                                                                    {company?.inOrange &&
                                                                        <span className="dot"> </span>
                                                                    }
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <h3 className="title-stage-one">{company.companyName}</h3>
                                                                </div>
                                                            </div>
                                                            <p className="title-stage-two">Anniv. {moment(company.createdAt).format('LL')}</p>
                                                        </div>
                                                    </div>
                                                )
                                            }) }
                                            { this.state.companies.length == 0 &&
                                                <div className="no-resut-found">
                                                    Aucun résultat n'a été trouvé
                                                </div>
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-7 viewer">
                                        <h1>{this.state.selectedCompany.companyName || 'Nom Société'}</h1>
                                        <div className={ this.state.isNewCompany ? 'hidden' : ''}>
                                        <div className="formHead">
                                            <div className="row">
                                                <div className="d-none d-md-block col-md-4 createdAt">
                                                    Créé Aujourd’hui
                                                </div>
                                                <div className="col-sm-6 col-md-4 exportBtns">
                                                    <button onClick={()=>{this.exportCompany()}} className="btn btm-light"><i className="lnr-download"></i></button>
                                                    <button className="btn btm-light"><i className="lnr-printer"></i></button>
                                                </div>
                                                <div className="col-sm-6 col-md-4 navigateBtns">
                                                    <button onClick={()=>{this.viewPrevCompany()}} className="btn btm-light"><i className="fa fa-angle-up"></i></button>
                                                    <span>{parseInt(this.state.selectedCompanyIndex )+ 1}/{this.state.companies.length}</span>
                                                    <button onClick={()=>{this.viewNextCompany()}} className="btn btm-light"><i className="fa fa-angle-down"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

                                        <FormWithConstraints
                                            ref={formWithConstraints => this.form = formWithConstraints}
                                            id="form-company"
                                            onClick={this.editModeReminder}
                                            noValidate>
                                            {
                                                !this.state.isNewCompany && this.state.selectedCompany?.id &&
                                                <div className="row">
                                                    <div className="col-lg-12" style={{textAlign: "right"}}>
                                                        <button onClick={()=>{ this.deleteCompany() }} disabled={!this.state.editMode} type="button" className="btn btn-default deleteKeyword">
                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M21.6622 40.7325L21.6819 40.7341H21.7017H22.3009H22.3207L22.3404 40.7325C23.3253 40.6545 24.2891 40.2356 25.0414 39.4779L26.4334 38.0759L26.7831 37.7237L26.4334 37.3714L10.8578 21.6837L10.5029 21.3264L10.1481 21.6837L8.75614 23.0857C7.07954 24.7744 7.07954 27.5108 8.75614 29.1994L18.9612 39.4779C19.7135 40.2356 20.6773 40.6545 21.6622 40.7325ZM26.0578 39.8818L25.2116 40.7341H26.4126H36.9797C37.2521 40.7341 37.4791 40.9575 37.4791 41.2406C37.4791 41.5238 37.2521 41.7471 36.9797 41.7471H22.3716H22.3556L22.3396 41.7482C22.1143 41.7626 21.8883 41.7626 21.663 41.7482L21.647 41.7471H21.631H10.9967C10.7243 41.7471 10.4974 41.5238 10.4974 41.2406C10.4974 40.9575 10.7243 40.7341 10.9967 40.7341H17.59H18.791L17.9448 39.8818L8.05249 29.9183C5.9825 27.8334 5.9825 24.4517 8.05249 22.3668L22.255 8.06217C24.323 5.97928 27.6744 5.97928 29.7424 8.06217L39.9475 18.3407C42.0175 20.4256 42.0175 23.8073 39.9475 25.8922L26.0578 39.8818ZM11.5614 20.2603L11.2116 20.6125L11.5614 20.9648L27.137 36.6525L27.4918 37.0099L27.8466 36.6525L39.2439 25.1733C40.9205 23.4846 40.9205 20.7482 39.2439 19.0596L29.0388 8.78106C27.3602 7.09041 24.6372 7.09041 22.9586 8.78106L11.5614 20.2603Z" fill="black" stroke="#EB3A59"/>
                                                                <rect opacity="0.05" width="48" height="48" rx="24" fill="#292C41"/>
                                                            </svg>


                                                        </button>
                                                    </div>
                                                </div>
                                            }

                                                <div className="formTitle">
                                                    <h2>Information Société</h2>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <label>Raison Sociale*</label>
                                                        <input value={this.state.selectedCompany.companyName} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="companyName" placeholder="NOM SOCIETE" className="form-control" required/>
                                                        <FieldFeedbacks for="companyName">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <label>SIREN*</label>
                                                        <input value={this.state.selectedCompany.sirenNumber}  onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="sirenNumber" placeholder="123 123 123" className="form-control" required />
                                                        <FieldFeedbacks for="sirenNumber">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <label>Code Etablissement*</label>
                                                        <input value={this.state.selectedCompany.institutionCode} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="institutionCode" placeholder="12345" className="form-control" required />
                                                        <FieldFeedbacks for="institutionCode">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <label>Adresse Siège*</label>
                                                        <input value={this.state.selectedCompany.headquartersAddress ? this.state.selectedCompany.headquartersAddress : null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="headquartersAddress" placeholder="18 rue des violles furtifs" className="form-control" required />
                                                        <FieldFeedbacks for="headquartersAddress">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-3">
                                                        <label>Code Postal*</label>
                                                        <input value={this.state.selectedCompany.headquartersZipCode ? this.state.selectedCompany.headquartersZipCode : null}  onChange={(e)=>{this.handleChangeInput(e)}}  type="text" name="headquartersZipCode" placeholder="12345" className="form-control" required />
                                                        <FieldFeedbacks for="headquartersZipCode">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <label>Commune*</label>
                                                        <input value={this.state.selectedCompany.headquartersCommon ? this.state.selectedCompany.headquartersCommon : null}  onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="headquartersCommon" placeholder="COMMUNE" className="form-control" required />
                                                        <FieldFeedbacks for="headquartersCommon">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>

                                                <div className="billingAddres">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <label>Adresse Facturation (si différente)</label> <input type="checkbox" defaultChecked={this.state.isBillingAddressChecked} onClick={(e)=>{this.handleBillingAddressCheck(!this.state.isBillingAddressChecked)}}></input>
                                                            <input disabled={this.state.isBillingAddressChecked == false} value={this.state.selectedCompany.billingAddress ? this.state.selectedCompany.billingAddress : null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="billingAddress" placeholder="18 rue des violles furtifs" className="form-control"/>
                                                            <FieldFeedbacks for="billingAddress">
                                                                <FieldFeedback when="*" />
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <label>Code Postal*</label>
                                                            <input disabled={this.state.isBillingAddressChecked == false} value={this.state.selectedCompany.billingZipCode ? this.state.selectedCompany.billingZipCode : null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="billingZipCode" placeholder="12345" className="form-control"/>
                                                            <FieldFeedbacks for="billingZipCode">
                                                                <FieldFeedback when="*" />
                                                            </FieldFeedbacks>
                                                        </div>
                                                        <div className="col-lg-9">
                                                            <label>Commune*</label>
                                                            <input disabled={this.state.isBillingAddressChecked == false} value={this.state.selectedCompany.billingCommon ? this.state.selectedCompany.billingCommon : null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="billingCommon" placeholder="COMMUNE" className="form-control"/>
                                                            <FieldFeedbacks for="billingCommon">
                                                                <FieldFeedback when="*" />
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <label>Noms de domaine</label>
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <input
                                                                        type="text"
                                                                        name="domainNames"
                                                                        id="domainNames"
                                                                        placeholder=""
                                                                        className="form-control"
                                                                        onKeyPress={(e) => {
                                                                            this.enterPressed(e);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    <button
                                                                        type="button"
                                                                        onClick={(e) => {
                                                                            this.addDomain(e);
                                                                        }}
                                                                        className="btn btn-primary btn-pill"
                                                                        style={{ height: 36, marginTop: 4 }}
                                                                    >
                                                                        <i className="fa fa-plus"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <FieldFeedbacks for="domainNames">
                                                                <FieldFeedback when="*" />
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                {this.state.selectedCompany["domainNames"] !== undefined && this.state.selectedCompany["domainNames"] !== null && this.state.selectedCompany["domainNames"].length > 0 &&
                                                    <div className="selectedCompanyBtn">
                                                        {this.state.selectedCompany["domainNames"].map((domain, key) => (
                                                            <div type="button"
                                                                className={`button mb-2 mr-2 btn-icon btn-pill btn btn-secondary ${domain.unselected ? 'unselected' : ''
                                                                    }`}
                                                            >
                                                                {domain.label}
                                                                <span
                                                                    onClick={() => {
                                                                        this.unselectDomainNames(domain);
                                                                    }}
                                                                >
                                                                    <svg
                                                                        width="52"
                                                                        height="52"
                                                                        viewBox="0 0 52 52"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <rect
                                                                            x="0.542969"
                                                                            y="26"
                                                                            width="36"
                                                                            height="36"
                                                                            rx="18"
                                                                            transform="rotate(-45 0.542969 26)"
                                                                            fill="#F66031"
                                                                        />
                                                                        <path
                                                                            d="M30.2416 21.7574L21.7563 30.2426M21.7563 21.7574L30.2416 30.2426L21.7563 21.7574Z"
                                                                            stroke="white"
                                                                            strokeWidth="2"
                                                                            strokeMiterlimit="10"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                }
                                                </div>

                                                <div className="formTitle">
                                                    <h2>Information Abonnement</h2>
                                                </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <label>Abonnement</label>
                                                    <input value={this.state.selectedCompany?.subscriptionQuota ? this.state.selectedCompany?.subscriptionQuota : null}  onChange={(e)=>{this.handleChangeInput(e)}}  type="text" name="subscriptionQuota" className="form-control" required />
                                                    <FieldFeedbacks for="subscriptionQuota">
                                                        <FieldFeedback when="*" />
                                                    </FieldFeedbacks>
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Nb Contrats validés</label>
                                                    <input disabled={true} value={this.state.selectedCompany?.validatedContracts ? this.state.selectedCompany?.validatedContracts : null} type="text" name="validatedContracts" className="form-control" required />
                                                    <FieldFeedbacks for="validatedContracts">
                                                        <FieldFeedback when="*" />
                                                    </FieldFeedbacks>
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Nb Pages numérisées</label>
                                                    <input disabled={true} value={this.state.selectedCompany?.ocerizedPages ? this.state.selectedCompany?.ocerizedPages : null} type="text" name="ocerizedPages" className="form-control" required />
                                                    <FieldFeedbacks for="ocerizedPages">
                                                        <FieldFeedback when="*" />
                                                    </FieldFeedbacks>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <label>Prix annuel</label>
                                                    <input value={this.state.selectedCompany?.annualPrice ? this.state.selectedCompany?.annualPrice : null}  onChange={(e)=>{this.handleChangeInput(e)}}  type="text" name="annualPrice" className="form-control" required />
                                                    <FieldFeedbacks for="annualPrice">
                                                        <FieldFeedback when="*" />
                                                    </FieldFeedbacks>
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>%Remise</label>
                                                    <input value={this.state.selectedCompany?.discount ? this.state.selectedCompany?.discount : null}  onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="discount" className="form-control" />
                                                    <FieldFeedbacks for="discount">
                                                        <FieldFeedback when="*" />
                                                    </FieldFeedbacks>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="date-end-switch d-flex align-items-center">
                                                        <span>Règl annuel</span>
                                                        <Switcher
                                                            checked={this.state.selectedCompany?.regulationType == 1 ? true : false}
                                                            onChange={(checked) => {
                                                                this.setState({
                                                                    ...this,
                                                                    selectedCompany:{
                                                                        ...this.state.selectedCompany,
                                                                        ['regulationType']: checked ? 1 : 0
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        <span>Règlement d'avance</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <label>Date souscription</label>
                                                    <DatePicker locale="fr" dateFormat="dd/MM/yyyy" className="form-control" autoComplete="off" required
                                                                selected={
                                                                    this.state.selectedCompany?.subscriptionDate
                                                                        ? Moment(
                                                                        this.state.selectedCompany?.subscriptionDate,
                                                                        "DD/MM/YYYY"
                                                                        ).toDate()
                                                                        : ""
                                                                }
                                                                name="subscriptionDate"
                                                                onChange={(e) => {
                                                                    this.handleChangeDate("subscriptionDate", e);
                                                                }}
                                                    />
                                                    <FieldFeedbacks for="subscriptionDate">
                                                        <FieldFeedback when="*" />
                                                    </FieldFeedbacks>
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Durée initiale</label>
                                                    <div className='duration-type'>
                                                        <input value={this.state.selectedCompany?.subscriptionDuration ? this.state.selectedCompany?.subscriptionDuration : null}  onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="subscriptionDuration" className="form-control" required />
                                                        <div
                                                            role="group"
                                                            className="btn-group-sm btn-group btn-group-toggle btn-toggle"
                                                            data-toggle="buttons"
                                                        >
                                                            <label
                                                                className={this.state.selectedCompany?.unit ? "btn active-label" : "btn inactive-label"}
                                                                onClick={(e) => this.chooseDurationUnit(1)}
                                                            >
                                                                <input type="radio" autoComplete="off" />
                                                                <span>Mois</span>
                                                            </label>
                                                            <label
                                                                className={!this.state.selectedCompany?.unit ? "btn active-label" : "btn inactive-label"}
                                                                onClick={(e) => this.chooseDurationUnit(0)}
                                                            >
                                                                <input type="radio" autoComplete="off" />
                                                                <span>Année</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <FieldFeedbacks for="subscriptionDuration">
                                                        <FieldFeedback when="*" />
                                                    </FieldFeedbacks>
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Date fin</label>
                                                    <DatePicker locale="fr" dateFormat="dd/MM/yyyy" className="form-control" autoComplete="off" required
                                                                selected={
                                                                    this.state.selectedCompany?.subscriptionEndingDate
                                                                        ? Moment(
                                                                        this.state.selectedCompany?.subscriptionEndingDate,
                                                                        "DD/MM/YYYY"
                                                                        ).toDate()
                                                                        : ""
                                                                }
                                                                name="subscriptionEndingDate"
                                                                onChange={(e) => {
                                                                    this.handleChangeDate("subscriptionEndingDate", e);
                                                                }}
                                                    />
                                                    <FieldFeedbacks for="subscriptionEndingDate">
                                                        <FieldFeedback when="*" />
                                                    </FieldFeedbacks>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <label>Années Offerte</label>
                                                    <input type="checkbox" defaultChecked={this.state.selectedCompany?.isFirstYearFree} onClick={(e)=>{this.handleIsFirstYearFree(!this.state.selectedCompany?.isFirstYearFree)}}></input>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Méthode signature</label>
                                                    {this.getSignMethod()}
                                                </div>
                                            </div>

                                                <div className="formTitle">
                                                    <h2>Information Contact Commercial</h2>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <label>Nom Signataire*</label>
                                                        <input value={this.state.selectedCompany?.signatoryLastName ? this.state.selectedCompany?.signatoryLastName : null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="signatoryLastName" placeholder="NOM CONTACT COMMERCIAL (Signataire)" className="form-control" required />
                                                        <FieldFeedbacks for="signatoryLastName">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <label>Prénom Signataire*</label>
                                                        <input value={this.state.selectedCompany.signatoryFirstName ? this.state.selectedCompany.signatoryFirstName : null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="signatoryFirstName" placeholder="Prénom contact Commercial" className="form-control"  required />
                                                        <FieldFeedbacks for="signatoryFirstName">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                <div className="col-lg-4">
                                                        <label>Téléphone Mobile</label>
                                                        <input value={this.state.selectedCompany.signatoryPhoneNumber ? this.state.selectedCompany.signatoryPhoneNumber : null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="signatoryPhoneNumber" placeholder="06.07.08.09.10" className="form-control" required />
                                                        <FieldFeedbacks for="signatoryPhoneNumber">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Téléphone Fixe*</label>
                                                        <input value={this.state.selectedCompany.signatoryHomePhoneNumber ? this.state.selectedCompany.signatoryHomePhoneNumber : null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="signatoryHomePhoneNumber" placeholder="01.02.03.04.05" className="form-control" />
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <label>Téléphone Autre</label>
                                                        <input value={this.state.selectedCompany.signatoryExtraPhoneNumber ? this.state.selectedCompany.signatoryExtraPhoneNumber : null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="signatoryExtraPhoneNumber" placeholder="01.02.03.04.05" className="form-control" />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <label>Adresse Mail Signataire</label>
                                                        <input value={this.state.selectedCompany.signatoryEmail ? this.state.selectedCompany.signatoryEmail : null}  onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="signatoryEmail" placeholder="adresse@mail.fr" className="form-control" required />
                                                        <FieldFeedbacks for="signatoryEmail">
                                                            <FieldFeedback when="*" />
                                                        </FieldFeedbacks>
                                                    </div>
                                                </div>


                                                <div className="formTitle">
                                                    <h2>
                                                        Information Compte Administrateur 
                                                    </h2>
                                                </div>
                                               
                                            <div className="formTitle">
                                                <h2 style={{ paddingTop: 0 }}>
                                                    <a onClick={() => this.addAdmin()} className="btn-icon btn-pill btn btn-nav-form">
                                                        <svg width="25" height="25" viewBox="0 0 113 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M59.375 52.5H79.9375C81.5598 52.5 82.875 53.7965 82.875 55.3958C82.875 56.9952 81.5598 58.2917 79.9375 58.2917H59.375V78.5625C59.375 80.1618 58.0598 81.4583 56.4375 81.4583C54.8152 81.4583 53.5 80.1618 53.5 78.5625V58.2917H32.9375C31.3152 58.2917 30 56.9952 30 55.3958C30 53.7965 31.3152 52.5 32.9375 52.5H53.5V32.2292C53.5 30.6298 54.8152 29.3333 56.4375 29.3333C58.0598 29.3333 59.375 30.6298 59.375 32.2292V52.5ZM0.625 14.8542C0.625 6.85754 7.20082 0.375 15.3125 0.375H97.5625C105.674 0.375 112.25 6.85754 112.25 14.8542V95.9375C112.25 103.934 105.674 110.417 97.5625 110.417H15.3125C7.20082 110.417 0.625 103.934 0.625 95.9375V14.8542ZM6.5 14.8542V95.9375C6.5 100.735 10.4455 104.625 15.3125 104.625H97.5625C102.43 104.625 106.375 100.735 106.375 95.9375V14.8542C106.375 10.0562 102.43 6.16667 97.5625 6.16667H15.3125C10.4455 6.16667 6.5 10.0562 6.5 14.8542Z" fill="black" />
                                                        </svg>
                                                    </a>
                                                </h2>
                                                {this.state.selectedCompany.clientAdmins &&
                                                <>
                                                    <h2 style={{ paddingTop: 0 }}>
                                                        <a disabled={this.state.selectedCompanyAdminIndex-1 <1}
                                                            onClick={() => this.backAdmin(this.state.selectedCompanyAdminIndex)} className="btn-icon btn-pill btn btn-nav-form">
                                                            <svg width="25" height="25" viewBox="0 0 113 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M34.1543 58.2917L52.6396 76.5148C53.7868 77.6457 53.7868 79.4793 52.6396 80.6102C51.4925 81.7411 49.6325 81.7411 48.4854 80.6102L24.9854 57.4435C23.8382 56.3126 23.8382 54.4791 24.9854 53.3482L48.4854 30.1815C49.6325 29.0506 51.4925 29.0506 52.6396 30.1815C53.7868 31.3124 53.7868 33.1459 52.6396 34.2768L34.1543 52.5H85.8125C87.4348 52.5 88.75 53.7965 88.75 55.3958C88.75 56.9952 87.4348 58.2917 85.8125 58.2917H34.1543ZM15.3125 6.16667C10.4455 6.16667 6.5 10.0562 6.5 14.8542V95.9375C6.5 100.735 10.4455 104.625 15.3127 104.625L97.6033 104.629C102.47 104.629 106.416 100.74 106.416 95.942L106.375 14.8553C106.373 10.0586 102.428 6.17113 97.5625 6.17113C97.5257 6.17113 70.109 6.16965 15.3125 6.16667ZM112.25 14.8527L112.291 95.9364C112.291 103.939 105.715 110.421 97.6031 110.421L15.3125 110.417C7.20082 110.417 0.625 103.934 0.625 95.9375V14.8542C0.625 6.85754 7.20082 0.375 15.3125 0.375H97.5625C105.771 0.477628 112.247 6.91911 112.25 14.8527Z" fill="black" />
                                                            </svg>
                                                        </a>
                                                        {this.state.selectedCompanyAdminIndex + 1}/{this.state.selectedCompany.clientAdmins ? this.state.selectedCompany.clientAdmins.length : 0}
                                                        <a  disabled={this.state.selectedCompanyAdminIndex+1>this.state.selectedCompany.clientAdmins.length}
                                                            onClick={() => this.nextAdmin(this.state.selectedCompanyAdminIndex)} className="btn-icon btn-pill btn btn-nav-form">
                                                            <svg width="25" height="25" viewBox="0 0 112 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M78.0418 52.5003L59.6875 34.2771C58.5485 33.1463 58.5485 31.3127 59.6875 30.1818C60.8265 29.0509 62.6733 29.0509 63.8123 30.1818L87.1456 53.3485C88.2847 54.4794 88.2847 56.3129 87.1456 57.4438L63.8123 80.6105C62.6733 81.7414 60.8265 81.7414 59.6875 80.6105C58.5485 79.4796 58.5485 77.646 59.6875 76.5152L78.0418 58.292H26.7499C25.1391 58.292 23.8332 56.9955 23.8332 55.3961C23.8332 53.7968 25.1391 52.5003 26.7499 52.5003H78.0418ZM96.7499 104.625C101.582 104.625 105.5 100.736 105.5 95.9378V14.8545C105.5 10.0565 101.582 6.16698 96.7497 6.16698L15.0427 6.16252C10.2103 6.16252 6.29274 10.052 6.29274 14.85L6.33325 95.9367C6.33511 100.733 10.2521 104.621 15.0832 104.621C15.1198 104.621 42.342 104.622 96.7499 104.625ZM0.499914 95.9392L0.459412 14.8556C0.459411 6.85339 6.98859 0.37085 15.0429 0.37085L96.7499 0.375317C104.804 0.375317 111.333 6.85786 111.333 14.8545V95.9378C111.333 103.934 104.804 110.417 96.7499 110.417H15.0832C6.93285 110.314 0.502996 103.873 0.499914 95.9392Z" fill="black" />
                                                            </svg>
                                                        </a>
                                                    </h2>
                                                    {(this.state.selectedCompany.clientAdmins && this.state.selectedCompany.clientAdmins.length > 1) &&
                                                        <h2 style={{ paddingTop: 0 }}>
                                                            <a onClick={() => this.removeAdmin()} className="btn-icon btn-pill btn btn-nav-form">
                                                                <svg width="25" height="25" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M6.33333 15.0833V96.75C6.33333 101.583 10.2508 105.5 15.0833 105.5H96.75C101.583 105.5 105.5 101.583 105.5 96.75V15.0833C105.5 10.2508 101.583 6.33333 96.75 6.33333H15.0833C10.2508 6.33333 6.33333 10.2508 6.33333 15.0833ZM0.5 15.0833C0.5 7.02918 7.02918 0.5 15.0833 0.5H96.75C104.804 0.5 111.333 7.02918 111.333 15.0833V96.75C111.333 104.804 104.804 111.333 96.75 111.333H15.0833C7.02918 111.333 0.5 104.804 0.5 96.75V15.0833ZM32.5833 58.8333C30.9725 58.8333 29.6667 57.5275 29.6667 55.9167C29.6667 54.3058 30.9725 53 32.5833 53H79.25C80.8608 53 82.1667 54.3058 82.1667 55.9167C82.1667 57.5275 80.8608 58.8333 79.25 58.8333H32.5833Z" fill="black" />
                                                                </svg>
                                                            </a>
                                                        </h2>
                                                    }
                                                </>
                                                }
                                            </div>
                                            {!this.state.selectedCompany.clientAdmins && <p className='warning-msg'>Aucun compte administrateur rajouté</p>}
                                            {this.state.selectedCompany.clientAdmins && this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex] &&
                                                <>
                                                    <div className="row">
                                                        <div className="col-lg-12 text-center">
                                                            <label>Idem Commercial</label> <input type="checkbox" checked={(this.state.idemCommercialIsCheckedIndexList && this.state.idemCommercialIsCheckedIndexList.indexOf(this.state.selectedCompanyAdminIndex) !== -1)?true:false} onClick={(e) => { this.handleIdemCommercialCheck(!this.state.idemCommercialIsChecked) }}></input>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>Nom Administrateur (compte Admin)*</label>
                                                            <input value={this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex].lastName ? this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex].lastName : ""} onChange={(e) => { this.handleChangeInput(e) }} type="text" name="adminLastName" placeholder="NOM Administrateur (Compte admin)" className="form-control" required />
                                                            <FieldFeedbacks for="adminLastName">
                                                                <FieldFeedback when="*" />
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <label>Prénom Administrateur</label>
                                                            <input value={this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex].firstName ? this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex].firstName : ""} onChange={(e) => { this.handleChangeInput(e) }} type="text" name="adminFirstName" placeholder="Prénom Administrateur" className="form-control" required />
                                                            <FieldFeedbacks for="adminFirstName">
                                                                <FieldFeedback when="*" />
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <label>Téléphone Mobile</label>
                                                            <input value={this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex].phoneNumber ? this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex].phoneNumber : ""} onChange={(e) => { this.handleChangeInput(e) }} type="text" name="adminPhoneNumber" placeholder="06.07.08.09.10" className="form-control" required />
                                                            <FieldFeedbacks for="adminPhoneNumber">
                                                                <FieldFeedback when="*" />
                                                            </FieldFeedbacks>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label>Téléphone Fixe*</label>
                                                            <input value={this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex].homePhoneNumber ? this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex].homePhoneNumber : ""} onChange={(e) => { this.handleChangeInput(e) }} type="text" name="adminHomePhoneNumber" placeholder="01.02.03.04.05" className="form-control" />
                                                            <FieldFeedbacks for="adminHomePhoneNumber">
                                                                <FieldFeedback when="*" />
                                                            </FieldFeedbacks>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label>Téléphone Autre</label>
                                                            <input value={this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex].extraPhoneNumber ? this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex].extraPhoneNumber : ""} onChange={(e) => { this.handleChangeInput(e) }} type="text" name="adminExtraPhoneNumber" placeholder="01.02.03.04.05" className="form-control" />
                                                            <FieldFeedbacks for="adminExtraPhoneNumber">
                                                                <FieldFeedback when="*" />
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <label>Adresse Mail Administrateur (Login)*</label>
                                                            <input value={this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex].email ? this.state.selectedCompany.clientAdmins[this.state.selectedCompanyAdminIndex].email : ""} onChange={(e) => { this.handleChangeInput(e) }} type="text" name="AdminEmail" placeholder="adresse@mail.fr" className="form-control" required />
                                                            <FieldFeedbacks for="AdminEmail">
                                                                <FieldFeedback when="*" />
                                                            </FieldFeedbacks>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                                    

                                                <div className="formTitle">
                                                    <h2>Information Contact technique</h2>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <label>Nom contact Technique</label>
                                                        <input value={this.state.selectedCompany.technicalContactLastName ? this.state.selectedCompany.technicalContactLastName : null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="technicalContactLastName" placeholder="NOM Contact technique" className="form-control" />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <label>Prénom Contact Technique</label>
                                                        <input value={this.state.selectedCompany.technicalContactFirstName ? this.state.selectedCompany.technicalContactFirstName : null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="technicalContactFirstName" placeholder="Prénom Contact Technique" className="form-control" />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <label>Téléphone </label>
                                                        <input value={this.state.selectedCompany.technicalContactPhoneNumber ? this.state.selectedCompany.technicalContactPhoneNumber : null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="technicalContactPhoneNumber" placeholder="01.02.03.04.05" className="form-control" />
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <label>Adresse Mail</label>
                                                        <input value={this.state.selectedCompany.technicalContactEmail ? this.state.selectedCompany.technicalContactEmail: null} onChange={(e)=>{this.handleChangeInput(e)}} type="text" name="technicalContactEmail" placeholder="adresse@mail.fr" className="form-control" />
                                                    </div>
                                                </div>

                                                <div className="formTitle">
                                                    <h2>Historique d'archivage</h2>
                                                </div>

                                            {this.state.selectedCompanyIndex !== null && this.state.selectedCompany.archiveHistoList !== undefined &&
                                                <div className="row">
                                                    {this.state.selectedCompany.archiveHistoList.length > 0 &&
                                                        <div className="col-lg-12">
                                                            <a className='btn btn-export-archive' onClick={()=>this.exportClientArchived()}> <i className="lnr-download mr-1"></i> Exporter l'historique</a>
                                                        </div>
                                                    }
                                                    <div className="col-lg-12">
                                                        {  this.getClientHistoListTable()}
                                                    </div>
                                                </div>
                                            }

                                            </FormWithConstraints>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            );
        }

}

export default withRouter(Companies);
