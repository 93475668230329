import React from "react";

const ControlPanel = ({
  isExport=false,
  fileName,
  onPressDownloadSigned,
  onPressDownload,
  onPressPrint,
  onPressZoomOut,
  onPressZoomIn,
  onChangeSearchInput,
  refInput,
  onCleanSearch,
  onPressPrevResult,
  currentResult,
  totalResults,
  onPressNextResult,
  currentPage,
  nbPages,
  showBtnDownloadSignedContract
}) => (
  <div className="controls d-flex">
    <div className="fileName">{fileName}</div>
    <div className="exportBtns">
      {showBtnDownloadSignedContract &&
        <button onClick={onPressDownloadSigned} className="btn btm-light" title="Télécharger le contrat signé ou validé">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="13.4855" y1="27.4859" x2="22.5358" y2="27.2266" stroke="#5367F4" stroke-linecap="round" />
          <line x1="0.5" y1="-0.5" x2="3.44486" y2="-0.5" transform="matrix(-0.39392 0.919145 -0.919145 -0.393919 13.54 25.3809)" stroke="#5367F4" stroke-linecap="round" />
          <line x1="16.3864" y1="26.0246" x2="15.9605" y2="27.1602" stroke="#5367F4" stroke-linecap="round" />
          <line x1="18.1689" y1="26.0374" x2="17.0088" y2="28.7441" stroke="#5367F4" stroke-linecap="round" />
          <path d="M24.3787 25.6153L22.5214 23.7255C21.7827 24.6066 21.3481 25.712 21.2873 26.8711C22.4264 26.8092 23.5128 26.3671 24.3787 25.6153ZM23.0898 23.1238L24.97 25.037L34.2344 15.6101C34.4586 15.382 34.4586 15.0121 34.2344 14.784L33.166 13.6969C32.9418 13.4688 32.5784 13.4688 32.3542 13.6969L23.0898 23.1238ZM30.1295 14.7806C29.5184 14.2033 28.5629 14.2171 27.9684 14.8221L25.2599 17.578C25.0998 17.741 24.8402 17.741 24.6801 17.578C24.52 17.4151 24.52 17.151 24.6801 16.988L27.3885 14.2321C28.3033 13.3012 29.778 13.2873 30.7096 14.1904L31.7744 13.1069C32.3188 12.5529 33.2014 12.5529 33.7458 13.1069L34.8143 14.194C35.3587 14.748 35.3587 15.6461 34.8143 16.2001L25.2599 25.922C24.133 27.0688 22.6044 27.713 21.0107 27.713H20.87C20.6435 27.713 20.46 27.5262 20.46 27.2958V27.1526C20.46 25.5309 21.0931 23.9756 22.2201 22.8288L30.1295 14.7806Z" fill="#5367F4" stroke="#5367F4" />
        </svg>
      </button>}
      {isExport &&
        <button onClick={onPressDownload} className="btn btm-light" title="Téléchargement d'export">
          <i className="lnr-download"></i>
        </button>
      }
      <button onClick={onPressPrint} className="btn btm-light" title="Téléchargement du document initial">
        <i className="lnr-printer"></i>
      </button>
    </div>
    { currentPage && nbPages ?
      <div>
      <span><b>Page : </b>{currentPage} / {nbPages} </span><b></b>
    </div>
    : ""
    }
    <div className="zoom-controls">
      <button onClick={onPressZoomOut} className="btn btm-light">
        <i className="fa fa-search-minus"></i>
      </button>
      <button onClick={onPressZoomIn} className="btn btm-light">
        <i className="fa fa-search-plus"></i>
      </button>
    </div>
    <div className="search-zone d-flex">
      <button className="btn btn-default search-icon" htmlFor="search">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.749 12.7482L19.0003 19"
            stroke="#190734"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.88235 14.7647C11.6834 14.7647 14.7647 11.6834 14.7647 7.88235C14.7647 4.08133 11.6834 1 7.88235 1C4.08133 1 1 4.08133 1 7.88235C1 11.6834 4.08133 14.7647 7.88235 14.7647Z"
            stroke="#190734"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <input
        type="text"
        name="search"
        id="search"
        ref={refInput}
        onChange={onChangeSearchInput}
      />
      <button className="btn btn-default clear-input" onClick={onCleanSearch}>
        <svg
          width="9"
          height="9"
          viewBox="0 0 9 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.8125 7.81306L1.62528 1.625M7.8125 1.62556L1.625 7.81306L7.8125 1.62556Z"
            stroke="#190734"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <div className="navigateBtns">
        <button onClick={onPressPrevResult} className="btn btm-light">
          <i className="fa fa-angle-up"></i>
        </button>
        <span>
          {currentResult}/{totalResults}
        </span>
        <button onClick={onPressNextResult} className="btn btm-light">
          <i className="fa fa-angle-down"></i>
        </button>
      </div>
    </div>
  </div>
);

export default ControlPanel;
