import React from "react";
const CheckIcon = ({color = null}) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3920_24345)">
            <rect width="36" height="36" rx="18" fill={color || "#F66031"} />
            <path d="M12 18.375L16.125 22.5L24 14.25" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_3920_24345">
                <rect width="36" height="36" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default CheckIcon;

