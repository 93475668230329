import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useState } from "react";

// components
import EditMode from "../../../../../../components/edit-mode/EditMode";
import Header from "../../../../../../components/layouts/header/Header";
import Sidebar from "../../../../../../components/layouts/sidebar/Sidebar";

//icons
import dashBordIcon from "../../icons/dash-bord.svg";

import Cookies from "js-cookie";

//css
import "./layout.scss";

const PageLayout = ({ leftArea, rightArea, editMode, onChangeEditMode }) => {
  let currentClientCookie = Cookies.get("currentClient") ? JSON.parse(Cookies.get("currentClient")) : null;
  return (
    <div className="app-container dash-bord  app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
      <Header />

      <div className="app-main">
        <div className="app-sidebar sidebar-shadow">
          <Sidebar />
        </div>
        <div className="app-main__outer">
          <div
            className={`bg-under-header editModeTitle ${
              editMode ? "editMode" : ""
            }`}
          >
            <div className="_container" style={{ maxWidth: "100%" }}>
              <div className="row">
                <div className="col-sm-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div></div>
                    {
                      currentClientCookie && currentClientCookie["isManager"] &&
                      <div className="edit-mode">
                          <BootstrapSwitchButton
                            onChange={onChangeEditMode}
                            checked={editMode}
                            disabled={false}
                          />
                        {editMode ? "Désactiver" : "Activer"} le mode édition
                      </div>
                    }
                    {
                      currentClientCookie && !currentClientCookie["isManager"] &&
                      <div className="edit-mode">
                          <span className="ml-1">
                            Mode consultation
                          </span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="app-main__inner">
            <div className="row">
              <div className="col-md-4 left-side">
                <div className="head d-flex">
                  <img src={dashBordIcon} alt="" />
                  <h2>Tableaux de Bord</h2>
                </div>
                {leftArea}
              </div>
              <div className="col-md-8 viewer">
                <div className="ribbon"><span>Négociation</span></div>
                {/* <div className="text-center alert alert-danger">
                  <svg width="23" height="27" viewBox="0 0 112 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M58.8331 61.75C58.8331 63.3608 57.5273 64.6667 55.9165 64.6667C54.3057 64.6667 52.9998 63.3608 52.9998 61.75V38.4167C52.9998 36.8058 54.3057 35.5 55.9165 35.5C57.5273 35.5 58.8331 36.8058 58.8331 38.4167V61.75ZM52.9998 76.3333H58.8331V82.1667H52.9998V76.3333ZM53.3388 2.05199C54.4343 -0.0173315 57.3987 -0.0173315 58.4942 2.05199L110.994 101.219C112.023 103.161 110.614 105.5 108.416 105.5H3.41648C1.21856 105.5 -0.189614 103.161 0.838769 101.219L53.3388 2.05199ZM8.26079 99.6667H103.572L55.9165 9.65036L8.26079 99.6667Z" fill="#EB3A59" />
                  </svg><br />
                  En cours de dev
                </div> */}
                {rightArea}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;
