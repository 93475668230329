import React, { useEffect, useState } from "react";
import accents from 'remove-accents';

//icons
import PlusIcon from "../../../../components/SearchForm/icons/Plus.icon";

//scss
import "./fields.scss";
import { Draggable, Droppable } from "react-beautiful-dnd";

const Fields = ({ data, searchInput, filters, chartData, axie, onPressItem, sourceDestination }) => {
  const [fields, setFields] = useState(data)

  useEffect(()=>{
    let fields = data.filter(item=>{
      let inFilters = filters?.find(f=>f.name === item.name)
      let inChartData = chartData?.find(f=>f.name === item.name)
      let inAxie = axie?.name === item.name
      return (inFilters||inChartData||inAxie) ? false : true
    })
    setFields(fields)
  },[data, filters, chartData, axie])

  const _filter = item => {
    return accents.remove(item.name).toLowerCase()
    .includes(accents.remove(searchInput).toLowerCase())
  }

  return (
    <div className="graph-fields">
        <Droppable droppableId={'initData'}>
          {(provided, snapshot) => (
            <div className="row droppable"
              ref={provided.innerRef}
              {...provided.droppableProps}>
              {fields.filter(_filter).map((field, index) => (
                field.key !== 0 &&
                <Draggable key={field.key} draggableId={field.key} index={field.key} isDragDisabled={false}>
                  {(provided, snapshot) => (
                    <div className="col-sm-6 fieldDraggable-item mb-3"
                      ref={provided.innerRef}
                      {...provided.draggableProps}>
                        
                      <button
                        className="btn-icon btn-pill btn btn-danger d-flex justify-content-between align-items-center"
                        {...provided.dragHandleProps}
                      >
                        <span {...provided.dragHandleProps}>{field.name}</span>
                        <span {...provided.dragHandleProps} className="svg" onClick={() => onPressItem(field)}>
                          <PlusIcon />
                        </span>
                      </button>
                    </div>
                  )}
                </Draggable>

              ))}
            </div>
          )}
        </Droppable>
      </div>
  );
};

export default Fields;
