import React, { useEffect, useState } from 'react';
import Modal from '../../../../../../components/Modal';

import { t } from "i18next";
import { withTranslation } from 'react-i18next';


const ControlModal = ({ isOpen, onRequestClose, onDoAction }) => {

  

  return (
    <Modal
      title={t('frontoffice.contract_linked_docs.new_item.choice_title')}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      isFullScreen={false}
      footer={
        <>
          <button className='m-auto' onClick={() => onRequestClose(false)}>{t('common.cancel')}</button>
        </>
      }
    >
      <table className="control-action-modal w-auto">
        <tbody>
          <tr>
            <td className="zone-details">
              <p  onClick={() => onDoAction("new")}>
                <svg width="94" height="117" viewBox="0 0 94 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M93.6666 26.8138V101.943C93.6666 109.94 87.1375 116.423 79.0833 116.423H14.9166C6.86249 116.423 0.333313 109.94 0.333313 101.943V15.0685C0.333313 7.07184 6.86249 0.589302 14.9166 0.589302H67.2534C68.0278 0.540539 68.8338 0.796872 69.479 1.43747L92.8124 24.6041C93.4576 25.2447 93.7158 26.045 93.6666 26.8138ZM64.5 6.38097H14.9166C10.0842 6.38097 6.16665 10.2705 6.16665 15.0685V101.943C6.16665 106.741 10.0842 110.631 14.9166 110.631H79.0833C83.9158 110.631 87.8333 106.741 87.8333 101.943V29.5476H67.4166C65.8058 29.5476 64.5 28.2511 64.5 26.6518V6.38097ZM70.3333 10.4763V23.756H83.7085L70.3333 10.4763ZM47 51.0122V90.3543C47 91.9537 45.6941 93.2502 44.0833 93.2502C42.4725 93.2502 41.1666 91.9537 41.1666 90.3543V51.0122L28.6457 63.4437C27.5067 64.5746 25.6599 64.5746 24.5209 63.4437C23.3819 62.3128 23.3819 60.4792 24.5209 59.3483L42.0209 41.9733C43.1599 40.8424 45.0067 40.8424 46.1457 41.9733L63.6457 59.3483C64.7847 60.4792 64.7847 62.3128 63.6457 63.4437C62.5067 64.5746 60.6599 64.5746 59.5209 63.4437L47 51.0122Z" fill="black" />
                </svg>
                {t('frontoffice.contract_linked_docs.new_item.new_doc')}
              </p>
            </td>
          </tr>
          <tr>
            <td className="zone-details">
              <p onClick={() => onDoAction("exists")}>
                <svg width="95" height="106" viewBox="0 0 95 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24 23.8333V29.6667H15.1875C10.3205 29.6667 6.37497 33.5842 6.37497 38.4167V90.9167C6.37497 95.7491 10.3205 99.6667 15.1875 99.6667H56.3125C61.1795 99.6667 65.125 95.7491 65.125 90.9167V82.1667H71V90.9167C71 98.9708 64.4242 105.5 56.3125 105.5H15.1875C7.07579 105.5 0.499969 98.9708 0.499969 90.9167V38.4167C0.499969 30.3625 7.07579 23.8333 15.1875 23.8333H24ZM71 6.33332H38.6875C33.8205 6.33332 29.875 10.2508 29.875 15.0833V67.5833C29.875 72.4158 33.8205 76.3333 38.6875 76.3333H79.8125C84.6795 76.3333 88.625 72.4158 88.625 67.5833V23.8333H73.9375C72.3151 23.8333 71 22.5275 71 20.9167V6.33332ZM94.5 21.0799V67.5833C94.5 75.6375 87.9241 82.1667 79.8125 82.1667H38.6875C30.5758 82.1667 24 75.6375 24 67.5833V15.0833C24 7.02917 30.5758 0.499988 38.6875 0.499988H73.7731C74.553 0.450874 75.3648 0.709052 76.0146 1.35426L93.6396 18.8543C94.2894 19.4995 94.5494 20.3055 94.5 21.0799ZM76.875 18H84.4707L76.875 10.4581V18ZM44.5625 58.8333C42.9401 58.8333 41.625 57.5275 41.625 55.9167C41.625 54.3058 42.9401 53 44.5625 53H56.3125C57.9348 53 59.25 54.3058 59.25 55.9167C59.25 57.5275 57.9348 58.8333 56.3125 58.8333H44.5625ZM44.5625 47.1667C42.9401 47.1667 41.625 45.8608 41.625 44.25C41.625 42.6392 42.9401 41.3333 44.5625 41.3333H73.9375C75.5598 41.3333 76.875 42.6392 76.875 44.25C76.875 45.8608 75.5598 47.1667 73.9375 47.1667H44.5625ZM44.5625 35.5C42.9401 35.5 41.625 34.1942 41.625 32.5833C41.625 30.9725 42.9401 29.6667 44.5625 29.6667H73.9375C75.5598 29.6667 76.875 30.9725 76.875 32.5833C76.875 34.1942 75.5598 35.5 73.9375 35.5H44.5625Z" fill="black" />
                </svg>
                {t('frontoffice.contract_linked_docs.new_item.exists_doc')}
              </p>
            </td>
          </tr>
          <tr>
            <td className="zone-details">
              <p onClick={() => onDoAction("contract")}>
                <svg width="94" height="117" viewBox="0 0 94 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.24998 81.6668C4.86081 81.6668 6.16664 82.9633 6.16664 84.5627V101.943C6.16664 106.741 10.0842 110.631 14.9166 110.631H79.0833C83.9158 110.631 87.8333 106.741 87.8333 101.943V29.5476H67.4166C65.8058 29.5476 64.5 28.2511 64.5 26.6518V6.38097H14.9166C10.0842 6.38097 6.16664 10.2705 6.16664 15.0685V26.646C6.16664 28.2453 4.86081 29.5418 3.24998 29.5418C1.63915 29.5418 0.333309 28.2453 0.333309 26.646V15.0685C0.333309 7.07184 6.86249 0.589302 14.9166 0.589302H67.2534C68.0278 0.540539 68.8338 0.796872 69.479 1.43747L92.8124 24.6041C93.4576 25.2447 93.7158 26.045 93.6666 26.8138V101.943C93.6666 109.94 87.1375 116.423 79.0833 116.423H14.9166C6.86249 116.423 0.333309 109.94 0.333309 101.943V84.5627C0.333309 82.9633 1.63915 81.6668 3.24998 81.6668ZM70.3333 10.4763V23.756H83.7085L70.3333 10.4763ZM48.7085 52.7085L36.1876 40.277C35.0485 39.1461 35.0485 37.3126 36.1876 36.1817C37.3266 35.0508 39.1733 35.0508 40.3124 36.1817L57.8124 53.5567C58.9514 54.6876 58.9514 56.5211 57.8124 57.652L40.3124 75.027C39.1733 76.1579 37.3266 76.1579 36.1876 75.027C35.0485 73.8961 35.0485 72.0626 36.1876 70.9317L48.7085 58.5002H3.24998C1.63915 58.5002 0.333309 57.2037 0.333309 55.6043C0.333309 54.005 1.63915 52.7085 3.24998 52.7085H48.7085Z" fill="black" />
                </svg>
                {t('frontoffice.contract_linked_docs.new_item.a_contract')}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default withTranslation()(ControlModal);
