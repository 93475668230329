import React from "react";


import "./search-graph.scss";


const SearchGraph = ({onPress, isRotated=false}) => {

 

  return(
    <div className="dash-bord-search">
      <div className="d-flex justify-content-end">
        <button onClick={onPress} disabled={isRotated}>
          <svg width="40" height="40" viewBox="0 0 139 140" fill="none" xmlns="http://www.w3.org/2000/svg" className={isRotated ? 'rotateInSearch':''}>
            <path d="M28.9582 36.9963C38.7285 24.8232 53.4959 17.5 69.4998 17.5C98.2877 17.5 121.625 41.0051 121.625 70C121.625 98.9949 98.2877 122.5 69.4998 122.5C41.9052 122.5 19.1351 100.839 17.4717 73.2261C17.3749 71.6182 18.5905 70.2357 20.1869 70.1381C21.7832 70.0406 23.1559 71.2649 23.2528 72.8728C24.7309 97.4112 44.9721 116.667 69.4998 116.667C95.089 116.667 115.833 95.7733 115.833 70C115.833 44.2267 95.089 23.3333 69.4998 23.3333C55.1978 23.3333 42.0123 29.9083 33.3265 40.8333H49.229C50.8283 40.8333 52.1248 42.1392 52.1248 43.75C52.1248 45.3608 50.8283 46.6667 49.229 46.6667H26.0623C24.463 46.6667 23.1665 45.3608 23.1665 43.75V20.4167C23.1665 18.8058 24.463 17.5 26.0623 17.5C27.6616 17.5 28.9582 18.8058 28.9582 20.4167V36.9963Z" fill="#f66031" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default SearchGraph;
