import React, { Component } from 'react';

class CustomDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedOption: null
    };
    this.dropdownRef = React.createRef(); // Create a ref to the dropdown container
  }

  componentDidMount() {
    // Add a click event listener to the document to handle clicks outside the component
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    // Remove the event listener when the component is unmounted
    document.removeEventListener('click', this.handleClickOutside);
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  selectOption = (option, action) => {
    this.setState({
      selectedOption: option,
      isOpen: false
    });
    // Call the onSelect prop with the selected option
    if (this.props.onSelect) {
        this.props.onSelect({
          selectedOption: option,
          action: action
        });
    }
  };

  addSelectOption = () => {
    this.setState({
      isOpen: false,
    });
    if (this.props.onAddSelected) {
        this.props.onAddSelected();
    }
  };

  // Add this method to prevent the page from reloading
  handleButtonClick = (e) => {
    e.preventDefault();
    this.toggleDropdown();
  };

  // Handle clicks outside the component
  handleClickOutside = (e) => {
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
      // Click occurred outside the dropdown, so close it
      this.setState({
        isOpen: false,
      });
    }
  };

  render() {
    const {options, disabled, selectedValue } = this.props;
    const { isOpen, selectedOption } = this.state;
    return (
      <div className="custom-dropdown" ref={this.dropdownRef}>
        <button disabled={disabled} onClick={this.handleButtonClick} className="dropdown-toggle form-control" style={{color:selectedValue ? "#219653": "black"}}>
          {selectedValue ? selectedValue :  "--- Votre Sélection ---"}
        </button>
        {isOpen && (
          <ul className="dropdown-list text-center" style={{fontFamily:'sans-serif'}}>
            {options.map((option, key) => (
              <li
                key={option.type}
                
                className="dropdown-item" style={{paddingTop: (selectedOption && selectedOption.type == option.type || option.type === selectedValue) ? "4px" :"10px", paddingBottom: (selectedOption && selectedOption.type == option.type || option.type === selectedValue) ? "4px" :"10px", fontFamily:'sans-serif', backgroundColor: (selectedOption && selectedOption.type == option.type || option.type === selectedValue) ? "#f6603138": "transparent" ,color: "black", fontWeight: 400}}
              >
                {(!(selectedOption && selectedOption.type == option.type || option.type === selectedValue)) &&
                  <svg onClick={() => this.selectOption(option, "select")} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10.5" cy="10.5" r="10" fill="#FAFCFE" stroke="#190734" />
                  </svg>
                }
                {(selectedOption && selectedOption.type == option.type || option.type === selectedValue) &&
                  <svg onClick={()=>{this.selectOption(option, "desassociate"); this.setState({selectedOption: null})}} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginLeft:"-6px"}}>
                    <circle cx="18" cy="18" r="11" fill="#FAFCFE" stroke="#190734" />
                    <circle cx="18" cy="18" r="8.5" fill="#F66031" />
                  </svg>
                }
                <span onClick={() => this.selectOption(option, "select")}>{option.type}</span>
                {(option.isAssociate && selectedOption && selectedOption.type == option.type || option.type === selectedValue) &&
                <span className='revok-btn' onClick={()=>{this.selectOption(option, "desassociate"); this.setState({selectedOption: null})}}>Désassocier</span>}
                {(!option.isAssociated) &&
                <span className='del-btn' onClick={()=>{this.selectOption(option, "delete"); this.setState({selectedOption: null})}}>Supprimer</span>}
              </li>
            ))}
            <li
                onClick={() => this.addSelectOption()}
                className="dropdown-item" style={{fontFamily:'sans-serif', color: "black", fontWeight: 600}}
              >
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_7678_22696)">
                  <path d="M23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23C17.8513 23 23 17.8513 23 11.5Z" fill="#5367F4" />
                  <path d="M15.3327 11.5H7.66602H15.3327ZM11.4993 7.66666V15.3333V7.66666Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_7678_22696">
                    <rect width="23" height="23" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              Ajouter

              </li>
          </ul>
        )}
      </div>
    );
  }
}

export default CustomDropdown;
