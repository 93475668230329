import React from "react";
import { CopyIcon } from "../../../assets/icons";

const ButtonCopy = ({ onPress}) => (
  <button
    type="button"
    className={`btn btn-default icon-restriction`}
    onClick={onPress}
  >
    <CopyIcon />
  </button>
);

export default ButtonCopy;
