import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"
import Moment from 'moment';
import errorAlert from '../components/confirm-alert/error-alert';
axios.defaults.timeout = 100000;

export const getCategoriesByImportRightForUser = () => {
  const requestUrl = API_URL+`/category/getCategoryForUserByRightType?access_token=`+getConnectedUserAccessToken();
  var formData = new FormData();
  formData.append('client', getCurrentClientID());
  formData.append('rightType', 0);
  return axios.post(requestUrl, formData);
};

export const getCategoriesForContractValidation = () => {
  const requestUrl = API_URL+`/category/getCategoriesForContractValidation?access_token=`+getConnectedUserAccessToken();
  var formData = new FormData();
  formData.append('client', getCurrentClientID());
  return axios.post(requestUrl, formData);
};

export const updateCategory = (contractID, categoryID) => {
  const requestUrl = API_URL+`/contract/updateCategory?access_token=`+getConnectedUserAccessToken();
  var formData = new FormData();
  formData.append('contract', contractID);
  formData.append('category', categoryID);
  return axios.post(requestUrl, formData);
};

export const getContractOcerizationInfo = (contractHashedID, withNoFile = false) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/contract/getContractOserizationInfo?access_token=`+getConnectedUserAccessToken();
  formData.append('contractHashedID', contractHashedID);
  // formData.append('getHTMLValidationContent', 1);
  if(withNoFile) {
    //formData.append('withNoFile', withNoFile);
  }
  return axios({
    method: 'post',
    url: requestUrl,
    data: formData,
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    timeout: Infinity,
});
};

export const getContractHTMLFile = (contractHashedID, getHTMLValidationContent = false, getHTMLCommentContent = false, getHTMLContent = false) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/contract/getContractHTMLFile?access_token=`+getConnectedUserAccessToken();
  formData.append('contractHashedID', contractHashedID);
  if(getHTMLValidationContent)
  formData.append('getHTMLValidationContent', getHTMLValidationContent ? 1:0); //Fiche contrat
  if(getHTMLCommentContent)
    formData.append('getHTMLCommentContent', getHTMLCommentContent ? 1:0); //Page commentaires
  if(getHTMLContent)
    formData.append('getHTMLContent', getHTMLContent ? 1:0); // Les autres pages 
  return axios({
    method: 'post',
    url: requestUrl,
    data: formData,
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    timeout: Infinity,
});
};

export const getContractOcerizationInfoForGuests = (contractHashedID, withNoFile = false) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/contract/getContractGuest?access_token=`+getConnectedUserAccessToken();
  formData.append('contractHashedID', contractHashedID);
  formData.append('getHTMLValidationContent', 1);
  if(withNoFile) {
    //formData.append('withNoFile', withNoFile);
  }
  return axios({
    method: 'post',
    url: requestUrl,
    data: formData,
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    timeout: Infinity,
});
};


export const downloadContractFromS3Server = (contractID) => {
  const requestUrl = API_URL+`/contract/getContractFileFromS3/`+contractID+`?access_token=`+getConnectedUserAccessToken();
  window.open(requestUrl, '_blank');
};

export const downloadSignedContractFromDocusignServer = (contractID) => {
  const requestUrl = API_URL+`/contract/getSignedContract/`+contractID+`?access_token=`+getConnectedUserAccessToken();
  window.open(requestUrl, '_blank');
};

export const getOtherClientContracts = (categoryId) => {
  const requestUrl = API_URL+`/contract/getContractsByCategory/`+categoryId+`?access_token=`+getConnectedUserAccessToken();
  return axios.get(requestUrl);
};

export const getClientKeywordByCategory = (categoryId) => {
  const requestUrl = API_URL+`/clientKeyword/getClientKeywordByCategory/`+categoryId+`?access_token=`+getConnectedUserAccessToken();
  return axios.get(requestUrl);
};

export const saveContractDraft = (contract, signatory1, signatory2, amount, contractDuration, priorNoticeDuration, negociationDuration, endingDate,
                                  negociationBeginingDate, contractDurationType, negociationDurationType, priorNoticeDurationType, priorNoticeDate,
                                  extraKeywordsWithValues, pdfValidationContent, beginningDate, objet, contractCode, renewal, dueAmount, dueFrequency,
                                  dueFrequencyType, totalAmoutInitialPeriod, amountType, amountTerm, contractAlerts) => {
  const requestUrl = API_URL+`/contract/saveContractValidationDraft?access_token=`+getConnectedUserAccessToken();
  var keywordsValues = [];
  var docAtWebKeywordsValues = [];
  var clientKeywordsValues = [];
  var i;
  var formData = new FormData();

  console.log('amountTerm', amountTerm)

  formData.append('contract', contract.id);
  if (contract.name) {
    formData.append('name', contract.name);
  }

  /*if (contract.contractCode) {
    formData.append('contractCode', contract.contractCode);
  }*/
  if(contractAlerts){
    formData.append('alerts[negociation]', contractAlerts.negciationAlert);
    formData.append('alerts[priorNotice]', contractAlerts.priorNoticeAlert);
    formData.append('alerts[ending]', contractAlerts.endingAlert);
    formData.append('alerts[isValidated]', 1);
  }

  if(contract.category && contract.category!=[]) {
    if(contract.category.id) {
      formData.append('category', contract.category.id);
    } else {
      formData.append('category', contract.category);
    }
  }
  if(contract.subCategory && contract.subCategory!=[]) {
    if(contract.subCategory.id) {
      formData.append('subCategory', contract.subCategory.id);
    } else {
      formData.append('subCategory', contract.subCategory);
    }
  }

  if(contract.parentContract && contract.parentContract!=[]) {
    if(contract.parentContract.id) {
      formData.append('parentContract', contract.parentContract.id);
    } else {
      formData.append('parentContract', contract.parentContract);
    }
  }

  for (i = 0; i < contract.keywords.docAtWebKeywords.length; i++) {
    var singleKeywordValue = null;
    var durationType = null;
    var durationJson = null;
    if (contract.keywords.docAtWebKeywords[i].name == "Nom Signataire Fournisseur" && signatory1) {
      singleKeywordValue = signatory1.split("'").join(' ')
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Nom Signataire interne" && signatory2) {
      singleKeywordValue = signatory2.split("'").join(' ')
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Durée" && contractDuration) {
      singleKeywordValue = JSON.stringify(contractDuration)
      durationType = contractDurationType
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Préavis") {
      if(priorNoticeDuration == 0) {
        singleKeywordValue = JSON.stringify(0)
        durationType = priorNoticeDurationType
      }
      if(priorNoticeDuration) {
        singleKeywordValue = JSON.stringify(priorNoticeDuration)
        durationType = priorNoticeDurationType
      }
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Négociation" && negociationDuration) {
      singleKeywordValue = JSON.stringify(negociationDuration)
      durationType = negociationDurationType
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Fréquence échéances" && dueFrequency) {
      singleKeywordValue = JSON.stringify(dueFrequency)
      durationType = dueFrequencyType
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Date de fin" && endingDate) {
      singleKeywordValue = endingDate
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Date de début" && beginningDate) {
      singleKeywordValue = beginningDate
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Objet" && objet) {
      singleKeywordValue = objet
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Numéro du contrat" && contractCode) {
      singleKeywordValue = contractCode
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Renouvellement" /*&& renewal*/) {
      singleKeywordValue = renewal
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Montant échéances" && dueAmount) {
      singleKeywordValue = dueAmount
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Montant Global du Contrat" && amount) {
      singleKeywordValue = amount
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Montant Total de la période initiale du contrat" && totalAmoutInitialPeriod) {
      singleKeywordValue = JSON.stringify(totalAmoutInitialPeriod)
      durationType = contractDurationType
    }
    if (contract.keywords.docAtWebKeywords[i].name == "negociationBeginingDate" && negociationBeginingDate) {
      singleKeywordValue = negociationBeginingDate
    }

    if (contract.keywords.docAtWebKeywords[i].name == "priorNoticeDate" && priorNoticeDate) {
      singleKeywordValue = priorNoticeDate
    }

    if (contract.keywords.docAtWebKeywords[i].name === "Frais Installation/FAS") {
      durationJson = '{'
          +'"amount":"'+totalAmoutInitialPeriod+'",'
          +'"type":"'+amountType+'",'
          +'"term":"'+amountTerm+'"'
          +'}';
      var keywordValue = '"'+(i+1)+'":{'
          +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
          +'"value":'+durationJson+','
          +'"position":""'
          +'}';
      keywordsValues.push(keywordValue.toString())
    }

    if (singleKeywordValue && singleKeywordValue != "") {
        if(contract.keywords.docAtWebKeywords[i].name !== "Préavis" && contract.keywords.docAtWebKeywords[i].name !== "Négociation" && contract.keywords.docAtWebKeywords[i].name !== "Durée" && contract.keywords.docAtWebKeywords[i].name !== "Fréquence échéances" ) {
        var keywordValue = '"'+(i+1)+'":{'
         +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
         +'"value":"'+singleKeywordValue+'",'
         +'"position":""'
         +'}';
         keywordsValues.push(keywordValue.toString())
      } else {
        if(JSON.parse(singleKeywordValue).duration || JSON.parse(singleKeywordValue).duration == 0) {
          durationJson = '{'
            +'"duration":"'+JSON.parse(singleKeywordValue).duration+'",'
            +'"durationType":"'+durationType+'"'
            +'}';
            var keywordValue = '"'+(i+1)+'":{'
            +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
            +'"value":'+durationJson+','
            +'"position":""'
            +'}';
            keywordsValues.push(keywordValue.toString())
        } else if (!JSON.parse(singleKeywordValue).hasOwnProperty("duration")) {
          durationJson = '{'
            +'"duration":'+singleKeywordValue+','
            +'"durationType":"'+durationType+'"'
            +'}';
            var keywordValue = '"'+(i+1)+'":{'
            +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
            +'"value":'+durationJson+','
            +'"position":""'
            +'}';
            keywordsValues.push(keywordValue.toString())
        }
      }
    }
  }

  let docatwebKeywordIndex = i;
  let clientKeywordIndex = 0
  for (let j = 0; j < extraKeywordsWithValues.length; j++) {
    let keywordValueElement = extraKeywordsWithValues[j].keywordValue
    if(extraKeywordsWithValues[j].isdate === true && extraKeywordsWithValues[j].keywordValue && !extraKeywordsWithValues[j].keywordValue.includes("/")) {
      keywordValueElement = Moment(extraKeywordsWithValues[j].keywordValue).format('DD/MM/YYYY')
    }
      if(extraKeywordsWithValues[j].type === "docAtWebKeyword") {
        var keywordValue = '"'+(docatwebKeywordIndex+1)+'":{'
         +'"id":"'+extraKeywordsWithValues[j].value+'",'
         +'"value":"'+(keywordValueElement ? (keywordValueElement).split("'").join(' '):"")+'",'
         +'"position":""'
         +'}';
         keywordsValues.push(keywordValue.toString())
         docatwebKeywordIndex++
      } else {
        var clientKeyword = '"'+(clientKeywordIndex+1)+'":{'
         +'"id":"'+extraKeywordsWithValues[j].value+'",'
         +'"value":"'+(keywordValueElement ? (keywordValueElement).split("'").join(' '):"")+'",'
         +'"position":""'
         +'}';
         clientKeywordsValues.push(clientKeyword)
         clientKeywordIndex++
      }
  }

  formData.append('htmlValidationContent', pdfValidationContent);

  if (keywordsValues.length) {
    formData.append('keywords[docAtWebKeywords]', '{'+keywordsValues.toString()+'}');
  }

  if (clientKeywordsValues.length) {
    formData.append('keywords[clientKeywords]', '{'+clientKeywordsValues.toString()+'}');
  }

  console.log('test', '{'+keywordsValues.toString()+'}')
  console.log('test client', '{'+clientKeywordsValues.toString()+'}')
  return axios.post(requestUrl, formData);
};

export const calculateTheContractAlerts = (contractHashedID, ending, negociationDuration, priorNoticeDuration, negociationDurationType, priorNoticeDurationType) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/contract/calculateTheContractAlerts?access_token=`+getConnectedUserAccessToken();
  formData.append('contract', contractHashedID);
  formData.append('ending', ending);

  if(priorNoticeDuration.duration) {
    formData.append('priorNoticeDuration', '{'+'"duration":'+priorNoticeDuration.duration+','+'"durationType":"'+priorNoticeDurationType+'"'+'}');
  } else {
    formData.append('priorNoticeDuration', '{'+'"duration":'+priorNoticeDuration+','+'"durationType":"'+priorNoticeDurationType+'"'+'}');
  }
  if(negociationDuration.duration) {
    formData.append('negociationDuration', '{'+'"duration":'+negociationDuration.duration+','+'"durationType":"'+negociationDurationType+'"'+'}');
  } else {
    formData.append('negociationDuration', '{'+'"duration":'+negociationDuration+','+'"durationType":"'+negociationDurationType+'"'+'}');
  }
  return axios.post(requestUrl, formData);
};

export const validateContract = (contract, signatory1, signatory2, amount, contractDuration, priorNoticeDuration, negociationDuration, endingDate, negociationBeginingDate, contractDurationType, negociationDurationType, priorNoticeDurationType, priorNoticeDate, extraKeywordsWithValues, pdfValidationContent, contractAlerts, beginningDate, objet, contractCode, renewal, dueAmount, dueFrequency, dueFrequencyType, totalAmoutInitialPeriod, amountType, amountTerm, sign=false, allGuests='', canDuplicateName=false, canDuplicateNumber=false, changesAcceptedKeywords=[]) => {
  const requestUrl = API_URL+`/contract/validateContract?access_token=`+getConnectedUserAccessToken();
  var keywordsValues = [];
  var docAtWebKeywordsValues = [];
  var clientKeywordsValues = [];
  var i;
  var formData = new FormData();
  
  if ((contract.inNegociationBy && sign.toString() === 'true') || sign.toString() === 'true') {
    formData.append('sign', 1);
  }

  if (canDuplicateName && canDuplicateName.toString() !== '') {
    if (canDuplicateName.toString() === 'true') {
      formData.append('canDuplicateName', 1);
    }
    if (canDuplicateName.toString() === 'false') {
      formData.append('canDuplicateName', 0);
    }
  }
  if (canDuplicateNumber && canDuplicateNumber.toString() !== '') {
    if (canDuplicateNumber.toString() === 'true') {
      formData.append('canDuplicateNumber', 1);
    }
    if (canDuplicateNumber.toString() === 'false') {
      formData.append('canDuplicateNumber', 0);
    }
  }
  
  if (allGuests && allGuests.toString() !== '') {
    if (allGuests.toString() === 'true') {
      formData.append('allGuests', 1);
    }
    if (allGuests.toString() === 'false') {
      formData.append('allGuests', 0);
    }
  }

  formData.append('contract', contract.id);
  if (contract.name) {
    formData.append('name', contract.name);
  }
  /*if (contract.contractCode) {
    formData.append('contractCode', contract.contractCode);
  }*/

  if(contract.category && contract.category!=[]) {
    if(contract.category.id) {
      formData.append('category', contract.category.id);
    } else {
      formData.append('category', contract.category);
    }
  }
  if(contract.subCategory && contract.subCategory!=[]) {
    if(contract.subCategory.id) {
      formData.append('subCategory', contract.subCategory.id);
    } else {
      formData.append('subCategory', contract.subCategory);
    }
  }

  if(contract.parentContract && contract.parentContract!=[]) {
    if(contract.parentContract.id) {
      formData.append('parentContract', contract.parentContract.id);
    } else {
      formData.append('parentContract', contract.parentContract);
    }
  }
  for (i = 0; i < contract.keywords.docAtWebKeywords.length; i++) {
    var singleKeywordValue = null;
    var durationType = null;
    var durationJson = null;
    if (contract.keywords.docAtWebKeywords[i].name == "Nom Signataire Fournisseur" && signatory1) {
      singleKeywordValue = signatory1.split("'").join(' ')
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Nom Signataire interne" && signatory2) {
      singleKeywordValue = signatory2.split("'").join(' ')
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Durée" && contractDuration) {
      singleKeywordValue = JSON.stringify(contractDuration)
      durationType = contractDurationType
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Préavis") {
      if(priorNoticeDuration == 0) {
        singleKeywordValue = JSON.stringify(0)
        durationType = priorNoticeDurationType
      }
      if(priorNoticeDuration) {
        singleKeywordValue = JSON.stringify(priorNoticeDuration)
        durationType = priorNoticeDurationType
      }
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Négociation" && negociationDuration) {
      singleKeywordValue = JSON.stringify(negociationDuration)
      durationType = negociationDurationType
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Fréquence échéances" && dueFrequency) {
      singleKeywordValue = JSON.stringify(dueFrequency)
      durationType = dueFrequencyType
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Date de fin" && endingDate) {
      singleKeywordValue = endingDate
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Date de début" && beginningDate) {
      singleKeywordValue = beginningDate
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Objet" && objet) {
      singleKeywordValue = objet
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Numéro du contrat" && contractCode) {
      singleKeywordValue = contractCode
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Renouvellement" /*&& renewal*/) {
      singleKeywordValue = renewal
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Montant échéances" && dueAmount) {
      singleKeywordValue = dueAmount
    }
    if (contract.keywords.docAtWebKeywords[i].name == "Montant Global du Contrat" && amount) {
      singleKeywordValue = amount
    }
    if (contract.keywords.docAtWebKeywords[i].name == "negociationBeginingDate" && negociationBeginingDate && negociationBeginingDate !== undefined && negociationBeginingDate !== "undefined") {
      singleKeywordValue = negociationBeginingDate
    }

    if (contract.keywords.docAtWebKeywords[i].name == "priorNoticeDate" && priorNoticeDate && priorNoticeDate !== undefined && priorNoticeDate !== "undefined") {
      singleKeywordValue = priorNoticeDate
    }

    if (contract.keywords.docAtWebKeywords[i].name === "Frais Installation/FAS") {
      durationJson = '{'
          +'"amount":"'+(typeof totalAmoutInitialPeriod === "object" ? totalAmoutInitialPeriod["amount"]:totalAmoutInitialPeriod)+'",'
          +'"type":"'+amountType+'",'
          +'"term":"'+amountTerm+'"'
          +'}';
      var keywordValue = '"'+(i+1)+'":{'
          +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
          +'"value":'+durationJson+','
          +'"position":""'
          +'}';
      keywordsValues.push(keywordValue.toString())
    }

    if (singleKeywordValue && singleKeywordValue != "") {
      if(contract.keywords.docAtWebKeywords[i].name !== "Préavis" && contract.keywords.docAtWebKeywords[i].name !== "Négociation" && contract.keywords.docAtWebKeywords[i].name !== "Durée" && contract.keywords.docAtWebKeywords[i].name !== "Fréquence échéances" ) {
        var keywordValue = '"'+(i+1)+'":{'
         +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
         +'"value":"'+singleKeywordValue+'",'
         +'"position":""'
         +'}';
         keywordsValues.push(keywordValue.toString())
      } else {
        if(JSON.parse(singleKeywordValue).duration || JSON.parse(singleKeywordValue).duration == 0) {
          durationJson = '{'
            +'"duration":"'+JSON.parse(singleKeywordValue).duration+'",'
            +'"durationType":"'+durationType+'"'
            +'}';
            var keywordValue = '"'+(i+1)+'":{'
            +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
            +'"value":'+durationJson+','
            +'"position":""'
            +'}';
            keywordsValues.push(keywordValue.toString())
        } else if (!JSON.parse(singleKeywordValue).hasOwnProperty("duration")) {
          durationJson = '{'
            +'"duration":'+singleKeywordValue+','
            +'"durationType":"'+durationType+'"'
            +'}';
            var keywordValue = '"'+(i+1)+'":{'
            +'"id":"'+contract.keywords.docAtWebKeywords[i].id+'",'
            +'"value":'+durationJson+','
            +'"position":""'
            +'}';
            keywordsValues.push(keywordValue.toString())
        }
      }
    }
  }


  let docatwebKeywordIndex = i;
  let clientKeywordIndex = 0
  for (let j = 0; j < extraKeywordsWithValues.length; j++) {
    let keywordValueElement = extraKeywordsWithValues[j].keywordValue
    if(extraKeywordsWithValues[j].isdate === true && extraKeywordsWithValues[j].keywordValue && !extraKeywordsWithValues[j].keywordValue.includes("/")) {
      keywordValueElement = Moment(extraKeywordsWithValues[j].keywordValue).format('DD/MM/YYYY')
    }
      if(extraKeywordsWithValues[j].type === "docAtWebKeyword") {
        var keywordValue = '"'+(docatwebKeywordIndex+1)+'":{'
         +'"id":"'+extraKeywordsWithValues[j].value+'",'
         +'"value":"'+(keywordValueElement).split("'").join(' ')+'",'
         +'"position":""'
         +'}';
         keywordsValues.push(keywordValue.toString())
         docatwebKeywordIndex++
      } else {
        var clientKeyword = '"'+(clientKeywordIndex+1)+'":{'
         +'"id":"'+extraKeywordsWithValues[j].value+'",'
         +'"value":"'+(keywordValueElement).split("'").join(' ')+'",'
         +'"position":""'
         +'}';
         clientKeywordsValues.push(clientKeyword)
         clientKeywordIndex++
      }
  }
  formData.append('htmlValidationContent', pdfValidationContent);

  if (keywordsValues.length) {
    formData.append('keywords[docAtWebKeywords]', '{'+keywordsValues.toString()+'}');
  }

  if (clientKeywordsValues.length) {
    formData.append('keywords[clientKeywords]', '{'+clientKeywordsValues.toString()+'}');
  }
  formData.append('alerts[negociation]', contractAlerts.negciationAlert);
  formData.append('alerts[priorNotice]', contractAlerts.priorNoticeAlert);
  formData.append('alerts[ending]', contractAlerts.endingAlert);
  formData.append('alerts[isValidated]', 1);
  console.log("changesAcceptedKeywords=======>",changesAcceptedKeywords)
  if(changesAcceptedKeywords && changesAcceptedKeywords.length > 0){
    let changedKeywords = changesAcceptedKeywords.filter(x=>x.changed === true)
    if(changedKeywords && changedKeywords.length>0){
      changedKeywords = changedKeywords.map(nego => {
        return {
          negociation: nego.negociation,
          value: nego.value
        };
      });
      console.log("changedKeywords=======>",changedKeywords)
      for (let index = 0; index < changedKeywords.length; index++) {
        const element = changedKeywords[index];
        formData.append('changes['+ index +']', JSON.stringify(element));
      }
    }
  }


    // Display FormData entries in the console
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

  return axios.post(requestUrl, formData);
};

export const calculateTheContractEndingDateAndDuration = (contractDuration, contractDurationType, beginningDate, endingDate) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/contract/calculateTheContractEndingDateAndDuration?access_token=`+getConnectedUserAccessToken();

  formData.append('beginningDate', beginningDate);
  if(contractDuration) {
    /*if(contractDuration.duration) {
      formData.append('contractDuration', contractDuration.duration);
    } else {
      formData.append('contractDuration', contractDuration);
    }*/
    formData.append('contractDuration', contractDuration);
  }
 if(contractDurationType) {
    formData.append('contractDurationType ', contractDurationType);
    formData.append('contractDurationType', contractDurationType);
  }
  if(endingDate) {
    formData.append('endingDate', endingDate);
  }

  return axios.post(requestUrl, formData);
};


export const calculateContractsAmount = (contractDuration, contractDurationType, dueFrequency, dueFrequencyType, dueAmount, totalAmoutInitialPeriod) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/contract/calculateTheContractAmount?access_token=`+getConnectedUserAccessToken();

  if(contractDuration.duration) {
    formData.append('contractDuration', contractDuration.duration);
  } else {
    formData.append('contractDuration', contractDuration);
  }
  formData.append('contractDurationType', contractDurationType);

  if(dueFrequency.duration) {
    formData.append('dueFrequency', dueFrequency.duration);
  } else {
    formData.append('dueFrequency', dueFrequency);
  }
  formData.append('dueFrequencyType', dueFrequencyType);

  if(dueAmount) {
    formData.append('dueAmount', dueAmount);
  }
  if(totalAmoutInitialPeriod) {
    formData.append('totalInitialAmount', totalAmoutInitialPeriod);
  }
  return axios.post(requestUrl, formData);
};

export const calculateTheContractTotalAmount = (contractDuration, contractDurationType, dueFrequency, dueFrequencyType, dueAmount) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/contract/calculateTheContractTotalAmount?access_token=`+getConnectedUserAccessToken();

  if(contractDuration.duration) {
    formData.append('contractDuration', contractDuration.duration);
  } else {
    formData.append('contractDuration', contractDuration);
  }
  formData.append('contractDurationType', contractDurationType);

  if(dueFrequency.duration) {
    formData.append('dueFrequency', dueFrequency.duration);
  } else {
    formData.append('dueFrequency', dueFrequency);
  }
  formData.append('dueFrequencyType', dueFrequencyType);

  if(dueAmount) {
    formData.append('dueAmount', dueAmount);
  }
  return axios.post(requestUrl, formData);
};

export const updateContractValidationsContent = (contractHashedID, htmlValidationContent) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/contract/updateContractValidationsContent?access_token=`+getConnectedUserAccessToken();
  formData.append('contract', contractHashedID);
  formData.append('htmlValidationContent', htmlValidationContent);
  return axios({
    method: 'post',
    url: requestUrl,
    data: formData,
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    timeout: Infinity,
});
};

export const handleContractOcerizationContentInTheBackGround = (contractHashedID, contentType) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/contract/handleContractOcerizationContentInTheBackGround?access_token=`+getConnectedUserAccessToken();
  formData.append('contract', contractHashedID);
  formData.append('contentType', contentType);
  return axios({
    method: 'post',
    url: requestUrl,
    data: formData,
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    timeout: Infinity,
});
};

export const deleteUnvalidatedContracts = (contractsIds) => {
  var responses = [];
  contractsIds.map((id) => {
    const requestUrl = API_URL+`/contract/`+id+`?access_token=`+getConnectedUserAccessToken();
    axios.delete(requestUrl).then(() => {
      responses.push(id);
    }, (error) => {
      errorAlert.show(error?.response?.data, "Suppression contrat").then(() => {window.location.reload();});
    });
  });
  return new Promise( (resolve, reject) => {
    resolve(responses);
  });
}

export const deleteSingleUnvalidatedContract = (contractsId) => {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + `/contract/` + contractsId + `?access_token=` + getConnectedUserAccessToken();
    axios.delete(requestUrl).then(res => resolve(res.data))
      .catch(err => reject(err.response.data))
  })
}

export const ResiliateContract = (contractId, date, penality) => {
  return new Promise((resolve, reject) => {

    let requestUrl = `${API_URL}/contract/resiliateContract?access_token=${getConnectedUserAccessToken()}`

    let data = new FormData()
    data.append('contract', contractId)
    data.append('date', date)
    data.append('penalty', penality !==undefined ? penality : 0)

    axios.post(requestUrl, data)
    .then(res => resolve(res.data))
    .catch(err => reject(err.response.data))
  })
}

export const renewContract = (contractId, userHashedId) => {
  const requestUrl = API_URL+`/contract/renew/`+contractId+`?user=`+userHashedId+`&access_token=`+getConnectedUserAccessToken();
  return requestUrl;
  // return axios.get(requestUrl);
};

export const toggleContractDispute = (contractHashedID, isDispute, reason, disputedAt) => {
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    const requestUrl = API_URL + `/contract/dispute?access_token=` + getConnectedUserAccessToken();
    formData.append('contract', contractHashedID);
    formData.append('isDispute', isDispute);
    formData.append('reason', reason);
    formData.append('date', disputedAt);
    axios.post(requestUrl, formData)
      .then(res => resolve(res.data))
      .catch(err => reject(err.response.data))
  })
};


export const checkContractParameters = (contractID, contractName=null, contractNumber=null) => {
  const requestUrl = API_URL + `/contract/checkContractParameters?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  formData.append('contract', contractID);
  if (contractName)
    formData.append('name', contractName);
  if (contractNumber)
    formData.append('number', contractNumber);
  return axios.post(requestUrl, formData);
};

export const updateContractRestriction = (keywordId, isRestricted) => {
  const requestUrl = API_URL + `/contractKeyword/updateRestriction?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  formData.append('id', keywordId);
  if (isRestricted)
    formData.append('restrict', 1);
  else
    formData.append('restrict', 0);
  return axios.post(requestUrl, formData);
};

export const updateContractRestrictionList = (keywordsArray, isRestricted) => {
  const requestUrl = API_URL + `/contractKeyword/updateRestrictions?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  let keywordIds = keywordsArray.map(obj => obj.keywordId);
  keywordIds.forEach((field, index) => {
    formData.append(`ids[${index}]`, field);
  });
  if (isRestricted)
    formData.append('restrict', 1);
  else
    formData.append('restrict', 0);
  // Display FormData entries in the console
  // for (let [key, value] of formData.entries()) {
  //   console.log(key, value);
  // }
  return axios.post(requestUrl, formData);
};

//Affectation
export const affectContractWithoutWorkflow = (affectationParams) => {
  const requestUrl = API_URL + `/contract/affect?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  formData.append('contract', affectationParams.contractID);
  if(affectationParams.contributor)
    formData.append('contributor', affectationParams.contributor);
  formData.append('replace', affectationParams.replace);
  return axios.post(requestUrl, formData);
};

export const affectContractByWorkflow = (affectationParams,alreadyHandledBy) => {
  const requestUrl = API_URL + `/contract/addAssignments?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  formData.append('contract', affectationParams.contractID);
  for (let index = 0; index < affectationParams["assignments"].length; index++) {
    const element = affectationParams["assignments"][index];
    let objAssignment = {
      contributor: element["handledBy"].id,
      position: index+1,
      canParticipate: element.canParticipate ? 1 : 0
    }
    if(element.id){
      objAssignment["id"] = element.id
    }
    if(element.startDate){
      objAssignment["startDate"] = element.startDate
    }else if(alreadyHandledBy && affectationParams["assignments"].length == 1 && (!element.startDate || element.startDate == undefined || element.startDate == null || element.startDate == "")){
      objAssignment.position = 0;
    }
    if(element.endDate){
      objAssignment["endDate"] = element.endDate
    }
    formData.append('assignments['+index.toString()+']', JSON.stringify(objAssignment));
  }
  // Display FormData entries in the console
  for (let [key, value] of formData.entries()) {
    console.log(key, value);
  }
  return axios.post(requestUrl, formData);
};

export const deleteAffectContractByWorkflow = (assignmentId) => {
  const requestUrl = API_URL+`/contract/assignment/`+assignmentId+`?access_token=`+getConnectedUserAccessToken();
  return axios.delete(requestUrl);
};

export const taskValidationByWorkflow = (contractParams) => {
  const requestUrl = API_URL + `/contract/validateTask?access_token=` + getConnectedUserAccessToken();
  var formData = new FormData();
  formData.append('contract', contractParams.id);
  formData.append('isManager', (contractParams.isManager.toString() === "1" || contractParams.isManager.toString() === "true") ? 1:0);
  
  return axios.post(requestUrl, formData);
};

export const archiveContractsByUser = (contractParams) => {
  let requestUrl = ""
  if(contractParams && contractParams.type === "UNARCHIVE")
     requestUrl = API_URL + `/contract/unarchiveByUser/${getCurrentClientID()}?access_token=` + getConnectedUserAccessToken();
  else
     requestUrl = API_URL + `/contract/archiveByUser/${getCurrentClientID()}?access_token=` + getConnectedUserAccessToken();

  var formData = new FormData();
  contractParams["contractIDs"].forEach((field, index) => {
    formData.append(`contracts[${index}]`, field);
  });
  if(contractParams.type !== "UNARCHIVE")
    formData.append('type', contractParams.type);
  
  return axios.post(requestUrl, formData);
};