import React, { useEffect, useState } from 'react';
import { t } from "i18next";
import { withTranslation } from 'react-i18next';
import Modal from '../../../../../../../components/Modal';

import "./RecurrenceModal.scss";
import ButtonRadioUnChecked from '../../Buttons-radio/ButtonRadioUnChecked';
import ButtonRadioChecked from '../../Buttons-radio/ButtonRadioChecked';
import ButtonArrowsUpDown from '../../Buttons-arrow/ButtonArrowsUpDown';
import DatePicker from 'react-datepicker';
import Moment from 'moment';

const RecurrenceModal = ({ isOpen, onRequestClose, onFinalize, selectedAlert, locale}) => {
  const getDayNameAndNumber = (date) =>{
    
    // Array of short day names
    var shortDays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

    // Get the day of the week (0-6)
    var dayIndex = date.getDay();

    // Get the short day name
    var shortDayName = shortDays[dayIndex];

    // Get the date number
    var dateNumber = date.getDate();

    return {
      shortDay: shortDayName,
      numDate: dateNumber
    }
  }

  const convertDateFormat = (dateStr) => {
    // Parse the input date string using the expected format
    const date = Moment(dateStr, 'DD/MM/YYYY', true);

    // Check if the date is valid
    if (!date.isValid()) {
      return dateStr;
      throw new Error('Invalid date format. Expected format: dd/MM/yyyy');
    }

    // Format the date to yyyy-MM-dd
    const formattedDate = date.format('YYYY-MM-DD');

    return formattedDate;
  }

  const [dateObj, setDateObj] = useState(getDayNameAndNumber(selectedAlert && selectedAlert.date !== undefined ? new Date(selectedAlert.date):new Date()))

   // 'endDate', 'isRecurrent', 'repeatAtDay', 'frequencyType', 'frequency', 'repeatCount', 'endWithContract', 'repeatAtDays'
   const [recurrenceObj, setRecurrenceObj] = useState({
    isRecurrent: true, // true / false
    frequency: selectedAlert.frequency || 1, // repetion numbers
    frequencyType: selectedAlert.frequencyType || "DAILY", // "DAILY"/ "WEEKLY" / "MONTHLY" / "YEARLY"
    repeatAtDays: selectedAlert.repeatAtDays || [], // "SU" / "MO" / "TU" / "WE" / "TH" / "FR" / "SA"
    repeatAtDay: selectedAlert.repeatAtDay ? selectedAlert.repeatAtDay:( dateObj ? dateObj.numDate.toString(): null), // example : "1TH" / "2TH" / "3TH" / "4TH" / "-1TH" 
    endWithContract: selectedAlert.endWithContract !== undefined && selectedAlert.endWithContract.toString() === "1" ? true:false, // true / false
    endDate: (selectedAlert && selectedAlert.endDate !== undefined ?  new Date(convertDateFormat(selectedAlert.endDate)): null), // date dd/MM/YYYY
    repeatCount: selectedAlert.repeatCount || 1, // repetion numbers
    endWith:{
      withContract: selectedAlert.endWithContract !== undefined && (selectedAlert.endWithContract.toString() === "1" || selectedAlert.endWithContract.toString() === "true") ? true:false,
      withDate: !selectedAlert.endWithContract && !selectedAlert.repeatCount && selectedAlert && selectedAlert.endDate !== undefined,
      withRepetition: selectedAlert.repeatCount ? true: false
    },
    dayNameOnCurrentMonth: null
  })

  const detectPositionOfDayOnMonth =(date)=>{
    const dayOfMonth = date.getDate();
    const dayOfWeek = date.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1).getDay(); // Day of the week for the first day of the month
    
    let occurrencesCount = 0;
    let currentDate = new Date(date.getFullYear(), date.getMonth(), 1);
    
    // Count occurrences of the specified day name within the month
    while (currentDate.getMonth() === date.getMonth()) {
        if (currentDate.getDay() === dayOfWeek) {
            occurrencesCount++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    const occurrence = Math.ceil((dayOfMonth + firstDayOfMonth) / 7);
    const dayName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
    const occurrenceSuffix = ['st', 'nd', 'rd', 'th'][Math.min(occurrence - 1, 3)];

    const monthName = date.toLocaleString('default', { month: 'long' });

    // console.log(`${dayName} is the ${occurrence}${occurrenceSuffix} on this month ${monthName} ${date.getFullYear()}. There are ${occurrencesCount} occurrences of ${dayName} in ${monthName}.`);
    return {
      position: occurrence+''+occurrenceSuffix,
      occurrences: occurrencesCount
    }
  }

  // useEffect(() => {
  //  if(selectedAlert && selectedAlert.date !== undefined){
     
  //    setRecurrenceObj({
  //      ...recurrenceObj,
  //     date: new Date(selectedAlert.date),
  //      positionOnCurrentMonth: detectPositionOfDayOnMonth(new Date(selectedAlert.date))
  //    })
  //    setDateObj(getDayNameAndNumber(new Date(selectedAlert.date)))
  //  }
    
  // }, [selectedAlert["date"]])

  // useEffect(() => {
  //   if(selectedAlert && selectedAlert.endDate !== undefined){
  //    console.log(selectedAlert.endDate)
  //     setRecurrenceObj({
  //       ...recurrenceObj,
  //       endDate: new Date(convertDateFormat(selectedAlert.endDate))
  //     })
  //   }
     
  //  }, [selectedAlert["endDate"]])

  useEffect(() => {
    if (selectedAlert) {

      setRecurrenceObj({
        ...recurrenceObj,
        frequency: selectedAlert.frequency || 1, // repetion numbers
        frequencyType: selectedAlert.frequencyType || "DAILY", // "DAILY"/ "WEEKLY" / "MONTHLY" / "YEARLY"
        repeatAtDays: selectedAlert.repeatAtDays || [], // "SU" / "MO" / "TU" / "WE" / "TH" / "FR" / "SA"
        repeatAtDay: selectedAlert.repeatAtDay ? selectedAlert.repeatAtDay : (dateObj ? dateObj.numDate.toString() : null), // example : "1TH" / "2TH" / "3TH" / "4TH" / "-1TH" 
        endWithContract: selectedAlert.endWithContract !== undefined && selectedAlert.endWithContract.toString() === "1" ? true : false, // true / false
        endDate: (selectedAlert && selectedAlert.endDate !== undefined ? new Date(convertDateFormat(selectedAlert.endDate)) : null), // date dd/MM/YYYY
        repeatCount: selectedAlert.repeatCount || 1, // repetion numbers
        endWith: {
          withContract: selectedAlert.endWithContract !== undefined && (selectedAlert.endWithContract.toString() === "1" || selectedAlert.endWithContract.toString() === "true") ? true : false,
          withDate: !selectedAlert.endWithContract && !selectedAlert.repeatCount && selectedAlert && selectedAlert.endDate !== undefined,
          withRepetition: selectedAlert.repeatCount ? true : false
        }
      })
    }

  }, [selectedAlert])

  const handleChangeAction = (e, varName) =>{
    setRecurrenceObj({
      ...recurrenceObj,
      [varName]: e.target.value
    })
  }

  const handleUpAction = (varName) =>{
    let  newVar = recurrenceObj[varName]
    newVar+=1
    setRecurrenceObj({
      ...recurrenceObj,
      [varName]: newVar
    })
  }
  
  const handleDownAction = (varName) =>{
    let  newVar = recurrenceObj[varName]
    if(newVar > 1)
      newVar-=1
    setRecurrenceObj({
      ...recurrenceObj,
      [varName]: newVar
    })
  }

  const handleStringParam = (varName, newVar) =>{ 
    setRecurrenceObj({
      ...recurrenceObj,
      [varName]: newVar
    })
  }
  
  const getFRDayName = (shortDayEN) => {
    switch (shortDayEN) {
      case "MO":
        return "Lundi"
        break;
      case "TU":
        return "Mardi"
        break;
      case "WE":
        return "Mercredi"
        break;
      case "TH":
        return "Jeudi"
        break;
      case "FR":
        return "Vendredi"
        break;
      case "SA":
        return "Samedi"
        break;
      case "SU":
        return "Dimanche"
        break;

      default:
        return "Inconnue"
        break;
    }
  }
                  
  const handleChangeEndDate = (d) => {
    setRecurrenceObj({
      ...recurrenceObj,
      endDate: d
    })
  }

  const handleChangeRepeatAtDays = (element) => {
    let dayList = recurrenceObj.repeatAtDays || []
    const index = dayList.indexOf(element);
    if (index === -1) {
        // Element doesn't exist, so add it
        dayList.push(element);
    } else {
        // Element exists, so remove it
        dayList.splice(index, 1);
    }
    setRecurrenceObj({
      ...recurrenceObj,
      repeatAtDays: dayList
    })
  }


  const handleChangeRepeatAtDay = (d) => {
    setRecurrenceObj({
      ...recurrenceObj,
      repeatAtDay: d
    })
  }


  const onChangeEndWith = (str) =>{
    let endWithVar = recurrenceObj.endWith || {}
    if(str==="withContract"){
      endWithVar.withContract = true
      endWithVar.withDate = false
      endWithVar.withRepetition = false
    }
    if(str==="withDate"){
      endWithVar.withContract = false
      endWithVar.withDate = true
      endWithVar.withRepetition = false
    }
    if(str==="withRepetition"){
      endWithVar.withContract = false
      endWithVar.withDate = false
      endWithVar.withRepetition = true
    }
    setRecurrenceObj({
      ...recurrenceObj,
      endWith: endWithVar
    })
  }

  
  const checkBeforeFinalize = () =>{
    const {frequencyType, frequency, repeatAtDays, repeatAtDay, endWith, endDate, repeatCount} = recurrenceObj;
    switch (frequencyType) {
      case "DAILY":
        if (!frequency || frequency === null || frequency === "" || frequency === undefined) return true
        if (endWith && endWith.withDate === true && (!endDate || endDate === null || endDate === "" || endDate === undefined)) return true
        if (endWith && endWith.withRepetition === true && (!repeatCount || repeatCount === null || repeatCount === "" || repeatCount === undefined)) return true
        return false
        break;

      case "WEEKLY":
        if (!frequency || frequency === null || frequency === "" || frequency === undefined) return true
        if (!repeatAtDays || repeatAtDays === null || repeatAtDays === "" || repeatAtDays === undefined || repeatAtDays.length === 0) return true
        if (endWith && endWith.withDate === true && (!endDate || endDate === null || endDate === "" || endDate === undefined)) return true
        if (endWith && endWith.withRepetition === true && (!repeatCount || repeatCount === null || repeatCount === "" || repeatCount === undefined)) return true
        return false
        break;

      case "MONTHLY":
        if (!frequency || frequency === null || frequency === "" || frequency === undefined) return true
        if (!repeatAtDay || repeatAtDay === null || repeatAtDay === "" || repeatAtDay === undefined) return true
        if (endWith && endWith.withDate === true && (!endDate || endDate === null || endDate === "" || endDate === undefined)) return true
        if (endWith && endWith.withRepetition === true && (!repeatCount || repeatCount === null || repeatCount === "" || repeatCount === undefined)) return true
        return false
        break;

      case "YEARLY":
        if (!frequency || frequency === null || frequency === "" || frequency === undefined) return true
        if (endWith && endWith.withDate === true && (!endDate || endDate === null || endDate === "" || endDate === undefined)) return true
        if (endWith && endWith.withRepetition === true && (!repeatCount || repeatCount === null || repeatCount === "" || repeatCount === undefined)) return true
        return false
        break;

      default:
        return true
        break;
    }
  }

  const onBuildObject = () =>{
    if(selectedAlert.frequencyType)
      delete selectedAlert.frequencyType
    if(selectedAlert.frequency)
      delete selectedAlert.frequency
    if(selectedAlert.repeatAtDays)
      delete selectedAlert.repeatAtDays
    if(selectedAlert.repeatAtDay)
      delete selectedAlert.repeatAtDay
    if(selectedAlert.endWith)
      delete selectedAlert.endWith
    if(selectedAlert.endWithContract)
      delete selectedAlert.endWithContract
    if(selectedAlert.endDate)
      delete selectedAlert.endDate
    if(selectedAlert.repeatCount)
      delete selectedAlert.repeatCount
    const {frequencyType, frequency, repeatAtDays, repeatAtDay, endWith, endWithContract, endDate, repeatCount} = recurrenceObj;
    let finalObj = {}
    let objTmp = {
      isRecurrent: 1,
      frequencyType: frequencyType,
      frequency: frequency
    }

    if(endWith && endWith.withContract && endWith.withContract && (endWith.withContract.toString() === '1' || endWith.withContract.toString() === 'true')){
      objTmp["endWithContract"] = 1;
    }

    if(endWith && endWith.withDate && endDate !== null && endDate !== "" && endDate !== undefined){
      objTmp["endDate"] = endDate;
      // objTmp["endDate"] = Moment(new Date(endDate)).format("YYYY-MM-DD");
    }

    if(endWith && endWith.withRepetition && repeatCount !== null && repeatCount !== "" && repeatCount !== undefined){
      objTmp["repeatCount"] = repeatCount;
    }
    
    if(frequencyType === "DAILY"){
      objTmp["repeatAtDay"] = dateObj.shortDay;
    }

    if(frequencyType === "WEEKLY"){
      objTmp["repeatAtDays"] = repeatAtDays;
    }

    if(frequencyType === "MONTHLY"){
      objTmp["repeatAtDay"] = repeatAtDay;
    }
    finalObj = {...selectedAlert, ...objTmp};
    
    onFinalize(finalObj);
  }

  return (
    <Modal
      title={t("frontoffice.contract_alerts.popup_custom_recurrence.custom_recurrence")}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      isFullScreen={false}
      footer={
        <>
          <button className='m-auto' onClick={() => onRequestClose(false)}>Annuler</button>
          <button className={checkBeforeFinalize() ? 'm-auto disabled-btn': 'm-auto'} disabled={checkBeforeFinalize()} onClick={() => onBuildObject()}>Terminé</button>
        </>
      }
    >
      <div className="modal-recurrence">
        <div className="rows d-flex">
          <div className='col-8 freq-alert'>
            <p className="freq-actions">
              {t("frontoffice.contract_alerts.popup_custom_recurrence.repeat_alert_every")} 
              <input type='number' name='freq' value={recurrenceObj.frequency} className='form-control' min={1} onChange={e=>handleChangeAction(e,"frequency")}/>
              {/* <ButtonArrowsUpDown onPressUp={()=>handleUpAction("frequency")} onPressDown={()=>{handleDownAction("frequency")}} /> */}
            </p>
            { recurrenceObj &&  recurrenceObj.frequencyType === "WEEKLY" &&
              <p className="freq-actions">
                <ul className="weekly-zone">
                  <li onClick={()=>handleChangeRepeatAtDays("MO")} className={recurrenceObj.repeatAtDays.includes("MO") ? 'active-day' : ''}>{t("common.days.short.mo")}</li>
                  <li onClick={()=>handleChangeRepeatAtDays("TU")} className={recurrenceObj.repeatAtDays.includes("TU") ? 'active-day' : ''}>{t("common.days.short.tu")}</li>
                  <li onClick={()=>handleChangeRepeatAtDays("WE")} className={recurrenceObj.repeatAtDays.includes("WE") ? 'active-day' : ''}>{t("common.days.short.we")}</li>
                  <li onClick={()=>handleChangeRepeatAtDays("TH")} className={recurrenceObj.repeatAtDays.includes("TH") ? 'active-day' : ''}>{t("common.days.short.th")}</li>
                  <li onClick={()=>handleChangeRepeatAtDays("FR")} className={recurrenceObj.repeatAtDays.includes("FR") ? 'active-day' : ''}>{t("common.days.short.fr")}</li>
                  <li onClick={()=>handleChangeRepeatAtDays("SA")} className={recurrenceObj.repeatAtDays.includes("SA") ? 'active-day' : ''}>{t("common.days.short.sa")}</li>
                  <li onClick={()=>handleChangeRepeatAtDays("SU")} className={recurrenceObj.repeatAtDays.includes("SU") ? 'active-day' : ''}>{t("common.days.short.su")}</li>
                </ul>
              </p>
            }
            { recurrenceObj &&  recurrenceObj.frequencyType === "MONTHLY" &&
              <p className="freq-actions">
                <ul className="daily-zone">
                  <li onClick={()=>handleChangeRepeatAtDay(dateObj.numDate.toString())} className={recurrenceObj.repeatAtDay.includes(dateObj.numDate.toString()) ? 'active-day' : ''}>{dateObj.numDate}</li>
                  { 
                    // recurrenceObj && recurrenceObj.positionOnCurrentMonth !== null && recurrenceObj.positionOnCurrentMonth !== undefined && recurrenceObj.positionOnCurrentMonth.position !== undefined && recurrenceObj.positionOnCurrentMonth.position === "1st" && 
                    <li onClick={()=>handleChangeRepeatAtDay("1"+dateObj.shortDay)} className={recurrenceObj.repeatAtDay === ("1"+dateObj.shortDay) ? 'active-day' : ''}>{t("common.steps.first")}</li>
                  }
                  { 
                    // recurrenceObj && recurrenceObj.positionOnCurrentMonth !== null && recurrenceObj.positionOnCurrentMonth !== undefined && recurrenceObj.positionOnCurrentMonth.position !== undefined && recurrenceObj.positionOnCurrentMonth.position === "2nd" && 
                    <li onClick={()=>handleChangeRepeatAtDay("2"+dateObj.shortDay)} className={recurrenceObj.repeatAtDay === ("2"+dateObj.shortDay) ? 'active-day' : ''}>{t("common.steps.second")}</li>
                  }
                  { 
                    // recurrenceObj && recurrenceObj.positionOnCurrentMonth !== null && recurrenceObj.positionOnCurrentMonth !== undefined && recurrenceObj.positionOnCurrentMonth.position !== undefined && recurrenceObj.positionOnCurrentMonth.position === "3rd" && 
                    <li onClick={()=>handleChangeRepeatAtDay("3"+dateObj.shortDay)} className={recurrenceObj.repeatAtDay === ("3"+dateObj.shortDay) ? 'active-day' : ''}>{t("common.steps.third")}</li>
                  }
                  { 
                    // recurrenceObj && recurrenceObj.positionOnCurrentMonth !== null && recurrenceObj.positionOnCurrentMonth !== undefined && recurrenceObj.positionOnCurrentMonth.position !== undefined && (recurrenceObj.positionOnCurrentMonth.position === "4th" || recurrenceObj.positionOnCurrentMonth.position === "5th") && 
                    <li onClick={()=>handleChangeRepeatAtDay("4"+dateObj.shortDay)} className={(recurrenceObj.repeatAtDay === ("4"+dateObj.shortDay)) ? 'active-day' : ''}>{t("common.steps.fourth")}</li>
                  }
                  { 
                    // recurrenceObj && recurrenceObj.positionOnCurrentMonth !== null && recurrenceObj.positionOnCurrentMonth !== undefined && recurrenceObj.positionOnCurrentMonth.position !== undefined && recurrenceObj.positionOnCurrentMonth.position === "5th" && 
                    <li onClick={()=>handleChangeRepeatAtDay("-1"+dateObj.shortDay)} className={recurrenceObj.repeatAtDay === ("-1"+dateObj.shortDay) ? 'active-day last-day-of-month' : 'last-day-of-month'}>{t("common.steps.last")}</li>
                  }
                </ul>
                <span className="current-day">{t("common.days.long."+getFRDayName(dateObj.shortDay))}</span>
              </p>
            }
          </div>
          <div className='col-4'>
            <ul className="radio-list">
              <li onClick={()=>handleStringParam("frequencyType","DAILY")}>
                { recurrenceObj.frequencyType =="DAILY" ?
                  <ButtonRadioChecked onPress={()=>{}}/>:
                  <ButtonRadioUnChecked onPress={()=>{}}/>
                }
                <span>{t("common.days.label")}</span>
              </li>
              <li onClick={()=>handleStringParam("frequencyType","WEEKLY")}>
                {recurrenceObj.frequencyType == "WEEKLY" ?
                  <ButtonRadioChecked onPress={() => { }} /> :
                  <ButtonRadioUnChecked onPress={() => { }} />
                }
                <span>{t("common.weeks")}</span>
              </li>
              <li onClick={()=>handleStringParam("frequencyType","MONTHLY")}>
                {recurrenceObj.frequencyType == "MONTHLY" ?
                  <ButtonRadioChecked onPress={() => { }} /> :
                  <ButtonRadioUnChecked onPress={() => { }} />
                }
                <span>{t("common.months.label")}</span>
              </li>
              <li onClick={()=>handleStringParam("frequencyType","YEARLY")}>
                {recurrenceObj.frequencyType == "YEARLY" ?
                  <ButtonRadioChecked onPress={() => { }} /> :
                  <ButtonRadioUnChecked onPress={() => { }} />
                }
                <span>{t("common.years")}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="rows d-flex">
          <div className="col-12">
            <p className="horizontal-bar"></p>
          </div>
        </div>
        <div className="rows d-flex">
          <div className="col-12">
            <p>{t("frontoffice.contract_alerts.popup_custom_recurrence.end_with")} :</p>
            <ul className="radio-list">
              <li>
                <div onClick={() => onChangeEndWith("withContract")}>
                  {recurrenceObj.endWith.withContract ?
                    <ButtonRadioChecked onPress={() => { }} /> :
                    <ButtonRadioUnChecked onPress={() => { }} />
                  }
                  <span>{t("frontoffice.contract_alerts.popup_custom_recurrence.with_contract")}</span>
                </div>
              </li>
              <li className="mt-2">
                <div onClick={()=>onChangeEndWith("withDate")}>
                  {recurrenceObj.endWith.withDate ?
                    <ButtonRadioChecked onPress={() => { }} /> :
                    <ButtonRadioUnChecked onPress={() => { }} />
                  }
                  <span>{t("common.date")}</span>
                </div>
                {recurrenceObj.endWith.withDate && <DatePicker locale={locale} disabled={!recurrenceObj.endWith.withDate} dateFormat="dd MMMM yyyy" className="form-control mx-2" autoComplete="off" name="date" selected={recurrenceObj.endDate} onChange={(dateValue) => { handleChangeEndDate(dateValue) }} minDate={new Date()} placeholderText="Date" required />}
              </li>
              <li className="repeat-count mt-2">
                <div onClick={()=>onChangeEndWith("withRepetition")}>
                  {recurrenceObj.endWith.withRepetition ?
                    <ButtonRadioChecked onPress={() => { }} /> :
                    <ButtonRadioUnChecked onPress={() => { }} />
                  }
                  <span >{t("frontoffice.contract_alerts.popup_custom_recurrence.repetitions")} X </span>
                </div>
                <input className='mx-2 count-freq' type='number' disabled={!recurrenceObj.endWith.withRepetition} name='freq2' value={recurrenceObj.repeatCount} min={1} onChange={e=>handleChangeAction(e,"repeatCount")}/>
                {/* <ButtonArrowsUpDown disabled={!recurrenceObj.endWith.withRepetition} onPressUp={()=>handleUpAction("repeatCount")} onPressDown={()=>{handleDownAction("repeatCount")}}  /> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withTranslation()(RecurrenceModal);
