import { t } from 'i18next';
import React, { Component } from 'react'; 
import { withTranslation } from 'react-i18next';
import { BrowserRouter as Router,  Link } from "react-router-dom";
import './BoRgpd.scss';

class BoRgpd extends Component { 

    constructor (props) {
        super(props)
        this.state = {
            activeMenu: props.activeMenu
        } 
    }

    render() {
        return ( 
            <ul className="BoContractCltRGPD side-menu-tabs">
                <li>
                    <Link to="/back-office/home">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.2695 23.2204L23.9998 12.1875L36.7301 23.2204" stroke="#7077A5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M33.3347 18.1283V14.3092H29.5156" stroke="#7077A5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M14.6641 23.7156V36.375H20.6049V27.8881H27.3943V36.375H33.3351V23.6799" stroke="#7077A5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>  
                        <span>{t('header.menu.home')}</span>
                    </Link>
                </li>
                <li className={`${this.state.activeMenu == 'keywords' ? 'active' : ''}`}>
                    <a onClick={this.props.onButtonClick}>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2969 17.4863L18.0007 26.2173L25.7045 17.9999M18.0007 2.59229V26.2173V2.59229Z" stroke="#7077A5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6.1875 25.1902V33.4076H29.8125V25.1902" stroke="#7077A5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <span>Export</span>
                    </a>
                </li>
            </ul>
        )
    }
}

export default withTranslation()(BoRgpd);