import React from "react";

const TabContent = ({
  TabId,
  isActive,
  recentContract,
  listingTitle,
  listing,
}) => (
  <div
    id={TabId}
    className={`tab-pane ${isActive && "active"}`}
    role="tabpanel"
  >
    {recentContract}
    {!!listing && (
      <>
        <h2 className="tab-title hidden-only-on-mobile">{listingTitle}</h2>
        <div className={"list-contrats" + ((TabId == "tab-6") ? " list-contrats-tab-all":"")} data-module="sticky-table">
          {listing}
        </div>
      </>
    )}
  </div>
);

export default TabContent;
