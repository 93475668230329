import React, { Component } from 'react';
import { BrowserRouter as Router,  Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { t } from "i18next";
import { withTranslation } from "react-i18next";
import './FoManageContract.scss';
import ErrorAlert from "../../confirm-alert/error-alert";

class BoContractSettings extends Component {

    constructor (props) {
        super(props)
        this.state = {
            activeMenu: props.activeMenu,
            contractId: null,
            scrollPosition: 0
        }
        this.containerRef = React.createRef();
    }

    updateScrollPosition = () => {
        const { current: container } = this.containerRef;
        if (container) {
          this.setState({ scrollPosition: container.scrollLeft });
        }
      };
    
    scrollTabs = (scrollOffset) => {
        const { current: container } = this.containerRef;
        if (container) {
            const startPosition = container.scrollLeft;
            const targetPosition = startPosition + scrollOffset;
            const startTime = performance.now();
            const duration = 500; // milliseconds

            const animateScroll = (currentTime) => {
                const elapsedTime = currentTime - startTime;
                const ease = easeInOutQuad(elapsedTime, 0, 1, duration);
                container.scrollLeft = startPosition + (scrollOffset * ease);

                if (elapsedTime < duration) {
                    requestAnimationFrame(animateScroll);
                } else {
                    this.updateScrollPosition();
                }
            };

            const easeInOutQuad = (t, b, c, d) => {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b;
            };

            requestAnimationFrame(animateScroll);
        }
    };
      

    componentDidMount() {
        console.log('this.props.contractStatus', this.props.contractStatus)
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        if(parsed.contract) {
            this.setState({
                ...this,
                contractId: parsed.contract,
            })
        } else {
            ErrorAlert.show("Vous devez fournir l'identifiant du contrat", "Attention");
            setTimeout(() => {
                this.props.history.push("/");
             }, 3000);
        }
    }

    handleClick = (bloc) => {
        const { onClick } = this.props;
        onClick(bloc);
      }

    render() {
        return (
            <ul className="FoManageContract scrollable-tab-navigation">
                <li className="no-underline">
                    <a className={this.state.scrollPosition === 0 ? "scroll-button scroll-button-disabled":"scroll-button"}
                        onClick={() => this.scrollTabs(-100)}
                        disabled={this.state.scrollPosition === 0}>
                        <svg width="24" height="48" viewBox="0 0 24 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.9645 42.5184C24.0128 43.7414 23.8712 45.5827 22.6481 46.631C21.4251 47.6794 19.5838 47.5377 18.5355 46.3147L1.03548 25.898C0.0992569 24.8058 0.0992569 23.194 1.03548 22.1017L18.5355 1.68508C19.5838 0.462048 21.4251 0.320411 22.6481 1.36872C23.8712 2.41704 24.0128 4.25833 22.9645 5.48136L7.09145 23.9999L22.9645 42.5184Z" fill="#F66031" />
                        </svg>
                    </a>
                </li>
                <div className="tabs-container" ref={this.containerRef}>
                    <li>
                        {/*<Link to={"/manage-contract/validate?contract="+this.state.contractId}> //TOSEE*/}
                        <Link to="/home">
                            <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.26953 12.2204L13.9998 1.1875L26.7301 12.2204" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M23.3352 7.12828V3.3092H19.5161" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.66406 12.7156V25.375H10.6049V16.8881H17.3943V25.375H23.3351V12.6799" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className="active-tab">{t("frontoffice.contract_details.contract_tabs.home")}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/manage-contract/exchanges?contract=" + this.state.contractId} className={this.props.isRestrictedUser ? 'disabled-link' : ''}>
                            <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.2239 1.91708H18.5C18.1318 1.91708 17.8333 1.61647 17.8333 1.24565C17.8333 0.874828 18.1318 0.574219 18.5 0.574219H23.8333C24.2015 0.574219 24.5 0.874828 24.5 1.24565V6.61708C24.5 6.9879 24.2015 7.28851 23.8333 7.28851C23.4651 7.28851 23.1667 6.9879 23.1667 6.61708V2.86662L16.3047 9.77756C16.0444 10.0398 15.6223 10.0398 15.3619 9.77756L11.8333 6.22376L6.9714 11.1204C6.71106 11.3826 6.28895 11.3826 6.0286 11.1204C5.76825 10.8582 5.76825 10.4331 6.0286 10.1709L11.3619 4.79945C11.6223 4.53724 12.0444 4.53724 12.3047 4.79945L15.8333 8.35325L22.2239 1.91708ZM23.8333 15.3456C24.2015 15.3456 24.5 15.6463 24.5 16.0171C24.5 16.3879 24.2015 16.6885 23.8333 16.6885H3.83333C1.99238 16.6885 0.5 15.1855 0.5 13.3314V1.24565C0.5 0.874828 0.798477 0.574219 1.16667 0.574219C1.53486 0.574219 1.83333 0.874828 1.83333 1.24565V13.3314C1.83333 14.4438 2.72876 15.3456 3.83333 15.3456H23.8333Z" fill="#5367F4" stroke="#5367F4" />
                            </svg>
                            <span className="active-tab">{t("frontoffice.contract_details.contract_tabs.bourse")}</span>
                        </Link>
                    </li>
                    {this.props.isFromValidationForm &&
                        <li className={`${this.props.activeMenu == 'clauses' ? 'active' : ''}`}>
                            <a className={!this.props.editMode ? '' : ''} onClick={() => this.handleClick(0)}>
                                <svg width="24" height="44" viewBox="0 0 118 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M94 93.2501V15.0625C94 10.2646 90.0825 6.37504 85.25 6.37504H22.4392C23.4319 8.0788 24 10.0569 24 12.1667V104.833C24 108.032 26.6117 110.625 29.8334 110.625C33.055 110.625 35.6667 108.032 35.6667 104.833V96.1459C35.6667 94.5466 36.9725 93.2501 38.5834 93.2501H94ZM99.8334 93.2501H114.417C116.028 93.2501 117.333 94.5466 117.333 96.1459V101.938C117.333 109.934 110.804 116.417 102.75 116.417H29.8334C23.39 116.417 18.1667 111.231 18.1667 104.833V23.75H12.3334C5.89003 23.75 0.666687 18.564 0.666687 12.1667C0.666687 5.76941 5.89003 0.583374 12.3334 0.583374H85.25C93.3042 0.583374 99.8334 7.06592 99.8334 15.0625V93.2501ZM41.5 99.0417V104.833C41.5 106.943 40.9319 108.921 39.9392 110.625H102.75C107.583 110.625 111.5 106.736 111.5 101.938V99.0417H41.5ZM18.1667 17.9584V12.1667C18.1667 8.96806 15.555 6.37504 12.3334 6.37504C9.11169 6.37504 6.50002 8.96806 6.50002 12.1667C6.50002 15.3654 9.11169 17.9584 12.3334 17.9584H18.1667ZM38.5834 29.5417C36.9725 29.5417 35.6667 28.2452 35.6667 26.6459C35.6667 25.0466 36.9725 23.75 38.5834 23.75H79.4167C81.0275 23.75 82.3334 25.0466 82.3334 26.6459C82.3334 28.2452 81.0275 29.5417 79.4167 29.5417H38.5834ZM38.5834 46.9167C36.9725 46.9167 35.6667 45.6202 35.6667 44.0209C35.6667 42.4216 36.9725 41.125 38.5834 41.125H79.4167C81.0275 41.125 82.3334 42.4216 82.3334 44.0209C82.3334 45.6202 81.0275 46.9167 79.4167 46.9167H38.5834ZM38.5834 64.2917C36.9725 64.2917 35.6667 62.9952 35.6667 61.3959C35.6667 59.7966 36.9725 58.5 38.5834 58.5H79.4167C81.0275 58.5 82.3334 59.7966 82.3334 61.3959C82.3334 62.9952 81.0275 64.2917 79.4167 64.2917H38.5834ZM38.5834 81.6667C36.9725 81.6667 35.6667 80.3702 35.6667 78.7709C35.6667 77.1716 36.9725 75.8751 38.5834 75.8751H67.75C69.3609 75.8751 70.6667 77.1716 70.6667 78.7709C70.6667 80.3702 69.3609 81.6667 67.75 81.6667H38.5834Z" fill={this.props.showBloc === 0 ? '#F66031' : '#5367F4'} />
                                </svg>
                                <span className="active-tab">{t("frontoffice.contract_details.contract_tabs.clauses")}</span>
                            </a>
                        </li>}
                    {!this.props.isFromValidationForm &&
                        <li className={`${this.props.activeMenu == 'clauses' ? 'active' : ''}`}>
                            <Link className={!this.props.editMode ? '' : ''} to={"/manage-contract/validate?contract=" + this.state.contractId}>
                                <svg width="24" height="44" viewBox="0 0 118 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M94 93.2501V15.0625C94 10.2646 90.0825 6.37504 85.25 6.37504H22.4392C23.4319 8.0788 24 10.0569 24 12.1667V104.833C24 108.032 26.6117 110.625 29.8334 110.625C33.055 110.625 35.6667 108.032 35.6667 104.833V96.1459C35.6667 94.5466 36.9725 93.2501 38.5834 93.2501H94ZM99.8334 93.2501H114.417C116.028 93.2501 117.333 94.5466 117.333 96.1459V101.938C117.333 109.934 110.804 116.417 102.75 116.417H29.8334C23.39 116.417 18.1667 111.231 18.1667 104.833V23.75H12.3334C5.89003 23.75 0.666687 18.564 0.666687 12.1667C0.666687 5.76941 5.89003 0.583374 12.3334 0.583374H85.25C93.3042 0.583374 99.8334 7.06592 99.8334 15.0625V93.2501ZM41.5 99.0417V104.833C41.5 106.943 40.9319 108.921 39.9392 110.625H102.75C107.583 110.625 111.5 106.736 111.5 101.938V99.0417H41.5ZM18.1667 17.9584V12.1667C18.1667 8.96806 15.555 6.37504 12.3334 6.37504C9.11169 6.37504 6.50002 8.96806 6.50002 12.1667C6.50002 15.3654 9.11169 17.9584 12.3334 17.9584H18.1667ZM38.5834 29.5417C36.9725 29.5417 35.6667 28.2452 35.6667 26.6459C35.6667 25.0466 36.9725 23.75 38.5834 23.75H79.4167C81.0275 23.75 82.3334 25.0466 82.3334 26.6459C82.3334 28.2452 81.0275 29.5417 79.4167 29.5417H38.5834ZM38.5834 46.9167C36.9725 46.9167 35.6667 45.6202 35.6667 44.0209C35.6667 42.4216 36.9725 41.125 38.5834 41.125H79.4167C81.0275 41.125 82.3334 42.4216 82.3334 44.0209C82.3334 45.6202 81.0275 46.9167 79.4167 46.9167H38.5834ZM38.5834 64.2917C36.9725 64.2917 35.6667 62.9952 35.6667 61.3959C35.6667 59.7966 36.9725 58.5 38.5834 58.5H79.4167C81.0275 58.5 82.3334 59.7966 82.3334 61.3959C82.3334 62.9952 81.0275 64.2917 79.4167 64.2917H38.5834ZM38.5834 81.6667C36.9725 81.6667 35.6667 80.3702 35.6667 78.7709C35.6667 77.1716 36.9725 75.8751 38.5834 75.8751H67.75C69.3609 75.8751 70.6667 77.1716 70.6667 78.7709C70.6667 80.3702 69.3609 81.6667 67.75 81.6667H38.5834Z" fill={this.props.showBloc === 0 ? '#F66031' : '#5367F4'} />
                                </svg>
                                <span className="active-tab">{t("frontoffice.contract_details.contract_tabs.clauses")}</span>
                            </Link>
                        </li>}
                    {this.props.isFromValidationForm && !this.props.inNegociationBy &&
                        <li className={`${this.props.activeMenu == 'signatories' ? 'active' : ''}`}>
                            <a className={!this.props.editMode && this.props.contractStatus < 2 ? 'disabled-link' : ''} onClick={() => this.handleClick(1)}>
                                <svg width="29" height="44" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <line x1="1.27132" y1="24.7448" x2="16.1966" y2="24.3173" stroke={this.props.showBloc === 1 ? '#F66031' : '#5367F4'} stroke-linecap="round" />
                                    <line x1="0.5" y1="-0.5" x2="5.75095" y2="-0.5" transform="matrix(-0.39392 0.919145 -0.919145 -0.393919 2.46237 21.1086)" stroke={this.props.showBloc === 1 ? '#F66031' : '#5367F4'} stroke-linecap="round" />
                                    <line x1="5.57036" y1="22.1628" x2="4.6903" y2="24.5096" stroke={this.props.showBloc === 1 ? '#F66031' : '#5367F4'} stroke-linecap="round" />
                                    <line x1="8.82282" y1="21.7652" x2="6.75437" y2="26.5916" stroke={this.props.showBloc === 1 ? '#F66031' : '#5367F4'} stroke-linecap="round" />
                                    <path d="M18.8171 21.479L15.8741 18.4844C14.7034 19.8805 14.0149 21.6322 13.9186 23.4688C15.7235 23.3709 17.445 22.6702 18.8171 21.479ZM16.7747 17.531L19.754 20.5626L34.4342 5.62483C34.7894 5.2634 34.7894 4.67739 34.4342 4.31595L32.7412 2.59324C32.386 2.23181 31.8101 2.23181 31.4549 2.59324L16.7747 17.531ZM27.9296 4.31055C26.9614 3.39569 25.4473 3.41759 24.5052 4.37622L20.2134 8.74327C19.9597 9.00144 19.5484 9.00144 19.2946 8.74327C19.0409 8.4851 19.0409 8.06652 19.2946 7.80835L23.5864 3.44131C25.036 1.9663 27.3728 1.94426 28.8488 3.37521L30.5361 1.65833C31.3988 0.780556 32.7974 0.780556 33.66 1.65833L35.353 3.38104C36.2157 4.25882 36.2157 5.68197 35.353 6.55975L20.2134 21.9649C18.4276 23.7821 16.0056 24.8029 13.4801 24.8029H13.2572C12.8984 24.8029 12.6075 24.5069 12.6075 24.1418V23.915C12.6075 21.3452 13.6107 18.8806 15.3965 17.0635L27.9296 4.31055Z" fill={this.props.showBloc === 1 ? '#F66031' : '#5367F4'} stroke={this.props.showBloc === 1 ? '#F66031' : '#5367F4'} />
                                </svg>
                                <span className="active-tab">{t("frontoffice.contract_details.contract_tabs.signatories")}</span>
                            </a>
                        </li>
                    }
                    <li className={`${this.props.activeMenu == 'alertes' ? 'active' : ''}`}>
                        <Link className={this.props.isRestrictedUser || this.props.contractStatus != 2 ? 'disabled-link' : ''} to={"/manage-contract/alertes?contract=" + this.state.contractId}>
                            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.53742 15.6944V9.64634C3.53838 8.19823 4.11431 6.80977 5.13862 5.78614C6.16292 4.76251 7.55177 4.1875 8.99988 4.1875C10.448 4.1875 11.8368 4.76251 12.8611 5.78614C13.8854 6.80977 14.4614 8.19823 14.4623 9.64634V15.6944C14.4628 16.0914 14.6207 16.4721 14.9014 16.7528C15.1821 17.0336 15.5627 17.1915 15.9598 17.1919C16.0998 17.1919 16.2341 17.2476 16.3331 17.3466C16.4322 17.4456 16.4878 17.5799 16.4878 17.72C16.4878 17.86 16.4322 17.9943 16.3331 18.0934C16.2341 18.1924 16.0998 18.248 15.9598 18.248H2.03977C1.89972 18.248 1.76541 18.1924 1.66638 18.0934C1.56735 17.9943 1.51172 17.86 1.51172 17.72C1.51172 17.5799 1.56735 17.4456 1.66638 17.3466C1.76541 17.2476 1.89972 17.1919 2.03977 17.1919C2.43683 17.1915 2.81751 17.0336 3.09829 16.7529C3.37906 16.4721 3.53699 16.0914 3.53742 15.6944Z" stroke="#5367F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7.03906 18.3043C7.07566 18.7996 7.29802 19.2627 7.66162 19.601C8.02522 19.9393 8.5032 20.1277 8.99981 20.1285C9.49643 20.1277 9.97441 19.9393 10.338 19.601C10.7016 19.2627 10.924 18.7996 10.9606 18.3043" stroke="#5367F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.248 4.3351V3.20459C10.248 2.87359 10.1166 2.55614 9.8825 2.32209C9.64845 2.08803 9.331 1.95654 9 1.95654C8.669 1.95654 8.35155 2.08803 8.1175 2.32209C7.88344 2.55614 7.75195 2.87359 7.75195 3.20459V4.33686" stroke="#5367F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span>{t("frontoffice.contract_details.contract_tabs.alerts")}</span>
                        </Link>
                    </li>
                    <li className={`${this.props.activeMenu == 'comments' ? 'active' : ''}`}>
                        <Link className={this.props.isRestrictedUser || this.props.contractStatus != 2 ? 'disabled-link' : ''} to={"/manage-contract/comments?contract=" + this.state.contractId}>
                            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.875 1.95624V15.4562H7.95V19.1687L12 15.4562H22.125V1.95624H1.875Z" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5.9248 12.0812H14.0248M5.9248 5.33124H18.0748H5.9248ZM5.9248 8.70624H18.0748H5.9248Z" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>{t("frontoffice.contract_details.contract_tabs.comments")}</span>
                        </Link>
                    </li>
                    <li className={`${this.props.activeMenu == 'linked-documents' ? 'active' : ''}`}>
                        <Link className={this.props.isRestrictedUser || this.props.contractStatus != 2 ? 'disabled-link' : ''} to={"/manage-contract/linked-documents?contract=" + this.state.contractId}>
                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.66356 15.0785L13.1283 9.69098C13.305 9.51739 13.4452 9.31112 13.5409 9.084C13.6365 8.85688 13.6858 8.61338 13.6858 8.36746C13.6858 8.12154 13.6365 7.87804 13.5409 7.65092C13.4452 7.4238 13.305 7.21753 13.1283 7.04395C12.9522 6.86975 12.743 6.73154 12.5126 6.63723C12.2823 6.54293 12.0353 6.49438 11.7858 6.49438C11.5364 6.49438 11.2894 6.54293 11.059 6.63723C10.8286 6.73154 10.6194 6.86975 10.4433 7.04395L2.29356 15.0785C1.70523 15.6619 1.375 16.4512 1.375 17.2739C1.375 18.0967 1.70523 18.886 2.29356 19.4694C2.88541 20.0494 3.68602 20.375 4.52053 20.375C5.35505 20.375 6.15566 20.0494 6.7475 19.4694L17.3296 9.06815C17.7403 8.66329 18.0661 8.18265 18.2883 7.65367C18.5106 7.12468 18.625 6.55771 18.625 5.98513C18.625 5.41256 18.5106 4.84559 18.2883 4.3166C18.0661 3.78761 17.7403 3.30697 17.3296 2.90212C16.9189 2.49722 16.4314 2.17604 15.8948 1.95691C15.3582 1.73778 14.7831 1.625 14.2023 1.625C13.6215 1.625 13.0464 1.73778 12.5099 1.95691C11.9733 2.17604 11.4858 2.49722 11.0751 2.90212L3.17803 10.6875" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>{t("frontoffice.contract_details.contract_tabs.linked_docs")}</span>
                        </Link>
                    </li>
                    {!this.props.isRestrictedUser && this.props.inNegociationBy && <li className={`${this.props.activeMenu == 'negociations' ? 'active' : ''}`}>
                        <Link to={"/manage-contract/negociations?contract=" + this.state.contractId}>
                            <svg width="34" height="21" viewBox="0 0 34 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.5 2V2.75C6.5 4.82107 4.82107 6.5 2.75 6.5H2V15.5H2.81207C4.88314 15.5 6.56207 17.1789 6.56207 19.25V20H27.5V19.25C27.5 17.1789 29.1789 15.5 31.25 15.5H32V6.5H31.25C29.1789 6.5 27.5 4.82107 27.5 2.75V2H6.5ZM5 2H4.25C3.00736 2 2 3.00736 2 4.25V5H2.75C3.99264 5 5 3.99264 5 2.75V2ZM5.06207 20V19.25C5.06207 18.0074 4.05471 17 2.81207 17H2V17.75C2 18.9926 3.00736 20 4.25 20H5.06207ZM6.08773 21.5C6.0024 21.5337 5.9094 21.5523 5.81207 21.5523C5.71474 21.5523 5.62174 21.5337 5.53641 21.5H4.25C2.17893 21.5 0.5 19.8211 0.5 17.75V4.25C0.5 2.17893 2.17893 0.5 4.25 0.5H29.75C31.8211 0.5 33.5 2.17893 33.5 4.25V17.75C33.5 19.8211 31.8211 21.5 29.75 21.5H6.08773ZM29 20H29.75C30.9926 20 32 18.9926 32 17.75V17H31.25C30.0074 17 29 18.0074 29 19.25V20ZM29 2V2.75C29 3.99264 30.0074 5 31.25 5H32V4.25C32 3.00736 30.9926 2 29.75 2H29ZM17 17C13.6863 17 11 14.3137 11 11C11 7.68629 13.6863 5 17 5C20.3137 5 23 7.68629 23 11C23 14.3137 20.3137 17 17 17ZM17 15.5C19.4853 15.5 21.5 13.4853 21.5 11C21.5 8.51472 19.4853 6.5 17 6.5C14.5147 6.5 12.5 8.51472 12.5 11C12.5 13.4853 14.5147 15.5 17 15.5Z" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>{t("frontoffice.contract_details.contract_tabs.negotiation")}</span>
                        </Link>
                    </li>}
                    <li className={`${this.props.activeMenu == 'endorsements' ? 'active' : ''}`}>
                        <Link className={(!this.props.isContractAvt && !this.props.isContractParent) ? 'disabled-link' : ''} to={this.props.isContractAvt ? "/manage-contract/endorsements?contract=" + this.state.contractId : "/manage-contract/endorsements?parentContract=" + this.state.contractId}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.875 9.75C12.5298 9.75 12.25 9.47018 12.25 9.125C12.25 8.77982 12.5298 8.5 12.875 8.5H19.125C19.4702 8.5 19.75 8.77982 19.75 9.125C19.75 9.47018 19.4702 9.75 19.125 9.75H12.875ZM10.375 13.5C10.0298 13.5 9.75 13.2202 9.75 12.875C9.75 12.5298 10.0298 12.25 10.375 12.25H19.125C19.4702 12.25 19.75 12.5298 19.75 12.875C19.75 13.2202 19.4702 13.5 19.125 13.5H10.375ZM10.375 17.25C10.0298 17.25 9.75 16.9702 9.75 16.625C9.75 16.2798 10.0298 16 10.375 16H16.625C16.9702 16 17.25 16.2798 17.25 16.625C17.25 16.9702 16.9702 17.25 16.625 17.25H10.375ZM12.875 3.5C12.5298 3.5 12.25 3.22018 12.25 2.875C12.25 2.52982 12.5298 2.25 12.875 2.25H21.625C23.3509 2.25 24.75 3.64911 24.75 5.375V21.625C24.75 23.3509 23.3509 24.75 21.625 24.75H7.875C6.14911 24.75 4.75 23.3509 4.75 21.625V15.375C4.75 15.0298 5.02982 14.75 5.375 14.75C5.72018 14.75 6 15.0298 6 15.375V21.625C6 22.6605 6.83947 23.5 7.875 23.5H21.625C22.6605 23.5 23.5 22.6605 23.5 21.625V5.375C23.5 4.33947 22.6605 3.5 21.625 3.5H12.875ZM6 7.875C6 8.22018 5.72018 8.5 5.375 8.5C5.02982 8.5 4.75 8.22018 4.75 7.875V3.5C4.75 2.11929 5.86929 1 7.25 1C8.63071 1 9.75 2.11929 9.75 3.5V7.875C9.75 10.2912 7.79125 12.25 5.375 12.25C2.95875 12.25 1 10.2912 1 7.875V1.625C1 1.27982 1.27982 1 1.625 1C1.97018 1 2.25 1.27982 2.25 1.625V7.875C2.25 9.60089 3.64911 11 5.375 11C7.10089 11 8.5 9.60089 8.5 7.875V3.5C8.5 2.80964 7.94036 2.25 7.25 2.25C6.55964 2.25 6 2.80964 6 3.5V7.875Z" fill="#5367F4" stroke="#5367F4" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>{t("frontoffice.contract_details.contract_tabs.endorsement")}</span>
                        </Link>
                    </li>
                    {!this.props.isRestrictedUser &&
                        <li className={`${this.props.activeMenu == 'tags' ? 'active' : ''}`}>
                            <Link to={"/manage-contract/tags?contract=" + this.state.contractId}>
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 4.33224C1 2.4919 2.4919 1 4.33224 1H12.3296C12.5064 1 12.6759 1.07022 12.8009 1.1952L23.8678 12.2622C25.3774 13.7717 25.3774 16.2191 23.8678 17.7287L17.7287 23.8678C16.2191 25.3774 13.7717 25.3774 12.2622 23.8678L1.1952 12.8009C1.07022 12.6759 1 12.5064 1 12.3296V4.33224ZM12.0536 2.3329H4.33224C3.22803 2.3329 2.3329 3.22803 2.3329 4.33224V12.0536L13.2047 22.9253C14.1937 23.9144 15.7972 23.9144 16.7862 22.9253L22.9253 16.7862C23.9144 15.7972 23.9144 14.1937 22.9253 13.2047L12.0536 2.3329ZM7.66449 6.33159C6.92835 6.33159 6.33159 6.92835 6.33159 7.66449C6.33159 8.40063 6.92835 8.99738 7.66449 8.99738C8.40063 8.99738 8.99738 8.40063 8.99738 7.66449C8.99738 6.92835 8.40063 6.33159 7.66449 6.33159ZM7.66449 4.99869C9.13676 4.99869 10.3303 6.19221 10.3303 7.66449C10.3303 9.13676 9.13676 10.3303 7.66449 10.3303C6.19221 10.3303 4.99869 9.13676 4.99869 7.66449C4.99869 6.19221 6.19221 4.99869 7.66449 4.99869Z" fill="#5367F4" stroke="#5367F4" />
                                </svg>
                                <span>{t("frontoffice.contract_details.contract_tabs.tags")}</span>
                            </Link>
                        </li>}
                </div>
                
                <li className="no-underline">
                    <a className={this.containerRef.current && this.state.scrollPosition + this.containerRef.current.offsetWidth >= this.containerRef.current.scrollWidth ? "scroll-button scroll-button-disabled":"scroll-button"}
                        onClick={() => this.scrollTabs(100)}
                        disabled={this.containerRef.current &&
                            this.state.scrollPosition + this.containerRef.current.offsetWidth >=
                            this.containerRef.current.scrollWidth}>
                        <svg width="25" height="48" viewBox="0 0 25 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.4436 23.9999L1.45721 5.48136C0.401404 4.25833 0.544053 2.41704 1.77582 1.36872C3.00759 0.320411 4.86203 0.462048 5.91784 1.68508L23.5428 22.1017C24.4858 23.194 24.4858 24.8058 23.5428 25.898L5.91784 46.3147C4.86203 47.5377 3.00759 47.6794 1.77582 46.631C0.544053 45.5827 0.401404 43.7414 1.45721 42.5184L17.4436 23.9999Z" fill="#F66031" />
                        </svg>
                    </a>
                </li>
                
            </ul>
        )
    }
}

//export default BoContractSettings;
export default withTranslation()(withRouter(BoContractSettings))
