import React from "react";
import {CheckIcon } from "../../../assets/icons";
const ButtonCheck = ({ onPress, disabled = false }) => (
  <button
    style={{display:'flex'}}
    type="button"
    className={`btn btn-default ${disabled ? "" : ""}`}
    onClick={onPress}
    disabled={disabled}
  >
    <CheckIcon />
  </button>
);

export default ButtonCheck;
