const SmallFrench = {
    'zéro': 0,
    'zero': 0,
    'un': 1,
    'une': 1, // Additional mapping for "une"
    'deux': 2,
    'trois': 3,
    'quatre': 4,
    'cinq': 5,
    'six': 6,
    'sept': 7,
    'huit': 8,
    'neuf': 9,
    'dix': 10,
    'onze': 11,
    'douze': 12,
    'treize': 13,
    'quatorze': 14,
    'quinze': 15,
    'seize': 16,
    'dix-sept': 17,
    'dix-huit': 18,
    'dix-neuf': 19,
    'vingt': 20,
    'trente': 30,
    'quarante': 40,
    'cinquante': 50,
    'soixante': 60,
    'septante': 70,
    'septante-deux': 72,
    'septante-trois': 73,
    'septante-quatre': 74,
    'septante-cinq': 75,
    'septante-six': 76,
    'septante-sept': 77,
    'septante-huit': 78,
    'septante-neuf': 79,
    'soixante-dix': 70,
    'soixante-onze': 71,
    'soixante-douze': 72,
    'soixante-treize': 73,
    'soixante-quatorze': 74,
    'soixante-quinze': 75,
    'soixante-seize': 76,
    'soixante-dix-sept': 77,
    'soixante-dix-huit': 78,
    'soixante-dix-neuf': 79,
    'huitante': 80,
    'huitante-deux': 82,
    'huitante-trois': 83,
    'huitante-quatre': 84,
    'huitante-cinq': 85,
    'huitante-six': 86,
    'huitante-sept': 87,
    'huitante-huit': 88,
    'huitante-neuf': 89,
    'quatre-vingt': 80,
    'nonante': 90,
    'nonante-deux': 92,
    'nonante-trois': 93,
    'nonante-quatre': 94,
    'nonante-cinq': 95,
    'nonante-six': 96,
    'nonante-sept': 97,
    'nonante-huit': 98,
    'nonante-neuf': 99,
    'quatre-vingt-dix': 90,
    'quatre-vingt-onze': 91,
    'quatre-vingt-douze': 92,
    'quatre-vingt-treize': 93,
    'quatre-vingt-quatorze': 94,
    'quatre-vingt-quinze': 95,
    'quatre-vingt-seize': 96,
    'quatre-vingt-dix-sept': 97,
    'quatre-vingt-dix-huit': 98,
    'quatre-vingt-dix-neuf': 99
  };

  const MagnitudeFrench = {
    'cent': 100,
    'cents': 100,
    'mille': 1000,
    'milles': 1000,
    'million': 1000000,
    'millions': 1000000,
    'milliard': 1000000000,
    'milliards': 1000000000,
    'billion': 1000000000000,
    'billions': 1000000000000,
    'billiard': 1000000000000000,
    'billiards': 1000000000000000,
    // Add more as needed
  };

  const checkRange_two_to_nine = (key) =>{
    switch (key) {
      case "deux":
        return true;
        break;
      case "trois":
        return true;
        break;
      case "quatre":
        return true;
        break;
      case "cinq":
        return true;
        break;
      case "six":
        return true;
        break;
      case "sept":
        return true;
        break;
      case "huit":
        return true;
        break;
      case "neuf":
        return true;
        break;
    
      default:
        return false
        break;
    }
  }

export  const text2numFrench = (s) => {
    if (!(s != null) || s === "") {
      return s;
    }
    s = s.trim().replace(/\bet\b/gi, ""); // This removes "et" which means "and" in French
    let a = s.split(/[\s]+/);
    let n = 0;
    let g = 0;
    let decimals = false;
    let d = 0;
    let dn = 0;
    let dg = 0;
    for (let i = 0; i < a.length; i++) {
      let w = a[i].toLowerCase();
      let x = SmallFrench[w];
      if (x != null) {
        if (decimals) {
          dg += x;
        } else {
          g += x;
        }
      } else if (w === "mille" ||  w === "milles") {
        if (g === 0 && dg === 0 && dn === 0) { // Check if "cent" is at the beginning of the number
          g = 1000;
        } else {
          if (decimals) {
            dg *= 1000;
          } else {
            g *= 1000;
          }
        }
      } else if (w === "cent" || w === "cents") {
        if (g === 0 && dg === 0 && dn === 0) { // Check if "cent" is at the beginning of the number
          g = 100;
        } else {
          if (decimals) {
            dg += (100*((i>0 && checkRange_two_to_nine(a[i-1].toLowerCase())) ? SmallFrench[a[i-1].toLowerCase()]:1))-
            ((i>0 && checkRange_two_to_nine(a[i-1].toLowerCase())) ? SmallFrench[a[i-1].toLowerCase()]:0);
          } else {
            g += (100*((i>0 && checkRange_two_to_nine(a[i-1].toLowerCase())) ? SmallFrench[a[i-1].toLowerCase()]:1))-
              ((i>0 && checkRange_two_to_nine(a[i-1].toLowerCase())) ? SmallFrench[a[i-1].toLowerCase()]:0);
          }
        }
      } else if (w === "virgule" || w === "point") { // These are French equivalents of "dot" or "point"
        decimals = true;
      } else {
        x = MagnitudeFrench[w];
        if (x != null) {
          if (decimals) {
            dn += dg * x;
            dg = 0;
          } else {
            n += g * x;
            g = 0;
          }
        } else {
          return 0;
          throw new Error("Nombre inconnu : " + w); // "Unknown number" in French
        }
      }
    }
    d += dn + dg;
    d = moveDecimal(d);
    if (d > 0) {
      return n + g + d;
    } else {
      return n + g;
    }
  };

  const moveDecimal = (n) => {
    const l = n.toString().length;
    const v = n / Math.pow(10, l);
    return v;
  };  