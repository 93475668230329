import { Record, List } from 'immutable';

class DashBordData extends Record({
  scale: '',
  cryptedData: [],
  isRGPD: false
}) {
  constructor(values) {
    let record = super(values);
    if(values.scale && values.scale !== null && values.scale !== undefined){
      record = record.set('scale', values?.scale).set(
        'cryptedData',
        values?.cryptedData.map((item) => new CryptedData(item))
      ).set('isRGPD', values?.isRGPD);
    }
    else{
      record = new Values(values)
    }
    return record;
  }
}

class CryptedData extends Record({
  name: '',
  values: [],
  isRGPD: false
}) {
  constructor(values) {
    let record = super(values);
    record = record.set('name', values?.cryptedDatum).set(
      'values',
      values?.values.map((item) => new Values(item))
      ).set('isRGPD', values?.isRGPD);
      return record;
    }
  }
  
  class Values extends Record({
    name: '',
    amount: 0,
    count: 0,
    contracts:[],
    databuyers:[],
    isRGPD: false
  }) {
    constructor(values) {
      let record = super(values);
    record = record.set('name', values?.value)
    .set('amount', values?.amount)
    .set('count', values?.count)
    .set('contracts', values?.contracts)
    .set('databuyers', values?.data)
    .set('isRGPD', values?.isRGPD)
    return record;
  }
}

export default DashBordData;
