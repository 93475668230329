import Cookies from 'js-cookie';

export const getConnectedUserAccessToken = () => {
    if (localStorage.getItem('apiToken')) {
        return localStorage.getItem('apiToken');
    }
}

export const getCurrentClientID = () => {
    let currentClient = Cookies.get("currentClient");
    if(currentClient) {
        return JSON.parse(currentClient).client.id;
    }
    return 0;
}

export const getCurrentUserID = () => {
    let currentUser = Cookies.get("userRights");
    if(currentUser) {
        return JSON.parse(currentUser).id;
    }
    return 0;
}

export const isAdmin = () => {
    if (Cookies.get("userRights")) {
        if (JSON.parse(Cookies.get("userRights")).isAdmin == true) {
            return true;
        }
    }
    return null;
}

export const isUser = () => {
    
    let undefinedStates = ['undefined', undefined, 'null', null]
    const currentClient = !undefinedStates.includes(Cookies.get("currentClient")) ?  JSON.parse( Cookies.get("currentClient")) : null
    
    if( currentClient ){
        const search = window.location.search;
        const query = new URLSearchParams(search);
        if(query && query.get('client') && query.get('renew_success')){
            let clientList = JSON.parse(Cookies.get("currentUserInformation"))
            let userRights = clientList['userRights']
            let foundedItem = userRights.find(x=> x.roleType === 'USER' && x['client'].id.toString() === query.get('client').toString())
            if(foundedItem){
                Cookies.set("currentClient",foundedItem)
                return true;
            }
        }
        if(query && query.get('client') && query.get('tab') && Cookies.get("currentUserInformation")){
            let clientList = JSON.parse(Cookies.get("currentUserInformation"))
            let userRights = clientList['userRights']
            let foundedItem = userRights.find(x=> x.roleType === 'USER' && x['client'].id.toString() === query.get('client').toString())
            if(foundedItem){
                Cookies.set("currentClient",foundedItem)
                return true;
            }
        }
        const roleType = currentClient.roleType
        if (roleType === "USER" || roleType === "GUEST" || roleType === "COMMERCIAL_CONTRACT" || roleType === "TECHNICAL_CONTRACT") {
            return true;
        }else if(query && query.get('contract')){
            return true;
        }else{
            return null;
        }
    }else if(Cookies.get("userRights")){
        const search = window.location.search;
        const query = new URLSearchParams(search);
        if(query && query.get('contract')){
            return true;
        }else{
            return null;
        }
    } else {
        const search = window.location.search;
        const query = new URLSearchParams(search);
        if (query && query.get('client') && query.get('tab')) {
            Cookies.set("redirectionArchive", {
                client: query.get('client'),
                tab: query.get('tab'),
            })
        }
    }
}

export const isNewGuest = () => {
    const search = window.location.search;
    const query = new URLSearchParams(search);
    if(query && query.get('redirect') && query.get('guest_id') && (query.get('redirect') === true || parseInt(query.get('redirect')) === 1) && parseInt(query.get('guest_id'))){
      Cookies.set("guest_id",parseInt(query.get('guest_id')));
      return true;
    }
    return null;
}

export const isClientAdmin = () => {
    if (Cookies.get("currentClient")) {
        if (JSON.parse(Cookies.get("currentClient")).roleType == "ADMIN_CLIENT") {
            return true;
        }
    }
    return null;
}

export const isAdminClientOrUser = () => {
    if (Cookies.get("userRights")) {
        if (JSON.parse(Cookies.get("userRights")).isAdmin == true) {
            return true;
        }
        if(Cookies.get("currentClient")) {
            if (JSON.parse(Cookies.get("currentClient")).roleType == "ADMIN_CLIENT" || JSON.parse(Cookies.get("currentClient")).roleType == "USER" || JSON.parse(Cookies.get("currentClient")).roleType == "COMMERCIAL_CONTRACT" || JSON.parse(Cookies.get("currentClient")).roleType == "TECHNICAL_CONTRACT") {
                return true;
            }
        }
    }
    return null;
}