import React, { Component } from "react";
import {
  getUsers,
  dissociateUserToClient,
  getOrganizations,
  createUser,
  exportUsers,
  exportUser,
} from "../../../../../api/user";
import "./UsersAccess.scss";
import ErrorAlert from "../../../../../components/confirm-alert/error-alert";

// components
import Header from "../../../../../components/layouts/header/Header";
import Sidebar from "../../../../../components/layouts/sidebar/Sidebar";
import BoRightsManagementMenu from "../../../../../components/layouts/BO-Rights-Management/BoRightsManagementMenu";
import ConfirmAlert from "../../../../../components/confirm-alert/ConfirmAlert";
import LoaderComponent from "../../../../../components/loader/Loader";
import EditMode from "../../../../../components/edit-mode/EditMode";

// plugins
import * as moment from "moment/min/moment-with-locales";

// XLSX library
import * as XLSX from 'xlsx';

const ObjectsToCsv = require("objects-to-csv");

// form validation
const ReactFormWithConstraints = require("react-form-with-constraints");


/* eslint-disable import/first */
import ReactMultiSelectCheckboxes from "../../../../../components/react-multiselect";
import { withTranslation } from "react-i18next";
import i18next, { t } from "i18next";
import Moment from "moment";
import DatePicker from "react-datepicker";
import Modal from "../../../../../components/Modal";
import ResumeDataModal from "../../../../../components/resumeDataModal/ResumeDataModal";


const { FormWithConstraints, FieldFeedbacks, FieldFeedback } =
  ReactFormWithConstraints;

class UsersAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      default: 123,
      showFormAdduser: false,
      editMode: false,
      users: [],
      selectedUser: {},
      selectedUserIndex: null,
      isNewUser: true,
      scrolling: false,
      organisationOptions: [],
      selectedOrganisations: [],
      showModal:false,
      downloadStage: 0, // 0: no stage selected | 1: exportUser | 2: exportUsers
      organisationInvalid: false, 
      showResumeModal: false,
      actionData: "add", // add | edit | delete
      formUser: [
        { name: "lastName", txtLabel: "Prénom Utilisateur", value: null, edited: false },
        { name: "firstName", txtLabel: "Nom Utilisateur", value: null, edited: false },
        { name: "limitDate", txtLabel: "Date limite", value: null, edited: false },
        { name: "email", txtLabel: "Adresse Mail Utilisateur", value: null, edited: false },
        { name: "emails", txtLabel: "Autres adresse Mail", value: null, edited: false },
        { name: "organisations", txtLabel: "Organisation", value: null, edited: false }
      ]
    };

    this.allusers = this.state.users;
  }

  componentDidMount() {
    LoaderComponent.show();
    getUsers().then((response) => {
      getOrganizations().then((response) => {
        LoaderComponent.hide();
        this.setState({
          ...this,
          organisationOptions: response.data,
        });
      });
      this.setState(
        {
          ...this,
          users: response.data,
        },
        () => {
          this.allusers = [...this.state.users];
          document.getElementById("search-users-input").value = "";
        }
      );
    });
  }

  changeEditMode = (checked) => {
    this.setState({
      ...this,
      editMode: checked,
    });
  };

  exitForm = () => {
    return new Promise((resolve, reject) => {
      if (
        !this.state.showFormAdduser ||
        !this.state.editMode ||
        Object.keys(this.state.selectedUser).length == 0
      ) {
        resolve();
      } else {
        if (
          this.state.selectedUser !==
          this.state.users[this.state.selectedUserIndex]
        ) {
          ConfirmAlert.show(t('common.modals.confirm_action')).then(
            resolve,
            reject
          );
        } else {
          resolve();
        }
      }
    });
  };

  newuser = () => {
    this.exitForm().then(() => {
      document.getElementById("form-user").reset();
      this.setState({
        ...this.state,
        actionData: "add",
        isNewUser: true,
        showFormAdduser: true,
        selectedUser: {
          selectedOrganisations: [],
        },
      });
    });
  };

  cancelNewuser = () => {
    this.exitForm().then(() => {
      document.getElementById("form-user").reset();
      this.setState({
        ...this,
        isNewUser: false,
        selectedUserIndex: null,
        showFormAdduser: false,
        organisationInvalid: false,
        selectedUser: {
          selectedOrganisations: [],
        },
      });
    });
  };

  handleChangeInput = (e) => {
    if (!this.state.selectedUser.hasOwnProperty("firstName")) {
      this.setState({
        ...this,
        isNewUser: true,
        showFormAdduser: true,
        selectedUser: {
          selectedOrganisations: [],
        },
      });
    }
    this.form.validateFields(e.target);
    this.setState({
      ...this,
      selectedUser: {
        ...this.state.selectedUser,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeEmails = (e, index) => {
    let emails = this.state.selectedUser.emails;
    emails[index] = e.target.value;
    this.setState({
      ...this,
      selectedUser: {
        ...this.state.selectedUser,
        emails: emails,
      },
    });
  };

  addNewuser = () => {
    this.form.validateFields();

    if (this.state.selectedUser["selectedOrganisations"].length === 0) {
      this.setState(
        {
          ...this,
          organisationInvalid: true
        }
      )
      return;
    }else{
      this.setState(
        {
          ...this,
          organisationInvalid: false
        }
      )
    }

    setTimeout(() => {
      if (this.form.isValid()) {
        if (this.state.isNewUser) {
          console.log(this.state.selectedUser);
          LoaderComponent.show();
          createUser(this.state.selectedUser).then(
            (response) => {
              getUsers().then((response) => {
                LoaderComponent.hide();
                this.setState(
                  {
                    ...this,
                    users: response.data,
                    showResumeModal: false
                  },
                  () => {
                    this.allusers = [...this.state.users];
                    document.getElementById("search-users-input").value = "";
                  }
                );
              });
              this.setState({
                showFormAdduser: !this.state.showFormAdduser,
                selectedUser: {
                  selectedOrganisations: [],
                },
              });
              document.getElementById("form-user").reset();
            },
            (error) => {
              ErrorAlert.show(error.response.data, t('common.modals.api_error'));
              getUsers().then((response) => {
                LoaderComponent.hide();
                this.setState(
                  {
                    ...this,
                    users: response.data,
                    showResumeModal: false
                  },
                  () => {
                    this.allusers = [...this.state.users];
                    document.getElementById("search-users-input").value = "";
                  }
                );
              });
            }
          );
          //this.state.users.unshift(this.state.selectedUser);
        } else {
          this.Updateuser();
        }
      }
    });
  };

  viewuserInfo = (user, index) => {
    if(user && user.rgpd)
      return
    if (!user.selectedOrganisations) user.selectedOrganisations = [];
    this.exitForm().then(() => {
      this.setState({
        ...this,
        isNewUser: false,
        showFormAdduser: true,
        selectedUser: user,
        selectedUserIndex: index,
        actionData: "edit",
        formUser: [
          { name: "firstName", txtLabel: "Prénom Utilisateur", value: null, originValue: user?.firstName, edited: false },
          { name: "lastName", txtLabel: "Nom Utilisateur", value: null, originValue: user?.lastName, edited: false },
          { name: "limitDate", txtLabel: "Date limite", value: null, originValue: user?.limitDate, edited: false },
          { name: "email", txtLabel: "Adresse Mail Utilisateur", value: null, originValue: user?.email, edited: false },
          {
            name: "emails", txtLabel: "Autres adresse Mail", value: null,
            originValue: (Array.isArray(user?.emails) ? user?.emails?.map(email => `${email}`).join('\n ') : user?.emails),
            edited: false
          },
          {
            name: "organisations", txtLabel: "Organisation(s)", value: null,
            originValue: (Array.isArray(user?.selectedOrganisations) ? user?.selectedOrganisations?.map(organisation => `${organisation.label}`).join('\n ') : user?.selectedOrganisations),
            edited: false
          }
        ]
      });
      document.getElementById("form-user").reset();
    });
  };

  Updateuser = () => {
    let users = this.state.users;
    createUser(this.state.selectedUser).then(
      (response) => {
        getUsers().then((response) => {
          this.setState(
            {
              ...this,
              users: response.data,
              showResumeModal: false
            },
            () => {
              this.allusers = [...this.state.users];
              document.getElementById("search-users-input").value = "";
            }
          );
        });
      },
      (error) => {
        ErrorAlert.show(error.response.data, t('common.modals.api_error'));
        getUsers().then((response) => {
          this.setState(
            {
              ...this,
              users: response.data,
              showResumeModal: false
            },
            () => {
              this.allusers = [...this.state.users];
              document.getElementById("search-users-input").value = "";
            }
          );
        });
      }
    );
    users[this.state.selectedUserIndex] = this.state.selectedUser;
    this.setState({
      ...this,
      isNewUser: false,
      showFormAdduser: false,
      selectedUserIndex: null,
      users: users,
      selectedUser: {},
      showResumeModal: false
    });
    document.getElementById("form-user").reset();
  };

  viewNextuser = () => {
    let index = parseInt(this.state.selectedUserIndex);
    let users = this.state.users;
    console.log(index + 1);
    if (index >= 0 && index < users.length - 1) {
      if(users && users[index + 1] && users[index + 1].rgpd)
        return
      this.setState({
        ...this,
        selectedUser: users[index + 1],
        selectedUserIndex: [index + 1],
        actionData: "edit",
        formUser: [
          { name: "firstName", txtLabel: "Prénom Utilisateur", value: null, originValue: users[index + 1]?.firstName, edited: false },
          { name: "lastName", txtLabel: "Nom Utilisateur", value: null, originValue: users[index + 1]?.lastName, edited: false },
          { name: "limitDate", txtLabel: "Date limite", value: null, originValue: users[index + 1]?.limitDate, edited: false },
          { name: "email", txtLabel: "Adresse Mail Utilisateur", value: null, originValue: users[index + 1]?.email, edited: false },
          {
            name: "emails", txtLabel: "Autres adresse Mail", value: null,
            originValue: (Array.isArray(users[index + 1]?.emails) ? users[index + 1]?.emails?.map(email => `${email}`).join('\n ') : users[index + 1]?.emails),
            edited: false
          },
          {
            name: "organisations", txtLabel: "Organisation(s)", value: null,
            originValue: (Array.isArray(users[index + 1]?.selectedOrganisations) ? users[index + 1]?.selectedOrganisations?.map(organisation => `${organisation.label}`).join('\n ') : users[index + 1]?.selectedOrganisations),
            edited: false
          }
        ]
      });
    }
  };

  viewPrevuser = () => {
    let index = parseInt(this.state.selectedUserIndex);
    let users = this.state.users;
    console.log(index - 1);
    if (index >= 1 && index <= users.length) {
      if(users && users[index - 1] && users[index - 1].rgpd)
        return
      this.setState({
        ...this,
        selectedUser: users[index - 1],
        selectedUserIndex: [index - 1],
        actionData: "edit",
        formUser: [
          { name: "firstName", txtLabel: "Prénom Utilisateur", value: null, originValue: users[index - 1]?.firstName, edited: false },
          { name: "lastName", txtLabel: "Nom Utilisateur", value: null, originValue: users[index - 1]?.lastName, edited: false },
          { name: "limitDate", txtLabel: "Date limite", value: null, originValue: users[index - 1]?.limitDate, edited: false },
          { name: "email", txtLabel: "Adresse Mail Utilisateur", value: null, originValue: users[index - 1]?.email, edited: false },
          {
            name: "emails", txtLabel: "Autres adresse Mail", value: null,
            originValue: (Array.isArray(users[index - 1]?.emails) ? users[index - 1]?.emails?.map(email => `${email}`).join('\n ') : users[index - 1]?.emails),
            edited: false
          },
          {
            name: "organisations", txtLabel: "Organisation(s)", value: null,
            originValue: (Array.isArray(users[index - 1]?.selectedOrganisations) ? users[index - 1]?.selectedOrganisations?.map(organisation => `${organisation.label}`).join('\n ') : users[index - 1]?.selectedOrganisations),
            edited: false
          }
        ]
      });
    }
  };

  sortusers = (sortby, sort) => {
    let users = this.state.users;
    users.sort(function (a, b) {
      if (a[sortby] < b[sortby]) return sort == "ASC" ? -1 : 1;
      if (a[sortby] > b[sortby]) return sort == "ASC" ? 1 : -1;
      return 0;
    });
    this.setState({
      ...this,
      users: users,
    });
  };

  searchusers = (e) => {
    let users = this.allusers;
    let searchInput = e.target.value;
    let foundedusers = [];
    users.map((user, index) => {
      if (user.firstName.toLowerCase().includes(searchInput.toLowerCase()) || user.lastName.toLowerCase().includes(searchInput.toLowerCase()) || user.email.toLowerCase().includes(searchInput.toLowerCase()))
        foundedusers.push(user);
    });
    this.setState({
      ...this,
      users: foundedusers,
    });
  };

  exportuser = () => {
    let data = [this.state.selectedUser];
    const csv = new ObjectsToCsv(data).toString();
    // Save to file:
    csv.then((csvString) => {
      let link = document.createElement("a");
      link.id = "download-csv";
      link.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(csvString)
      );
      link.setAttribute("download", this.state.selectedUser.firstName + ".csv");
      document.body.appendChild(link);
      document.querySelector("#download-csv").click();
      setTimeout(() => {
        document.querySelector("#download-csv").remove();
      });
    });
  };

  addEmailField = () => {
    let emails = this.state.selectedUser.emails || [];
    emails.push("");
    this.setState({
      ...this,
      selectedUser: {
        ...this.state.selectedUser,
        emails: emails,
      },
    });
  };

  deleteUser = () => {
    ConfirmAlert.show(t('common.modals.confirm_action')).then(
      () => {
        console.log(this.state.selectedUser);
        let users = this.state.users;
        //users.splice(this.state.selectedUserIndex, 1) ;
        LoaderComponent.show();
        dissociateUserToClient(this.state.selectedUser.id).then(
          (response) => {
            getUsers().then((response) => {
              this.setState(
                {
                  ...this,
                  users: response.data,
                },
                () => {
                  LoaderComponent.hide();
                  this.allusers = [...this.state.users];
                  document.getElementById("search-users-input").value = "";
                }
              );
            });
          },
          (error) => {
            LoaderComponent.hide();
            ErrorAlert.show(error.response.data, t('common.modals.api_error'));
          }
        );

        document.getElementById("form-user").reset();
        this.setState({
          ...this,
          users: users,
          isNewUser: false,
          showFormAdduser: false,
          selectedUser: {},
          selectedUserIndex: null,
        });
      }
    );
  };

  changeOrganisations = (organisations) => {
    this.setState({
      ...this,
      selectedUser: {
        ...this.state.selectedUser,
        selectedOrganisations: organisations,
      },
    });
  };

  unselectOrganisation = (index) => {
    let organisations = this.state.selectedUser.selectedOrganisations;
    organisations[index].unselected = !organisations[index].unselected;
    this.setState({
      ...this,
      selectedUser: {
        ...this.state.selectedUser,
        selectedOrganisations: organisations,
      },
    });
  };

  onScrolling = (e) => {
    this.setState({
      ...this,
      scrolling: e.target.scrollTop > 100 ? true : false,
    });
  };

  handleLimitDate = (name, value) => {
    var selectedUser = this.state.selectedUser;
    if (value) {
      selectedUser.limitDate = Moment(value).format("DD/MM/YYYY");
    } else {
      selectedUser.limitDate = null;
    }
    this.setState(
        {
          ...this,
          selectedUser: selectedUser,
        });
  };

  exportUser = ()=>{
    this.handleShow(1)
  }

  doExportUser = (fileExtension = '')=>{
    LoaderComponent.show()
    exportUser(this.state.selectedUser.id).then((result) => {
        LoaderComponent.hide()
        if(fileExtension === 'csv'){
          const fileName = `Export-utilisateur-${this.state.selectedUser.firstName +"-"+this.state.selectedUser.lastName +"-"+moment().format('DD-MM-YYYY')}.csv`;
          if (result) this.fileDownload(result.data, fileName);
        }
        if(fileExtension === 'xlsx'){
          const fileNameExcel = `Export-utilisateur-${this.state.selectedUser.firstName +"-"+this.state.selectedUser.lastName +"-"+moment().format('DD-MM-YYYY')}.xlsx`;
          if (result) this.convertCsvToExcelBuffer(result.data, fileNameExcel)
        }
        this.handleClose()
    },
    error=>{
        LoaderComponent.hide()
        ErrorAlert.show(error.response.data, "Erreur API");
    });
  }

  

  exportUsers = ()=>{
    this.handleShow(2)
  }

  doExportUsers = (fileExtension = '')=>{
    LoaderComponent.show()
    exportUsers().then((result) => {
        LoaderComponent.hide()
        if(fileExtension === 'csv'){
          const fileName = `Export-utilisateurs-${moment().format('DD-MM-YYYY')}.csv`;
          if (result) this.fileDownload(result.data, fileName);
        }
        if(fileExtension === 'xlsx'){
          const fileNameExcel = `Export-utilisateurs-${moment().format('DD-MM-YYYY')}.xlsx`;
          if (result) this.convertCsvToExcelBuffer(result.data, fileNameExcel)
        }
        this.handleClose()
    },
    error=>{
        LoaderComponent.hide()
        ErrorAlert.show(error.response.data, "Erreur API");
    });
  }

  fileDownload = (data, fileName) => {
      var universalBOM = '\uFEFF';
      var a = window.document.createElement('a');
      a.setAttribute(
        'href',
        'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + data)
      );
      a.setAttribute('download', fileName);
      window.document.body.appendChild(a);
      a.click();
    };

    convertCsvToExcelBuffer = (csvString, fileNameExcel) => {
      const arrayOfArrayCsv = csvString.split("\n").map((row) => {
        return row.split(";")
      });
      const wb = XLSX.utils.book_new();
      const newWs = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
      XLSX.utils.book_append_sheet(wb, newWs);
      const rawExcel = XLSX.write(wb, { type: 'base64' })
      console.log('rawExcel',rawExcel)
      XLSX.writeFile(wb, fileNameExcel);
      return rawExcel
    }

  handleShow = (stage = 0) => this.setState(
    {
      ...this.state,
      showModal: true,
      downloadStage: stage
    }
  );

  handleClose = () => this.setState(
    {
      ...this.state,
      showModal: false,
      downloadStage: 0,
      showResumeModal: false
    }
  );

  onDownloadCSVFile = () => {
    if (this.state.downloadStage === 1) this.doExportUser('csv')
    if (this.state.downloadStage === 2) this.doExportUsers('csv')
  }

  onDownloadXLSXFile = () => {
    if (this.state.downloadStage === 1) this.doExportUser('xlsx')
    if (this.state.downloadStage === 2) this.doExportUsers('xlsx')
  }

  editModeReminder = () => {
    ConfirmAlert.show(
      "Voulez-vous activer le mode d'édition pour effectuer des modifications ?",
      "Rappel",
      "Activer",
      "Annuler"
    ).then(() => {
      this.setState({
        ...this.state,
        editMode: true,
      });
    });
  };

  setFormUser = (e, action) => {
    let name = e.target.name;
    let value = e.target.value;
    let formUser = this.state.formUser
    let findIndex = formUser.findIndex(x => x.name === name);
    if (findIndex > -1) {
      if (Array.isArray(value)) {
        value = value.map(item => `${item.label || item}`).join('\n ');
      }
      formUser[findIndex].value = value

      if (action === "edit") {
        formUser[findIndex].edited = true
      } else {
        formUser[findIndex].edited = false
      }
    } else {
      formUser.push({
        name: name,
        value: value,
        isRequired: true
      })
    }
    this.setState({
      ...this.state,
      actionData: action,
      formUser: formUser
    })
  }

  doSave = () => {
    this.addNewuser();
  }

  handleCancel = () => {
    document.getElementById("form-user").reset();
    this.setState({
      ...this,
      isNewUser: false,
      showFormAddUser: false,
      selectedUser: {},
      selectedUserIndex: null,
      showResumeModal: false,
      actionData: "add", // add | edit | delete
      formUser: [
        { name: "lastName", txtLabel: "Prénom Utilisateur", value: null, edited: false },
        { name: "firstName", txtLabel: "Nom Utilisateur", value: null, edited: false },
        { name: "limitDate", txtLabel: "Date limite", value: null, edited: false },
        { name: "limitDate", txtLabel: "Date limite", value: null, edited: false },
        { name: "email", txtLabel: "Adresse Mail Utilisateur", value: null, edited: false },
        { name: "emails", txtLabel: "Autres adresse Mail", value: null, edited: false },
        { name: "organisations", txtLabel: "Organisation", value: null, edited: false }
      ]
    });
  }

  render() {
    const lang = i18next.resolvedLanguage
    return (
      <div className="app-container backoffice-rights-access page-user app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
        <Header />

        <div className="app-main">
          <div className="app-sidebar sidebar-shadow">
            <Sidebar />
          </div>
          <div className="app-main__outer">
            <EditMode
              isEditMode={this.state.editMode}
              onChange={(checked) => {
                this.changeEditMode(checked);
              }}
            />
            <div className="app-main__inner">
              <div className="row">
                <div
                  className={`col-sm-5 left-side ${
                    this.state.scrolling ? "scrolling" : ""
                  }`}
                >
                  {this.state.showModal &&
                    <Modal
                      title={"Export"}
                      isOpen={this.state.showModal}
                      onRequestClose={() => this.handleClose()}
                      footer={
                        <>
                          <button onClick={() => this.onDownloadCSVFile()}>
                            CSV
                          </button>
                          <button onClick={() => this.onDownloadXLSXFile()}>
                            XLSX
                          </button>
                        </>
                      }
                      isFullScreen={false}
                    >
                      <div>
                        <p className="text-center font-weight-bold">
                          Veuillez choisir le format souhaité
                        </p>
                      </div>
                    </Modal>
                  }
                  <h1 className="side-menu-title-style">
                    <svg
                      width="43"
                      height="42"
                      viewBox="0 0 43 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M26.7924 15.3392L32.125 19.3125C33.3142 20.2044 34.0684 21.5478 34.2212 23.0098C35.5929 22.6766 37.0258 22.5 38.5 22.5H39.1224L34.5612 17.9388C34.1463 17.5239 34.1463 16.8511 34.5612 16.4362C34.9761 16.0213 35.6489 16.0213 36.0638 16.4362L42.4388 22.8112C42.8537 23.2261 42.8537 23.8989 42.4388 24.3138L36.0638 30.6888C35.6489 31.1037 34.9761 31.1037 34.5612 30.6888C34.1463 30.2739 34.1463 29.6011 34.5612 29.1862L39.1224 24.625H38.5C29.698 24.625 22.5625 31.7605 22.5625 40.5625C22.5625 41.1493 22.0868 41.625 21.5 41.625C20.9132 41.625 20.4375 41.1493 20.4375 40.5625C20.4375 31.7605 13.302 24.625 4.5 24.625H3.8776L8.4388 29.1862C8.85373 29.6011 8.85373 30.2739 8.4388 30.6888C8.02387 31.1037 7.35113 31.1037 6.9362 30.6888L0.561199 24.3138C0.146267 23.8989 0.146267 23.2261 0.561199 22.8112L6.9362 16.4362C7.35113 16.0213 8.02387 16.0213 8.4388 16.4362C8.85373 16.8511 8.85373 17.5239 8.4388 17.9388L3.8776 22.5H4.5C5.97418 22.5 7.40711 22.6766 8.77879 23.0098C8.93161 21.5478 9.68591 20.2043 10.876 19.3118L16.1968 15.3306C14.2482 13.7731 13 11.3761 13 8.6875C13 3.99308 16.8056 0.1875 21.5 0.1875C26.1944 0.1875 30 3.99308 30 8.6875C30 11.381 28.7472 13.7819 26.7924 15.3392ZM24.8158 16.5165C23.797 16.9485 22.6764 17.1875 21.5 17.1875C20.3174 17.1875 19.1913 16.946 18.1681 16.5096L12.15 21.0125C11.3474 21.6145 10.875 22.5592 10.875 23.5625C10.875 23.5939 10.8736 23.625 10.871 23.6557C15.8056 25.5161 19.7113 29.475 21.5 34.4444C23.2887 29.475 27.1944 25.5161 32.129 23.6557C32.1264 23.625 32.125 23.5939 32.125 23.5625C32.125 22.5592 31.6526 21.6145 30.8527 21.0145L24.8158 16.5165ZM21.5 15.0625C25.0208 15.0625 27.875 12.2083 27.875 8.6875C27.875 5.16668 25.0208 2.3125 21.5 2.3125C17.9792 2.3125 15.125 5.16668 15.125 8.6875C15.125 12.2083 17.9792 15.0625 21.5 15.0625Z"
                        fill="black"
                      />
                    </svg>
                    <span>
                      {t("header.menu.rights_management")} :
                    </span>
                    {t("backoffice.rights_management.users.title")}
                  </h1>

                  <div className="sub-menu">
                    <BoRightsManagementMenu activeMenu="users-access"></BoRightsManagementMenu>
                  </div>
                  <hr />
                  <div className="actions side-menu-actions">
                    <div className="sorting w-auto">
                      <button
                        onClick={() => {
                          this.exportUsers();
                        }}
                        className="btn btn-sorting"
                      >
                        <svg width="24" height="24" viewBox="0 0 112 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M58.8333 66.3752L71.3543 53.8543C72.4933 52.7152 74.34 52.7152 75.4791 53.8543C76.6181 54.9933 76.6181 56.84 75.4791 57.9791L57.9791 75.4791C56.84 76.6181 54.9933 76.6181 53.8543 75.4791L36.3543 57.9791C35.2152 56.84 35.2152 54.9933 36.3543 53.8543C37.4933 52.7152 39.34 52.7152 40.4791 53.8543L53 66.3752V3.41667C53 1.80584 54.3058 0.5 55.9167 0.5C57.5275 0.5 58.8333 1.80584 58.8333 3.41667V66.3752ZM90.9167 18C89.3058 18 88 16.6942 88 15.0833C88 13.4725 89.3058 12.1667 90.9167 12.1667H96.75C104.804 12.1667 111.333 18.6958 111.333 26.75V85.0833C111.333 93.1375 104.804 99.6667 96.75 99.6667H15.0833C7.02918 99.6667 0.5 93.1375 0.5 85.0833V26.75C0.5 18.6958 7.02918 12.1667 15.0833 12.1667H20.9167C22.5275 12.1667 23.8333 13.4725 23.8333 15.0833C23.8333 16.6942 22.5275 18 20.9167 18H15.0833C10.2508 18 6.33333 21.9175 6.33333 26.75V85.0833C6.33333 89.9158 10.2508 93.8333 15.0833 93.8333H96.75C101.583 93.8333 105.5 89.9158 105.5 85.0833V26.75C105.5 21.9175 101.583 18 96.75 18H90.9167Z" 
                          fill="#FF8A00"/>
                        </svg>

                      </button>
                    </div>
                    <div className="sorting">
                      <button
                        onClick={() => {
                          this.sortusers("firstName", "ASC");
                        }}
                        className="btn btn-sorting"
                      >
                        <svg
                          width="24"
                          height="21"
                          viewBox="0 0 24 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.64706 3.08294V20.2112C5.64706 20.554 5.33102 20.8318 4.94118 20.8318C4.55133 20.8318 4.23529 20.554 4.23529 20.2112V3.08294L1.20502 5.74683C0.929352 5.98916 0.482412 5.98916 0.206748 5.74683C-0.0689161 5.5045 -0.0689161 5.11159 0.206748 4.86926L4.44204 1.14605C4.71771 0.903711 5.16465 0.903711 5.44031 1.14605L9.67561 4.86926C9.95127 5.11159 9.95127 5.5045 9.67561 5.74683C9.39994 5.98916 8.953 5.98916 8.67734 5.74683L5.64706 3.08294ZM12 3.44644C11.6102 3.44644 11.2941 3.16861 11.2941 2.8259C11.2941 2.48319 11.6102 2.20537 12 2.20537H23.2941C23.684 2.20537 24 2.48319 24 2.8259C24 3.16861 23.684 3.44644 23.2941 3.44644H12ZM12 8.41072C11.6102 8.41072 11.2941 8.1329 11.2941 7.79019C11.2941 7.44747 11.6102 7.16965 12 7.16965H20.4706C20.8604 7.16965 21.1765 7.44747 21.1765 7.79019C21.1765 8.1329 20.8604 8.41072 20.4706 8.41072H12ZM12 13.375C11.6102 13.375 11.2941 13.0972 11.2941 12.7545C11.2941 12.4118 11.6102 12.1339 12 12.1339H17.6471C18.0369 12.1339 18.3529 12.4118 18.3529 12.7545C18.3529 13.0972 18.0369 13.375 17.6471 13.375H12ZM12 18.3393C11.6102 18.3393 11.2941 18.0615 11.2941 17.7188C11.2941 17.376 11.6102 17.0982 12 17.0982H14.8235C15.2134 17.0982 15.5294 17.376 15.5294 17.7188C15.5294 18.0615 15.2134 18.3393 14.8235 18.3393H12Z"
                            fill="#5367F4"
                          />
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          this.sortusers("firstName", "DESC");
                        }}
                        className="btn btn-sorting"
                      >
                        <svg
                          width="24"
                          height="22"
                          viewBox="0 0 24 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.84923 20.9721L4.82229 20.9994L4.79982 21.0304C4.76761 21.0749 4.71905 21.1 4.66667 21.1C4.61428 21.1 4.56573 21.0749 4.53352 21.0304L4.51105 20.9994L4.48411 20.9721L0.550913 16.9918C0.483029 16.9231 0.483029 16.8093 0.550914 16.7406C0.61553 16.6752 0.717803 16.6752 0.78242 16.7406L3.64435 19.6368L4.5 20.5027V19.2854V0.674649C4.5 0.572566 4.58021 0.5 4.66667 0.5C4.75312 0.5 4.83333 0.572566 4.83333 0.674649V19.2854V20.5027L5.68898 19.6368L8.55091 16.7406C8.61553 16.6752 8.7178 16.6752 8.78242 16.7406C8.85031 16.8093 8.85031 16.9231 8.78242 16.9918L4.84923 20.9721ZM12.6667 2.19859C12.5802 2.19859 12.5 2.12603 12.5 2.02395C12.5 1.92186 12.5802 1.8493 12.6667 1.8493H23.3333C23.4198 1.8493 23.5 1.92186 23.5 2.02395C23.5 2.12603 23.4198 2.19859 23.3333 2.19859H12.6667ZM12.6667 7.59578C12.5802 7.59578 12.5 7.52322 12.5 7.42114C12.5 7.31905 12.5802 7.24649 12.6667 7.24649H20.6667C20.7531 7.24649 20.8333 7.31905 20.8333 7.42114C20.8333 7.52322 20.7531 7.59578 20.6667 7.59578H12.6667ZM12.6667 12.993C12.5802 12.993 12.5 12.9204 12.5 12.8183C12.5 12.7162 12.5802 12.6437 12.6667 12.6437H18C18.0865 12.6437 18.1667 12.7162 18.1667 12.8183C18.1667 12.9204 18.0865 12.993 18 12.993H12.6667ZM12.6667 18.3902C12.5802 18.3902 12.5 18.3176 12.5 18.2155C12.5 18.1134 12.5802 18.0409 12.6667 18.0409H15.3333C15.4198 18.0409 15.5 18.1134 15.5 18.2155C15.5 18.3176 15.4198 18.3902 15.3333 18.3902H12.6667Z"
                            fill="black"
                            stroke="#5367F4"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="search-input">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.7488 12.7482L19.0001 19"
                          stroke="#5367F4"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.88235 14.7647C11.6834 14.7647 14.7647 11.6834 14.7647 7.88235C14.7647 4.08133 11.6834 1 7.88235 1C4.08133 1 1 4.08133 1 7.88235C1 11.6834 4.08133 14.7647 7.88235 14.7647Z"
                          stroke="#5367F4"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <input
                        id="search-users-input"
                        type="text"
                        placeholder=""
                        onKeyUp={(e) => {
                          this.searchusers(e);
                        }}
                      />
                    </div>
                    <button
                      onClick={() => {
                        this.newuser();
                      }}
                      className="btn-icon btn-pill btn btn-danger"
                    >
                      <svg
                        className="inner"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 7H1M7 1V13V1Z"
                          stroke="#FFF"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <svg
                        className="outer"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="36"
                          height="36"
                          rx="18"
                          fill="#5367F4"
                        ></rect>
                      </svg>
                      {t('common.add')}
                    </button>
                  </div>
                  <div
                    className={`scrolling-verticaly ${
                      this.state.showFormAdduser ? "showFormAddRecord" : ""
                    }`}
                    onScroll={(e) => {
                      this.onScrolling(e);
                    }}
                  >
                    {this.state.showFormAdduser && (
                      <div className="new-user add-record">
                        {!this.state.selectedUser.firstName &&
                          !this.state.selectedUser.lastName && (
                            <h2>{t("backoffice.rights_management.users.newUser")}</h2>
                          )}
                        {(this.state.selectedUser.firstName ||
                          this.state.selectedUser.lastName) && (
                          <h2>
                            {this.state.selectedUser.firstName || ""}{" "}
                            {this.state.selectedUser.lastName || ""}
                          </h2>
                        )}
                        <div>
                          <button
                            onClick={() => {
                              if(this.state.formUser.some(x=>x.value !== null && x.value !== undefined && x.value !== "" && x.value.length>0))
                                this.setState({...this.state, showResumeModal: true});
                              else{
                                document.getElementById("form-user").reset();
                                this.setState({
                                  ...this,
                                  isNewUser: false,
                                  selectedUserIndex: null,
                                  selectedUser: {},
                                });
                              }
                            }}
                            className="btn-pill btn btn-cancel"
                          >
                            {t('common.cancel')}
                          </button>
                          <button
                            onClick={() => {
                              if(this.state.actionData === "edit"){
                                this.setState({
                                  ...this,
                                  isNewUser: false,
                                  showResumeModal: true
                                });
                              }else{
                                this.addNewuser();
                              }
                            }}
                            className="btn-pill btn btn-danger btn-save"
                            disabled={!this.state.editMode || !this.state.selectedUser?.firstName || !this.state.selectedUser?.lastName || !this.state.selectedUser?.email}
                          >
                            {t('common.save')}
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="records users">
                      {this.state.users.map((user, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              this.viewuserInfo(user, index);
                            }}
                            className={`side-menu-style record user mb-0 ${
                              index == this.state.selectedUserIndex &&
                              !this.state.isNewUser
                                ? "active"
                                : ""
                            }`}
                          >
                            <h3 className={"title-stage-one " + (user && user.rgpd ? "rgpd_archived":"")}>
                              {user.code ? user.code: (user.firstName + " " + user.lastName)}
                            </h3>
                            <p className="title-stage-two">{user && user.rgpd ? "Utilisateur archivé" : moment(user.createdAt).lang(lang).format("LL")}</p>
                          </div>
                        );
                      })}
                      {this.state.users.length == 0 && (
                        <div className="no-resut-found">
                          {t('common.no_result_found')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-sm-7 viewer">
                  {!this.state.selectedUser.firstName &&
                    !this.state.selectedUser.lastName && (
                      <h1>{t("backoffice.rights_management.users.newUser")}</h1>
                    )}
                  {(this.state.selectedUser.firstName ||
                    this.state.selectedUser.lastName) && (
                    <h1>
                      {this.state.selectedUser.firstName || ""}{" "}
                      {this.state.selectedUser.lastName || ""}
                    </h1>
                  )}
                  <div className={this.state.isNewUser || this.state.selectedUserIndex == null ? "hidden" : ""}>
                    <div className="formHead">
                      <div className="row">
                        <div className="col-sm-4 createdAt">

                        </div>
                        <div className="col-sm-4 exportBtns">
                          <button
                            onClick={() => {
                              this.exportUser();
                            }}
                            className="btn btm-light"
                          >
                            <i className="lnr-download"></i>
                          </button>
                          <button className="btn btm-light">
                            <i className="lnr-printer"></i>
                          </button>
                        </div>
                        <div className="col-sm-4 navigateBtns">
                          <button
                            onClick={() => {
                              this.viewPrevuser();
                            }}
                            className="btn btm-light"
                          >
                            <i className="fa fa-angle-up"></i>
                          </button>
                          <span>
                            {parseInt(this.state.selectedUserIndex) + 1}/
                            {this.state.users.length}
                          </span>
                          <button
                            onClick={() => {
                              this.viewNextuser();
                            }}
                            className="btn btm-light"
                          >
                            <i className="fa fa-angle-down"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <FormWithConstraints
                    ref={(formWithConstraints) =>
                      (this.form = formWithConstraints)
                    }
                    id="form-user"
                    className={!this.state.editMode ? "consultation" : ""}
                    noValidate
                  >
                    <div
                      className="hideFormInputs"
                      onClick={this.editModeReminder}
                    ></div>
                    {!this.state.isNewUser && this.state.editMode && this.state.selectedUserIndex !== null && (
                      <div className="row">
                        <div
                          className="col-lg-12"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            onClick={() => {
                              this.deleteUser();
                            }}
                            type="button"
                            className="btn btn-default deleteUser"
                          >
                            <svg
                              width="48"
                              height="48"
                              viewBox="0 0 48 48"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M32.9292 36.7065L33.2828 36.3529L32.9292 35.9994L28.259 31.3292C28.1097 31.1799 28.1097 30.9378 28.259 30.7885C28.4084 30.6391 28.6505 30.6391 28.7998 30.7885L33.47 35.4587L33.8235 35.8122L34.1771 35.4587L38.8473 30.7885C38.9966 30.6391 39.2387 30.6391 39.388 30.7885C39.5373 30.9378 39.5373 31.1799 39.388 31.3292L34.7178 35.9994L34.3643 36.3529L34.7178 36.7065L39.388 41.3767C39.5373 41.526 39.5373 41.7681 39.388 41.9174C39.2387 42.0667 38.9966 42.0667 38.8473 41.9174L34.1771 37.2472L33.8235 36.8937L33.47 37.2472L28.7998 41.9174C28.6505 42.0667 28.4084 42.0667 28.259 41.9174C28.1097 41.7681 28.1097 41.526 28.259 41.3767L32.9292 36.7065ZM27.9187 25.4199L27.7174 25.3339L27.5176 25.4234C26.4802 25.8883 25.3298 26.1471 24.1176 26.1471C22.9079 26.1471 21.7598 25.8893 20.724 25.4262L20.5182 25.3342L20.313 25.4275L13.1095 28.7018C11.986 29.2124 11.2647 30.3327 11.2647 31.5668V32.8235C11.2647 34.5616 12.6737 35.9706 14.4118 35.9706H23.2353C23.4465 35.9706 23.6176 36.1418 23.6176 36.3529C23.6176 36.5641 23.4465 36.7353 23.2353 36.7353H14.4118C12.2514 36.7353 10.5 34.9839 10.5 32.8235V31.5668C10.5 30.0328 11.3966 28.6404 12.7931 28.0056L18.8931 25.2329L19.6436 24.8918L18.9942 24.3839C17.0453 22.8596 15.7941 20.4878 15.7941 17.8235C15.7941 13.2266 19.5207 9.5 24.1176 9.5C28.7146 9.5 32.4412 13.2266 32.4412 17.8235C32.4412 20.475 31.2019 22.8368 29.2691 24.3619L28.6084 24.8832L29.3822 25.2141L33.9739 27.1779C34.168 27.2609 34.2581 27.4856 34.1751 27.6798C34.092 27.8739 33.8673 27.964 33.6732 27.881L27.9187 25.4199ZM24.1176 25.3824C28.2923 25.3824 31.6765 21.9982 31.6765 17.8235C31.6765 13.6489 28.2923 10.2647 24.1176 10.2647C19.943 10.2647 16.5588 13.6489 16.5588 17.8235C16.5588 21.9982 19.943 25.3824 24.1176 25.3824Z"
                                fill="black"
                                stroke="#EB3A59"
                              />
                              <rect
                                opacity="0.05"
                                width="48"
                                height="48"
                                rx="24"
                                fill="#292C41"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-lg-12">
                        <label>{t("backoffice.rights_management.users.userLastName")}*</label>
                        <input
                          value={this.state.selectedUser.lastName}
                          onChange={(e) => {
                            this.setFormUser(e, this.state.actionData)
                            this.handleChangeInput(e);
                          }}
                          type="text"
                          name="lastName"
                          placeholder={t("backoffice.rights_management.users.userLastName")}
                          className="form-control"
                          required
                        />
                        <FieldFeedbacks for="lastName">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <label>{t("backoffice.rights_management.users.userFirstName")}*</label>
                        <input
                          value={this.state.selectedUser.firstName}
                          onChange={(e) => {
                            this.setFormUser(e, this.state.actionData)
                            this.handleChangeInput(e);
                          }}
                          type="text"
                          name="firstName"
                          placeholder={t("backoffice.rights_management.users.userFirstName")}
                          className="form-control"
                          required
                        />
                        <FieldFeedbacks for="firstName">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <label>{t("backoffice.rights_management.users.limitDate")}</label>

                        <br/>
                        <DatePicker
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            autoComplete="off"
                            selected={
                              this.state.selectedUser?.limitDate
                                  ? Moment(
                                  this.state.selectedUser.limitDate,
                                  "DD/MM/YYYY"
                                  ).toDate()
                                  : ""
                            }
                            name="limitDate"
                            placeholder={t("backoffice.rights_management.users.userFirstName")}
                            onChange={(e) => {
                              this.setFormUser({target:{name:"limitDate", value:Moment(e).format("DD/MM/YYYY")}}, this.state.actionData);
                              this.handleLimitDate("limitDate", e);
                            }}
                        />
                        <FieldFeedbacks for="limitDate">
                          <FieldFeedback when="*" />
                        </FieldFeedbacks>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <label>{t("backoffice.rights_management.users.userEmail")}*</label>
                        <div className="row">
                          <div className="col-sm-10">
                            <input
                              value={this.state.selectedUser.email}
                              onChange={(e) => {
                                this.setFormUser(e, this.state.actionData)
                                this.handleChangeInput(e);
                              }}
                              onKeyUp={(e) => {
                                this.setFormUser(e, this.state.actionData)
                                this.handleChangeInput(e);
                              }}
                              onClick={(e) => {
                                this.setFormUser(e, this.state.actionData)
                                this.handleChangeInput(e);
                              }}
                              type="text"
                              name="email"
                              placeholder={t("backoffice.rights_management.users.userEmail")}
                              className="form-control"
                              required
                            />
                            <FieldFeedbacks for="email">
                              <FieldFeedback when="*" />
                            </FieldFeedbacks>

                            {this.state.selectedUser.emails &&
                              this.state.selectedUser.emails.map(
                                (email, index) => {
                                  return (
                                    <input
                                      value={email}
                                      onChange={(e) => {
                                        let emails = this.state.selectedUser.emails;
                                        emails[index] = e.target.value; 
                                        this.setFormUser({target:{name:"emails", value:emails}}, this.state.actionData)
                                        this.handleChangeEmails(e, index);
                                      }}
                                      type="text"
                                      name={`emails[${index}]`}
                                      placeholder={t("backoffice.rights_management.users.otherUserEmail")}
                                      className="form-control"
                                    />
                                  );
                                }
                              )}
                          </div>
                          <div className="col-sm-2">
                            <button
                              type="button"
                              onClick={(e) => {
                                this.addEmailField(e);
                              }}
                              className="btn btn-primary btn-pill"
                              style={{ height: 36, marginTop: 4 }}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <label>{t("backoffice.rights_management.organizations.title")}*</label>
                        <div className="multi-select-checkboxes">
                          <ReactMultiSelectCheckboxes
                            resetInputOnSelect={true}
                            placeholderButtonLabel={t("common.select")}
                            tabSelectsValue="true"
                            placeholder={t("common.search")}
                            onChange={(list) => {
                              this.setFormUser({target:{name:"organisations", value:list}}, this.state.actionData)
                              this.changeOrganisations(list);
                            }}
                            className="organisation-options"
                            value={
                              this.state.selectedUser.selectedOrganisations &&  this.state.selectedUser.selectedOrganisations.length>0 ? this.state.selectedUser.selectedOrganisations : []
                            }
                            options={this.state.organisationOptions}
                            required
                          />
                          {this.state.organisationInvalid && this.state.selectedUser && this.state.selectedUser !== undefined && this.state.selectedUser["selectedOrganisations"] !== undefined && this.state.selectedUser["selectedOrganisations"].length === 0 && <span data-feedback="7.1" style={{display:"table-cell"}} className="error">Veuillez renseigner ce champ.</span> }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 selectedOrganisations">
                        {this.state.selectedUser.selectedOrganisations &&
                          this.state.selectedUser.selectedOrganisations.map(
                            (organisation, index) => {
                              return (
                                <button
                                  onClick={() => {
                                    this.unselectOrganisation(index);
                                  }}
                                  type="button"
                                  className={`mb-2 mr-2 btn-icon btn-pill btn btn-secondary ${
                                    organisation.unselected ? "unselected" : ""
                                  }`}
                                >
                                  {organisation.label}
                                  <svg
                                    width="52"
                                    height="52"
                                    viewBox="0 0 52 52"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="0.542969"
                                      y="26"
                                      width="36"
                                      height="36"
                                      rx="18"
                                      transform="rotate(-45 0.542969 26)"
                                      fill="#F66031"
                                    />
                                    <path
                                      d="M30.2416 21.7574L21.7563 30.2426M21.7563 21.7574L30.2416 30.2426L21.7563 21.7574Z"
                                      stroke="white"
                                      strokeWidth="2"
                                      strokeMiterlimit="10"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </button>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </FormWithConstraints>
                </div>
              </div>
            </div>
          </div>
          <ResumeDataModal title={"Confirmez vos données saisie"}
            isOpen={this.state.showResumeModal}
            onRequestClose={() => this.handleClose()}
            onRequestCancel={() => this.handleCancel()}
            requiredFields={this.state.actionData === "add" ? this.state.formUser.length : 0}
            doSave={() => this.doSave()}
            fields={this.state.actionData === "add" ? this.state.formUser : this.state.formUser.filter(x => x.edited)}
            action={this.state.actionData}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(UsersAccess);
