import React, { useState } from 'react';
import $ from 'jquery';
import { ExportIcon, SearchIcon, SortIcon } from '../../../assets/icons';
import moment from 'moment';
import ExportModal from './parts/ExportModal';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

const ListingGuestNegociationContract = ({
  data,
  tags,
  handleDoubleClick,
  getContractBadges,
  seachIncontractList,
}) => {
  const [isExportModalOpen, setOpenExportModal] = useState(false);

  const sortByDeadline = () => {
    $('#deadline').click();
  };
  return (
    <>
      <div className="filters">
        <div className="row">
          <div className="col-12 col-sm-12 col-lg-4 search-area-mobile">
            <div className="search-input">
              <SearchIcon />
              <input
                type="text"
                placeholder={t('frontoffice.home.contracts.table.client') + ", " + t('frontoffice.home.contracts.table.contract') + "..."}
                onChange={(e) => seachIncontractList(e, "homeDataTable5")}
              />
            </div>
          </div>
          <div className="col-sm-12 col-lg-4 hidden-only-on-mobile"></div>
          <div className="col-sm-12 col-lg-4 hidden-only-on-mobile">
            
          </div>
        </div>
      </div>
      <table
        width="100%"
        id="homeDataTable5"
        className="table table-hover"
        data-searching="true"
        data-select="false"
        data-paging="false"
        data-info="false"
      >
        <thead>
          <tr>
            {/* <th data-sorting="false"><input type="checkbox" /></th> */}
            <th>{t('frontoffice.home.contracts.table.client')}</th>
            <th>{t('frontoffice.home.contracts.table.contract')}</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((nego, index) => {
            let contractBadges = getContractBadges(nego);
            return (
              <tr contractId={nego.id} onDoubleClick={() => handleDoubleClick(nego.contractId, nego.avt)}>
                {/* <td><input type="checkbox" /></td> */}
                <td>{nego.client}</td>
                <td>{nego.contract}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* <ExportModal isOpen={isExportModalOpen} onRequestClose={() => setOpenExportModal(false)} /> */}
    </>
  );
};

export default withTranslation()(ListingGuestNegociationContract);
