import React from "react";
const ButtonArrowsUpDown = ({ onPressUp, onPressDown, disabled}) => (
  <div className="button-arrows-updown">
    <button
      type="button"
      className={`btn btn-default button_radio_custom`}
      disabled={disabled}
      onClick={onPressUp}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8384_24254)">
          <rect opacity="0.05" width="24" height="24" rx="12" fill="#292C41" />
          <path d="M14.5 13.625L12.2511 11L10 13.625" stroke="#7077A5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_8384_24254">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>

    <button
      type="button"
      className={`btn btn-default button_radio_custom`}
      disabled={disabled}
      onClick={onPressDown}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8384_24617)">
          <rect opacity="0.05" width="24" height="24" rx="12" fill="#292C41" />
          <path d="M9.75 10.875L11.9989 13.5L14.25 10.875" stroke="#7077A5" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_8384_24617">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  </div>
);

export default ButtonArrowsUpDown;
