import React from "react";
const NegoIcon = ({isInproposition=false, isInAcceptedproposition=false, isInRefusedproposition=false}) => (
  <svg
    width="34"
    height="34"
    viewBox="0 -5 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.05" width="34" height="34" rx="18" />
    <path
      d="M6.5 2V2.75C6.5 4.82107 4.82107 6.5 2.75 6.5H2V15.5H2.81207C4.88314 15.5 6.56207 17.1789 6.56207 19.25V20H27.5V19.25C27.5 17.1789 29.1789 15.5 31.25 15.5H32V6.5H31.25C29.1789 6.5 27.5 4.82107 27.5 2.75V2H6.5ZM5 2H4.25C3.00736 2 2 3.00736 2 4.25V5H2.75C3.99264 5 5 3.99264 5 2.75V2ZM5.06207 20V19.25C5.06207 18.0074 4.05471 17 2.81207 17H2V17.75C2 18.9926 3.00736 20 4.25 20H5.06207ZM6.08773 21.5C6.0024 21.5337 5.9094 21.5523 5.81207 21.5523C5.71474 21.5523 5.62174 21.5337 5.53641 21.5H4.25C2.17893 21.5 0.5 19.8211 0.5 17.75V4.25C0.5 2.17893 2.17893 0.5 4.25 0.5H29.75C31.8211 0.5 33.5 2.17893 33.5 4.25V17.75C33.5 19.8211 31.8211 21.5 29.75 21.5H6.08773ZM29 20H29.75C30.9926 20 32 18.9926 32 17.75V17H31.25C30.0074 17 29 18.0074 29 19.25V20ZM29 2V2.75C29 3.99264 30.0074 5 31.25 5H32V4.25C32 3.00736 30.9926 2 29.75 2H29ZM17 17C13.6863 17 11 14.3137 11 11C11 7.68629 13.6863 5 17 5C20.3137 5 23 7.68629 23 11C23 14.3137 20.3137 17 17 17ZM17 15.5C19.4853 15.5 21.5 13.4853 21.5 11C21.5 8.51472 19.4853 6.5 17 6.5C14.5147 6.5 12.5 8.51472 12.5 11C12.5 13.4853 14.5147 15.5 17 15.5Z"
      stroke={`${isInproposition ? "#F66031": (isInAcceptedproposition ? "#5CC973":(isInRefusedproposition ? "#EB3A59":"#BDBDBD"))}`}
      strokeWidth="1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NegoIcon;
