import { t } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import OwlCarousel from 'react-owl-carousel2';
import owlCaroucelOptions from './options.json';

const RecentContractsInNegociation = ({
  data,
  getCardClassName,
  handleDoubleClick,
  setFavoriteContrat,
  displayRecent,
  toggleRecent
}) => (
  <>
    {data?.length > 0 && (
      <>
        <h2 className="tab-title">
          {t('frontoffice.home.contracts.recent.title')} 
          <a style={{cursor:'pointer'}} onClick={()=>toggleRecent()}>
            {displayRecent && <i className="fa fa-angle-up ml-2"></i>}
            {!displayRecent && <i className="fa fa-angle-down ml-2"></i>}
          </a>
        </h2>
        {displayRecent &&
        <OwlCarousel options={owlCaroucelOptions}>
          {data?.sort((a,b) => b.favorite - a.favorite).map((contrat, index) => {
            return (
              <div
                key={contrat.id}
                onDoubleClick={() => {
                  handleDoubleClick(contrat.hachedId);
                }}
              >
                <div
                  className={`slider-item text-left mb-5 card-left-border card ${getCardClassName(
                    contrat
                  )}`}
                >
                  <div className="widget-chat-wrapper-outer">
                    <div className="widget-chart-content">
                      <div className="widget-title text-uppercase">
                        {contrat.num || '---'}
                        <button
                          onClick={() => {
                            setFavoriteContrat(contrat, index, 'recentContractsInNegociation');
                          }}
                          class={`btn btn-icon btn-icon-only ${contrat.favorite ? 'favorite' : ''}`}
                        >
                          <i className="lnr-star"></i>
                          <i className="fa fa-star active"></i>
                        </button>
                      </div>
                      <small>{contrat.category}</small>
                      <table>
                        <tbody>
                          <tr>
                            <th>{t('frontoffice.home.contracts.recent.negotiation_date')}</th>
                            <td>{contrat.date_negociation}</td>
                          </tr>
                          <tr>
                            <th>{t('frontoffice.home.contracts.recent.notice_period')}</th>
                            <td>{contrat.date_preavi}</td>
                          </tr>
                          <tr>
                            <th>{t('frontoffice.home.contracts.recent.end_date')}</th>
                            <td>
                              <span className="badge">{contrat.date_fin}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </OwlCarousel>}
      </>
    )}
  </>
);

export default withTranslation()(RecentContractsInNegociation);
