import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"

export const getCategories = () => {
    const requestUrl = API_URL+`/categoriesListWithSubcategories/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl);
  };

export const createCategory = (categoryForm) => {
  console.log("categoryForm", categoryForm)
    const requestUrl = API_URL+`/category?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    if(categoryForm.id) {
        formData.append('id', categoryForm.id);
    }
    formData.append('name', categoryForm.name);
    if (categoryForm.designation) {
        formData.append('designation', categoryForm.designation);
    }

    if(categoryForm.negociationTime) {
      formData.append('negociationTime', categoryForm.negociationTime);
    }
    if(categoryForm.negociationTimeUnit) {
      formData.append('negociationTimeUnit', categoryForm.negociationTimeUnit);
    }

    if(categoryForm.archiveType) {
      formData.append('archiveType', categoryForm.archiveType);
    }
    if(categoryForm.archiveDuration) {
      formData.append('archiveDuration', categoryForm.archiveDuration);
    }

    if(categoryForm.accountingAccount) {
      formData.append('accountingAccount', categoryForm.accountingAccount);
    }

    formData.append('client', getCurrentClientID());
    return axios.post(requestUrl, formData);
}

export const deleteCategory = (categoryID) => {
  const requestUrl = API_URL+`/category/`+categoryID+`?access_token=`+getConnectedUserAccessToken();
  return axios.delete(requestUrl);
};

export const updateSubCategory = (subCategoryForm) => {
  const requestUrl = API_URL+`/subCategory?access_token=`+getConnectedUserAccessToken();
  var formData = new FormData();
  if(subCategoryForm.id) {
      formData.append('id', subCategoryForm.id);
  }
  formData.append('name', subCategoryForm.name);
  if (subCategoryForm.designation) {
      formData.append('designation', subCategoryForm.designation);
  }
  formData.append('category', subCategoryForm.category);

  if(subCategoryForm.negociationTime) {
    formData.append('negociationTime', subCategoryForm.negociationTime);
  }
  if(subCategoryForm.negociationTimeUnit) {
    formData.append('negociationTimeUnit', subCategoryForm.negociationTimeUnit);
  }

  if(subCategoryForm.accountingAccount) {
    formData.append('accountingAccount', subCategoryForm.accountingAccount);
  }

  formData.append('client', getCurrentClientID());
  return axios.post(requestUrl, formData);
}

export const deleteSubCategory = (subCategoryID) => {
  const requestUrl = API_URL+`/subCategory/`+subCategoryID+`?access_token=`+getConnectedUserAccessToken();
  return axios.delete(requestUrl);
};
