import React, { useEffect, useState } from "react";
import { calculateAmount, getFields } from "../../../../../../../api/dashboard";
import ChartPreview from "../../../components/charts";

//icons
import arrowIcon from "../../../icons/arrow.svg";
import chartPlaceholder from "../../../icons/chart-placeholder.png";

import { t } from 'i18next';
//API
import {
  addTDBToPage,
  editTDBPage,
  deleteTDB
} from '../../../../../../../api/dashboard';
import LoaderComponent from '../../../../../../../components/loader/Loader';
import NotifyAlert from "../../../../../../../components/confirm-alert/notify-alert/NotifyAlert";

const Screen = ({ item, active, onSelect, children, selectedScreen, activePage, selectedDash, toast, loadPages, isToogleActive }) => {
  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);
  const [currentScreen, setCurrentScreen] = useState({});
  const [calculatedData, setCalculatedData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [fields, setFields] = useState([]);

  const getScreenWidth = () => `calc(${33.33 * width}% - 20px)`;
  const getScreenHeight = () => 255 * height + (height - 1) * 20;
  useEffect(() => {
    if(item && isToogleActive && isToogleActive.dashboard == item.id){
      const tmpData = {
        idTDB: selectedDash?.id ? selectedDash?.id :isToogleActive?.dashboard,
        idPage: activePage['page'].id,
        position: selectedScreen,
        height: height,
        width: width
      }
      loadFields();
      setCurrentScreen(tmpData) 
      savePage(tmpData)
      getCalculatedData();
    }else if (item && item.id && !(selectedDash !== null && selectedDash !== undefined && item && item.id == selectedDash.id)){
      let find = activePage['detail'].find(x=>x.dashboard === item?.id) 
        if(find){
          if(!item.width || !item.height){
            setWidth(find.width)
            setHeight(find.height)
          }
          getCalculatedData();
        }
    }else if(item && item.width && item.height){
      setWidth(item.width)
      setHeight(item.height)
    }else if(!item || (!item.width && !item.height)){
      setWidth(1)
      setHeight(1)
    }

  }, [width,height,selectedScreen,activePage,selectedDash,isToogleActive])

  const loadFields = async () => {
    const _fields = await getFields();
    setFields(_fields);
  };

  const savePage = async (tmpData = null) => {

    let find = (activePage['detail'] && activePage['detail'].length>0)? activePage['detail'].find(x => x.dashboard === item?.id):null
    if (!find) {

      if (tmpData !== null) {
        setCurrentScreen(tmpData)
      }

      if ((!currentScreen || currentScreen?.idTDB == undefined) && (tmpData == null))
        return;

      LoaderComponent.show();
      try {
        if (currentScreen && currentScreen?.idTDB !== undefined){
          tmpData = currentScreen
        }
        if (tmpData && tmpData?.idTDB !== undefined){
          addTDBToPage(tmpData.idTDB, tmpData.idPage, tmpData.position, tmpData.height, tmpData.width).then(data=>{
            if(!activePage['detail'])
              activePage['detail'] = [];
            activePage['detail'].push({id: data.id, position: tmpData.position, height: tmpData.height, width: tmpData.width, dashboard: tmpData.idTDB})
          });
          
        }
        LoaderComponent.hide();
        toast.success(t('Tableau de board rajouté avec succès'), {
          position: toast.POSITION.TOP_RIGHT
        });
        setCurrentScreen({});
      } catch (error) {
        // console.log('error', error);
        LoaderComponent.hide();
        toast.error(t('Une erreur est survenue, tableau de board non ajouté'), {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  };

  const saveEditedPage = async (width, height) => {
  
    try {
      let find = activePage['detail'].find(x=>x.dashboard === item?.id) 
      if(find){
        LoaderComponent.show();
        item.width = width
        item.height = height
        editTDBPage(find.id,item.id, activePage.page.id, find.position, height, width);
        LoaderComponent.hide();
        toast.success(t('Tableau de board modifié avec succès'), {
          position: toast.POSITION.TOP_RIGHT
        });
        setCurrentScreen({});
        loadPages()
      }
    } catch (error) {
      // console.log('error', error);
      LoaderComponent.hide();
      toast.error(t('Une erreur est survenue, tableau de board non ajouté'), {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const getCalculatedData = async () => {
    setCalculatedData(null);

    if (!item) return;
    if (!item.fromDate) return;
    if (!item.toDate) return;
    if (item?.cryptedData?.length === 0) return;
    // if (!item.scale) return;

    setLoadingData(true);
    try {
      const result = await calculateAmount({
        startDate: item.fromDate,
        endDate: item.toDate,
        filters: filters,
        cryptedData: item.cryptedData,
        scale: item.scale && item.scale['name'] !== null && item.scale['name'] !== undefined && item.scale['name'] !== "" ? item.scale : null,
      });
      
      setCalculatedData(result);
      
        //// pre selectec cryptedData
      let __cryptedData = [];
      item['cryptedData'].forEach((element) => {
        console.log(element)
        let values = element.data.map((v) => v.name);
        let field = fields.find((f) => f.name === element.name);
        if (field) {
          field = field.set('filter', element.filter)
          field = field.set('group', element.group)
          let newData = [...field.data].map((d) => {
            return values.includes(d.name) ? { ...d, selected: true } : d;
          });
          __cryptedData.push(field.set('data', newData));
        }
      });
      setLoadingData(false);
      setChartData(__cryptedData);
    } catch (error) {
      setLoadingData(false);
    }
  };
  
  // console.log('currentScreen',currentScreen)
  return (
    <div
      className={`graph-screen-item chart-preview-adjusted ${active ? "active-screen" : ""}`}
      style={{
        width: getScreenWidth(),
        height: getScreenHeight(),
        backgroundImage: `url(${chartPlaceholder})`,
      }}
      onClick={onSelect}
    >
      {item && <p className="title-graph">{item.name}</p>}
      <Controls
        width={width}
        height={height}
        onPressTop={() => {
          if (height > 1) {
            setHeight(height - 1);
            saveEditedPage(width,height - 1)
          }
        }}
        onPressRight={() => {
          if (width < 3) {
            setWidth(width + 1);
            saveEditedPage(width + 1,height)
          }
        }}
        onPressBottom={() => {
          if (height < 3) {
            setHeight(height + 1);
            saveEditedPage(width,height + 1)
          }
        }}
        onPressLeft={() => {
          if (width > 1) {
            setWidth(width - 1);
            saveEditedPage(width - 1,height)
          }
        }}
      />
      {item && <ChartPreview 
              type={item.type}
              title={item.name}
              data={calculatedData}
              loadingData={loadingData}
              chartData={item?.cryptedData}
              displayValues={item.displayValues}
              displayLegend={item.displayLegend}
              unity={item.unity} />}
    </div>
  );
};

const Controls = ({
  width,
  height,
  onPressTop,
  onPressRight,
  onPressBottom,
  onPressLeft,
}) => (
  <>
    {height > 1 && (
      <button className="btn-control top" onClick={onPressTop}>
        <img src={arrowIcon} alt="" />
      </button>
    )}
    {width < 3 && (
      <button className="btn-control right" onClick={onPressRight}>
        <img src={arrowIcon} alt="" />
      </button>
    )}
    {height < 3 && (
      <button className="btn-control bottom" onClick={onPressBottom}>
        <img src={arrowIcon} alt="" />
      </button>
    )}
    {width > 1 && (
      <button className="btn-control left" onClick={onPressLeft}>
        <img src={arrowIcon} alt="" />
      </button>
    )}
  </>
);

export default Screen;
