import React, { useState } from 'react';
import './style.scss';
import LoaderComponent from '../../../../../../components/loader/Loader';
import ChartBar from './ChartBar';
import ChartBarHorizontal from './ChartBarHorizontal';
import ChartDonut from './ChartDonut';
import ChartLine from './ChartLine';
import ChartPie3d from './ChartPie3d';
import colorsListFromJson from "./colors.json";
import Gradient from "javascript-color-gradient";
const unities = {
  euro: '€',
  'k-euro': 'K€',
  'm-euro': 'M€',
  percent: '%',
  qty: '',
};

const ChartPreview = ({
  type,
  title,
  data,
  loadingData,
  chartData,
  displayValues,
  displayLegend,
  displayDetailAmount,
  unity,
  colorsList
}) => {

  const getValue = (value, displayUnity) => {
    let _unity = unity ? unities[unity] : '';
    const formatNumber = (number, divider) =>
      number === 0 ? 0 : parseInt((number / divider).toFixed(0));

    const formatToFixedNumber = (number) =>
      number === 0 ? 0 : parseFloat((number).toFixed(2));
      buildColors()

    switch (unity) {
      case 'euro':
        return displayUnity ? `${formatToFixedNumber(value)}${_unity}` : value;
      case 'k-euro':
        return displayUnity ? `${formatNumber(value, 1000)}${_unity}` : formatNumber(value, 1000);
      case 'm-euro':
        return displayUnity
          ? `${formatNumber(value, 1000000)}${_unity}`
          : formatNumber(value, 1000000);
      case 'percent':
        return displayUnity ? `${formatToFixedNumber(value)}${_unity}` : value;
      case 'qty':
        return value;
      default:
        return value;
    }
  };

  const getTotalPeriod = (_data) =>
    _data.reduce((accumulator, object) => {
      return (
        accumulator +
        object.cryptedData.reduce((accumulator, object) => {
          return (
            accumulator +
            object.values.reduce((accumulator, object) => {
              return accumulator + object.amount;
            }, 0)
          );
        }, 0)
      );
    }, 0);

  const getTotalItemPeriod = (periodItem) =>
    periodItem.cryptedData.reduce((accumulator, object) => {
      return (
        accumulator +
        object.values.reduce((accumulator, object) => {
          return accumulator + object.amount;
        }, 0)
      );
    }, 0);

  const isMltipleCryptedData = (_data) => {
    if (_data.length === 0) return false;
    let cryptedData = _data[0].cryptedData.filter((itm) => itm.values.length > 0);
    if (cryptedData.length === 0) return false;
    let values = cryptedData[0].values;

    if (cryptedData.length > 1) return true;
    if (values.length > 1) {
      let field = chartData?.find((itm) => itm.name === cryptedData[0].name);
      if (field?.group) return false;
      else return true;
    }
    return false;
  };

  const calculatePercent = (amount, total) => {
    return parseFloat(((amount * 100) / total).toFixed(2));
  };

  const buildData = () => {
    let _data = [];
    if (Array.isArray(data) && data.length > 0) {
      /// set bars names
      _data.push([
        '',
        ...[].concat(
          [],
          ...data[0].cryptedData
            .filter((itm) => itm.values.length > 0)
            .map((item) => {
              let field = chartData?.find((itm) => itm.name === item.name);
              if (field?.group) return [item.isRGPD ? "RGPD":item.name];
              else return item.values.map((val) => val.isRGPD ? "RGPD" : val.name);
            })
        ),
      ]);

      let toalPeriod = getTotalPeriod(data);
      let multipleCryptedData = isMltipleCryptedData(data);
      console.log('multipleCryptedData', multipleCryptedData);

      data.forEach((dashbordItem) => {
        let toalPeriodItem = getTotalItemPeriod(dashbordItem);
        _data.push([
          dashbordItem.isRGPD ? "RGPD": dashbordItem.scale,
          ...[].concat(
            [],
            ...dashbordItem.cryptedData
              .filter((itm) => itm.values.length > 0)
              .map((item) => {
                let field = chartData?.find((itm) => itm.name === item.name);

                if (field?.group) {
                  if(displayDetailAmount){
                    const sumAmountIsDisputed = item.values.reduce((sum, obj) => sum + obj.contracts.filter(contract => contract.isDisputed === 1).reduce((contractSum, contract) => contractSum + contract.amount, 0), 0);

                    if (unity === 'qty') {
                      const sumAmountIsDisputedForEach = item.values.map(obj => obj.contracts.filter(contract => contract.isDisputed === 1).reduce((sum, contract) => sum + contract.amount, 0));

                      return sumAmountIsDisputedForEach ? sumAmountIsDisputedForEach.length:0
                    }
                    if (unity === 'percent') {
                      if (multipleCryptedData) {
                        return calculatePercent(sumAmountIsDisputed, toalPeriodItem);
                      } else return calculatePercent(sumAmountIsDisputed, toalPeriod);
                    }
                    return sumAmountIsDisputed;
                    
                  }else{
                    if (unity === 'qty') {
                      return [
                        item.values.reduce((accumulator, object) => {
                          return accumulator + object.count;
                        }, 0),
                      ];
                    }
                    if (unity === 'percent') {
                      let amount = item.values.reduce((accumulator, object) => {
                        return accumulator + object.amount;
                      }, 0);
                      if (multipleCryptedData) {
                        return calculatePercent(amount, toalPeriodItem);
                      } else return calculatePercent(amount, toalPeriod);
                    }
                    return [
                      item.values.reduce((accumulator, object) => {
                        return accumulator + object.amount;
                      }, 0),
                    ];

                  }
                } else {
                  if(displayDetailAmount){
                    return item.values.map((val) => {
                      const sumAmountIsDisputed = val["contracts"].filter(transaction => transaction.isDisputed === 1).reduce((sum, transaction) => sum + transaction.amount, 0);
                      if (unity === 'percent') {
                        if (multipleCryptedData) {
                          return calculatePercent(sumAmountIsDisputed, toalPeriodItem);
                        } else return calculatePercent(sumAmountIsDisputed, toalPeriod);
                      }
                      if (unity === 'qty') return val["contracts"] ? val["contracts"].filter(transaction => transaction.isDisputed === 1).length:0;
                      return sumAmountIsDisputed;

                    });
                  }else{
                    return item.values.map((val) => {
                      if (unity === 'percent') {
                        if (multipleCryptedData) {
                          return calculatePercent(val.amount, toalPeriodItem);
                        } else return calculatePercent(val.amount, toalPeriod);
                      }
                      if (unity === 'qty') return val.count;
                      return val.amount;
                    });
                  }
                }
              })
          ),
        ]);
      });
    }

    const formatedData = [];
    _data.forEach((row, index) => {
      row.forEach((col, key) => {
        if (key === 0) {
          formatedData.push([col]);
        } else {
          if (index === 0) {
            formatedData[index].push(col);
            if (displayValues) {
              formatedData[index].push({ role: 'annotation' });
            }
          } else {
            formatedData[index].push(getValue(col, false));
            if (displayValues) {
              formatedData[index].push(getValue(col, true));
            }
          }
        }
      });
    });
    return formatedData;
  };

  const buildColors = () => {
    let colors = [];
    if(colorsList !== undefined){
      if (Array.isArray(chartData) && chartData.length > 0) {
        chartData.forEach((d, index)=>{
            colors.push(getGradienBuilderColor(colorsList.find(x => x.key && x.key == d.key && x.name == d.name) ? colorsList.find(x => x.key && x.key == d.key && x.name == d.name).color:'orangered' || null,d.group ? 1:d.data.filter(x=>x.selected).length))
        })
      }
    }else{
      let _colors = colorsListFromJson[1];
      if (Array.isArray(chartData) && chartData.length > 0) {
        chartData.forEach((d, index)=>{
            colors.push(getGradienBuilderColor(d.color && d.color !== "" ? d.color : _colors[index],d.group ? 1:d.data.filter(x=>x.selected).length))
        })
      }
    }
    return colors || []
  }

  const getGradienBuilderColor = (color,entries_count) => {

    let colorArr = new Gradient()
      .setColorGradient(color, "#FFFFFF")
      .setMidpoint(entries_count)
      .getColors();

    colorArr.unshift(color);
    colorArr.splice(-1)
    return colorArr;

  }

  const getOriginalData = (data) => {
    if (Array.isArray(data) && data.length > 0) {
      return data[0].cryptedData.map(x=>x.values)
    }else{
      return null
    }
  }

  const originalData = getOriginalData(data);

  const [showMore, setShowMore] = useState(false);

  const originalDataToDisplay = displayDetailAmount && originalData !== null && originalData !== undefined && originalData[0] !== undefined && originalData[0] !== null? (showMore ? originalData[0] : originalData[0].slice(0, 4)):[];

  const getProps = () => {
    return {
      title: title,
      data: buildData(),
      displayValues: displayValues,
      displayLegend: displayLegend,
      colors: buildColors()
    };
  };

  const getTotalDisputedAmount = (myMap) => {
    return Array.from(myMap.values())
    .filter(item => item.isDisputed)
    .reduce((total, item) => total + item.amount, 0);
  };

  return (
    <>
    <div className="chart-preview">
      {loadingData ? (
        <Loader />
      ) : (
        <>
          {!!data && (
            <>
              {(() => {
                switch (type) {
                  case 1:
                    return <ChartBar {...getProps()} />;
                  case 2:
                    return <ChartLine {...getProps()} />;
                  case 3:
                    return <ChartBarHorizontal {...getProps()} />;
                  case 4:
                    return <ChartDonut {...getProps()} />;
                  case 5:
                    return <ChartPie3d {...getProps()} />;

                  default:
                    return null;
                }
              })()}
            </>
          )}
        </>
      )}
    </div>
    {
    displayDetailAmount && originalData && buildColors() && buildColors().length>0 && 
    <>
    <p className="title-details-amount">Détails des litiges</p> 
    {/* <div className="row">
      {originalData[0].map((d, index)=>{    
          return(
            <div className="col-6">
              <p className="bloc-details-amount">
                <span className="key-legend" style={{backgroundColor:buildColors()[0][index]}}></span>
                <strong className="name-legend">{d.name}</strong>
                {d.contracts && (d.contracts.length <1 || (d.contracts.length>0 && getTotalDisputedAmount(d.contracts)<=0 ))&&
                  <>
                    <small className='no-disputed-tag'>pas en litige</small>
                  </>
                }
                {d.contracts && d.contracts.length>0 && getTotalDisputedAmount(d.contracts)>0 &&
                  <>
                    <strong>
                      {" " + getTotalDisputedAmount(d.contracts)}
                      {"€ "}
                    </strong>
                    <small className='disputed-tag'>en litige</small>
                  </>
                }
              </p>
            </div>
          )
        })
      }
      
    </div> */}

    <div>
      <div className="row">
        {originalDataToDisplay.map((d, index) => (
          <div className={index % 2 == 0 ? "col-6": "col-6 border-litige"} key={index}>
            <p className="bloc-details-amount">
                <span className="key-legend" style={{backgroundColor:buildColors()[0][index]}}></span>
                <strong className="name-legend">{d.name}</strong>
                {d.contracts && (d.contracts.length <1 || (d.contracts.length>0 && getTotalDisputedAmount(d.contracts)<=0 ))&&
                  <>
                    <small className='no-disputed-tag'>en litige</small>
                  </>
                }
                {d.contracts && d.contracts.length>0 && getTotalDisputedAmount(d.contracts)>0 &&
                  <>
                    <strong style={{color:"#EB3A59"}}>
                      {" " + getTotalDisputedAmount(d.contracts)}
                      {"€ "}
                    </strong>
                    <small className='disputed-tag'>en litige</small>
                  </>
                }
              </p>
          </div>
        ))}
      </div>

      {originalData[0].length > 4 && (
        <>
          <div className="horizontal-line"></div>
          <button className="moreData" onClick={() => setShowMore(!showMore)}>
            {showMore ? 
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.48154 23.2977C4.25851 24.346 2.41722 24.2043 1.3689 22.9813C0.320586 21.7583 0.462224 19.917 1.68526 18.8687L22.1019 1.36867C23.1942 0.432448 24.8059 0.432448 25.8982 1.36867L46.3149 18.8687C47.5379 19.917 47.6796 21.7583 46.6312 22.9813C45.5829 24.2043 43.7416 24.346 42.5186 23.2977L24.0001 7.42465L5.48154 23.2977ZM5.48154 46.631C4.25851 47.6793 2.41722 47.5377 1.3689 46.3147C0.320586 45.0916 0.462224 43.2503 1.68526 42.202L22.1019 24.702C23.1942 23.7658 24.8059 23.7658 25.8982 24.702L46.3149 42.202C47.5379 43.2503 47.6796 45.0916 46.6312 46.3147C45.5829 47.5377 43.7416 47.6793 42.5186 46.631L24.0001 30.758L5.48154 46.631Z" fill="black"/>
            </svg>
            
            :
            <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.8863 1.36873C43.1006 0.32041 44.9287 0.462048 45.9696 1.68508C47.0104 2.90812 46.8698 4.74941 45.6555 5.79772L25.3846 23.2977C24.3002 24.234 22.6999 24.234 21.6155 23.2977L1.34465 5.79772C0.130348 4.74941 -0.0102778 2.90812 1.03055 1.68508C2.07138 0.462048 3.89951 0.32041 5.11381 1.36873L23.5001 17.2418L41.8863 1.36873ZM41.8863 24.7021C43.1006 23.6538 44.9287 23.7954 45.9696 25.0184C47.0104 26.2415 46.8698 28.0827 45.6555 29.1311L25.3846 46.6311C24.3002 47.5673 22.6999 47.5673 21.6155 46.6311L1.34465 29.1311C0.130348 28.0827 -0.0102778 26.2415 1.03055 25.0184C2.07138 23.7954 3.89951 23.6538 5.11381 24.7021L23.5001 40.5751L41.8863 24.7021Z" fill="black"/>
            </svg>
            }
            {showMore ? 'Voir moins' : 'Voir plus'}
          </button>
        </>
      )}
    </div>
    
    </>
  }
    </>
  );
};

const Loader = () => <div className="loading-container">{LoaderComponent.getLoader()}</div>;

export default ChartPreview;
