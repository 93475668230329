import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"

export const deleteClient = (clientId) => {
    const requestUrl = API_URL+`/client/`+clientId+`?access_token=`+getConnectedUserAccessToken();
    return axios.delete(requestUrl);
};

export const getTotalNumberOfPages = () => {
  const requestUrl = API_URL+`/client/getTotalNumberOfPages/`+getCurrentClientID()+`?access_token=`+getConnectedUserAccessToken();
  return axios.get(requestUrl)
};

export const saveClient = (clientForm, isBillingAddressChecked) => {
    
    const requestUrl = API_URL+`/client?access_token=`+getConnectedUserAccessToken();
      let clientAddresses = [
        { address: clientForm.headquartersAddress, postalCode: clientForm.headquartersZipCode, state: clientForm.headquartersCommon, addressType: 'SIEGE'}
      ];

      let users = [
        { firstName: clientForm.signatoryFirstName, lastName: clientForm.signatoryLastName, email: clientForm.signatoryEmail, phoneNumber: clientForm.signatoryPhoneNumber, homePhoneNumber: clientForm.signatoryHomePhoneNumber, extraPhoneNumber: clientForm.signatoryExtraPhoneNumber, userRole: {roleType : 'COMMERCIAL_CONTRACT'}}
      ];

      if(clientForm.billingAddress && clientForm.billingZipCode && clientForm.billingCommon && isBillingAddressChecked === true) {
        clientAddresses.push({ address: clientForm.billingAddress, postalCode: clientForm.billingZipCode, state: clientForm.billingCommon, addressType: 'BILLING'});
      }

      if(clientForm.technicalContactEmail && clientForm.technicalContactFirstName && clientForm.technicalContactLastName && clientForm.technicalContactPhoneNumber) {
        users.push({ firstName: clientForm.technicalContactFirstName, lastName: clientForm.technicalContactLastName, email: clientForm.technicalContactEmail, phoneNumber: clientForm.technicalContactPhoneNumber, userRole: {roleType : 'TECHNICAL_CONTRACT'}})
      }
    var formData = new FormData();
    formData.append('companyName', clientForm.companyName);
    formData.append('sirenNumber', clientForm.sirenNumber);
    formData.append('institutionCode', clientForm.institutionCode);
    formData.append('companyCode', clientForm.institutionCode);

    if(clientForm?.subscriptionQuota) {
      formData.append('subscriptionQuota', clientForm.subscriptionQuota);
    }
    if(clientForm?.subscriptionDate) {
      formData.append('subscriptionDate', clientForm.subscriptionDate);
    }
    if(clientForm?.subscriptionEndingDate) {
      formData.append('subscriptionEndingDate', clientForm.subscriptionEndingDate);
    }
    if(clientForm?.annualPrice) {
      formData.append('annualPrice', clientForm.annualPrice);
    }
    if(clientForm?.subscriptionDuration) {
      formData.append('subscriptionDuration', clientForm.subscriptionDuration);
    }
    if(clientForm?.unit) {
      formData.append('unit', clientForm.unit);
    }
    if(clientForm?.discount) {
      formData.append('discount', clientForm.discount);
    } else {
        formData.append('discount', 0);
    }
    if(clientForm?.regulationType) {
      formData.append('regulationType', clientForm.regulationType);
    }
    if(clientForm?.isFirstYearFree) {
      formData.append('isFirstYearFree', (clientForm.isFirstYearFree == true ? 1 : 0));
    }
    if(clientForm?.signMethod) {
        formData.append('signMethod', clientForm.signMethod);
    }

    if(clientForm.clientAdmins && clientForm.clientAdmins.length>0){
      clientForm.clientAdmins.map((cltAdmin, index)=>{
        let userObj = { firstName: cltAdmin.firstName, lastName: cltAdmin.lastName, email: cltAdmin.email, phoneNumber: cltAdmin.phoneNumber, homePhoneNumber: cltAdmin.homePhoneNumber, extraPhoneNumber: cltAdmin.extraPhoneNumber, userRole: {roleType : 'ADMIN_CLIENT'}}
        formData.append(`user[${index}]`, JSON.stringify(userObj));
      })
    }
  
    let indexTechComercialContact = 0;
    if(clientForm.clientAdmins && clientForm.clientAdmins.length>0){
      indexTechComercialContact = clientForm.clientAdmins.length
    }
    if(users && users.length>0){
      users.map((user,index)=>{
        formData.append(`user[${(index+indexTechComercialContact)}]`, JSON.stringify(user));
      })
    }

    formData.append('clientAddresses[0]', JSON.stringify(clientAddresses[0]));
    if(clientForm.billingAddress && clientForm.billingZipCode && clientForm.billingCommon && isBillingAddressChecked === true) {
      formData.append('clientAddresses[1]', JSON.stringify(clientAddresses[1]));
    }

  
    console.log(clientForm)
    if (clientForm.domainNames && clientForm.domainNames.length) {
      formData.append('domainNames', JSON.stringify(clientForm.domainNames.filter(domain => (!domain.unselected) || domain.unselected === false).map(d => d.label)));
    }

    return axios.post(requestUrl, formData);
};

export const getClients = () => {
  const requestUrl = API_URL+`/client?access_token=`+getConnectedUserAccessToken();
  return axios.get(requestUrl)
};


export const updateClient = (clientForm, isBillingAddressChecked) => {
    
  const requestUrl = API_URL+`/client?access_token=`+getConnectedUserAccessToken();
    let clientAddresses = [
      { id: clientForm.headquartersAddressID, address: clientForm.headquartersAddress, postalCode: clientForm.headquartersZipCode, state: clientForm.headquartersCommon, addressType: 'SIEGE'}
    ];

    let users = [
        { firstName: clientForm.signatoryFirstName, lastName: clientForm.signatoryLastName, email: clientForm.signatoryEmail, phoneNumber: clientForm.signatoryPhoneNumber, homePhoneNumber: clientForm.signatoryHomePhoneNumber, extraPhoneNumber: clientForm.signatoryExtraPhoneNumber, userRole: {roleType : 'COMMERCIAL_CONTRACT'}}
    ];

    if(clientForm.billingAddress && clientForm.billingZipCode && clientForm.billingCommon && isBillingAddressChecked === true) {
      clientAddresses.push({ id: clientForm.billingAddressID, address: clientForm.billingAddress, postalCode: clientForm.billingZipCode, state: clientForm.billingCommon, addressType: 'BILLING'});
    }
    if(clientForm.technicalContactEmail && clientForm.technicalContactFirstName && clientForm.technicalContactLastName && clientForm.technicalContactPhoneNumber) {
        users.push({ firstName: clientForm.technicalContactFirstName, lastName: clientForm.technicalContactLastName, email: clientForm.technicalContactEmail, phoneNumber: clientForm.technicalContactPhoneNumber, userRole: {roleType : 'TECHNICAL_CONTRACT'}})
    }

  var formData = new FormData();
  formData.append('id', clientForm.id);
  formData.append('companyName', clientForm.companyName);
  formData.append('sirenNumber', clientForm.sirenNumber);
  formData.append('institutionCode', clientForm.institutionCode);
  formData.append('companyCode', clientForm.institutionCode);  //TODO: To add in the client creation form
  if(clientForm?.subscriptionDate) {
    formData.append('subscriptionDate', clientForm.subscriptionDate);
  }
  if(clientForm?.subscriptionQuota) {
    formData.append('subscriptionQuota', clientForm.subscriptionQuota);
  }
  if(clientForm?.subscriptionEndingDate) {
    formData.append('subscriptionEndingDate', clientForm.subscriptionEndingDate);
  }
  if(clientForm?.annualPrice) {
    formData.append('annualPrice', clientForm.annualPrice);
  }
  if(clientForm?.subscriptionDuration) {
    formData.append('subscriptionDuration', clientForm.subscriptionDuration);
  }
  if(clientForm?.unit) {
    formData.append('unit', clientForm.unit);
  }
  if(clientForm?.discount) {
    formData.append('discount', clientForm.discount);
  } else {
      formData.append('discount', 0);
  }
  formData.append('regulationType', clientForm.regulationType);
  if(clientForm?.isFirstYearFree) {
    formData.append('isFirstYearFree', (clientForm.isFirstYearFree == true ? 1 : 0));
  }
    if(clientForm?.signMethod) {
        formData.append('signMethod', clientForm.signMethod);
    }

  if(clientForm.clientAdmins && clientForm.clientAdmins.length>0){
    clientForm.clientAdmins.map((cltAdmin, index)=>{
      let userObj = { firstName: cltAdmin.firstName, lastName: cltAdmin.lastName, email: cltAdmin.email, phoneNumber: cltAdmin.phoneNumber, homePhoneNumber: cltAdmin.homePhoneNumber, extraPhoneNumber: cltAdmin.extraPhoneNumber, userRole: {roleType : 'ADMIN_CLIENT'}}
      formData.append('user['+index+']', JSON.stringify(userObj));
    })
  }

  let indexTechComercialContact = 0;
  if(clientForm.clientAdmins && clientForm.clientAdmins.length>0){
    indexTechComercialContact = clientForm.clientAdmins.length
  }
  if(users && users.length>0){
    users.map((user,index)=>{
      formData.append('user['+(index+indexTechComercialContact)+']', JSON.stringify(user));
    })
  }


  formData.append('clientAddresses[0]', JSON.stringify(clientAddresses[0]));
  if(clientForm.billingAddress && clientForm.billingZipCode && clientForm.billingCommon && isBillingAddressChecked === true) {
    formData.append('clientAddresses[1]', JSON.stringify(clientAddresses[1]));
  }

  console.log(clientForm)
  if (clientForm.domainNames && clientForm.domainNames.length) {
    formData.append('domainNames', JSON.stringify(clientForm.domainNames.filter(domain => (!domain.unselected) || domain.unselected === false).map(d => d.label)));
  }


  console.log('%cFormData values:','font-weight: bold; color: red;font-size: 28px');

  for (const [key, value] of formData.entries()) {
    console.log(`${key}: ${value}`);
  }
  
    return axios.post(requestUrl, formData);
  };

export const calculateSubscriptionEndingDate = (subscriptionDate, subscriptionDuration, isFirstYearFree, unit=0) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/client/calculateSubscriptionEndingDate?access_token=`+getConnectedUserAccessToken();

  formData.append('subscriptionDate', subscriptionDate);
  formData.append('subscriptionDuration', subscriptionDuration);
  formData.append('isFirstYearFree', (isFirstYearFree == true ? 1 : 0));
  if(unit && unit.toString() == "1"){
    formData.append('unit', unit);
  }

  return axios.post(requestUrl, formData);
};

export const getFormDataDifference = (formData1, formData2) => {
  // Convert FormData objects to arrays for easier comparison
  const array1 = Array.from(formData1.entries());
  const array2 = Array.from(formData2.entries());

  // Find the differences between the arrays
  const differences = [];

  // Iterate over the entries of the first array
  for (let i = 0; i < array1.length; i++) {
    const [key1, value1] = array1[i];

    // Find the corresponding entry in the second array
    const matchingEntry = array2.find(([key2]) => key1 === key2);

    // If the entry is not found or the values are different, add it to the differences array
    if (!matchingEntry || value1 !== matchingEntry[1]) {
      differences.push({ key: key1, value1, value2: matchingEntry ? matchingEntry[1] : undefined });
    }
  }

  // Add the entries from the second array that are not present in the first array
  for (let i = 0; i < array2.length; i++) {
    const [key2, value2] = array2[i];

    // Find the corresponding entry in the first array
    const matchingEntry = array1.find(([key1]) => key1 === key2);

    // If the entry is not found, add it to the differences array
    if (!matchingEntry) {
      differences.push({ key: key2, value1: undefined, value2 });
    }
  }

  return differences;
}

export const getArchiveHistoryByClient = (clientId) => {
  const requestUrl = API_URL+`/archiveHistory/${clientId}?access_token=`+getConnectedUserAccessToken();
  return axios.get(requestUrl)
};

export const exportArchiveHistoryByClient = (clientId) => {
  const requestUrl = API_URL+`/archiveHistory/export/${clientId}?access_token=`+getConnectedUserAccessToken();
  return axios.get(requestUrl)
};