import React, { Component } from 'react'; 
import './GuestPassword.scss';
import { checkIfUserEmailExists, createThePAsswordResetLink, getGuestPhone, verifyGuest } from "../../../api/user"
import LoaderComponent from '../../../components/loader/Loader' 
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Cookies from "js-cookie";
import errorAlert from '../../../components/confirm-alert/error-alert';
import { Link, withRouter } from 'react-router-dom';
import HeaderVisitor from '../../../components/layouts/header-visitor/HeaderVisitor';
import NotifyAlert from '../../../components/confirm-alert/notify-alert/NotifyAlert';
import { sendInvitationSMS } from '../../../api/negociation';
class GuestPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guest_id: Cookies.get("guest_id") ? Cookies.get("guest_id") : null,
      phone_number: null,
      isActivated: false,
      checkError: false,
      isCodeReveived: false
    };
    this.form = {
      code: null,
    };
  }

  componentDidMount() {
    if (this.state.guest_id == null || this.state.phone_number == null) {
      const queryString = require("query-string");
      var parsed = queryString.parse(this.props.location.search);

      if (
        parsed &&
        parsed.redirect &&
        parsed.guest_id &&
        (parsed.redirect === true || parseInt(parsed.redirect) === 1) &&
        parseInt(parsed.guest_id)
      ) {
        Cookies.set("guest_id", parseInt(parsed.guest_id));
        if (parsed && parsed.guest_id){
            Cookies.set("guest_id", parsed.guest_id);
            this.setState({
                ...this.state,
                guest_id: parsed.guest_id
            })

            getGuestPhone(this.state.guest_id).then(
                (response) => {
                    this.setState({
                        ...this.state,
                        phone_number: response.data['phoneNumber'],
                        isActivated: response.data['isActivated']
                    })
                    if(response && response.data['isActivated']){
                      NotifyAlert.show(
                        "Vous êtes déjà utilisateur de Docatweb, vous pouvez vous connecter avec votre compte habituel",
                        'Information'
                      ).then(() => {});
                      this.props.history.push(
                        "/home"
                      );
                      
                    }
                },
                (error) => {
                  LoaderComponent.hide();
                  if(error && error.response)
                    errorAlert.show(error.response.data, "Erreur API");
                }
              );
        }
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    LoaderComponent.show();
    this.setState({
      checkError: false,
    });
    verifyGuest(this.state.guest_id, this.form.code.value).then(
      (response) => {
        LoaderComponent.hide();
        console.log(response["data"]);
        if (response["data"]) {
          setTimeout(() => {
            window.location.replace(response["data"]);
          }, 2000);
        } else {
          this.setState({
            checkError: true,
          });
        }
      },
      (error) => {
        LoaderComponent.hide();
        errorAlert.show(error.response.data, "Erreur API");
        // setTimeout(() => {
        //     this.props.history.push("/home");
        // }, 3000);
      }
    );
  };

  resendSMSInvitation = () => {
    if (this.state.guest_id) {
      sendInvitationSMS(this.state.guest_id).then(
        (response) => {
          NotifyAlert.show("SMS envoyé avec succès", "Renvoie de code").then(
            () => {
              this.toogleCodeReceived()
            }
          );
        },
        (error) => {
          LoaderComponent.hide();
          if (
            error &&
            error.response &&
            error.response.data === "You have exceeded the maximum of attempts"
          )
            errorAlert.show(
              "Vous avez dépassé le maximum de tentatives",
              "Erreur API"
            );
          else errorAlert.show(error.response.data, "Renvoie de code");
        }
      );
    }
  };

  toogleCodeReceived = () =>{
    this.setState({
      ...this.state,
      isCodeReveived: !this.state.isCodeReveived
  })
  } 

  render() {
    return (
      <div className="wave-bg vh-100">
        <HeaderVisitor />
        <form
          method="post"
          action="#"
          className="forgot-password-form"
          onSubmit={this.onSubmit}
          id="form-reset-password"
        >
          <center>
            <h3 className="black-text">{t("guest_password.title")}</h3>
          </center>

          <div
            className={`form-group input-group ${
              this.state.checkError ? "error" : ""
            }`}
          >
            {/* <label>{t("guest_password.email")}</label> */}
            <input
              ref={(input) => {
                this.form.code = input;
              }}
              className="form-control"
              type="text"
              name="code"
              placeholder={t("guest_password.email")}
              required
            ></input>
            <span className="error">Une erreur est survenue</span>
          </div>

          <div className="form-group">
            { this.state.isCodeReveived &&
              <>
                <button className="btn btn-primary" type="submit">
                  {t("guest_password.validate")}
                </button>
                <p
                  onClick={()=> this.toogleCodeReceived()}
                  className="already-have-code"
                >
                  Je n'ai pas reçu le code
                </p>
              </>
            }
            { !this.state.isCodeReveived &&
              <>
                <a
                  onClick={() => this.resendSMSInvitation()}
                  className="btn btn-send-code black-text"
                >
                  <span>
                    Envoyer code
                  </span>
                </a>
                <p
                  onClick={()=> this.toogleCodeReceived()}
                  className="already-have-code"
                >
                  J'ai déjà reçu le code
                </p>
              </>       
              }
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              { !this.state.isCodeReveived && <p
                className="black-text"
              >
               Le code vous sera envoyé au numéro <strong className='orange-text'>+33 {this.state.phone_number}</strong>
              </p>}
            </div>
            <div className="col-md-12 text-center">
              <Link to="/login" className="btn btn-link black-text">
                {t("authentication.connect")}
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(withTranslation()(GuestPassword));