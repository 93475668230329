import React from "react";

const PlusCirleIcon = () => (
  <>
    <svg
      className="inner"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 7H1M7 1V13V1Z"
        stroke="#F66031"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
    <svg
      className="outer"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="18" fill="white"></rect>
    </svg>
  </>
);

export default PlusCirleIcon;
