import axios from 'axios';
import { API_FXR_TOKEN } from '../config/common';
axios.defaults.timeout = 100000;
export const getExchangeTimeSeriesData = (range, currency, amount=1) => {
  const requestUrl = `https://api.fxratesapi.com/timeseries?api_key=${API_FXR_TOKEN}&start_date=${range.startDate}&end_date=${range.endDate}&currencies=${currency.to}&base=${currency.from}&format=JSON&amount=${amount}`
  return axios.get(requestUrl);
};

export const getConvertedAmount = (currency, amount) => {
  const requestUrl = `https://api.fxratesapi.com/convert?api_key=${API_FXR_TOKEN}&from=${currency.from}&to=${currency.to}&format=JSON&amount=${amount}`
  return axios.get(requestUrl);
};

