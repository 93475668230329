import { t } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PlusCirleIcon } from "../../../assets/icons";

const Tabs = ({ items, children, isSticky, handleTabClick, handleDragStart, handleDragOver, handleDrop, draggedIndex, recentContract, currentTab, pinTimeline}) => (
  <div className="mb-3 full-width card tabs no-padding-left-mobile no-padding-right-mobile">
    <div className="recent-contracts">
      {recentContract}
    </div>
    <div className={`card-header-tab card-header ${isSticky ? ' fixed-tabs-menu':''}`}>
      <ul className="nav draggable-list">
        {items.map((item, index) => (
          <li   className={index === draggedIndex ? 'dragged-item nav-item' : 'nav-item'}>
            {
              item && item.id == '#tab-recent' &&
              <a data-toggle="tab" data-id={item.id} href={item.id} className={`nav-link ${item.active && 'active'}`} onClick={() => handleTabClick(item.id, index)}
                key={index}
                draggable="true"
                onDragStart={(event) => handleDragStart(event, index)}
                onDragOver={(event) => handleDragOver(event)}
                onDrop={(event) => handleDrop(event, index)}>
                {/* {item.label} */}
                {t('frontoffice.home.contracts.tabs.'+item.key)}
              </a>
            }
            {
              item && item.id !== '#tab-recent' &&
              <a data-toggle="tab" data-id={item.id} href={item.id} className={`nav-link ${item.active && 'active'}`} onClick={() => handleTabClick(item.id, index)}
              key={index}
                draggable="true"
                onDragStart={(event) => handleDragStart(event, index)}
                onDragOver={(event) => handleDragOver(event)}
                onDrop={(event) => handleDrop(event, index)}>
                {/* {item.label}  */}
                {t('frontoffice.home.contracts.tabs.'+item.key)} ({item.count})
              </a>
            }
          </li>
        ))}
      </ul>
      <div className={"card-header-title" + (isSticky? " new-contract-btn-sticky":"")}>
        <Link to="/manage-contract/import">
          <button className="btn-icon btn-pill btn btn-danger">
            <PlusCirleIcon />
            <span>
              {t('frontoffice.home.contracts.tabs.create_contract')}
            </span>
          </button>
        </Link>
      </div>
    </div>
    <div className={"card-body no-padding-left-mobile no-padding-right-mobile"+ (isSticky? ((" contract-list-sticky"+currentTab?.replace("#", "-"))+(pinTimeline?"-pinned":"")):"")}>
      <div className="tab-content">
        {children}
      </div>
    </div>
  </div>
);

export default withTranslation()(Tabs);
