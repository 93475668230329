import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"

export const getContractInfo = (contractID) => {
    const requestUrl = API_URL+`/contract/getContractInfo?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contractID);
    return axios.post(requestUrl, formData);
}

export const createIndex = (contractID, name) => {
    const requestUrl = API_URL+`/index?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contractID);
    formData.append('name', name);
    return axios.post(requestUrl, formData);
}

export const getContractWithIndexDetail = (contractID) => {
    const requestUrl = API_URL+`/contract/getContractWithIndexDetail?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contractID);
    return axios.post(requestUrl, formData);
}

export const associateContractToIndex = (contractID, indexID) => {
    const requestUrl = API_URL+`/contract/associateContractToIndex?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contractID);
    formData.append('index', indexID);
    return axios.post(requestUrl, formData);
}

export const dissociateContractToIndex = (contractID, indexID) => {
    const requestUrl = API_URL+`/contract/dissociateContractToIndex?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contractID);
    formData.append('index', indexID);
    return axios.post(requestUrl, formData);
}

export const deleteTag = (indexID, contractID) => {
    const requestUrl = API_URL+`/contract/deleteContractIndex?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contractID);
    formData.append('index', indexID);
    return axios.post(requestUrl, formData);
  };

export const getContractWithTagDetail = (contractID) => {
    const requestUrl = API_URL+`/contract/getContractWithTagDetail?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contractID);
    return axios.post(requestUrl, formData);
}

export const associateContractToTag = (contractID, tagID) => {
    const requestUrl = API_URL+`/contract/associateContractToTag?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contractID);
    formData.append('tag', tagID);
    return axios.post(requestUrl, formData);
}

export const dissociateContractToTag = (contractID, tagID) => {
    const requestUrl = API_URL+`/contract/dissociateContractToTag?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contractID);
    formData.append('tag', tagID);
    return axios.post(requestUrl, formData);
}

export const deleteOrganization = (tagID, contractID) => {
    const requestUrl = API_URL+`/contract/deleteContractTag?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contractID);
    formData.append('tag', tagID);
    return axios.post(requestUrl, formData);
};

export const exportTags = (contractID) => {
    const requestUrl = API_URL + `/contract/index/export/` + contractID + `?access_token=` + getConnectedUserAccessToken();
    return axios.get(requestUrl)
};