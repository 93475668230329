import React from "react";

const DeleteIcon = () => (
  <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 5.48125V18.3606C16 19.7315 14.8807 20.8428 13.5 20.8428H2.5C1.11929 20.8428 0 19.7315 0 18.3606V3.46776C0 2.09691 1.11929 0.985614 2.5 0.985614H11.472C11.6048 0.977255 11.7429 1.0212 11.8536 1.13101L15.8536 5.10244C15.9642 5.21226 16.0084 5.34945 16 5.48125ZM11 1.97847H2.5C1.67157 1.97847 1 2.64525 1 3.46776V18.3606C1 19.1831 1.67157 19.8499 2.5 19.8499H13.5C14.3284 19.8499 15 19.1831 15 18.3606V5.9499H11.5C11.2239 5.9499 11 5.72764 11 5.45347V1.97847ZM12 2.68053V4.95704H14.2929L12 2.68053ZM8.70711 13.5L10.8536 11.3536C11.0488 11.1583 11.0488 10.8417 10.8536 10.6464C10.6583 10.4512 10.3417 10.4512 10.1464 10.6464L8 12.7929L5.85355 10.6464C5.65829 10.4512 5.34171 10.4512 5.14645 10.6464C4.95118 10.8417 4.95118 11.1583 5.14645 11.3536L7.29289 13.5L5.14645 15.6464C4.95118 15.8417 4.95118 16.1583 5.14645 16.3536C5.34171 16.5488 5.65829 16.5488 5.85355 16.3536L8 14.2071L10.1464 16.3536C10.3417 16.5488 10.6583 16.5488 10.8536 16.3536C11.0488 16.1583 11.0488 15.8417 10.8536 15.6464L8.70711 13.5Z" fill="#EB3A59" />
  </svg>

);

export default DeleteIcon;
