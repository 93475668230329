import React, { useEffect, useState } from "react";
import uuid from 'react-uuid'

import PageLayout from "../components/layout";

//parts
import DashBordsListing from "./parts/listing/DashBordsListing";
import GraphScreens from "./parts/graph-screens/GraphScreens";
import Tabs from "./parts/tabs/tabs";
import DATA from "./constants.json";
import { t } from 'i18next';
//API
import {
  getListing,
  addPage,
  editPage,
  deletePage,
  getPagesDetail,
  deleteTDB
} from '../../../../../api/dashboard-buying';
import Modal from '../../../../../components/Modal';
import LoaderComponent from '../../../../../components/loader/Loader';
import NotifyAlert from "../../../../../components/confirm-alert/notify-alert/NotifyAlert";
import ConfirmAlert from "../../../../../components/confirm-alert/ConfirmAlert";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Menu from "./parts/menu/Menu";

const DashBordConsultnMode = ({editMode, onChangeEditMode}) => {
  const [navMenu, setNavMenu] = useState('BUYER');
  const [items, setItems] = useState([])
  const [pages, setPages] = useState(null)
  const [screen, setScreen] = useState(null)
  const [activePage, setActivePage] = useState(null)
  const [currentDash, setCurrentDash] = useState(null)


  const [listing, setListing] = useState([]);

  const [isPageModalOpen, setOpenPageModal] = useState(false);
  const [isToogleActive, setIsToogleActive] = useState(null);

  const [activeTab, setActiveTab] = useState(0);

  const loadListing = async () => {
    const _fields = await getListing();
    setListing(_fields && _fields.length>0 ? _fields.filter(item=>item.hidden === false) : _fields );
    setItems(_fields && _fields.length>0 ? _fields.filter(item=>item.hidden === false) : _fields )
  };
  const loadPages = async () => {
    const _fields = await getPagesDetail();
    setPages(_fields[navMenu]);
  };

  useEffect(() => {
    setCurrentDash(null)
    loadListing();
    loadPages();
  }, [currentDash, activePage,navMenu]);

  useEffect(() => {
    setActiveTab(0)
    setActivePage(null)
  }, [navMenu]);

  useEffect(() => {
    setIsToogleActive(null)
  }, [activePage]);

  const onPressAdd = () => {
    setOpenPageModal(true)
  }
 
  const onPressDel = async () => {
   
    if (activePage == null && pages && pages.length<1) {
      toast.warn(t('Suppression'), t('Veuillez choisir une page valide'), {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }else{
      let page = (activePage == null)?  pages[0].page: activePage.page
      ConfirmAlert.show(t('Etes vous sur de bien vouloir supprimer la page: ')+ page.name + t(' ?')).then(async ()=>{
        try {
          await deletePage({
            id: page.id,
          });
          setIsToogleActive(null)
          LoaderComponent.hide();
          toast.success(t('Page ') + page.name +t(' supprimé avec succès'), {
            position: toast.POSITION.TOP_RIGHT
          });
          loadPages()
          
        } catch (error) {
          console.log('error', error);
          LoaderComponent.hide();
          toast.error(t('Une erreur est survenue, page non supprimé'), {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }, (e) => {

      }
      );
  }
  }

  const onActiveChart = async(isActive, item, isDelete) => {
    if(isActive){
      console.log('adding',item)
      setCurrentDash(item)
      setIsToogleActive({
        isActive: isActive,
        dashboard: item.id
      })
    }else{
      setCurrentDash({})
      // console.log('removing')
      setIsToogleActive(null)
      try {
        let find = null
        if(activePage == null)
           find = pages[0]['detail'].find(x=>x.dashboard === item?.id)
        else
           find = activePage['detail'].find(x=>x.dashboard === item?.id)
        if(find){
          await deleteTDB({
            id: find.id,
          });
          if(activePage && activePage['detail']){
            const indexOfObject = activePage['detail'].findIndex(object => {
              return object.dashboard === item?.id;
            });
            activePage['detail'].splice(indexOfObject, 1);
            setActivePage(activePage);
          }
          setCurrentDash(null)
          loadPages()
          LoaderComponent.hide();
          toast.success(t('Tableau de bord ') + item.name +t(' supprimé avec succès'), {
            position: toast.POSITION.TOP_RIGHT
          });
        }else{
          LoaderComponent.hide();
        }
        
        
      } catch (error) {
        // console.log('error', error);
        LoaderComponent.hide();
        toast.error(t('Une erreur est survenue, tableau de bord non supprimé'), {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  
  return(
    <PageLayout
      editMode={editMode}
      onChangeEditMode={onChangeEditMode}
      leftArea={
        <>
          <Menu onchange={setNavMenu} />
          <DashBordsListing
            navMenu={navMenu}
            items={items}
            screen={screen}
            onToggle={onActiveChart}
            currentDash={currentDash}
            activePage={activePage ? activePage : (pages ? pages[0]:null)}
            isToogleActive={isToogleActive}
          />
        </>
      }
      rightArea={
        <>
          <ToastContainer className="toast-styled"/>
          <PageModal isOpen={isPageModalOpen} onRequestClose={() => setOpenPageModal(false)} loadPages={loadPages} pages={pages} navMenu={navMenu}/>
          <Tabs 
            pages={pages !== null ? pages:[]}
            onPressAdd={onPressAdd}
            onPressDel={onPressDel}
            onChangeTab={setActivePage}
            onEditTab={(data)=>{
               editPage(data,navMenu);
            }}
            toast={toast}
            activeTab={activeTab}
            onSetActiveTab={setActiveTab}
          />
          <GraphScreens
            activePage={activePage ? activePage : (pages ? pages[0]:null)}
            items={pages && pages.length > 0 ? listing: []}
            count={6}
            screen={screen}
            currentDash={currentDash}
            onSelectScreen={setScreen}
            toast={toast}
            loadPages={loadPages}
            loadListing={loadListing}
            isToogleActive={isToogleActive}
          />
        </>
      }
    />
  );
}

const PageModal = ({ isOpen, onRequestClose, loadPages, pages, navMenu }) => {
  const savePage = async () => {
    //Save namePage
    LoaderComponent.show();
    if(!namePage || namePage.length<0)
      return;
    try {
      if(pages && pages.length>0){
        let found = pages.find(item=> (item.page.name).toLowerCase().replace(/\s/g, '') == namePage.toLowerCase().replace(/\s/g, ''))
        if(found){
          toast.warn(t('Il existe déjà une page portant le même nom : ' + namePage), {
            position: toast.POSITION.TOP_RIGHT
          });
          LoaderComponent.hide();
          onRequestClose(false);
          return;
        }
      }
      await addPage({
        name: namePage,
        type: navMenu
      });
      LoaderComponent.hide();
      // NotifyAlert.show(t('Page rajouté avec succès')).then(() => {});
      toast.success('Page rajouté avec succès', {
        position: toast.POSITION.TOP_RIGHT
      });
      setNamePage(null)
      loadPages()
    } catch (error) {
      // console.log('error', error);
      LoaderComponent.hide();
      toast.error(t('Une erreur est survenue, page non ajouté'), {
        position: toast.POSITION.TOP_RIGHT
      });
    }

    onRequestClose(false);
  };

    const [namePage, setNamePage] = useState(null)
  
  return (<Modal
    title={'Tableaux de bord : Nouvelle page'}
    isOpen={isOpen}
    onRequestClose={() => onRequestClose(false)}
    footer={
      <>
        <button onClick={savePage}>
           {t('common.save')} 
        </button>
        <button onClick={() => onRequestClose(false)}>{t('common.cancel')}</button>
      </>
    }
  >
    <ul className="export-contracts-fiels">
      <label>
        Veuillez saisir le nom de la page à rajouter:
      </label>
      <input type="text" name="title" className="form-control" value={namePage}  onChange={e=>setNamePage(e.target.value)} required></input>
    </ul>
  </Modal>
);
}
export default DashBordConsultnMode;
