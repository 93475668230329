import React from 'react';

const TagIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"  width="20" height="20" viewBox="0 0 20 20" style={{zoom:1.3}}>
        <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" fill="blue"></path>
        <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" fill="blue"></path>
    </svg>
);

export default TagIcon;
