import moment from "moment";
import React, { useEffect, useState } from "react";
const ResiliateContract = ({
  show,
  endingDate,
  resiliationDate,
  resiliationPenalty,
  onPressResiliate,
  onPressCancelResiliate,
  editMode,
  editModeReminder
}) => {

  const [date, setDate] = useState('')
  const [penalty, setPenalty] = useState('')

  const getEndDate = () => {
    return moment(endingDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
  }

  const getPenalty = () => {
    return penalty;
  }

  const getResiliationDate = () => {
    return date
  }

  const onSubmit = e => {
    e.preventDefault()
    if( resiliationDate ){
      onPressCancelResiliate()
    }else{
      let formatedDate = moment(date).format('DD/MM/YYYY')
      onPressResiliate(formatedDate, penalty)
    }
  }

  useEffect(()=>{
    if( resiliationDate ){
      setDate(moment(resiliationDate, 'DD/MM/YYYY').format('YYYY-MM-DD'))
    }else{
      setDate('')
    }
    // console.log('resiliationPenalty', resiliationPenalty)
    setPenalty(resiliationPenalty)
  },[resiliationDate, resiliationPenalty])


  return show ? (
    <form className="termination-contract" onSubmit={onSubmit}>
      <div className="input-item input-item-styled required">
        <label className="penalty">Date de résiliation</label>
        <label className="penalty">Pénalité</label>
        <div className="d-flex" onClick={!editMode ? editModeReminder : null}>
          <input
            className="form-control"
            name="termination"
            type="date"
            required
            value={getResiliationDate()}
            max={getEndDate()}
            onChange={e => setDate(e.target.value)}
            disabled={resiliationDate ||!editMode}
          />

          <input
              className="form-control"
              name="penalty"
              type="number"
              required
              value={getPenalty()}
              onChange={e => {
                const inputValue = e.target.value;
                // Remove leading zeros by converting to a number and back to a string
                const processedValue = inputValue ? Number(inputValue).toString() : '';
                setPenalty(processedValue);
              }}
              disabled={resiliationDate ||!editMode}
          />
          <button
            className="btn btn-danger"
            type="submit"
            disabled={!date || !editMode}
          >
            {resiliationDate ? "Annuler la résiliation" : "Résilier"}
          </button>
        </div>
      </div>
    </form>
  ): null
};

export default ResiliateContract;
