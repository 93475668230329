import { Record, List } from 'immutable';

class DashBordField  extends Record({
  key: 0,
  name: "",
  isScale: false,
  isCryptedData: true,
  isMultipleSelection: true,
  group: false,
  filter: false,
  color: "",
  data: new List()
}){
  constructor(values){
    let record = super(values);
    record = record
    .set('key', values?.id)
    .set('name', values?.name)
    .set('group', !!values?.isGrouped)
    .set('filter', !!values?.isFiltered)
    .set('color', values?.color)
    .set('data', values?.values.map(item => {
      if( typeof item == 'string' ){
        return{
          name: item,
          selected: true
        }
      }else return {
        ...item,
        selected: true
      }
    }))

    return record
  }
}

export default DashBordField