import React from "react";

const EyeOnIcon = () => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1.4375C5.55 1.4375 0.9375 8 0.9375 8C0.9375 8 5.55 14.5625 12 14.5625C18.45 14.5625 23.0625 8 23.0625 8C23.0625 8 18.45 1.4375 12 1.4375Z"
      stroke="#7077A5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12.3125C14.4853 12.3125 16.5 10.2978 16.5 7.8125C16.5 5.32722 14.4853 3.3125 12 3.3125C9.51472 3.3125 7.5 5.32722 7.5 7.8125C7.5 10.2978 9.51472 12.3125 12 12.3125Z"
      stroke="#7077A5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EyeOnIcon;
