import React from "react";
export default ({ checked }) =>
  checked ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 4C7.20887 4 6.43552 4.23459 5.77772 4.67412C5.11992 5.11364 4.60723 5.73836 4.30448 6.46926C4.00173 7.20016 3.92251 8.00444 4.07685 8.78037C4.23119 9.55629 4.61216 10.269 5.17157 10.8284C5.73098 11.3878 6.44371 11.7688 7.21964 11.9231C7.99556 12.0775 8.79983 11.9983 9.53073 11.6955C10.2616 11.3928 10.8863 10.8801 11.3259 10.2223C11.7654 9.56449 12 8.79112 12 8C12 6.94602 11.5841 5.93462 10.8426 5.18562C10.1011 4.43661 9.09392 4.01054 8.04 4H8ZM8 0C6.41775 0 4.87104 0.469191 3.55544 1.34824C2.23985 2.22729 1.21446 3.47671 0.60896 4.93852C0.00345945 6.40033 -0.154958 8.00887 0.153724 9.56071C0.462405 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7601 14.6517 12.4445C15.5308 11.129 16 9.58224 16 8C16 5.87826 15.1571 3.84343 13.6569 2.34314C12.1566 0.842848 10.1217 0 8 0ZM8 14.4C6.7342 14.4 5.49682 14.0246 4.44435 13.3214C3.39187 12.6182 2.57157 11.6186 2.08717 10.4492C1.60277 9.27972 1.47603 7.99289 1.72297 6.75141C1.96992 5.50993 2.57947 4.36957 3.47452 3.47451C4.36958 2.57945 5.50995 1.96992 6.75143 1.72297C7.9929 1.47603 9.27972 1.60278 10.4492 2.08718C11.6186 2.57158 12.6182 3.39188 13.3214 4.44436C14.0246 5.49683 14.4 6.7342 14.4 8C14.4 9.69738 13.7257 11.3252 12.5255 12.5255C11.3252 13.7257 9.69738 14.4 8 14.4Z"
        fill="#5082F5"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00001 0C6.41776 0 4.87103 0.469191 3.55544 1.34824C2.23985 2.22729 1.21447 3.47671 0.608969 4.93852C0.00346851 6.40033 -0.154961 8.00887 0.15372 9.56071C0.462402 11.1126 1.22433 12.538 2.34315 13.6568C3.46197 14.7756 4.88743 15.5376 6.43928 15.8463C7.99112 16.1549 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7601 14.6517 12.4445C15.5308 11.129 16 9.58224 16 8C16 5.87826 15.1571 3.84343 13.6568 2.34314C12.1566 0.842848 10.1217 0 8.00001 0ZM8.00001 14.4C6.73421 14.4 5.49683 14.0246 4.44436 13.3214C3.39188 12.6181 2.57158 11.6186 2.08718 10.4492C1.60278 9.27972 1.47604 7.99289 1.72298 6.75141C1.96993 5.50993 2.57946 4.36957 3.47452 3.47451C4.36957 2.57945 5.50994 1.96992 6.75142 1.72297C7.9929 1.47603 9.27973 1.60276 10.4492 2.08716C11.6186 2.57156 12.6182 3.39186 13.3214 4.44433C14.0246 5.49681 14.4 6.7342 14.4 8C14.4 9.69738 13.7257 11.3252 12.5255 12.5255C11.3252 13.7257 9.69739 14.4 8.00001 14.4Z"
        fill="#ADADAD"
      />
    </svg>
  );
