import React, { Component } from 'react';
import { BrowserRouter as Router, Link, withRouter } from "react-router-dom";
import { getLinkedDocuments, setNewDocumentData, deleteDocumentWithFile, getCSVFile, getContractInfo, addDocumentToTheRecentsList, updateLinkedDocumentContent, getOnlyContractsByCategory, getDocumentContracts, linkDocumentToContract, getOtherLinkedDocumentsByCategory, converContractToLinkedDocument } from '../../../../../api/linkedDocument'
import { downloadContractFromS3Server } from '../../../../../api/validateContract'

import './LinkedDocuments.scss'

import { t } from "i18next";
import { withTranslation } from "react-i18next";

// components
import Header from '../../../../../components/layouts/header/Header'
import Sidebar from '../../../../../components/layouts/sidebar/Sidebar'
import ConfirmAlert from '../../../../../components/confirm-alert/ConfirmAlert'
import LoaderComponent from '../../../../../components/loader/Loader'
import EditMode from '../../../../../components/edit-mode/EditMode';

// plugins
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Moment from 'moment';
import ErrorAlert from "../../../../../components/confirm-alert/error-alert"
import $ from 'jquery'
import Mark from "mark.js";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr"; // the locale you want
import ControlPanel from '../components/ControlPanel';
import ZoomContent from '../../../../../components/zoom-content/ZoomContent';
import FoManageContract from "../../../../../components/layouts/FO-Manage-Contract/FoManageContract";
import Popover from "react-text-selection-popover";
import highlightRange from "dom-highlight-range";
import DataTable from '../../../../../components/dataTable/DataTable';
import ContractsModal from './parts/ContractsModal';
import { ToastContainer, toast } from 'react-toastify';
import LinkedDocumentsModal from './parts/LinkedDocumentsModal';
import ControlModal from './parts/ControlModal';
import ContractsConvertModal from './parts/ContractsConvertModal';
import ResumeDataModal from '../../../../../components/resumeDataModal/ResumeDataModal';
registerLocale("fr", fr); // register it with the name you want



// form validation
const ReactFormWithConstraints =  require('react-form-with-constraints');
const { FormWithConstraints, FieldFeedbacks, FieldFeedback } = ReactFormWithConstraints;


class LinkedDocuments extends Component {

    constructor (props) {
        super(props);
        this.state = {
            editMode: false,
            showFormAddDocument: false,
            documents: [],
            pdfContent: null,
            contractId: null,
            currentDocument: null,
            contractInfo: null,
            search: {
                currentOccurence: 0,
                totalOccurence: 0
            },
            contracts:[],
            isLinkedContractsModalOpen: false,
            isLinkedDocumentToContractsModalOpen: false,
            isActionModalOpen: false,
            existsLinkedDocument:[],
            isConvertContractsModalOpen: false,
            isPopoverOpen: false,
            showResumeModal: false,
            actionData: "edit", // add | edit | delete
            formLinkedDoc: [
                {name: "subject", txtLabel: t('frontoffice.contract_linked_docs.subject_doc'), value: null, edited: false},
                {name: "sender", txtLabel: t('frontoffice.contract_linked_docs.name_sender'), value: null, edited: false},
                {name: "date", txtLabel: t('frontoffice.contract_linked_docs.date_doc'), value: null, edited: false},
                {name: "content", txtLabel: t('frontoffice.contract_linked_docs.comment'), value: null, edited: false},
                {name: "type", txtLabel: t('frontoffice.contract_linked_docs.received_sent'), value: null, edited: false}
            ],
            selectedIndexForEdit:{
                doc: null,
                subdoc: null
            }
        }
        this.pdfContent = React.createRef(); //TO SEE
        this.selectedText = "";
    }

    componentDidMount() {
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);
        if(parsed.contract) {
            LoaderComponent.show();
            getContractInfo(parsed.contract).then((response) => {this.setState({
                ...this,
                contractInfo: response.data,
                //editMode: response.data.ifUpdateRightIsPresent,
            }, () => {
                if(this.state.contractInfo && this.state.contractInfo.category && this.state.contractInfo.category.id !== undefined){
                    getOnlyContractsByCategory(this.state.contractInfo.category.id).then((response) => {this.setState({
                        ...this,
                        contracts: response.data,
                        contractsByCategory: response.data
                    })});
                    getOtherLinkedDocumentsByCategory(this.state.contractInfo.category.id).then((response) => {this.setState({
                        ...this,
                        existsLinkedDocument: response["data"].filter(doc => doc.name)
                    })});
                }
                if (this.state.contractInfo.status != 2) {
                    LoaderComponent.hide();
                    ErrorAlert.show("Ce contrat n'est pas encore validé", "Attention");
                    setTimeout(() => {
                        this.props.history.push("/home");
                    }, 3000);
                } else {
                    if(parsed.document) {
                        this.searchDocumentInTheList(parsed.document)
                    }
                    this.setState({
                        ...this,
                        contractId: parsed.contract,
                    })
                    getLinkedDocuments(parsed.contract).then((response) => {
                       
                        this.fetchDataAndUpdate(response.data).then(updatedArray => {
                            this.setState({
                                ...this,
                                documents: updatedArray,
                            }, () => {
                                if (parsed.document) {
                                    this.searchDocumentInTheList(parsed.document)
                                }
                                LoaderComponent.hide();
                            })
                        })
                    });
                }

            }) },  (error) => {
                LoaderComponent.hide();
                ErrorAlert.show(error?.response?.data, "Erreur API");
                setTimeout(() => {
                    this.props.history.push("/home");
                 }, 3000);
              });

        } else {
            ErrorAlert.show("Vous devez fournir l'identifiant du contrat", "Attention");
            setTimeout(() => {
                this.props.history.push("/home");
             }, 3000);
        }
    }

    async fetchDataAndUpdate(documentsObject) {
        console.log(documentsObject)
        try {
          // Use Promise.all to wait for all promises to resolve
          await Promise.all(documentsObject.map(async (item) => {
            //Remove items with hachedIds null
            if(item.items !== undefined && item["items"].some(x=>x.hachedId)){
                item["items"] = item["items"].filter(x=>x.hachedId);
                await Promise.all(item["items"].map(async (subItem, index) => {
                    const response = await getDocumentContracts(subItem.id).then((res) => { return res})
                
                // Assuming your API returns some data and you want to add it to each object
                const newData = response.data;
          
                // Update the object in the array with the new data
                item["items"][index].linkedContracts = newData || []; // Add a new field 'newField' with the fetched data
                }));
            }else{
                const response = await getDocumentContracts(item.id).then((res) => { return res})
                
                // Assuming your API returns some data and you want to add it to each object
                const newData = response.data;
          
                // Update the object in the array with the new data
                item.linkedContracts = newData || []; // Add a new field 'newField' with the fetched data
            }
          }));
      
          // After updating all objects in the array, you can do further processing if needed
          console.log(documentsObject);
          return documentsObject
        } catch (error) {
          console.error('Error fetching data:', error);
          return []; // Return an empty array in case of error
        }
      }

    searchDocumentInTheList(documentHachedId) {
        let documents = this.state.documents;
        let i = 0;
        for(i = 0; i < documents.length; i++) {
            if(!documents[i].isGroup){
                if (documents[i].hachedId === documentHachedId) {
                    addDocumentToTheRecentsList(documents[i].id).then((response) => {
                        console.log('Document added to the recents list')
                      }, (error) => {
                        ErrorAlert.show(error.response.data, "Erreur API");
                      });
                    documents[i].show = true;
                    documents[i].edit = true;
                    this.setState({
                        ...this,
                        currentDocument: documents[i],
                        documents: documents,
                        editMode: true,
                        //pdfContent: documents[i].show ? documents[i].pdfContent : null,
                    })
                }
            }else{
                documents[i].items.map(subDocument =>{
                    if (subDocument.hachedId === documentHachedId) {
                        addDocumentToTheRecentsList(subDocument.id).then((response) => {
                            console.log('Document added to the recents list')
                          }, (error) => {
                            ErrorAlert.show(error.response.data, "Erreur API");
                          });
                          subDocument.show = true;
                          subDocument.edit = true;
                          this.setState({
                            ...this,
                            currentDocument: subDocument,
                            documents: documents,
                            editMode: true,
                            //pdfContent: documents[i].show ? documents[i].pdfContent : null,
                        })
                    }
                })
            }
        }
    }

    changeEditMode = (checked) => {
        this.setState({
            ...this,
            editMode: checked,
            //pdfContent: null,
        })
        if (this.state.currentDocument) {
            this.resetTheDocumentsList();
        }
    }

    resetTheDocumentsList() {
        let documents = this.state.documents;
        // let currentDocument = this.state.currentDocument;
        let i = 0;
        for(i = 0; i < documents.length; i++) {
            if(!documents[i].isGroup){
                documents[i].show = false;
                documents[i].edit = false;
            }else{
                documents[i].items.map(subDocument =>{
                    subDocument.show = false;
                    subDocument.edit = false;
                })
            }

        }
        this.setState({
            ...this,
            currentDocument: null,
            documents: documents,
            actionData: "edit", // add | edit | delete
            formLinkedDoc: [
                {name: "subject", txtLabel: t('frontoffice.contract_linked_docs.subject_doc'), value: null, edited: false},
                {name: "sender", txtLabel: t('frontoffice.contract_linked_docs.name_sender'), value: null, edited: false},
                {name: "date", txtLabel: t('frontoffice.contract_linked_docs.date_doc'), value: null, edited: false},
                {name: "content", txtLabel: t('frontoffice.contract_linked_docs.comment'), value: null, edited: false},
                {name: "type", txtLabel: t('frontoffice.contract_linked_docs.received_sent'), value: null, edited: false}
            ],
            selectedIndexForEdit:{
                doc: null,
                subdoc: null
            },
            showResumeModal: false
            //pdfContent: null,
            //...this.state,
            //editMode: checked
        })
    }

    showFormAddDocument = ()=>{
        this.setState({
            ...this.state,
            showFormAddDocument: true
        })
    }

    showAllDocuments = ()=>{
        let documents = this.state.documents;
        documents.map(document =>{
            if(!document.isGroup){
                document.show = true;
                document.edit = false;
            }else{
                document.items.map(subDocument =>{
                    subDocument.show = true;
                    subDocument.edit = false;
                    return subDocument;
                })
            }
            return document
        })
        this.setState({
            ...this.state,
            documents: documents
        })
    }
    hideAllDocuments = ()=>{
        let documents = this.state.documents;
        documents.map(document =>{
            if(!document.isGroup){
                document.show = false;
                document.edit = false;
            }else{
                document.items.map(subDocument =>{
                    subDocument.show = false;
                    subDocument.edit = false;
                    return subDocument;
                })
            }
            return document
        })
        this.setState({
            ...this.state,
            documents: documents
        })
    }

    showDocument = (index, subDocumentIndex = false)=>{
        this.hideAllDocuments()
        let documents = this.state.documents;
        if( subDocumentIndex === false ){
            documents[index].show = !documents[index].show
            documents[index].edit = false
            addDocumentToTheRecentsList(documents[index].id).then((response) => {
                console.log('Document added to the recents list')
              }, (error) => {
                ErrorAlert.show(error.response.data, "Erreur API");
              });
            this.setState({
                ...this.state,
                documents: documents,
                //pdfContent: documents[index].show ? documents[index].pdfContent : []
                currentDocument: documents[index],
                search: {
                    currentOccurence: 0,
                    totalOccurence: 0
                }
            })
            if(documents[index].occurences && documents[index].occurences>=0){
                setTimeout(() => {
                   this.searchInContract({target:{value:document.getElementById('search').value}})
                }, 2000);
            }
        }else{
            documents[index].items[subDocumentIndex].show = !documents[index].items[subDocumentIndex].show
            documents[index].items[subDocumentIndex].edit = false
            addDocumentToTheRecentsList(documents[index].items[subDocumentIndex].id).then((response) => {
                console.log('Document added to the recents list')
              }, (error) => {
                ErrorAlert.show(error.response.data, "Erreur API");
              });
            this.setState({
                ...this.state,
                documents: documents,
                //pdfContent: documents[index].items[subDocumentIndex].show ? documents[index].items[subDocumentIndex].pdfContent : []
                currentDocument: documents[index].items[subDocumentIndex],
                search: {
                    currentOccurence: 0,
                    totalOccurence: 0
                }
            })
            setTimeout(() => {
                if(documents[index].items[subDocumentIndex].occurences && documents[index].items[subDocumentIndex].occurences>=0){
                    this.searchInContract({target:{value:document.getElementById('search').value}})
                }
            }, 2000);
            
        }
    }

    cancelAddedDocument = ()=>{
        this.setState({
            ...this.state,
            showFormAddDocument: false
        })
    }

    editDocument = (index, subDocumentIndex = false)=>{
        this.hideAllDocuments()
        let documents = this.state.documents;
        if( subDocumentIndex === false ){
            documents[index].originValue =  {...documents[index]};
            documents[index].edit = !documents[index].edit
            documents[index].show = true

            this.setState({
                ...this.state,
                documents: documents,
                currentDocument: documents[index],
                pdfContent: documents[index].show ? documents[index].pdfContent : [],
                formLinkedDoc: [
                    {name: "subject", txtLabel: t('frontoffice.contract_linked_docs.subject_doc'), value: null, originValue:documents[index]["subject"], edited: false},
                    {name: "sender", txtLabel: t('frontoffice.contract_linked_docs.name_sender'), value: null, originValue:documents[index]["sender"], edited: false},
                    {name: "date", txtLabel: t('frontoffice.contract_linked_docs.date_doc'), value: null, originValue:Moment(documents[index]["date"]).format('DD/MM/YYYY'), edited: false},
                    {name: "content", txtLabel: t('frontoffice.contract_linked_docs.comment'), value: null, originValue:documents[index]["content"], edited: false},
                    {name: "type", txtLabel: t('frontoffice.contract_linked_docs.received_sent'), value: null, originValue:documents[index]["type"] ? t('frontoffice.contract_linked_docs.received'):t('frontoffice.contract_linked_docs.sent'), edited: false}
                ],
                selectedIndexForEdit: {
                    doc: index,
                    subdoc: null
                }
            })
        }else{
            documents[index].items[subDocumentIndex].originValue =  {...documents[index].items[subDocumentIndex]};
            documents[index].items[subDocumentIndex].edit = !documents[index].items[subDocumentIndex].edit
            documents[index].items[subDocumentIndex].show = true

            this.setState({
                ...this.state,
                documents: documents,
                currentDocument: documents[index],
                pdfContent: documents[index].items[subDocumentIndex].show ? documents[index].items[subDocumentIndex].pdfContent : [],
                formLinkedDoc: [
                    {name: "subject", txtLabel: t('frontoffice.contract_linked_docs.subject_doc'), value: null, originValue:documents[index].items[subDocumentIndex]["subject"], edited: false},
                    {name: "sender", txtLabel: t('frontoffice.contract_linked_docs.name_sender'), value: null, originValue:documents[index].items[subDocumentIndex]["sender"], edited: false},
                    {name: "date", txtLabel: t('frontoffice.contract_linked_docs.date_doc'), value: null, originValue:Moment(documents[index].items[subDocumentIndex]["date"]).format('DD/MM/YYYY'), edited: false},
                    {name: "content", txtLabel: t('frontoffice.contract_linked_docs.comment'), value: null, originValue:documents[index].items[subDocumentIndex]["content"], edited: false},
                    {name: "type", txtLabel: t('frontoffice.contract_linked_docs.received_sent'), value: null, originValue:documents[index].items[subDocumentIndex]["type"] ? t('frontoffice.contract_linked_docs.received'):t('frontoffice.contract_linked_docs.sent'), edited: false}
                ],
                selectedIndexForEdit: {
                    doc: index,
                    subdoc: subDocumentIndex
                }
            })
        }
    }

    /*editDocument = (index)=>{
        this.hideAllDocuments()
        let documents = this.state.documents;
        documents[index].originValue =  {...documents[index]};
        documents[index].edit = !documents[index].edit
        documents[index].show = true

        this.setState({
            ...this,
            documents: documents,
            currentDocument: documents[index]
            //pdfContent: documents[index].show ? documents[index].pdfContent : null
        })
    }*/

    handleEditFormChangeInput = (e, index, subDocumentIndex = false)=>{

        let documents = this.state.documents;
        let currentDocument = this.state.currentDocument ? this.state.currentDocument : documents[index] ;
        if( subDocumentIndex === false && e.target.value !== undefined && e.target.value !== null &&  e.target.value !== null){
            documents[index][e.target.name] = e.target.value;
            currentDocument[e.target.name] = e.target.value;

            this.setState({
                ...this.state,
                documents: documents,
                currentDocument: currentDocument,
            })
        }else if(e.target.value !== undefined && e.target.value !== null &&  e.target.value !== null){
            console.log('TTTTTTTTTTtt')
            documents[index].items[subDocumentIndex][e.target.name] = e.target.value;
            currentDocument[e.target.name] = e.target.value;

            this.setState({
                ...this.state,
                documents: documents,
                currentDocument: currentDocument,
            })
        }

    }

    handleEditFormChangeDate = (dateValue, index, subDocumentIndex = false)=>{
        let documents = this.state.documents;
        if( subDocumentIndex === false ){
            documents[index].date = dateValue;
        }else{
            documents[index].items[subDocumentIndex].date = dateValue;
        }
        this.setState({
            ...this.state,
            documents: documents
        })
        this.setFormLinkedDoc({
            target:{
                name: "date",
                value: Moment(dateValue).format('DD/MM/YYYY')
            }
        },"edit")
    }

    saveEditedDocument = (index, subDocumentIndex = false)=>{

        let documents = this.state.documents;
        if( subDocumentIndex === false ){
            let formEditValidation = documents[index].formEditValidation;
            formEditValidation.validateFields();

            setTimeout(()=>{
                if( !formEditValidation.isValid() ) return;
                LoaderComponent.show();
            setNewDocumentData(documents[index]).then((response) => {
                getLinkedDocuments(this.state.contractId).then((response) => {
                    this.fetchDataAndUpdate(response.data).then(updatedArray => {
                        this.setState({
                            ...this,
                            documents: updatedArray,
                        }, () => {
                            LoaderComponent.hide();
                        })
                    })    
                });
             },  (error) => {
                ErrorAlert.show(error?.response?.data, "Erreur API");
                getLinkedDocuments(this.state.contractId).then((response) => {
                    this.fetchDataAndUpdate(response.data).then(updatedArray => {
                        this.setState({
                            ...this,
                            documents: updatedArray,
                        }, () => {
                            LoaderComponent.hide();
                        })
                    })
                });
              });
             this.resetTheDocumentsList()

                documents[index].edit = false;
                this.setState({
                    ...this.state,
                    documents: documents
                })
            })
        } else{
            let formEditValidation = documents[index].items[subDocumentIndex].formEditValidation;
            formEditValidation.validateFields();
            setTimeout(()=>{
                if( !formEditValidation.isValid() ) return;

                LoaderComponent.show();
            setNewDocumentData(documents[index].items[subDocumentIndex]).then((response) => {
                getLinkedDocuments(this.state.contractId).then((response) => {
                    this.fetchDataAndUpdate(response.data).then(updatedArray => {
                        this.setState({
                            ...this,
                            documents: updatedArray,
                        }, () => {
                            LoaderComponent.hide();
                        })
                    })
                });
             },  (error) => {
                ErrorAlert.show(error?.response?.data, "Erreur API");
                getLinkedDocuments(this.state.contractId).then((response) => {
                    this.fetchDataAndUpdate(response.data).then(updatedArray => {
                        this.setState({
                            ...this,
                            documents: updatedArray,
                        }, () => {
                            LoaderComponent.hide();
                        })
                    })
                });
              });
             this.resetTheDocumentsList()

                documents[index].items[subDocumentIndex].edit = false;
                this.setState({
                    ...this.state,
                    documents: documents
                })
            })
        }
    }


    cancelEdit = (index)=>{
        /*let documents = this.state.documents;
        documents[index] = documents[index].originValue;
        documents[index].edit = false
    cancelEdit = (index, subDocumentIndex = false)=>{
        let documents = this.state.documents;

        if( subDocumentIndex === false ){
            documents[index] = documents[index].originValue;
            documents[index].edit = false
        } else{
            documents[index].items[subDocumentIndex] = documents[index].items[subDocumentIndex].originValue;
            documents[index].items[subDocumentIndex].edit = false
        }
        this.setState({
            ...this.state,
            documents: documents
        })*/
        this.resetTheDocumentsList()
    }

    deleteDocument = (index, subDocumentIndex = false)=>{
       
        const currentDoc = subDocumentIndex === false ? this.state.documents[index]: this.state.documents[index].items[subDocumentIndex]
        
        ConfirmAlert.show("Voulez vous vraiment supprimer ce document ?").then(()=>{
            
            LoaderComponent.show();
            deleteDocumentWithFile(currentDoc?.hachedId).then((response) => {
                getLinkedDocuments(this.state.contractId).then((response) => {
                    this.fetchDataAndUpdate(response.data).then(updatedArray => {
                        this.setState({
                            ...this,
                            documents: updatedArray,
                        }, () => {
                            LoaderComponent.hide();
                        })
                    })
                });
             },  (error) => {
                ErrorAlert.show(error?.response?.data, "Erreur API");
                getLinkedDocuments(this.state.contractId).then((response) => {
                    this.fetchDataAndUpdate(response.data).then(updatedArray => {
                        this.setState({
                            ...this,
                            documents: updatedArray,
                        }, () => {
                            LoaderComponent.hide();
                        })
                    })
                });
              });
             this.resetTheDocumentsList()
        });
    }

    getSelection = (e)=>{
        this.selectedText = window.getSelection().toString().trim()
    }




    ///// Viewer
    downloadContract = ()=>{
        getCSVFile(this.state.contractInfo?.id);
    }
    printContract = ()=>{
        downloadContractFromS3Server(this.state.contractInfo?.id);
    }
    searchInContract = (e)=>{
       
            let documentsList = this.state.documents
            this.state.documents.forEach((element,index) => {
                if (element.pdfContent && element.pdfContent !== undefined) {
                    var count = (element.pdfContent.match(new RegExp(e.target.value, "g")) || []).length;
                    element.occurences = e.target.value !== undefined && e.target.value !== null &&  e.target.value !== '' ? count : 0
                    documentsList = Object.assign([...documentsList], {
                        [index]: element
                    });
                    this.setState({
                        ...this,
                        documents: documentsList,
                    })
                }else if(element.items && element.items.length>0){
                    element.items.forEach((subElement,subIndex) => {
                        if (subElement.pdfContent && subElement.pdfContent !== undefined) {
                            var count = (subElement.pdfContent.match(new RegExp(e.target.value, "g")) || []).length;
                            subElement.occurences = e.target.value !== undefined && e.target.value !== null &&  e.target.value !== '' ? count : 0
                            element[subIndex] = subElement
                            documentsList = Object.assign([...documentsList], {
                                [index]: element
                            });
                            this.setState({
                                ...this,
                                documents: documentsList,
                            })
                        }
                    });
                }
            });
        if (document.querySelector("#pdf-content") !== null){
            this.panPinchRef.resetZoom()
            let input =  e.target.value;
            if( input ){
                this.markInstance = new Mark(document.querySelector("#pdf-content"));
                this.markInstance.unmark({
                    done: (e) => {
                        this.markInstance.mark(input, {
                            separateWordSearch: false,
                            acrossElements: true,
                            done: (countResults)=>{
                                this.setState({
                                    ...this.state,
                                    search: {
                                        currentOccurence: countResults > 0 ? 1 : 0,
                                        totalOccurence: countResults
                                    }
                                })
                                this.setActiveSearchOccurent(0);
                            }
                        })
                    }
                });
            }else{
                if( this.markInstance ){
                   this.markInstance.unmark()
                }
                this.setState({
                    ...this.state,
                    search: {
                        currentOccurence: 0,
                        totalOccurence: 0
                    }
                })
    
            }
        }
    }
    resetSearch = ()=>{
        if( this.markInstance ) {
            this.markInstance.unmark();
            this.searchInput.value = "";
            this.setState({
                ...this.state,
                search: {
                    currentOccurence: 0,
                    totalOccurence: 0
                }
            })
        }
        return new Promise((resolve, reject)=>{
            setTimeout(()=>{
                resolve();
            })
        })
    }
    setActiveSearchOccurent = (index)=>{
         $('#pdf-content mark[data-markjs]').removeClass('active');
         let current = $($('#pdf-content mark[data-markjs]')[index]);
         current.addClass('active');
         let fieldPosition = index == 0 ? 0 : current?.parents('div.pf')?.position()?.top + current?.parents('div.t')?.position()?.top || 0
         $('.pdf-viewer').animate({
             scrollTop: fieldPosition
         }, 300);
     }
    prevFindedSearch = ()=>{
        let currentOccurence = this.state.search.currentOccurence;
        if(currentOccurence > 1){
            this.setState({
                ...this.state,
                search:{
                    ...this.state.search,
                    currentOccurence: currentOccurence - 1
                }
            }, ()=>{
                this.setActiveSearchOccurent(currentOccurence - 2);
            })
        }
    }
    nextFindedSearch = ()=>{
        let currentOccurence = this.state.search.currentOccurence;
        let totalOccurence = this.state.search.totalOccurence;
        if(currentOccurence < totalOccurence){
            this.setState({
                ...this.state,
                search:{
                    ...this.state.search,
                    currentOccurence: currentOccurence + 1
                }
            }, ()=>{
                this.setActiveSearchOccurent(currentOccurence);
            })
        }
    }

    highlightText = ()=>{
        this.resetSearch()
        this.resetSearch();
        const selection = window.getSelection();
        if (!selection.isCollapsed) {
            const range = selection.getRangeAt(0);
            highlightRange(range, 'span', {
                class: 'highlight-text'
            });
            this.hidePopover()
            this.updateDocumentsList();
            updateLinkedDocumentContent(this.state.currentDocument.id, document.querySelector("#pdf-content").innerHTML).then((response) => {
            });
        }
    }

    updateDocumentsList() {
        let documents = this.state.documents;
        let currentDocument = this.state.currentDocument;
        let i = 0;
        for(i = 0; i < documents.length; i++) {
            if(!documents[i].isGroup){
                if (documents[i].hachedId === currentDocument.hachedId) {
                    console.log('UPDATE: 1');
                    documents[i].pdfContent = document.querySelector("#pdf-content").innerHTML
                }
            }else{
                documents[i].items.map(subDocument =>{
                    if (subDocument.hachedId === currentDocument.hachedId) {
                        subDocument.pdfContent = document.querySelector("#pdf-content").innerHTML
                    }
                })
            }
        }
        this.setState({
            ...this,
            documents: documents,
        })
    }

    onTextSelect = ()=>{
        this.setState({
            ...this.state,
            isPopoverOpen: true
        })
    }
    onTextUnselect = ()=>{
        this.setState({
            ...this.state,
            isPopoverOpen: false
        })
    }

    hidePopover = ()=>{
        document.getSelection().empty()
    }

    handleScrollCtrl = (e) => {
        // Check if the Ctrl or Command key is pressed
        if (e.ctrlKey || e.metaKey) {
          // // Handle the (Ctrl or Command) + Scroll event
          // if(e.ctrlKey)
          //   console.log('Ctrl + Scroll Detected');
          // if(e.metaKey)
          //   console.log('Command + Scroll Detected');
          // Determine the scroll direction (up or down)
          const delta = e.deltaY > 0 ? -0.1 : 0.1;
          // Adjust the zoom : in and out
          if (delta > 0) {
            this.panPinchRef.zoomOut();
          } else {
            this.panPinchRef.zoomIn();
          }
        }
      };

    getUserDocumentContractsLinkedListTable = (selectedLinkedContract) => {
        if (selectedLinkedContract) {
            const columns = [
                { name: 'number', label: t('frontoffice.contract_linked_docs.contract_infos.contract_number')},
                { name: 'name', label: t('frontoffice.contract_linked_docs.contract_infos.contract_name')},
                { name: 'amount', label: t('frontoffice.contract_linked_docs.contract_infos.contract_amount') },
                { name: 'tier', label: t('frontoffice.contract_linked_docs.contract_infos.contract_tier')},
                { name: 'priorNoticeAlert', label: t('frontoffice.contract_linked_docs.contract_infos.contract_prior_notice')},
                { name: 'endDate', label: t('frontoffice.contract_linked_docs.contract_infos.contract_end_date')},
            ];

            return (
                <DataTable data={selectedLinkedContract || []} columns={columns} selectable={false} allRowsSortable={true}  />
            );
        }
        return '';
    };

    onSaveLinkedContracts = () =>{
        this.setState({
            ...this.state,
            isLinkedContractsModalOpen: false
        },()=>{
            let currentDocument = ((this.state.currentDocument && this.state.currentDocument.isGroup && this.state.currentDocument.items !== undefined && this.state.currentDocument.items.length>0 && this.state.currentDocument.subDocumentIndex !== undefined)) ? this.state.currentDocument.items[this.state.currentDocument.subDocumentIndex]:this.state.currentDocument;
            if(currentDocument.contractsToLink !== undefined && currentDocument.contractsToLink.length>0){
                const  selectedContractsToLinkedIdx = currentDocument.contractsToLink.map((item)=> item.id)
                linkDocumentToContract(currentDocument.id, selectedContractsToLinkedIdx).then(res=>{
                    toast.success('Contrats liés avec succès', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.setState({
                        ...this.state,
                        documents: [],
                        currentDocument: null
                    },()=>{
                        this.componentDidMount()
                    })
                }, err=>{
                    toast.error('Une erreur est survenue lors de la liaison de contrat', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            }
        })
        
    }

    onLinkExistsLinkedDocumentToContract = () =>{
        let currentDocument = this.state.existsLinkedDocument.find(doc=>doc.linked) || null;
        this.setState({
            ...this.state,
            isLinkedDocumentToContractsModalOpen: false
        },()=>{
            if(currentDocument !== null && currentDocument.id !== undefined){
                linkDocumentToContract(currentDocument.id, [this.state.contractInfo.id]).then(res=>{
                    toast.success('Document lié avec succès', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    this.setState({
                        ...this.state,
                        documents: [],
                        currentDocument: null
                    },()=>{
                        this.componentDidMount()
                    })
                }, err=>{
                    toast.error('Une erreur est survenue lors de la liaison de contrat', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            }
        })
    }

    onDoAction = (action) =>{
        console.log("action",action)
        this.setState({...this.state, isActionModalOpen:false},()=>{
            switch (action) {
                case "new":
                    this.props.history.push("/manage-contract/add-linked-document?contract="+this.state.contractId);
                    break;
                case "exists":
                    this.setState({...this.state, isLinkedDocumentToContractsModalOpen:true})
                    break;
                    case "contract":
                    this.setState({...this.state, isConvertContractsModalOpen:true})
                    break;
            
                default:
                    break;
            }
        })
    }

    onSaveLinkedContract = () =>{
        this.setState({
            ...this.state,
            isConvertContractsModalOpen: false
        },()=>{
            if(this.state.contractsByCategory !== undefined && this.state.contractsByCategory.length>0){
                const  selectedContract = this.state.contractsByCategory.find(c=>c.linked)
                if(selectedContract){
                  LoaderComponent.show();
                  converContractToLinkedDocument(this.state.contractInfo.id, selectedContract.id).then(res=>{
                      LoaderComponent.hide();
                      toast.success('Conversion en document lié effectué avec succès', {
                          position: toast.POSITION.TOP_RIGHT
                      });
                  }, err=>{
                      LoaderComponent.hide();
                      toast.error('Une erreur est survenue lors de la conversion de contrat', {
                          position: toast.POSITION.TOP_RIGHT
                      });
                  })
                }else{
                  toast.warn('Aucun contrat choisi', {
                    position: toast.POSITION.TOP_RIGHT
                  });
                }
            }
        })
        
    }

    setFormLinkedDoc = (e, action) =>{
        let name = e.target.name;
        let value = e.target.value;
        let formLinkedDoc = this.state.formLinkedDoc
        let findIndex = formLinkedDoc.findIndex(x=>x.name === name);
        if(findIndex>-1){
            formLinkedDoc[findIndex].value = value
            if(action === "edit"){
                formLinkedDoc[findIndex].edited = true
            }else{
                formLinkedDoc[findIndex].edited = false
            }
        }else{
            formLinkedDoc.push({
                name: name,
                value: value,
                isRequired: true
            })
        }
        this.setState({
            ...this.state,
            actionData: action,
            formLinkedDoc:formLinkedDoc
        })
    }

    doSave = () =>{
        if(this.state.actionData === "edit"){
            const selectedIndexForEdit = this.state.selectedIndexForEdit
            if(selectedIndexForEdit.doc !== null && selectedIndexForEdit.subdoc === null){
                //Edit main doc
                this.saveEditedDocument(selectedIndexForEdit.doc)
            }
            if(selectedIndexForEdit.doc !== null && selectedIndexForEdit.subdoc !== null){
                //Edit sub doc
                this.saveEditedDocument(selectedIndexForEdit.doc, selectedIndexForEdit.subdoc)
            }
        }
    }

    clearFormLinkedDoc = () =>{
        this.setState({
            ...this.state,
            actionData: "edit", // add | edit | delete
            formLinkedDoc: [
                {name: "subject", txtLabel: t('frontoffice.contract_linked_docs.subject_doc'), value: null, edited: false},
                {name: "sender", txtLabel: t('frontoffice.contract_linked_docs.name_sender'), value: null, edited: false},
                {name: "date", txtLabel: t('frontoffice.contract_linked_docs.date_doc'), value: null, edited: false},
                {name: "content", txtLabel: t('frontoffice.contract_linked_docs.comment'), value: null, edited: false},
                {name: "type", txtLabel: t('frontoffice.contract_linked_docs.received_sent'), value: null, edited: false}
            ],
            selectedIndexForEdit:{
                doc: null,
                subdoc: null
            }
        })
    }

    handleClose = () =>{
        this.setState({
            ...this.state,
            showResumeModal: false
        })
    }

    handleCancel = () =>{
        window.location.reload();
    }

    render() {

        return (
            <div className="linkeddocuments-page-style" onWheel={this.handleScrollCtrl}>
                <div className="app-container manage-contract documents-contract  app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
                    <Header />

                    <div className="app-main">
                        <div className="app-sidebar sidebar-shadow">
                            <Sidebar />
                        </div>
                        <div className="app-main__outer">
                            <EditMode
                                isEditMode={this.state.editMode}
                                onChange={(checked)=>{
                                    this.changeEditMode(checked)
                                }}
                                isContractPage={true}
                            />
                            <div className="app-main__inner">

                                <div className="row">
                                    <ToastContainer className="toast-styled-contract"/>
                                    <div className="col-md-5 left-side">

                                        <div className="sub-menu">
                                            {this.state.contract?.status !== null && (
                                                <FoManageContract
                                                    activeMenu="linked-documents"
                                                    contractStatus="2"
                                                    inNegociationBy={this.state.contractInfo?.inNegociationBy}
                                                />
                                            )}
                                        </div>
                                        <div className="head d-flex m-auto">
                                            {/* <button disabled={!this.state.editMode} className="btn btn-light btn-add m-auto">
                                                {this.state.editMode ? (
                                                    <Link to={"/manage-contract/add-linked-document?contract="+this.state.contractId}>
                                                        Ajouter
                                                    </Link>
                                                ) : (
                                                    'Ajouter'
                                                )}
                                            </button> */}
                                            <button disabled={!this.state.editMode} onClick={() => this.setState({...this.state, isActionModalOpen:true})}  className="btn btn-light btn-add m-auto">
                                                {t("common.add")}
                                            </button>
                                        </div>
                                        {
                                            this.state.documents.length > 0 &&
                                            <ul>
                                                <li><button onClick={()=>{this.showAllDocuments()}} className="btn btn-default">{t("common.expand_all")}</button></li>
                                                <li><button onClick={()=>{this.hideAllDocuments()}} className="btn btn-default">{t("common.retract_all")}</button></li>
                                            </ul>
                                        }
                                        <div className="scrolling-verticaly">
                                            <div className="documents">
                                            {/* //////////////////////// Documents list ////////////////////////////// */}
                                            { this.state.documents && this.state.documents.length > 0 && this.state.documents.map((document, index) =>{
                                                document.formEdit = {}
                                                return(
                                                    <div>
                                                        {
                                                            !document.isGroup &&
                                                            <div className={`${document?.show ? 'selectedDocument' : ''}`}>
                                                                <div className={`document ${document?.show ? 'show' : ''}`} >
                                                                    <div className="document-head d-flex">
                                                                        <div className="document-title" onClick={()=>{this.showDocument(index)}}>
                                                                            {
                                                                                document.type == 0 &&
                                                                                <h3>
                                                                                    {t('frontoffice.contract_linked_docs.outgoing_mail')}
                                                                                    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M12.75 6.25V1.75L23.25 9.25L12.75 16.75V12.25C8.5875 12.25 4.7625 12.4 1.5 18.25C1.5 14.5375 1.875 6.25 12.75 6.25Z" stroke="#27AE60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                    </svg>
                                                                                </h3>
                                                                            }
                                                                            {
                                                                                document.type == 1 &&
                                                                                <h3>
                                                                                    {t('frontoffice.contract_linked_docs.incoming_mail')}
                                                                                    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M12.25 6.25V1.75L1.75 9.25L12.25 16.75V12.25C16.4125 12.25 20.2375 12.4 23.5 18.25C23.5 14.5375 23.125 6.25 12.25 6.25Z" stroke="#EB3A59" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                    </svg>
                                                                                </h3>
                                                                            }
                                                                            <p>{ Moment(document.date).format('DD/MM/YYYY') }</p>
                                                                            <ul className="infos">
                                                                                <li>
                                                                                    <span>{t('frontoffice.contract_linked_docs.sender')} :</span> { document.sender }
                                                                                </li>
                                                                                <li>
                                                                                    <span>{t('frontoffice.contract_linked_docs.subject')} :</span> { document.subject }
                                                                                </li>
                                                                            </ul>
                                                                            {document && document.occurences !== undefined && document.occurences !== null && document.occurences>=0 && <span className='search-zone'><strong>{document.occurences}</strong> occurence(s) trouvé(s)</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="document-body">
                                                                        <h4>{t('frontoffice.contract_linked_docs.details')}</h4>
                                                                        <p>
                                                                            {document.content}
                                                                        </p>
                                                                    </div>

                                                                    {
                                                                        this.state.editMode &&
                                                                        <button type="button" className="btn btn-default btn-edit" onClick={()=>{this.editDocument(index)}}>
                                                                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M18.0156 6.98339L18.3012 6.69771C19.4054 5.59321 19.121 4.08827 18.0156 2.98377C16.9101 1.87928 15.4046 1.59328 14.3013 2.69839L14.0156 2.98407" stroke="#F66031" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                <path d="M16.2662 8.73364L18.0168 6.98339L14.0166 2.98376L12.2666 4.73371" stroke="#F66031" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                <path d="M2.23073 14.7695L6.23005 18.7691M6.23005 18.7691L16.2661 8.73363L12.2665 4.7337L2.23073 14.7695L0.999023 20.0014L6.23005 18.7691Z" stroke="#F66031" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                            </svg>
                                                                        </button>
                                                                    }
                                                                </div>

                                                                {/* //////////////////////// form edit document ////////////////////////////// */}
                                                                {
                                                                    this.state.editMode && document?.edit &&

                                                                    <FormWithConstraints
                                                                        ref={form => document.formEditValidation = form}
                                                                        className='document edit-document'
                                                                        noValidate>

                                                                        <div className="document-head d-flex">
                                                                            <div className="document-title">
                                                                                <p>
                                                                                    <input type="text" name="subject" className="form-control" placeholder={t('frontoffice.contract_linked_docs.subject_doc')} value={document.subject} onChange={(e)=>{this.setFormLinkedDoc(e, "edit");this.handleEditFormChangeInput(e, index)}} required></input>
                                                                                    <FieldFeedbacks for="subject">
                                                                                        <FieldFeedback when="*" />
                                                                                    </FieldFeedbacks>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="document-body">
                                                                            <p>
                                                                                <input  type="text" name="sender" className="form-control" placeholder={t('frontoffice.contract_linked_docs.name_sender')} value={document.sender} onChange={(e)=>{this.setFormLinkedDoc(e, "edit");this.handleEditFormChangeInput(e, index)}} required></input>
                                                                                <FieldFeedbacks for="sender">
                                                                                    <FieldFeedback when="*" />
                                                                                </FieldFeedbacks>
                                                                            </p>
                                                                            <p>
                                                                                <label>{t('frontoffice.contract_linked_docs.date_doc')} </label>
                                                                                <DatePicker  locale="fr" dateFormat="dd/MM/yyyy" className="form-control" autoComplete="off" name="date" selected={new Date(document.date)} onChange={(dateValue)=>{this.handleEditFormChangeDate(dateValue,index)}} placeholderText={t('frontoffice.contract_linked_docs.date_doc')} required/>
                                                                                <FieldFeedbacks for="date">
                                                                                    <FieldFeedback when="*" />
                                                                                </FieldFeedbacks>
                                                                            </p>
                                                                            <p>
                                                                                <textarea name="content" className="form-control" rows="4" value={document.content} onChange={(e)=>{this.setFormLinkedDoc(e, "edit");this.handleEditFormChangeInput(e, index)}} placeholder={t('frontoffice.contract_linked_docs.your_comment')}></textarea>
                                                                                <FieldFeedbacks for="content">
                                                                                    <FieldFeedback when="*" />
                                                                                </FieldFeedbacks>
                                                                            </p>
                                                                            <p>
                                                                                <div className="switcher-btn doccument-type">
                                                                                    <span>{t('frontoffice.contract_linked_docs.received')}</span>
                                                                                    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M12.25 6.25V1.75L1.75 9.25L12.25 16.75V12.25C16.4125 12.25 20.2375 12.4 23.5 18.25C23.5 14.5375 23.125 6.25 12.25 6.25Z" stroke="#EB3A59" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                    </svg>
                                                                                    <BootstrapSwitchButton
                                                                                        onChange={(checked)=>{
                                                                                            this.setFormLinkedDoc({
                                                                                                target: {
                                                                                                    name: 'type',
                                                                                                    value: checked ? t('frontoffice.contract_linked_docs.sent') : t('frontoffice.contract_linked_docs.received')
                                                                                                }
                                                                                            }, "edit");
                                                                                            this.handleEditFormChangeInput({
                                                                                            target:{
                                                                                                name: 'type',
                                                                                                value: checked ? 'out' : 'in'
                                                                                            }
                                                                                        }, index)
                                                                                    }}
                                                                                    checked={document.type == 0}
                                                                                    />
                                                                                    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M12.75 6.25V1.75L23.25 9.25L12.75 16.75V12.25C8.5875 12.25 4.7625 12.4 1.5 18.25C1.5 14.5375 1.875 6.25 12.75 6.25Z" stroke="#27AE60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                    </svg>
                                                                                    <span>{t('frontoffice.contract_linked_docs.sent')}</span>
                                                                                </div>
                                                                            </p>
                                                                            <p className="linked-contracts">
                                                                                <label>{t('frontoffice.contract_linked_docs.linked_contracts')}</label>
                                                                            </p>
                                                                            {this.getUserDocumentContractsLinkedListTable(document.linkedContracts || [])}
                                                                            <p>
                                                                                <a className="btn btn-new-link" onClick={() => this.setState({...this.state, isLinkedContractsModalOpen:true})}>
                                                                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                         <g clip-path="url(#clip0_8069_32014)">
                                                                                            <rect width="26" height="26" rx="13" fill="white" />
                                                                                            <path d="M16.5 13H9.5M13 9.5V16.5V9.5Z" stroke="#F66031" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_8069_32014">
                                                                                                <rect width="26" height="26" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                    <span>
                                                                                        {t('frontoffice.contract_linked_docs.link')} 
                                                                                    </span>
                                                                                </a>
                                                                            </p>
                                                                        </div>
                                                                        <div className="submit-actions">
                                                                            <button title={t('common.save')} type="button" className="btn btn-default btn-save" onClick={()=>{this.saveEditedDocument(index)}}>
                                                                                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M1 5.375L5.125 9.5L13 1.25" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                </svg>
                                                                            </button>
                                                                            <button title={t('common.cancel')} type="button" className="btn btn-default btn-cancel"  onClick={()=>{(this.state.formLinkedDoc && this.state.formLinkedDoc.some(ld=>ld.edited)) ? this.setState({...this.state, showResumeModal:true}):this.cancelEdit(index)}}>
                                                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M9.75 9.75075L1.50037 1.5M9.75 1.50075L1.5 9.75075L9.75 1.50075Z" stroke="#7077A5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                </svg>
                                                                            </button>
                                                                            <button title={t('common.delete')} type="button" className="btn btn-default btn-delete"  onClick={()=>{this.deleteDocument(index)}}>
                                                                                <i className="fa fa-trash"></i>
                                                                            </button>

                                                                        </div>
                                                                    </FormWithConstraints>
                                                                }
                                                            </div>
                                                        }

                                                        {
                                                            document.isGroup &&
                                                            <div className="grouped-documents">
                                                                {document.items?.map((subDocument, subDocumentIndex)=>{
                                                                    return(
                                                                        <div className={`${subDocument?.show ? 'selectedDocument' : ''}`}>
                                                                            <div className={`document ${subDocument?.show ? 'show' : ''}`} >
                                                                                <div className="document-head d-flex">
                                                                                    <div className="document-title" onClick={()=>{this.showDocument(index, subDocumentIndex)}}>
                                                                                        {
                                                                                            subDocument.type == 0 &&
                                                                                            <h3>
                                                                                                {t('frontoffice.contract_linked_docs.outgoing_mail')}
                                                                                                <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M12.75 6.25V1.75L23.25 9.25L12.75 16.75V12.25C8.5875 12.25 4.7625 12.4 1.5 18.25C1.5 14.5375 1.875 6.25 12.75 6.25Z" stroke="#27AE60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                                </svg>
                                                                                            </h3>
                                                                                        }
                                                                                        {
                                                                                            subDocument.type == 1 &&
                                                                                            <h3>
                                                                                                {t('frontoffice.contract_linked_docs.incoming_mail')}
                                                                                                <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M12.25 6.25V1.75L1.75 9.25L12.25 16.75V12.25C16.4125 12.25 20.2375 12.4 23.5 18.25C23.5 14.5375 23.125 6.25 12.25 6.25Z" stroke="#EB3A59" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                                </svg>
                                                                                            </h3>
                                                                                        }
                                                                                        <p>{ Moment(subDocument.date).format('DD/MM/YYYY') }</p>
                                                                                        <ul className="infos">
                                                                                            <li>
                                                                                                <span>{t('frontoffice.contract_linked_docs.sender')}  :</span> { subDocument.sender }
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>{t('frontoffice.contract_linked_docs.subject')}  :</span> { subDocument.subject }
                                                                                            </li>
                                                                                        </ul>
                                                                                        {subDocument && subDocument.occurences !== undefined && subDocument.occurences !== null && subDocument.occurences>=0 && <span className='search-zone'><strong>{subDocument.occurences}</strong> occurence(s) trouvé(s)</span>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="document-body">
                                                                                    <h4>{t('frontoffice.contract_linked_docs.details')} </h4>
                                                                                    <p>
                                                                                        {subDocument.content}
                                                                                    </p>
                                                                                </div>

                                                                                {
                                                                                    this.state.editMode &&
                                                                                    <button type="button" className="btn btn-default btn-edit" onClick={()=>{this.editDocument(index, subDocumentIndex)}}>
                                                                                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M18.0156 6.98339L18.3012 6.69771C19.4054 5.59321 19.121 4.08827 18.0156 2.98377C16.9101 1.87928 15.4046 1.59328 14.3013 2.69839L14.0156 2.98407" stroke="#F66031" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            <path d="M16.2662 8.73364L18.0168 6.98339L14.0166 2.98376L12.2666 4.73371" stroke="#F66031" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            <path d="M2.23073 14.7695L6.23005 18.7691M6.23005 18.7691L16.2661 8.73363L12.2665 4.7337L2.23073 14.7695L0.999023 20.0014L6.23005 18.7691Z" stroke="#F66031" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                        </svg>
                                                                                    </button>
                                                                                }
                                                                            </div>

                                                                            {/* //////////////////////// form edit Sub document ////////////////////////////// */}
                                                                            {
                                                                                this.state.editMode && subDocument?.edit &&

                                                                                <FormWithConstraints
                                                                                    ref={form => subDocument.formEditValidation = form}
                                                                                    className='document edit-document'
                                                                                    noValidate>

                                                                                    <div className="document-head d-flex">
                                                                                        <div className="document-title">
                                                                                            <p>
                                                                                                <input type="text" name="subject" className="form-control" placeholder={t('frontoffice.contract_linked_docs.subject_doc')}  value={subDocument.subject} onChange={(e)=>{this.setFormLinkedDoc(e, "edit");this.handleEditFormChangeInput(e, index, subDocumentIndex)}} required></input>
                                                                                                <FieldFeedbacks for="subject">
                                                                                                    <FieldFeedback when="*" />
                                                                                                </FieldFeedbacks>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="document-body">
                                                                                        <p>
                                                                                            <input  type="text" name="sender" className="form-control" placeholder={t('frontoffice.contract_linked_docs.name_sender')} value={subDocument.sender} onChange={(e)=>{this.setFormLinkedDoc(e, "edit");this.handleEditFormChangeInput(e, index, subDocumentIndex)}} required></input>
                                                                                            <FieldFeedbacks for="sender">
                                                                                                <FieldFeedback when="*" />
                                                                                            </FieldFeedbacks>
                                                                                        </p>
                                                                                        <p>
                                                                                            <label>{t('frontoffice.contract_linked_docs.date_doc')} </label>
                                                                                            <DatePicker  locale="fr" dateFormat="dd/MM/yyyy" className="form-control" autoComplete="off" name="date" selected={new Date(subDocument.date)} onChange={(dateValue)=>{this.handleEditFormChangeDate(dateValue,index, subDocumentIndex)}} placeholderText={t('frontoffice.contract_linked_docs.date_doc')} required/>
                                                                                            <FieldFeedbacks for="date">
                                                                                                <FieldFeedback when="*" />
                                                                                            </FieldFeedbacks>
                                                                                        </p>
                                                                                        <p>
                                                                                            <textarea name="content" className="form-control" rows="4" value={subDocument.content} onChange={(e)=>{this.setFormLinkedDoc(e, "edit");this.handleEditFormChangeInput(e, index, subDocumentIndex)}} placeholder={t('frontoffice.contract_linked_docs.your_comment')}></textarea>
                                                                                            <FieldFeedbacks for="content">
                                                                                                <FieldFeedback when="*" />
                                                                                            </FieldFeedbacks>
                                                                                        </p>
                                                                                        <p>
                                                                                            <div className="switcher-btn doccument-type">
                                                                                                <span>{t('frontoffice.contract_linked_docs.received')}</span>
                                                                                                <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M12.25 6.25V1.75L1.75 9.25L12.25 16.75V12.25C16.4125 12.25 20.2375 12.4 23.5 18.25C23.5 14.5375 23.125 6.25 12.25 6.25Z" stroke="#EB3A59" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                                </svg>
                                                                                                <BootstrapSwitchButton
                                                                                                onChange={(checked) => {
                                                                                                    this.setFormLinkedDoc({
                                                                                                        target: {
                                                                                                            name: 'type',
                                                                                                            value: checked ? t('frontoffice.contract_linked_docs.sent') : t('frontoffice.contract_linked_docs.received')
                                                                                                        }
                                                                                                    }, "edit");
                                                                                                    this.handleEditFormChangeInput({
                                                                                                        target: {
                                                                                                            name: 'type',
                                                                                                            value: checked ? 'out' : 'in'
                                                                                                        }
                                                                                                    }, index, subDocumentIndex)
                                                                                                }}
                                                                                                checked={subDocument.type == 0}
                                                                                                />
                                                                                                <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M12.75 6.25V1.75L23.25 9.25L12.75 16.75V12.25C8.5875 12.25 4.7625 12.4 1.5 18.25C1.5 14.5375 1.875 6.25 12.75 6.25Z" stroke="#27AE60" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                                </svg>
                                                                                                <span>{t('frontoffice.contract_linked_docs.sent')}</span>
                                                                                            </div>
                                                                                        </p>
                                                                                        <p className="linked-contracts">
                                                                                            <label>{t('frontoffice.contract_linked_docs.linked_contracts')}</label>
                                                                                        </p>
                                                                                        {this.getUserDocumentContractsLinkedListTable(subDocument.linkedContracts || [])}
                                                                                        <p>
                                                                                            <a className="btn btn-new-link" onClick={() => this.setState({...this.state, isLinkedContractsModalOpen:true, currentDocument:{...this.state.currentDocument, subDocumentIndex:subDocumentIndex}})}>
                                                                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <g clip-path="url(#clip0_8069_32014)">
                                                                                                        <rect width="26" height="26" rx="13" fill="white" />
                                                                                                        <path d="M16.5 13H9.5M13 9.5V16.5V9.5Z" stroke="#F66031" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </g>
                                                                                                    <defs>
                                                                                                        <clipPath id="clip0_8069_32014">
                                                                                                            <rect width="26" height="26" fill="white" />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                </svg>
                                                                                                <span>
                                                                                                    {t('frontoffice.contract_linked_docs.link')}
                                                                                                </span>
                                                                                            </a>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="submit-actions">
                                                                                        <button title={t('common.save')} type="button" className="btn btn-default btn-save" onClick={()=>{this.saveEditedDocument(index, subDocumentIndex)}}>
                                                                                            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M1 5.375L5.125 9.5L13 1.25" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            </svg>
                                                                                        </button>
                                                                                        <button title={t('common.cancel')} type="button" className="btn btn-default btn-cancel"  onClick={()=>{(this.state.formLinkedDoc && this.state.formLinkedDoc.some(ld=>ld.edited)) ? this.setState({...this.state, showResumeModal:true}):this.cancelEdit(index, subDocumentIndex)}}>
                                                                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M9.75 9.75075L1.50037 1.5M9.75 1.50075L1.5 9.75075L9.75 1.50075Z" stroke="#7077A5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                            </svg>
                                                                                        </button>
                                                                                        <button title={t('common.delete')} type="button" className="btn btn-default btn-delete"  onClick={()=>{this.deleteDocument(index, subDocumentIndex)}}>
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </button>

                                                                                    </div>
                                                                                </FormWithConstraints>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        }

                                                    </div>
                                                )
                                            }) }
                                        </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 viewer">
                                        {
                                            this.state?.contractInfo && //TO SEE
                                            <div>
                                                <h1>{this.state.contractInfo?.name}</h1>
                                                <ControlPanel
                                                    isExport={true}
                                                    fileName={this.state.contractInfo?.originalFileName}
                                                    onPressDownload={this.downloadContract}
                                                    onPressPrint={this.printContract}

                                                    onPressZoomOut={()=>{this.panPinchRef.zoomOut()}}
                                                    onPressZoomIn={()=>{this.panPinchRef.zoomIn()}}

                                                    onChangeSearchInput={(e)=>{this.searchInContract(e)}}
                                                    refInput={(input)=>{this.searchInput = input}}
                                                    onCleanSearch={this.resetSearch}

                                                    onPressPrevResult={this.prevFindedSearch}
                                                    currentResult={this.state.search.currentOccurence}
                                                    totalResults={this.state.search.totalOccurence}
                                                    onPressNextResult={this.nextFindedSearch}
                                                />
                                                {this.state?.currentDocument !== null && this.state?.currentDocument !== undefined && (this.state?.currentDocument?.pdfContent || this.state?.pdfContent) &&
                                                <div className="pdf-viewer d-flex">
                                                    <div className="pdf-content" id="pdf-content" ref={this.pdfContent}>

                                                        <ZoomContent ref={(ref)=>{this.panPinchRef = ref}}>
                                                            <div
                                                                className="page"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: this.state?.currentDocument?.pdfContent?.replace(/\\r\\n/g, "") || this.state?.pdfContent?.replace(/\\r\\n/g, ""),
                                                                }}
                                                            />
                                                        </ZoomContent>

                                                        {
                                                            this.state.editMode &&
                                                            <Popover
                                                                className="comments-popover-container"
                                                                isOpen={this.state.isPopoverOpen}
                                                                onTextSelect={() => this.onTextSelect()}
                                                                onTextUnselect={() => this.onTextUnselect()}
                                                                selectionRef={this.pdfContent}>
                                                                <ul className="popover-options">
                                                                    <li>
                                                                        <button className="btn btn-default btn-marker" title="Surligner" onClick={()=>{this.highlightText()}}>
                                                                            <svg width="94" height="105" viewBox="0 0 94 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M19.5419 87.25L86.6252 20.6458L73.25 7.36616L6.16665 73.9703V87.25H19.5419ZM0.333313 90.1458V72.7708C0.333313 72.0028 0.640604 71.2662 1.18759 70.7232L71.1876 1.22317C72.3266 0.0922767 74.1733 0.0922767 75.3124 1.22317L92.8124 18.5982C93.9514 19.7291 93.9514 21.5626 92.8124 22.6935L22.8124 92.1935C22.2654 92.7366 21.5235 93.0417 20.75 93.0417H3.24998C1.63915 93.0417 0.333313 91.7452 0.333313 90.1458ZM3.24998 104.625C1.63915 104.625 0.333313 103.328 0.333313 101.729C0.333313 100.13 1.63915 98.8333 3.24998 98.8333H61.6238C63.2346 98.8333 64.5405 100.13 64.5405 101.729C64.5405 103.328 63.2346 104.625 61.6238 104.625H3.24998Z" fill="#FF8A00"/>
                                                                            </svg>
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </Popover>
                                                        }
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContractsModal 
                    isOpen={this.state.isLinkedContractsModalOpen} 
                    onRequestClose={() => this.setState({...this.state, isLinkedContractsModalOpen:false})} 
                    contracts={this.state.contracts || []} 
                    setContracts={(values)=>{
                        if(this.state.currentDocument.isGroup && this.state.currentDocument.items !== undefined && this.state.currentDocument.items.length>0 && this.state.currentDocument.subDocumentIndex !== undefined){
                            let currentDocument = this.state.currentDocument
                            currentDocument.items[currentDocument.subDocumentIndex].contractsToLink = values.filter(v=>v.linked)
                            this.setState({...this.state, currentDocument:currentDocument})
                        }else{
                            this.setState({...this.state, currentDocument:{...this.state.currentDocument, contractsToLink:values.filter(v=>v.linked)}})
                        }
                    }} 
                    currentDocument={((this.state.currentDocument && this.state.currentDocument.isGroup && this.state.currentDocument.items !== undefined && this.state.currentDocument.items.length>0 && this.state.currentDocument.subDocumentIndex !== undefined)) ? this.state.currentDocument.items[this.state.currentDocument.subDocumentIndex]:this.state.currentDocument}
                    onSaveLinkedContracts={this.onSaveLinkedContracts} />
                    
                <LinkedDocumentsModal 
                    isOpen={this.state.isLinkedDocumentToContractsModalOpen} 
                    onRequestClose={() => this.setState({...this.state, isLinkedDocumentToContractsModalOpen:false})} 
                    existsLinkedDocument={this.state.existsLinkedDocument || []} 
                    setExistsLinkedDocument={(values)=>this.setState({...this.state, existsLinkedDocument:values})} 
                    currentDocument={this.state.currentDocument}
                    onLinkExistsLinkedDocumentToContract={this.onLinkExistsLinkedDocumentToContract} />

                <ControlModal
                    isOpen={this.state.isActionModalOpen} 
                    onRequestClose={() => this.setState({...this.state, isActionModalOpen:false})} 
                    onDoAction={this.onDoAction} 
                />

                <ContractsConvertModal
                    isOpen={this.state.isConvertContractsModalOpen}
                    onRequestClose={() => this.setState({ ...this.state, isConvertContractsModalOpen: false })}
                    contracts={this.state.contractsByCategory || []}
                    setContracts={(values) => { this.setState({ ...this.state, contractsByCategory: values }) }}
                    onSaveLinkedContract={this.onSaveLinkedContract} />

                
                <ResumeDataModal title={"Confirmez vos données saisie"}
                    isOpen={this.state.showResumeModal}
                    onRequestClose={() => this.handleClose()}
                    onRequestCancel={() => this.handleCancel()}
                    requiredFields={this.state.actionData === "add" ? this.state.formLinkedDoc.length : 0}
                    doSave={() => this.doSave()}
                    fields={this.state.actionData === "add" ? this.state.formLinkedDoc : this.state.formLinkedDoc.filter(x => x.edited)}
                    action={this.state.actionData}
                />
            </div>
        );
    }
}

//export default LinkedDocuments;
export default withTranslation()(withRouter(LinkedDocuments))
