import React, { useEffect, useState } from 'react';

import { t } from 'i18next';

import { withTranslation } from 'react-i18next';
import Modal from '../../../../../components/Modal';
import "./PassGeneratorModal.scss"
import { CopyIcon } from '../../assets/icons';


const PassGeneratorModal = ({ isOpen, userPasswordConfiguration: initialData, onRequestClose, onGetCopiedPass }) => {

  const [length, setLength] = useState(6);
  const [includeLowercase, setIncludeLowercase] = useState(false);
  const [includeUppercase, setIncludeUppercase] = useState(false);
  const [includeNumbers, setIncludeNumbers] = useState(false);
  const [includeSpecialChars, setIncludeSpecialChars] = useState(false);
  const [password, setPassword] = useState('');
  const [msgError, setMsgError] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setLength(initialData !== null ? initialData.length :length);
    setIncludeLowercase(initialData !== null ? initialData.containsSimilarCharacters :includeLowercase);
    setIncludeUppercase(initialData !== null ? initialData.containsLetters :includeUppercase);
    setIncludeNumbers(initialData !== null ? initialData.containsNumbers :includeNumbers);
    setIncludeSpecialChars(initialData !== null ? initialData.containssymbols :includeSpecialChars);
  }, [initialData])
  

  const handleGeneratePassword = () => {
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numberChars = '0123456789';
    const specialChars = '!@#$%^&*()_+[]{}|;:,.<>?';

    let characterPool = '';
    let guaranteedChars = '';

    if (includeLowercase) {
      characterPool += lowercaseChars;
      guaranteedChars += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
    }
    if (includeUppercase) {
      characterPool += uppercaseChars;
      guaranteedChars += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
    }
    if (includeNumbers) {
      characterPool += numberChars;
      guaranteedChars += numberChars[Math.floor(Math.random() * numberChars.length)];
    }
    if (includeSpecialChars) {
      characterPool += specialChars;
      guaranteedChars += specialChars[Math.floor(Math.random() * specialChars.length)];
    }

    if (characterPool.length === 0 && length < 6) {
      setMsgError(t("password_generator.modal_generator.error_pass_rule"));
    } else if (length < 6) {
      setMsgError(t("password_generator.modal_generator.error_pass_length"));
    } else if (characterPool.length === 0) {
      setMsgError(t("password_generator.modal_generator.error_rules_chars"));
    } else {
      setMsgError('');
      let generatedPassword = guaranteedChars;

      for (let i = guaranteedChars.length; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characterPool.length);
        generatedPassword += characterPool[randomIndex];
      }

      // Shuffle the generated password to ensure the guaranteed characters are not at the start
      setPassword(shuffleString(generatedPassword));
    }

  };

  const copyToClipboard = () => {
    // Create a temporary textarea element
    const tempTextArea = document.createElement('textarea');
    // Assign the text to be copied
    tempTextArea.value = password;
    // Append the textarea to the document
    document.body.appendChild(tempTextArea);
    // Select the text
    tempTextArea.select();
    // Copy the text to the clipboard
    document.execCommand('copy');
    // Remove the temporary textarea
    document.body.removeChild(tempTextArea);
    // Set copied to true
    setCopied(true);
    onGetCopiedPass(password)
    // Reset copied state after a short delay
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  const shuffleString = (string) => {
    const array = string.split('');
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array.join('');
  };

  return (
    <Modal
      title={t("password_generator.modal_generator.title")}
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(false)}
      isFullScreen={false}
      footer={
        <>
          <button className='m-auto' onClick={() => onRequestClose(false)}>{t("common.close")}</button>
        </>
      }
    >

      <div className="pass-generator">
        <div>
          <label className="rule-range">
            <span>{t("password_generator.modal_generator.password_length")}</span>
            <input
              className="form-control"
              type="number"
              min="6"
              value={length}
              onChange={(e) => setLength(e.target.value)}
            />
          </label>
        </div>
        <div className="rule-item">
          <label className={includeLowercase ? "selected-label" : ""}>
            <input
              disabled={initialData !== null && initialData.containsSimilarCharacters}
              className="check-rule"
              type="checkbox"
              checked={includeLowercase}
              onChange={(e) => setIncludeLowercase(e.target.checked)}
            />
            <span>{t("password_generator.modal_generator.includes_lowercase")}</span>
          </label>
        </div>
        <div className="rule-item">
          <label className={includeUppercase ? "selected-label" : ""}>
            <input
              disabled={initialData !== null && initialData.containsLetters}
              className="check-rule"
              type="checkbox"
              checked={includeUppercase}
              onChange={(e) => setIncludeUppercase(e.target.checked)}
            />
            <span>{t("password_generator.modal_generator.includes_uppercase")}</span>
          </label>
        </div>
        <div className="rule-item">
          <label className={includeNumbers ? "selected-label" : ""}>
            <input
              disabled={initialData !== null && initialData.containsNumbers}
              className="check-rule"
              type="checkbox"
              checked={includeNumbers}
              onChange={(e) => setIncludeNumbers(e.target.checked)}
            />
            <span>{t("password_generator.modal_generator.includes_numbers")}</span>
          </label>
        </div>
        <div className="rule-item">
          <label className={includeSpecialChars ? "selected-label" : ""}>
            <input
              disabled={initialData !== null && initialData.containssymbols}
              className="check-rule"
              type="checkbox"
              checked={includeSpecialChars}
              onChange={(e) => setIncludeSpecialChars(e.target.checked)}
            />
            <span>{t("password_generator.modal_generator.includes_sp_chars")}</span>
          </label>
        </div>
        <div className="rule-btn">
          <button className="btn btn-primary" onClick={handleGeneratePassword}><i className="fa fa-retweet mr-1" aria-hidden="true"></i> {t("password_generator.modal_generator.generate_action")}</button>
        </div>
        {
          msgError !== "" && (
            <div className="error-msg">
              {msgError}
            </div>
          )
        }
        {msgError === "" && password && (
          <div className="pass-generated-msg">
            <h4>{t("password_generator.modal_generator.generated_password")}</h4>
            <p title="Copier" onClick={copyToClipboard}>
              <span className='mr-1'>{password}</span>
              <CopyIcon />

            </p>
            {copied && <p className="text-copied">{t("password_generator.modal_generator.pass_copied")}</p>}
          </div>
        )}
      </div>

    </Modal>
  );
};

export default withTranslation()(PassGeneratorModal);
