import React from "react";
import { CibleIcon } from "../../../assets/icons";
const ButtonCible = ({ onPress, disabled, cibleUsed=false  }) => (
  <button
    type="button"
    className={`btn btn-default button_cible  ${cibleUsed ? ' cible_used':''}`}
    disabled={disabled}
    onClick={onPress}
  >
    <CibleIcon />
  </button>
);

export default ButtonCible;
