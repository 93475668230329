import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ReactDOM from "react-dom";

//i18n
import './i18n';

import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import DataProvider from "../src/DataManagement/DataProvider";
import {
  isAdmin,
  isUser,
  isClientAdmin,
  isAdminClientOrUser,
  isNewGuest,
} from "../src/api/QueryAuthParameters";
// Pages
// Auth
import Login from "./pages/auth/login/Login";
import ForgotPassword from "./pages/auth/forgot-password/ForgotPassword";
import ResetPassword from "./pages/auth/reset-password/ResetPassword";
import UnconnectUser from "./pages/auth/unconnect/UnconnectUser";

// Front-Office
import Home from "./pages/dashboard/front-office/home/Home";

// Manage contract
import ImportContract from "./pages/dashboard/front-office/manage-contract/import-contract/ImportContract";
import ValidateContract from "./pages/dashboard/front-office/manage-contract/validate-contract/ValidateContract";
import Endorsements from "./pages/dashboard/front-office/manage-contract/endorsements/Endorsements";
import Comments from "./pages/dashboard/front-office/manage-contract/comments/Comments";
import LinkedDocuments from "./pages/dashboard/front-office/manage-contract/linked-documents/LinkedDocuments";
import AddLinkedDocument from "./pages/dashboard/front-office/manage-contract/add-linked-document/AddLinkedDocument";
import NegociationsContract from "./pages/dashboard/front-office/manage-contract/negociations/contract/Negociations";
import NegociationsEndorsement from "./pages/dashboard/front-office/manage-contract/negociations/endorsement/Negociations";
import Alertes from "./pages/dashboard/front-office/manage-contract/alertes/Alertes";
import Tags from "./pages/dashboard/front-office/manage-contract/tags/Tags";
import Organizations from "./pages/dashboard/front-office/manage-contract/organizations/Organizations";

import Profile from "./pages/dashboard/front-office/profile/Profile";

import Subscription from "./pages/dashboard/front-office/subscription/Subscription";

// Back-office
import HomeBackOffice from "./pages/dashboard/back-office/home/Home";

// Manage companies / clients
import Companies from "./pages/dashboard/back-office/companies/Companies";
import DocAtWebKeywords from "./pages/dashboard/back-office/docAtWebKeywords/DocAtWebKeywords";
import DocAtWebAdmins from "./pages/dashboard/back-office/DocAtWebAdmins/DocAtWebAdmins";

// Right Access
import UsersAccess from "./pages/dashboard/back-office/rights-management/users-access/UsersAccess";
import Organization from "./pages/dashboard/back-office/rights-management/organization/Organization";
import Perimeter from "./pages/dashboard/back-office/rights-management/perimeter/Perimeter";
import Teams from "./pages/dashboard/back-office/rights-management/teams/Teams";

// Contract Settings
import Keywords from "./pages/dashboard/back-office/contract-settings/keywords/Keywords";
import Categories from "./pages/dashboard/back-office/contract-settings/categories/Categories";
import SubCategories from "./pages/dashboard/back-office/contract-settings/sub-categories/SubCategories";

import History from "./pages/dashboard/back-office/history/History";

import SubscriptionBackOffice from "./pages/dashboard/back-office/subscription/Subscription";

import { stopReportingRuntimeErrors } from "react-error-overlay";
import DashBord from "./pages/dashboard/back-office/dash-bord";
import ReactModal from "react-modal";
import GuestPassword from "./pages/auth/guest-password/GuestPassword";
import DashBordBuying from "./pages/dashboard/back-office/dash-bord-buying";
import Rgpd from "./pages/dashboard/back-office/rgpd/Rgpd";
import DocAtWebAdminRgpd from "./pages/dashboard/back-office/docAtWebAdminRgpd/DocAtWebAdminRgpd";
import ExchangesContract from "./pages/dashboard/front-office/manage-contract/exchanges/ExchangesContract";
import BankStatements from "./pages/bankStatements/BankStatements";
import CookieConsent from "./components/cookie-consent/CookieConsent";
import Security from "./pages/dashboard/back-office/rights-management/security/Security";
import KeywordsERP from "./pages/dashboard/back-office/webservices/KeywordsERP/KeywordsERP";
import AuthorizedERPAddress from "./pages/dashboard/back-office/webservices/AuthorizedERPAddress/AuthorizedERPAddress";

if (process.env.NODE_ENV === "production"){
  // stopReportingRuntimeErrors(); // disables error overlays on production
  console.log = console.warn = console.error = () => {};
}

ReactModal.setAppElement('#root');

ReactDOM.render(
  <DataProvider>
    <React.StrictMode>
      <CookieConsent />
      <Router>
        <Switch>
          <Route path="/" exact>
            <Login />
          </Route>

          {/* Bank Statements */}
          <Route
            exact
            path="/bank-statements"
            component={() => 
              <BankStatements /> 
            }
          />

          {/**************** Auth ********************/}
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/forgot-password" exact>
            <ForgotPassword />
          </Route>
          <Route path="/reset-password" exact>
            <ResetPassword />
          </Route>
          <Route path="/unconnect" exact>
            <UnconnectUser />
          </Route>
          {/******************************************/}
          {/**************** Guest ********************/}
          <Route path="/guest-password" exact>
            <GuestPassword />
          </Route>
          {/******************************************/}

          {/*********** Front-Office *****************/}

          <Route
            exact
            path="/home"
            component={() => (isUser() ? <Home /> : <Redirect to="/" />)}
          />

          {/********** Manage Contact *************/}

          <Route
            exact
            path="/manage-contract/import"
            component={() =>
              isUser() ? <ImportContract /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/manage-contract/validate"
            component={() =>
              isUser() ? <ValidateContract /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/manage-contract/endorsements"
            component={() =>
              isUser() ? <Endorsements /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/manage-contract/tags"
            component={() => (isUser() ? <Tags /> : <Redirect to="/" />)}
          />

          <Route
            exact
            path="/manage-contract/organisations"
            component={() =>
              isUser() ? <Organizations /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/manage-contract/comments"
            component={() => (isUser() ? <Comments /> : <Redirect to="/" />)}
          />

          <Route
            exact
            path="/manage-contract/linked-documents"
            component={() =>
              isUser() ? <LinkedDocuments /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/manage-contract/add-linked-document"
            component={() =>
              isUser() ? <AddLinkedDocument /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/manage-contract/negociations"
            component={() =>
              isUser() ? <NegociationsContract /> : (isNewGuest() ? <GuestPassword /> : <Redirect to="/" />)
            }
          />
          <Route
            exact
            path="/manage-contract/negociations/endorsement"
            component={() =>
              isUser() ? <NegociationsEndorsement /> : (isNewGuest() ? <GuestPassword /> : <Redirect to="/" />)
            }
          />
          <Route
            exact
            path="/manage-contract/exchanges"
            component={() =>
              isUser() ? <ExchangesContract /> : (isNewGuest() ? <GuestPassword /> : <Redirect to="/" />)
            }
          />

          <Route
            exact
            path="/manage-contract/alertes"
            component={() => (isUser() ? <Alertes /> : <Redirect to="/" />)}
          />

          {/****************************************/}

          <Route path="/profile" exact>
            <Profile />
          </Route>

          <Route path="/subscription" exact>
            <Subscription />
          </Route>

          {/******************************************/}

          {/*********** Front-Office *****************/}

          <Route
            exact
            path="/back-office/home"
            component={() =>
              isAdminClientOrUser() ? <HomeBackOffice /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/back-office/companies"
            component={() => (isAdmin() ? <Companies /> : <Redirect to="/" />)}
          />
          <Route
            exact
            path="/back-office/webservices/authorized-erp-address"
            component={() =>
              isAdmin() ? <AuthorizedERPAddress /> : <Redirect to="/" />
            }
          />
          <Route
            exact
            path="/back-office/webservices/keywords"
            component={() =>
              isAdmin() ? <KeywordsERP /> : <Redirect to="/" />
            }
          />
          <Route
            exact
            path="/back-office/keywords"
            component={() =>
              isAdmin() ? <DocAtWebKeywords /> : <Redirect to="/" />
            }
          />
          <Route
            exact
            path="/back-office/manage-docatweb-admins"
            component={() =>
              isAdmin() ? <DocAtWebAdmins /> : <Redirect to="/" />
            }
          />

          {/* Rights access */}
          <Route
            exact
            path="/back-office/rights-management/users-access"
            component={() =>
              isClientAdmin() ? <UsersAccess /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/back-office/rights-management/organizations"
            component={() =>
              isClientAdmin() ? <Organization /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/back-office/rights-management/perimeters"
            component={() =>
              isClientAdmin() ? <Perimeter /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/back-office/rights-management/teams"
            component={() =>
              isClientAdmin() ? <Teams /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/back-office/rights-management/security"
            component={() =>
              isClientAdmin() ? <Security /> : <Redirect to="/" />
            }
          />

          {/* Contract management */}
          <Route
            exact
            path="/back-office/contract-settings/keywords"
            component={() =>
              isClientAdmin() ? <Keywords /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/back-office/contract-settings/categories"
            component={() =>
              isClientAdmin() ? <Categories /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/back-office/contract-settings/sub-categories"
            component={() =>
              isClientAdmin() ? <SubCategories /> : <Redirect to="/" />
            }
          />

          {/* activities history */}
          <Route
            exact
            path="/back-office/history"
            component={() => <History />}
          />

          {/* RGPD Client */}
          <Route
            exact
            path="/back-office/rgpd"
            component={() => 
              isClientAdmin() ? <Rgpd /> : <Redirect to="/" />
            }
          />

          {/* RGPD Docatweb */}
          <Route
            exact
            path="/back-office/docatweb-admin-rgpd"
            component={() => 
              isAdmin() ? <DocAtWebAdminRgpd /> : <Redirect to="/"/>
            }
          />

          {/* Abonement */}
          <Route path="/back-office/subscription" exact>
            <SubscriptionBackOffice />
          </Route>

          {/* DashBord */}
          <Route exact path="/back-office/dash-bord">
            <DashBord />
          </Route>

          {/* DashBord Buying */}
          <Route exact path="/back-office/dash-bord-buying">
            <DashBordBuying />
          </Route>

          {/******************************************/}
        </Switch>
      </Router>
    </React.StrictMode>
  </DataProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();