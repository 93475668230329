import React from "react";
const ListIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.05" width="34" height="34" rx="18" />
    <path d="M0.5 2.875H11.525M0.5 6.25H11.525M0.5 9.625H11.525M0.5 12.9812H11.525" stroke="#202020" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.75 1.375L18.1233 5.125L21.5 1.375" stroke="#202020" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.5 14.125L18.1267 10.375L14.75 14.125" stroke="#202020" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/> 
  </svg>
);

export default ListIcon;
