import React from "react";

const SortIcon = () => (
  <>
    <svg
      width="26"
      height="9"
      viewBox="0 0 26 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 1.25H24.25M1.75 8H15.25"
        stroke="#5367F4"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
    <svg
      width="8"
      height="3"
      viewBox="0 0 8 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        marginLeft: "-26px",
        marginTop: "19px",
      }}
    >
      <path
        d="M1.75 1.75H6.25"
        stroke="#5367F4"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </>
);

export default SortIcon;
