import axios from 'axios';
import {API_URL} from "../config/common";
import {getConnectedUserAccessToken, getCurrentClientID} from "./QueryAuthParameters"

export const getContractOcerizationInfo = (contractHashedID) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/contract/getContractOserizationInfo?access_token=`+getConnectedUserAccessToken();
  formData.append('contractHashedID', contractHashedID);
  formData.append('getHTMLCommentContent', 1);
  return axios.post(requestUrl, formData);
};

export const getContractComments = (contractHashedID) => {
    const requestUrl = API_URL+`/contractComment/getContractCommentsByContract?hachedId=`+contractHashedID+`&access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl)
  };

export const exportContractComments = (contractHashedID) => {
    const requestUrl = API_URL+`/contractComment/export/`+contractHashedID+`?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl)
  };

export const getCurrentUser = () => {
    const requestUrl = API_URL+`/user/getUserNameAndAvatarByAccessToken?access_token=`+getConnectedUserAccessToken();
    return axios.get(requestUrl)
  };

export const createComment = (commentForm, contractID, contractHTMLCommentsContent) => {
    const requestUrl = API_URL+`/contractComment?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('contract', contractID);
    formData.append('title', commentForm.title.value);
    formData.append('content', commentForm.content.value);
    formData.append('position', commentForm.highlightElementId.value);
    //formData.append('hmtlCommentsContent', contractHTMLCommentsContent);
    return axios.post(requestUrl, formData);
}

export const editComment = (comment) => {
    const requestUrl = API_URL+`/contractComment?access_token=`+getConnectedUserAccessToken();
    var formData = new FormData();
    formData.append('id', comment.id);
    formData.append('title', comment.title);
    formData.append('content', comment.content);
    return axios.post(requestUrl, formData);
}

export const deleteComment = (commentID) => {
    const requestUrl = API_URL+`/contractComment/`+commentID+`?access_token=`+getConnectedUserAccessToken();
    return axios.delete(requestUrl);
}

export const updateContractCommentsContent = (contractHashedID, hmtlCommentsContent) => {
  var formData = new FormData();
  const requestUrl = API_URL+`/contract/updateContractCommentsContent?access_token=`+getConnectedUserAccessToken();
  formData.append('contract', contractHashedID);
  formData.append('hmtlCommentsContent', hmtlCommentsContent);
  return axios({
    method: 'post',
    url: requestUrl,
    data: formData,
    maxBodyLength: Infinity,
    maxContentLength: Infinity,
    timeout: Infinity,
});
};